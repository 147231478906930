import { createSlice } from '@reduxjs/toolkit';
import { loginUserThunk } from 'actions/authActions';
import { checkUserThunk, logoutUserThunk } from 'actions/userActions';
import { IS_AUTHORIZED_LS_KEY } from 'modules/auth/constants/auth.const';
import { EMPTY_STRING } from 'modules/core/constants';
import { IUserPayload } from 'modules/core/types';

export type AuthReducerState = {
  session_role: string;
  isFetching: boolean;
  isAuth: boolean;
  isError: boolean;
  errorMessage: string;
  user: IUserPayload | null;
};

const initialState: AuthReducerState = {
  session_role: 'manager',
  isFetching: true,
  isAuth: false,
  isError: false,
  errorMessage: EMPTY_STRING,
  user: null,
};

export const authSlice = createSlice({
  initialState,
  name: 'auth',
  reducers: {
    clearState: () => ({ ...initialState }),
  },
  extraReducers: {
    [loginUserThunk.fulfilled.type]: (state) => ({
      ...state,
      isFetching: false,
      isAuth: true,
      isError: false,
    }),
    [loginUserThunk.pending.type]: (state: AuthReducerState) => ({ ...state, isFetching: true }),
    [loginUserThunk.rejected.type]: (state, { payload: { detail } }) => ({
      ...state,
      errorMessage: detail,
      isFetching: false,
      isError: true,
    }),
    [checkUserThunk.pending.type]: (state: AuthReducerState) => ({ ...state, isFetching: true }),
    [checkUserThunk.fulfilled.type]: (state: AuthReducerState, { payload: user }) => ({
      ...state,
      user,
      isFetching: false,
      isAuth: !!localStorage.getItem(IS_AUTHORIZED_LS_KEY),
    }),
    [checkUserThunk.rejected.type]: (state: AuthReducerState) => ({ ...state, isFetching: false }),
    [logoutUserThunk.pending.type]: (state: AuthReducerState) => ({ ...state, isFetching: true }),
    [logoutUserThunk.fulfilled.type]: (state: AuthReducerState) => ({ ...state, isFetching: false }),
    [logoutUserThunk.rejected.type]: (state: AuthReducerState) => ({ ...state, isFetching: false }),
  },
});

export const { clearState } = authSlice.actions;
