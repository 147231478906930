import { IField, IOption } from 'modules/core/types';

import {
  AMOUNT_RECEIVED,
  AVG_FROM_DEPOSITS_FIELDS,
  AVG_FROM_TAX_RETURNS_FIELDS,
  CHECK_OR_PAY_DATE,
  DATE_OF_HIRE_IF_LESS_THAN_2_YEARS,
  GROSS_YTD,
  HOURLY_RATE,
  HOURS_PER_WEEK,
  NYS_DISABILITY_DEDUCTED,
  PERIOD_ENDING_DATE,
  PREVIOUS_YEAR_W2_AMOUNT,
  RADIO_SECTION,
} from './fieldsConfig.const';
import {
  FIXED_TYPE_OPTIONS,
  NON_W2_TYPE_OPTIONS,
  OTHER_TYPE_OPTIONS,
  UNEMPLOYMENT_TYPE_OPTIONS,
  W2_TYPE_OPTIONS,
} from './options.const';
import { CATEGORIES, TYPES } from './fieldNames.const';

export const INCOME_PREFIX = 'income';

export const FORM_FIELD_NAMES = {
  INCOME_FORM: `${INCOME_PREFIX}.incomesForm`,
  SAVED_INCOMES: `${INCOME_PREFIX}.savedIncomes`,
  TOTAL_INCOME: `${INCOME_PREFIX}.totalIncome`,
};

export const MAP_TYPE_BY_CATEGORY = new Map<string, IOption[]>([
  [CATEGORIES.W2, W2_TYPE_OPTIONS],
  [CATEGORIES.NON_W2, NON_W2_TYPE_OPTIONS],
  [CATEGORIES.FIXED, FIXED_TYPE_OPTIONS],
  [CATEGORIES.UNEMPLOYMENT, UNEMPLOYMENT_TYPE_OPTIONS],
  [CATEGORIES.OTHER, OTHER_TYPE_OPTIONS],
]);

export const MAP_FIELDS_BY_TYPE = new Map<string, IField[]>([
  [
    TYPES.GROSS_YTD,
    [DATE_OF_HIRE_IF_LESS_THAN_2_YEARS, GROSS_YTD, PERIOD_ENDING_DATE, CHECK_OR_PAY_DATE, ...RADIO_SECTION],
  ],
  [TYPES.BASE_PAY, [HOURLY_RATE, HOURS_PER_WEEK, ...RADIO_SECTION]],
  [TYPES.NYS_DISABILITY, [GROSS_YTD, NYS_DISABILITY_DEDUCTED, ...RADIO_SECTION]],
  [TYPES.EARLY_YEAR, [DATE_OF_HIRE_IF_LESS_THAN_2_YEARS, PREVIOUS_YEAR_W2_AMOUNT, ...RADIO_SECTION]],
  [TYPES.AVG_FROM_TAX_RETURNS, AVG_FROM_TAX_RETURNS_FIELDS],
  [TYPES.AVG_FROM_DEPOSITS, AVG_FROM_DEPOSITS_FIELDS],
  [TYPES.SOCIAL_SECURITY, [AMOUNT_RECEIVED]],
  [TYPES.PENSION_AND_ETC, [AMOUNT_RECEIVED]],
  [TYPES.DISABILITY_AND_ETC, [AMOUNT_RECEIVED]],
  [TYPES.INSURANCE, [AMOUNT_RECEIVED]],
  [TYPES.NON_CALCULATED, [AMOUNT_RECEIVED]],
]);
