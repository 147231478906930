import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { MODAL_DIALOG_ID, MODAL_DIALOG_WIDTH } from 'modules/core/constants';
import { Card } from 'react-bootstrap';
import { useModalState } from 'modules/core/hooks';
import { FormRow, MoneyLabel } from 'modules/core/components';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { ModalDialog } from 'components/ModalDialog/ModalDialog';
import { useTranslation } from 'react-i18next';
import { DropdownFields } from 'components/Modals/LoanVariablesDialog/modals/Calculators/NetTradeInCalculator/components/DropdownFields';
import { ColumnWidth } from 'modules/core/enums';
import { Panel } from 'components/Modals/commonStyles';

import { NET_DOWN_PAYMENT_FIELDS } from './netDownPayment.const';
import { IProbabilityEstimatorForm } from '../../types';
import { ProbabilityEstimatorCalculationService } from '../../services';
import {
  NET_DOWN_PAYMENT_FIELD_NAMES,
  NET_DOWN_PAYMENT_INDEX_FIELD,
  PROBABILITY_ESTIMATOR_FIELDS,
} from '../../probabilityEstimator.const';
import { TotalNetDownPaymentPanel } from './styles';

const { Body } = Card;

interface INetDownPaymentModal {
  isLender?: boolean;
  submitCallback: VoidFunction;
  fieldWidth: ColumnWidth;
}

export const NetDownPaymentModal: FC<INetDownPaymentModal> = ({ submitCallback, isLender = false, fieldWidth }) => {
  const { t } = useTranslation();
  const {
    setValue: commonSetValue,
    getValues: commonGetValues,
    reset: commonReset,
  } = useFormContext<IProbabilityEstimatorForm>();
  const { isModalDialogActive, handleCloseDialog } = useModalState<IProbabilityEstimatorForm>(
    [MODAL_DIALOG_ID.NET_DOWN_PAYMENT],
    commonGetValues(),
    commonReset,
  );

  const defaultValues = commonGetValues().totalNetDownPaymentDialog;

  const formMethods = useForm({
    mode: 'onSubmit',
    shouldUnregister: false,
    defaultValues: {
      totalNetDownPaymentDialog: defaultValues,
    },
  });
  const { handleSubmit, watch, setValue, getValues } = formMethods;

  const formValues = watch();

  const {
    cashDown,
    tradeValue,
    amountOwed,
    totalNetDownPayment,
    make,
    otherMake,
    otherModel,
  } = formValues.totalNetDownPaymentDialog;
  const disabled = (Number.isNaN(tradeValue) || tradeValue === 0) && (Number.isNaN(amountOwed) || amountOwed === 0);
  const disabledModelField = disabled || !Boolean(make);

  useEffect(() => {
    const netDownPayment = ProbabilityEstimatorCalculationService.getTotalNetDownPayment(
      cashDown || 0,
      tradeValue || 0,
      amountOwed || 0,
    );

    setValue(NET_DOWN_PAYMENT_FIELD_NAMES.TOTAL_NET_DOWN, netDownPayment);
  }, [amountOwed, cashDown, setValue, tradeValue]);

  const handleSubmitModal = useCallback(() => {
    const values = getValues();

    handleCloseDialog()();
    commonSetValue(PROBABILITY_ESTIMATOR_FIELDS.NET_DOWN_PAYMENT, totalNetDownPayment || 0);
    commonSetValue('totalNetDownPaymentDialog', {
      ...values.totalNetDownPaymentDialog,
      otherMake: otherMake ? otherMake.trim() : '',
      otherModel: otherModel ? otherModel.trim() : '',
    });

    if (isLender && defaultValues.cashDown !== values.totalNetDownPaymentDialog.cashDown) {
      commonSetValue('cashDownOverridden', true);
    }

    submitCallback();
  }, [
    getValues,
    handleCloseDialog,
    commonSetValue,
    totalNetDownPayment,
    otherMake,
    otherModel,
    isLender,
    defaultValues,
    submitCallback,
  ]);

  const netDownPaymentFields = useMemo(
    () =>
      NET_DOWN_PAYMENT_FIELDS.map(({ name, label, component: Component, controlProps }) => {
        const _disabled = name === NET_DOWN_PAYMENT_FIELD_NAMES.LIEN_HOLDER && disabled;

        if (name === NET_DOWN_PAYMENT_FIELD_NAMES.LIEN_HOLDER && !isLender) return '';

        return (
          <FormRow
            fieldWidth={fieldWidth}
            label={label}
            name={name}
            control={<Component name={name} {...controlProps} disabled={_disabled} />}
          />
        );
      }),
    [disabled, fieldWidth, isLender],
  );

  return (
    <ModalDialog
      title={t('components.netDownPaymentModal.title')}
      submitButtonTitle={t('common.buttons.save')}
      cancelButtonTitle={t('common.buttons.cancel')}
      show={isModalDialogActive}
      onHide={handleCloseDialog(true)}
      onSubmitModal={handleSubmit(handleSubmitModal)}
      modalSize={MODAL_DIALOG_WIDTH.CUSTOM.PX_425}
      upperCaseTitle
    >
      <Body>
        <FormProvider {...formMethods}>
          <TotalNetDownPaymentPanel>
            <FormRow
              label={t('components.netDownPaymentModal.totalCashAndTrade')}
              fieldWidth={fieldWidth}
              control={
                <MoneyLabel isInteger isColored>
                  {totalNetDownPayment || 0}
                </MoneyLabel>
              }
            />
          </TotalNetDownPaymentPanel>
          <Panel>
            {netDownPaymentFields}
            {isLender && (
              <DropdownFields
                disabled={disabled}
                disabledModelField={disabledModelField}
                formPrefix={NET_DOWN_PAYMENT_INDEX_FIELD}
                fieldWidth={fieldWidth}
                fieldsNames={{
                  lienHolder: NET_DOWN_PAYMENT_FIELD_NAMES.LIEN_HOLDER,
                  year: NET_DOWN_PAYMENT_FIELD_NAMES.YEAR,
                  make: NET_DOWN_PAYMENT_FIELD_NAMES.MAKE,
                  model: NET_DOWN_PAYMENT_FIELD_NAMES.MODEL,
                  otherYear: NET_DOWN_PAYMENT_FIELD_NAMES.OTHER_YEAR,
                  otherMake: NET_DOWN_PAYMENT_FIELD_NAMES.OTHER_MAKE,
                  otherModel: NET_DOWN_PAYMENT_FIELD_NAMES.OTHER_MODEL,
                }}
              />
            )}
          </Panel>
        </FormProvider>
      </Body>
    </ModalDialog>
  );
};
