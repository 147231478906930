import { IField } from 'modules/core/types/common';
import { Input } from 'modules/core/components';
import i18n from 'i18n';
import { FRONT_AND_BACK_ENDS_ADDS_FIELD_NAMES } from 'modules/RACAffordabilityEngine/const/fieldNames.const';

export const FRONT_AND_BACK_ENDS_ADDS_FIELDS: IField[] = [
  {
    name: FRONT_AND_BACK_ENDS_ADDS_FIELD_NAMES.ADD_ONS,
    component: Input,
    label: i18n.t('components.frontAndBackEndsAddsModal.addOns'),
    controlProps: {
      hideInitZeroValue: true,
      decimalScale: 0,
      placeholder: i18n.t('components.input.placeholders.integer'),
    },
  },
  {
    name: FRONT_AND_BACK_ENDS_ADDS_FIELD_NAMES.VSC,
    component: Input,
    label: i18n.t('components.frontAndBackEndsAddsModal.vsc'),
    controlProps: {
      hideInitZeroValue: true,
      decimalScale: 0,
      placeholder: i18n.t('components.input.placeholders.integer'),
    },
  },
  {
    name: FRONT_AND_BACK_ENDS_ADDS_FIELD_NAMES.GAP,
    component: Input,
    label: i18n.t('components.frontAndBackEndsAddsModal.gap'),
    controlProps: {
      hideInitZeroValue: true,
      decimalScale: 0,
      placeholder: i18n.t('components.input.placeholders.integer'),
    },
  },
];
