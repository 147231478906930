import { WindowBreakpoints } from 'modules/core/enums/windowBreakpoints.enum';
import { Col } from 'react-bootstrap';
import styled from 'styled-components';

export const RACLogo = styled.img`
  height: 50px;
  margin: 15px 0 0 0;

  @media (max-width: ${WindowBreakpoints.small}px) {
    max-width: 125px;
    height: auto;
  }
`;

export const RateSheetSettingsCol = styled(Col)`
  align-self: center;
  .row {
    justify-content: flex-end;
  }
`;
