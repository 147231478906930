import React from 'react';
import i18n from 'i18n';
import { IField } from 'modules/core/types';
import { DollarAdornment, Input } from 'modules/core/components';
import { ColumnWidth } from 'modules/core/enums';
import { requiredValidator } from 'modules/core/validators';

import { SALES_FEES_FIELDS_NAMES } from './fieldNames.const';

export const TOTAL_FEES_FIELD: IField = {
  name: SALES_FEES_FIELDS_NAMES.TOTAL_FEES,
  component: Input,
  controlProps: {
    fieldWidth: ColumnWidth.FULL,
    placeholder: i18n.t('components.input.placeholders.integer'),
  },
};

export const CALCULATION_FIELDS: IField[] = [
  {
    label: i18n.t('components.loanVariablesModal.modals.salesFees.fields.salesDocumentFees'),
    name: SALES_FEES_FIELDS_NAMES.SALES_DOCOMENT_FEES,
    component: Input,
    controlProps: {
      startAdornment: <DollarAdornment />,
      required: true,
      rules: requiredValidator,
      textStyle: 'bold',
      fieldWidth: ColumnWidth.S,
      placeholder: i18n.t('components.input.placeholders.integer'),
      decimalScale: 0,
    },
  },
  {
    label: i18n.t('components.loanVariablesModal.modals.salesFees.fields.titleRegistartionLicence'),
    name: SALES_FEES_FIELDS_NAMES.TITLE_REGISTRATION_LICENSE,
    component: Input,
    controlProps: {
      startAdornment: <DollarAdornment />,
      required: true,
      rules: requiredValidator,
      textStyle: 'bold',
      fieldWidth: ColumnWidth.S,
      placeholder: i18n.t('components.input.placeholders.integer'),
      decimalScale: 0,
    },
  },
];
