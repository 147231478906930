import { IIncomeForm } from 'components/IncomeCalculator/types';
import { IIncomeReportPayload, IVehicleForm } from 'modules/core/types';
import { Selector, useDispatch, useSelector } from 'react-redux';
import {
  resetVehicles as resetVehiclesPE,
  setIncome as setIncomePE,
  resetIncome as resetIncomePE,
  setIsReset as setIsResetPE,
  resetVehicleFiltersDirtyFields as resetVehicleFiltersDirtyFieldsPE,
  resetByStockOptions as resetByStockOptionsPE,
} from 'store/reducers/probabilityEstimator.reducer';
import {
  resetVehicles as resetVehiclesLender,
  setIncome as setIncomeLender,
  resetIncome as resetIncomeLender,
  setIsReset as setIsResetLender,
  resetVehicleFiltersDirtyFields as resetVehicleFiltersDirtyFieldsLender,
  resetByStockOptions as resetByStockOptionsLender,
} from 'store/reducers/lenders.reducer';
import {
  resetVehicles as resetVehiclesRAC,
  setIncome as setIncomeRAC,
  resetIncome as resetIncomeRAC,
  setIsReset as setIsResetRAC,
  resetVehicleFiltersDirtyFields as resetVehicleFiltersDirtyFieldsRAC,
  resetByStockOptions as resetByStockOptionsRAC,
} from 'store/reducers/RACAffordabilityEngine.reducer';
import {
  getIncome as getIncomeRAC,
  getIncomeCalculatorReportData as getIncomeCalculatorReportDataRAC,
} from 'store/selectors/RACAffordabilityEngine.selector';
import {
  getIncome as getIncomePE,
  getIncomeCalculatorReportData as getIncomeCalculatorReportDataPE,
} from 'store/selectors/probabilityEstimator.selector';
import {
  getIncome as getIncomeLender,
  getIncomeCalculatorReportData as getIncomeCalculatorReportDataLender,
} from 'store/selectors/lenders.selector';
import { RootState } from 'store/store';
import { INITIAL_VALUES } from 'modules/ProbabilityEstimator/probabilityEstimator.const';
import { INITIAL_VALUES_ONE_LENDER } from 'modules/Lender/Lender.const';
import { INITIAL_VALUES as INITIAL_VALUES_RAC } from 'modules/RACAffordabilityEngine/RACAffordabilityEngine.const';
import { Dispatch } from 'redux';

export enum PageName {
  PROBABILITY = 'probability',
  LENDER = 'lender',
  RAC = 'rac',
}
interface IConsumerInformationMethods {
  getIncome: Selector<RootState, IIncomeForm>;
  setIncome: (arg: IIncomeForm) => void;
  resetVehicleFiltersDirtyFields: () => void;
  onResetState: () => void;
  incomeCalculatorData: IIncomeReportPayload;
  initialValues: IVehicleForm;
  getIncomeCalculatorReportData: IIncomeReportPayload;
}

const generateConsumerInformationData = (
  dispatch: Dispatch<any>,
  resetVehicles: Function,
  resetIncome: Function,
  setIsReset: Function,
  resetVehicleFiltersDirtyFields: Function,
  resetByStockOptions: Function,
  setIncome: (arg: IIncomeForm) => void,
  getIncome: Selector<RootState, IIncomeForm>,
  getIncomeCalculatorReportData: any,
  incomeCalculatorData: IIncomeReportPayload,
  initialValues: IVehicleForm,
): IConsumerInformationMethods => {
  const onResetState = () => {
    dispatch(resetVehicles());
    dispatch(resetIncome());
    dispatch(setIsReset(true));
  };

  const resetVehicleFilters = () => {
    dispatch(resetVehicleFiltersDirtyFields());
    dispatch(resetByStockOptions());
  };

  return {
    setIncome: setIncome,
    resetVehicleFiltersDirtyFields: resetVehicleFilters,
    getIncome: getIncome,
    getIncomeCalculatorReportData: getIncomeCalculatorReportData,
    onResetState,
    incomeCalculatorData: incomeCalculatorData,
    initialValues: initialValues,
  };
};

export const useConsumerInformationData = (pageName: PageName): IConsumerInformationMethods => {
  const dispatch = useDispatch();
  const incomeCalculatorDataPE = useSelector(getIncomeCalculatorReportDataPE);
  const incomeCalculatorDataRAC = useSelector(getIncomeCalculatorReportDataRAC);
  const incomeCalculatorDataLender = useSelector(getIncomeCalculatorReportDataLender);

  if (pageName === PageName.PROBABILITY) {
    return generateConsumerInformationData(
      dispatch,
      resetVehiclesPE,
      resetIncomePE,
      setIsResetPE,
      resetVehicleFiltersDirtyFieldsPE,
      resetByStockOptionsPE,
      setIncomePE,
      getIncomePE,
      getIncomeCalculatorReportDataPE,
      incomeCalculatorDataPE,
      INITIAL_VALUES,
    );
  }

  if (pageName === PageName.RAC) {
    return generateConsumerInformationData(
      dispatch,
      resetVehiclesRAC,
      resetIncomeRAC,
      setIsResetRAC,
      resetVehicleFiltersDirtyFieldsRAC,
      resetByStockOptionsRAC,
      setIncomeRAC,
      getIncomeRAC,
      getIncomeCalculatorReportDataRAC,
      incomeCalculatorDataRAC,
      INITIAL_VALUES_RAC,
    );
  }

  return generateConsumerInformationData(
    dispatch,
    resetVehiclesLender,
    resetIncomeLender,
    setIsResetLender,
    resetVehicleFiltersDirtyFieldsLender,
    resetByStockOptionsLender,
    setIncomeLender,
    getIncomeLender,
    getIncomeCalculatorReportDataLender,
    incomeCalculatorDataLender,
    INITIAL_VALUES_ONE_LENDER,
  );
};
