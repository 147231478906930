import styled from 'styled-components';
import { Image } from 'react-bootstrap';

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const GeneralVehicleInfo = styled.div`
  display: flex;
  align-items: center;
`;

export const ImageUI = styled(Image)`
  vertical-align: top;
  height: 50px;
  margin-right: 5px;
`;

export const VehicleModel = styled.div`
  margin-right: 16px;

  span:first-child {
    display: block;
  }
`;
