import React, { FC, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormContext, Controller, ControllerRenderProps } from 'react-hook-form';
import { Dropdown } from 'react-bootstrap';
import { AppDispatch } from 'store/store';
import { getInterestRateListThunk } from 'actions/controlActions';
import { getInterestRate } from 'store/selectors/controls.selector';
import { useUncontrolledInput } from 'modules/core/hooks';
import { IFormField } from 'modules/core/types/form';
import { useTranslation } from 'react-i18next';
import { PREFERENCES_PE_FIELD_NAMES } from 'modules/core/shared';

import { CreditScore } from '../types';
import { CreditScoreColors } from '../enums/creditScoreColors.enum';
import { DefaultToggle, OptionItem, ScoreMenu, SelectedScoreToggle } from './styles';

export const CreditScoreDropdown: FC<IFormField> = ({ name, validate }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const { watch } = useFormContext();
  const { controlField, setValue } = useUncontrolledInput(name);
  const interestRateList = useSelector(getInterestRate);

  const selectedScore = watch(name) as CreditScore;

  const isSelectedDefault = selectedScore.color === CreditScoreColors.DEFAULT;
  const toggleTitle = isSelectedDefault ? t('components.creditScore.title') : selectedScore.range;
  const ToggleComponent = isSelectedDefault ? DefaultToggle : SelectedScoreToggle;

  const renderOptions = useCallback(
    ({ onChange, onBlur }: ControllerRenderProps) =>
      interestRateList.map((creditScore) => {
        const isSelectedOption = selectedScore.id === creditScore.id;

        return (
          <OptionItem
            onClick={() => {
              onChange(creditScore);
              setValue(PREFERENCES_PE_FIELD_NAMES.LVT_HIGH_LIMIT, creditScore.ltv_limit);
            }}
            onBlur={onBlur}
            color={creditScore.color}
            selected={isSelectedOption}
            key={creditScore.id}
          >
            {creditScore.range}
          </OptionItem>
        );
      }),
    [interestRateList, selectedScore, setValue],
  );

  const renderDropdown = useCallback(
    (renderProps) => (
      <Dropdown>
        <ToggleComponent color={selectedScore.color}>{toggleTitle}</ToggleComponent>
        <ScoreMenu>{renderOptions(renderProps)}</ScoreMenu>
      </Dropdown>
    ),
    [ToggleComponent, renderOptions, selectedScore.color, toggleTitle],
  );

  useEffect(() => {
    dispatch(getInterestRateListThunk());
  }, [dispatch]);

  return (
    <Controller
      name={name}
      render={renderDropdown}
      control={controlField}
      rules={{
        validate,
      }}
    />
  );
};
