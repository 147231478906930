import React from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTER_PATH } from 'router/const/routerPath.const';

import { SubmitButton } from '../styles';

interface IButton {
  label: string;
  disabled?: boolean;
  isOnClick?: boolean;
}

export const Button: React.FC<IButton> = ({ label, disabled = false, isOnClick = false }) => {
  const history = useHistory();

  const onClickBackToLogin = () => {
    history.push(ROUTER_PATH.LOGIN);
  };

  return (
    <SubmitButton type="submit" variant="primary" disabled={disabled} onClick={isOnClick ? onClickBackToLogin : null}>
      {label}
    </SubmitButton>
  );
};
