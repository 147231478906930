import { useForm, UseFormOptions, UseFormMethods } from 'react-hook-form';
import { useCallback, useEffect } from 'react';
import { ActionCreatorWithPayload, Selector } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AppDispatch, RootState } from 'store/store';

interface IUseFormEnhanced {
  methods: UseFormMethods;
  syncFormAction: VoidFunction;
}

export const useFormEnhanced = <V>(
  syncFormValuesAction: ActionCreatorWithPayload<V>,
  selector: Selector<RootState, V>,
  options?: UseFormOptions,
): IUseFormEnhanced => {
  const dispatch: AppDispatch = useDispatch();
  const initialValues = useSelector(selector);
  const history = useHistory();

  const methods = useForm({
    ...options,
    // This is necessary to reset the table columns to default view when the user switches pages.
    defaultValues: { ...initialValues, selectedColumns: undefined },
  });

  const { getValues } = methods;

  const syncFormAction = useCallback(() => {
    dispatch(syncFormValuesAction(getValues() as V));
  }, [dispatch, getValues, syncFormValuesAction]);

  useEffect(() => {
    const unsubscribe = history.listen(() => {
      syncFormAction();
    });

    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { methods, syncFormAction };
};
