import { createAsyncThunk } from '@reduxjs/toolkit';

import { ACTION_PREFIX } from './actionPrefix.const';
import { lenderApi } from '../api';

export const getLendersListThunk = createAsyncThunk(
  `${ACTION_PREFIX.LENDER}/getLendersList`,
  async (subdomain?: string) => {
    const { data } = await lenderApi.getLendersList(subdomain);

    return data;
  },
);

export const getLenderThunk = createAsyncThunk(`${ACTION_PREFIX.LENDER}/getLender`, async (lenderId: number) => {
  const { data } = await lenderApi.getLender(lenderId);

  return data;
});

export const getLenderControlsThunk = createAsyncThunk(
  `${ACTION_PREFIX.LENDER}/getLenderControls`,
  async (lenderId: number) => {
    const { data } = await lenderApi.getLenderControls(lenderId);

    return data;
  },
);
