import React, { useRef } from 'react';
import { STATIC_IMAGE_URLS } from 'modules/core/constants';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useIsScroll } from 'hooks/useIsScroll';
import { LEARNING_ROUTES } from 'router/const/routerConfig.const';

import { formatUrl } from '../../Learning.utils';
import {
  LearningContent,
  LearningHeader,
  PositionCard,
  CardImage,
  BackButton,
  CardButton,
  ArrowDown,
} from '../../styles';
import { LEARNING_PATHS } from '../../Learning.const';
import { sendMetric } from '../../../../metrics';

interface ILearningLinkWrapper {
  prevPageLink?: string;
  nextPageLink?: string;
  isBackground?: boolean;
  children?: React.ReactNode;
}

export const LearningWrapper: React.FC<ILearningLinkWrapper> = ({
  prevPageLink,
  nextPageLink,
  children,
  isBackground = false,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { logoWhite } = STATIC_IMAGE_URLS;

  const buttonTitle =
    nextPageLink === LEARNING_PATHS.keyFactors
      ? t('components.learningLink.getStarted')
      : t('components.learningLink.continue');

  const contentRef = useRef<HTMLDivElement>(null);

  const isScroll = useIsScroll(contentRef);

  const currentUrl = history.location.pathname;

  const pushPageProgressToAnalytics = (nextRoute: string) => {
    const currentPageIndex = LEARNING_ROUTES.findIndex((route) => route.path === `/learning/${nextRoute}`) + 1;

    sendMetric(`Completed ${currentPageIndex}/${LEARNING_ROUTES.length} screens`);
  };

  const goToNextPage = () => {
    if (nextPageLink) {
      history.push(formatUrl(currentUrl, nextPageLink));
      pushPageProgressToAnalytics(nextPageLink);
    }
  };

  return (
    <>
      <LearningHeader>
        {prevPageLink && (
          <BackButton
            data-testid="prevBtn"
            onClick={() => {
              history.push(formatUrl(currentUrl, prevPageLink));
            }}
          />
        )}
        <CardImage src={logoWhite} />
      </LearningHeader>
      <LearningContent ref={contentRef} isBackground={isBackground}>
        <PositionCard>{children}</PositionCard>
        {nextPageLink && (
          <CardButton onClick={goToNextPage} data-testid="nextBtn">
            {buttonTitle}
          </CardButton>
        )}
        {isScroll && <ArrowDown />}
      </LearningContent>
    </>
  );
};
