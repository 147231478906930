import { EstimatorType } from 'modules/core/enums';
import { ProbabilityEstimatorService } from 'modules/ProbabilityEstimator/services/probabilityEstimator.service';
import { convertFalsyValuesToZero } from 'modules/core/utils';
import { IFeesAndTaxesFormOptions } from 'modules/core/types';

import { IApprovedLoanInput } from './types';

export class ApprovedLoanInputService {
  static getSubmitQuery(formValues: IApprovedLoanInput): Record<string, unknown> {
    const { feesAndTaxes, preferences, vehicleFilters, tableView, ...restValues } = formValues;
    const { salesTaxRate, ...restFeesAndTaxes } = feesAndTaxes;

    return {
      ...restValues,
      ...preferences,
      ...ProbabilityEstimatorService.getFilterObject(vehicleFilters, tableView),
      ...convertFalsyValuesToZero(restFeesAndTaxes as Record<IFeesAndTaxesFormOptions, number>),
      tableView,
      salesTaxRate,
      estimatorType: EstimatorType.APPROVED_LOAN,
    };
  }
}
