import React, { FC, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { IFormField, IOption } from 'modules/core/types';
import { useUncontrolledInput } from 'modules/core/hooks';

export interface IRadio extends IFormField {
  options: IOption[];
  inline?: boolean;
}

export const Radio: FC<IRadio> = ({ name, inline, options, defaultValue }) => {
  const { registerInput, setValue } = useUncontrolledInput(name);

  useEffect(() => {
    setValue(name, defaultValue);
  }, [setValue, name, defaultValue]);

  return (
    <div>
      {options.map(({ title, value }, index) => (
        <Form.Check
          inline={inline}
          type="radio"
          label={title}
          ref={registerInput}
          name={name}
          id={`${name}-${index}`}
          value={value}
          key={value}
          data-testid={`${name}-${index}`}
        />
      ))}
    </div>
  );
};
