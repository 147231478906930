import { useCallback, useMemo } from 'react';

import { IIncomeReportPayload } from '../types';
import { ReportService } from '../services';
import { ReportType } from '../enums';

interface IUseIncomeReportButton {
  onButtonPrintClick: VoidFunction;
  isButtonPrintDisabled: boolean;
}

export const useIncomeReportButton = (data: IIncomeReportPayload): IUseIncomeReportButton => {
  const isButtonPrintDisabled = useMemo(() => !data.total_income, [data]);

  const onButtonPrintClick = useCallback(() => {
    if (!isButtonPrintDisabled) {
      ReportService.printReport(data, ReportType.INCOME);
    }
  }, [isButtonPrintDisabled, data]);

  return { onButtonPrintClick, isButtonPrintDisabled };
};
