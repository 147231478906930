import i18n from 'i18n';
import { ErrorOption } from 'react-hook-form';
import { SLASH_SYMBOL } from 'modules/core/constants';

import { PASSWORD_FIELDS, PASSWORD_CHANGE_FAILED_ERROR_NAME } from './common.const';
import { FIELD_NAMES_TO_UPPERCASE_MAP } from './ChangePasswordForm/changePassword.const';

export class MyAccountDialogService {
  static setErrorsMessages(
    setError: (name: string, error: ErrorOption) => void,
    responseData?: Record<string, string | string[]>,
  ): void {
    const fieldsWidthError = this.getFieldsWithErrorByResponse(responseData);

    if (!responseData) {
      setError(PASSWORD_CHANGE_FAILED_ERROR_NAME, { message: i18n.t('common.errorMessages.undefinedError') });
    } else if (fieldsWidthError.length) {
      this.setErrorsByFields(fieldsWidthError, responseData, setError);
    } else {
      const formErrorMessages = responseData.detail || i18n.t('common.errorMessages.undefinedError');

      setError(PASSWORD_CHANGE_FAILED_ERROR_NAME, { message: formErrorMessages as string });
    }
  }

  private static getFieldsWithErrorByResponse(responseData?: Record<string, string | string[]>): string[] {
    return PASSWORD_FIELDS.reduce((fieldsWidthError: string[], field) => {
      if (responseData?.[field]) {
        return [...fieldsWidthError, field];
      }

      return fieldsWidthError;
    }, []);
  }

  private static setErrorsByFields(
    fieldsWidthError: string[],
    responseData: Record<string, string | string[]>,
    setError: (name: string, error: ErrorOption) => void,
  ): void {
    fieldsWidthError.forEach((fieldWidthError) => {
      const camelCaseFieldName = FIELD_NAMES_TO_UPPERCASE_MAP.get(fieldWidthError) || PASSWORD_CHANGE_FAILED_ERROR_NAME;

      if (responseData[fieldWidthError].length) {
        const resultErrors = responseData[fieldWidthError] as string[];

        setError(camelCaseFieldName, { message: resultErrors.join(SLASH_SYMBOL) });
      } else {
        setError(camelCaseFieldName, { message: responseData[fieldWidthError] as string });
      }
    });
  }
}
