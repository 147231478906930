import React, { FC, useCallback } from 'react';
import { Controller } from 'react-hook-form';
import { IFormField } from 'modules/core/types';
import { useUncontrolledInput } from 'modules/core/hooks';
import { costFormatter, thousandFormatter } from 'modules/core/utils';

import { FormLabel } from './styles';

export const LabelField: FC<IFormField> = ({ name, validate, isNeedFormat = true, endAdornment = undefined }) => {
  const { controlField, fieldValue } = useUncontrolledInput(name);
  const inputValue = fieldValue?.value ? fieldValue.value || 0 : fieldValue || 0;
  const fieldLabel = isNeedFormat ? costFormatter(inputValue) : thousandFormatter(inputValue);

  const renderLabel = useCallback(
    () => (
      <FormLabel>
        {fieldLabel}
        {endAdornment}
      </FormLabel>
    ),
    [endAdornment, fieldLabel],
  );

  return (
    <Controller
      name={name}
      render={renderLabel}
      control={controlField}
      rules={{
        validate,
      }}
    />
  );
};
