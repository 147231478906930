import { useEffect, useState } from 'react';
import { TableData } from 'modules/core/components/Table/types';
import { TableView, TableName } from 'modules/core/enums';
import { sortVehiclesForSalespersonView, SORT_VEHICLE_MAP_BY_TABLE } from 'modules/core/utils';
import { IRACVehicle, IVehicle, ILenderVehicle } from 'modules/core/types';
import { LINE_DELIMITER } from 'modules/core/constants';

interface ISortedData {
  sortedData: TableData[];
}

export const useSortedData = (
  selectedTableView: TableView,
  tableName: TableName,
  vehicles: IVehicle[] | IRACVehicle[] | ILenderVehicle[],
  isServerPagination: boolean,
): ISortedData => {
  const [sortedData, setSortedData] = useState<IVehicle[] | IRACVehicle[] | ILenderVehicle[]>([]);
  const vins = vehicles.map((vehicle) => vehicle.vin).join(LINE_DELIMITER);

  useEffect(() => {
    if (isServerPagination) {
      setSortedData(vehicles);
    } else {
      const sortVehiclesFunction =
        SORT_VEHICLE_MAP_BY_TABLE?.get(tableName)?.get(selectedTableView) || sortVehiclesForSalespersonView;

      setSortedData(sortVehiclesFunction(vehicles as IVehicle[] & IRACVehicle[] & ILenderVehicle[]));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vins, selectedTableView, tableName]);

  return { sortedData };
};
