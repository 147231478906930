import styled from 'styled-components';
import { Modal } from 'react-bootstrap';
import { Button } from 'modules/core/components/Button/styles';
import { DEFAULT_FONT_SIZE } from 'modules/core/theme/typography';
import { modalButtonMixin } from 'modules/core/theme/mixins';
import { Alignment } from 'modules/core/enums';
import { WindowBreakpoints } from 'modules/core/enums/windowBreakpoints.enum';

const ALIGN_VARIANTS = {
  FLEX_START: 'flex-start',
  CENTER: 'center',
  FLEX_END: 'flex-end',
};

export const ALIGN_VARIANTS_MAP = new Map<Alignment, string>([
  [Alignment.LEFT, ALIGN_VARIANTS.FLEX_START],
  [Alignment.CENTER, ALIGN_VARIANTS.CENTER],
  [Alignment.RIGHT, ALIGN_VARIANTS.FLEX_END],
]);

export const ModalContainer = styled(Modal)<{
  modalStyle?: string;
}>`
  color: ${({ theme: { colors } }) => colors['grey-5']};

  ${({ modalStyle }) => modalStyle}

  .modal-dialog {
    max-width: ${({ $modalSize }) => $modalSize};
    min-width: ${({ $minModalSize }) => $minModalSize};

    margin: 1.75rem auto;

    @media (max-width: ${WindowBreakpoints.small}px) {
      margin-top: 15px;
    }
  }
  }

  .modal-content {
    background-color: ${({ $modalTheme }) => $modalTheme};
  }
`;

export const Title = styled(Modal.Title)`
  font-size: ${({ $fontSize }) => ($fontSize ? $fontSize : DEFAULT_FONT_SIZE)};
  font-weight: ${({ theme: { boldText } }) => boldText.dark};
  text-transform: ${({ $uppercase }) => $uppercase && 'uppercase'};
`;

export const Content = styled(Modal.Body)<{ isLoader?: boolean }>`
  h5 {
    font-size: ${DEFAULT_FONT_SIZE};
    font-weight: ${({ theme: { boldText } }) => boldText.dark};
    margin: 10px 0;
  }
  ${({ isLoader }) =>
    isLoader &&
    `
      height: 40vh;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    `};
`;

export const ModalButton = styled(Button)`
  ${modalButtonMixin};
  padding: 5px 9px;
`;

export const Footer = styled(Modal.Footer)<{
  alignFooterContent: Alignment;
}>`
  justify-content: ${({ alignFooterContent }) => ALIGN_VARIANTS_MAP.get(alignFooterContent)};
`;
