import { CATEGORIES, TYPES } from './fieldNames.const';
import { IIncomeCardForm } from '../types';

export const INCOME_INITIAL_VALUES: Partial<IIncomeCardForm>[] = [
  {
    category: CATEGORIES.W2,
    type: TYPES.GROSS_YTD,
    amountReceived: 0,
    grossYTDAmount: 0,
    hourlyRate: 0,
    hoursPerWeek: 0,
    ytdNysDisabilityDeducted: 0,
    previousYearW2Amount: 0,
    loansListedOnPaycheck: 'No',
    garnishments: 'No',
    temporaryServiceEmployee: 'No',
    seasonalEmployee: 'No',
  },
];
