import { IUser } from 'modules/core/types';

import { ROUTER_PATH } from './const/routerPath.const';
import { browserHistory } from './browserHistory';
import { getRolesDashboardNavigation } from './const/dashboard.const';
import { IRoute } from './types';
import { IMenuLink } from '../modules/core/components/SideBar/sideBarMenuLinks';

export class NavigationService {
  static navigateToLogin(): void {
    browserHistory.push(ROUTER_PATH.LOGIN);
  }

  static navigateToDashboard(user: IUser, disabledFeatures: string[], lenders: IMenuLink[], subdomain = null): void {
    const { method, path } = getRolesDashboardNavigation(user, disabledFeatures, lenders, subdomain);

    method(path);
  }

  static filterByDisabledFeatures(privateRoutes: IRoute[], disabledFeaturesList: string[]): IRoute[] {
    return privateRoutes.filter((route) => {
      const isFeatureDisabled = disabledFeaturesList.find((disabledFeature) => route.name === disabledFeature);

      return !isFeatureDisabled;
    });
  }
}
