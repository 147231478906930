import React, { FC } from 'react';

import { TagItem } from './styles';

interface ITagProps {
  text: string;
  remove: any;
}

export const Tag: FC<ITagProps> = ({ text, remove }) => {
  const handleOnRemove = (e: any) => {
    e.stopPropagation();
    remove(text);
  };

  return (
    <TagItem>
      <span>{text}</span>
      <button type="button" onClick={handleOnRemove} aria-label={`remove ${text}`}>
        &#10005;
      </button>
    </TagItem>
  );
};
