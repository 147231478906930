import styled from 'styled-components';
import { Row, Form, Col } from 'react-bootstrap';
import { COLORS } from 'modules/core/theme/colors';
import { RowUI as TotalPriceInfoRow } from 'modules/core/components/InfoRow/styles';

export const ErrorNotification = styled.span`
  color: ${COLORS.GREY_5};
  font-size: 18px;
  font-weight: ${({ theme: { boldText } }) => boldText.dark};
`;

export const FormUI = styled(Form)`
  padding: 12px;
`;

export const DealerInfo = styled.div`
  flex: 1 1 100%;
  margin-bottom: 20px;

  span {
    font-weight: ${({ theme: { boldText } }) => boldText.dark};
  }
`;

export const Layout = styled(Row)`
  margin-bottom: 32px;
`;

export const InfoRow = styled.div`
  span {
    font-weight: ${({ theme: { boldText } }) => boldText.dark};
  }
`;

export const VehicleInfo = styled.div`
  margin-bottom: 8px;
`;

export const SeriesTitle = styled.div`
  font-weight: ${({ theme: { boldText } }) => boldText.dark};
`;

export const Notification = styled.span`
  display: flex;
  align-items: center;
  margin-bottom: 32px;

  span:last-child {
    color: ${COLORS.GREY_3};
    margin-left: 16px;
  }
`;

export const TotalInfo = styled(Row)`
  margin-bottom: 64px;

  & > div:first-child {
    margin-right: 36px;
  }
`;

export const TotalInfoCol = styled(Col)<{ lastRowColor: string }>`
  ${TotalPriceInfoRow} {
    &:last-child {
      color: ${({ lastRowColor }) => lastRowColor};
    }

    div:last-child {
      text-align: right;
    }
  }
`;

export const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;
`;
