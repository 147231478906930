import React from 'react';
import { useTranslation } from 'react-i18next';
import { STATIC_IMAGE_URLS } from 'modules/core/constants';

import { LearningWrapper } from '../common/LearningWrapper/LearningWrapper';
import { Container, TitleH1 } from '../styles';
import { Carousel, CarouselItem } from '../common/CarouselCard/styles';
import { CarouselCard } from '../common/CarouselCard/CarouselCard';
import { LEARNING_PATHS } from '../Learning.const';

export const LearningComparison: React.FC = () => {
  const { t } = useTranslation();

  return (
    <LearningWrapper prevPageLink={LEARNING_PATHS.lengthOfLoan} nextPageLink={LEARNING_PATHS.final}>
      <TitleH1>{t('components.learningLink.comparison.title')}</TitleH1>
      <Container>
        <Carousel variant="dark">
          <CarouselItem>
            <CarouselCard
              imgSrc={STATIC_IMAGE_URLS.cars.bmw}
              title={t('components.learningLink.comparison.card1.title')}
              price={t('components.learningLink.comparison.card1.price')}
              priceDescription={t('components.learningLink.comparison.card1.priceDescription')}
              mileage={t('components.learningLink.comparison.card1.mileage')}
              description={t('components.learningLink.comparison.card1.description')}
            />
          </CarouselItem>
          <CarouselItem>
            <CarouselCard
              imgSrc={STATIC_IMAGE_URLS.cars.chevrolet}
              title={t('components.learningLink.comparison.card2.title')}
              price={t('components.learningLink.comparison.card2.price')}
              priceDescription={t('components.learningLink.comparison.card2.priceDescription')}
              mileage={t('components.learningLink.comparison.card2.mileage')}
              description={t('components.learningLink.comparison.card2.description')}
            />
          </CarouselItem>
          <CarouselItem>
            <CarouselCard
              imgSrc={STATIC_IMAGE_URLS.cars.chevrolet}
              title={t('components.learningLink.comparison.card3.title')}
              price={t('components.learningLink.comparison.card3.price')}
              priceDescription={t('components.learningLink.comparison.card3.priceDescription')}
              mileage={t('components.learningLink.comparison.card3.mileage')}
              description={t('components.learningLink.comparison.card3.description')}
            />
          </CarouselItem>
        </Carousel>
      </Container>
    </LearningWrapper>
  );
};
