import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { STATIC_IMAGE_URLS, STATIC_VIDEOS_URLS } from 'modules/core/constants';

import { LearningWrapper } from '../common/LearningWrapper/LearningWrapper';
import { CardForm, TitleH1, Video } from '../styles';
import { LEARNING_PATHS } from '../Learning.const';
import { VehicleMiniCard } from '../common/VehicleMiniCard/VehicleMiniCard';

export const LearningLengthOfLoan: React.FC = () => {
  const { t } = useTranslation();

  return (
    <LearningWrapper prevPageLink={LEARNING_PATHS.loanToValue} nextPageLink={LEARNING_PATHS.comparison}>
      <TitleH1>{t('components.learningLink.lengthOfLoan.title')}</TitleH1>
      {t('components.learningLink.lengthOfLoan.description')}
      <CardForm>
        <VehicleMiniCard
          imgSrc={STATIC_IMAGE_URLS.cars.chevrolet}
          title={t('components.learningLink.lengthOfLoan.miniCard.title')}
          price={t('components.learningLink.lengthOfLoan.miniCard.price')}
          description={t('components.learningLink.lengthOfLoan.miniCard.description')}
        />
        <Video autoPlay muted playsInline>
          <source src={STATIC_VIDEOS_URLS.lengthSlider} type="video/mp4" />
        </Video>
        <Trans defaults={t('components.learningLink.lengthOfLoan.cardDescription')} components={[<strong />]} />
      </CardForm>
    </LearningWrapper>
  );
};
