import React, { FC } from 'react';
import { IInputAdornment, Input } from 'modules/core/components/index';
import { IFormField } from 'modules/core/types/form';

interface ITelephoneInput extends IFormField, IInputAdornment {
  format?: string;
}

export const TelephoneInput: FC<ITelephoneInput> = ({ name, rules, format, startAdornment, validate }) => (
  <Input
    name={name}
    format={format}
    rules={rules}
    startAdornment={startAdornment}
    validate={validate}
    allowEmptyFormatting
  />
);
