import styled from 'styled-components';
import { Button, Card } from 'react-bootstrap';
import { COLORS } from 'modules/core/theme/colors';
import { WindowBreakpoints } from 'modules/core/enums/windowBreakpoints.enum';
import { STATIC_IMAGE_URLS } from 'modules/core/constants';

export const LearningHeader = styled.div`
  height: 58px;
  width: 100vw;
  background-color: ${COLORS.GREY_16};
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
`;

export const LearningContent = styled.div<{ isBackground: boolean }>`
  min-height: calc(100vh - 58px);
  margin-top: 58px;
  background-image: ${({ isBackground }) => isBackground && `url(${STATIC_IMAGE_URLS.learningBackground})`};
  background-color: ${COLORS.GREY_10};
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Merriweather Sans', sans-serif;
  font-style: normal;
  font-size: 18px;
  color: ${COLORS.GREY_17};

  @media screen and (max-width: ${WindowBreakpoints.small}px) {
    font-size: 16px;
  }
`;

export const PositionCard = styled.div`
  min-height: calc(100vh - 58px);
  width: 520px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  padding: 30px 0 80px 0;

  @media screen and (max-width: ${WindowBreakpoints.small}px) {
    width: 90%;
    padding-top: 20px;
  }
`;

export const CardForm = styled(Card)`
  width: 520px;
  background-color: #fff;
  box-shadow: 3px 3px 5px rgba(192, 192, 192, 0.2), -3px -3px 5px rgba(192, 192, 192, 0.2);
  padding: 40px 50px;
  margin: 15px 0;
  font-size: 16px;

  @media screen and (max-width: ${WindowBreakpoints.small}px) {
    width: 100%;
    padding: 20px 15px;
    font-size: 15px;
    margin: 10px 0;
  }
`;

export const ExtendedCardForm = styled(CardForm)`
  font-size: 18px;

  @media screen and (max-width: ${WindowBreakpoints.small}px) {
    font-size: 16px;
  }
`;

export const Container = styled(CardForm)`
  padding: 0;
`;

export const MainVideo = styled.video`
  border-radius: 5px;
`;

export const Video = styled.video`
  margin-bottom: 10px;
`;

export const CardImage = styled(Card.Img)`
  width: 155px;
  padding: 5px;
`;

export const BackButton = styled.div`
  background-image: url(${STATIC_IMAGE_URLS.icons.backArrow});
  background-size: contain;
  background-repeat: no-repeat;
  width: 40px;
  height: 25px;
  position: absolute;
  cursor: pointer;
  left: 17px;
  top: 17px;

  @media screen and (max-width: ${WindowBreakpoints.small}px) {
    width: 30px;
    height: 18px;
    top: 20px;
  }
`;

export const CardButton = styled(Button)`
  position: fixed;
  z-index: 100;
  left: calc(100vw % 2 - 260px);
  bottom: 25px;
  border-radius: 6px;
  height: 48px;
  width: 520px;
  border: 1px solid transparent;
  background-color: ${COLORS.BLUE_7};
  cursor: pointer;

  &:hover {
    background-color: ${COLORS.BLUE_8};
    box-shadow: none;
  }

  @media screen and (max-width: ${WindowBreakpoints.small}px) {
    width: 90%;
  }
`;

export const TitleH1 = styled.h1`
  font-size: 36px;
  font-weight: 800;
  margin-bottom: 8px;

  @media screen and (max-width: ${WindowBreakpoints.small}px) {
    font-size: 28px;
  }
`;

export const AdditionalContainer = styled.div`
  min-height: calc(100vh - 170px);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
`;

export const TextBold = styled.div`
  font-weight: 800;
  font-size: 18px;
  line-height: 2;

  @media screen and (max-width: ${WindowBreakpoints.small}px) {
    font-size: 16px;
  }
`;

export const ArrowDown = styled.div`
  background-image: url(${STATIC_IMAGE_URLS.icons.downArrow});
  background-size: contain;
  background-repeat: no-repeat;
  width: 50px;
  height: 50px;
  opacity: 0.6;
  position: fixed;
  right: 40px;
  bottom: 40px;

  @media screen and (max-width: ${WindowBreakpoints.small}px) {
    width: 30px;
    height: 30px;
    right: 7px;
    bottom: 85px;
    opacity: 0.4;
  }
`;
