import { MODAL_DIALOG_WIDTH } from 'modules/core/constants';
import React from 'react';
import { Modal, ModalProps } from 'react-bootstrap';
import { Alignment, ButtonVariant, ModalTheme } from 'modules/core/enums';

import { ModalButton, Content, ModalContainer, Title, Footer } from './style';

interface IModalDialogProps extends ModalProps {
  children: React.ReactNode;
  title?: string;
  submitButtonTitle?: string;
  onSubmitModal?: VoidFunction;
  cancelButtonTitle?: string;
  modalSize?: string;
  showFooter?: boolean;
  upperCaseTitle?: boolean;
  alignFooterContent?: Alignment;
  additionalButton?: React.ReactNode;
  verticallyCentered?: boolean;
  showCloseButton?: boolean;
  header?: React.ReactNode;
  scrollable?: boolean;
  modalTheme?: ModalTheme;
  modalStyle?: string;
  isDisabled?: boolean;
  backdrop?: boolean | 'static' | undefined;
}

export const ModalDialog: React.FC<IModalDialogProps> = ({
  children,
  title,
  submitButtonTitle,
  show,
  onHide,
  isDisabled = false,
  cancelButtonTitle,
  onSubmitModal,
  modalSize = MODAL_DIALOG_WIDTH.MD,
  showFooter = true,
  upperCaseTitle = false,
  alignFooterContent = Alignment.RIGHT,
  additionalButton,
  verticallyCentered = false,
  header,
  showCloseButton = true,
  scrollable = false,
  modalTheme = ModalTheme.DEFAULT,
  modalStyle,
  backdrop = 'static',
}) => (
  <ModalContainer
    show={show}
    onHide={onHide}
    backdrop={backdrop}
    centered={verticallyCentered}
    scrollable={scrollable}
    modalStyle={modalStyle}
    backdropClassName="backdrop-full-screen"
    $modalTheme={modalTheme}
    $modalSize={modalSize}
  >
    <Modal.Header closeButton={showCloseButton}>
      {header || <Title $uppercase={upperCaseTitle}>{title}</Title>}
    </Modal.Header>
    <Content>{children}</Content>
    {showFooter && (
      <Footer alignFooterContent={alignFooterContent}>
        {additionalButton && additionalButton}
        {cancelButtonTitle && (
          <ModalButton onClick={onHide} buttontheme={ButtonVariant.OUTLINE}>
            {cancelButtonTitle}
          </ModalButton>
        )}
        {submitButtonTitle && (
          <ModalButton onClick={onSubmitModal || onHide} buttontheme={ButtonVariant.CONTAINED} disabled={isDisabled}>
            {submitButtonTitle}
          </ModalButton>
        )}
      </Footer>
    )}
  </ModalContainer>
);
