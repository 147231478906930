import React, { FC, useCallback, useState, useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { IFormField } from 'modules/core/types';
import { useUncontrolledInput } from 'modules/core/hooks';

import { CheckBox, Input, Label } from './styles';

export const ConfirmCheckBox: FC<IFormField> = ({ name, rules, textLabel, styles, changeCallback }) => {
  const { controlField, hasError, setValue, fieldValue } = useUncontrolledInput(name);
  const [isError, setIsError] = useState<boolean>(hasError);

  const handleChange = useCallback(
    (e: any) => {
      setValue(name, e.target.checked);
      e.target.checked && setIsError(false);
      changeCallback && changeCallback();
    },
    [changeCallback, name, setValue],
  );

  const renderConfirmCheckBox = useCallback(
    () => (
      <CheckBox hasError={isError}>
        <Input
          className="form-check-input mr-1"
          id={name}
          checked={fieldValue || false}
          onChange={handleChange}
          type="checkbox"
        />
        <Label className="form-check-label" htmlFor={name}>
          {textLabel}
        </Label>
      </CheckBox>
    ),
    [fieldValue, handleChange, isError, name, textLabel],
  );

  useEffect(() => {
    setIsError(hasError);
  }, [hasError]);

  return (
    <Controller
      name={name}
      render={renderConfirmCheckBox}
      control={controlField}
      rules={{
        ...rules,
      }}
    />
  );
};
