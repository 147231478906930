import { SEMICOLON_SYMBOL, EQUAL_SYMBOL } from 'modules/core/constants';
import { ICookieOptionalParameters } from 'modules/auth/types';

export const getFromCookies = (name: string): string | undefined => {
  const cookies: Record<string, string> = document.cookie.split(SEMICOLON_SYMBOL).reduce((result, cookie) => {
    const [key, value] = cookie.trim().split(EQUAL_SYMBOL);

    return { ...result, [key]: value };
  }, {});

  return cookies[name];
};

export const setCookies = (name: string, value: string | number, options?: ICookieOptionalParameters): void => {
  document.cookie = `${name}=${value};path=${options?.path};max-age=${options?.maxAge}`;
};
