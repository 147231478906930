import { IProbabilityEstimatorForm } from 'modules/ProbabilityEstimator/types';
import { IFetchingVehicleFlags, IVehicle, IVehicleFiltersDirtyFields } from 'modules/core/types';
import { toProbabilityEstimatorReportData } from 'modules/core/utils';
import { toIncomeCalculatorReportData } from 'modules/core/utils/selectors/incomeReport';
import { TableData, IColumn } from 'modules/core/components/Table/types';
import { createSelector } from '@reduxjs/toolkit';
import { IProbabilityEstimatorState } from 'store/reducers/probabilityEstimator.reducer';
import { IIncomeForm } from 'components/IncomeCalculator/types';

import { RootState } from '../store';
import { getFeesAndTaxesState } from './controls.selector';

export const getProbabilityEstimatorState = (state: RootState): IProbabilityEstimatorState =>
  state.probabilityEstimator;

export const getProbabilityEstimatorInitialValues = (state: RootState): IProbabilityEstimatorForm =>
  getProbabilityEstimatorState(state).initialValues;

export const getIsFetchingVehiclesCount = (state: RootState): boolean =>
  getProbabilityEstimatorState(state).isFetchingVehiclesCount;

export const getVehicleFetchingFlags = (state: RootState): IFetchingVehicleFlags => {
  const { isFetchingVehicles, isFetchingVehiclesFailed, isReset } = getProbabilityEstimatorState(state);

  return { isFetchingVehicles, isFetchingVehiclesFailed, isReset };
};

export const getVehiclesByParams = (state: RootState): IVehicle[] | undefined =>
  getProbabilityEstimatorState(state).vehicles.vehiclesByParams.vehicles;

export const getVehiclesByParamsCount = (state: RootState): number =>
  getProbabilityEstimatorState(state).vehicles.vehiclesByParams.count;

export const getVehiclesByStock = (state: RootState): IVehicle[] | undefined =>
  getProbabilityEstimatorState(state).vehicles.vehiclesByStock;

export const getIncome = (state: RootState): IIncomeForm => getProbabilityEstimatorState(state).income;

export const getSelectedRows = (state: RootState): TableData[] => getProbabilityEstimatorState(state).tableSelectedRows;

export const getIsTableView = (state: RootState): boolean => getProbabilityEstimatorState(state).isTableView;

export const getNotFoundVehicles = (state: RootState): string[] => getProbabilityEstimatorState(state).notFoundVehicles;

export const getNotAvailableVehicles = (state: RootState): string[] =>
  getProbabilityEstimatorState(state).notAvailableVehicles;

export const getVehicleFiltersDirtyFields = (state: RootState): IVehicleFiltersDirtyFields =>
  getProbabilityEstimatorState(state).vehicleFiltersDirtyFields;

export const getTableVisibleColumns = (state: RootState): IColumn[] =>
  getProbabilityEstimatorState(state).tableVisibleColumns;

export const getProbabilityEstimatorReportData = createSelector(
  getProbabilityEstimatorState,
  getFeesAndTaxesState,
  toProbabilityEstimatorReportData,
);

export const getIncomeCalculatorReportData = createSelector(getIncome, toIncomeCalculatorReportData);
