import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Image } from 'react-bootstrap';
import { consumerApi } from 'api';
import { DEFAULT_FADE_TIME, STATIC_IMAGE_URLS } from 'modules/core/constants';
import { FormProvider, useForm } from 'react-hook-form';
import { useDebounceCallback } from 'modules/core/hooks';
import { Button, Dropdown, Input, TelephoneInput } from 'modules/core/components';
import { useTranslation } from 'react-i18next';
import { getDealersThunk } from 'actions/dealerActions';
import { AppDispatch } from 'store/store';
import { getFormattedDealers } from 'store/selectors/dealer.selector';
import { getUser } from 'store/selectors/auth.selector';
import { sendMetric } from 'metrics';

import {
  Content,
  ImageUI,
  CardUI as Card,
  InputGroup,
  ErrorMessageUI as ErrorMessage,
  Title,
  Text,
  CongratulationMessage,
  Info,
} from './styles';
import {
  CREDIT_INTERVIEW_FIELDS,
  CREDIT_INTERVIEW_LABELS,
  FORMAT_NUMBER_MASK,
  POST_CREDIT_INTERVIEW_INFO_ERROR_NAME,
} from './common.const';
import { transformToRequestData } from './utils';
import { PHONE_INPUT_RULES, TEXT_INPUT_RULES } from './rules.const';

export const CreditInterview: FC = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isCongratulationMessageShown, setIsCongratulationMessageShown] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const formMethods = useForm({ mode: 'onChange' });
  const {
    handleSubmit,
    setError,
    watch,
    clearErrors,
    formState: { errors, isValid },
  } = formMethods;
  const dealersList = useSelector(getFormattedDealers);
  const user = useSelector(getUser);
  const defaultValue = user?.dealer?.id;
  const { customerCar, customerName, customerPhone } = watch();

  const congratulationMessage = (
    <CongratulationMessage>
      <Title>{t('components.creditInterview.congratulationTitle')}</Title>
      <Info>{t('components.creditInterview.linkSent')}</Info>
      <Info>{t('components.creditInterview.tryAgain')}</Info>
      <Button
        title={t('components.creditInterview.buttons.sendNewLink')}
        onClick={() => setIsCongratulationMessageShown(false)}
      />
    </CongratulationMessage>
  );

  const onSubmit = useCallback(
    (data) => {
      // eslint-disable-next-line no-param-reassign
      data[CREDIT_INTERVIEW_FIELDS.CUSTOMER_NAME] = data[CREDIT_INTERVIEW_FIELDS.CUSTOMER_NAME].trim();
      try {
        consumerApi
          .postCreditInterviewInfo(transformToRequestData(data))
          .then(() => {
            setIsCongratulationMessageShown(true);
            sendMetric('Link sent');
          })
          .finally(() => setIsLoading(false));
      } catch (e: any) {
        const errorMessage =
          e.response?.data?.detail || e.response?.data?.phone_number || t('common.errorMessages.undefinedError');

        setIsLoading(false);
        setError(POST_CREDIT_INTERVIEW_INFO_ERROR_NAME, { message: errorMessage });
      }
    },
    [setError, t],
  );

  const debounceCallback = useDebounceCallback((data: any) => onSubmit(data), DEFAULT_FADE_TIME);

  const onSubmitDebounced = (data: any) => {
    setIsLoading(true);
    debounceCallback(data);
  };

  useEffect(() => {
    dispatch(getDealersThunk());
  }, [dispatch]);

  useEffect(() => {
    clearErrors(POST_CREDIT_INTERVIEW_INFO_ERROR_NAME);
  }, [clearErrors, customerCar, customerName, customerPhone]);

  return (
    <Content>
      {isCongratulationMessageShown ? (
        congratulationMessage
      ) : (
        <>
          <ImageUI>
            <Image src={STATIC_IMAGE_URLS.icons.car} />
          </ImageUI>
          <Title>{t('components.creditInterview.formTitle')}</Title>
          <FormProvider {...formMethods}>
            <Form onSubmit={handleSubmit(onSubmitDebounced)}>
              <InputGroup>
                <Text>{t('components.creditInterview.selectDealership')}</Text>
                <Dropdown
                  options={dealersList}
                  name={CREDIT_INTERVIEW_FIELDS.CUSTOMER_CAR}
                  defaultValue={defaultValue}
                />
              </InputGroup>
              <Card>
                <InputGroup>
                  <Form.Text>{CREDIT_INTERVIEW_LABELS.CUSTOMER_NAME}</Form.Text>
                  <Input name={CREDIT_INTERVIEW_FIELDS.CUSTOMER_NAME} rules={TEXT_INPUT_RULES} valueAsNumber={false} />
                </InputGroup>
                <InputGroup>
                  <Form.Text>{CREDIT_INTERVIEW_LABELS.CUSTOMER_PHONE}</Form.Text>
                  <TelephoneInput
                    name={CREDIT_INTERVIEW_FIELDS.CUSTOMER_PHONE}
                    rules={PHONE_INPUT_RULES}
                    format={FORMAT_NUMBER_MASK}
                  />
                </InputGroup>
                {errors[POST_CREDIT_INTERVIEW_INFO_ERROR_NAME] && (
                  <ErrorMessage>{errors[POST_CREDIT_INTERVIEW_INFO_ERROR_NAME].message}</ErrorMessage>
                )}
                <Button
                  title={t('components.creditInterview.buttons.sendLink')}
                  type="submit"
                  disabled={!isValid || !customerCar || isLoading}
                />
              </Card>
            </Form>
          </FormProvider>
        </>
      )}
    </Content>
  );
};
