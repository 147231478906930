import styled, { keyframes } from 'styled-components';

export const hide = keyframes`
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0.9;
  }
  80% {
    opacity: 0.9;
  }
  100% {
    opacity: 0;
  }
`;

export const Container = styled.div<{ type?: string }>`
  position: fixed;
  z-index: 1060;
  right: 16px;
  top: 8px;
  background-color: ${(props) => ({ theme: { colors } }) =>
    props.type === 'failed' ? colors['red-3'] : colors['green-3']};
  padding: 16px 32px;
  opacity: 0;
  color: ${({ theme: { colors } }) => colors.white};
  box-shadow: 0px 0px 16px 0px ${({ theme: { colors } }) => colors.white};
  font-size: ${({ theme: { fontSize } }) => fontSize.md};
  border-radius: 5px;
  animation-duration: 7s;
  animation-fill-mode: forwards;
  animation-name: ${hide};
`;
