import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Logo, Container, SecondPartTitle, FirstPartTitle, Title } from './styles';

interface ITitleContainer {
  title: string;
  imgSrc?: string;
}

export const TitleContainer: FC<ITitleContainer> = ({ title, imgSrc }) => {
  const { t } = useTranslation();

  return (
    <Container>
      {imgSrc && <Logo src={imgSrc} alt={`${t('commonPartPageName')} | ${title}`} />}
      <Title>
        <FirstPartTitle>{`${t('commonPartPageName')} | `}</FirstPartTitle>
        <SecondPartTitle>{title}</SecondPartTitle>
      </Title>
    </Container>
  );
};
