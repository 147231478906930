import React, { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { Form, Modal } from 'react-bootstrap';
import { ModalContainer, Title } from 'components/ModalDialog/style';
import { TotalNetDownPaymentPanel } from 'components/Modals/FrontAndBackEndsAddsModal/styles';
import { IField } from 'modules/core/types';
import { ButtonVariant, ColumnWidth, ModalTheme } from 'modules/core/enums';
import { FormRow, MoneyLabel } from 'modules/core/components';
import { produce } from 'immer';

import { SALES_FEES_FIELDS_NAMES, SALES_FEES_FORM_PREFIX } from './consts/fieldNames.const';
import { Panel } from '../styles';
import { ModalButton } from '../../../styles';
import { LOAN_VARIABLES_FORM_PREFIX } from '../../../consts/fieldNames.const';
import { ISalesFeesForm } from './types';
import { DEFAULT_SALES_FEES_VALUES } from './consts/consts';

interface ISalesFeesProps {
  onSalesFeesCalculatorSubmit: (data: ISalesFeesForm) => void;
  show: boolean;
  onHide: () => void;
  title: string;
  modalSize: string;
  totalFieldLabel: string;
  calculationFields: IField[];
  totalWidthField: ColumnWidth;
  totalName: string;
  formValuesName: string;
  uppercaseTitle?: boolean;
  setFormHistory: React.Dispatch<React.SetStateAction<any[]>>;
}

export const SaleFeesCalculator: FC<ISalesFeesProps> = ({
  show,
  onHide,
  title,
  modalSize,
  totalFieldLabel,
  calculationFields,
  totalWidthField,
  onSalesFeesCalculatorSubmit,
  totalName,
  formValuesName,
  uppercaseTitle = true,
  setFormHistory,
}) => {
  const { t } = useTranslation();
  const { setValue: commonSetValue, watch: commonWatch } = useFormContext();
  const loanTermFormValues = commonWatch(LOAN_VARIABLES_FORM_PREFIX);
  const commonSalesFeesForm = loanTermFormValues.salesFeesForm as ISalesFeesForm;
  const formMethods = useForm({
    mode: 'onSubmit',
    shouldUnregister: false,
    defaultValues: { salesFeesForm: commonSalesFeesForm } || DEFAULT_SALES_FEES_VALUES,
  });
  const {
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { isDirty },
  } = formMethods;
  const { salesFeesForm } = getValues();

  const { salesFees, salesDocumentFees, titleRegistrationLicense } = watch(SALES_FEES_FORM_PREFIX) as any;

  const generateFields = useCallback(
    (fields: IField[]) =>
      fields.map(({ name, component: Component, controlProps, label, isVerticalAlign }: IField) => {
        const fieldWidth = controlProps?.fieldWidth ? { fieldWidth: controlProps.fieldWidth as ColumnWidth } : {};

        return (
          <FormRow
            {...fieldWidth}
            key={name}
            required={!!controlProps?.required}
            label={label}
            isVerticalAlign={isVerticalAlign}
            name={name}
            control={<Component {...controlProps} name={name} />}
          />
        );
      }),
    [],
  );

  const calculateTotalFees = useCallback(() => {
    const _salesDocumentFees = salesDocumentFees || 0;
    const _titleRegistrationLicense = titleRegistrationLicense || 0;

    return _salesDocumentFees + _titleRegistrationLicense;
  }, [salesDocumentFees, titleRegistrationLicense]);

  const onSubmit = useCallback(() => {
    const data = {
      salesFees,
      salesDocumentFees,
      titleRegistrationLicense,
    };
    const values = {
      loanVariablesForm: {
        ...loanTermFormValues,
        salesFeesForm: salesFeesForm,
        totalSalesFees: salesFees,
      },
    };

    onSalesFeesCalculatorSubmit(data);
    commonSetValue(totalName, salesFees);
    commonSetValue(formValuesName, salesFeesForm);

    if (isDirty) {
      setFormHistory((prevHistory) =>
        produce(prevHistory, (draft) => {
          draft.push(values);
        }),
      );
    }

    onHide();
  }, [
    salesFees,
    salesDocumentFees,
    titleRegistrationLicense,
    loanTermFormValues,
    salesFeesForm,
    onSalesFeesCalculatorSubmit,
    commonSetValue,
    totalName,
    formValuesName,
    isDirty,
    onHide,
    setFormHistory,
  ]);

  const setInitialValues = useCallback(() => {
    setValue(SALES_FEES_FIELDS_NAMES.TOTAL_FEES, commonSalesFeesForm.salesFees);
    setValue(SALES_FEES_FIELDS_NAMES.SALES_DOCOMENT_FEES, commonSalesFeesForm.salesDocumentFees);
    setValue(SALES_FEES_FIELDS_NAMES.TITLE_REGISTRATION_LICENSE, commonSalesFeesForm.titleRegistrationLicense);
  }, [
    commonSalesFeesForm.salesDocumentFees,
    commonSalesFeesForm.salesFees,
    commonSalesFeesForm.titleRegistrationLicense,
    setValue,
  ]);

  useEffect(() => setValue(SALES_FEES_FIELDS_NAMES.TOTAL_FEES, calculateTotalFees()), [
    calculateTotalFees,
    salesDocumentFees,
    setValue,
    titleRegistrationLicense,
  ]);

  useEffect(() => {
    show && setInitialValues();
  }, [setInitialValues, show]);

  return (
    <FormProvider {...formMethods}>
      <ModalContainer
        show={show}
        onHide={onHide}
        keyboard={false}
        backdrop={false}
        $modalSize={modalSize}
        $modalTheme={ModalTheme.DEFAULT}
        centered
      >
        <Modal.Header closeButton>
          <Title $uppercase={uppercaseTitle}>{title}</Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <TotalNetDownPaymentPanel>
              <FormRow
                fieldWidth={totalWidthField}
                label={totalFieldLabel}
                control={
                  <MoneyLabel isInteger isColored>
                    {salesFees || 0}
                  </MoneyLabel>
                }
              />
            </TotalNetDownPaymentPanel>
            <Panel>{generateFields(calculationFields)}</Panel>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <ModalButton width={'20px'} padding={'6px'} buttontheme={ButtonVariant.OUTLINE} onClick={onHide}>
            {t('common.buttons.cancel')}
          </ModalButton>
          <ModalButton
            width={'20px'}
            padding={'6px'}
            buttontheme={ButtonVariant.CONTAINED}
            onClick={handleSubmit(onSubmit)}
          >
            {t('common.buttons.save')}
          </ModalButton>
        </Modal.Footer>
      </ModalContainer>
    </FormProvider>
  );
};
