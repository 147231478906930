import styled from 'styled-components';

export const AgreementWrapper = styled.div`
  text-align: justify;
  padding: 5px 25px;

  #privacy-policy {
    text-decoration: underline;
    cursor: pointer;
    color: #0d6efd;
  }
`;
