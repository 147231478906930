import i18n from 'i18next';
import {
  FEES_AND_TAXES_INDEX_FIELD,
  FEES_AND_TAXES_INITIAL_VALUES,
  PREFERENCES_INITIAL_VALUES,
} from 'modules/core/shared';
import {
  FILTERS_FIELD_NAMES,
  VEHICLE_FILTERS_INITIAL_VALUES,
} from 'modules/core/components/VehicleFilters/vehicleFilters.const';
import { TableView } from 'modules/core/enums';
import { INCOME_INITIAL_VALUES } from 'components/IncomeCalculator/const/initial.const';

import { IApprovedLoanInput } from './types';
import { getFirstOptionValue } from '../core/utils';
import { SUBVENTION_OPTIONS } from './Summary/subvention.const';
import { FilterView } from '../core/enums/filterView.enum';

export const FIELD_NAMES = {
  APPROVED_MONTHLY_PAYMENT: 'approvedMonthlyPayment',
  APPROVED_LOAN_TERM: 'approvedLoanTerm',
  APPROVED_LTV_RATIO: 'approvedLTVRatio',
  APPROVED_LOAN_VALUE: 'approvedLoanValue',
  APPROVED_INTEREST_RATE: 'approvedInterestRate',
  SUBVENTION: 'subvention',
  SUBVENTION_YEAR: 'subventionYear',
  PRICE_ALLOWED_VALUE: 'priceAllowedValue',
  CASH_DOWN_PAYMENT: 'cashDownPayment',
  TRADE_VALUE: 'tradeValue',
  AMOUNT_OWED: 'amountOwed',
  NET_DOWN_PAYMENT: 'netDownPayment',
  STOCKS: 'stocks',
  FILTER_VIEW: 'filterView',
};

export const APPROVED_LOAN_NAMES_WATCH = [
  FIELD_NAMES.APPROVED_MONTHLY_PAYMENT,
  FIELD_NAMES.APPROVED_INTEREST_RATE,
  FIELD_NAMES.APPROVED_LOAN_TERM,
  FIELD_NAMES.APPROVED_LTV_RATIO,
  FIELD_NAMES.CASH_DOWN_PAYMENT,
  FIELD_NAMES.AMOUNT_OWED,
  FEES_AND_TAXES_INDEX_FIELD,
  FIELD_NAMES.TRADE_VALUE,
];

export const INITIAL_VALUES: IApprovedLoanInput = {
  preferences: PREFERENCES_INITIAL_VALUES,
  feesAndTaxes: FEES_AND_TAXES_INITIAL_VALUES,
  vehicleFilters: VEHICLE_FILTERS_INITIAL_VALUES,
  approvedMonthlyPayment: 0,
  approvedLoanTerm: 0,
  approvedLTVRatio: 0,
  approvedLoanValue: 0,
  approvedInterestRate: undefined,
  cashDownPayment: 0,
  tableView: TableView.SALESPERSON,
  tradeValue: 0,
  amountOwed: 0,
  priceAllowed: 0,
  netDownPayment: 0,
  filterView: FilterView.BY_PARAMS,
  stocks: [],
  vehicleTableAccordion: true,
  subvention: getFirstOptionValue(SUBVENTION_OPTIONS) as string,
  income: { totalIncome: 0, savedIncomes: INCOME_INITIAL_VALUES },
  isRequestNeeded: {
    byParams: true,
    byStock: true,
  },
  condition: 'all',
};

export const VEHICLES_COUNT_FIELDS_SUBSCRIPTION = [
  ...APPROVED_LOAN_NAMES_WATCH,
  FIELD_NAMES.SUBVENTION,
  FIELD_NAMES.SUBVENTION_YEAR,
  FIELD_NAMES.APPROVED_LTV_RATIO,
  FIELD_NAMES.STOCKS,
  FIELD_NAMES.FILTER_VIEW,
  ...Object.values(FILTERS_FIELD_NAMES),
];

export const DEFAULT_PLACEHOLDER_TITLE: string = i18n.t('components.approvedLoanInput.defaultPlaceholderTitle');
