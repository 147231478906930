import i18n from 'i18next';
import { IOption } from 'modules/core/types/select';

import { CATEGORIES, TYPES } from './fieldNames.const';

export const CATEGORY_OPTIONS: IOption[] = [
  {
    title: i18n.t('components.incomeCalculator.categories.w2'),
    value: CATEGORIES.W2,
  },
  {
    title: i18n.t('components.incomeCalculator.categories.nonW2'),
    value: CATEGORIES.NON_W2,
  },
  {
    title: i18n.t('components.incomeCalculator.categories.fixed'),
    value: CATEGORIES.FIXED,
  },
  {
    title: i18n.t('components.incomeCalculator.categories.unemployment'),
    value: CATEGORIES.UNEMPLOYMENT,
  },
  {
    title: i18n.t('components.incomeCalculator.categories.other'),
    value: CATEGORIES.OTHER,
  },
];

export const W2_TYPE_OPTIONS: IOption[] = [
  {
    title: i18n.t('components.incomeCalculator.types.grossYTD'),
    value: TYPES.GROSS_YTD,
  },
  {
    title: i18n.t('components.incomeCalculator.types.basePay'),
    value: TYPES.BASE_PAY,
  },
  {
    title: i18n.t('components.incomeCalculator.types.nysDisability'),
    value: TYPES.NYS_DISABILITY,
  },
  {
    title: i18n.t('components.incomeCalculator.types.earlyYear'),
    value: TYPES.EARLY_YEAR,
  },
];

export const NON_W2_TYPE_OPTIONS: IOption[] = [
  { title: i18n.t('components.incomeCalculator.types.avgFromTaxReturns'), value: TYPES.AVG_FROM_TAX_RETURNS },
  { title: i18n.t('components.incomeCalculator.types.avgFromDeposits'), value: TYPES.AVG_FROM_DEPOSITS },
];

export const FIXED_TYPE_OPTIONS: IOption[] = [
  { title: i18n.t('components.incomeCalculator.types.socialSecurity'), value: TYPES.SOCIAL_SECURITY },
  { title: i18n.t('components.incomeCalculator.types.pensionAndEtc'), value: TYPES.PENSION_AND_ETC },
  { title: i18n.t('components.incomeCalculator.types.disabilityAndEtc'), value: TYPES.DISABILITY_AND_ETC },
];

export const UNEMPLOYMENT_TYPE_OPTIONS: IOption[] = [
  { title: i18n.t('components.incomeCalculator.types.insurance'), value: TYPES.INSURANCE },
];

export const OTHER_TYPE_OPTIONS: IOption[] = [
  { title: i18n.t('components.incomeCalculator.types.nonCalculated'), value: TYPES.NON_CALCULATED },
];

export const RADIO_VALUES = {
  YES: 'Yes',
  NO: 'No',
};
