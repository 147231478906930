import { AxiosResponse } from 'axios';
import { Api } from 'api/api';
import { Endpoints } from 'modules/core/constants';
import { IFeaturesList } from 'modules/core/types';

class FeaturesListApi extends Api {
  getFeaturesList = (subdomain?: string): Promise<AxiosResponse<IFeaturesList>> => {
    const subdomain_param = subdomain ? `?subdomain=${subdomain}` : '';

    return this.api.get(`${Endpoints.getFeaturesList}${subdomain_param}`);
  };
}

export default new FeaturesListApi();
