import { IVehicleFilterField } from '../types';

export const useDisableFilter = (
  filters: IVehicleFilterField[],
  condition: boolean,
  filterName: string,
): IVehicleFilterField[] => {
  const filterIndex = filters.findIndex((filter) => filter.name === filterName);

  if (filterIndex !== -1) {
    filters[filterIndex].controlProps!.disabled = condition;

    return filters;
  }

  return filters;
};
