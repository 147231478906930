import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { MODAL_DIALOG_WIDTH } from 'modules/core/constants';
import { Card } from 'react-bootstrap';
import { FormRow, MoneyLabel, Panel } from 'modules/core/components';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { ModalDialog } from 'components/ModalDialog/ModalDialog';
import { useTranslation } from 'react-i18next';
import { ColumnWidth } from 'modules/core/enums';
import { IProbabilityEstimatorForm } from 'modules/ProbabilityEstimator/types';
import { TextCollapser } from 'components/common/TextCollapser/TextCollapser';
import { produce } from 'immer';
import { LOAN_VARIABLES_FORM_PREFIX } from 'components/Modals/LoanVariablesDialog/consts/fieldNames.const';

import { MONTHLY_INCOME_FIELDS } from './consts/fieldConfig.const';
import { RAC_MONTHLY_INCOME_FIELDS_NAMES, MONTHLY_INCOME_FORM_PREFIX } from './consts/fieldNames.const';
import { TotalIncomePanel } from './styles';
import { ILendersMonthlyIncome } from './consts/types';

const { Body } = Card;

interface INetDownPaymentModal {
  show: boolean;
  onHide: VoidFunction;
  modalName: string;
  totalName: string;
  setFormHistory?: React.Dispatch<React.SetStateAction<any[]>>;
  initialValues: ILendersMonthlyIncome;
  verticallyCentered?: boolean;
}

export const LendersMonthlyIncomeModal: FC<INetDownPaymentModal> = ({
  show,
  onHide,
  setFormHistory,
  modalName,
  totalName,
  initialValues,
  verticallyCentered = false,
}) => {
  const { t } = useTranslation();
  const { setValue: commonSetValue, watch: commonWatch } = useFormContext<IProbabilityEstimatorForm>();
  const loanTermFormValues = commonWatch(LOAN_VARIABLES_FORM_PREFIX) as any;

  const formMethods = useForm({
    mode: 'onSubmit',
    shouldUnregister: false,
    defaultValues: initialValues,
  });
  const {
    handleSubmit,
    getValues,
    setValue,
    watch,
    reset,
    formState: { isDirty },
  } = formMethods;
  const { totalIncome } = watch(MONTHLY_INCOME_FORM_PREFIX);

  const handleSubmitModal = useCallback(() => {
    const values = getValues();

    commonSetValue(modalName, values);
    commonSetValue(totalName, Math.round(values.income.totalIncome));

    if (setFormHistory && isDirty) {
      const data = {
        loanVariablesForm: {
          ...loanTermFormValues,
          monthlyIncomeForm: values,
          monthlyIncome: values.income.totalIncome,
        },
      };

      setFormHistory((prevHistory) =>
        produce(prevHistory, (draft) => {
          draft.push(data);
        }),
      );
    }

    onHide();
  }, [commonSetValue, getValues, isDirty, loanTermFormValues, modalName, onHide, setFormHistory, totalName]);

  const netDownPaymentFields = useMemo(() => {
    const requiredFields = [RAC_MONTHLY_INCOME_FIELDS_NAMES.PRIMARY_MONTHLY_INCOME];

    return MONTHLY_INCOME_FIELDS.map(({ name, label, component: Component, controlProps }) => (
      <FormRow
        fieldWidth={ColumnWidth.M}
        label={label}
        name={name}
        {...(requiredFields.includes(name) && { required: true })}
        control={<Component name={name} {...controlProps} />}
      />
    ));
  }, []);

  const {
    coApplicantMonthlyIncome,
    coApplicantOtherMonthlyIncome,
    primaryMonthlyIncome,
    primaryOtherMonthlyIncome,
  } = getValues().income;

  const calculateTotalIncome = useCallback(() => {
    const totalIncome =
      (coApplicantMonthlyIncome || 0) +
      (coApplicantOtherMonthlyIncome || 0) +
      (primaryMonthlyIncome || 0) +
      (primaryOtherMonthlyIncome || 0);

    setValue(RAC_MONTHLY_INCOME_FIELDS_NAMES.TOTAL_INCOME, totalIncome);
  }, [
    coApplicantMonthlyIncome,
    coApplicantOtherMonthlyIncome,
    primaryMonthlyIncome,
    primaryOtherMonthlyIncome,
    setValue,
  ]);

  useEffect(() => {
    reset(initialValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  useEffect(() => {
    calculateTotalIncome();
  }, [calculateTotalIncome]);

  return (
    <ModalDialog
      title={t('components.racIncomeCalculator.title')}
      submitButtonTitle={t('common.buttons.save')}
      cancelButtonTitle={t('common.buttons.cancel')}
      show={show}
      onHide={onHide}
      onSubmitModal={handleSubmit(handleSubmitModal)}
      modalSize={MODAL_DIALOG_WIDTH.SM}
      verticallyCentered={verticallyCentered}
      upperCaseTitle
    >
      <Body>
        <FormProvider {...formMethods}>
          <TotalIncomePanel>
            <FormRow
              name={RAC_MONTHLY_INCOME_FIELDS_NAMES.TOTAL_INCOME}
              label={t('components.racIncomeCalculator.labels.totalIncome')}
              fieldWidth={ColumnWidth.M}
              control={
                <MoneyLabel isInteger isColored>
                  {totalIncome}
                </MoneyLabel>
              }
            />
          </TotalIncomePanel>

          <Panel>{netDownPaymentFields}</Panel>
          <TextCollapser
            label={t('components.incomeCalculator.lenderIncomePolicy.label')}
            textKey={'components.incomeCalculator.lenderIncomePolicy.text'}
          />
        </FormProvider>
      </Body>
    </ModalDialog>
  );
};
