import { AxiosResponse } from 'axios';
import { Api } from 'api/api';
import { Endpoints } from 'modules/core/constants';
import { ILenderControlsResponse, ILenderRequest, ILendersListItem } from 'modules/Lender/types/Lender.types';

class LenderApi extends Api {
  getLendersList = (subdomain?: string): Promise<AxiosResponse<ILendersListItem[]>> => {
    const subdomain_param = subdomain ? `?subdomain=${subdomain}` : '';

    return this.api.get(`${Endpoints.getLendersList}${subdomain_param}`);
  };

  getLender = (lenderId: number): Promise<AxiosResponse<ILenderRequest>> =>
    this.api.get(`${Endpoints.getLender}${String(lenderId)}/`);

  getLenderControls = (lenderId: number): Promise<AxiosResponse<ILenderControlsResponse>> =>
    this.api.get(`${Endpoints.getLenderControls}${String(lenderId)}/`);
}

export default new LenderApi();
