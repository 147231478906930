import React from 'react';
import { useTranslation } from 'react-i18next';
import { ROUTER_PATH } from 'router/const/routerPath.const';

import { BackToLoginPosition, Link } from '../styles';

export const BackToLoginLink: React.FC = () => {
  const { t } = useTranslation();

  return (
    <BackToLoginPosition>
      <Link href={ROUTER_PATH.LOGIN}>{t('components.login.buttons.backToLogin')}</Link>
    </BackToLoginPosition>
  );
};
