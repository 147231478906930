import { IDealer, IDealerStore } from 'modules/core/types/dealer';
import { IOption } from 'modules/core/types';
import { createSelector } from '@reduxjs/toolkit';
import { IResponseOptionData } from 'modules/CreditInterview/types';
import { toMultiselectOptions } from 'modules/core/utils';

import { RootState } from '../store';
import { IDealerReducerState } from '../reducers/dealer.reducer';

export const getDealerState = (state: RootState): IDealerReducerState => state.dealer;

const getDealers = (state: RootState): IDealer[] => getDealerState(state).dealers;
const getPureDealerStores = (state: RootState): IDealerStore[] => getDealerState(state).pureDealerStores;

export const getDealerStores = (state: RootState): IOption[] => getDealerState(state).dealerStores;

export const getFormattedDealers = createSelector(getDealers, (dealers) =>
  dealers.map(
    ({ id, name }: IResponseOptionData): IOption => ({
      value: id,
      title: name,
    }),
  ),
);

export const getMultiselectDealersOptions = createSelector(getDealers, toMultiselectOptions);
export const getMultiselectDealerStoresOptions = createSelector(getPureDealerStores, toMultiselectOptions);
