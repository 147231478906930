import styled from 'styled-components';
import { Row } from 'react-bootstrap';

import { CheckBox } from '../LoanVariablesDialog/components/common/ConfirmCheckBox/styles';

export const Panel = styled.div<{ isSuccessMessage?: boolean }>`
  padding: ${({ isSuccessMessage }) => (isSuccessMessage ? '50px 95px' : '18px 14px')};
  background-color: ${({ theme: { colors } }) => colors.white};
  border-radius: 4px;
  box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
  display: flex;
  flex-direction: column;

  .row:last-child {
    margin-bottom: 0;
  }

  .row {
    margin-bottom: 16px;
  }
  & > ${CheckBox} {
    margin-top: 2px;
  }
`;

export const ButtonsBlock = styled.div`
  .btn:first-child {
    margin-right: 7px;
  }
`;

export const ConfirmPriceHelpMessage = styled.div`
  font-size: 9px;
  margin-left: 18px;
`;

export const SuccessMessage = styled(Row)`
  padding: 0;
  text-align: center;
`;
