import { reportApi } from 'api';
import { TReport } from 'modules/core/types';
import { ReportType } from 'modules/core/enums';

export class ReportService {
  static async printReport(reportData: TReport, reportType: ReportType): Promise<void> {
    const { data: reportBlob } = await reportApi.postPrintReport(reportData, reportType);

    this.openReport(reportBlob);
  }

  private static openReport(reportBlob: Blob): void {
    const fileURL = URL.createObjectURL(reportBlob);

    window.open(fileURL);
  }
}
