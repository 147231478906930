import React, { useCallback, useState } from 'react';
import { ModalProps } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ModalDialog } from 'components/ModalDialog';
import { DOT_SYMBOL } from 'modules/core/constants';

import { AgreementWrapper } from './styles';
import { END_USER_AGREEMENT_FIRST_PART, END_USER_AGREEMENT_SECOND_PART } from './agreement-modals-content';
import { parseContent } from './agreement-modal.utils';
import { PrivacyPolicy } from './PrivacyPolicy';

interface IEndUserAgreement extends ModalProps {
  onHide: () => void;
}

export const EndUserAgreement: React.FC<IEndUserAgreement> = ({ show, onHide }: IEndUserAgreement) => {
  const { t } = useTranslation();
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);

  const onClickPrivacyPolicy = useCallback(() => {
    onHide();
    setShowPrivacyPolicy(true);
  }, [onHide]);

  return (
    <>
      <ModalDialog
        show={show}
        onHide={onHide}
        title={t('components.endUserAgreement.title')}
        submitButtonTitle={t('common.buttons.ok')}
      >
        <AgreementWrapper>
          {parseContent(END_USER_AGREEMENT_FIRST_PART)}
          <p>
            {t('components.endUserAgreement.privacyPolicyBlock.number')}
            <b>
              <u>{t('components.endUserAgreement.privacyPolicyBlock.label')}</u>
            </b>
            {t('components.endUserAgreement.privacyPolicyBlock.text')}
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a id="privacy-policy" onClick={onClickPrivacyPolicy} target="_top">
              {t('components.endUserAgreement.privacyPolicyBlock.link')}
            </a>
            {DOT_SYMBOL}
          </p>
          {parseContent(END_USER_AGREEMENT_SECOND_PART)}
        </AgreementWrapper>
      </ModalDialog>
      <PrivacyPolicy show={showPrivacyPolicy} onHide={() => setShowPrivacyPolicy(false)} />
    </>
  );
};
