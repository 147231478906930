export const Endpoints = {
  getCsrfToken: 'csrf_token/',
  postLogin: 'login/',
  getUser: 'user/',
  postPasswordReset: 'password-reset/',
  getResetPasswordKey: 'password-reset-check/',
  postConfirmReset: 'password-reset-confirm/',
  postChangePassword: 'password/change/',
  logout: 'logout/',
  getFooterData: 'controls/site_footer_data/',
  getFeesAndTaxesData: 'controls/general_controls/',
  getSubventionYearData: 'controls/loan_terms/',
  getInterestRateList: 'controls/interest_rate/',
  getAdjustEventMessages: 'controls/internal_event_messages/adjust/',
  getVehicleMakes: 'vehicles/makes/',
  getVehicleModels: 'vehicles/models_by_makes/',
  getVehicleBodyTypes: 'vehicles/body_types/',
  getVehiclePhotos: 'vehicles/photos/',
  vehicleAdjustRetail: 'vehicles/adjust/retail/',
  getVehicleAdjustLog: 'vehicles/adjustment-log/',
  getDealers: 'dealers/dealers/',
  getDealersStore: 'dealers/stores/',
  getAllowedDealers: 'dealers/allowed_dealers/',
  getDealersWithManagers: 'dealers/dealers-with-managers/',
  postCreditInterviewInfo: 'consumer/link/send/',
  postUnlockInventory: 'consumer/unlock_inventory/',
  getVehicleBodies: 'vehicles/bodies/',
  getVehicles: 'vehicles/retail/',
  getVehiclesCount: 'vehicles/retail/count/',
  postPrintReport: 'report/',
  sendSearch: 'report/send-search/',
  getControlNumbers: 'controls/number/',
  getFeaturesList: 'features-list/',
  getRACVehicle: 'rac/retail/rac/',
  getRACVehicleByStock: 'rac/retail/rac/used/stock_mode/',
  getTierOptions: 'rac/retail/rac/loan_terms/',
  verification: 'verification-confirm/',
  getLoanVariablesInfo: 'rac/retail/rac/used/loanvariables/',
  getLendersList: 'lenders/lenders/',
  getLender: 'lenders/lender/',
  getLenderVehicles: 'lenders/search_vehicles/',
  getLenderVehiclesByStock: 'lenders/search_vehicles/used/stock_mode/',
  getLenderControls: 'lenders/lender_controls/',
  getLenderLoanVariablesInfo: 'lenders/loanvariables/',
  getLenderLoanTermsList: 'lenders/loan_terms/',
  getDefaultVehiclePhoto: 'vehicles/default_photo/',
  getPassengerCapacities: 'vehicles/passenger_capacities/',
  getLoanTermsList: 'rac/retail/rac/loan_terms_list/',
  getRouteOneData: 'vehicles/r1_credit_decision/',
  getRegionControls: 'region/region_controls/',
  postJDPUsage: 'rac/retail/rac/jdp_usage/',
  getRateSheetsData: 'rac/retail/rac/rate_sheets_data/',
  createRouteOneVehicle: 'vehicles/create_r1_vehicle/',
};
