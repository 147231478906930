import { MODAL_DIALOG_ID, MODAL_DIALOG_WIDTH } from 'modules/core/constants';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Card, Form } from 'react-bootstrap';
import { useModalState } from 'modules/core/hooks';
import { FormRow, MoneyLabel } from 'modules/core/components';
import { useFormContext } from 'react-hook-form';
import { ModalDialog } from 'components/ModalDialog/ModalDialog';
import { useTranslation } from 'react-i18next';
import { IRACAffordabilityEngineForm } from 'modules/RACAffordabilityEngine/types/RACAffordabilityEngineForm';
import {
  APPROVED_LOAN_TERMS_FIELD_NAMES,
  FRONT_AND_BACK_ENDS_ADDS_FIELD_NAMES,
} from 'modules/RACAffordabilityEngine/const/fieldNames.const';
import { useSelector } from 'react-redux';
import { getRegionControls } from 'store/selectors/RACAffordabilityEngine.selector';
import { IGetVscAndGapLimit, IGetVscAndGapLimitResult } from 'modules/core/types';
import { getCurrentLenderControls } from 'store/selectors/lenders.selector';

import { FRONT_AND_BACK_ENDS_ADDS_FIELDS } from './FrontAndBackEndsAdds.const';
import { TotalNetDownPaymentPanel } from './styles';
import { getFrontAndBackEndsAdds } from './service';
import { FormControlFeedback } from '../LoanVariablesDialog/styles';
import { Panel } from '../commonStyles';

const { Body } = Card;

interface IFrontAndBackEndsAddsModal {
  submitCallback: VoidFunction;
  isLender?: boolean;
}

export const FrontAndBackEndsAddsModal: FC<IFrontAndBackEndsAddsModal> = ({ submitCallback, isLender = false }) => {
  const { t } = useTranslation();
  const regionControls = useSelector(getRegionControls);
  const lenderControls = useSelector(getCurrentLenderControls);
  const { watch, setValue, reset, getValues } = useFormContext<IRACAffordabilityEngineForm>();
  const [isVscInvalid, setIsVscInvalid] = useState(false);
  const [isGapInvalid, setIsGapInvalid] = useState(false);
  const { isModalDialogActive, handleCloseDialog } = useModalState<IRACAffordabilityEngineForm>(
    [MODAL_DIALOG_ID.FRONT_AND_BACK_ENDS_ADDS],
    getValues(),
    reset,
  );

  const formValues = watch() as IRACAffordabilityEngineForm;

  const { addOns, vsc, gap, totalFrontAndBackEndsAdds } = formValues.totalFrontAndBackEndsAddsDialog;

  useEffect(() => {
    const frontAndBackEndsAdds = getFrontAndBackEndsAdds(addOns || 0, vsc || 0, gap || 0);

    setValue(FRONT_AND_BACK_ENDS_ADDS_FIELD_NAMES.TOTAL_FRONT_AND_BACK_ENDS_ADDS, frontAndBackEndsAdds);
  }, [addOns, vsc, setValue, gap]);

  const handleSubmitModal = useCallback(() => {
    if (!isVscInvalid && !isGapInvalid) {
      handleCloseDialog()();
      setValue(APPROVED_LOAN_TERMS_FIELD_NAMES.FRONT_AND_BACK_ENDS_ADDS, totalFrontAndBackEndsAdds || 0);
      submitCallback();
    }
  }, [isVscInvalid, isGapInvalid, handleCloseDialog, setValue, totalFrontAndBackEndsAdds, submitCallback]);

  const getVscOrGapLimit = useCallback(
    ({
      fieldName,
      vsc,
      gap,
      t,
      isVscInvalid,
      isGapInvalid,
      regionControls,
      lenderControls,
      setIsVscInvalid,
      setIsGapInvalid,
    }: IGetVscAndGapLimit): IGetVscAndGapLimitResult => {
      let message = '';
      let maxValue = 0;
      let isInvalid = false;
      const vscLimitAbove = isLender ? lenderControls.maxVSC : regionControls.vscLimitAbove;
      const gapLimit = isLender ? lenderControls.maxGap : regionControls.gapLimit;

      if (fieldName === FRONT_AND_BACK_ENDS_ADDS_FIELD_NAMES.VSC && !!vscLimitAbove) {
        maxValue = vscLimitAbove;
        isInvalid = vsc > vscLimitAbove;

        if (isInvalid !== isVscInvalid) setIsVscInvalid(isInvalid);
        message = t('components.RACAffordabilityEngine.vscAndGapLimits.vscLimitMessage', { maxVSC: maxValue });
      }

      if (fieldName === FRONT_AND_BACK_ENDS_ADDS_FIELD_NAMES.GAP && !!gapLimit) {
        maxValue = gapLimit;
        isInvalid = gap > maxValue;

        if (isInvalid !== isGapInvalid) setIsGapInvalid(isInvalid);
        message = t('components.RACAffordabilityEngine.vscAndGapLimits.gapLimitMessage', { maxGap: maxValue });
      }

      return { message, isInvalid };
    },
    [isLender],
  );

  const isDisabled = useCallback(
    (name) => {
      if (name === FRONT_AND_BACK_ENDS_ADDS_FIELD_NAMES.GAP) {
        return isLender ? !lenderControls.maxGap : !regionControls.gapLimit;
      }

      if (name === FRONT_AND_BACK_ENDS_ADDS_FIELD_NAMES.VSC) {
        return isLender ? !lenderControls.maxVSC : false;
      }

      return false;
    },
    [isLender, lenderControls.maxGap, lenderControls.maxVSC, regionControls.gapLimit],
  );

  const frontAndBackEndsAddsFields = useMemo(
    () =>
      FRONT_AND_BACK_ENDS_ADDS_FIELDS.map(({ name, label, component: Component, controlProps }) => {
        const isVscOrGap = [
          FRONT_AND_BACK_ENDS_ADDS_FIELD_NAMES.VSC,
          FRONT_AND_BACK_ENDS_ADDS_FIELD_NAMES.GAP,
        ].includes(name);
        let message = '';
        let isInvalid = false;

        if (isVscOrGap) {
          const { message: vscOrGapMessage, isInvalid: isVscOrGapInvalid } = getVscOrGapLimit({
            fieldName: name,
            vsc,
            gap,
            t,
            isVscInvalid,
            isGapInvalid,
            regionControls,
            lenderControls,
            setIsVscInvalid,
            setIsGapInvalid,
          });

          message = vscOrGapMessage;
          isInvalid = isVscOrGapInvalid;
        }

        return (
          <FormRow
            label={label}
            control={
              <>
                <Component name={name} disabled={isDisabled(name)} isInvalid={isInvalid} {...controlProps} />
                {isVscOrGap && (
                  <FormControlFeedback type="invalid" className="d-block" $isInvalid={isInvalid}>
                    {message}
                  </FormControlFeedback>
                )}
              </>
            }
          />
        );
      }),
    [isDisabled, getVscOrGapLimit, vsc, gap, t, isVscInvalid, isGapInvalid, regionControls, lenderControls],
  );

  return (
    <ModalDialog
      title={t('components.frontAndBackEndsAddsModal.title')}
      submitButtonTitle={t('common.buttons.save')}
      cancelButtonTitle={t('common.buttons.cancel')}
      show={isModalDialogActive}
      onHide={handleCloseDialog(true)}
      onSubmitModal={handleSubmitModal}
      modalSize={MODAL_DIALOG_WIDTH.SM}
      isDisabled={isVscInvalid || isGapInvalid}
      upperCaseTitle
    >
      <Body>
        <Form>
          <TotalNetDownPaymentPanel>
            <FormRow
              label={t('components.frontAndBackEndsAddsModal.totalAdds')}
              control={
                <MoneyLabel isInteger isColored>
                  {totalFrontAndBackEndsAdds || 0}
                </MoneyLabel>
              }
            />
          </TotalNetDownPaymentPanel>
          <Panel>{frontAndBackEndsAddsFields}</Panel>
        </Form>
      </Body>
    </ModalDialog>
  );
};
