import React, { useState } from 'react';
import { Card, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { STATIC_IMAGE_URLS } from 'modules/core/constants';
import { Loader } from 'modules/core/components';
import { getUserInfo } from 'actions/userActions';
import { IUser } from 'modules/core/types';
import { getUser } from 'store/selectors/auth.selector';
import { NavigationService } from 'router/navigation.service';
import { SUBDOMAIN_LS_KEY } from 'modules/auth/constants/auth.const';
import { getFeaturesListThunk } from 'actions/featuresListActions';
import { getLendersListThunk } from 'actions/lenderAction';

import { BackToLoginLink, Button } from '../common';
import {
  LoginContent,
  PositionCard,
  CardForm,
  CardImage,
  CardTitle,
  TextSelectDealer,
  FormPosition,
  FormSelect,
} from '../styles';

const { logo } = STATIC_IMAGE_URLS;

export const SelectDealer: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { handleSubmit } = useForm({
    mode: 'onChange',
  });
  const history = useHistory();
  const user = useSelector(getUser) as IUser;
  const [tenant, setTenant] = useState(user?.tenants[0]?.subdomain);
  const [isFetching, setIsFetching] = useState(false);

  const onSubmit = async () => {
    setIsFetching(true);

    localStorage.setItem(SUBDOMAIN_LS_KEY, tenant);

    const info = { history: history, subdomain: tenant };
    const responseData: any = await getUserInfo(info);
    const featureListResponseData: any = await dispatch(getFeaturesListThunk(tenant));
    const lendersListResponseData: any = await dispatch(getLendersListThunk(tenant));

    const disabledFeaturesList = Object.entries(featureListResponseData.payload)
      .filter(([, is_enabled]) => !is_enabled)
      .map((feature) => feature[0]);

    NavigationService.navigateToDashboard(
      responseData.payload,
      disabledFeaturesList,
      lendersListResponseData.payload,
      tenant,
    );
  };

  const handleSelectChange = (e: any) => setTenant(e.target.value);

  return isFetching ? (
    <Loader />
  ) : (
    <LoginContent>
      <PositionCard>
        <CardForm>
          <CardImage src={logo} />
          <Card.Body>
            <CardTitle>{t('components.selectDealer.title')}</CardTitle>
            <FormPosition onSubmit={handleSubmit(onSubmit)}>
              <TextSelectDealer>{t('components.selectDealer.text')}</TextSelectDealer>
              <FormSelect onChange={handleSelectChange}>
                {user?.tenants?.map((tenant: any) => (
                  <option value={tenant?.subdomain} key={tenant?.subdomain}>
                    {tenant?.name}
                  </option>
                ))}
              </FormSelect>
              <Form.Group className="mt-3">
                <Button label={t('components.selectDealer.continueButton')} />
              </Form.Group>
            </FormPosition>
            {!user?.tenants && <BackToLoginLink />}
          </Card.Body>
        </CardForm>
      </PositionCard>
    </LoginContent>
  );
};
