import { Roles } from 'modules/core/enums';

import { ADMINS, ADMINS_AND_MANAGER, ALL, MANAGER_AND_SALESPERSON } from './roleGroups.const';

export const PERMISSIONS: Record<string, Roles[]> = {
  APPROVED_LOAN_INPUT: ADMINS_AND_MANAGER,
  RAC_AFFORDABILITY_ENGINE: ADMINS_AND_MANAGER,
  LENDER: ADMINS_AND_MANAGER,
  ADMIN_PANEL: ADMINS,
  MANAGER_PANEL: [Roles.MANAGER],
  REPORTS: ADMINS_AND_MANAGER,
  TABLE_VIEW_DROPDOWN: ADMINS_AND_MANAGER,
  TABLE_VIEW_BY_SALESPERSON: ALL,
  TABLE_VIEW_BY_MANAGER: ADMINS_AND_MANAGER,
  TABLE_VIEW_BY_ADMIN: ADMINS_AND_MANAGER,
  ACCOUNT_DIALOG: MANAGER_AND_SALESPERSON,
  REDIRECT_TO_ADMIN_ACCOUNT: ADMINS,
  REDIRECT_TO_ADMIN_PANEL: [Roles.ADMIN],
  REDIRECT_TO_GLOBAL_ADMIN_PANEL: [Roles.GLOBALADMIN],
  REDIRECT_TO_BASE_PAGE_BY_ERROR_PAGE: MANAGER_AND_SALESPERSON,
  REDIRECT_TO_ADMIN_PANEL_BY_ERROR_PAGE: ADMINS,
  ADJUST_VIEW_DEFAULT_SETTINGS: ALL,
  ADJUST_VIEW_CUSTOMER_FRIENDLY_SETTINGS: ADMINS_AND_MANAGER,
  ADDITIONAL_DOWN_PAYMENT_BY_VEHICLE_CARD: [Roles.SALESPERSON],
  DISCOUNTING_TOGGLE: ADMINS_AND_MANAGER,
};
