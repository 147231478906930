import React, { FC, memo, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, SortingRule, usePagination, useRowSelect, useSortBy, useTable } from 'react-table';
import { useTranslation } from 'react-i18next';
import { AppDispatch } from 'store/store';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import { getUser } from 'store/selectors/auth.selector';
import { getIsModalsDisabled } from 'store/selectors/ui.selector';
import { AddVehicleDialog } from 'components/Modals/AddVehicleDialog/AddVehicleDialog';

import { ItemsPerPage } from './ItemsPerPage';
import { TableHeader } from './TableHeader';
import { TableBody } from './TableBody';
import { Pagination } from './Pagination';
import { AdjustDropdown } from './AdjustDropdown';
import { useSelectableColumns } from './plugins';
import { getHiddenColumnsList } from './helper';
import { ITableCallbacks, TableColumn, TableData, ITableSortBy } from './types';
import { TABLE_RENDER_HEADER_KEY, SELECT_ALL_COLUMN_ID, INITIAL_PAGE_SIZE } from './table.const';
import { Panel } from '../Panel/Panel';
import {
  NoVehicleErrorMessage,
  OverflowTableWrapper,
  SelectAllLabel,
  SelectAllSection,
  TableContainerWrapper,
  TableHeaderSection,
  TableItems,
  TableTopSection,
  TableWrapper,
  ToggleCardViewButton,
  ToggleTableViewButton,
  TotalItems,
} from './styles';
import { VehicleCardView } from './VehicleCardView';
import { IRouteOneData } from '../../types/routeOne';
import { Button } from '../Button/Button';
import { ButtonVariant, VehicleTablePlaceholderType } from '../../enums';
import { IRACVehicle } from '../../types';
import { useGenerateRandomVehicleList } from '../../hooks/useGenerateRandomVehicleList';

interface ITable {
  title?: string;
  data: TableData[];
  columns: TableColumn[];
  defaultColumns?: TableColumn[];
  customerFriendlyColumns?: string[];
  pageCount: number;
  fetchRequestCallback?: <C>(pageIndex: number, pageSize: number, sortBy: SortingRule<C>[]) => Promise<void>;
  selectable?: boolean;
  isLoading?: boolean;
  isAdjustViewVisible?: boolean;
  withCardView?: boolean;
  renderAdditionalTableHeaderComponents?: (hiddenColumnsCallback: VoidFunction) => JSX.Element;
  tableCallbacks?: ITableCallbacks;
  children?: ReactNode;
  isTableFiltersDisabled?: boolean;
  isTableActionsDisabled?: boolean;
  isTableView?: boolean;
  setIsTableView?: ActionCreatorWithPayload<boolean, string>;
  tablePlaceholder?: JSX.Element;
  tablePlaceholderType: string;
  tableSortBy?: ITableSortBy[];
  CustomVehicleCard?: any;
  noColoring?: boolean;
  totalCount?: number;
  isServerPagination?: boolean;
  selectedRows?: TableData[];
  skipAutoStateReset?: boolean;
  handleOpenDialog?: () => void;
  isRac?: boolean;
  isLender?: boolean;
  showNoVehicleErrorMessage: boolean;
  showNoVehicleInInventoryErrorMessage: boolean;
  routeOneData: IRouteOneData;
  isMakeItWork: boolean;
  tier?: number;
}

const Table: FC<ITable> = ({
  title,
  data,
  columns,
  customerFriendlyColumns,
  pageCount,
  children,
  fetchRequestCallback,
  renderAdditionalTableHeaderComponents,
  selectable = false,
  isAdjustViewVisible = true,
  withCardView = false,
  tableCallbacks,
  isTableFiltersDisabled,
  isTableActionsDisabled,
  isTableView,
  setIsTableView,
  tablePlaceholder,
  tablePlaceholderType,
  CustomVehicleCard,
  noColoring,
  defaultColumns,
  totalCount,
  isServerPagination = false,
  selectedRows,
  skipAutoStateReset = false,
  handleOpenDialog,
  isRac = false,
  isLender = false,
  showNoVehicleErrorMessage,
  showNoVehicleInInventoryErrorMessage,
  routeOneData,
  isMakeItWork,
  tier,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const isAddVehicleModalDisabled = useSelector(getIsModalsDisabled);
  const user = useSelector(getUser);
  const { isSmallWidth } = useWindowDimensions();
  const [routeOneVehicleRowIndex, setRouteOneVehicleRowIndex] = useState(-1);
  const [showAddVehicleDialog, setShowAddVehicleDialog] = useState<boolean>(false);
  const [sortedRows, setSortedRows] = useState<Row<TableData>[]>([]);

  const {
    setPageSize,
    headerGroups,
    rows,
    prepareRow,
    allColumns,
    setHiddenColumns,
    visibleColumns,
    toggleHideAllColumns,
    selectedFlatRows,
    gotoPage,
    state: { pageIndex, pageSize, sortBy, selectedRowIds },
  } = useTable(
    {
      data,
      columns,
      manualPagination: isServerPagination,
      getRowId: React.useCallback((row, relativeIndex) => (isServerPagination ? row.id : relativeIndex), [
        isServerPagination,
      ]),
      initialState: {
        pageIndex: 0,
        pageSize: INITIAL_PAGE_SIZE,
      },
      pageCount: pageCount,
      autoResetPage: !skipAutoStateReset,
      autoResetSelectedRows: !skipAutoStateReset,
      autoResetSortBy: !skipAutoStateReset,
    },
    useSortBy,
    usePagination,
    useRowSelect,
    useSelectableColumns(selectable, noColoring),
  );

  const countAdditionalRows = INITIAL_PAGE_SIZE - data.length;

  const additionalVehicles = useGenerateRandomVehicleList(countAdditionalRows);

  const { rows: additionalRows } = useTable(
    {
      data: additionalVehicles,
      columns,
    },
    useRowSelect,
    useSelectableColumns(selectable, noColoring),
  );

  const rowsIds = rows.map((row) => row.id).join();

  useEffect(() => {
    setRouteOneVehicleRowIndex(-1);

    if (!!rowsIds) setSortedRows(rows);
    else setSortedRows([]);

    if (isRac && isMakeItWork && Object.keys(routeOneData).length > 0 && routeOneData.vehicle_exists) {
      const routeOneVehicleVin = (routeOneData as IRouteOneData).sale_vehicle.vin;

      if (rows.length && rows.length === 1 && rows[0].original.vin === routeOneVehicleVin)
        setRouteOneVehicleRowIndex(rows[0].index);
      const manuallyCreatedTrue = rows.filter((row) => row.original.manually_created);
      const manuallyCreatedFalse = rows.filter((row) => !row.original.manually_created);

      manuallyCreatedTrue.sort((a, b) => (b.original as IRACVehicle).id - (a.original as IRACVehicle).id);
      const sortedByManuallyCreated = manuallyCreatedTrue.concat(manuallyCreatedFalse);

      sortedByManuallyCreated.sort((a, b) => {
        if (a.original.vin === routeOneVehicleVin) {
          setRouteOneVehicleRowIndex(a.index);

          return -1;
        } else if (b.original.vin === routeOneVehicleVin) {
          setRouteOneVehicleRowIndex(b.index);

          return 1;
        } else return 0;
      });
      setSortedRows(sortedByManuallyCreated);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsIds]);

  const hiddenColumnsCallback = useCallback(() => {
    setHiddenColumns(getHiddenColumnsList(columns));
  }, [setHiddenColumns, columns]);

  const visibleAdjustmentViewColumns = useMemo(() => visibleColumns.filter(({ id }) => id !== SELECT_ALL_COLUMN_ID), [
    visibleColumns,
  ]);

  const selectorColumn = useMemo(() => allColumns.find(({ id }) => id === SELECT_ALL_COLUMN_ID), [allColumns]);

  useEffect(() => {
    selectorColumn?.toggleHidden(!visibleAdjustmentViewColumns.length);
  }, [selectorColumn, visibleAdjustmentViewColumns]);

  useEffect(() => {
    hiddenColumnsCallback();
  }, [hiddenColumnsCallback]);

  // Required to support paginated requests
  useEffect(() => {
    if (fetchRequestCallback) {
      fetchRequestCallback(pageIndex, pageSize, sortBy);
    }
  }, [pageIndex, pageSize, sortBy, fetchRequestCallback]);

  useEffect(() => {
    if (isServerPagination && selectedRows) {
      tableCallbacks?.selectedPaginatedRowsCallback?.(selectedFlatRows, selectedRowIds, selectedRows);
    } else {
      tableCallbacks?.selectedRowsCallback?.(selectedFlatRows);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFlatRows, selectedRowIds, isServerPagination]);

  useEffect(() => {
    tableCallbacks?.visibleColumnsCallback?.(visibleColumns);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibleColumns]);

  useEffect(() => {
    if (setIsTableView && isSmallWidth) dispatch(setIsTableView(false));

    if (setIsTableView && !isSmallWidth) dispatch(setIsTableView(true));
  }, [isSmallWidth, dispatch, setIsTableView]);

  const totalItemsTitle = useMemo(() => {
    const totalItems = totalCount ? totalCount : data.length;
    const selectedItemsCount = totalCount ? Object.keys(selectedRowIds).length : selectedFlatRows.length;

    return selectedItemsCount
      ? t('components.table.selectedItems', { selectedItemsCount, totalItems, count: totalItems })
      : t('components.table.totalVehicles', { totalItems, count: totalItems });
  }, [data.length, selectedRowIds, t, totalCount, selectedFlatRows.length]);

  const isPaginationVisible = useMemo(() => pageCount > 1 && !!visibleColumns.length, [pageCount, visibleColumns]);

  const toggleTableView = useCallback(
    (value: boolean) => () => {
      if (setIsTableView) dispatch(setIsTableView(value));
    },
    [dispatch, setIsTableView],
  );

  const TableHeaderComponent = (
    <TableHeaderSection>
      {isRac && user?.is_sso && (
        <Button
          title={t('addVehicleModal.button')}
          onClick={() => setShowAddVehicleDialog(true)}
          buttonTheme={ButtonVariant.CONTAINED}
          disabled={isAddVehicleModalDisabled}
        />
      )}
      {renderAdditionalTableHeaderComponents?.(hiddenColumnsCallback)}
      {isAdjustViewVisible && isTableView && (
        <AdjustDropdown
          allColumns={allColumns}
          toggleHideAllColumns={toggleHideAllColumns}
          defaultColumns={defaultColumns || []}
          customerFriendlyColumns={customerFriendlyColumns || []}
          isDisabled={!isTableView || isTableActionsDisabled}
          closeDropDown={isTableActionsDisabled}
        />
      )}
    </TableHeaderSection>
  );

  return (
    <>
      <TableContainerWrapper>
        <Panel title={title} headerComponent={TableHeaderComponent}>
          {showNoVehicleErrorMessage && (
            <NoVehicleErrorMessage>{t('components.table.noRouteOneVehicleErrorMessage')}</NoVehicleErrorMessage>
          )}
          {showNoVehicleInInventoryErrorMessage && (
            <NoVehicleErrorMessage>
              {t('components.table.noRouteOneVehicleInInventoryErrorMessage')}
            </NoVehicleErrorMessage>
          )}
          <TableTopSection disabled={isTableFiltersDisabled}>
            {children}
            <TableItems>
              {!isTableView && selectable && (
                <SelectAllSection>
                  {headerGroups[0]?.headers[0]?.render(TABLE_RENDER_HEADER_KEY)}
                  <SelectAllLabel>{t('components.table.selectAll')}</SelectAllLabel>
                </SelectAllSection>
              )}
              <TotalItems>{totalItemsTitle}</TotalItems>
              {withCardView && isTableView !== undefined && (
                <>
                  {isRac && !user?.isWithInventory ? (
                    <></>
                  ) : (
                    <ToggleCardViewButton
                      selected={!isTableView}
                      onClick={toggleTableView(false)}
                      type="button"
                      data-testId="card-view"
                    />
                  )}
                  <ToggleTableViewButton
                    selected={isTableView}
                    onClick={toggleTableView(true)}
                    type="button"
                    data-testId="table-view"
                  />
                </>
              )}
              <ItemsPerPage setPageSize={setPageSize} currentPageSize={pageSize} gotoPage={gotoPage} />
            </TableItems>
          </TableTopSection>
          {isTableView ? (
            <OverflowTableWrapper>
              <TableWrapper striped bordered isRac={isRac} isLender={isLender}>
                <TableHeader headerGroups={headerGroups} />
                <TableBody
                  handleOpenDialog={handleOpenDialog}
                  prepareRow={prepareRow}
                  rows={sortedRows}
                  additionalRows={tablePlaceholderType === VehicleTablePlaceholderType.LOADING ? [] : additionalRows}
                  pageSize={pageSize}
                  currentPage={pageIndex}
                  noColoring={noColoring}
                  isServerPagination={isServerPagination}
                  isMakeItWork={isMakeItWork}
                  routeOneVehicleRowIndex={routeOneVehicleRowIndex}
                  isRac={isRac}
                  isLender={isLender}
                />
              </TableWrapper>
            </OverflowTableWrapper>
          ) : (
            <VehicleCardView
              prepareRow={prepareRow}
              rows={sortedRows}
              pageSize={pageSize}
              currentPage={pageIndex}
              CustomVehicleCard={CustomVehicleCard}
              isServerPagination={isServerPagination}
            />
          )}
          {(user?.isWithInventory || tablePlaceholderType === VehicleTablePlaceholderType.LOADING) && tablePlaceholder}
          {isPaginationVisible && <Pagination gotoPage={gotoPage} pageCount={pageCount} currentPage={pageIndex} />}
        </Panel>
      </TableContainerWrapper>
      {isRac && user?.is_sso && (
        <AddVehicleDialog
          tier={tier as number}
          show={showAddVehicleDialog}
          onHide={() => setShowAddVehicleDialog(false)}
        />
      )}
    </>
  );
};

export const TableMemo = memo(Table);
