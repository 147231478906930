import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'store/store';
import { clearNotification } from 'store/reducers/ui.reducer';
import { getNotification } from 'store/selectors/ui.selector';

import { Container } from './styles';
import { NOTIFICATION_DURATION_TIME } from './common.const';

export const Notification: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const notification = useSelector(getNotification);

  useEffect(() => {
    if (notification) {
      setTimeout(() => {
        dispatch(clearNotification());
      }, NOTIFICATION_DURATION_TIME);
    }
  }, [dispatch, notification]);

  return notification && <Container type={notification?.type}>{notification?.message}</Container>;
};
