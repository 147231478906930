import React from 'react';

import { PreloaderPosition, ImgSize } from './styles';

const spinnerPath = './preloader';

const Preloader: React.FC = () => (
  <PreloaderPosition>
    <ImgSize alt="spinner" src={spinnerPath} />
  </PreloaderPosition>
);

export default Preloader;
