import i18n from 'i18next';
import { Cell } from 'react-table';
import { TableColumn } from 'modules/core/components/Table/types';
import { ILTVValues } from 'modules/core/types';
import { costFormatter, percentFormatter } from 'modules/core/utils';

import { LOAN_VARIABLES_FIELDS_NAMES } from './consts/fieldNames.const';

export const LOAN_VARIABLES_MODAL_FIELDS = {
  MAX_PRICE: 'max_price',
  F_E_ADVANCE: 'additional_front_end_advance_at_list_price',
  VSC_SALES: 'vsc_sales',
  PAYMENT_TERMS: 'payments',
  F_I_FLAT: 'f_and_i_flat',
  PROFIT: 'profit',
  LTV: 'category',
  APR: 'rate',
  EXTRA_CASH: 'extra_down_payment',
  DISCOUNT_AND_PROCESSING_FEES: 'discount_and_processing_fees',
  DISCOUNT: 'discount',
  TOTAL_LTV: 'total_ltv',
  LTV_INC: 'ltv_inc',
  LTV_EXC: 'ltv_exc',
};

export const COMMON_COLUMNS_NAMES = {
  startEmptyColumn: 'startEmptyColumn',
  endEmptyColumn: 'endEmptyColumn',
};

export const LOAN_VARIABLES_MAX_PRICE_COLUMN = {
  Header: i18n.t('components.loanTermTable.columns.maxPrice'),
  accessor: LOAN_VARIABLES_MODAL_FIELDS.MAX_PRICE,
  id: LOAN_VARIABLES_MODAL_FIELDS.MAX_PRICE,
  isVisible: true,
  Cell: (cell: Cell<ILTVValues>): string => costFormatter(cell.row.original.max_price),
};

export const LOAN_VARIABLES_EXTRA_CASH_OR_DISCOUNT_COLUMN = {
  Header: i18n.t('components.loanTermTable.columns.extraCashOrDiscount'),
  accessor: LOAN_VARIABLES_MODAL_FIELDS.EXTRA_CASH,
  id: LOAN_VARIABLES_MODAL_FIELDS.EXTRA_CASH,
  isVisible: true,
  Cell: (cell: Cell<ILTVValues>): string => costFormatter(cell.row.original.extra_down_payment),
};

export const LOAN_VARIABLES_COLUMNS = [
  {
    Header: i18n.t('components.loanTermTable.columns.ltvExcTaxFees'),
    accessor: LOAN_VARIABLES_MODAL_FIELDS.LTV,
    id: LOAN_VARIABLES_MODAL_FIELDS.LTV,
    isVisible: true,
    Cell: (cell: Cell<ILTVValues>): string => cell.row.original.category.replace(' LTV', ''),
  },
  {
    Header: i18n.t('components.loanTermTable.columns.apr'),
    accessor: LOAN_VARIABLES_MODAL_FIELDS.APR,
    id: LOAN_VARIABLES_MODAL_FIELDS.APR,
    isVisible: true,
    Cell: (cell: Cell<ILTVValues>): string => percentFormatter(cell.row.original.rate),
  },
  {
    Header: i18n.t('components.loanTermTable.columns.payment'),
    accessor: LOAN_VARIABLES_MODAL_FIELDS.PAYMENT_TERMS,
    id: LOAN_VARIABLES_MODAL_FIELDS.PAYMENT_TERMS,
    isVisible: true,
    Cell: (cell: Cell<ILTVValues>): string => costFormatter(cell.row.original.payments),
  },
  {
    Header: i18n.t('components.loanTermTable.columns.profit'),
    accessor: LOAN_VARIABLES_MODAL_FIELDS.PROFIT,
    id: LOAN_VARIABLES_MODAL_FIELDS.PROFIT,
    isVisible: true,
    Cell: (cell: Cell<ILTVValues>): string => costFormatter(cell.row.original.profit),
  },
  {
    Header: i18n.t('components.loanTermTable.columns.fAndIFlat'),
    accessor: LOAN_VARIABLES_MODAL_FIELDS.F_I_FLAT,
    id: LOAN_VARIABLES_MODAL_FIELDS.F_I_FLAT,
    isVisible: true,
    Cell: (cell: Cell<ILTVValues>): string => costFormatter(cell.row.original.f_and_i_flat),
  },
  {
    Header: i18n.t('components.loanTermTable.columns.discountAndProcessingFees'),
    accessor: LOAN_VARIABLES_MODAL_FIELDS.DISCOUNT_AND_PROCESSING_FEES,
    id: LOAN_VARIABLES_MODAL_FIELDS.DISCOUNT_AND_PROCESSING_FEES,
    isVisible: true,
    Cell: (cell: Cell<ILTVValues>): string => costFormatter(cell.row.original.discount_and_processing_fees),
  },
];

export const START_EMPTY_COLUMN = {
  Header: '',
  accessor: '',
  id: COMMON_COLUMNS_NAMES.startEmptyColumn,
  isVisible: true,
};

export const END_EMPTY_COLUMN = {
  Header: '',
  accessor: '',
  id: COMMON_COLUMNS_NAMES.endEmptyColumn,
  isVisible: true,
};

export const RETAIL_PRICE_LOAN_VARIABLES_COLUMNS = [
  START_EMPTY_COLUMN,
  LOAN_VARIABLES_EXTRA_CASH_OR_DISCOUNT_COLUMN,
  ...LOAN_VARIABLES_COLUMNS,
  END_EMPTY_COLUMN,
];

export const ADJUSTED_PRICE_LOAN_VARIABLES_COLUMNS = [
  START_EMPTY_COLUMN,
  LOAN_VARIABLES_MAX_PRICE_COLUMN,
  ...LOAN_VARIABLES_COLUMNS,
  END_EMPTY_COLUMN,
];

export const LOAN_VARIABLES_COLUMNS_MAP = new Map<string, TableColumn[]>([
  [LOAN_VARIABLES_FIELDS_NAMES.RETAIL_PRICE, RETAIL_PRICE_LOAN_VARIABLES_COLUMNS],
  [LOAN_VARIABLES_FIELDS_NAMES.ADJUSTED_PRICE, ADJUSTED_PRICE_LOAN_VARIABLES_COLUMNS],
]);

export const LENDER_LOAN_VARIABLES_EXTRA_CASH_OR_DISCOUNT_COLUMN = {
  Header: i18n.t('components.loanTermTable.columns.extraCashOrDiscount'),
  accessor: 'extra_cash_or_discount',
  id: 'extra_cash_or_discount',
  isVisible: true,
  Cell: (cell: Cell<ILTVValues>): string => costFormatter(cell.row.original.extra_cash_or_discount as number),
};

export const LENDER_LOAN_VARIABLES_LTV_INC = {
  Header: i18n.t('components.loanTermTable.columns.ltvIncTaxFees'),
  accessor: LOAN_VARIABLES_MODAL_FIELDS.LTV_INC,
  id: LOAN_VARIABLES_MODAL_FIELDS.LTV_INC,
  isVisible: true,
  Cell: (cell: Cell<ILTVValues>): string => percentFormatter(cell.row.original.f_e_ltv_inc as number),
};

export const LENDER_LOAN_VARIABLES_LTV_EXC = {
  Header: i18n.t('components.loanTermTable.columns.ltvExcTaxFees'),
  accessor: LOAN_VARIABLES_MODAL_FIELDS.LTV_EXC,
  id: LOAN_VARIABLES_MODAL_FIELDS.LTV_EXC,
  isVisible: true,
  Cell: (cell: Cell<ILTVValues>): string => percentFormatter(cell.row.original.f_e_ltv_exc as number),
};

export const LENDER_LOAN_VARIABLES_CUSTOMER_RATE = {
  Header: i18n.t('components.loanTermTable.columns.customerRate'),
  accessor: 'customer_rate',
  id: 'customer_rate',
  isVisible: true,
  Cell: (cell: Cell<ILTVValues>): string => percentFormatter(cell.row.original.customer_rate as number),
};

export const LENDER_LOAN_VARIABLES_ESTIMATED_PAYMENT = {
  Header: i18n.t('components.loanTermTable.columns.estimatedPayment'),
  accessor: 'monthly_payment',
  id: 'monthly_payment',
  isVisible: true,
  Cell: (cell: Cell<ILTVValues>): string => costFormatter(cell.row.original.monthly_payment as number),
};

export const LENDER_LOAN_VARIABLES_PROFIT = {
  Header: i18n.t('components.loanTermTable.columns.profit'),
  accessor: 'profit',
  id: 'profit',
  isVisible: true,
  Cell: (cell: Cell<ILTVValues>): string => costFormatter(cell.row.original.profit as number),
};

export const LENDER_LOAN_VARIABLES_TOTAL_LTV = {
  Header: i18n.t('components.loanTermTable.columns.totalLTV'),
  accessor: LOAN_VARIABLES_MODAL_FIELDS.TOTAL_LTV,
  id: LOAN_VARIABLES_MODAL_FIELDS.TOTAL_LTV,
  isVisible: true,
  Cell: (cell: Cell<ILTVValues>): string => percentFormatter(cell.row.original.ltv_ratio as number),
};

export const LENDER_LOAN_VARIABELS_COLUMNS = [
  START_EMPTY_COLUMN,
  LENDER_LOAN_VARIABLES_EXTRA_CASH_OR_DISCOUNT_COLUMN,
  LENDER_LOAN_VARIABLES_LTV_INC,
  LENDER_LOAN_VARIABLES_LTV_EXC,
  LENDER_LOAN_VARIABLES_CUSTOMER_RATE,
  LENDER_LOAN_VARIABLES_ESTIMATED_PAYMENT,
  LENDER_LOAN_VARIABLES_PROFIT,
  LENDER_LOAN_VARIABLES_TOTAL_LTV,
  END_EMPTY_COLUMN,
];
