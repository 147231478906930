import i18n from 'i18n';
import { reportApi } from 'api';

import { transformFormData as transformSalespersonFormData } from './sendSalespersonReportForm/utils';
import { IDialogContent } from './types';

const { postSendSearch } = reportApi;

export const DIALOG_CONTENT: IDialogContent = {
  dialogTitle: i18n.t('components.sendReportDialog.titles.saveAndSend'),
  dialogHeaderTitle: i18n.t('components.sendReportDialog.notifications.enterConsumersData'),
  transformFormDataCallback: transformSalespersonFormData,
  sendReportApiRequest: postSendSearch,
};
