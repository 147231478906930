import styled from 'styled-components';

import { COLORS } from '../../theme/colors';

export const TagInput = styled.div`
  align-items: center;
  display: flex;
  border: 1px solid ${COLORS.GREY_22};
  border-radius: 6px;
  gap: 8px;
  line-height: 1.4;
  padding: 8px;
  flex-wrap: wrap-reverse;

  * {
    box-sizing: border-box;
    transition: all 0.2s ease;
  }

  &:focus-within {
    border-color: ${COLORS.BLUE_10};
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
  }
`;

export const TagItem = styled.span`
  background: ${COLORS.WHITE_10};
  border-radius: 6px;
  padding-left: 8px;
  align-items: center;
  display: inline-flex;
  justify-content: center;

  button {
    background: none;
    border: 0;
    border-radius: 50%;
    cursor: pointer;
    line-height: inherit;
    padding: 8px 8px;
    color: ${COLORS.GREY_23};
  }
}
`;

export const Input = styled.input`
  min-width: 30%;
  border: 0;
  outline: 0;
  font-size: inherit;
  line-height: inherit;
`;
