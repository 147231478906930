import React, { FC, ReactNode, useCallback, useMemo, useState } from 'react';
import { Form } from 'react-bootstrap';
import NumberFormat, { NumberFormatProps, NumberFormatValues } from 'react-number-format';
import { useUncontrolledInput } from 'modules/core/hooks';
import { Controller, ControllerRenderProps } from 'react-hook-form';
import { IFormField } from 'modules/core/types/form';
import { ASTERISK, EMPTY_STRING, SLASH_SYMBOL } from 'modules/core/constants';

import { Adornment, Input as InputUI, InputFontWeight } from './styles';
import { TUnderlyingHTMLTextElement } from './types';

const { Control: FormControl } = Form;

type ChangeNumberInput = Pick<ControllerRenderProps, 'onChange'>['onChange'];

type InputNumberProps = Pick<NumberFormatProps, 'thousandSeparator' | 'decimalScale'>;

export interface IInputAdornment {
  endAdornment?: ReactNode;
  startAdornment?: ReactNode;
  endAdornmentOnClick?: VoidFunction;
}

export interface IInput extends IFormField, IInputAdornment, InputNumberProps {
  placeholder?: string;
  textPosition?: 'end' | 'start';
  textStyle?: InputFontWeight;
  valueAsNumber?: boolean;
  disabled?: boolean;
  format?: string;
  allowEmptyFormatting?: boolean;
  allowNegative?: boolean;
  type?: string;
  as?: TUnderlyingHTMLTextElement;
  changeCallback?: (event?: any) => void;
  clickCallback?: VoidFunction;
  blurCallback?: (() => void) | undefined;
  testId?: string;
  required?: boolean;
  readonly?: boolean;
  autoComplete?: boolean;
  hideInitZeroValue?: boolean;
  isInvalid?: boolean;
  maxLength?: number;
  className?: string;
  isAllowTypeSpace?: boolean;
}

export const Input: FC<IInput> = ({
  name,
  rules = {},
  startAdornment: StartAdornment,
  endAdornment: EndAdornment,
  endAdornmentOnClick,
  placeholder,
  validate,
  valueAsNumber = true,
  textPosition = 'start',
  textStyle = 'normal',
  disabled = false,
  thousandSeparator = true,
  format,
  allowEmptyFormatting = false,
  allowNegative = false,
  type = 'text',
  as = 'input',
  maxLength = undefined,
  defaultValue,
  changeCallback,
  clickCallback,
  blurCallback,
  decimalScale,
  testId,
  required,
  readonly,
  autoComplete = false,
  hideInitZeroValue = false,
  isInvalid = false,
  isAllowTypeSpace = true,
  className,
}) => {
  const { hasError, errorMessage, controlField, registerInput } = useUncontrolledInput(name);
  const [isZeroEntered, setIsZeroEntered] = useState(false);

  const processedPlaceholder = required ? `${placeholder}${ASTERISK}` : placeholder;

  const commonInputProps = useMemo(
    () => ({
      placeholder: processedPlaceholder,
      name,
      isInvalid: isInvalid ? isInvalid : Boolean(errorMessage) || hasError,
      disabled,
    }),
    [disabled, errorMessage, hasError, isInvalid, name, processedPlaceholder],
  );

  const renderAdornment = useCallback(
    (AdornmentComponent: ReactNode, adornmentOnClick?: VoidFunction, isCursorPointer?: boolean) => (
      <Adornment isCursorPointer={isCursorPointer} onClick={adornmentOnClick}>
        {AdornmentComponent}
      </Adornment>
    ),
    [],
  );

  const handleNumberInputChange = useCallback(
    (onChange: ChangeNumberInput) => ({ value }: NumberFormatValues) => {
      onChange(hideInitZeroValue && value === EMPTY_STRING ? 0 : value);
      setIsZeroEntered(value === '0');
    },
    [hideInitZeroValue],
  );

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    // Check if the key pressed is space (key code 32)
    if (event.keyCode === 32) {
      event.preventDefault();
    }
  };

  const renderNumberInput = useCallback(
    ({ onChange, onBlur, value }: ControllerRenderProps) => (
      <NumberFormat
        {...commonInputProps}
        autoComplete={autoComplete ? 'on' : 'off'}
        customInput={FormControl}
        thousandSeparator={thousandSeparator}
        onValueChange={handleNumberInputChange(onChange)}
        onChange={changeCallback}
        onClick={clickCallback}
        onBlur={blurCallback || onBlur}
        prefix={EMPTY_STRING}
        value={value === 0 && hideInitZeroValue && !isZeroEntered ? EMPTY_STRING : value}
        format={format}
        allowEmptyFormatting={allowEmptyFormatting}
        allowNegative={allowNegative}
        decimalScale={decimalScale}
        placeholder={placeholder}
        readOnly={readonly}
        maxLength={maxLength}
      />
    ),
    [
      commonInputProps,
      autoComplete,
      thousandSeparator,
      handleNumberInputChange,
      changeCallback,
      clickCallback,
      blurCallback,
      hideInitZeroValue,
      format,
      allowEmptyFormatting,
      allowNegative,
      decimalScale,
      placeholder,
      readonly,
      maxLength,
      isZeroEntered,
    ],
  );

  const renderTextField = useCallback(
    () => (
      <FormControl
        {...commonInputProps}
        autoComplete={autoComplete ? 'on' : 'off'}
        ref={registerInput(rules)}
        type={type}
        as={as}
        onChange={changeCallback}
        maxLength={maxLength}
        onKeyDown={!isAllowTypeSpace ? handleKeyDown : undefined}
      />
    ),
    [commonInputProps, autoComplete, registerInput, rules, type, as, changeCallback, maxLength, isAllowTypeSpace],
  );

  const parsedErrorMessage = useMemo(
    () => errorMessage?.split(SLASH_SYMBOL)?.map((error: string) => error && <div>{error}</div>),
    [errorMessage],
  );

  return (
    <InputUI
      className={className}
      textposition={textPosition}
      textstyle={textStyle}
      data-testid={testId}
      isInvalid={isInvalid}
      endAdornment={Boolean(EndAdornment)}
      startAdornment={Boolean(StartAdornment)}
      isClickCallback={clickCallback}
      readonly={readonly}
    >
      {StartAdornment && renderAdornment(StartAdornment)}
      <Controller
        render={valueAsNumber ? renderNumberInput : renderTextField}
        defaultValue={defaultValue || EMPTY_STRING}
        control={controlField}
        name={name}
        rules={{
          ...rules,
          validate,
          valueAsNumber,
        }}
      />
      {EndAdornment && renderAdornment(EndAdornment, endAdornmentOnClick, !!endAdornmentOnClick)}
      <FormControl.Feedback type="invalid">{parsedErrorMessage}</FormControl.Feedback>
    </InputUI>
  );
};
