import styled from 'styled-components';
import { COLORS } from 'modules/core/theme/colors';

export const BlockWrapper = styled.div`
  padding: 0;
  margin-top: 10px;
`;

export const Button = styled.button`
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 0;
  color: ${COLORS.GREY_24};
  background-color: ${COLORS.GREY_10};
  font-weight: bold;
  font-size: 14px;
`;

export const Triangle = styled.div<{ isOpen: boolean }>`
  width: 0;
  height: 0;
  border-left: ${({ isOpen }) => (isOpen ? '5px solid transparent' : `10px solid ${COLORS.GREY_24}`)};
  border-right: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-top: ${({ isOpen }) => (isOpen ? `10px solid ${COLORS.GREY_24}` : '5px solid transparent')};
  margin-right: ${({ isOpen }) => (isOpen ? '15px' : '10px')};
  margin-top: ${({ isOpen }) => (isOpen ? '4px' : '0')};
`;

export const Text = styled.p<{ isOpen: boolean }>`
  margin-top: 3px;
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
`;

export const TextWrapper = styled.div`
  color: ${COLORS.GREY_24};
  font-size: 12px;
`;
