import { Roles } from 'modules/core/enums';
import { PERMISSIONS } from 'modules/PermissionWrapper/permissions.const';

import { ROUTER_PATH } from './routerPath.const';

export const ROUTES_PERMISSIONS_MAP = new Map<string, Roles[]>([
  [ROUTER_PATH.APPROVED_LOAN_INPUT, PERMISSIONS.APPROVED_LOAN_INPUT],
  [ROUTER_PATH.RAC_AFFORDABILITY_ENGINE, PERMISSIONS.RAC_AFFORDABILITY_ENGINE],
  [ROUTER_PATH.LENDER, PERMISSIONS.LENDER],
]);

export const ROUTER_NAME = {
  PROBABILITY_ESTIMATOR: 'probability_estimator',
  APPROVED_LOAN_INPUT: 'approved_loan_input',
  RAC_AFFORDABILITY_ENGINE: 'rac',
  LENDER: 'lender',
  CREDIT_INTERVIEW: 'credit_interview',
  REPORTS: 'reports',
  ADJUSTMENT_MODAL_VIEW: 'adjust_model_view',
  SELECT_DEALER: 'select-dealer',
};
