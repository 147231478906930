import React, { FC } from 'react';
import { vinFormatter } from 'modules/core/utils';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { VINSpan } from 'common/styles';
import { useTranslation } from 'react-i18next';
import { showNotification } from 'store/reducers/ui.reducer';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'store/store';
import { getUser } from 'store/selectors/auth.selector';

import { Badge } from '../../Badge';
import { VEHICLE_STATUS_BADGE_PROPS } from '../../Badge/common.const';

interface IVINCell {
  vin: string;
  isStubbed?: boolean;
}

export const VINCell: FC<IVINCell> = ({ vin, isStubbed = false }) => {
  const dispatch = useDispatch<AppDispatch>();
  const user = useSelector(getUser);
  const { t } = useTranslation();

  return (
    <>
      {isStubbed ? (
        <span>{user?.is_sso && user?.isWithInventory ? vinFormatter(vin) : vin}</span>
      ) : (
        <CopyToClipboard
          text={vin}
          onCopy={() => dispatch(showNotification({ message: t('successfullyCopied'), type: 'successful' }))}
        >
          <VINSpan title={t('components.table.vinHelpMessage')}>
            {user?.is_sso && user?.isWithInventory ? vinFormatter(vin) : vin}
          </VINSpan>
        </CopyToClipboard>
      )}
      {user?.is_sso && !user?.isWithInventory && (
        <>
          <br />
          <Badge className="make-it-work-badge" {...VEHICLE_STATUS_BADGE_PROPS.MAKE_IT_WORK} />
        </>
      )}
    </>
  );
};
