import React, { FC, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { getUser } from 'store/selectors/auth.selector';
import { Roles } from 'modules/core/enums';

interface IPermissionWrapper {
  allowedRoles: Roles[];
  children: ReactNode;
}

export const PermissionWrapper: FC<IPermissionWrapper> = ({ children, allowedRoles }) => {
  const user = useSelector(getUser);
  const isRoleAllowed = user?.role && allowedRoles.includes(user.role);

  return <>{isRoleAllowed && children}</>;
};
