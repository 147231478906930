import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { STATIC_IMAGE_URLS } from 'modules/core/constants';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getLenderState } from 'store/selectors/lenders.selector';

import { COMMON_POSTFIX, LENDERS, TAB_CONTENT_MAP, TAB_CONTENT_TITLES } from './common.const';

export const TabContent: FC = () => {
  const { pathname } = useLocation();
  const lenderName = useSelector(getLenderState)?.name;

  const title = pathname?.includes(LENDERS)
    ? `${lenderName} ${COMMON_POSTFIX}`
    : TAB_CONTENT_MAP.get(pathname) || TAB_CONTENT_TITLES.STREAMLINE;

  return (
    <Helmet>
      <link rel="shortcut icon" href={STATIC_IMAGE_URLS.favicon} type="image/x-icon" />
      <title>{title}</title>
    </Helmet>
  );
};
