import { AxiosResponse } from 'axios';
import { Endpoints } from 'modules/core/constants';
import { ILogout, IChangePasswordFormValues, ILoginFormValues } from 'modules/auth/auth';
import { IUserPayload, IResponseStatus } from 'modules/core/types';

import { Api } from './api';

// This constant is required due to the backend '/login' endpoint mechanism
const USER_SESSION_ROLE = 'manager';

class AuthApi extends Api {
  getScrfToken = (): Promise<AxiosResponse<void>> => this.api.get(Endpoints.getCsrfToken);

  login = ({ email, password, keepLogged, tenant }: ILoginFormValues): Promise<AxiosResponse<void>> =>
    this.api.post(Endpoints.postLogin, {
      username: email,
      password,
      keep_logged: keepLogged,
      session_role: USER_SESSION_ROLE,
      tenant: tenant,
    });

  getUser = (subdomain: string | undefined = undefined): Promise<AxiosResponse<IUserPayload>> => {
    const subdomain_param = subdomain ? `?subdomain=${subdomain}` : '';

    return this.api.get(`${Endpoints.getUser}${subdomain_param}`);
  };

  logout = (): Promise<AxiosResponse<ILogout>> => this.api.post(Endpoints.logout);

  // TODO: add return type
  resetPassword = (email: string) => this.api.post(Endpoints.postPasswordReset, { email });

  getResetPasswordKeyCheck = (key: string): Promise<AxiosResponse<void>> =>
    this.api.get(`${Endpoints.getResetPasswordKey}${key}`);

  // TODO: add return type
  confirmPasswordReset = (data: IChangePasswordFormValues) => this.api.post(Endpoints.postConfirmReset, data);

  verificationPassword = (data: IChangePasswordFormValues) => this.api.post(Endpoints.verification, data);

  changePassword = (data: Record<string, unknown>): Promise<AxiosResponse<IResponseStatus>> =>
    this.api.post(Endpoints.postChangePassword, data);
}

export default new AuthApi();
