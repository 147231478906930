import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IProbabilityEstimatorForm } from 'modules/ProbabilityEstimator/types';
import { INITIAL_VALUES } from 'modules/ProbabilityEstimator/probabilityEstimator.const';
import {
  getVehiclesByStockForProbabilityEstimatorThunk,
  getVehiclesForProbabilityEstimatorThunk,
} from 'actions/vehicleActions';
import { IPaginatedVehicles, IVehicle, IVehicleByStock, IVehicleFiltersDirtyFields } from 'modules/core/types';
import { IColumn, TableData } from 'modules/core/components/Table/types';
import { IIncomeForm } from 'components/IncomeCalculator/types';

export interface IProbabilityEstimatorState {
  initialValues: IProbabilityEstimatorForm;
  vehicles: {
    vehiclesByParams: {
      vehicles?: IVehicle[];
      count: number;
    };
    vehiclesByStock?: IVehicle[];
  };
  tableSelectedRows: TableData[];
  tableVisibleColumns: IColumn[];
  income: IIncomeForm;
  isFetchingVehiclesCount: boolean;
  isFetchingVehicles: boolean;
  isFetchingVehiclesFailed: boolean;
  isReset: boolean;
  isTableView: boolean;
  vehicleFiltersDirtyFields: IVehicleFiltersDirtyFields;
  notFoundVehicles: string[];
  notAvailableVehicles: string[];
}

const initialState: IProbabilityEstimatorState = {
  initialValues: INITIAL_VALUES,
  vehicles: {
    vehiclesByParams: {
      vehicles: undefined,
      count: 0,
    },
    vehiclesByStock: undefined,
  },
  tableSelectedRows: [],
  tableVisibleColumns: [],
  income: INITIAL_VALUES.income,
  isFetchingVehiclesCount: false,
  isFetchingVehicles: false,
  isFetchingVehiclesFailed: false,
  isReset: false,
  isTableView: true,
  vehicleFiltersDirtyFields: {},
  notFoundVehicles: [],
  notAvailableVehicles: [],
};

export const probabilityEstimatorSlice = createSlice({
  name: 'probabilityEstimator',
  initialState,
  reducers: {
    syncInitialValues: (
      state: IProbabilityEstimatorState,
      { payload: initialValues }: PayloadAction<IProbabilityEstimatorForm>,
    ) => ({
      ...state,
      initialValues,
    }),
    setSelectedRows: (state: IProbabilityEstimatorState, { payload }: PayloadAction<TableData[]>) => ({
      ...state,
      tableSelectedRows: payload,
    }),
    setVisibleColumns: (
      state: IProbabilityEstimatorState,
      { payload: tableVisibleColumns }: PayloadAction<IColumn[]>,
    ) => ({
      ...state,
      tableVisibleColumns,
    }),
    setIncome: (state: IProbabilityEstimatorState, { payload: income }: PayloadAction<IIncomeForm>) => ({
      ...state,
      income,
    }),
    resetIncome: (state: IProbabilityEstimatorState) => ({
      ...state,
      income: initialState.income,
    }),
    resetVehicles: (state: IProbabilityEstimatorState) => ({
      ...state,
      vehicles: {
        vehiclesByParams: {
          vehicles: undefined,
          count: 0,
        },
        vehiclesByStock: undefined,
      },
    }),
    resetVehiclesByStock: (state: IProbabilityEstimatorState) => ({
      ...state,
      vehicles: { ...state.vehicles, vehiclesByStock: undefined },
    }),
    resetVehiclesByParams: (state: IProbabilityEstimatorState) => ({
      ...state,
      vehicles: {
        ...state.vehicles,
        vehiclesByParams: {
          vehicles: undefined,
          count: 0,
        },
      },
    }),
    resetByStockOptions: (state: IProbabilityEstimatorState) => ({
      ...state,
      notFoundVehicles: [],
      notAvailableVehicles: [],
    }),
    resetByStock: (state: IProbabilityEstimatorState) => ({
      ...state,
      notFoundVehicles: [],
      notAvailableVehicles: [],
      vehicles: { ...state.vehicles, vehiclesByStock: undefined },
    }),
    setIsReset: (state: IProbabilityEstimatorState, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isReset: payload,
    }),
    setIsTableView: (state: IProbabilityEstimatorState, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isTableView: payload,
    }),
    setVehicleFiltersDirtyFields: (
      state: IProbabilityEstimatorState,
      { payload }: PayloadAction<IVehicleFiltersDirtyFields>,
    ) => ({
      ...state,
      vehicleFiltersDirtyFields: payload,
    }),
    resetVehicleFiltersDirtyFields: (state: IProbabilityEstimatorState) => ({
      ...state,
      vehicleFiltersDirtyFields: {},
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(
      getVehiclesForProbabilityEstimatorThunk.fulfilled,
      (state: IProbabilityEstimatorState, { payload: paginatedResponse }: PayloadAction<IPaginatedVehicles>) => ({
        ...state,
        vehicles: {
          ...state.vehicles,
          vehiclesByParams: {
            vehicles: paginatedResponse.results,
            count: paginatedResponse.count,
          },
        },
        isFetchingVehicles: false,
        isFetchingVehiclesCount: false,
        isFetchingVehiclesFailed: false,
      }),
    );
    builder.addCase(getVehiclesForProbabilityEstimatorThunk.pending, (state: IProbabilityEstimatorState) => ({
      ...state,
      vehicles: {
        ...state.vehicles,
        vehiclesByParams: {
          vehicles: undefined,
          count: 0,
        },
      },
      isReset: false,
      isFetchingVehicles: true,
      isFetchingVehiclesCount: true,
      isFetchingVehiclesFailed: false,
    }));
    builder.addCase(getVehiclesForProbabilityEstimatorThunk.rejected, (state: IProbabilityEstimatorState) => ({
      ...state,
      vehicles: {
        ...state.vehicles,
        vehiclesByParams: {
          vehicles: undefined,
          count: 0,
        },
      },
      isFetchingVehicles: false,
      isFetchingVehiclesCount: false,
      isFetchingVehiclesFailed: true,
    }));

    builder.addCase(
      getVehiclesByStockForProbabilityEstimatorThunk.fulfilled,
      (state: IProbabilityEstimatorState, { payload }: PayloadAction<IVehicleByStock[]>) => ({
        ...state,
        vehicles: { ...state.vehicles, vehiclesByStock: payload[0].vehicles },
        notFoundVehicles: payload[0].search_result.not_in_db,
        notAvailableVehicles: payload[0].search_result.not_available,
        isFetchingVehicles: false,
        isFetchingVehiclesCount: false,
        isFetchingVehiclesFailed: false,
      }),
    );

    builder.addCase(getVehiclesByStockForProbabilityEstimatorThunk.pending, (state: IProbabilityEstimatorState) => ({
      ...state,
      vehicles: { ...state.vehicles, vehiclesByStock: undefined },
      notFoundVehicles: [],
      notAvailableVehicles: [],
      isReset: false,
      isFetchingVehicles: true,
      isFetchingVehiclesCount: true,
      isFetchingVehiclesFailed: false,
    }));

    builder.addCase(getVehiclesByStockForProbabilityEstimatorThunk.rejected, (state: IProbabilityEstimatorState) => ({
      ...state,
      vehicles: { ...state.vehicles, vehiclesByStock: undefined },
      notFoundVehicles: [],
      notAvailableVehicles: [],
      isFetchingVehicles: false,
      isFetchingVehiclesCount: false,
      isFetchingVehiclesFailed: true,
    }));
  },
});

export const {
  syncInitialValues,
  setSelectedRows,
  setVisibleColumns,
  setIncome,
  resetVehicles,
  resetVehiclesByStock,
  resetVehiclesByParams,
  resetIncome,
  resetByStockOptions,
  resetByStock,
  setIsReset,
  setIsTableView,
  setVehicleFiltersDirtyFields,
  resetVehicleFiltersDirtyFields,
} = probabilityEstimatorSlice.actions;
