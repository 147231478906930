import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useModalState } from 'modules/core/hooks';
import { MODAL_DIALOG_ID } from 'modules/core/constants';
import { ReportType } from 'modules/core/enums';
import { TReportSelector } from 'modules/core/types';
import { ReportService } from 'modules/core/services';
import { getSelectedRows } from 'store/selectors/probabilityEstimator.selector';
import { getDisabledFeaturesList } from 'store/selectors/ui.selector';
import { getUser } from 'store/selectors/auth.selector';

import { IDropdownOptions } from './types';

export const useDropdownOptions = (reportDataSelector: TReportSelector): IDropdownOptions => {
  const { t } = useTranslation();
  const { handleOpenDialog: handleOpenReportDialog } = useModalState([MODAL_DIALOG_ID.SEND_REPORT]);
  const tableSelectedRows = useSelector(getSelectedRows);
  const disabledFeaturesList = useSelector(getDisabledFeaturesList);
  const reportData = useSelector(reportDataSelector);
  const user = useSelector(getUser);

  const areDisabledEmailAndSMS =
    disabledFeaturesList.includes('is_email_sending_enabled') &&
    disabledFeaturesList.includes('is_sms_sending_enabled') &&
    user?.isWithInventory;

  const sendReportDialogClickProp = useMemo(
    () => (tableSelectedRows.length ? { onClick: handleOpenReportDialog } : {}),
    [handleOpenReportDialog, tableSelectedRows],
  );

  const printReportClickProp = useMemo(
    () =>
      tableSelectedRows.length ? { onClick: () => ReportService.printReport(reportData, ReportType.SALESPERSON) } : {},
    [tableSelectedRows, reportData],
  );

  const managerViewDropdownOptions = useMemo(
    () => [
      {
        title: t('vehicleTableActions.viewActions.textOrEmail'),
        actionProp: sendReportDialogClickProp,
        isDisabled: !tableSelectedRows.length,
      },
      {
        title: t('vehicleTableActions.viewActions.print'),
        actionProp: printReportClickProp,
        isDisabled: !tableSelectedRows.length,
      },
    ],
    [t, sendReportDialogClickProp, tableSelectedRows, printReportClickProp],
  );

  const salespersonViewDropdownOptions = useMemo(
    () => [
      {
        title: t('vehicleTableActions.viewActions.textOrEmail'),
        actionProp: sendReportDialogClickProp,
        isDisabled: !tableSelectedRows.length,
      },
      {
        title: t('vehicleTableActions.viewActions.print'),
        actionProp: printReportClickProp,
        isDisabled: !tableSelectedRows.length,
      },
    ],
    [t, sendReportDialogClickProp, tableSelectedRows, printReportClickProp],
  );

  if (areDisabledEmailAndSMS) {
    salespersonViewDropdownOptions.shift();
    managerViewDropdownOptions.shift();
  }

  return { managerViewDropdownOptions, salespersonViewDropdownOptions };
};
