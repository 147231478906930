import { useSelector } from 'react-redux';

import { getVendorMappings } from '../store/selectors/ui.selector';
import { FormField, IField, IOption } from '../modules/core/types';
import { COMMON_VENDOR_FILTER_OPTIONS, PREFERENCES_FIELD_NAMES } from '../modules/core/shared';
import { Dropdown } from '../modules/core/components';
import { EMPTY_STRING } from '../modules/core/constants';

interface IUseVendorFieldAndOptions {
  VENDOR_FIELD: IField;
  VENDOR_FILTER_OPTIONS: IOption[];
}

export const useVendorFieldAndOptions = (isDisabled?: boolean): IUseVendorFieldAndOptions => {
  const vendorOptions = useSelector(getVendorMappings);

  const TRANSFORMED_VENDOR_OPTIONS: string[][] = [];

  Object.values(vendorOptions).forEach((vendor) => {
    Object.entries(vendor).forEach((subVendor) => TRANSFORMED_VENDOR_OPTIONS.push(subVendor));
  });

  const VENDOR_FILTER_OPTIONS: IOption[] = [
    ...TRANSFORMED_VENDOR_OPTIONS.map((vendor) => ({
      value: vendor[0],
      title: vendor[1],
    })),
    ...COMMON_VENDOR_FILTER_OPTIONS,
  ];

  const VENDOR_FIELD: IField = {
    name: PREFERENCES_FIELD_NAMES.VENDOR_FIELD,
    component: Dropdown as FormField,
    controlProps: {
      options: VENDOR_FILTER_OPTIONS,
      disabled: isDisabled,
    },
    label: EMPTY_STRING,
  };

  return { VENDOR_FIELD, VENDOR_FILTER_OPTIONS };
};
