import React, { FC, useState } from 'react';
import { Trans } from 'react-i18next';

import { BlockWrapper, Button, Triangle, Text, TextWrapper } from './styles';

interface ITextCollapser {
  label: string;
  textKey: string;
}

export const TextCollapser: FC<ITextCollapser> = ({ label, textKey }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <BlockWrapper>
      <Button onClick={() => setIsOpen(!isOpen)}>
        <Triangle isOpen={isOpen} />
        <span>{label}</span>
      </Button>
      <Text isOpen={isOpen}>
        {isOpen && (
          <TextWrapper>
            <Trans i18nKey={textKey} />
          </TextWrapper>
        )}
      </Text>
    </BlockWrapper>
  );
};
