import styled from 'styled-components';
import { Carousel as CarouselElement } from 'react-bootstrap';
import { COLORS } from 'modules/core/theme/colors';
import Image from 'react-bootstrap/Image';
import { WindowBreakpoints } from 'modules/core/enums/windowBreakpoints.enum';

export const Carousel = styled(CarouselElement)`
  & .carousel-control-prev,
  & .carousel-control-next {
    background-color: ${COLORS.BLUE_7};
    border-radius: 60px;
    height: 50px;
    width: 50px;
    top: calc(100% / 2 - 25px);
    opacity: 0.8;
    transition: all 0.4s;

    @media screen and (max-width: 660px) {
      display: none;
    }
  }

  & .carousel-control-prev:hover,
  & .carousel-control-next:hover {
    background-color: ${COLORS.BLUE_8};
    box-shadow: none;
    opacity: 1;
  }

  & .carousel-control-prev {
    left: -70px;
  }

  & .carousel-control-next {
    right: -70px;
  }

  & .carousel-indicators {
    background-color: ${COLORS.BLUE_9};
    border-radius: 10px;
    transition: all 0.4s;
  }

  & .carousel-indicators:hover {
    background-color: ${COLORS.BLUE_7};
    border-radius: 10px;
  }
`;

export const CarouselItem = styled(CarouselElement.Item)`
  width: 100%;
  min-height: 400px;
`;

export const CardContainer = styled.div`
  padding: 30px 60px 50px 60px;

  @media screen and (max-width: ${WindowBreakpoints.small}px) {
    padding: 30px 20px 50px 20px;
  }
`;

export const Img = styled(Image)`
  width: 70%;
`;

export const ImgContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.p`
  color: ${COLORS.GREY_3};
  font-size: 14px;
  font-style: italic;
`;

export const Price = styled.p`
  color: ${COLORS.BLUE_7};
  font-size: 32px;
  font-weight: 800;
`;

export const PriceDescription = styled.p`
  color: ${COLORS.BLUE_7};
  font-size: 22px;
`;

export const PriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
`;
