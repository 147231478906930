import styled from 'styled-components';
import { COLORS } from 'modules/core/theme/colors';

export const Badge = styled.span<{ color: string }>`
  padding: 0 3px;
  text-transform: capitalize;
  color: ${COLORS.WHITE} !important;
  font-size: 12px;
  background-color: ${({ color }) => color};
`;
