import React, { useCallback, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import {
  FIRST_COLUMN,
  FIRST_COLUMN_MOBILE,
  SECOND_COLUMN,
  SECOND_COLUMN_MOBILE,
  THIRD_COLUMN,
  THIRD_COLUMN_MOBILE,
} from 'modules/ApprovedLoanInput/Summary/fields.const';
import { FIELD_NAMES } from 'modules/ApprovedLoanInput/approvedLoanInput.const';
import { isSelectedTrueDropdown } from 'modules/core/utils/typings';
import { FormRow, Panel } from 'modules/core/components';
import { getSubventionYearOptions } from 'store/selectors/controls.selector';
import { getSubventionYearDataThunk } from 'actions/controlActions';
import { AppDispatch } from 'store/store';
import { IField } from 'modules/core/types/common';
import { ColumnWidth } from 'modules/core/enums';
import { DEFAULT_SUBVENTION_YEAR } from 'modules/ApprovedLoanInput/Summary/subvention.const';
import { useWindowDimensions } from 'hooks/useWindowDimensions';

import { HeaderComponent } from '../HeaderComponents/HeaderComponents';

export const Summary: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();
  const subventionYearOptions = useSelector(getSubventionYearOptions);
  const { watch } = useFormContext();
  const { isSmallWidth } = useWindowDimensions();

  const subventionWatch = watch(FIELD_NAMES.SUBVENTION);

  useEffect(() => {
    dispatch(getSubventionYearDataThunk());
  }, [dispatch]);

  const getSummaryField = useCallback(
    ({ name, component: Component, controlProps }: IField) => {
      const isSubventionYearField = name === FIELD_NAMES.SUBVENTION_YEAR;

      const isSubventionFieldHidden = isSubventionYearField && !isSelectedTrueDropdown(subventionWatch);

      if (isSubventionFieldHidden) {
        return null;
      }

      const props = isSubventionYearField
        ? { options: subventionYearOptions, defaultValue: DEFAULT_SUBVENTION_YEAR }
        : controlProps;

      return <Component name={name} {...props} />;
    },
    [subventionWatch, subventionYearOptions],
  );

  const renderFields = useCallback(
    (fields: IField[]) =>
      fields.map((field) => {
        const control = getSummaryField(field);

        return control ? (
          <FormRow
            key={field.name}
            label={field.label}
            disabled={Boolean(field.controlProps?.disabled)}
            required={Boolean(field.controlProps?.isRequired)}
            isVerticalAlign={field.isVerticalAlign}
            control={control}
          />
        ) : null;
      }),
    [getSummaryField],
  );

  return (
    <Panel title={t('components.approvedLoanInput.approvedLoanTerms.title')} headerComponent={<HeaderComponent />}>
      <Row>
        <Col sm={ColumnWidth.XS}>{renderFields(isSmallWidth ? FIRST_COLUMN_MOBILE : FIRST_COLUMN)}</Col>
        <Col sm={ColumnWidth.XS}>{renderFields(isSmallWidth ? SECOND_COLUMN_MOBILE : SECOND_COLUMN)}</Col>
        <Col sm={ColumnWidth.XS}>{renderFields(isSmallWidth ? THIRD_COLUMN_MOBILE : THIRD_COLUMN)}</Col>
      </Row>
    </Panel>
  );
};
