import React from 'react';

import { CardContainer, Img, ImgContainer, Title, Price, Description } from './styles';

interface IVehicleMiniCard {
  imgSrc: string;
  title: string;
  price: string;
  description: string;
}

export const VehicleMiniCard: React.FC<IVehicleMiniCard> = ({ imgSrc, title, price, description }) => (
  <CardContainer>
    <ImgContainer>
      <Img src={imgSrc} />
    </ImgContainer>
    <div>
      <Title>{title}</Title>
      <Price>{price}</Price>
      <Description>{description}</Description>
    </div>
  </CardContainer>
);
