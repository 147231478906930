import { GET_DEPENDENT_FILTER_VIEW } from 'modules/core/components/VehicleFilters/vehicleFilters.const';
import { FilterView } from 'modules/core/enums/filterView.enum';
import { useEffect } from 'react';

interface ISwitchFilters {
  currentFilterName: string;
  isValidStock: boolean;
  isRequestNeeded: boolean;
}

export const useSwitchFilters = (
  commonDependencies: any[],
  filterView: FilterView,
  isFetchingVehiclesFailed: boolean,
  watch: () => Record<string, any>,
  setValue: (name: string, value: any) => void,
  modalDependencies: any[],
): ISwitchFilters => {
  const { tableView, stocks, vehicleFilters } = watch();

  const isValidStock = filterView === FilterView.BY_STOCK ? !!stocks.length : true;
  const isRequestNeeded = watch().isRequestNeeded[filterView];

  const currentFilterName = `isRequestNeeded.${filterView}`;
  const dependentFilterName = `isRequestNeeded.${GET_DEPENDENT_FILTER_VIEW.get(filterView)}`;

  useEffect(() => {
    if (isFetchingVehiclesFailed) {
      setValue(dependentFilterName, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchingVehiclesFailed]);

  useEffect(() => {
    setValue(currentFilterName, filterView === FilterView.BY_STOCK);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stocks.length, tableView]);

  useEffect(() => {
    setValue(currentFilterName, filterView === FilterView.BY_PARAMS);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...Object.values(vehicleFilters), tableView]);

  useEffect(() => {
    setValue(currentFilterName, true);
    setValue(dependentFilterName, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...commonDependencies, tableView]);

  useEffect(() => {
    setValue(dependentFilterName, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...modalDependencies]);

  return { currentFilterName, isValidStock, isRequestNeeded };
};
