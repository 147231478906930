import React from 'react';
import i18n from 'i18n';
import { IField } from 'modules/core/types';
import { DollarAdornment, Input } from 'modules/core/components';
import { ColumnWidth } from 'modules/core/enums';
import { requiredValidator, validateRequiredMinimumValue } from 'modules/core/validators';

import { RAC_MONTHLY_INCOME_FIELDS_NAMES } from './fieldNames.const';

export const TOTAL_INCOME_FIELD: IField = {
  name: RAC_MONTHLY_INCOME_FIELDS_NAMES.TOTAL_INCOME,
  component: Input,
  controlProps: {
    fieldWidth: ColumnWidth.FULL,
    placeholder: i18n.t('components.input.placeholders.integer'),
  },
};

export const MONTHLY_INCOME_FIELDS: IField[] = [
  {
    label: i18n.t('components.racIncomeCalculator.labels.primaryMothlyIncome'),
    name: RAC_MONTHLY_INCOME_FIELDS_NAMES.PRIMARY_MONTHLY_INCOME,
    component: Input,
    controlProps: {
      startAdornment: <DollarAdornment />,
      required: true,
      rules: requiredValidator,
      validate: validateRequiredMinimumValue,
      textStyle: 'bold',
      placeholder: i18n.t('components.input.placeholders.integer'),
      decimalScale: 2,
    },
  },
  {
    label: i18n.t('components.racIncomeCalculator.labels.primaryOtherMonthlyIncome'),
    name: RAC_MONTHLY_INCOME_FIELDS_NAMES.PRIMARY_OTHER_MONTHLY_INCOME,
    component: Input,
    controlProps: {
      startAdornment: <DollarAdornment />,
      placeholder: i18n.t('components.input.placeholders.integer'),
      decimalScale: 2,
    },
  },
  {
    label: i18n.t('components.racIncomeCalculator.labels.coApplicantMonthlyIncome'),
    name: RAC_MONTHLY_INCOME_FIELDS_NAMES.CO_APPLICANT_MONTHLY_INCOME,
    component: Input,
    controlProps: {
      startAdornment: <DollarAdornment />,
      placeholder: i18n.t('components.input.placeholders.integer'),
      decimalScale: 2,
    },
  },
  {
    label: i18n.t('components.racIncomeCalculator.labels.coApplicantOtherMonthlyIncome'),
    name: RAC_MONTHLY_INCOME_FIELDS_NAMES.CO_APPLICANT_OTHER_MONTHLY_INCOME,
    component: Input,
    controlProps: {
      startAdornment: <DollarAdornment />,
      placeholder: i18n.t('components.input.placeholders.integer'),
      decimalScale: 2,
    },
  },
];
