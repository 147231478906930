import React, { FC, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, ModalProps } from 'react-bootstrap';
import { ModalDialog } from 'components/ModalDialog';
import { MODAL_DIALOG_WIDTH } from 'modules/core/constants';
import { TReportSelector } from 'modules/core/types';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { ModalButton } from 'components/ModalDialog/style';
import { ButtonVariant } from 'modules/core/enums';
import { showNotification } from 'store/reducers/ui.reducer';
import { AppDispatch } from 'store/store';

import { DIALOG_CONTENT } from './common.const';
import { SendSalespersonReportDialog } from './sendSalespersonReportForm/SendSalespersonReportDialog';
import { Info } from './styles';
import { IFormData } from './sendSalespersonReportForm/types';

interface ISendReportDialog extends ModalProps {
  reportDataSelector: TReportSelector;
  onHide: () => void;
}

export const SendReportDialog: FC<ISendReportDialog> = ({ show, onHide, reportDataSelector }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const reportBaseData = useSelector(reportDataSelector);
  const [isLoading, setIsLoading] = useState(false);
  const [isContactInfoValid, setIsContactInfoValid] = useState(false);
  const [sendErrors, setSendErrors] = useState('');

  const { dialogTitle, dialogHeaderTitle, transformFormDataCallback, sendReportApiRequest } = DIALOG_CONTENT;

  const formMethods = useForm({
    mode: 'onChange',
  });

  const {
    handleSubmit,
    getValues,
    formState: { isValid },
  } = formMethods;

  const sendReport = useCallback(async () => {
    const transformedFormData = transformFormDataCallback(getValues() as IFormData);

    setIsLoading(true);

    try {
      await sendReportApiRequest({ ...reportBaseData, ...transformedFormData });

      dispatch(showNotification({ message: t('components.sendReportDialog.successfullySend'), type: 'successful' }));

      onHide();
      setSendErrors('');
    } catch (e: any) {
      setSendErrors(e.response?.data?.detail);
    } finally {
      setIsLoading(false);
    }
  }, [transformFormDataCallback, getValues, sendReportApiRequest, reportBaseData, dispatch, t, onHide]);

  const onClose = useCallback((): void => {
    onHide();
    setSendErrors('');
  }, [setSendErrors, onHide]);

  const additionalButton = useMemo(
    () => (
      <ModalButton
        onClick={sendReport}
        buttontheme={ButtonVariant.CONTAINED}
        type="submit"
        disabled={!isValid || isLoading || !isContactInfoValid}
      >
        {t('common.buttons.send')}
      </ModalButton>
    ),
    [sendReport, isValid, isLoading, isContactInfoValid, t],
  );

  return (
    <ModalDialog
      show={show}
      onHide={onClose}
      title={dialogTitle}
      modalSize={MODAL_DIALOG_WIDTH.SM}
      cancelButtonTitle={t('common.buttons.cancel')}
      additionalButton={additionalButton}
    >
      <>
        <Info>{dialogHeaderTitle}</Info>
        <FormProvider {...formMethods}>
          <Form onSubmit={handleSubmit(sendReport)}>
            <SendSalespersonReportDialog setIsContactInfoValid={setIsContactInfoValid} errors={sendErrors} />
          </Form>
        </FormProvider>
      </>
    </ModalDialog>
  );
};
