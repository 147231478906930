import { Endpoints } from 'modules/core/constants';
import { AxiosResponse } from 'axios';
import { Api } from 'api/api';
import { ICreditInterviewInfo } from 'modules/CreditInterview/types';
import { IResponseStatus } from 'modules/core/types';

import { IUnlockInventoryRequestData } from '../components/Modals/UnlockInventoryDialog/types';

class ConsumerApi extends Api {
  postCreditInterviewInfo = (requestData: ICreditInterviewInfo): Promise<AxiosResponse<IResponseStatus>> =>
    this.api.post(Endpoints.postCreditInterviewInfo, requestData);

  postUnlockInventory = (requestData: IUnlockInventoryRequestData): Promise<AxiosResponse<void>> =>
    this.api.post(Endpoints.postUnlockInventory, requestData);
}

export default new ConsumerApi();
