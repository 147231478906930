import React, { FC, useState, useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ModalProps } from 'react-bootstrap';
import { AppDispatch } from 'store/store';
import { vehicleApi } from 'api';
import { ModalDialog } from 'components/ModalDialog';
import { getDefaultVehiclePhoto, getVehicleDetails } from 'store/selectors/vehicle.selector';
import { Loader } from 'modules/core/components';
import { clearVehicleDetails } from 'store/reducers/vehicle.reducer';
import { PlaceholderTitle } from 'common/styles';

import { CarouselItem, CarouselUI as Carousel, ImageUI as Image, Container, Title } from './styles';
import { INITIAL_VALUES } from './common.const';
import { STATIC_IMAGE_URLS } from '../../../modules/core/constants';

interface IVehicleDetailsDialog extends ModalProps {
  onHide: () => void;
}

export const VehicleDetailsDialog: FC<IVehicleDetailsDialog> = ({ show, onHide }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const defaultVehiclePhoto = useSelector(getDefaultVehiclePhoto);
  const [imageList, setImageList] = useState<string[] | undefined>(INITIAL_VALUES.imageList);
  const vehicleDetails = useSelector(getVehicleDetails);
  const [isFetching, setIsFetching] = useState<boolean>(INITIAL_VALUES.isFetching);
  const [isFetchingFailed, setIsFetchingFailed] = useState<boolean>(INITIAL_VALUES.isFetchingFailed);

  const onImageError = useCallback(() => {
    setIsFetchingFailed(true);
  }, [setIsFetchingFailed]);

  const renderSlide = useCallback(
    (link) => (
      <CarouselItem key={link}>
        <Image srcSet={link} alt={t('components.vehicleDetailsModal.vehicleDetailsImage')} onError={onImageError} />
      </CarouselItem>
    ),
    [onImageError, t],
  );

  const isCarouselExist = useMemo(() => imageList && imageList.length > 1, [imageList]);

  const getImageList = useCallback(async () => {
    if (vehicleDetails?.id) {
      setImageList(INITIAL_VALUES.imageList);
      setIsFetching(true);
      const response = await vehicleApi.getVehiclePhotosById(vehicleDetails.id);

      if (response instanceof Error) {
        setIsFetchingFailed(true);
      } else {
        setImageList(response?.data?.[vehicleDetails.id] || []);
      }

      setIsFetching(false);
    }
  }, [vehicleDetails, setIsFetching, setIsFetchingFailed]);

  const clearState = useCallback(() => {
    setIsFetching(INITIAL_VALUES.isFetching);
    setIsFetchingFailed(INITIAL_VALUES.isFetchingFailed);
  }, []);

  const handleHide = useCallback(() => {
    dispatch(clearVehicleDetails());
    setIsFetchingFailed(false);
    vehicleApi.cancelAllRequests();
    clearState();
    onHide();
  }, [dispatch, onHide, clearState]);

  const content = useMemo(() => {
    if (isFetchingFailed || !imageList) {
      return <PlaceholderTitle>{t('common.errorMessages.failedRequestMessage')}</PlaceholderTitle>;
    }

    if (isFetching) {
      return <Loader />;
    }

    if (!imageList.length) setImageList([defaultVehiclePhoto || STATIC_IMAGE_URLS.icons.noPhoto]);

    return isCarouselExist ? (
      <Carousel indicators={false} interval={null}>
        {imageList.map(renderSlide)}
      </Carousel>
    ) : (
      <Image src={imageList[0]} alt={t('components.vehicleDetailsModal.vehicleDetailsImage')} onError={onImageError} />
    );
  }, [t, defaultVehiclePhoto, imageList, isCarouselExist, renderSlide, isFetching, isFetchingFailed, onImageError]);

  useEffect(() => {
    getImageList();
  }, [getImageList, setIsFetchingFailed]);

  return (
    <ModalDialog
      show={show}
      onHide={handleHide}
      title={t('components.vehicleDetailsModal.title')}
      showFooter={false}
      upperCaseTitle
      verticallyCentered
    >
      <Container>
        <Title>{vehicleDetails?.title}</Title>
        {content}
      </Container>
    </ModalDialog>
  );
};
