import React, { FC, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, RouteProps, Router, Switch } from 'react-router-dom';
import { AppDispatch } from 'store/store';
import { MainLayout } from 'modules/core/layout/MainLayout';
import { ErrorPage } from 'pages/ErrorPage/ErrorPage';
import { getDisabledFeaturesList, getFetchingUIFlag } from 'store/selectors/ui.selector';
import { getAuthState, getUser } from 'store/selectors/auth.selector';
import { getFeaturesListThunk } from 'actions/featuresListActions';
import { TabContent, Loader } from 'modules/core/components';
import { sendMetric } from 'metrics';
import { getPathnameWithoutLastElement } from 'modules/Learning/Learning.utils';
import { getLendersListThunk } from 'actions/lenderAction';
import { getFetchingLendersFlag } from 'store/selectors/lenders.selector';
import { useDebounceForLoading } from 'router/utils/useDebounceForLoading';
import { getDefaultVehiclePhotoThunk, getRouteOneDataThunk } from 'actions/vehicleActions';
import { getPassengerCapacitiesThunk } from 'actions/passengerCapacitiesAction';
import { ProductFruits } from 'react-product-fruits';
import { ADMIN_URL_PREFIX, LOGIN_URL_PREFIX } from 'modules/core/constants';

import { RouterItem } from './RouterItem';
import { ROUTER_PATH } from './const/routerPath.const';
import {
  AUTH_ROUTES,
  LEARNING_ROUTES,
  MAKE_IT_WORKS_WITHOUT_INVENTORY_ROUTES,
  PRIVATE_ROUTES,
} from './const/routerConfig.const';
import { browserHistory } from './browserHistory';
import { NavigationService } from './navigation.service';
import { useCheckAuthUser } from '../hooks';
import { IRoute } from './types';

const PRODUCT_FRUITS_WORKSPACE_CODE = process.env.REACT_APP_PRODUCT_FRUITS_WORKSPACE_CODE;

export const Navigation: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { isAuth, isFetching: isAuthFetching } = useSelector(getAuthState);
  const isFetchingFeatureList = useSelector(getFetchingUIFlag);
  const user = useSelector(getUser);
  const isFetchingLendersList = useSelector(getFetchingLendersFlag);
  const isLoginSubdomain = window.location.hostname.includes(LOGIN_URL_PREFIX);
  const isSelectDealerPage = window.location.pathname.includes(ROUTER_PATH.SELECT_DEALER);
  const isAuthenticationFetching = isLoginSubdomain ? !isLoginSubdomain : isAuthFetching;

  const getIsFetching = useCallback(() => {
    const isAuthAndNotAdminUrl = isAuth && !window.location.hostname.includes(ADMIN_URL_PREFIX);

    if (isAuthAndNotAdminUrl && isSelectDealerPage) {
      return false;
    }

    if (isAuthAndNotAdminUrl && !isLoginSubdomain) {
      if (user?.isWithInventory) {
        return isAuthFetching || isFetchingFeatureList || isFetchingLendersList;
      } else return isAuthFetching;
    } else return isAuthenticationFetching;
  }, [
    isAuth,
    isAuthFetching,
    isAuthenticationFetching,
    isFetchingFeatureList,
    isFetchingLendersList,
    isLoginSubdomain,
    isSelectDealerPage,
    user?.isWithInventory,
  ]);

  const isFetching = getIsFetching();
  const isLoading = useDebounceForLoading(isFetching);

  const disabledFeaturesList = useSelector(getDisabledFeaturesList);

  const userInfo = {
    username: user?.email || 'Anonymous',
  };

  const productFruitsConfig = {
    disableBannersAutoMargin: true,
  };

  const enabledPrivateRoutes = NavigationService.filterByDisabledFeatures(PRIVATE_ROUTES, disabledFeaturesList);

  const renderComponent = useCallback(
    (Component?: FC) => (props: RouteProps) => (Component ? <Component {...props} /> : null),
    [],
  );

  const mainLayoutWrapperCallback = useCallback(
    (isSinglePage: boolean, component?: FC) => (props: RouteProps) => (
      <MainLayout isSinglePage={isSinglePage}>{renderComponent(component)(props)}</MainLayout>
    ),
    [renderComponent],
  );

  const renderRoutes = useCallback(
    (
      routes: IRoute[],
      defaultExact,
      isPrivate = true,
      isAccessibleToAll = false,
      wrapperCallback = null,
      isSinglePage = false,
    ) =>
      routes.map(({ path, component, exact = defaultExact }: IRoute) => (
        <RouterItem
          render={wrapperCallback ? wrapperCallback(isSinglePage, component) : renderComponent(component)}
          path={path}
          key={path}
          exact={exact}
          isPrivate={isPrivate}
          isAccessibleToAll={isAccessibleToAll}
        />
      )),
    [renderComponent],
  );

  useEffect(() => {
    if (
      isAuth &&
      !window.location.hostname.includes(ADMIN_URL_PREFIX) &&
      !window.location.hostname.includes(LOGIN_URL_PREFIX)
    ) {
      if (user?.isWithInventory) {
        dispatch(getPassengerCapacitiesThunk());
        dispatch(getFeaturesListThunk());
        dispatch(getLendersListThunk());
        dispatch(getDefaultVehiclePhotoThunk());
      }

      if (user?.is_sso) dispatch(getRouteOneDataThunk());
    }
  }, [user?.is_sso, isAuth, dispatch, user?.isWithInventory]);
  useCheckAuthUser();

  useEffect(() => {
    if (getPathnameWithoutLastElement() === ROUTER_PATH.LEARNING.START) {
      sendMetric(`Completed 1/${LEARNING_ROUTES.length} screens`);
    }
  }, []);

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <ProductFruits
        config={productFruitsConfig}
        user={userInfo}
        workspaceCode={PRODUCT_FRUITS_WORKSPACE_CODE as string}
        language="en"
      />
      <Router history={browserHistory}>
        <TabContent />
        <Switch>
          <Route exact path={ROUTER_PATH.BASE} render={() => <Redirect to={ROUTER_PATH.LOGIN} />} />
          {renderRoutes(AUTH_ROUTES, false, false)}
          {user?.isWithInventory && renderRoutes(LEARNING_ROUTES, false, false, true)}
          {user?.isWithInventory && renderRoutes(enabledPrivateRoutes, true, true, false, mainLayoutWrapperCallback)}
          {user?.is_sso &&
            !user?.tenant &&
            renderRoutes(MAKE_IT_WORKS_WITHOUT_INVENTORY_ROUTES, true, true, false, mainLayoutWrapperCallback, true)}
          <RouterItem path="*" component={ErrorPage} isPrivate />
        </Switch>
      </Router>
    </>
  );
};
