import i18n from 'i18n';
import { CreditScoreColors } from 'modules/ProbabilityEstimator/enums/creditScoreColors.enum';
import { CreditScore } from 'modules/ProbabilityEstimator/types';

import { ValidateField } from '../types';
import { EMPTY_STRING, INPUT_MAX_CHARACTERS, AREA_MAX_CHARACTERS, BILLING_CONTACT_MAX_CHARACTERS } from '../constants';

interface IRequiredValidation {
  required: boolean;
}

export const requiredValidator: IRequiredValidation = { required: true };

export const validateMinRequiredValue: ValidateField = (value) => (value ? undefined : EMPTY_STRING);

export const validateMinRequiredValueForDropdown: ValidateField = (value) => (value.value ? undefined : EMPTY_STRING);

export const validateNumberValue: ValidateField = (value) => (!Number.isNaN(value) ? undefined : EMPTY_STRING);

export const validateTierValue: ValidateField = (value) => !!value.tier;

export const validateRequiredMinimumValue: ValidateField = (value) =>
  Number(value) === 0 || !value ? EMPTY_STRING : undefined;

export const validateCreditScore: ValidateField = (value: CreditScore) =>
  value.color === CreditScoreColors.DEFAULT ? EMPTY_STRING : undefined;

export const validateMaxRequiredInputValue: ValidateField = (value: string): string | undefined =>
  value.length > INPUT_MAX_CHARACTERS
    ? i18n.t('common.errorMessages.maxCharacters', { charactersAmount: INPUT_MAX_CHARACTERS })
    : undefined;

export const validateMaxRequiredAreaValue: ValidateField = (value: string): string | undefined =>
  value.length > AREA_MAX_CHARACTERS
    ? i18n.t('common.errorMessages.maxCharacters', { charactersAmount: AREA_MAX_CHARACTERS })
    : undefined;

export const validateBillingContact: ValidateField = (value: string): string | undefined =>
  value.length > BILLING_CONTACT_MAX_CHARACTERS
    ? i18n.t('common.errorMessages.maxCharacters', { charactersAmount: BILLING_CONTACT_MAX_CHARACTERS })
    : undefined;
