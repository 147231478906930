import { IFeaturesList, INotificationData } from 'modules/core/types';
import { createSelector } from '@reduxjs/toolkit';
import { toDisabledFeaturesList } from 'modules/core/utils';

import { RootState } from '../store';
import { UIReducerState } from '../reducers/ui.reducer';

export const getUiState = (state: RootState): UIReducerState => state.ui;

export const getActiveModalsState = (state: RootState): string[] => getUiState(state).activeModal;

export const getNotification = (state: RootState): INotificationData | null => getUiState(state).notification;

export const getFullSideBar = (state: RootState): boolean => getUiState(state).fullSideBar;

export const getFeaturesList = (state: RootState): IFeaturesList => getUiState(state).featuresList;

export const getVendorName = (state: RootState): string => getUiState(state).featuresList.vendor_name;

export const getVendorMappings = (state: RootState): Record<string, Record<string, string>> =>
  getUiState(state).featuresList.vendor_mappings;

export const getCurrentVendorMappings = createSelector(
  getFeaturesList,
  (featuresList) => featuresList.vendor_mappings?.[featuresList.vendor_name],
);

export const getIsMissingCostInfo = createSelector(getFeaturesList, (featuresList) => featuresList.is_cost_missing);

export const getDisabledFeaturesList = createSelector(getUiState, toDisabledFeaturesList);

export const getFetchingUIFlag = (state: RootState): boolean => getUiState(state).isFetchingFeatureList;

export const getIsModalsDisabled = (state: RootState): boolean => getUiState(state).isModalsDisabled;
