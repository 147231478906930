import styled, { css } from 'styled-components';
import { Form, Card } from 'react-bootstrap';
import { Dropdown } from 'modules/core/components/Dropdown/styles';
import { COLORS } from 'modules/core/theme/colors';
import { ErrorMessage } from 'modules/auth/styles';
import { Button } from 'modules/core/components/Button/styles';
import { WindowBreakpoints } from 'modules/core/enums/windowBreakpoints.enum';

const buttonMixin = css`
  margin: 0 auto;
  border-radius: 30px;
`;

export const Content = styled.div`
  width: 540px;
  margin: 40px auto 0;

  @media (max-width: ${WindowBreakpoints.medium}px) {
    width: 90%;
    min-width: 282px;
    margin-top: 30px;
  }

  ${Dropdown} {
    width: 520px;
    margin: 0 auto;

    @media (max-width: ${WindowBreakpoints.medium}px) {
      width: 90%;
      min-width: 230px;
    }

    & > select {
      border: none;
      background-color: ${({ theme: { colors } }) => colors['grey-10']};
      border-bottom: 1px solid ${({ theme: { colors } }) => colors['grey-11']};
      text-align-last: center;
      font-size: ${({ theme: { fontSize } }) => fontSize.sm};
      color: ${COLORS.BLUE_1};
      font-weight: 700;

      @media (max-width: ${WindowBreakpoints.small}px) {
        font-size: ${({ theme: { fontSize } }) => fontSize.m};
      }

      &:focus {
        box-shadow: none;
      }

      & option {
        color: ${({ theme: { colors } }) => colors.black};
      }
    }
  }

  input {
    padding: 0;
    border: 0;
    border-radius: 0;
    border-bottom: 1px solid ${({ theme: { colors } }) => colors['grey-11']};

    &:active,
    &:focus,
    &:hover {
      box-shadow: none !important;
    }
  }
`;

export const Title = styled.div`
  text-align: center;
  text-transform: uppercase;
  font-size: ${({ theme: { fontSize } }) => fontSize.sm};
  font-weight: ${({ theme: { boldText } }) => boldText.dark};
  color: ${COLORS.BLACK_3};
  margin-bottom: 24px;

  @media (max-width: ${WindowBreakpoints.small}px) {
    margin-bottom: 16px;
  }
`;

export const Text = styled.div`
  text-align: center;
  font-size: ${({ theme: { fontSize } }) => fontSize.sm};
  color: ${COLORS.GREY_7};
`;

export const ImageUI = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
`;

export const CardUI = styled(Card)`
  border-radius: 10px;
  box-shadow: 0 0 20px rgb(0 0 0 / 20%);
  border: 1px solid #e5e5e5;
  padding: 35px 20px 55px;
  margin: 45px 20px 10px;

  @media (max-width: ${WindowBreakpoints.small}px) {
    margin: 30px 15px 10px;
    padding: 25px 15px 55px;
    padding-bottom: 40px;
  }

  ${Button} {
    ${buttonMixin};
    width: 300px;

    @media (max-width: ${WindowBreakpoints.medium}px) {
      width: 75%;
    }
  }
`;

export const InputGroup = styled(Form.Group)`
  color: #999;
  margin-bottom: 32px;

  .form-control {
    &:active,
    &:focus {
      & + .form-label {
        visibility: hidden;
      }
    }
  }

  .form-text {
    color: ${({ theme: { colors } }) => colors['grey-11']};
  }
`;

export const ErrorMessageUI = styled(ErrorMessage)`
  font-size: 11px;
  margin-bottom: 16px;
  text-align: center;
`;

export const CongratulationMessage = styled.div`
  padding-top: 32px;
  text-align: center;
  color: ${COLORS.BLUE_1};

  ${Title} {
    color: ${COLORS.BLUE_1};
    margin-bottom: 32px;
  }

  ${Button} {
    ${buttonMixin};
    width: 100%;
  }
`;

export const Info = styled.div`
  margin-bottom: 12px;

  &:last-of-type {
    margin-bottom: 36px;
  }
`;
