import {
  IAccessory,
  IAccessoryPayload,
  IVendorBody,
  IVendorBodyPayload,
  IVendorResponse,
  IVendorResponsePayload,
  IUserLog,
  IUserLogPayload,
} from './types';

const toAccessory = (accessories: IAccessoryPayload[]): IAccessory[] =>
  accessories.map((accessory) => ({
    slug: accessory.AccCode,
    retail: accessory.Retail,
    accDesc: accessory.AccDesc,
    tradeIn: accessory.TradeIn,
  }));

const toSeriesInfo = (vendorResponseBodies: IVendorBodyPayload[]): IVendorBody[] =>
  vendorResponseBodies.map((vendorResponseBody) => ({
    id: vendorResponseBody.Uid.toString(),
    accessories: toAccessory(vendorResponseBody.Accessories),
    title: vendorResponseBody.BodyDescr,
    model: vendorResponseBody.SeriesDescr,
    odometrAdj: vendorResponseBody.MileageAdj,
    tradeIn: vendorResponseBody.TradeIn,
    retail: vendorResponseBody.Retail,
  }));

export const toAdjustInfo = (vendorResponse: IVendorResponsePayload): IVendorResponse => ({
  error: vendorResponse.third_party_error,
  seriesInfo: vendorResponse.Bodies && toSeriesInfo(vendorResponse.Bodies),
  year: vendorResponse.Year,
  make: vendorResponse.MakeDescr,
});

export const toVehicleLogUser = (user: IUserLogPayload): IUserLog => ({
  firstName: user.first_name,
  lastName: user.last_name,
});
