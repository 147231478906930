import i18n from 'i18n';
import i18next, { TFunctionResult } from 'i18next';

import { FIELD_NAMES, TYPES } from '../../../components/IncomeCalculator/const';
import { IIncomeCalculatorData } from '../../../components/IncomeCalculator/types';

const TWO_YEARS_IN_MILLISECONDS = 2 * 365 * 24 * 60 * 60 * 1000;

export const getCalendarValidation = (name: string, watch: () => IIncomeCalculatorData) => (): TFunctionResult => {
  const cardNumber = Number(name.split('.')[2]);
  const fieldName = name.split('.')[3];

  // This function converts an object into an array: [[a, 2],[c, 4], [d, '']] and returns the object without empty strings.
  const getPreparationData = () => {
    const incomeCard = watch().income.incomesForm[cardNumber];
    const arrayFormattedIncomesForm: [string, any][] = Object.entries(incomeCard).filter(([, value]) => value !== '');

    return Object.fromEntries(arrayFormattedIncomesForm);
  };

  const { type, dateOfHire, periodEndingDate, checkOrPayDate } = getPreparationData();

  const validateDateOfHire = () => {
    const delta = dateOfHire ? new Date().getTime() - dateOfHire.getTime() : 0;

    return delta < TWO_YEARS_IN_MILLISECONDS
      ? undefined
      : i18next.t('components.incomeCalculator.errorMessages.forDateOfHire');
  };

  if (type === TYPES.GROSS_YTD) {
    if (fieldName === FIELD_NAMES.DATE_OF_HIRE) return validateDateOfHire();

    if (fieldName === FIELD_NAMES.PERIOD_ENDING_DATE && periodEndingDate < dateOfHire)
      return i18n.t('components.incomeCalculator.errorMessages.forPeriodEndingDate');

    if (fieldName === FIELD_NAMES.CHECK_OR_PAY_DATE && checkOrPayDate < periodEndingDate)
      return i18n.t('components.incomeCalculator.errorMessages.forCheckOrPayDate&PeriodEndingDate');

    if (fieldName === FIELD_NAMES.CHECK_OR_PAY_DATE && checkOrPayDate < dateOfHire)
      return i18n.t('components.incomeCalculator.errorMessages.forCheckOrPayDate&DateOfHire');
  }

  if (type === TYPES.EARLY_YEAR && fieldName === FIELD_NAMES.DATE_OF_HIRE) return validateDateOfHire();

  return undefined;
};
