import React, { FC, useCallback } from 'react';
import { IToggle } from 'modules/core/types';
import { Controller } from 'react-hook-form';
import { useUncontrolledInput } from 'modules/core/hooks';

import { ToggleUI } from './styles';

export const Toggle: FC<IToggle> = ({ defaultChecked, icons, name, onToggleChange, checked }) => {
  const { controlField, setValue } = useUncontrolledInput(name);

  const handleChange = useCallback(
    (e) => {
      if (onToggleChange) {
        onToggleChange(e);
      } else {
        setValue(e.target.name, e.target.checked);
      }
    },
    [onToggleChange, setValue],
  );

  const renderToggle = useCallback(
    () => (
      <ToggleUI defaultChecked={defaultChecked} icons={icons} onChange={handleChange} name={name} checked={checked} />
    ),
    [defaultChecked, handleChange, icons, name, checked],
  );

  return <Controller name={name} control={controlField} render={renderToggle} />;
};
