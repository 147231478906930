import i18n from 'i18n';

const REGIONS = {
  NY: i18n.t('components.vehicleAdjustmentDialog.regions.easternRegion'),
  PN: i18n.t('components.vehicleAdjustmentDialog.regions.pacificNorthwestRegion'),
  SW: i18n.t('components.vehicleAdjustmentDialog.regions.southwesternRegion'),
  MW: i18n.t('components.vehicleAdjustmentDialog.regions.midwestRegion'),
  CE: i18n.t('components.vehicleAdjustmentDialog.regions.centralRegion'),
  SE: i18n.t('components.vehicleAdjustmentDialog.regions.southeasternRegion'),
  NE: i18n.t('components.vehicleAdjustmentDialog.regions.newEnglandRegion'),
  DS: i18n.t('components.vehicleAdjustmentDialog.regions.desertSouthwestRegion'),
  MS: i18n.t('components.vehicleAdjustmentDialog.regions.mountainStatesRegion'),
  CR: i18n.t('components.vehicleAdjustmentDialog.regions.californiaRegion'),
};

const VEHICLE_INFO_NAMES = {
  CONDITION: 'condition',
  MAKE: 'make',
  YEAR: 'year',
  MILEAGE: 'mileage',
};

const TOTAL_COST_INFO_NAMES = {
  BASE_PRICE: 'basePrice',
  ADDS_DEDUCTS: 'addsDeducts',
  ODOMETER_ADJ: 'odometerAdj',
};

export const REGIONS_VALUES = [
  REGIONS.NY,
  REGIONS.PN,
  REGIONS.SW,
  REGIONS.MW,
  REGIONS.CE,
  REGIONS.SE,
  REGIONS.NE,
  REGIONS.DS,
  REGIONS.MS,
  REGIONS.CR,
];

export const DEFAULT_REGION = 'NY';

export const VEHICLE_INFO_FIELDS = [
  VEHICLE_INFO_NAMES.CONDITION,
  VEHICLE_INFO_NAMES.MAKE,
  VEHICLE_INFO_NAMES.YEAR,
  VEHICLE_INFO_NAMES.MILEAGE,
];

export const BASE_TOTAL_COST_INFO_FIELDS = [
  TOTAL_COST_INFO_NAMES.BASE_PRICE,
  TOTAL_COST_INFO_NAMES.ADDS_DEDUCTS,
  TOTAL_COST_INFO_NAMES.ODOMETER_ADJ,
];

export const RETAIL_NAME = 'retail';

export const TRADE_IN_NAME = 'tradeIn';

export const VEHICLE_ADJUSTMENT_FORM_FIELDS = {
  SERIES: 'series',
  EQUIPMENTS: 'equipments',
};

export const VEHICLE_ADJUSTMENT_FIELDS_SUBSCRIPTION = [
  VEHICLE_ADJUSTMENT_FORM_FIELDS.SERIES,
  VEHICLE_ADJUSTMENT_FORM_FIELDS.EQUIPMENTS,
];
