import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { AppDispatch } from 'store/store';
import { ModalProps, Col } from 'react-bootstrap';
import { vehicleApi } from 'api';
import { ModalDialog } from 'components/ModalDialog';
import { ModalButton } from 'components/ModalDialog/style';
import {
  STATIC_IMAGE_URLS,
  EMPTY_STRING,
  ICONS_HEIGHT,
  ICONS_WIDTH,
  MODAL_DIALOG_WIDTH,
  MODAL_STYLES,
} from 'modules/core/constants';
import { Alignment, ButtonVariant, ModalTheme, ColumnWidth } from 'modules/core/enums';
import { clearVehicleAdjustmentData } from 'store/reducers/vehicle.reducer';
import { getAdjustmentId, getVehicleAdjustmentLog, getVehicleAdjustRetail } from 'store/selectors/vehicle.selector';
import { getAdjustEventMessage } from 'store/selectors/controls.selector';
import { getVehicleAdjustmentLogThunk, getVehicleAdjustRetailThunk } from 'actions/vehicleActions';
import { getAdjustEventMessagesThunk } from 'actions/controlActions';
import { colonFormatter, slashFormatter } from 'modules/core/utils';
import { Radio, InfoRow as TotalPriceInfoRow, Icon, Loader } from 'modules/core/components';
import { FormProvider, useForm } from 'react-hook-form';
import { COLORS } from 'modules/core/theme/colors';

import {
  VEHICLE_INFO_FIELDS,
  VEHICLE_ADJUSTMENT_FORM_FIELDS,
  VEHICLE_ADJUSTMENT_FIELDS_SUBSCRIPTION,
  BASE_TOTAL_COST_INFO_FIELDS,
  RETAIL_NAME,
  TRADE_IN_NAME,
  DEFAULT_REGION,
  REGIONS_VALUES,
} from './common.const';
import {
  InfoRow,
  FormUI as Form,
  TotalInfo,
  TotalInfoCol,
  Layout,
  DealerInfo,
  VehicleInfo,
  SeriesTitle,
  Notification,
  ErrorNotification as ErrorNotificationUI,
  LoaderContainer,
} from './styles';
import { AdjustmentTable } from './AdjustmentTable';
import { AdjustmentLogTable } from './AdjustmentLogTable';
import { DialogHeader } from './DialogHeader';
import { TVehicleInfoOption } from './types';
import { VehicleAdjustmentService } from './vehicleAdjustment.service';

interface IVehicleAdjustmentDialog extends ModalProps {
  onApplyCallback: VoidFunction;
  onHide: () => void;
}

export const VehicleAdjustmentDialog: FC<IVehicleAdjustmentDialog> = ({
  show,
  onHide,
  onApplyCallback,
  isDisplayWithoutButtons = false,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const adjustmentId = useSelector(getAdjustmentId);
  const vehicleAdjustRetail = useSelector(getVehicleAdjustRetail);
  const vehicleAdjustmentLog = useSelector(getVehicleAdjustmentLog);
  const adjustEventMessage = useSelector(getAdjustEventMessage);
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  const isVendorResponseError = isDataLoaded && !!vehicleAdjustRetail?.adjustInfo?.error;

  const formMethods = useForm({
    mode: 'onChange',
  });

  const { watch, reset, handleSubmit } = formMethods;
  const {
    [VEHICLE_ADJUSTMENT_FORM_FIELDS.SERIES]: selectedSeries,
    [VEHICLE_ADJUSTMENT_FORM_FIELDS.EQUIPMENTS]: equipments,
  } = watch(VEHICLE_ADJUSTMENT_FIELDS_SUBSCRIPTION);

  const areAdjustmentLogsExist = !!vehicleAdjustmentLog.length;

  const vehicleAdjustRetailSeriesInfo = useMemo(() => vehicleAdjustRetail?.adjustInfo?.seriesInfo, [
    vehicleAdjustRetail,
  ]);

  const currentAdjustment = useMemo(() => vehicleAdjustRetailSeriesInfo?.find((item) => item.id === selectedSeries), [
    vehicleAdjustRetailSeriesInfo,
    selectedSeries,
  ]);

  const resetForm = useCallback(() => {
    reset(VehicleAdjustmentService.getDefaultFormState(vehicleAdjustRetail));
  }, [vehicleAdjustRetail, reset]);

  const header = useMemo(
    () => (
      <DialogHeader
        data={vehicleAdjustRetail}
        resetForm={resetForm}
        areLogsExist={areAdjustmentLogsExist}
        isVendorResponseError={isVendorResponseError}
      />
    ),
    [areAdjustmentLogsExist, resetForm, vehicleAdjustRetail, isVendorResponseError],
  );

  const errorNotification = (
    <ErrorNotificationUI>{t('components.vehicleAdjustmentDialog.errorMessage')}</ErrorNotificationUI>
  );

  const vehicleInfoFields = useMemo(
    () =>
      VEHICLE_INFO_FIELDS.map((field: string) => (
        <InfoRow key={field}>
          <span>{`${colonFormatter(t(`components.vehicleAdjustmentDialog.labels.vehicleInfo.${field}`))} `}</span>
          {VehicleAdjustmentService.getVehicleInfo(vehicleAdjustRetail)?.[field as TVehicleInfoOption]}
        </InfoRow>
      )),
    [t, vehicleAdjustRetail],
  );

  const dealer = useMemo(
    () =>
      vehicleAdjustRetail?.regionJdpower
        ? adjustEventMessage?.replace(DEFAULT_REGION, REGIONS_VALUES[vehicleAdjustRetail.regionJdpower - 1])
        : EMPTY_STRING,
    [adjustEventMessage, vehicleAdjustRetail],
  );

  const series = useMemo(
    () =>
      vehicleAdjustRetailSeriesInfo?.map((seriesItem) => ({
        title: `${seriesItem.title} ${seriesItem.model}`,
        value: seriesItem.id,
      })),
    [vehicleAdjustRetailSeriesInfo],
  );

  const getTotalRetailCostInfoFieldsByName = useCallback(
    (name) =>
      currentAdjustment &&
      [...BASE_TOTAL_COST_INFO_FIELDS, name].map((fieldName) => (
        <TotalPriceInfoRow
          label={t(`components.vehicleAdjustmentDialog.labels.totalInfo.${fieldName}`)}
          value={VehicleAdjustmentService.getTotalCostInfoByName(name, equipments, currentAdjustment)?.[fieldName]}
        />
      )),
    [equipments, currentAdjustment, t],
  );

  const onDialogHide = useCallback(() => {
    dispatch(clearVehicleAdjustmentData());
    onHide();
  }, [dispatch, onHide]);

  const onSubmit = useCallback(
    async (data) => {
      const submitData = currentAdjustment && [
        VehicleAdjustmentService.getAdjustmentChosenValues(data, adjustmentId, currentAdjustment),
      ];

      if (submitData) {
        await vehicleApi.patchVehicleAdjustRetail(submitData);
      }

      onDialogHide();
      onApplyCallback();
    },
    [onApplyCallback, onDialogHide, adjustmentId, currentAdjustment],
  );

  const additionalButton = useMemo(
    () => (
      <ModalButton buttontheme={ButtonVariant.CONTAINED} type="submit" onClick={handleSubmit(onSubmit)}>
        {t('common.buttons.apply')}
      </ModalButton>
    ),
    [handleSubmit, onSubmit, t],
  );

  const tradeInTotalInfoField = getTotalRetailCostInfoFieldsByName(TRADE_IN_NAME);
  const retailTotalInfoField = getTotalRetailCostInfoFieldsByName(RETAIL_NAME);

  const formContent = useMemo(
    () =>
      isDataLoaded ? (
        <Form onSubmit={handleSubmit(onSubmit)}>
          <DealerInfo>
            <span>{slashFormatter(t('components.vehicleAdjustmentDialog.jDPower'))}</span>
            {` ${dealer}`}
          </DealerInfo>
          <Layout>
            <Col sm={ColumnWidth.S}>
              <VehicleInfo>{vehicleInfoFields}</VehicleInfo>
              <SeriesTitle>{t('components.vehicleAdjustmentDialog.series')}</SeriesTitle>
              {series && (
                <Radio options={series} name={VEHICLE_ADJUSTMENT_FORM_FIELDS.SERIES} defaultValue={series[0].value} />
              )}
            </Col>
            <Col sm={ColumnWidth.L}>{currentAdjustment && <AdjustmentTable data={currentAdjustment} />}</Col>
          </Layout>
          <Notification>
            <Icon imageSrc={STATIC_IMAGE_URLS.icons.info} height={ICONS_HEIGHT.DEFAULT} width={ICONS_WIDTH.DEFAULT} />
            <span>{t('components.vehicleAdjustmentDialog.notification')}</span>
          </Notification>
          <TotalInfo>
            <TotalInfoCol lastRowColor={COLORS.ORANGE_2} sm={ColumnWidth.S}>
              {tradeInTotalInfoField}
            </TotalInfoCol>
            <TotalInfoCol lastRowColor={COLORS.BLUE_6} sm={ColumnWidth.S}>
              {retailTotalInfoField}
            </TotalInfoCol>
          </TotalInfo>
          <AdjustmentLogTable data={vehicleAdjustmentLog} />
        </Form>
      ) : (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      ),
    [
      currentAdjustment,
      dealer,
      handleSubmit,
      isDataLoaded,
      onSubmit,
      retailTotalInfoField,
      series,
      t,
      tradeInTotalInfoField,
      vehicleAdjustmentLog,
      vehicleInfoFields,
    ],
  );

  const dispatchRequests = useCallback(async () => {
    setIsDataLoaded(false);

    await dispatch(getVehicleAdjustRetailThunk(adjustmentId));
    await dispatch(getAdjustEventMessagesThunk());
    await dispatch(getVehicleAdjustmentLogThunk(adjustmentId));

    setIsDataLoaded(true);
  }, [dispatch, adjustmentId]);

  useEffect(() => {
    if (adjustmentId) {
      dispatchRequests();
    }
  }, [dispatchRequests, adjustmentId]);

  useEffect(() => {
    resetForm();
  }, [resetForm]);

  return (
    <FormProvider {...formMethods}>
      <ModalDialog
        show={show}
        onHide={onDialogHide}
        cancelButtonTitle={t('common.buttons.cancel')}
        {...(isVendorResponseError || isDisplayWithoutButtons ? {} : { additionalButton })}
        modalSize={isDisplayWithoutButtons ? MODAL_DIALOG_WIDTH.FULL_SCREEN : undefined}
        alignFooterContent={Alignment.CENTER}
        showCloseButton={false}
        header={isDataLoaded && header}
        modalTheme={ModalTheme.LIGHT}
        scrollable
        modalStyle={isDisplayWithoutButtons ? MODAL_STYLES.FULL_SCREEN : undefined}
      >
        {isVendorResponseError ? errorNotification : formContent}
      </ModalDialog>
    </FormProvider>
  );
};
