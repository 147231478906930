import React, { FC } from 'react';

import { SpinnerWrapper, SpinnerItem, Container } from './styles';

export const Loader: FC = () => (
  <Container>
    <SpinnerWrapper>
      <SpinnerItem />
      <SpinnerItem />
      <SpinnerItem />
    </SpinnerWrapper>
  </Container>
);
