import { ProbabilityEstimatorService } from 'modules/ProbabilityEstimator/services/probabilityEstimator.service';
import { convertFalsyValuesToZero } from 'modules/core/utils';
import { IFeesAndTaxesFormOptions } from 'modules/core/types';

import { IRACAffordabilityEngineForm } from './types/RACAffordabilityEngineForm';

export class RACAffordabilityEngineService {
  static getSubmitQuery(formValues: IRACAffordabilityEngineForm): Record<string, unknown> {
    const { tier } = formValues.tier;
    const {
      feesAndTaxes,
      preferences,
      vehicleFilters,
      tableView,
      totalNetDownPaymentDialog,
      totalFrontAndBackEndsAddsDialog,
      ...restValues
    } = formValues;
    const { salesTaxRate, ...restFeesAndTaxes } = feesAndTaxes;

    return {
      ...restValues,
      ...preferences,
      tier,
      tableView,
      ...ProbabilityEstimatorService.getFilterObject(vehicleFilters, tableView),
      ...convertFalsyValuesToZero(restFeesAndTaxes as Record<IFeesAndTaxesFormOptions, number>),
      salesTaxRate: salesTaxRate,
      netDownPayment: totalNetDownPaymentDialog.totalNetDownPayment || 0,
      amountOwed: totalNetDownPaymentDialog.amountOwed || 0,
      tradeValue: totalNetDownPaymentDialog.tradeValue || 0,
      cashDown: totalNetDownPaymentDialog.cashDown || 0,
      addOns: totalFrontAndBackEndsAddsDialog.addOns || 0,
      vsc: totalFrontAndBackEndsAddsDialog.vsc || 0,
      gap: totalFrontAndBackEndsAddsDialog.gap || 0,
    };
  }

  static getSubmitQueryForLoanVariables(formValues: any): Record<string, unknown> {
    let tier;
    let loan_term;

    if (formValues.isLender) {
      tier = formValues.tier.tier_id;
      loan_term = formValues.term;
    } else {
      tier = formValues.tier.tier;
      loan_term = formValues.loan_term;
    }

    const {
      feesAndTaxes,
      preferences,
      totalNetDownPaymentDialog,
      totalFrontAndBackEndsAddsDialog,
      retail_price,
      unit_cost,
      current_price,
      vendorName,
      ...restValues
    } = formValues;
    const { salesTaxRate, ...restFeesAndTaxes } = feesAndTaxes;

    return {
      ...restValues,
      ...preferences,
      tier,
      ...convertFalsyValuesToZero(restFeesAndTaxes as Record<IFeesAndTaxesFormOptions, number>),
      salesTaxRate: salesTaxRate,
      netDownPayment: totalNetDownPaymentDialog.totalNetDownPayment || 0,
      amountOwed: totalNetDownPaymentDialog.amountOwed || 0,
      tradeValue: totalNetDownPaymentDialog.tradeValue || 0,
      cashDown: totalNetDownPaymentDialog.cashDown || 0,
      vscAndGapInsurance: (totalFrontAndBackEndsAddsDialog.vsc || 0) + (totalFrontAndBackEndsAddsDialog.gap || 0),
      addOns: totalFrontAndBackEndsAddsDialog.addOns || 0,
      currentPrice: current_price,
      vsc: totalFrontAndBackEndsAddsDialog.vsc || 0,
      gap: totalFrontAndBackEndsAddsDialog.gap || 0,
      loanTerm: loan_term,
      unitCost: unit_cost,
    };
  }
}
