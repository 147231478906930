import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Preloader from 'components/common/Preloader/Preloader';
import { AppDispatch } from 'store/store';
import { resetPasswordThunk } from 'actions/authActions';

import { BackToLoginLink, EmailInput, Button } from '../common';
import { IForgotPasswordValue } from '../auth';
import { FormPosition, TextForgotPassword } from '../styles';

interface IForgotPasswordForm {
  onShowLinkIsSentContent: VoidFunction;
}

export const ForgotPasswordForm: React.FC<IForgotPasswordForm> = ({ onShowLinkIsSentContent }) => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const [resetPasswordError, setResetPasswordError] = useState();
  const [showSpinner, setShowSpinner] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm<IForgotPasswordValue>({ mode: 'onChange' });

  const onSubmit = useCallback(
    async (data: IForgotPasswordValue) => {
      setShowSpinner(true);
      const resetPasswordPassword = await dispatch(resetPasswordThunk(data));

      if (resetPasswordPassword.meta.requestStatus === 'fulfilled') {
        onShowLinkIsSentContent();
      } else if (resetPasswordPassword.payload.email) {
        setResetPasswordError(resetPasswordPassword.payload.email[0]);
      }

      setShowSpinner(false);
    },
    [setShowSpinner, setResetPasswordError, dispatch, onShowLinkIsSentContent],
  );

  return (
    <>
      <FormPosition onSubmit={handleSubmit(onSubmit)}>
        <TextForgotPassword>{t('components.resetPassword.enterYourEmail')}</TextForgotPassword>
        <EmailInput
          register={register}
          errorEmail={errors.email}
          placeholder={t('components.login.email')}
          resetPasswordError={resetPasswordError}
          autoComplete="off"
        />
        {showSpinner ? (
          <Preloader />
        ) : (
          <Form.Group>
            {!resetPasswordError ? <div>&nbsp;</div> : null}
            <Button label={t('components.resetPassword.buttons.resetPassword')} disabled={!isValid} />
          </Form.Group>
        )}
      </FormPosition>
      {!showSpinner && <BackToLoginLink />}
    </>
  );
};
