import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { colonFormatter, numericDateFormatter } from 'modules/core/utils';
import { ColumnWidth } from 'modules/core/enums';
import { EMPTY_STRING } from 'modules/core/constants';

import { IVehicleLog } from '../types';
import { RowUI as Row, ColUI as Col, Title, Container, NoDataMessage, Header } from './styles';
import { HEADER_LABELS } from './adjustmentLogTable.const';

interface IAdjustmentLogTable {
  data: IVehicleLog[];
}

export const AdjustmentLogTable: FC<IAdjustmentLogTable> = ({ data }) => {
  const { t } = useTranslation();

  const renderRow = useCallback(
    (timestamp, user, chosenValues, Component) => (
      <Component>
        <Col sm={ColumnWidth.XXXS}>{timestamp}</Col>
        <Col sm={ColumnWidth.XS}>{user}</Col>
        <Col sm={ColumnWidth.M}>{chosenValues}</Col>
      </Component>
    ),
    [],
  );

  const rows = useMemo(
    () =>
      data.map(({ changedAt, user, changes }) => {
        const changedDate = numericDateFormatter(changedAt);
        const userInfo = `${user.firstName} ${user.lastName}`;
        const series = `${colonFormatter(t('components.vehicleAdjustmentDialog.series'))} ${changes.serie}`;
        const equipmentInfo = changes.equipment?.join(', ') || EMPTY_STRING;
        const equipment = `${colonFormatter(t('components.vehicleAdjustmentDialog.equipment'))} ${equipmentInfo}`;

        const chosenValues = (
          <>
            <span>{series}</span>
            <br />
            <span>{equipment}</span>
          </>
        );

        return renderRow(changedDate, userInfo, chosenValues, Row);
      }),
    [t, data, renderRow],
  );

  const header = renderRow(HEADER_LABELS.TIME_STAMP, HEADER_LABELS.USER, HEADER_LABELS.CHOSEN_VALUES, Header);

  const table = useMemo(
    () =>
      data.length ? (
        <>
          {header}
          {rows}
        </>
      ) : (
        <NoDataMessage>{t('components.vehicleAdjustmentDialog.noData')}</NoDataMessage>
      ),
    [data, header, rows, t],
  );

  return (
    <Container>
      <Title>{t('components.vehicleAdjustmentDialog.editLog')}</Title>
      {table}
    </Container>
  );
};
