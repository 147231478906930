import { COLORS } from './colors';
import { FONT_SIZE } from './fontSize';
import { RUBIK_FONT_FAMILY } from './typography';

export const theme = {
  colors: {
    'green-1': COLORS.GREEN_1,
    'green-2': COLORS.GREEN_2,
    'green-3': COLORS.GREEN_3,
    'green-4': COLORS.GREEN_4,
    'green-5': COLORS.GREEN_5,
    'green-6': COLORS.GREEN_6,
    'red-1': COLORS.RED_1,
    'red-2': COLORS.RED_2,
    'red-3': COLORS.RED_3,
    'yellow-1': COLORS.YELLOW_1,
    'yellow-2': COLORS.YELLOW_2,
    'yellow-3': COLORS.YELLOW_3,
    'yellow-4': COLORS.YELLOW_4,
    'orange-1': COLORS.ORANGE_1,
    'grey-1': COLORS.GREY_1,
    'grey-2': COLORS.GREY_2,
    'grey-3': COLORS.GREY_3,
    'grey-4': COLORS.GREY_4,
    'grey-5': COLORS.GREY_5,
    'grey-6': COLORS.GREY_6,
    'grey-7': COLORS.GREY_7,
    'grey-8': COLORS.GREY_8,
    'grey-9': COLORS.GREY_9,
    'grey-10': COLORS.GREY_10,
    'grey-11': COLORS.GREY_11,
    'grey-12': COLORS.GREY_12,
    'grey-13': COLORS.GREY_13,
    'grey-14': COLORS.GREY_14,
    'blue-1': COLORS.BLUE_1,
    'blue-2': COLORS.BLUE_2,
    'blue-3': COLORS.BLUE_3,
    'blue-4': COLORS.BLUE_4,
    'blue-5': COLORS.BLUE_5,
    black: COLORS.BLACK,
    'black-2': COLORS.BLACK_2,
    white: COLORS.WHITE,
    'white-2': COLORS.WHITE_2,
    'white-3': COLORS.WHITE_3,
    'white-4': COLORS.WHITE_4,
    'white-5': COLORS.WHITE_5,
  },
  fontFamily: {
    rubik: RUBIK_FONT_FAMILY,
  },
  boldText: {
    normal: 400,
    bold: 500,
    dark: 600,
  },
  fontSize: {
    mini: FONT_SIZE.MINI,
    m: '13px',
    normal: '14px',
    sm: '15px',
    button: '17px',
    md: '18px',
    huge: '27px',
    lg: '32px',
  },
  variables: {
    inputHeight: '34px',
    modalDialogButtonWith: '95px',
  },
  modalDialog: {
    width: '720px',
  },
};
