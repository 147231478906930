const MILLISECONDS_IN_DAY = 1000 * 60 * 60 * 24;

export const getDaysDiff = (firstDate: Date, lastDate: Date): number => {
  const millisecondsDiff = lastDate.getTime() - firstDate.getTime();

  return Math.round(millisecondsDiff / MILLISECONDS_IN_DAY);
};

export const getEndOfPreviousYearDate = (max_year: Date): Date => {
  const startOfYear = new Date(max_year.getFullYear(), 0, 1);

  return new Date(startOfYear.getTime() - MILLISECONDS_IN_DAY);
};

export const getFirstOfCurrentYearDate = (): Date => {
  const currentYear = new Date().getFullYear();
  const startOfYear = new Date(currentYear, 0, 1);

  return new Date(startOfYear.getTime());
};
