import { CATEGORIES, TYPES } from 'components/IncomeCalculator/const/fieldNames.const';
import { IIncomeCardForm } from 'components/IncomeCalculator/types';
import { VEHICLE_FILTERS_INITIAL_VALUES } from 'modules/core/components/VehicleFilters/vehicleFilters.const';
import { TableView } from 'modules/core/enums';
import { FEES_AND_TAXES_INITIAL_VALUES, PREFERENCES_LENDER_INITIAL_VALUES } from 'modules/core/shared';
import { CreditScoreColors } from 'modules/ProbabilityEstimator/enums/creditScoreColors.enum';
import { FilterView } from 'modules/core/enums/filterView.enum';
import { LENDERS_MOTHLY_INCOME_DEFAULT_VALUES } from 'modules/RACAffordabilityEngine/RacMontlyIncomeModal/consts/defaultValues';

import { ILenderForm, ITier } from './types/Lender.types';

const NET_DOWN_PAYMENT_VALUES = {
  cashDown: 0,
  tradeValue: 0,
  amountOwed: 0,
};

export const LTV_INC_TAXES_FEES = 'ltv_inc_taxes_fees';

export const INCOME_INITIAL_VALUES: Partial<IIncomeCardForm>[] = [
  {
    category: CATEGORIES.W2,
    type: TYPES.GROSS_YTD,
    amountReceived: 0,
    grossYTDAmount: 0,
    hourlyRate: 0,
    hoursPerWeek: 0,
    ytdNysDisabilityDeducted: 0,
    previousYearW2Amount: 0,
    loansListedOnPaycheck: 'No',
    garnishments: 'No',
    temporaryServiceEmployee: 'No',
    seasonalEmployee: 'No',
  },
];

export const INITIAL_VALUES_ONE_LENDER: ILenderForm = {
  feesAndTaxes: FEES_AND_TAXES_INITIAL_VALUES,
  preferences: PREFERENCES_LENDER_INITIAL_VALUES,
  vehicleFilters: VEHICLE_FILTERS_INITIAL_VALUES,
  totalNetDownPaymentDialog: NET_DOWN_PAYMENT_VALUES,
  tableView: TableView.SALESPERSON,
  vehicleTableAccordion: true,
  income: { totalIncome: 0, savedIncomes: INCOME_INITIAL_VALUES },
  grossMonthlyIncome: undefined,
  desiredMonthlyPayment: 0,
  cashDownPayment: 0,
  filterView: FilterView.BY_PARAMS,
  stocks: [],
  netDownPayment: undefined,
  tier: {
    ltv_limit: 0,
    max_allowed_payment: 0,
    max_allowed_pti: 0,
    max_green_payment: 0,
    max_green_pti: 0,
    max_payment: 0,
    max_yellow_payment: 0,
    max_yellow_pti: 0,
    pti: 0,
    rate: 0,
    tier_name: '',
    tier_id: 0,
    color: CreditScoreColors.DEFAULT,
  } as ITier,
  isRequestNeeded: {
    byParams: true,
    byStock: true,
  },
  condition: 'all',
  lendersMonthlyIncomeModal: LENDERS_MOTHLY_INCOME_DEFAULT_VALUES,
};
