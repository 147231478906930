import React, { FC, useCallback, useEffect, useState } from 'react';
import { ASTERISK, BLANK_SPACE, MODAL_DIALOG_WIDTH } from 'modules/core/constants';
import { ButtonVariant, ColumnWidth, ModalTheme } from 'modules/core/enums';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Input, FormRow, TelephoneInput, Button } from 'modules/core/components';
import { Modal, Row } from 'react-bootstrap';
import { FORMAT_NUMBER_MASK } from 'modules/CreditInterview/common.const';
import { PHONE_INPUT_RULES } from 'modules/CreditInterview/rules.const';
import { validateBillingContact } from 'modules/core/validators';
import { getUser } from 'store/selectors/auth.selector';
import { consumerApi } from 'api';

import { MODAL_STEPS, UNLOCK_INVENTORY_FORM_INITIAL_VALUES } from './consts/consts';
import { ButtonsBlock, ConfirmPriceHelpMessage, Panel, SuccessMessage } from './styles';
import { ModalContainer, Title } from '../../ModalDialog/style';
import { UNLOCK_INVENTORY_FIELDS_NAMES } from './consts/fieldNames.const';
import { ConfirmCheckBox } from '../LoanVariablesDialog/components/common/ConfirmCheckBox/ConfirmCheckBox';

interface IUnlockInventoryDialog {
  show: boolean;
  onHide: VoidFunction;
}

export const UnlockInventoryDialog: FC<IUnlockInventoryDialog> = ({ show, onHide }) => {
  const { t } = useTranslation();
  const user = useSelector(getUser);
  const [step, setStep] = useState<number>(1);
  const formMethods = useForm({
    mode: 'onSubmit',
    shouldUnregister: false,
    defaultValues: { unlockInventoryForm: UNLOCK_INVENTORY_FORM_INITIAL_VALUES },
  });
  const { getValues, reset, handleSubmit, setError, clearErrors } = formMethods;
  const { confirmPrice, confirmTerms, phoneNumber, billingContact } = getValues().unlockInventoryForm;

  const onSubmit = useCallback(() => {
    consumerApi.postUnlockInventory({
      full_name: `${user?.firstName} ${user?.lastName}`,
      email: user?.email || '',
      phone_number: `+1${phoneNumber.toString()}`,
      billing_contact: billingContact ? billingContact : undefined,
    });
    setStep(MODAL_STEPS.THIRD);
  }, [billingContact, phoneNumber, user?.email, user?.firstName, user?.lastName]);

  useEffect(() => {
    if (show) setStep(MODAL_STEPS.FIRST);
  }, [show]);

  const handleNextStep = useCallback(() => {
    if (!confirmPrice) {
      setError(UNLOCK_INVENTORY_FIELDS_NAMES.CONFIRM_PRICE, { message: 'error' });
    }

    if (!confirmTerms) {
      setError(UNLOCK_INVENTORY_FIELDS_NAMES.CONFIRM_TERMS, { message: 'error' });
    }

    if (confirmPrice && confirmTerms) {
      clearErrors([UNLOCK_INVENTORY_FIELDS_NAMES.CONFIRM_PRICE, UNLOCK_INVENTORY_FIELDS_NAMES.CONFIRM_TERMS]);
      setStep(MODAL_STEPS.SECOND);
    }
  }, [clearErrors, confirmPrice, confirmTerms, setError]);

  const handleBackStep = useCallback(() => {
    reset({
      unlockInventoryForm: {
        confirmPrice: true,
        confirmTerms: true,
        phoneNumber: phoneNumber.toString() || '',
        billingContact: billingContact || '',
      },
    });
    setStep(MODAL_STEPS.FIRST);
  }, [billingContact, phoneNumber, reset]);

  const handleHide = useCallback(() => {
    onHide();
    reset({ unlockInventoryForm: UNLOCK_INVENTORY_FORM_INITIAL_VALUES });
  }, [reset, onHide]);

  const handleClickCheckbox = useCallback(
    (name) => {
      if (name === UNLOCK_INVENTORY_FIELDS_NAMES.CONFIRM_PRICE) {
        clearErrors(UNLOCK_INVENTORY_FIELDS_NAMES.CONFIRM_PRICE);
      }

      if (name === UNLOCK_INVENTORY_FIELDS_NAMES.CONFIRM_TERMS) {
        clearErrors(UNLOCK_INVENTORY_FIELDS_NAMES.CONFIRM_TERMS);
      }
    },
    [clearErrors],
  );

  const onSubmitCallback = useCallback(() => {
    handleSubmit(onSubmit)();
  }, [handleSubmit, onSubmit]);

  return (
    <FormProvider {...formMethods}>
      <ModalContainer
        show={show}
        onHide={handleHide}
        modalSize={MODAL_DIALOG_WIDTH.CUSTOM.PX_613}
        backdrop={'static'}
        $modalTheme={ModalTheme.DEFAULT}
        $minModalSize={MODAL_DIALOG_WIDTH.CUSTOM.PX_613}
        $modalSize={MODAL_DIALOG_WIDTH.CUSTOM.PX_613}
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Title>{t('components.unlockInventoryDialog.title')}</Title>
        </Modal.Header>
        <Modal.Body>
          {step === MODAL_STEPS.FIRST && (
            <Panel>
              <Row>
                <div>{t('components.unlockInventoryDialog.firstStep.message')}</div>
              </Row>
              <FormRow
                name={UNLOCK_INVENTORY_FIELDS_NAMES.CONFIRM_PRICE}
                control={
                  <>
                    <ConfirmCheckBox
                      name={UNLOCK_INVENTORY_FIELDS_NAMES.CONFIRM_PRICE}
                      changeCallback={() => handleClickCheckbox(UNLOCK_INVENTORY_FIELDS_NAMES.CONFIRM_PRICE)}
                      textLabel={t('components.unlockInventoryDialog.firstStep.priceCheckBox')}
                    />
                    <ConfirmPriceHelpMessage>
                      {t('components.unlockInventoryDialog.firstStep.priceCheckBoxHelpMessage')}
                    </ConfirmPriceHelpMessage>
                  </>
                }
              />
              <FormRow
                name={UNLOCK_INVENTORY_FIELDS_NAMES.CONFIRM_TERMS}
                control={
                  <ConfirmCheckBox
                    name={UNLOCK_INVENTORY_FIELDS_NAMES.CONFIRM_TERMS}
                    changeCallback={() => handleClickCheckbox(UNLOCK_INVENTORY_FIELDS_NAMES.CONFIRM_TERMS)}
                    textLabel={
                      <>
                        {t('components.unlockInventoryDialog.firstStep.termsAndConditionsCheckBox')}{' '}
                        <a href={process.env.REACT_APP_STREAMLINE_TERMS_SITE} target="_blank" rel="noreferrer">
                          {(process.env.REACT_APP_STREAMLINE_TERMS_SITE || '').replace('https://', '')}
                        </a>
                        {BLANK_SPACE}
                        {ASTERISK}
                      </>
                    }
                  />
                }
              />
            </Panel>
          )}
          {step === MODAL_STEPS.SECOND && (
            <Panel>
              <Row>
                <div>{t('components.unlockInventoryDialog.secondStep.message')}</div>
              </Row>
              <FormRow
                fieldWidth={ColumnWidth.FULL}
                key={UNLOCK_INVENTORY_FIELDS_NAMES.FULL_NAME}
                label={t('components.unlockInventoryDialog.secondStep.fullNameLabel')}
                name={UNLOCK_INVENTORY_FIELDS_NAMES.FULL_NAME}
                control={
                  <div>
                    {user?.firstName} {user?.lastName}
                  </div>
                }
                boldLabel
              />
              <FormRow
                fieldWidth={ColumnWidth.FULL}
                key={UNLOCK_INVENTORY_FIELDS_NAMES.EMAIL}
                label={t('components.unlockInventoryDialog.secondStep.emailLabel')}
                name={UNLOCK_INVENTORY_FIELDS_NAMES.EMAIL}
                control={<div>{user?.email}</div>}
                boldLabel
              />
              <FormRow
                fieldWidth={ColumnWidth.FULL}
                key={UNLOCK_INVENTORY_FIELDS_NAMES.PHONE_NUMBER}
                required
                label={t('components.unlockInventoryDialog.secondStep.phoneNumberLabel')}
                name={UNLOCK_INVENTORY_FIELDS_NAMES.PHONE_NUMBER}
                control={
                  <TelephoneInput
                    name={UNLOCK_INVENTORY_FIELDS_NAMES.PHONE_NUMBER}
                    rules={PHONE_INPUT_RULES}
                    format={FORMAT_NUMBER_MASK}
                  />
                }
              />
              <FormRow
                fieldWidth={ColumnWidth.FULL}
                key={UNLOCK_INVENTORY_FIELDS_NAMES.BILLING_CONTACT}
                label={t('components.unlockInventoryDialog.secondStep.billingContactLabel')}
                name={UNLOCK_INVENTORY_FIELDS_NAMES.BILLING_CONTACT}
                boldLabel
                control={
                  <Input
                    name={UNLOCK_INVENTORY_FIELDS_NAMES.BILLING_CONTACT}
                    textStyle={'bold'}
                    valueAsNumber={false}
                    validate={validateBillingContact}
                  />
                }
              />
            </Panel>
          )}
          {step === MODAL_STEPS.THIRD && (
            <Panel isSuccessMessage>
              <SuccessMessage>
                <div>{t('components.unlockInventoryDialog.thirdStep.message')}</div>
              </SuccessMessage>
            </Panel>
          )}
        </Modal.Body>
        <Modal.Footer
          className={`d-flex ${step === MODAL_STEPS.THIRD ? 'justify-content-end' : 'justify-content-between'}`}
        >
          {(step === MODAL_STEPS.FIRST || step === MODAL_STEPS.SECOND) && (
            <div>
              {t('components.unlockInventoryDialog.notReady')}
              <a href={process.env.REACT_APP_STREAMLINE_PRICING_SITE} target="_blank" rel="noreferrer">
                {t('components.unlockInventoryDialog.learnMore')}
              </a>
            </div>
          )}
          {step === MODAL_STEPS.FIRST && (
            <ButtonsBlock>
              <Button
                title={t('components.unlockInventoryDialog.buttons.back')}
                buttonTheme={ButtonVariant.OUTLINE}
                onClick={handleHide}
                width={'120px'}
              />
              <Button
                title={t('components.unlockInventoryDialog.buttons.next')}
                buttonTheme={ButtonVariant.CONTAINED}
                onClick={handleNextStep}
                width={'120px'}
              />
            </ButtonsBlock>
          )}
          {step === MODAL_STEPS.SECOND && (
            <ButtonsBlock>
              <Button
                title={t('components.unlockInventoryDialog.buttons.back')}
                buttonTheme={ButtonVariant.OUTLINE}
                onClick={handleBackStep}
                width={'120px'}
              />
              <Button
                title={t('components.unlockInventoryDialog.buttons.submit')}
                buttonTheme={ButtonVariant.CONTAINED}
                onClick={onSubmitCallback}
                width={'120px'}
              />
            </ButtonsBlock>
          )}
          {step === MODAL_STEPS.THIRD && (
            <Button
              title={t('components.unlockInventoryDialog.buttons.success')}
              buttonTheme={ButtonVariant.CONTAINED}
              width={'120px'}
              disabled
            />
          )}
        </Modal.Footer>
      </ModalContainer>
    </FormProvider>
  );
};
