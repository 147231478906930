import i18n from 'i18next';
import { IOption } from 'modules/core/types';
import { EMPTY_STRING } from 'modules/core/constants';

import { FILTER_OPTION_VALUES } from './vehicleFilters.const';

export const VEHICLE_TYPE_OPTIONS: IOption[] = [
  {
    title: i18n.t('components.vehicleFilters.options.vehicleTypes.all'),
    value: FILTER_OPTION_VALUES.VEHICLE_TYPE.ALL,
  },
  {
    title: i18n.t('components.vehicleFilters.options.vehicleTypes.used'),
    value: FILTER_OPTION_VALUES.VEHICLE_TYPE.USED,
  },
  {
    title: i18n.t('components.vehicleFilters.options.vehicleTypes.new'),
    value: FILTER_OPTION_VALUES.VEHICLE_TYPE.NEW,
  },
];

export const MILE_OPTIONS: IOption[] = [
  {
    title: i18n.t('components.vehicleFilters.options.miles.filterMiles'),
    value: FILTER_OPTION_VALUES.MILES.FILTER_MILES,
  },
  {
    title: i18n.t('components.vehicleFilters.options.miles.upTo'),
    value: FILTER_OPTION_VALUES.MILES.UP_TO,
  },
  {
    title: i18n.t('components.vehicleFilters.options.miles.atLeast'),
    value: FILTER_OPTION_VALUES.MILES.AT_LEAST,
  },
];

export const YEAR_OPTIONS: IOption[] = [
  {
    title: i18n.t('components.vehicleFilters.options.years.filterYears'),
    value: FILTER_OPTION_VALUES.YEARS.FILTER_YEARS,
  },
  {
    title: i18n.t('components.vehicleFilters.options.years.newerThenFrom'),
    value: FILTER_OPTION_VALUES.YEARS.NEWER_THEN_FROM,
  },
  {
    title: i18n.t('components.vehicleFilters.options.years.olderThenFrom'),
    value: FILTER_OPTION_VALUES.YEARS.OLDER_THEN_FROM,
  },
  {
    title: i18n.t('components.vehicleFilters.options.years.onlyFrom'),
    value: FILTER_OPTION_VALUES.YEARS.ONLY_FORM,
  },
];

export const AGE_OPTIONS: IOption[] = [
  {
    title: i18n.t('components.vehicleFilters.options.ages.filterAges'),
    value: EMPTY_STRING,
  },
  {
    title: i18n.t('components.vehicleFilters.options.ages.less10days'),
    value: FILTER_OPTION_VALUES.AGES.LESS_10_DAYS,
  },
  {
    title: i18n.t('components.vehicleFilters.options.ages.more10days'),
    value: FILTER_OPTION_VALUES.AGES.MORE_10_DAYS,
  },
  {
    title: i18n.t('components.vehicleFilters.options.ages.more20days'),
    value: FILTER_OPTION_VALUES.AGES.MORE_20_DAYS,
  },
  {
    title: i18n.t('components.vehicleFilters.options.ages.more30days'),
    value: FILTER_OPTION_VALUES.AGES.MORE_30_DAYS,
  },
  {
    title: i18n.t('components.vehicleFilters.options.ages.more60days'),
    value: FILTER_OPTION_VALUES.AGES.MORE_60_DAYS,
  },
  {
    title: i18n.t('components.vehicleFilters.options.ages.more90days'),
    value: FILTER_OPTION_VALUES.AGES.MORE_90_DAYS,
  },
];

export const ALL_OPTION: IOption = {
  title: i18n.t('components.vehicleFilters.options.all'),
  value: EMPTY_STRING,
};

export const ALL_STORES_OPTION: IOption = {
  title: i18n.t('components.vehicleFilters.options.all'),
  value: EMPTY_STRING,
};

export const ALL_VEHICLES_OPTION: IOption = {
  title: i18n.t('components.vehicleFilters.options.all'),
  value: EMPTY_STRING,
};

export const ALL_MAKES_OPTION: IOption = {
  title: i18n.t('components.vehicleFilters.options.all'),
  value: EMPTY_STRING,
};

export const ALL_MODELS_OPTION: IOption = {
  title: i18n.t('components.vehicleFilters.options.all'),
  value: EMPTY_STRING,
};

export const DOMESTIC_OPTION: IOption = {
  title: i18n.t('components.vehicleFilters.options.domestic'),
  value: FILTER_OPTION_VALUES.DOMESTIC,
};

export const IMPORT_OPTION: IOption = {
  title: i18n.t('components.vehicleFilters.options.import'),
  value: FILTER_OPTION_VALUES.IMPORT,
};

export const DRIVE_TYPE_OPTIONS: IOption[] = [
  {
    title: i18n.t('components.vehicleFilters.options.all'),
    value: EMPTY_STRING,
  },
  {
    title: i18n.t('components.vehicleFilters.options.driveTypes.AWD'),
    value: FILTER_OPTION_VALUES.DRIVE_TYPE.AWD,
  },
  {
    title: i18n.t('components.vehicleFilters.options.driveTypes.FWD'),
    value: FILTER_OPTION_VALUES.DRIVE_TYPE.FWD,
  },
  {
    title: i18n.t('components.vehicleFilters.options.driveTypes.RWD'),
    value: FILTER_OPTION_VALUES.DRIVE_TYPE.RWD,
  },
  {
    title: i18n.t('components.vehicleFilters.options.driveTypes.4WD'),
    value: FILTER_OPTION_VALUES.DRIVE_TYPE.FOUR_WD,
  },
  {
    title: i18n.t('components.vehicleFilters.options.driveTypes.2WD'),
    value: FILTER_OPTION_VALUES.DRIVE_TYPE.TWO_WD,
  },
];

export const EXTRA_CASH_OPTIONS: IOption[] = [
  {
    title: i18n.t('components.vehicleFilters.options.extraCash.all'),
    value: EMPTY_STRING,
  },
  {
    title: i18n.t('components.vehicleFilters.options.extraCash.noExtraCash'),
    value: FILTER_OPTION_VALUES.EXTRA_CASH.NO_EXTRA_CASH,
  },
  {
    title: i18n.t('components.vehicleFilters.options.extraCash.upTo1000'),
    value: FILTER_OPTION_VALUES.EXTRA_CASH.UP_TO_1000,
  },
  {
    title: i18n.t('components.vehicleFilters.options.extraCash.upTo2000'),
    value: FILTER_OPTION_VALUES.EXTRA_CASH.UP_TO_2000,
  },
  {
    title: i18n.t('components.vehicleFilters.options.extraCash.upTo3000'),
    value: FILTER_OPTION_VALUES.EXTRA_CASH.UP_TO_3000,
  },
  {
    title: i18n.t('components.vehicleFilters.options.extraCash.upTo4000'),
    value: FILTER_OPTION_VALUES.EXTRA_CASH.UP_TO_4000,
  },
  {
    title: i18n.t('components.vehicleFilters.options.extraCash.upTo5000'),
    value: FILTER_OPTION_VALUES.EXTRA_CASH.UP_TO_5000,
  },
];
