import React from 'react';
import { useSelector } from 'react-redux';
import { getUser } from 'store/selectors/auth.selector';
import { ROUTER_PATH } from 'router/const/routerPath.const';
import { getManagerRedirectPath, getSalespersonRedirectPath } from 'router/const/dashboard.const';
import { getLendersList } from 'store/selectors/lenders.selector';
import { getDisabledFeaturesList } from 'store/selectors/ui.selector';
import { removeHandleBeforeUnloadEvent } from 'hooks/useConfirmTabClose';

import { LogoWrapper } from './styles';
import { LOGO_DATA_MAP } from './common.const';
import { NavigationLink } from '../NavigationLink';
import { Roles } from '../../enums';
import { EMPTY_STRING } from '../../constants';

export const Logo: React.FC = () => {
  const user = useSelector(getUser);
  const lenders = useSelector(getLendersList);
  const disabledFeatures = useSelector(getDisabledFeaturesList);

  let path = ROUTER_PATH.PROBABILITY_ESTIMATOR;

  if (user?.role) {
    if ([Roles.MANAGER, Roles.SALESPERSON].includes(user.role)) {
      if (user.role === Roles.MANAGER) {
        path = getManagerRedirectPath(disabledFeatures, lenders);

        path = path === EMPTY_STRING ? '/manager-admin/' : path;
      } else path = getSalespersonRedirectPath(disabledFeatures);
    } else path = LOGO_DATA_MAP.get(user.role) as string;
  }

  return user?.isWithInventory ? (
    <NavigationLink isExternal={true} path={path}>
      <LogoWrapper isClickable onClick={removeHandleBeforeUnloadEvent} />
    </NavigationLink>
  ) : (
    <LogoWrapper isClickable={false} />
  );
};
