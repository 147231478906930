import { useSelector } from 'react-redux';
import { getUser } from 'store/selectors/auth.selector';
import { TableView, TableName } from 'modules/core/enums';
import { TableColumn } from 'modules/core/components/Table/types';
import { Column } from 'react-table';
import { WITHOUT_INVENTORY_RAC_TABLE_COLUMNS } from 'modules/core/components/VehicleTable/vehicleTableColumns';

import { GET_DEFAULT_COLUMNS_MAP, COLUMNS_MAP_BY_TABLE } from '../common.const';

export const useColumnsByRole = (selectedTableView: TableView, tableName: TableName): TableColumn[] => {
  const user = useSelector(getUser);
  const DEFAULT_COLUMNS_MAP = GET_DEFAULT_COLUMNS_MAP.get(tableName) as Column<Record<string, unknown>>[];

  if (!user) {
    return DEFAULT_COLUMNS_MAP;
  }

  if (!user.isWithInventory) {
    return WITHOUT_INVENTORY_RAC_TABLE_COLUMNS;
  }

  return COLUMNS_MAP_BY_TABLE.get(tableName)?.get(user.role)?.get(selectedTableView) || DEFAULT_COLUMNS_MAP;
};
