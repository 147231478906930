import React, { useCallback } from 'react';
import { ModalProps } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ModalDialog } from 'components/ModalDialog';
import { MODAL_DIALOG_WIDTH } from 'modules/core/constants';
import { useVehicleFormReset } from 'modules/core/hooks';
import { IVehicleForm } from 'modules/core/types';

import { RowContent } from './RowContent';

interface IResetModal extends ModalProps {
  initialValues: IVehicleForm;
  resetStateCallback: VoidFunction;
  resetVehicleFiltersDirtyFields?: VoidFunction;
  ltvTaxesFeesFilter?: string;
}

export const ResetModal: React.FC<IResetModal> = ({
  show,
  onHide,
  initialValues,
  resetStateCallback,
  resetVehicleFiltersDirtyFields,
  ltvTaxesFeesFilter,
}) => {
  const { t } = useTranslation();
  const { resetInputs, resetAll, resetFilters } = useVehicleFormReset(
    initialValues,
    resetStateCallback,
    resetVehicleFiltersDirtyFields,
    ltvTaxesFeesFilter,
  );

  const renderRowContent = useCallback(
    (label: string, yesButtonAction: VoidFunction, yesButtonContained?: boolean, noButtonContained?: boolean) => (
      <RowContent
        label={label}
        yesButtonAction={yesButtonAction}
        yesButtonContained={yesButtonContained}
        noButtonContained={noButtonContained}
      />
    ),
    [],
  );

  return (
    <ModalDialog
      show={show}
      onHide={onHide}
      title={t('components.resetModal.title')}
      submitButtonTitle={t('common.buttons.ok')}
      modalSize={MODAL_DIALOG_WIDTH.SM}
      showFooter={false}
    >
      {renderRowContent(t('components.resetModal.labels.reset.inputs'), resetInputs, true, false)}
      {renderRowContent(t('components.resetModal.labels.reset.filters'), resetFilters)}
      {renderRowContent(t('components.resetModal.labels.reset.inputsAndFilters'), resetAll)}
    </ModalDialog>
  );
};
