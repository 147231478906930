import { Selector } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from 'store/store';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { SetFieldValue } from 'modules/core/types/form';
import { PREFERENCES_FIELD_NAMES } from 'modules/core/shared';
import { getCurrentVendorMappings } from 'store/selectors/ui.selector';

import { IVehicleForm } from '../types';

export const useSettingsInitialValues = <V extends IVehicleForm>(
  setValue: SetFieldValue,
  selector: Selector<RootState, V>,
): void => {
  const dispatch: AppDispatch = useDispatch();
  const {
    preferences: { vendorField, ltvTaxesFeesFilter },
  } = useSelector(selector);

  const vendorOptions = useSelector(getCurrentVendorMappings);

  useEffect(() => {
    if (!vendorField) {
      setValue(PREFERENCES_FIELD_NAMES.VENDOR_FIELD, Object.keys(vendorOptions)[0]);
    }
  }, [dispatch, setValue, vendorField, vendorOptions]);

  useEffect(() => {
    setValue(PREFERENCES_FIELD_NAMES.LTV_TAXES_FEES_FILTER, ltvTaxesFeesFilter);
  }, [dispatch, setValue, ltvTaxesFeesFilter]);
};
