import i18n from 'i18next';
import {
  FIELD_NAMES,
  AVG_FROM_DEPOSITS_FIELDS_COUNT_NUMBERS,
  AVG_FROM_TAX_RETURNS_COUNT_NUMBERS,
} from 'components/IncomeCalculator/const/index';
import { reportCostFormatter, numericDateFormatter } from 'modules/core/utils';
import { FieldsMapper, IIncomeReportHeadersMapper } from 'modules/core/types';
import { ReportDataType } from 'modules/core/enums';

const LINE_31_SCHEDULE_C_YEAR_HEADERS = AVG_FROM_TAX_RETURNS_COUNT_NUMBERS.reduce(
  (headers, index) => ({
    ...headers,
    [`${FIELD_NAMES.LINE_31_SCHEDULE_C_YEAR}${index}`]: {
      name: i18n.t('components.incomeCalculator.labels.line31ScheduleCYear', { index }),
      datatype: ReportDataType.CURRENCY,
    },
  }),
  {},
);

const TOTAL_BANK_DEPOSITS_HEADERS = AVG_FROM_DEPOSITS_FIELDS_COUNT_NUMBERS.reduce(
  (headers, index) => ({
    ...headers,
    [`${FIELD_NAMES.TOTAL_BANK_DEPOSITS}${index}`]: {
      name: i18n.t('components.incomeCalculator.labels.totalBankDeposits', { index }),
      datatype: ReportDataType.CURRENCY,
    },
  }),
  {},
);

export const INCOME_CALCULATOR_HEADERS_MAPPER: Record<string, IIncomeReportHeadersMapper> = {
  [FIELD_NAMES.INCOME]: {
    name: i18n.t('components.incomeCalculator.labels.incomePerMonth'),
    datatype: ReportDataType.CURRENCY,
  },
  [FIELD_NAMES.CATEGORY]: {
    name: i18n.t('components.incomeCalculator.labels.category'),
    datatype: ReportDataType.STRING,
  },
  [FIELD_NAMES.TYPE]: {
    name: i18n.t('components.incomeCalculator.labels.type'),
    datatype: ReportDataType.STRING,
  },
  [FIELD_NAMES.LOANS_LISTED_ON_PAYCHECK]: {
    name: i18n.t('components.incomeCalculator.labels.loansListedOnPaycheck'),
    datatype: ReportDataType.BOOLEAN,
  },
  [FIELD_NAMES.GARNISHMENTS]: {
    name: i18n.t('components.incomeCalculator.labels.garnishments'),
    datatype: ReportDataType.BOOLEAN,
  },
  [FIELD_NAMES.TEMPORARY_SERVICE_EMPLOYEE]: {
    name: i18n.t('components.incomeCalculator.labels.temporaryServiceEmployee'),
    datatype: ReportDataType.BOOLEAN,
  },
  [FIELD_NAMES.SEASONAL_EMPLOYEE]: {
    name: i18n.t('components.incomeCalculator.labels.seasonalEmployee'),
    datatype: ReportDataType.BOOLEAN,
  },
  [FIELD_NAMES.DATE_OF_HIRE]: {
    name: i18n.t('components.incomeCalculator.labels.dateOfHire'),
    datatype: ReportDataType.DATETIME,
  },
  [FIELD_NAMES.GROSS_YTD]: {
    name: i18n.t('components.incomeCalculator.labels.grossYTDAmount'),
    datatype: ReportDataType.CURRENCY,
  },
  [FIELD_NAMES.PERIOD_ENDING_DATE]: {
    name: i18n.t('components.incomeCalculator.labels.periodEndingDate'),
    datatype: ReportDataType.DATETIME,
  },
  [FIELD_NAMES.CHECK_OR_PAY_DATE]: {
    name: i18n.t('components.incomeCalculator.labels.checkOrPayDate'),
    datatype: ReportDataType.DATETIME,
  },
  [FIELD_NAMES.HOURLY_RATE]: {
    name: i18n.t('components.incomeCalculator.labels.hourlyRate'),
    datatype: ReportDataType.CURRENCY,
  },
  [FIELD_NAMES.HOURS_PER_WEEK]: {
    name: i18n.t('components.incomeCalculator.labels.hoursPerWeek'),
    datatype: ReportDataType.INTEGER,
  },
  [FIELD_NAMES.NYS_DISABILITY_DEDUCTED]: {
    name: i18n.t('components.incomeCalculator.labels.ytdNysDisabilityDeducted'),
    datatype: ReportDataType.CURRENCY,
  },
  [FIELD_NAMES.PREVIOUS_YEAR_W2_AMOUNT]: {
    name: i18n.t('components.incomeCalculator.labels.previousYearW2Amount'),
    datatype: ReportDataType.CURRENCY,
  },
  ...LINE_31_SCHEDULE_C_YEAR_HEADERS,
  ...TOTAL_BANK_DEPOSITS_HEADERS,
  [FIELD_NAMES.AMOUNT_RECEIVED]: {
    name: i18n.t('components.incomeCalculator.labels.amountReceived'),
    datatype: ReportDataType.CURRENCY,
  },
};

export const INCOME_CALCULATOR_ROWS_MAPPER: Record<string, FieldsMapper> = {
  [FIELD_NAMES.CATEGORY]: i18n.t('components.incomeCalculator.categories', { returnObjects: true }),
  [FIELD_NAMES.TYPE]: i18n.t('components.incomeCalculator.types', { returnObjects: true }),
};

export const INCOME_CALCULATOR_TYPE_FORMATTERS = new Map<string, (value: any) => string>([
  [ReportDataType.CURRENCY, reportCostFormatter],
  [ReportDataType.DATETIME, numericDateFormatter],
]);
