import styled from 'styled-components';
import { titlePanelMixin } from 'modules/core/theme/mixins';
import { WindowBreakpoints } from 'modules/core/enums/windowBreakpoints.enum';

export const PageHeaderSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 0 6px;
`;

export const Title = styled.h4`
  ${titlePanelMixin};
  font-size: ${({ theme: { fontSize } }) => fontSize.md};
  margin: 10px 5px 10px 0;

  @media (max-width: ${WindowBreakpoints.small}px) {
    font-size: ${({ theme: { fontSize } }) => fontSize.sm};
  }
`;
