import React, { FC, useCallback, useEffect } from 'react';
import i18n from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useFormContext, Controller, ControllerRenderProps } from 'react-hook-form';
import { Dropdown } from 'react-bootstrap';
import { AppDispatch } from 'store/store';
import { useUncontrolledInput } from 'modules/core/hooks';
import { IFormField } from 'modules/core/types/form';
import { PREFERENCES_PE_FIELD_NAMES } from 'modules/core/shared';
import { getTiers } from 'store/selectors/RACAffordabilityEngine.selector';
import { getTierOptionsThunk } from 'actions/controlActions';
import { CreditScoreColors } from 'modules/ProbabilityEstimator/enums/creditScoreColors.enum';
import { getUser } from 'store/selectors/auth.selector';

import { ITier } from '../../core/types';
import {
  DefaultToggle,
  OptionItem,
  ScoreMenu,
  SelectedScoreToggle,
} from '../../ProbabilityEstimator/CreditScoreDropdown/styles';

export const TierDropdown: FC<IFormField> = ({ name, validate }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { watch } = useFormContext();
  const { controlField, setValue } = useUncontrolledInput(name);
  const tiers = useSelector(getTiers);
  const user = useSelector(getUser);

  const selectedOption = watch(name) as ITier;
  const tierDisplayName = (tier: number) => i18n.t('components.creditScore.tier', { tier });

  const isSelectedDefault = selectedOption.color === CreditScoreColors.DEFAULT;
  const ToggleComponent = isSelectedDefault ? DefaultToggle : SelectedScoreToggle;
  const toggleTitle = selectedOption.tier
    ? tierDisplayName(selectedOption.tier)
    : i18n.t('components.creditScore.title');

  const renderOptions = useCallback(
    ({ onChange, onBlur }: ControllerRenderProps) =>
      tiers.map((tierOption) => {
        const isSelectedOption = selectedOption.tier === tierOption.tier;

        return (
          <OptionItem
            data-testid="tier-option"
            onClick={() => {
              onChange(tierOption);
              setValue(PREFERENCES_PE_FIELD_NAMES.LVT_HIGH_LIMIT, tierOption.max_ltv);
            }}
            onBlur={onBlur}
            selected={isSelectedOption}
            key={tierOption.tier}
            color={tierOption.color}
          >
            {tierDisplayName(tierOption.tier)}
          </OptionItem>
        );
      }),
    [selectedOption, setValue, tiers],
  );

  const renderDropdown = useCallback(
    (renderProps) => (
      <Dropdown data-testid="tier-control">
        <ToggleComponent disabled={user?.is_sso} color={selectedOption.color} data-testid="default-toggle">
          {toggleTitle}
        </ToggleComponent>
        <ScoreMenu>{renderOptions(renderProps)}</ScoreMenu>
      </Dropdown>
    ),
    [ToggleComponent, user?.is_sso, selectedOption.color, toggleTitle, renderOptions],
  );

  useEffect(() => {
    dispatch(getTierOptionsThunk());
  }, [dispatch]);

  return (
    <Controller
      name={name}
      render={renderDropdown}
      control={controlField}
      rules={{
        validate,
      }}
    />
  );
};
