import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ContainerFluid } from 'common/styles';
import { Header } from 'modules/core/components/Header/Header';
import { EMPTY_STRING, ICONS_HEIGHT } from 'modules/core/constants';
import { PermissionWrapper } from 'modules/PermissionWrapper';
import { Icon, NavigationLink } from 'modules/core/components';
import { ALL } from 'modules/PermissionWrapper/roleGroups.const';
import { useSelector } from 'react-redux';
import { getUser } from 'store/selectors/auth.selector';
import { Roles } from 'modules/core/enums';
import {
  getManagerRedirectPath,
  getSalespersonRedirectPath,
  getRolesDashboardNavigation,
} from 'router/const/dashboard.const';
import { getLendersList } from 'store/selectors/lenders.selector';
import { getDisabledFeaturesList } from 'store/selectors/ui.selector';
import { ROUTER_PATH } from 'router/const/routerPath.const';

import { useLinkConfig } from './useLinkConfig';
import { ERROR_404_NOT_FOUND, ERROR_PAGE_BODY_MAP } from './error.const';
import { ErrorBody, ErrorCard, ErrorHeader, ErrorLinks, ErrorWrapper } from './styles';

interface IErrorPage {
  errorCode: number;
}

export const ErrorPage: React.FC<IErrorPage> = ({ errorCode = ERROR_404_NOT_FOUND }) => {
  const { t } = useTranslation();
  const linksProps = useLinkConfig();
  const user = useSelector(getUser);
  const lenders = useSelector(getLendersList);
  const disabledFeatures = useSelector(getDisabledFeaturesList);

  if (user?.is_sso) {
    const { path } = getRolesDashboardNavigation(user, disabledFeatures, lenders);

    linksProps[linksProps.length - 1] = {
      ...linksProps[linksProps.length - 1],
      path: path,
    };
  } else if (user && [Roles.MANAGER, Roles.SALESPERSON].includes(user.role)) {
    linksProps[linksProps.length - 1] = {
      ...linksProps[linksProps.length - 1],
      get path() {
        if (user.role === Roles.MANAGER) {
          const path = getManagerRedirectPath(disabledFeatures, lenders);

          return path === EMPTY_STRING ? '/manager-admin/' : path;
        }

        return getSalespersonRedirectPath(disabledFeatures);
      },
    };
  }

  const links = useMemo(
    () =>
      linksProps.map(({ isExternal, path, iconSrc, allowedRoles, title }) => {
        if (path !== ROUTER_PATH.ERROR) {
          return (
            <PermissionWrapper allowedRoles={allowedRoles || ALL}>
              <Icon imageSrc={iconSrc} height={ICONS_HEIGHT.MIDDLE} />
              <NavigationLink isExternal={isExternal} path={path}>
                {title}
              </NavigationLink>
            </PermissionWrapper>
          );
        }

        return null;
      }),
    [linksProps],
  );

  return (
    <ContainerFluid fluid>
      <Header />
      <ErrorWrapper>
        <ErrorCard>
          <ErrorHeader>
            <h1>{`${errorCode} ${t('components.errorPage.errorTitle')}`}</h1>
          </ErrorHeader>
          <ErrorBody>
            <p>{ERROR_PAGE_BODY_MAP.get(errorCode)}</p>
            <p>{t('components.errorPage.message.contactAdministrator')}</p>
          </ErrorBody>
          <ErrorLinks>{links}</ErrorLinks>
        </ErrorCard>
      </ErrorWrapper>
    </ContainerFluid>
  );
};
