export const END_USER_AGREEMENT_FIRST_PART = [
  `<p>
      This End User Agreement ("<u>Agreement</u>") is between you, the end user, ("<u>you</u>") and
      Streamline Auto Group, LLC ("<u>Streamline</u>", "<u>us</u>" or "<u>we</u>"). By downloading,
      installing, accessing or using this or any other Streamline application, website and/or any accompanying software
      or service (individually and collectively, the "<u>Streamline Product</u>") or by accepting this Agreement by
      electronic means, you are bound by this Agreement.
  </p>`,
  `<h5>
      This Agreement contains provisions that exempt Streamline and other persons from liability
      or limits their liability, specify the forum and jurisdiction for resolution or disputes, waive the
      right to class actions, and other important provisions.
  </h5>`,
  `<p>
      Streamline may amend this Agreement (i.e. change, delete or add new provisions) at any time,
      which amendments will become effective immediately when posted. It is your responsibility to review
      this Agreement before you use the Streamline Product because your use of the Streamline Product
      (or, if applicable, your acceptance of this Agreement by electronic means) after we make
      amendments to this Agreement will be deemed to be your agreement to the Agreement, as
      amended. This Agreement governs any upgrades or updates to the Streamline Product, unless a
      separate agreement accompanies the upgrade or update.
  </p>`,
  `<p>
      Additional notices, terms, and conditions may apply to receipt of services, participation in a particular program,
      training, authorized user registration to the Streamline Product or specific portions and features of the Streamline Product,
      all of which are made a part of this Agreement by this reference.
  </p>`,
  `<p>
      Although it may not be required, if you accept this Agreement via a click-through, you agree that
      your acceptance of this Agreement by electronic means, including an “accept” or similar button or
      checkbox or other electronic signature process, indicates your intent to be bound and is binding
      upon you and enforceable in the same manner as a handwritten signature.
  </p>`,
  `<p>
      1. <b><u>Eligibility.</u></b> Streamline provides various software services, including the Streamline Product,
      to certain Streamline-authorized dealerships ("<u>Participating Dealerships</u>"). The Participating
      Dealerships may allow access to the Streamline Product to their employees, representatives and
      customers, and to certain Streamline-authorized lending institutions (“Participating Lenders”) and
      their employees, in each case, as permitted under that Participating Dealership’s agreement with
      Streamline. By using the Streamline Product, you represent and warrant that you are authorized by a
      Participating Dealership to use the Streamline Product. You are only permitted to use the Streamline
      Product in connection with your (or, if you are an employee of a Participating Lender, that
      Participating Lender’s) relationship with Participating Dealership. The Streamline Product is only
      available to persons over the age of 18.
  </p>`,
  `<p>
      2. <b><u>Grant of Rights.</u></b> <br>
      (a) Subject to your compliance with this Agreement, Streamline hereby grants to you a personal,
      limited, non-exclusive, non-transferable, non-sublicensable, revocable right to use the Streamline
      Product (i) if you are a customer, for your personal and noncommercial use in connection with your
      relationship with the applicable Participating Dealership, (ii) if you are an employee or representative
      of a Participating Dealership, for the internal business purposes of that Participating Dealership, or
      (iii) if you are an employee of a Participating Lender, for the internal business purposes of that
      Participating Lender in connection with their relationship with the applicable Participating Dealership.
      Streamline may change, suspend, or discontinue any aspect of the Streamline Product or your right
      to use the Streamline Product at any time. <br>
      (b) Except for the limited license granted to you in this Agreement, Streamline, its affiliates
      and licensors retain all right, title and interest in the Streamline Product, including all of its content, all
      upgrades and updates, and all related intellectual property rights. If you provide feedback regarding
      the Streamline Product, Streamline will own all rights in such feedback and you irrevocably assign to
      Streamline, and otherwise waive, any and all rights you may have to such feedback. <br>
      (c) Streamline may exercise whatever means it deems necessary to prevent unauthorized use of the Streamline Product,
      including technological barriers, IP mapping, and directly contacting your Internet Service Provider (ISP)
      regarding such unauthorized use.
  </p>`,
  `<p>
      3. <b><u>User Content.</u></b> <br>
      (a) You may be able to input or upload text, data, pictures and other content (collectively,
      "<u>User Content</u>") to or through the Streamline Product. User Content shall also include data that you
      authorize Streamline to collect. By so authorizing Streamline or distributing or disseminating User
      Content through the Streamline Product, you hereby grant to Streamline a non-exclusive, worldwide,
      perpetual, royalty-free, paid-up, transferable and sub-licensable right and license to use, host,
      transfer, display, perform, reproduce, copy, distribute and make derivative works of your User
      Content (and any copyrights, publicity, database and other proprietary rights therein) in connection
      with our provision of the Streamline Product, and/or, in anonymous form, for Streamline’s marketing
      and promotional purposes, in each case, in any media formats and through any media channels.
      Additionally, you hereby grant to Streamline, the Participating Dealer, the Participating Lenders, and
      each other user that is authorized to access your User Content a worldwide, perpetual, royalty-free,
      paid-up, transferable and sub-licensable, non-exclusive right and license to access and use such
      User Content in the manner contemplated by this Agreement and/or the Streamline Product. The
      foregoing licenses shall survive any termination of this Agreement or your use of the Streamline Product. <br>
      (b) You are solely responsible for your User Content and the consequences of posting or publishing it. By uploading and publishing
      your User Content, you affirm, represent, and warrant that you are the creator and owner of or have the necessary licenses, rights,
      consents, and permissions to use and to authorize Streamline, the Participating Dealer, the Participating Lenders
      and Streamline’s users to use and distribute your User Content as
      necessary to exercise the licenses granted by you in this Section and in the manner contemplated by Streamline and this Agreement. <br>
      (c) Streamline will not use or otherwise process any User Content for the purpose of serving as a factor in establishing any
      consumer’s eligibility for any the purposes stated in Section 603(d)(1)(A)-(B) or Section 604 of the Federal Fair Credit Reporting
      Act, as may be amended or superseded.
  </p>`,
  `<p>
      4. <b><u>Prohibited Conduct. </u></b> You will not: <br>

      (a) copy or distribute the Streamline Product; <br>
      (b) assign, sublicense, rent, timeshare, loan, lease or otherwise transfer the Streamline Product; <br>
      (c) allow any third party to use or copy the Streamline Product, directly or indirectly; <br>
      (d) post or transmit through the Streamline Product any material that (i) is unlawful, threatening, abusive,
      defamatory, vulgar, obscene, profane or otherwise objectionable, as reasonably determined by Streamline;
      (ii) violates or infringes the rights of others, including intellectual property, moral rights, privacy
      and publicity rights, (iv) that gives rise to civil or criminal liability; (v) is an advertisement or solicitation;
      (vi) is inaccurate, false or misleading; (vii) contains any viruses or other malicious code, file, or program;
      or (viii) depicts or encourages violence, or make any threats to commit violent acts; <br>
      (e) reverse engineer, decompile, disassemble, modify or create works derivative of the Streamline Product; <br>
      (f) remove any proprietary notices (e.g., copyright and trademark notices) from the Streamline Product; <br>
      (g) use the Streamline Product beyond its intended use; or <br>
      (h) use the Streamline Product in violation of any law.
  </p>`,
  `<p>
      In addition to its other rights and remedies under this Agreement, Streamline may refer any suspected any
      activity that violates this Section (and any User Content) to the Participating
      Dealership, the Participating Lenders and appropriate law enforcement authorities.
  </p>`,
  `<p>
      5. <b><u>Account Security.</u></b> You may be required to register as an authorized user for access to the
      Streamline Product, which may require a user name, a password and verification factors
      (collectively, "<u>Authorized User Credentials</u>"), and/or use of multi-factor authentication (MFA) and/or
      single sign-on (SSO). You may also have to provide certain registration information, all of which
      must be accurate and updated. You are not allowed to share or give your Authorized User
      Credentials to anyone else. You are responsible for maintaining the confidentiality of your password
      and verification factors. You agree not to use another person’s Authorized User Credentials or select
      Authorized User Credentials: (a) with the intent to impersonate another person; (b) subject to the
      rights of another person without authorization; (c) in violation of the intellectual property rights of
      another person; or (d) that Streamline, in its sole discretion, deems inappropriate or offensive or
      discriminates on the basis of race, color, sex, language, religion, political or other opinion, national or
      social origin, property, birth or other status. You have no ownership or other property interest in your
      account and/or user name, and all rights in and to your user name and account are and will forever
      be owned by and inure to the benefit of Streamline. Streamline will not be liable for any loss or
      damage arising from your failure to comply with these obligations. Streamline may delete or change
      Authorized User Credentials (with notice) at any time and for any reason. You may be liable for the
      losses incurred by Streamline or others due to any unauthorized use of your Streamline Product account.
  </p>`,
  `<p>
      6. <b><u>Third-Party Content.</u></b> The Streamline Product may include links or references to other
      information, content, resources, applications, tools, products, web sites or services (collectively,
      "<u>Third-Party Content</u>") solely as a convenience to you and other users. The inclusion of links or
      references to Third-Party Content does not represent an endorsement or approval by Streamline
      with respect to any such Third-Party Content, or the providers thereof. Streamline is not responsible
      for the availability of any Third-Party Content. Streamline expressly disclaims responsibility for the
      Third-Party Content, including any products and services provided by, available through, or
      advertised on the Third-Party Content. In addition, your dealings with any Third-Party Content found
      on or through the Streamline Product are solely between you and the applicable third party. Access
      and use of Third-Party Content is solely at your own risk.
  </p>`,
  `<p>
      7. <b><u>Termination and Suspension.</u></b> Streamline may immediately terminate this Agreement or suspend your right to use
      the Streamline Product at any time without cause or notice.
  </p>`,
  `<p>
      8. <b><u>Disclaimers; No Warranties.</u></b> TO THE MAXIMUM EXTENT PERMITTED BY LAW,
      STREAMLINE, AND ITS AFFILIATES, PARTICIPATING DEALERSHIPS, PARTICIPATING
      LENDERS, PARTNERS, REPRESENTATIVES, THIRD-PARTY PROVIDERS, LICENSORS AND
      SUPPLIERS (COLLECTIVELY, "AFFILIATES") DISCLAIM ALL WARRANTIES, STATUTORY,
      EXPRESS OR IMPLIED, INCLUDING IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS
      FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT OF PROPRIETARY RIGHTS.
      NOTHING, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM STREAMLINE OR
      THROUGH THE STREAMLINE PRODUCT WILL CREATE ANY WARRANTY NOT EXPRESSLY
      STATED HEREIN. YOUR USE OF THE STREAMLINE PRODUCT IS AT YOUR SOLE RISK, AND
      NONE OF STREAMLINE NOR ANY OF ITS AFFILIATES IS RESPONSIBLE OR LIABLE FOR
      YOUR ACTIONS RELATED THERETO OR FOR ANY DECISIONS YOU MAKE BASED THEREON.
      THE STREAMLINE PRODUCT AND ANY DATA, INFORMATION, THIRD-PARTY SOFTWARE,
      CONTENT, USER CONTENT, COMMERCIAL CONTENT, THIRD-PARTY CONTENT, SERVICES,
      OR APPLICATIONS MADE AVAILABLE IN CONJUNCTION WITH OR THROUGH THE
      STREAMLINE PRODUCT ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE", "WITH ALL
      FAULTS" BASIS AND WITHOUT WARRANTIES OR REPRESENTATIONS OF ANY KIND EITHER
      EXPRESS OR IMPLIED. STREAMLINE AND ITS AFFILIATES DO NOT WARRANT THAT THE
      DATA, CONTENT, USER CONTENT, COMMERCIAL CONTENT, FUNCTIONS, OR ANY OTHER
      INFORMATION OFFERED ON OR THROUGH THE STREAMLINE PRODUCT OR ANY THIRD-PARTY
      CONTENT WILL BE UNINTERRUPTED, SECURE, OR FREE OF ERRORS, VIRUSES OR
      OTHER HARMFUL COMPONENTS AND DO NOT WARRANT THAT ANY OF THE FOREGOING
      WILL BE CORRECTED. STREAMLINE AND ITS AFFILIATES DO NOT WARRANT OR MAKE ANY
      REPRESENTATIONS REGARDING THE USE OR THE RESULTS OF THE USE OF THE
      STREAMLINE PRODUCT OR ANY THIRD-PARTY CONTENT IN TERMS OF CORRECTNESS,
      ACCURACY, RELIABILITY, GUARANTEEING ANY OUTCOME, OR OTHERWISE. NOTHING,
      WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM STREAMLINE OR THROUGH THE
      STREAMLINE PRODUCT WILL CONSTITUTE FINANCIAL, LEGAL OR OTHER ADVICE. NONE
      OF STREAMLINE NOR ANY OF ITS AFFILIATES IS RESPONSIBLE OR LIABLE FOR ANY
      INTERNET, NETWORK, CONNECTIVITY, COMMUNICATIONS OR OTHER ISSUES THAT
      IMPACT YOUR ABILITY TO ACCESS AND/OR USE THE STREAMLINE PRODUCT.
  </p>`,
  `<p>
      9. <b><u>Limitation of Liability and Damages.</u></b> TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT WILL
      STREAMLINE OR ITS AFFILIATES BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, SPECIAL, GENERAL, INCIDENTAL,
      CONSEQUENTIAL, PUNITIVE, AGGRAVATED OR INDIRECT DAMAGES (WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE), OR OTHERWISE),
      WHICH INCLUDE, WITHOUT LIMITATION, DAMAGES FOR PERSONAL INJURY, LOST PROFITS, LOST DATA AND BUSINESS INTERRUPTION,
      ARISING OUT OF THE USE OR INABILITY TO USE THE STREAMLINE PRODUCT, OR ANY USER CONTENT, THIRD-PARTY CONTENT,
      OR OTHER ONLINE OR OFFLINE INTERACTION FACILITATED THROUGH USE OF THE STREAMLINE PRODUCT, EVEN IF STREAMLINE
      HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN ANY CASE, THE ENTIRE LIABILITY OF STREAMLINE AND ITS
      AFFILIATES UNDER THIS AGREEMENT FOR ALL DAMAGES, LOSSES, AND CAUSES OF ACTION (WHETHER IN CONTRACT, TORT
      (INCLUDING NEGLIGENCE), OR OTHERWISE) IS LIMITED TO THE AMOUNT PAID BY YOU, IF ANY, FOR THE STREAMLINE
      PRODUCT OR $100, WHICHEVER IS GREATER.
  </p>`,
  `<p>
      10. <b><u>Indemnification.</u></b> You will indemnify, defend and hold Streamline and its Affiliates and its
      and their respective directors, officers, employees and agents harmless from any claims, losses, damages,
      liabilities and expenses (including reasonable attorney’s fees and costs) arising out of or relating to
      your use or misuse of the Streamline Product, your User Content, or any violation by you of this Agreement.
      Streamline reserves the right, at your expense, to assume the exclusive defense and control of any matter
      for which you are required to indemnify Streamline, and you agree to cooperate with Streamline’s defense
      of these claims. Streamline will use reasonable efforts to notify you of any such claim, action,
      or proceeding upon becoming aware of it.
  </p>`,
  `<p>
      11. <b><u>Copyright and Digital Millennium Copyright Act.</u></b> Streamline is committed to complying with copyright and related laws,
      and requires all users of the Streamline Product to comply with these laws. Accordingly, you may not store any
      material or content or use or disseminate any material or content though the Streamline Product in
      any manner that constitutes an infringement of third-party intellectual property rights, including rights granted by copyright law.
      Owners of copyrighted works who believe that their rights under copyright law have been infringed
      may take advantage of certain provisions of the Digital Millennium Copyright Act of 1998 (the "DMCA") to report alleged
      infringements. You may not post, modify, distribute, or reproduce in any way any copyrighted material, trademarks,
      or other proprietary information belonging to others without obtaining the prior written consent
      of the owner of such proprietary rights.
  </p>`,
  `<p>
      Streamline will terminate the use privileges of any user who repeatedly infringes the copyright rights of others
      upon receipt of proper notification to Streamline by the copyright owner or the copyright owner’s legal agent.
      If you feel that a posted message is objectionable or infringing, we encourage you to contact us immediately.
      Upon our receipt of a proper notice of claimed infringement under the DMCA, we will respond expeditiously to remove,
      or disable access to, the material claimed to be infringing and will follow the procedures specified in the DMCA to
      resolve the claim between the notifying party and the alleged infringer who provided the content in issue.
      Streamline’s designated agent (i.e., the proper party) to whom you should address such notice is listed below.
      If you believe that your work has been copied and posted using the Streamline Product in a way that constitutes
      copyright infringement, please provide our designated agent with the following information: <br>
      (a) an electronic or physical signature of the person authorized to act on behalf of the owner of the copyright
      or other intellectual property interest; <br>
      (b) a description of the copyrighted work or other intellectual property that you claim has been infringed; <br>
      (c) a description of where the material that you claim is infringing is located to permit us to locate the material; <br>
      (d) your address, telephone number, and email address; <br>
      (e) a statement by you that you have a good faith belief that the disputed use is not authorized by the copyright
      or intellectual property owner, its agent, or the law; and <br>
      (f) a statement by you, made under penalty of perjury, that the information contained in your report is accurate
      and that you are the copyright or intellectual property owner or authorized to act on
      the copyright or intellectual property owner’s behalf. <br>
      Notice of claims of copyright infringement should be sent to our agent at
      <a href="mailto:info@streamline.auto" target="_top">info@streamline.auto</a>
      with the subject line DMCA. Address your correspondence to: DMCA Officer.
  </p>`,
  `<p>
      12. <b><u>Third-Party Beneficiary.</u></b> Streamline’s Affiliates (and each of our and their respective
      successors and assigns) will be considered third-party beneficiaries of this Agreement and will be
      entitled to bring a direct action against you in the event of breach of any applicable provisions of this
      Agreement. In addition, if  you access the Streamline Product using an Android,
      Apple iOS, or Microsoft Windows-powered device, or the Amazon cloud interface, then Google,
      Inc. ("<u>Google</u>"), Apple Inc. ("<u>Apple</u>"), Microsoft Corporation ("<u>Microsoft</u>"), and Amazon Digital
      Services, Inc. ("<u>Amazon</u>"), respectively, shall be a third-party beneficiary to this Agreement.
      However, these third-party beneficiaries are not a party to this Agreement, and are not responsible
      for the provision or support of the Streamline Product.
  </p>`,
  `<p>
      13. <b><u>Network.</u></b> If you access the Streamline Product via a mobile device, your network provider's messaging,
      data and other rates, terms and fees may apply when you access and use the Streamline Product.
  </p>`,
];

export const END_USER_AGREEMENT_SECOND_PART = [
  `<p>
      15. <b><u>General.</u></b> This Agreement will be governed by and construed in accordance with the laws of the
      State of New York, without giving effect to any principles of conflicts of law. The parties agree that
      the federal and state courts residing in Erie County, New York will have exclusive jurisdiction over
      this Agreement. You will not consolidate or seek class treatment for any claim with respect to the
      Streamline Product and you will not join any purported class action involving the Streamline Product.
      Recognizing and acknowledging that any breach of the provisions of Sections 2 or 4 will cause
      irreparable damage for which other remedies may be inadequate, in addition to monetary damages
      and any other remedies available at law or in equity, Streamline will have the right to petition for
      such equitable relief as may be necessary to prevent such a breach or threatened breach without
      having to post a bond or prove actual damages. This Agreement will not be governed by the 1980
      U.N. Convention on Contracts for the International Sale of Goods. This Agreement constitutes the
      entire understanding and agreement between Streamline and you with respect to the transactions
      contemplated in this Agreement and supersedes all prior or contemporaneous oral or written
      communications with respect to the subject matter of this Agreement, all of which are merged in this
      Agreement. You may not assign this Agreement without our prior written consent. Any attempted
      assignment in violation of this Agreement will be invalid. This Agreement is binding upon and inures
      to the benefit of each party’s respective successors and permitted assigns. The heading references
      herein are for convenience purposes only, do not constitute a part of this Agreement, and will not be
      deemed to limit or affect any of the provisions hereof. For purposes of this Agreement, (i) the words
      "include," "includes," and "including" are deemed to be followed by the words "without limitation"; (ii)
      the word "or" is not exclusive; (iii) the words "herein," "hereof," "hereby," "hereto," and "hereunder"
      refer to this Agreement as a whole; and (iv) words denoting the singular have a comparable meaning
      when used in the plural, and vice-versa. You may not modify, amend or alter in any way this
      Agreement but we may amend it as provided in this Agreement. In the event any provision of this
      Agreement is found invalid or unenforceable pursuant to judicial decree, the remainder of this
      Agreement will remain valid and enforceable according to its terms. Any failure by Streamline to
      enforce any provision of this Agreement will not operate as a waiver of that provision or any
      subsequent breach of that provision. No waiver by Streamline will be effective unless in writing and
      duly executed by Streamline. The disclaimers and limitations of liability and any other terms that by
      their nature are intended to survive will survive any termination or expiration of this Agreement.
      IT IS EXPRESSLY UNDERSTOOD AND AGREED THAT IN THE EVENT ANY REMEDY HEREUNDER IS DETERMINED TO HAVE FAILED OF ITS ESSENTIAL
      PURPOSE, ALL LIMITATIONS OF LIABILITY AND EXCLUSIONS OF DAMAGES WILL REMAIN IN EFFECT.
  </p>`,
  `<p>
      16. <b><u>Notices.</u></b> You agree to receive electronically all communications, agreements, documents,
      notices and disclosures that we provide in connection with this Agreement, your account and your
      use of the Streamline Product. Notices may include email, in-app messages and in-website chat communications.
  </p>`,
  `<p>
      17. <b><u>Contact.</u></b> Any questions, complaints or claims regarding the Streamline Product
      should be directed to: <a href="mailto:info@streamline.auto" target="_top">info@streamline.auto</a>
  </p>`,
];

export const PRIVACY_POLICY = [
  `<p>
      This Privacy Policy ("<u>Privacy Policy</u>") applies to information collected through any Streamline Auto Group,
      LLC ("<u>Streamline</u>", "<u>us</u>" or "<u>we</u>") website, application and/or any accompanying software
      or service (individually and collectively, the "<u>Streamline Product</u>").
      It also applies to interactions with us in person, by telephone, text message, or other means.
  </p>`,
  `<p>
      Your privacy is very important to us and we have developed this policy in order for you to understand
      how we collect, use, communicate and disclose information. This Privacy Policy governs information we
      receive from you or about you and explains how we may collect,
      use, and in some instances share this information.
  </p>`,
  `<p>
      Streamline may amend this Privacy Policy at any time. When we revise the Privacy Policy,
      we will post the new Privacy Policy on this page and change the effective date located at the end of the
      Privacy Policy. You should review this Privacy Policy before you use a Streamline Product or interact with us.
  </p>`,
  `<h5>
      What information we collect and how we use it
  </h5>`,
  `<p>
      1. <b><u>Information you provide.</u></b> Streamline collects personal information that individuals provide through
      voluntary submissions, such as email and online registration forms. Personal information means any
      information that may be used to identify an individual or household, including name and email address.
      We may also collect limited, high-level financial information to enable our budget calculation function.
      The types and amount of personal information we collect may vary, depending on what information you choose to provide.
      Some of the ways in which we use this information are to: <br><br>
      (a) provide our application services, <br>
      (b) provide you with content relevant to you, <br>
      (c) offer personalized features, <br>
      (d) tailor your user experience, <br>
      (e) allow you to connect to and share information with other users, <br>
      (f) validate your identity, <br>
      (g) prevent fraud, and <br>
      (h) provide customer services including technical support. <br>
  </p>`,
  `<p>
      When you allow others to view your profile, send messages, post messages, or otherwise share personal
      information with other users, we cannot control and are not responsible for the actions of other users
      in handling the information.
  </p>`,
  `<p>
      2. <b><u>Information from other sources.</u></b> We may also collect information about you from other sources,
      such as from other users of our services, our service providers and vendors, and
      other third party sources that you have permitted to share your information with us,
      in order to provide our services, more useful information, and a more personalized experience.
  </p>`,
  `<p>
      3. <b><u>Messages and Content.</u></b> We may collect and store any messages, posts or content that
      you send or upload using the Streamline Product. We may also remove any messages,
      posts or content that we find objectionable or that violate our Master Services Agreement
      with dealers or the Streamline End User License Agreement.
  </p>`,
  `<p>
      4. <b><u>Employment-Related Information.</u></b> If you apply for a job with us we may collect personal
      information in a number of circumstances. Personal information we may collect includes: name,
      address, telephone number and other contact information; educational or other credentials,
      employment history and experience, volunteer work, and other information necessary to evaluate
      a candidate’s suitability for employment; together with any other information we may collect
      with your consent or as required by applicable law.
  </p>`,
  `<p>
      5. <b><u>Automatically Collected Information.</u></b> Certain information is automatically collected when
      you use a Streamline Product. This can include the type of browser software you use, the
      operating system you’re running, the website that referred you, or your IP address. Our partners
      or we may also collect information from you when you use the Streamline Product in the form of
      cookies. A cookie is a text file that a web-service can store on your web browser and later
      retrieve. A cookie enables the Streamline Product to recognize you as a prior user, provides
      the Streamline Product with information regarding your prior use and assists us in determining
      what portions of the Streamline Product are most appropriate for you. Your Web browser may provide
      you with the ability to reject cookies. To take full advantage of the Streamline Product, we
      recommend that you accept cookies from the Streamline Product. If you reset your browser to
      refuse all cookies or to indicate when a cookie is being sent, some services may not function
      properly. Additionally, your browser may have a “Do Not Track” feature. This lets you tell
      websites you visit that you do not want to have your online activity tracked. These features are
      not yet uniform across browsers. Streamline Products do not currently respond to these signals.
  </p>`,
  `<h5>
      What we use information for
  </h5>`,
  `<p>
      We may use and/or share your information and information we collect in connection with your use of
      the Streamline Product as follows: <br><br>
      (a) to improve the Streamline Product; <br>
      (b) if you provide information in response to any forms that we host for third parties, we will
      transmit your personal information to those parties identified in the applications, contributions,
      or other forms you choose to submit and, if applicable, to the third party service providers
      specified by such third parties. The third party’s privacy policies and practices, and not this
      Privacy Policy, govern the third party’s use of that personal information; <br>
      (c) to disclose information to companies that provide support services to us (e.g. website hosts,
      software developers, researchers) that allow us to provide the Streamline Product and that help
      us market our Streamline Product, which companies may use information about you to perform
      their functions on our behalf; <br>
      (d) to communicate with you about the Streamline Product, accounts and program participation, based on
      your requests, profiles, patterns, habits, preferences and usage statistics, which may be through
      notifications pushed to the Streamline Product on your mobile device; <br>
      (e) to process and manage your use of the Streamline Product; <br>
      (f) to respond to your inquiries, post your comments or statements on any blog,
      social media site or other online forum maintained in connection with the Streamline Product,
      or take other actions in response to your inquiries or other Streamline Product related activities; <br>
      (g) if you participate in any blog, social media or other online forum in connection with the
      Streamline Product, we may disclose personal information that you post to other forum
      participants and users of the Streamline Product; <br>
      (h) with respect to the information you provide or information we collect about you in
      connection with the Streamline Product, we may (i) analyze and disclose that information
      either in anonymized format that does not directly identify you or when aggregated with anonymized
      information from other users of the Streamline Products, (ii) use it to develop new products or services or
      to modify existing products or services, including the Streamline Product, and (iii)
      include that information with similar information from other users of the Streamline Product and
      disclose it to third parties. When we use anonymized information, we will take steps to ensure
      we do not re-identify it. If we share anonymized or aggregated data with a third party, we
      obligate the third party contractually not to re-identify.
  </p>`,
  `<p>
      We also may use and disclose information (1) upon lawful request, in response to legal process,
      and when required to comply with laws, (2) to enforce our agreements, corporate policies, and terms
      of use or (3) to protect the rights, property or safety of our employees, agents, customers,
      other users of the Streamline Product and other third-parties), (4) to investigate, prevent or take
      action regarding suspected or actual illegal activities or to assist government enforcement agencies;
      (5) to investigate, prevent or take action regarding any violation of this Privacy Policy or the
      Master Services Agreement, (6) if doing so is appropriate to carry out a user’s request, reasonably
      necessary to offer the service, or reasonably believed to be legally required, or is in Streamline’s
      interest to protect its property or other legal rights or the rights or property of others and/or (7)
      to investigate and defend us against any third-party claims or allegations, or to protect the
      security or integrity of our Streamline Product.
  </p>`,
  `<p>
      We may also provide information we receive from you to our subsidiaries, affiliated companies, or other
      trusted businesses or persons for the purpose of processing personal information on our behalf,
      facilitating our business, and for any other purposes identified above.
  </p>`,
  `<p>
      In connection with any sale of the assets or equity, a financing or change-of-control of Streamline or
      its affiliates we may disclose, share and/or transfer personal information and data we have collected
      with third-parties involved in such transactions (for example, if we sell the Streamline Product to a
      third-party buyer, we will share and transfer information about you and other users with and to that buyer).
  </p>`,
  `<h5>
      Children
  </h5>`,
  `<p>
      We do not knowingly request or collect personal information from anyone under the age of 13.
      Streamline’s website and its services are not intended for or designed to be used by anyone
      under 13 years of age. If you use our service, you represent that you are 13 years of age or
      older. We recommend that minors over the age of 13 ask their parents for permission before
      sending any information about themselves to anyone over the Internet.
  </p>`,
  `<h5>
      Links
  </h5>`,
  `<p>
      Streamline’s website and the Streamline Product may contain links to non-Streamline websites
      or content, such as to financial institutions’ webpages. We are not responsible for the privacy
      practices of other websites. We encourage our users to review the privacy terms of other websites.
  </p>`,
  `<h5>
      Security
  </h5>`,
  `<p>
      We use reasonable administrative, technical, and physical safeguards to protect your information
      from unauthorized access, disclosure, alteration, or destruction. However, please note that this is
      not a guarantee that such information may not be accessed, disclosed, altered, or destroyed. No
      data transmissions over the Internet can be guaranteed to be 100% secure. Consequently, we
      cannot ensure or warrant the security of any information you transmit to us and you do so at your own risk.
  </p>`,
  `<h5>
      Data Rights
  </h5>`,
  `<p>
      Subject to applicable law, you may have the right to request and obtain information about, or
      copies of, your personal information that we process, where we obtained your information, the
      business or commercial purpose for collecting your information, and the third parties with whom
      your information is shared. You may also have the right to correct your personal information, or
      object to its processing. Lastly, you may ask us for information about when we typically delete
      stored information, or ask that information be anonymized or destroyed, depending on the
      situation and applicable laws. To enquire about any data rights you may have, please contact us
      at <a href="mailto:info@streamline.auto" target="_top">info@streamline.auto</a>.
  </p>`,
  `<h5>
      How to contact us
  </h5>`,
  `<p>
      If you have any questions about this Privacy Policy or how we handle personal information, please email us at
      <a href="mailto:info@streamline.auto" target="_top">info@streamline.auto</a> or by mail at:
  </p>`,
  `<p>
      Streamline Auto Group, LLC <br>
      ATTN: Privacy Manager <br>
      3552 Southwestern Boulevard <br>
      Orchard Park, New York 14127
  </p>`,
  `<p>
      Effective Date: April 11, 2023
  </p>`,
];
