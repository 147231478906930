import styled from 'styled-components';
import { Accordion as AccordionBootstrap, Tabs, Card, Col } from 'react-bootstrap';
import { WindowBreakpoints } from 'modules/core/enums/windowBreakpoints.enum';

import { ChevronDown } from '../Icons';
import { COLORS } from '../../theme/colors';

export const FilterLabel = styled.div`
  padding-bottom: 6px;
  color: ${({ theme: { colors } }) => colors['grey-3']};
  font-size: ${({ theme: { fontSize } }) => fontSize.sm};

  @media (min-width: ${WindowBreakpoints.xxxLarge + 1}px) {
    height: 42px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
`;

export const FilterSection = styled.div`
  display: flex;
  flex-wrap: nowrap;

  @media (max-width: ${WindowBreakpoints.xxxLarge}px) {
    flex-wrap: wrap;
  }

  & > div.col-sm-4 {
    @media (min-width: ${WindowBreakpoints.xxxLarge + 1}px) {
      flex: auto;
      max-width: 200px;
    }
  }
`;

export const FilterCol = styled(Col)`
  padding: 4px 6px;
`;

export const Accordion = styled(AccordionBootstrap)`
  width: 100%;

  .nav-item {
    .nav-link {
      color: ${COLORS.GREY_21};
    }
    .nav-link: hover {
      color: ${COLORS.BLACK};
    }
  }
`;

export const AccordionToggle = styled(AccordionBootstrap.Button)`
  border: none;
  text-transform: uppercase;
  font-weight: ${({ theme: { boldText } }) => boldText.dark};
  font-size: ${({ theme: { fontSize } }) => fontSize.sm};
  color: ${COLORS.GREY_21} !important;
  background-color: inherit !important;
  box-shadow: none !important;
  z-index: 0 !important;
`;

export const CardBody = styled(Card.Body)`
  padding-top: 0;
`;

export const ChevronDisplayFilters = styled(ChevronDown)<{
  active?: boolean;
}>`
  margin-right: 10px;
  opacity: 1;
  transition: all 0.3s ease;
  transform: ${({ active }) => (active ? 'rotate(-90deg)' : 'rotate(0deg)')};

  @media (max-width: ${WindowBreakpoints.medium}px) {
    margin-left: 0;
  }
`;

export const AdditionalComponent = styled.div`
  padding-top: 10px;
`;

export const FilterTabs = styled(Tabs)`
  margin: 0 6px;
  border-bottom: 1px ${COLORS.WHITE_9} solid;

  @media (max-width: ${WindowBreakpoints.medium}px) {
    margin: 0;
  }

  & > .nav-item {
    color: ${COLORS.GREY_21};
  }
`;

export const Label = styled.div`
  padding-bottom: 6px;
  color: ${({ theme: { colors } }) => colors['grey-3']};
  font-size: ${({ theme: { fontSize } }) => fontSize.sm};
`;

export const HelpMessage = styled.div`
  font-size: 14px;
  color: ${COLORS.GREY_23};
`;

export const ErrorMessageUI = styled.div`
  margin: 10px 0;
  font-size: 14px;
  color: ${COLORS.RED_6};
`;

export const StockSection = styled.div`
  display: flex;
  padding: 0 6px;

  @media (min-width: ${WindowBreakpoints.small + 1}px) {
    justify-content: space-between;
  }

  @media (max-width: ${WindowBreakpoints.small}px) {
    flex-direction: column;
    .col-2 {
      justify-content: flex-start;
    }
  }

  @media (max-width: ${WindowBreakpoints.medium}px) {
    & > div {
      padding-left: 0;
      padding-right: 0;
    }
  }
`;

export const ToggleContainer = styled.div`
  height: 34px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
