import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { ROUTER_PATH } from 'router/const/routerPath.const';
import { setCurrentLenderId } from 'store/reducers/lenders.reducer';
import { getLendersFullIds } from 'store/selectors/lenders.selector';
import { AppDispatch } from 'store/store';

import { Lender } from './Lender';

// This component is necessary for the correct operation (state switching) of the react-hook-form when the user switches between Landers.
export const LenderController: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const LendersFullIds = useSelector(getLendersFullIds);
  const { lenderFullId } = useParams<{ lenderFullId: string }>();
  const history = useHistory();

  const [isLenderDisplayed, setIsLenderDisplayed] = useState(false);

  useEffect(() => {
    dispatch(setCurrentLenderId(lenderFullId));
    const isLender = LendersFullIds.includes(lenderFullId);

    if (!isLender) history.push(ROUTER_PATH.ERROR);
    setIsLenderDisplayed(false);
    setTimeout(() => setIsLenderDisplayed(true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lenderFullId]);

  return isLenderDisplayed ? <Lender /> : <></>;
};
