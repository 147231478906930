import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  getAdjustEventMessagesThunk,
  getControlNumbersThunk,
  getFeesAndTaxesDataThunk,
  getInterestRateListThunk,
  getSubventionYearDataThunk,
} from 'actions/controlActions';
import { ISubventionYear } from 'modules/ApprovedLoanInput/types';
import { CreditScore } from 'modules/ProbabilityEstimator/types';
import { IControlNumbersPayload, IFeesAndTaxes, IFeesAndTaxesPayload } from 'modules/core/types';
import { IAdjustEventMessagesPayload } from 'components/Modals/VehicleAdjustmentDialog/types';

export interface IControlReducerState {
  subventionYear: ISubventionYear[];
  feesAndTaxes: IFeesAndTaxes;
  interestRateList: CreditScore[];
  adjustEventMessages: IAdjustEventMessagesPayload[];
  controlNumbers?: IControlNumbersPayload;
}

const initialState: IControlReducerState = {
  subventionYear: [],
  feesAndTaxes: { estimatedFeesControl: undefined, salesTaxRateControl: undefined, insuranceControl: undefined },
  interestRateList: [],
  adjustEventMessages: [],
};

export const controlsSlice = createSlice({
  name: 'controls',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getSubventionYearDataThunk.fulfilled,
      (state: IControlReducerState, { payload: subventionYear }: PayloadAction<ISubventionYear[]>) => ({
        ...state,
        subventionYear,
      }),
    );

    builder.addCase(
      getFeesAndTaxesDataThunk.fulfilled,
      (
        state: IControlReducerState,
        { payload: { sales_fees, sales_tax_rate, warranty_credit_insurance } }: PayloadAction<IFeesAndTaxesPayload>,
      ) => ({
        ...state,
        feesAndTaxes: {
          estimatedFeesControl: sales_fees,
          salesTaxRateControl: sales_tax_rate,
          insuranceControl: warranty_credit_insurance,
        },
      }),
    );

    builder.addCase(
      getInterestRateListThunk.fulfilled,
      (state: IControlReducerState, { payload: interestRateList }: PayloadAction<CreditScore[]>) => ({
        ...state,
        interestRateList,
      }),
    );

    builder.addCase(
      getAdjustEventMessagesThunk.fulfilled,
      (
        state: IControlReducerState,
        { payload: adjustEventMessages }: PayloadAction<IAdjustEventMessagesPayload[]>,
      ) => ({
        ...state,
        adjustEventMessages,
      }),
    );

    builder.addCase(
      getControlNumbersThunk.fulfilled,
      (state: IControlReducerState, { payload: controlNumbers }: PayloadAction<IControlNumbersPayload>) => ({
        ...state,
        controlNumbers,
      }),
    );
  },
});
