import { ISubmittedData, ICreditInterviewInfo } from './types';

export const transformToRequestData = ({
  customerCar,
  customerName,
  customerPhone,
}: ISubmittedData): ICreditInterviewInfo => ({
  dealer: Number(customerCar),
  phone_number: customerPhone.toString(),
  recipient_name: customerName,
});
