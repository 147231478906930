import React, { useCallback, useMemo, useState } from 'react';
import { Form } from 'react-bootstrap';
import { IFormField } from 'modules/core/types';
import { Controller, ControllerRenderProps } from 'react-hook-form';
import { useUncontrolledInput } from 'modules/core/hooks';
import { ASTERISK, EMPTY_STRING } from 'modules/core/constants';

import { Input as InputUI, InputFontWeight } from '../Input/styles';
import { CalendarUI } from './styles';
import { CALENDAR_INPUT_CLASS_NAME, DATE_FORMAT } from './calendarInput.const';

// We need to declare alias in order
// to avoid cycled dependency in core types.s
export interface ICalendarInput extends IFormField {
  textPosition?: 'end' | 'start';
  textStyle?: InputFontWeight;
  testId?: string;
  disabled?: boolean;
  required?: boolean;
  placeholder?: string;
  maxDate?: Date;
  autoComplete?: boolean;
}

export const CalendarInput: React.FC<ICalendarInput> = ({
  name,
  defaultValue,
  validate,
  rules = {},
  textPosition = 'start',
  textStyle = 'normal',
  testId,
  disabled,
  required,
  placeholder,
  maxDate,
  autoComplete = false,
}) => {
  const [date, setDate] = useState<Date>();

  const { hasError, errorMessage, controlField, setValue } = useUncontrolledInput(name);

  const handleCalendarInputChange = useCallback(
    (onChange) => (value: Date) => {
      setDate(value);
      setValue(name, value);

      return onChange(value);
    },
    [setDate, setValue, name],
  );

  const processedPlaceholder = required ? `${placeholder}${ASTERISK}` : placeholder;

  const commonInputProps = useMemo(
    () => ({ placeholder: processedPlaceholder, name, isInvalid: Boolean(errorMessage) || hasError, disabled }),
    [disabled, errorMessage, hasError, name, processedPlaceholder],
  );

  const renderCalendar = useCallback(
    ({ onChange }: ControllerRenderProps) => (
      <CalendarUI
        {...commonInputProps}
        className={CALENDAR_INPUT_CLASS_NAME}
        placeholderText={DATE_FORMAT.toUpperCase()}
        dateFormat={DATE_FORMAT}
        selected={date || (defaultValue as Date)}
        onChange={handleCalendarInputChange(onChange)}
        showMonthDropdown
        showYearDropdown
        maxDate={maxDate}
        autoComplete={autoComplete ? 'on' : 'off'}
      />
    ),
    [commonInputProps, date, defaultValue, handleCalendarInputChange, maxDate, autoComplete],
  );

  return (
    <InputUI textposition={textPosition} textstyle={textStyle} data-testid={testId}>
      <Controller
        render={renderCalendar}
        defaultValue={defaultValue || EMPTY_STRING}
        control={controlField}
        name={name}
        rules={{
          ...rules,
          validate,
        }}
      />
      <Form.Control.Feedback type="invalid">{errorMessage}</Form.Control.Feedback>
    </InputUI>
  );
};
