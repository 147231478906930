import { useDispatch } from 'react-redux';
import { AppDispatch } from 'store/store';
import { setSelectedRows, setVisibleColumns } from 'store/reducers/probabilityEstimator.reducer';
import { toTableSelectedRows, toTableVisibleColumns } from 'modules/core/utils';

import { TReactTableColumn, TSelectedRow, TSelectedIds, TableData } from '../core/components/Table/types';

interface IUseTableCallback {
  selectedRowsCallback: (rows: TSelectedRow[]) => void;
  visibleColumnsCallback: (columns: TReactTableColumn[]) => void;
  selectedPaginatedRowsCallback: (rows: TSelectedRow[], ids: TSelectedIds, selectedRows: TableData[]) => void;
}

export const useTableCallback = (): IUseTableCallback => {
  const dispatch = useDispatch<AppDispatch>();

  const selectedRowsCallback = (rows: TSelectedRow[]) => {
    dispatch(setSelectedRows(toTableSelectedRows(rows)));
  };

  const visibleColumnsCallback = (columns: TReactTableColumn[]) => {
    dispatch(setVisibleColumns(toTableVisibleColumns(columns)));
  };

  const selectedPaginatedRowsCallback = (rows: TSelectedRow[], ids: TSelectedIds, selectedRows: TableData[]) => {
    const selectedTableRows = toTableSelectedRows(rows);
    const currentSelectedIds = Object.keys(ids);
    let map = new Map();

    // Concating arrays of previously selected vehicles and new ones with duplicates
    let allRows = selectedTableRows.concat(selectedRows as []);

    // Removing duplicates items
    allRows.forEach((item) => {
      if (!map.has(item.id)) {
        map.set(item.id, item);
      }
    });

    // Remove everything what is not actually in selection anymore
    const mapKeys = Array.from(map.keys());

    for (const key of mapKeys) {
      if (currentSelectedIds.indexOf(key.toString()) === -1) {
        map.delete(key);
      }
    }

    dispatch(setSelectedRows(Array.from(map.values())));
  };

  return { selectedRowsCallback, visibleColumnsCallback, selectedPaginatedRowsCallback };
};
