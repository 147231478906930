import React, { FC, ReactNode, useMemo } from 'react';
import { ColumnWidth } from 'modules/core/enums';

import { FormLabel, FormRow as FormRowUI, SubLabel, ColUI as Col } from './styles';
import { FEES_AND_TAXES_FIELD_NAMES } from '../../shared';

interface IFormRow {
  required?: boolean;
  label?: string;
  control: ReactNode;
  disabled?: boolean;
  fieldWidth?: ColumnWidth;
  subLabel?: string;
  isVerticalAlign?: boolean;
  name?: string;
  boldLabel?: boolean;
}

export const FormRow: FC<IFormRow> = ({
  control,
  label,
  required,
  disabled,
  fieldWidth,
  subLabel,
  isVerticalAlign,
  name,
  boldLabel,
}) => {
  const colWidths = useMemo(() => {
    if (isVerticalAlign) {
      return { label: ColumnWidth.FULL, control: ColumnWidth.FULL };
    }

    const controlCol = label ? fieldWidth || ColumnWidth.S : ColumnWidth.FULL;
    const labelCol = ColumnWidth.FULL - controlCol;

    return { label: labelCol, control: controlCol };
  }, [fieldWidth, isVerticalAlign, label]);

  return (
    <FormRowUI>
      {label && (
        <Col id={name && name.substr(name.indexOf('.') + 1)} xs={colWidths.label}>
          <FormLabel
            id={
              [FEES_AND_TAXES_FIELD_NAMES.ESTIMATED_FEES, FEES_AND_TAXES_FIELD_NAMES.SALES_FEES].includes(name || '')
                ? 'enlarged-label'
                : ''
            }
            required={required}
            disabled={disabled}
            bold={boldLabel}
          >
            {label}
            {subLabel && <SubLabel>{subLabel}</SubLabel>}
          </FormLabel>
        </Col>
      )}
      <Col xs={colWidths.control}>{control}</Col>
    </FormRowUI>
  );
};
