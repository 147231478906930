import { AppDispatch } from 'store/store';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getFeesAndTaxesState, getSubventionYearOptions } from 'store/selectors/controls.selector';
import { SetFieldValue } from 'modules/core/types/form';
import { FEES_AND_TAXES_FIELD_NAMES, PREFERENCES_PE_FIELD_NAMES } from 'modules/core/shared';
import { setFirstRouteOneRender } from 'store/reducers/RACAffordabilityEngine.reducer';
import { OTHER_OPTION } from 'components/Modals/LoanVariablesDialog/modals/Calculators/NetTradeInCalculator/consts/consts';
import { IVehicleModel } from 'modules/core/components/VehicleFilters/types';
import { getVehicleState } from 'store/selectors/vehicle.selector';
import {
  getFirstRouteOneRender,
  getRateSheetsDataFlags,
  getRegionControls,
  getTiers,
} from 'store/selectors/RACAffordabilityEngine.selector';
import { IRouteOneData } from 'modules/core/types/routeOne';
import { ITier } from 'modules/core/types';

import { ProbabilityEstimatorCalculationService } from '../../ProbabilityEstimator/services';
import { NET_DOWN_PAYMENT_FIELD_NAMES } from '../../ProbabilityEstimator/probabilityEstimator.const';
import { APPROVED_LOAN_TERMS_FIELD_NAMES, FRONT_AND_BACK_ENDS_ADDS_FIELD_NAMES } from '../const/fieldNames.const';
import { ESTIMATED_FEES_OVERRIDDEN } from '../types/RACAffordabilityEngineForm';

export const useRouteOneValues = (
  setValue: SetFieldValue,
  isRouteOneDataEmpty: boolean,
  routeOneData: IRouteOneData,
  isReset: boolean,
  tier: ITier,
  desiredMonthlyPayment: number,
  grossMonthlyIncome?: number,
  isMakeItWork?: boolean,
  isWithInventory?: boolean,
): void => {
  const dispatch: AppDispatch = useDispatch();
  const { insuranceControl } = useSelector(getFeesAndTaxesState);
  const { vehicleModels } = useSelector(getVehicleState);
  const subventionYearOptions = useSelector(getSubventionYearOptions);
  const firstRouteOneRender = useSelector(getFirstRouteOneRender);
  const regionControls = useSelector(getRegionControls);
  const { isCalculatedPayment } = useSelector(getRateSheetsDataFlags);
  const tiers = useSelector(getTiers);

  const { max_allowed_pti, max_allowed_payment } = tier;

  const maxPayment =
    isCalculatedPayment &&
    grossMonthlyIncome &&
    ProbabilityEstimatorCalculationService.getMaximalMonthlyPayment(
      max_allowed_pti,
      max_allowed_payment,
      grossMonthlyIncome,
    );

  useEffect(() => {
    if (
      isMakeItWork &&
      (firstRouteOneRender || isReset) &&
      !isRouteOneDataEmpty &&
      (isWithInventory ? typeof insuranceControl !== 'undefined' : true) &&
      vehicleModels.length > 0 &&
      subventionYearOptions.length > 0 &&
      tiers.length > 0 &&
      Object.keys(regionControls).length > 0
    ) {
      const tierOption = tiers.find((tier) => tier.tier === parseInt(routeOneData.finance_details.tier));

      if (tierOption) {
        setValue(APPROVED_LOAN_TERMS_FIELD_NAMES.TIER, tierOption);
        setValue(PREFERENCES_PE_FIELD_NAMES.LVT_HIGH_LIMIT, tierOption.max_ltv);
      }

      const {
        primary_monthly_income,
        primary_other_monthly_income,
        coApplicant_monthly_income,
        coApplicant_other_monthly_income,
      } = routeOneData.income;

      const totalIncome =
        (primary_monthly_income || 0) +
        (primary_other_monthly_income || 0) +
        (coApplicant_monthly_income || 0) +
        (coApplicant_other_monthly_income || 0);

      setValue('lendersMonthlyIncomeModal', {
        income: {
          totalIncome: totalIncome,
          primaryMonthlyIncome: primary_monthly_income,
          primaryOtherMonthlyIncome: primary_other_monthly_income,
          coApplicantMonthlyIncome: coApplicant_monthly_income,
          coApplicantOtherMonthlyIncome: coApplicant_other_monthly_income,
        },
      });
      setValue(APPROVED_LOAN_TERMS_FIELD_NAMES.GROSS_MONTHLY_INCOME, Math.round(totalIncome));

      if (isCalculatedPayment) {
        const r1Payment = routeOneData.finance_details.payment;

        setValue(
          APPROVED_LOAN_TERMS_FIELD_NAMES.DESIRED_MONTHLY_PAYMENT,
          (maxPayment || 0) <= (r1Payment || 0) ? maxPayment : r1Payment,
        );
      } else setValue(APPROVED_LOAN_TERMS_FIELD_NAMES.DESIRED_MONTHLY_PAYMENT, routeOneData.finance_details.payment);

      setValue(
        FEES_AND_TAXES_FIELD_NAMES.ESTIMATED_FEES,
        (routeOneData.taxes_fees.sales_fees || 0) + (routeOneData.taxes_fees.title_reg_license || 0),
      );

      if (routeOneData.trade_in_vehicle.value !== 0 || routeOneData.trade_in_vehicle.amount_owed !== 0) {
        setValue(NET_DOWN_PAYMENT_FIELD_NAMES.TRADE_VALUE, routeOneData.trade_in_vehicle.value);
        setValue(NET_DOWN_PAYMENT_FIELD_NAMES.AMOUNT_OWED, routeOneData.trade_in_vehicle.amount_owed);

        if (routeOneData.trade_in_vehicle.year !== 0) {
          const yearOption = subventionYearOptions.find(
            (year) => year.title === routeOneData.trade_in_vehicle.year.toString(),
          );

          if (typeof yearOption == 'undefined') {
            setValue(NET_DOWN_PAYMENT_FIELD_NAMES.YEAR, OTHER_OPTION);
            setValue(NET_DOWN_PAYMENT_FIELD_NAMES.OTHER_YEAR, routeOneData.trade_in_vehicle.year);
          } else setValue(NET_DOWN_PAYMENT_FIELD_NAMES.YEAR, yearOption);
        }

        if (routeOneData.trade_in_vehicle.make !== '') {
          const makeModelOption = vehicleModels.find(
            (makeModel) => Object.keys(makeModel)[0] === routeOneData.trade_in_vehicle.make,
          );

          if (typeof makeModelOption == 'undefined') {
            setValue(NET_DOWN_PAYMENT_FIELD_NAMES.MAKE, OTHER_OPTION);
            setValue(NET_DOWN_PAYMENT_FIELD_NAMES.MODEL, OTHER_OPTION);
            setValue(NET_DOWN_PAYMENT_FIELD_NAMES.OTHER_MAKE, routeOneData.trade_in_vehicle.make);
            setValue(NET_DOWN_PAYMENT_FIELD_NAMES.OTHER_MODEL, routeOneData.trade_in_vehicle.model);
          } else {
            const makeName = Object.keys(makeModelOption)[0];

            setValue(NET_DOWN_PAYMENT_FIELD_NAMES.MAKE, { title: makeName, value: makeName });

            const modelOption = Object.values(makeModelOption)[0].find(
              (model: IVehicleModel) => model.name === routeOneData.trade_in_vehicle.model,
            );

            if (typeof modelOption == 'undefined') {
              setValue(NET_DOWN_PAYMENT_FIELD_NAMES.MODEL, OTHER_OPTION);
              setValue(NET_DOWN_PAYMENT_FIELD_NAMES.OTHER_MODEL, routeOneData.trade_in_vehicle.model);
            } else setValue(NET_DOWN_PAYMENT_FIELD_NAMES.MODEL, { title: modelOption.name, value: modelOption.name });
          }
        }
      }

      setValue(
        NET_DOWN_PAYMENT_FIELD_NAMES.CASH_DOWN,
        routeOneData.finance_details.cash_down + routeOneData.finance_details.rebates,
      );
      setValue(NET_DOWN_PAYMENT_FIELD_NAMES.LIEN_HOLDER, routeOneData.trade_in_vehicle.lien_holder);
      const netDownPayment = ProbabilityEstimatorCalculationService.getTotalNetDownPayment(
        routeOneData.finance_details.cash_down + routeOneData.finance_details.rebates || 0,
        routeOneData.trade_in_vehicle.value || 0,
        routeOneData.trade_in_vehicle.amount_owed || 0,
      );

      setValue(NET_DOWN_PAYMENT_FIELD_NAMES.TOTAL_NET_DOWN, netDownPayment);
      setValue(APPROVED_LOAN_TERMS_FIELD_NAMES.NET_DOWN_PAYMENT, netDownPayment);

      let gap = Math.round(routeOneData.finance_details.gap);
      let vsc = routeOneData.finance_details.vsc;

      if (regionControls.gapLimit === null) {
        gap = 0;
      } else if (gap > regionControls.gapLimit) {
        gap = regionControls.gapLimit || 0;
      }

      if (regionControls?.vscLimitAbove && vsc > regionControls.vscLimitAbove) {
        vsc = regionControls.vscLimitAbove;
      }

      setValue(FRONT_AND_BACK_ENDS_ADDS_FIELD_NAMES.GAP, gap);
      setValue(FRONT_AND_BACK_ENDS_ADDS_FIELD_NAMES.VSC, vsc);
      setValue(FRONT_AND_BACK_ENDS_ADDS_FIELD_NAMES.TOTAL_FRONT_AND_BACK_ENDS_ADDS, gap + vsc);
      setValue(APPROVED_LOAN_TERMS_FIELD_NAMES.FRONT_AND_BACK_ENDS_ADDS, gap + vsc);
      setValue('cashDownOverridden', false);
      setValue(ESTIMATED_FEES_OVERRIDDEN, false);
      dispatch(setFirstRouteOneRender(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    tiers.length,
    isRouteOneDataEmpty,
    insuranceControl,
    vehicleModels.length,
    subventionYearOptions.length,
    isReset,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    Object.keys(regionControls).length,
  ]);
};
