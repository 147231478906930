import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IOption } from 'modules/core/types';
import { IDealer, IDealerStore } from 'modules/core/types/dealer';
import { getDealersThunk, getDealerStoresThunk, getPureDealerStoresThunk } from 'actions/dealerActions';

import { sortDealers } from './utils/sortDealers';

export interface IDealerReducerState {
  dealers: IDealer[];
  dealerStores: IOption[];
  pureDealerStores: IDealerStore[];
}

const initialState: IDealerReducerState = {
  dealers: [],
  dealerStores: [],
  pureDealerStores: [],
};

export const dealerSlice = createSlice({
  name: 'dealer',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getDealerStoresThunk.fulfilled,
      (state: IDealerReducerState, { payload: dealerStores }: PayloadAction<IOption[]>) => ({
        ...state,
        dealerStores: sortDealers(dealerStores),
      }),
    );

    builder.addCase(
      getPureDealerStoresThunk.fulfilled,
      (state: IDealerReducerState, { payload: pureDealerStores }: PayloadAction<IDealerStore[]>) => ({
        ...state,
        pureDealerStores,
      }),
    );

    builder.addCase(
      getDealersThunk.fulfilled,
      (state: IDealerReducerState, { payload: dealers }: PayloadAction<IDealer[]>) => ({
        ...state,
        dealers,
      }),
    );
  },
});
