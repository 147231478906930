import axios, { AxiosInstance, CancelTokenSource } from 'axios';
import { CSRF_TOKEN_NAME } from 'modules/auth/constants/auth.const';
import { getFromCookies } from 'modules/auth/utils/cookie';
import qs from 'qs';

import { responseOnFulfilled, responseOnReject } from './interceptors';

export class Api {
  protected api: AxiosInstance;

  public createCancelTokenSource: () => CancelTokenSource;

  constructor() {
    this.api = axios.create({
      baseURL: process.env.REACT_APP_API || '/api',
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
    });

    this.api.interceptors.request.use((config) => {
      const scrfToken = getFromCookies(CSRF_TOKEN_NAME);

      if (scrfToken) {
        // eslint-disable-next-line no-param-reassign
        config.headers['X-CSRFToken'] = scrfToken;
      }

      return config;
    });

    this.api.interceptors.response.use(responseOnFulfilled, responseOnReject);

    this.createCancelTokenSource = () => axios.CancelToken.source();
  }
}
