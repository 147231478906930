import styled from 'styled-components';
import { modalButtonMixin } from 'modules/core/theme/mixins';

export const ButtonGroup = styled.div`
  padding: 15px 0;

  button {
    ${modalButtonMixin};

    &:first-child {
      margin-right: 7px;
    }
  }
`;

export const Label = styled.div`
  padding-top: 15px;
  padding-bottom: 20px;

  &:first-child {
    padding-top: 0;
  }
`;
