import styled from 'styled-components';
import { Card, Table, Button as ButtonUI } from 'react-bootstrap';
import { COLORS } from 'modules/core/theme/colors';
import { STATIC_IMAGE_URLS } from 'modules/core/constants';
import { disabledSectionMixin } from 'modules/core/theme/mixins';
import { ILoanStatusGradientBackgroundProps } from 'modules/core/types';
import { WindowBreakpoints } from 'modules/core/enums/windowBreakpoints.enum';

import { Title } from '../Title';

type SelectableButton = {
  selected: boolean;
};

export const TABLE_PADDING = '15px';

export const TableContainerWrapper = styled.div`
  margin-top: 20px;

  ${Title} {
    @media (max-width: ${WindowBreakpoints.small}px) {
      flex-direction: column;
      height: auto;
    }
  }
`;

export const TableHeaderSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: ${WindowBreakpoints.small}px) {
    justify-content: start;
    margin-top: 5px;
  }

  & > div:nth-child(n) {
    padding-left: 10px;
  }

  & > div:nth-child(1) {
    @media (max-width: ${WindowBreakpoints.small}px) {
      padding-left: 0;
    }
  }

  & button {
    @media (max-width: ${WindowBreakpoints.small}px) {
      font-size: 14px;
      min-width: 105px;
    }
  }

  .title {
    font-size: ${({ theme: { fontSize } }) => fontSize.sm};
    font-weight: ${({ theme: { boldText } }) => boldText.bold};
  }
`;

export const TableTopSection = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: ${TABLE_PADDING};

  @media (max-width: ${WindowBreakpoints.medium}px) {
    flex-direction: column;
  }

  ${({ disabled }) => disabled && disabledSectionMixin}
`;

export const TableItems = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme: { colors } }) => colors['grey-3']};
  font-size: ${({ theme: { fontSize } }) => fontSize.sm};
`;

export const TotalItems = styled.span`
  margin-right: 10px;
`;

export const SelectAllSection = styled.div`
  display: flex;

  div {
    display: flex;
    align-items: center;
  }
`;

export const SelectAllLabel = styled.span`
  margin: 0 10px 0 5px;
`;

export const TableRow = styled.tr<{
  isRac?: boolean;
  isMakeItWork?: boolean;
  isMakeItWorkVehicle?: boolean;
  isRelative?: boolean;
  isBlur?: boolean;
  isLender?: boolean;
}>`
  &:hover {
    background: ${({ isRac, isBlur, isLender }) => (isRac || isLender) && !isBlur && '#eff6f0'};
  }

  position: ${({ isRelative }) => isRelative && 'relative'};

  ${({ isMakeItWork }) =>
    isMakeItWork &&
    `
      &:hover .badge-ui {
        display: none;
      }

      &:hover .make-it-work-badge {
        display: initial;
      }
    `};

  ${({ isMakeItWorkVehicle }) =>
    isMakeItWorkVehicle &&
    `
      outline: 3px solid #CDAC36;
      outline-offset: -4px;
    `};
`;

export const TableCell = styled.td<{
  backgroundColors: ILoanStatusGradientBackgroundProps;
  noColoring?: boolean;
  isRac?: boolean;
  isSpecialColumn?: boolean;
  isBlur?: boolean;
}>`
  ${({ isBlur }) =>
    isBlur &&
    `
      filter: blur(3px);
      background-color: rgba(0, 0, 0, 0.1) !important;
      user-select: none;
      pointer-events: none;
      --bs-table-accent-bg: unset !important;
    `};

  &:nth-of-type(1) {
    background: ${({ backgroundColors, noColoring }) => !noColoring && `${backgroundColors.to} !important`};
  }

  &:nth-of-type(2) {
    background: ${({ backgroundColors, noColoring, isBlur }) =>
      !noColoring &&
      `linear-gradient(to left, ${!isBlur ? backgroundColors.from : 'rgba(0, 0, 0, 0.1)'}, ${backgroundColors.to})`};
  }

  ${({ isRac, isSpecialColumn }) =>
    isRac &&
    `
    div div {
      padding: 0.5rem !important;
    }

    height: 100%;
    padding: ${isSpecialColumn ? `0.5rem` : `0`} !important;
  `}

  border: 1px solid ${({ theme: { colors } }) => colors.white};
`;

export const TableWrapper = styled(Table)<{ isRac?: boolean; isLender?: boolean }>`
  padding: ${TABLE_PADDING};
  margin-bottom: 0 !important;

  &.table-bordered {
    td,
    th {
      border: 2px solid ${({ theme: { colors } }) => colors.white};
    }

    .tr:nth-child(odd) td {
      background-color: ${({ theme: { colors } }) => colors['grey-2']};
    }

    th {
      padding: 8px;
      background-color: ${({ theme: { colors } }) => colors['grey-2']};
      vertical-align: top;
      white-space: break-spaces;
    }
  }
  ${({ isRac, isLender }) =>
    (isRac || isLender) &&
    `
    height: 100%;
    weight: 100%;
    tbody {
      tr td {
        height: 100%;
      }
    }
  `}
`;

const ToggleViewButton = styled.button<SelectableButton>`
  height: 36px;
  width: 36px;
  border: none;
  border-radius: 4px;
  margin-right: 10px;
  background-size: 18px 18px;
  background-repeat: no-repeat;
  background-position: center;
  background-color: ${(props) => (props.selected ? COLORS.GREY_1 : 'transparent')};

  &:hover {
    background-color: ${(props) => (props.selected ? COLORS.GREY_1 : COLORS.GREY_4)};
  }
`;

export const ToggleTableViewButton = styled(ToggleViewButton)`
  background-image: url(${STATIC_IMAGE_URLS.icons.table});

  @media (max-width: ${WindowBreakpoints.small}px) {
    display: none;
  }
`;

export const ToggleCardViewButton = styled(ToggleViewButton)`
  background-image: url(${STATIC_IMAGE_URLS.icons.cards});

  @media (max-width: ${WindowBreakpoints.small}px) {
    display: none;
  }
`;

export const OverflowTableWrapper = styled.div`
  overflow: auto;
  max-height: calc(100vh - 190px);
`;

export const NoVehicleErrorMessage = styled(Card.Body)`
  margin: 0 6px;
  padding-top: 0;
  padding-bottom: 0;
  color: ${COLORS.RED_6};
`;

export const UnlockIcon = styled.span`
  display: inline-block;
  background-repeat: no-repeat;
  background-size: contain;
  height: 22px;
  width: 22px;
  background-image: url(${STATIC_IMAGE_URLS.icons.lockWhiteBackground});
  vertical-align: middle;
`;

export const UnlockButton = styled(ButtonUI)`
  height: 43px;
  width: 217px;
  font-size: 17px;
  white-space: nowrap;
  position: absolute;
  top: 25%;
  left: 42%;
  color: #323b4d !important;
  background: #ffffff 0 0 no-repeat padding-box !important;
  border: 1px solid #323b4d !important;
  box-shadow: none !important;
  background-image: url(${STATIC_IMAGE_URLS.icons.print});
  background-size: cover;
  display: flex;
  justify-content: center;

  &:active,
  &:focus,
  &:hover {
    background: #ffffff 0 0 no-repeat padding-box !important;
    color: #323b4d;
  }

  &:active,
  &:hover {
    ${UnlockIcon} {
      background-image: url(${STATIC_IMAGE_URLS.icons.unlockWhiteBackground});
    }
  }
`;
