import { AnyAction, Reducer, combineReducers } from '@reduxjs/toolkit';
import { DESTROY_STORE_ACTION } from 'actions/actions.const';

import { authSlice } from './auth.reducer';
import { uiReducer } from './ui.reducer';
import { controlsSlice } from './controls.reducer';
import { probabilityEstimatorSlice } from './probabilityEstimator.reducer';
import { approvedLoanSlice } from './approvedLoan.reducer';
import { vehicleSlice } from './vehicle.reducer';
import { dealerSlice } from './dealer.reducer';
import { RACAffordabilityEngineSlice } from './RACAffordabilityEngine.reducer';
import { lenderSlice } from './lenders.reducer';

export const appReducer = combineReducers({
  auth: authSlice.reducer,
  ui: uiReducer.reducer,
  controls: controlsSlice.reducer,
  probabilityEstimator: probabilityEstimatorSlice.reducer,
  approvedLoanInput: approvedLoanSlice.reducer,
  RACAffordabilityEngine: RACAffordabilityEngineSlice.reducer,
  vehicle: vehicleSlice.reducer,
  dealer: dealerSlice.reducer,
  lenders: lenderSlice.reducer,
});

export const rootReducer: Reducer = (currentState: ReturnType<typeof appReducer>, action: AnyAction) => {
  const newState = action.type === DESTROY_STORE_ACTION ? undefined : currentState;

  return appReducer(newState, action);
};
