import React from 'react';
import i18n from 'i18n';
import { Input } from 'modules/core/components/Input/Input';
import { CalculatorAdornment } from 'modules/core/components';
import { IField } from 'modules/core/types/common';
import { validateCreditScore, validateMinRequiredValue } from 'modules/core/validators';

import { CreditScoreDropdown } from '../CreditScoreDropdown';
import { PROBABILITY_ESTIMATOR_FIELDS } from '../probabilityEstimator.const';

export const CONSUMER_INFORMATION_COMMON_FIELDS: IField[] = [
  {
    name: PROBABILITY_ESTIMATOR_FIELDS.GROSS_MONTHLY_INCOME,
    component: Input,
    label: i18n.t('components.consumerInformation.monthlyIncome'),
    isVerticalAlign: true,
    controlProps: {
      textStyle: 'bold',
      textPosition: 'start',
      endAdornment: <CalculatorAdornment />,
      required: true,
      decimalScale: 0,
      validate: validateMinRequiredValue,
      hideInitZeroValue: true,
      placeholder: i18n.t('components.input.placeholders.integer'),
    },
  },
  {
    name: PROBABILITY_ESTIMATOR_FIELDS.NET_DOWN_PAYMENT,
    component: Input,
    label: i18n.t('components.consumerInformation.totalCashAndTrade'),
    isVerticalAlign: true,
    controlProps: {
      textStyle: 'bold',
      textPosition: 'start',
      endAdornment: <CalculatorAdornment />,
      disabled: false,
      decimalScale: 0,
      allowNegative: true,
      readonly: true,
      hideInitZeroValue: true,
      placeholder: i18n.t('components.input.placeholders.integer'),
    },
  },
];

export const CONSUMER_INFORMATION_FIELDS: IField[] = [
  {
    name: PROBABILITY_ESTIMATOR_FIELDS.CREDIT_SCORE,
    component: CreditScoreDropdown,
    label: i18n.t('components.consumerInformation.creditTier'),
    isVerticalAlign: true,
    controlProps: { required: true, validate: validateCreditScore },
  },
  ...CONSUMER_INFORMATION_COMMON_FIELDS,
];
