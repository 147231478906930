import i18n from 'i18n';
import { Input } from 'modules/core/components';
import { minLengthPasswordValidation } from 'modules/core/rules';
import { IField } from 'modules/core/types/common';

import { CHANGE_PASSWORD_NAMES } from '../common.const';

export const FORM_LABELS = {
  OLD_PASSWORD: i18n.t('components.resetPassword.labels.oldPassword'),
  NEW_PASSWORD: i18n.t('components.resetPassword.labels.newPassword'),
  CONFIRM_NEW_PASSWORD: i18n.t('components.resetPassword.labels.confirmNewPassword'),
};

export const FIELD_NAMES = {
  OLD_PASSWORD: 'oldPassword',
  NEW_PASSWORD: 'newPassword',
  CONFIRM_NEW_PASSWORD: 'confirmedNewPassword',
};

const FIELD_PROPS = {
  valueAsNumber: false,
  type: 'password',
};

export const FIELDS_CONFIG: IField[] = [
  {
    name: FIELD_NAMES.OLD_PASSWORD,
    label: FORM_LABELS.OLD_PASSWORD,
    controlProps: { rules: { required: `${i18n.t('common.errors.passwordRequire')}` }, ...FIELD_PROPS },
    component: Input,
  },
  {
    name: FIELD_NAMES.NEW_PASSWORD,
    label: FORM_LABELS.NEW_PASSWORD,
    component: Input,
    controlProps: { rules: minLengthPasswordValidation, ...FIELD_PROPS },
  },
  {
    name: FIELD_NAMES.CONFIRM_NEW_PASSWORD,
    label: FORM_LABELS.CONFIRM_NEW_PASSWORD,
    component: Input,
    controlProps: { rules: minLengthPasswordValidation, ...FIELD_PROPS },
  },
];

export const FIELD_NAMES_TO_UPPERCASE_MAP = new Map<string, string>([
  [CHANGE_PASSWORD_NAMES.OLD_PASSWORD, FIELD_NAMES.OLD_PASSWORD],
  [CHANGE_PASSWORD_NAMES.NEW_PASSWORD, FIELD_NAMES.NEW_PASSWORD],
  [CHANGE_PASSWORD_NAMES.CONFIRMED_NEW_PASSWORD, FIELD_NAMES.CONFIRM_NEW_PASSWORD],
]);
