import { IApprovedLoanInput } from './types';

export const roundValue = (value: number): number => (!Number.isFinite(value) ? 0 : Math.round(value));

export const calcApprovedLoanValue = ({
  approvedInterestRate,
  approvedMonthlyPayment,
  approvedLoanTerm,
}: IApprovedLoanInput): number => {
  let pV = -approvedMonthlyPayment * approvedLoanTerm;

  if (approvedInterestRate) {
    const rate = approvedInterestRate / 100 / 12;
    const approvedLoanNominator = -approvedMonthlyPayment * ((1 + rate) ** approvedLoanTerm - 1);
    const approvedLoanDenominator = rate * (1 + rate) ** approvedLoanTerm;

    pV = approvedLoanNominator / approvedLoanDenominator;
  }

  return pV;
};

export const calcPriceAllowed = (priceAllowed: IApprovedLoanInput, approvedLoanValue: number): number => {
  const {
    cashDownPayment,
    amountOwed,
    tradeValue,
    feesAndTaxes: { estimatedFees, salesTaxRate },
  } = priceAllowed;

  const priceCash = -approvedLoanValue + Number(cashDownPayment) - amountOwed - (estimatedFees || 0);
  const priceRate = 1 + (salesTaxRate || 0) / 100;
  const res = priceCash / priceRate + Number(tradeValue);

  return roundValue(res);
};

export const calcNetDownPayment = ({ cashDownPayment, tradeValue, amountOwed }: IApprovedLoanInput): number =>
  Number(cashDownPayment) + Number(tradeValue) - amountOwed;
