import React, { FC, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { ISelectable } from 'modules/core/types';
import { useUncontrolledInput } from 'modules/core/hooks';

import { Dropdown as DropdownUI } from './styles';

const { Control: FormControl } = Form;

export interface IDropdown extends ISelectable {
  defaultValue?: string | number;
  disabled?: boolean;
  onChange?: any;
}

export const Dropdown: FC<IDropdown> = ({ options, name, rules = {}, defaultValue, disabled = false, onChange }) => {
  const { hasError, registerInput, setValue, fieldValue } = useUncontrolledInput(name);

  useEffect(() => {
    if (defaultValue) {
      if (!fieldValue) {
        setValue(name, defaultValue);
      } else {
        setValue(name, fieldValue);
      }
    }
  }, [setValue, name, defaultValue, options, fieldValue]);

  return (
    <DropdownUI size="sm" data-testid={name}>
      <FormControl
        as="select"
        onChange={onChange}
        ref={registerInput(rules)}
        name={name}
        isInvalid={hasError}
        disabled={disabled}
      >
        {options.map(({ title, value }) => (
          <option value={value} key={value}>
            {title}
          </option>
        ))}
      </FormControl>
    </DropdownUI>
  );
};
