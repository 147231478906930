import { ADMIN_URL_PREFIX } from 'modules/core/constants';
import { SUBDOMAIN_LS_KEY } from 'modules/auth/constants/auth.const';

const BACKEND_PORT = 8000;
const PROTOCOL = process.env.NODE_ENV === 'development' ? 'http://' : 'https://';

export const getGlobalAdminLink = (): string => {
  if (process.env.NODE_ENV === 'development') {
    return `${PROTOCOL}${ADMIN_URL_PREFIX}${process.env.WDS_SOCKET_HOST}:${BACKEND_PORT}/global-admin/`;
  }

  return `${PROTOCOL}${ADMIN_URL_PREFIX}${process.env.REACT_APP_DOMAIN}/global-admin/`;
};

export const getAdminLink = (subdomain: string): string => {
  const ls_subdomain = localStorage.getItem(SUBDOMAIN_LS_KEY);
  const actual_subdomain = ls_subdomain ? ls_subdomain : subdomain;

  if (process.env.NODE_ENV === 'development') {
    return `${PROTOCOL}${actual_subdomain}.${process.env.WDS_SOCKET_HOST}:${BACKEND_PORT}/admin/`;
  }

  return `${PROTOCOL}${subdomain}.${process.env.REACT_APP_DOMAIN}/admin/`;
};

export const getManagerAdminLink = (subdomain: string): string => {
  const ls_subdomain = localStorage.getItem(SUBDOMAIN_LS_KEY);
  const actual_subdomain = ls_subdomain ? ls_subdomain : subdomain;

  if (process.env.NODE_ENV === 'development') {
    return `${PROTOCOL}${actual_subdomain}.${process.env.WDS_SOCKET_HOST}:${BACKEND_PORT}/manager-admin/`;
  }

  return `${PROTOCOL}${subdomain}.${process.env.REACT_APP_DOMAIN}/manager-admin/`;
};

export const getFullPath = (subdomain: string, path: string): string =>
  `${PROTOCOL}${subdomain}.${process.env.REACT_APP_DOMAIN}${path}`;
