import { ColumnWidth } from 'modules/core/enums';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { getSetupRateSheetsData } from 'store/selectors/RACAffordabilityEngine.selector';
import { useTranslation } from 'react-i18next';
import { FormLabel } from 'modules/core/components/FormRow/styles';
import { Input } from 'modules/core/components';

import { WITHOUT_INVENTORY_FIELDS_NAMES } from '../const/fieldNames.const';
import { RateSheetSettingsCol } from '../styles';

interface IWithoutInventoryPaheHeader {
  getPageLogoAndTitle: () => JSX.Element;
  submitCallback: VoidFunction;
}

export const WithoutInventoryPageHeaderRow: React.FC<IWithoutInventoryPaheHeader> = ({
  getPageLogoAndTitle,
  submitCallback,
}) => {
  const { t } = useTranslation();
  const rateSheets = useSelector(getSetupRateSheetsData);

  return (
    <Row>
      <Col lg={ColumnWidth.XL} md={ColumnWidth.XL}>
        {getPageLogoAndTitle()}
      </Col>
      {Object.keys(rateSheets).length > 0 && (
        <RateSheetSettingsCol lg={ColumnWidth.XS} md={ColumnWidth.XS}>
          <Row>
            <Col lg={ColumnWidth.S} md={ColumnWidth.M}>
              <FormLabel>{t('components.RACAffordabilityEngine.withoutInventory.fields.rateSheet')}</FormLabel>
              <Input
                name={WITHOUT_INVENTORY_FIELDS_NAMES.RATE_SHEET}
                placeholder={t('components.racSetupRateSheetAndTaxRateModal.fields.rateSheetRegion.placeholder')}
                valueAsNumber={false}
                disabled
              />
            </Col>
            <Col lg={ColumnWidth.S} md={ColumnWidth.M}>
              <FormLabel>{t('components.RACAffordabilityEngine.withoutInventory.fields.region')}</FormLabel>
              <Input
                name={WITHOUT_INVENTORY_FIELDS_NAMES.REGION}
                placeholder={t('components.racSetupRateSheetAndTaxRateModal.fields.region.placeholder')}
                valueAsNumber={false}
                disabled
              />
            </Col>
          </Row>
        </RateSheetSettingsCol>
      )}
    </Row>
  );
};
