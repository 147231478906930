import { useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveModalsState } from 'store/selectors/ui.selector';
import { hideModal, showModal } from 'store/reducers/ui.reducer';

import { ResetForm } from '../types';

export interface IUseModalState {
  isModalDialogActive: boolean;
  handleOpenDialog: VoidFunction;
  handleCloseDialog: (resetMode?: boolean) => VoidFunction;
}

export const useModalState = <D>(modalNames: string[], modalValues?: D, reset?: ResetForm): IUseModalState => {
  const dispatch = useDispatch();
  const initialModalValues = useRef(modalValues);
  const activeModals = useSelector(getActiveModalsState);

  const handleOpenDialog = useCallback(() => {
    dispatch(showModal(modalNames));
  }, [dispatch, modalNames]);

  const rollbackChanges = useCallback(() => {
    if (reset) {
      reset(initialModalValues.current as { [key: string]: any } | undefined, { dirtyFields: true });
    }
  }, [reset, initialModalValues]);

  const handleCloseDialog = useCallback(
    (resetMode?: boolean) => () => {
      dispatch(hideModal(modalNames.slice(0, modalNames.length - 1)));

      if (reset && resetMode) {
        rollbackChanges();
      }
    },
    [dispatch, modalNames, reset, rollbackChanges],
  );

  const isModalDialogActive = modalNames.every((name) => activeModals.includes(name));

  return {
    isModalDialogActive,
    handleCloseDialog,
    handleOpenDialog,
  };
};
