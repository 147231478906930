import styled from 'styled-components';
import { ILoanStatusGradientBackgroundProps } from 'modules/core/types';

export const SelectCell = styled.div<{ backgroundColor: ILoanStatusGradientBackgroundProps; noColoring?: boolean }>`
  margin: -10px;
  padding-top: 20px;
  display: flex;
  justify-content: center;
  min-height: 80px;
`;
