import { IRACVehicle } from '../types';
import { HYPHEN } from '../constants';

export const useHideVehicleField = (vehicles?: IRACVehicle[]): IRACVehicle[] | undefined => {
  if (!vehicles) return vehicles;

  const fieldsToHide = {
    age: null,
    passenger_capacity: null,
    unit_cost: null,
    drive_type: HYPHEN,
    guidebook: HYPHEN,
    stock: HYPHEN,
    location: HYPHEN,
  };

  return vehicles.map((vehicle) => {
    if (vehicle.manually_created) {
      return { ...vehicle, ...fieldsToHide, ltv_value: { ...vehicle.ltv_value, profit: null } };
    } else {
      return vehicle;
    }
  });
};
