import React, { FC } from 'react';
import { IButton } from 'modules/core/types';
import { ButtonVariant } from 'modules/core/enums';

import { Button as ButtonUI, PrependIconWrapper } from './styles';

export const Button: FC<IButton> = ({
  title,
  type = 'button',
  onClick,
  disabled = false,
  buttonTheme = ButtonVariant.CONTAINED,
  prependIcon,
  width,
}) => (
  <ButtonUI variant="primary" type={type} onClick={onClick} disabled={disabled} buttontheme={buttonTheme} width={width}>
    {prependIcon && <PrependIconWrapper>{prependIcon}</PrependIconWrapper>}
    {title}
  </ButtonUI>
);
