import i18n from 'i18next';
import { Column } from 'react-table';

import { LTV_TABLE_COLUMN, LTV_CLICKABLE_TABLE_COLUMN } from '../../core/components/VehicleTable/vehicleTableColumns';
import { VEHICLE_TABLE_COLUMN_ID } from '../../core/components/VehicleTable/defaultVehicleTableColumns';

const LTV_COLUMN_NAME = VEHICLE_TABLE_COLUMN_ID.LTV_RATIO;

export const useColumnsByLtvMethod = (
  columns: Column<Record<string, unknown>>[],
  ltvTaxesFeesFilter: string,
  isClickable: boolean = false,
): Column<Record<string, unknown>>[] => {
  const ltvHeader =
    ltvTaxesFeesFilter === 'true'
      ? i18n.t('components.table.columns.ltvIncTaxFees')
      : i18n.t('components.table.columns.ltvExcTaxFees');

  const ltvColumn = isClickable ? LTV_CLICKABLE_TABLE_COLUMN : LTV_TABLE_COLUMN;
  const currentColumns = [...columns];
  const ltvIndex = currentColumns.findIndex((column) => column.id === LTV_COLUMN_NAME);

  ltvColumn.Header = ltvHeader;
  currentColumns[ltvIndex] = ltvColumn;

  return currentColumns;
};
