import i18n from 'i18n';
import { DollarAdornment, Input } from 'modules/core/components';
import { ColumnWidth } from 'modules/core/enums';
import { IField } from 'modules/core/types';

import { NET_TRADE_IN_FIELDS_NAMES } from './fieldNames.const';

export const NET_TRADE_IN_FIELD: IField = {
  name: NET_TRADE_IN_FIELDS_NAMES.NET_TRADE_IN_VALUE,
  component: Input,
  controlProps: {
    textStyle: 'bold',
    fieldWidth: ColumnWidth.FULL,
    placeholder: i18n.t('components.input.placeholders.integer'),
  },
};

export const NET_TRADE_IN_CALCULATION_FIELDS: IField[] = [
  {
    label: i18n.t('components.loanVariablesModal.modals.netTradeIn.fields.tradeInValue'),
    name: NET_TRADE_IN_FIELDS_NAMES.NET_TRADE_IN_VALUE,
    component: Input,
    controlProps: {
      startAdornment: <DollarAdornment />,
      fieldWidth: ColumnWidth.XL,
      placeholder: i18n.t('components.input.placeholders.integer'),
      decimalScale: 0,
    },
  },
  {
    label: i18n.t('components.loanVariablesModal.modals.netTradeIn.fields.amountOwnedOnTrade'),
    name: NET_TRADE_IN_FIELDS_NAMES.AMOUNT_OWNED_ON_TRADE,
    component: Input,
    controlProps: {
      startAdornment: <DollarAdornment />,
      fieldWidth: ColumnWidth.XL,
      decimalScale: 0,
      placeholder: i18n.t('components.input.placeholders.integer'),
    },
  },
  {
    label: i18n.t('components.loanVariablesModal.modals.netTradeIn.fields.lienHolder'),
    name: NET_TRADE_IN_FIELDS_NAMES.LIEN_HOLDER,
    component: Input,
    controlProps: {
      valueAsNumber: false,
      fieldWidth: ColumnWidth.XL,
      maxLength: 100,
    },
  },
];
