import React, { FC, useMemo } from 'react';
import { Row } from 'react-table';

import { canRenderRow } from '../helper';
import { CardView } from './styles';
import { VehicleCard } from './VehicleCard/VehicleCard';
import { TABLE_RENDER_CELL_KEY } from '../table.const';

export interface IVehicleCardView {
  pageSize: number;
  currentPage: number;
  rows: Row[];
  prepareRow: (row: Row) => void;
  CustomVehicleCard?: any;
  isServerPagination?: boolean;
}

export const VehicleCardView: FC<IVehicleCardView> = ({
  prepareRow,
  rows,
  pageSize,
  currentPage,
  CustomVehicleCard = VehicleCard,
  isServerPagination = false,
}) => {
  const cardCheckboxesStates = rows.map((item) => item.isSelected);

  const cards = useMemo(
    () =>
      rows.map((currentRow, index) => {
        prepareRow(currentRow);

        return canRenderRow(index, pageSize, currentPage, isServerPagination) ? (
          <CustomVehicleCard key={currentRow.id} vehicle={currentRow.original}>
            {currentRow.cells[0]?.render(TABLE_RENDER_CELL_KEY)}
          </CustomVehicleCard>
        ) : null;
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentPage, pageSize, prepareRow, rows, cardCheckboxesStates],
  );

  return <CardView>{cards}</CardView>;
};
