export const ACTION_PREFIX = {
  BASE: '@base',
  AUTH: '@auth',
  CONTROL: '@control',
  USER: '@user',
  DEALER: '@dealer',
  VEHICLE: '@vehicle',
  CONSUMER: '@consumer',
  REPORT: '@report',
  FEATURES_LIST: '@featuresList',
  LENDER: '@lender',
};
