import React, { FC } from 'react';
import { useTable } from 'react-table';
import { TABLE_RENDER_CELL_KEY, TABLE_RENDER_HEADER_KEY } from 'modules/core/components/Table/table.const';
import { canRenderRow } from 'modules/core/components/Table/helper';

import { TableWrapper, TableHeader, TableHeaderCell, TableBody, TableCell } from './styles';

export interface ITable {
  pageSize: number;
  currentPage: number;
  ltvCategories: any[];
  columns: any[];
  isAllColumns: boolean;
}

export const Table: FC<ITable> = ({ pageSize, currentPage, ltvCategories, columns, isAllColumns }) => {
  const { headerGroups, rows, prepareRow } = useTable({
    data: ltvCategories,
    columns,
    autoResetSortBy: true,
    autoResetSelectedRows: false,
  });

  return (
    <TableWrapper responsive>
      <TableHeader>
        {headerGroups.map(({ getHeaderGroupProps, headers }) => (
          <tr {...getHeaderGroupProps()}>
            {headers.map(({ render }) => (
              <TableHeaderCell data-testid={render(TABLE_RENDER_HEADER_KEY)}>
                {render(TABLE_RENDER_HEADER_KEY)}
              </TableHeaderCell>
            ))}
          </tr>
        ))}
      </TableHeader>
      <TableBody isAllColumns={isAllColumns}>
        {rows.map((row, item) => {
          prepareRow(row);

          return canRenderRow(item, pageSize, currentPage) ? (
            <tr {...row.getRowProps()} key={row.id}>
              {row.cells.map(({ getCellProps, render, column }) => (
                <TableCell {...getCellProps()} key={`${column.id}-${row.id}`}>
                  {render(TABLE_RENDER_CELL_KEY)}
                </TableCell>
              ))}
            </tr>
          ) : null;
        })}
      </TableBody>
    </TableWrapper>
  );
};
