import React, { FC, ReactNode } from 'react';

import { Title, PageHeaderSection } from './styles';

interface IPageHeader {
  title: string | ReactNode;
  headerComponent?: ReactNode;
}

export const PageHeader: FC<IPageHeader> = ({ title, headerComponent }) => (
  <PageHeaderSection>
    {typeof title === 'string' ? <Title>{title}</Title> : title}
    {headerComponent}
  </PageHeaderSection>
);
