import { useCallback, useEffect, useRef } from 'react';

export const useDebounceCallback = (
  callback: (...args: unknown[]) => void,
  timeToWait?: number,
): ((...args: unknown[]) => void) => {
  const callbackArgsRef = useRef<unknown[]>();
  const timeout = useRef<number>();
  const callbackRef = useRef(callback);

  const cleanup = useCallback(() => {
    if (timeout.current) clearTimeout(timeout.current);
  }, []);

  useEffect(() => cleanup, [cleanup]);

  return useCallback(
    (...args: unknown[]): void => {
      cleanup();

      callbackArgsRef.current = args;
      timeout.current = setTimeout(() => {
        if (callbackArgsRef.current) callbackRef.current(...callbackArgsRef.current);
      }, timeToWait);
    },
    [cleanup, timeToWait],
  );
};
