export const CATEGORIES = {
  W2: 'w2',
  NON_W2: 'nonW2',
  FIXED: 'fixed',
  UNEMPLOYMENT: 'unemployment',
  OTHER: 'other',
};

export const TYPES = {
  GROSS_YTD: 'grossYTD',
  BASE_PAY: 'basePay',
  NYS_DISABILITY: 'nysDisability',
  EARLY_YEAR: 'earlyYear',
  AVG_FROM_TAX_RETURNS: 'avgFromTaxReturns',
  AVG_FROM_DEPOSITS: 'avgFromDeposits',
  SOCIAL_SECURITY: 'socialSecurity',
  PENSION_AND_ETC: 'pensionAndEtc',
  DISABILITY_AND_ETC: 'disabilityAndEtc',
  INSURANCE: 'insurance',
  NON_CALCULATED: 'nonCalculated',
};

export const FIELD_NAMES = {
  INCOME: 'income',
  CATEGORY: 'category',
  TYPE: 'type',
  AMOUNT_RECEIVED: 'amountReceived',
  GROSS_YTD: 'grossYTDAmount',
  HOURLY_RATE: 'hourlyRate',
  HOURS_PER_WEEK: 'hoursPerWeek',
  NYS_DISABILITY_DEDUCTED: 'ytdNysDisabilityDeducted',
  PREVIOUS_YEAR_W2_AMOUNT: 'previousYearW2Amount',
  DATE_OF_HIRE: 'dateOfHire',
  PERIOD_ENDING_DATE: 'periodEndingDate',
  CHECK_OR_PAY_DATE: 'checkOrPayDate',
  LOANS_LISTED_ON_PAYCHECK: 'loansListedOnPaycheck',
  GARNISHMENTS: 'garnishments',
  TEMPORARY_SERVICE_EMPLOYEE: 'temporaryServiceEmployee',
  SEASONAL_EMPLOYEE: 'seasonalEmployee',
  LINE_31_SCHEDULE_C_YEAR: 'line31ScheduleCYear',
  TOTAL_BANK_DEPOSITS: 'totalBankDeposits',
};
