import { IFeesAndTaxesFormOptions, IProbabilityEstimatorPreferencesFormOptions } from 'modules/core/types';
import { convertFalsyValuesToZero } from 'modules/core/utils';
import { ProbabilityEstimatorService } from 'modules/ProbabilityEstimator/services/probabilityEstimator.service';

import { ILenderForm } from '../types/Lender.types';

export class LenderService {
  static getSubmitQuery(formValues: ILenderForm): Record<string, unknown> {
    const {
      feesAndTaxes,
      tier,
      preferences,
      totalNetDownPaymentDialog,
      totalFrontAndBackEndsAddsDialog,
      vehicleFilters,
      tableView,
      ...restValues
    } = formValues;
    const { salesTaxRate, ...restFeesAndTaxes } = feesAndTaxes;

    return {
      ...restValues,
      ...tier,
      ...ProbabilityEstimatorService.getFilterObject(vehicleFilters, tableView),
      ...convertFalsyValuesToZero(restFeesAndTaxes as Record<IFeesAndTaxesFormOptions, number>),
      ...convertFalsyValuesToZero(preferences as Record<IProbabilityEstimatorPreferencesFormOptions, string | number>),
      ...totalFrontAndBackEndsAddsDialog,
      amountOwed: totalNetDownPaymentDialog.amountOwed || 0,
      tradeValue: totalNetDownPaymentDialog.tradeValue || 0,
      cashDown: totalNetDownPaymentDialog.cashDown || 0,
      tableView,
      salesTaxRate,
    };
  }
}
