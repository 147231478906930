import styled from 'styled-components';
import { ModalButton } from 'components/ModalDialog/style';
import { RowUI } from 'modules/core/components/InfoRow/styles';

export const Container = styled.div`
  ${ModalButton} {
    margin-right: 12px;
  }
`;

export const CustomerInfo = styled.div`
  margin-bottom: 24px;

  ${RowUI}: last-child {
    text-transform: capitalize;
  }
`;

export const Link = styled.a`
  color: ${({ theme: { colors } }) => colors['blue-4']};
  text-decoration: none;

  &:hover {
    color: ${({ theme: { colors } }) => colors['blue-4']};
    text-decoration: none;
  }
`;
