import React from 'react';
import { ModalProps } from 'react-bootstrap';
import { ModalDialog } from 'components/ModalDialog';
import { useTranslation } from 'react-i18next';

import { AgreementWrapper } from './styles';
import { PRIVACY_POLICY } from './agreement-modals-content';
import { parseContent } from './agreement-modal.utils';

export const PrivacyPolicy: React.FC<ModalProps> = ({ show, onHide }: ModalProps) => {
  const { t } = useTranslation();

  return (
    <ModalDialog
      show={show}
      onHide={onHide}
      title={t('components.privacyPolicy.title')}
      submitButtonTitle={t('common.buttons.ok')}
    >
      <AgreementWrapper>{parseContent(PRIVACY_POLICY)}</AgreementWrapper>
    </ModalDialog>
  );
};
