import React, { FC, useEffect, useMemo, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Column, ColumnInstance } from 'react-table';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { COMMA_SYMBOL } from 'modules/core/constants';
import { PROBABILITY_ESTIMATOR_FIELDS } from 'modules/ProbabilityEstimator/probabilityEstimator.const';

import { SELECT_ALL_COLUMN_ID } from '../table.const';
import { AdditionalAdjustItems } from './AdditionalAdjustItems';
import { AdjustToggle, AdjustMenu, AdjustViewMenuItem, AdjustMenuItemTitle } from './styles';

export interface IAdjustDropdown {
  allColumns: ColumnInstance[];
  toggleHideAllColumns: (value: boolean) => void;
  defaultColumns: Column<Record<string, unknown>>[];
  customerFriendlyColumns: string[];
  isDisabled?: boolean;
  closeDropDown?: boolean;
}

export const AdjustDropdown: FC<IAdjustDropdown> = ({
  allColumns,
  toggleHideAllColumns,
  defaultColumns,
  customerFriendlyColumns,
  isDisabled,
  closeDropDown,
}) => {
  const { t } = useTranslation();
  const { setValue } = useFormContext();
  const [isChangedColumns, setIsChangedColumns] = useState(false);
  const tableColumns = useMemo(() => allColumns.filter(({ id }) => id !== SELECT_ALL_COLUMN_ID), [allColumns]);
  const selectedColumns = tableColumns
    .filter((item) => item.isVisible)
    .map((item) => item.id)
    .join(COMMA_SYMBOL);

  const setSelectedColumns = () => {
    setIsChangedColumns(true);
  };

  useEffect(() => {
    if (isChangedColumns) {
      setValue(PROBABILITY_ESTIMATOR_FIELDS.SELECTED_COLUMNS, selectedColumns);
      setIsChangedColumns(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedColumns]);

  const maxInputsBlockHeight = 700;
  const maxHeightAdjustMenu = `${document.body.clientHeight - maxInputsBlockHeight}px`;

  useEffect(() => {
    closeDropDown && document.getElementById('dropdown')?.click();
  }, [closeDropDown]);

  return (
    <Dropdown id="dropdown">
      <AdjustToggle disabled={isDisabled}>{t('components.table.adjustView.title')}</AdjustToggle>
      <AdjustMenu maxHeight={maxHeightAdjustMenu}>
        <AdditionalAdjustItems
          tableColumns={tableColumns}
          defaultColumns={defaultColumns}
          customerFriendlyColumns={customerFriendlyColumns}
          toggleHideAllColumns={toggleHideAllColumns}
          setSelectedColumns={setSelectedColumns}
          allColumns={allColumns}
        />
        {tableColumns.map((column) => (
          <AdjustViewMenuItem key={column.id}>
            <input
              type="checkbox"
              {...column.getToggleHiddenProps()}
              onClick={setSelectedColumns}
              data-testId={column.id}
            />
            <AdjustMenuItemTitle>{column.Header}</AdjustMenuItemTitle>
          </AdjustViewMenuItem>
        ))}
      </AdjustMenu>
    </Dropdown>
  );
};
