import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IApprovedLoanInput } from 'modules/ApprovedLoanInput/types';
import { INITIAL_VALUES } from 'modules/ApprovedLoanInput/approvedLoanInput.const';
import { IVehicle, IVehicleByStock } from 'modules/core/types';
import {
  getVehiclesByStockForApprovedLoanInputThunk,
  getVehiclesForApprovedLoanInputThunk,
} from 'actions/vehicleActions';
import { IIncomeForm } from 'components/IncomeCalculator/types';

export interface IApprovedLoanState {
  initialValues: IApprovedLoanInput;
  vehicles: {
    vehiclesByParams?: IVehicle[];
    vehiclesByStock?: IVehicle[];
  };
  income: IIncomeForm;
  isFetchingVehicles: boolean;
  isFetchingVehiclesFailed: boolean;
  isReset: boolean;
  isTableView: boolean;
  notFoundVehicles: string[];
  notAvailableVehicles: string[];
}

const initialState: IApprovedLoanState = {
  initialValues: INITIAL_VALUES,
  vehicles: {
    vehiclesByParams: undefined,
    vehiclesByStock: undefined,
  },
  income: INITIAL_VALUES.income,
  isFetchingVehicles: false,
  isFetchingVehiclesFailed: false,
  isReset: false,
  isTableView: true,
  notFoundVehicles: [],
  notAvailableVehicles: [],
};

export const approvedLoanSlice = createSlice({
  name: 'approvedLoanInput',
  initialState,
  reducers: {
    syncInitialValues: (state: IApprovedLoanState, { payload }: PayloadAction<IApprovedLoanInput>) => ({
      ...state,
      initialValues: payload,
    }),
    setIncome: (state: IApprovedLoanState, { payload: income }: PayloadAction<IIncomeForm>) => ({
      ...state,
      income,
    }),
    resetIncome: (state: IApprovedLoanState) => ({
      ...state,
      income: initialState.income,
    }),
    resetVehicles: (state: IApprovedLoanState) => ({
      ...state,
      vehicles: {
        vehiclesByParams: undefined,
        vehiclesByStock: undefined,
      },
    }),
    resetVehiclesByStock: (state: IApprovedLoanState) => ({
      ...state,
      vehicles: { ...state.vehicles, vehiclesByStock: undefined },
    }),
    resetVehiclesByParams: (state: IApprovedLoanState) => ({
      ...state,
      vehicles: { ...state.vehicles, vehiclesByParams: undefined },
    }),
    resetByStockOptions: (state: IApprovedLoanState) => ({
      ...state,
      notFoundVehicles: [],
      notAvailableVehicles: [],
    }),
    resetByStock: (state: IApprovedLoanState) => ({
      ...state,
      notFoundVehicles: [],
      notAvailableVehicles: [],
      vehicles: { ...state.vehicles, vehiclesByStock: undefined },
    }),
    setIsReset: (state: IApprovedLoanState, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isReset: payload,
    }),
    setIsTableView: (state: IApprovedLoanState, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isTableView: payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(
      getVehiclesForApprovedLoanInputThunk.fulfilled,
      (state: IApprovedLoanState, { payload: vehicles }: PayloadAction<IVehicle[]>) => ({
        ...state,
        vehicles: { ...state.vehicles, vehiclesByParams: Array.isArray(vehicles) ? vehicles : [] },
        isFetchingVehicles: false,
        isFetchingVehiclesFailed: false,
      }),
    );
    builder.addCase(getVehiclesForApprovedLoanInputThunk.pending, (state: IApprovedLoanState) => ({
      ...state,
      vehicles: { ...state.vehicles, vehiclesByParams: undefined },
      isReset: false,
      isFetchingVehicles: true,
      isFetchingVehiclesFailed: false,
    }));
    builder.addCase(getVehiclesForApprovedLoanInputThunk.rejected, (state: IApprovedLoanState) => ({
      ...state,
      vehicles: { ...state.vehicles, vehiclesByParams: undefined },
      isFetchingVehicles: false,
      isFetchingVehiclesFailed: true,
    }));

    builder.addCase(
      getVehiclesByStockForApprovedLoanInputThunk.fulfilled,
      (state: IApprovedLoanState, { payload }: PayloadAction<IVehicleByStock[]>) => ({
        ...state,
        vehicles: { ...state.vehicles, vehiclesByStock: payload[0].vehicles },
        notFoundVehicles: payload[0].search_result.not_in_db,
        notAvailableVehicles: payload[0].search_result.not_available,
        isFetchingVehicles: false,
        isFetchingVehiclesFailed: false,
      }),
    );

    builder.addCase(getVehiclesByStockForApprovedLoanInputThunk.pending, (state: IApprovedLoanState) => ({
      ...state,
      vehicles: { ...state.vehicles, vehiclesByStock: undefined },
      notFoundVehicles: [],
      notAvailableVehicles: [],
      isReset: false,
      isFetchingVehicles: true,
      isFetchingVehiclesFailed: false,
    }));

    builder.addCase(getVehiclesByStockForApprovedLoanInputThunk.rejected, (state: IApprovedLoanState) => ({
      ...state,
      vehicles: { ...state.vehicles, vehiclesByStock: undefined },
      notFoundVehicles: [],
      notAvailableVehicles: [],
      isFetchingVehicles: false,
      isFetchingVehiclesFailed: true,
    }));
  },
});

export const {
  setIncome,
  resetVehicles,
  resetVehiclesByStock,
  resetVehiclesByParams,
  resetIncome,
  setIsReset,
  setIsTableView,
  resetByStockOptions,
  resetByStock,
} = approvedLoanSlice.actions;
