import { useEffect, useState } from 'react';

import { WindowBreakpoints } from '../modules/core/enums/windowBreakpoints.enum';

interface IWindowDimensionSize {
  width: number;
  height: number;
}

interface IWindowDimensionResult extends IWindowDimensionSize {
  isSmallWidth: boolean;
  isMediumWidth: boolean;
  isLargeWidth: boolean;
  isXXXLargeWidth: boolean;
}

export const useWindowDimensions = (): IWindowDimensionResult => {
  const initialDimensions = { width: 0, height: 0 };
  const [windowDimensions, setWindowDimensions] = useState<IWindowDimensionSize>(initialDimensions);

  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;

    return {
      width,
      height,
    };
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const { small, medium, xxxLarge, large } = WindowBreakpoints;
  const { width } = windowDimensions;

  return {
    ...windowDimensions,
    isSmallWidth: width ? width <= small : false,
    isMediumWidth: width ? width <= medium : false,
    isLargeWidth: width ? width <= large : false,
    isXXXLargeWidth: width ? width <= xxxLarge : false,
  };
};
