import React, { FC } from 'react';
import { ICONS_HEIGHT, STATIC_IMAGE_URLS } from 'modules/core/constants';

import { Icon } from '../Icon';

interface IIconSuccess {
  height?: string;
}

export const IconSuccess: FC<IIconSuccess> = ({ height = ICONS_HEIGHT.DEFAULT }) => (
  <Icon imageSrc={STATIC_IMAGE_URLS.icons.success} height={height} />
);
