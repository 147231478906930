import { useFormContext } from 'react-hook-form';
import { LoanStatusColors } from 'modules/core/enums';
import { ILoanStatusGradientBackgroundProps } from 'modules/core/types';
import { PROBABILITY_ESTIMATOR_FIELDS } from 'modules/ProbabilityEstimator/probabilityEstimator.const';

import { getBackgroundPropsByLoanStatus } from '../components/Table/helper';

type TBackgroundProps = ILoanStatusGradientBackgroundProps | string;

export const useBackgroundPropsByLoanStatus = (
  loanStatus: LoanStatusColors | undefined,
  backgroundColorPropsMap: Map<LoanStatusColors, TBackgroundProps>,
): TBackgroundProps => {
  const { watch } = useFormContext();

  const tableView = watch(PROBABILITY_ESTIMATOR_FIELDS.TABLE_VIEW);

  return getBackgroundPropsByLoanStatus(loanStatus, backgroundColorPropsMap, tableView);
};
