import styled from 'styled-components';
import { TableTopSection, TableItems } from 'modules/core/components/Table/styles';

import { WindowBreakpoints } from '../core/enums/windowBreakpoints.enum';

export const Container = styled.div`
  ${TableTopSection} {
    position: relative;

    @media (max-width: ${WindowBreakpoints.medium}px) {
      display: flex;
      flex-direction: column;

      .card-body {
        padding-left: 0px;
        padding-right: 0px;
      }
    }
  }

  ${TableItems} {
    position: absolute;
    right: 0;
    top: 4px;

    @media (max-width: ${WindowBreakpoints.medium}px) {
      position: static;
      align-self: flex-end;
      margin-top: 5px;
    }

    @media (max-width: ${WindowBreakpoints.small}px) {
      flex-wrap: wrap;
      width: 100%;
      justify-content: space-between;
    }
  }

  .make-it-work-badge {
    display: none;
  }
`;
