import styled from 'styled-components';
import { Form } from 'react-bootstrap';
import { COLORS } from 'modules/core/theme/colors';

const COMMON_PX_SIZE = '42px';

export const FormUI = styled(Form)`
  margin-bottom: ${COMMON_PX_SIZE};
`;

export const FormGroup = styled(Form.Group)`
  margin-bottom: ${COMMON_PX_SIZE};

  &:first-child {
    margin-bottom: 24px;
  }

  input {
    height: ${COMMON_PX_SIZE};
  }
`;

export const Label = styled(Form.Label)`
  color: ${COLORS.GREY_3};
`;
