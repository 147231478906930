import React, { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { Tag } from './Tag';
import { EMPTY_STRING } from '../../constants';
import { Input, TagInput } from './styles';

interface ITagsInputProps {
  name: string;
  placeHolder: string;
  onChange: (tags: string[]) => void;
  values: string[];
}

export enum KeyName {
  ENTER = 'Enter',
  BACKSPACE = 'Backspace',
}

export const TagsInput: FC<ITagsInputProps> = ({ name, placeHolder, onChange, values }) => {
  const { setValue } = useFormContext();

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    onChange(values);
  }, [values.length]);

  const handleOnKeyUp = (e: any) => {
    e.stopPropagation();

    const text = e.target.value.toUpperCase().replace(/\s/g, EMPTY_STRING);

    if (e.key === KeyName.BACKSPACE && values.length && !text) {
      setValue(name, values.slice(0, -1));
    }

    if (text && KeyName.ENTER === e.key) {
      if (values.includes(text)) {
        e.target.value = EMPTY_STRING;
        e.preventDefault();

        return;
      }

      setValue(name, [...values, text]);
      e.target.value = EMPTY_STRING;
      e.preventDefault();
    }
  };

  const onTagRemove = (text: string) => {
    setValue(
      name,
      values.filter((value: string) => value !== text),
    );
  };

  return (
    <TagInput aria-labelledby={name}>
      {values.map((value: string) => (
        <Tag key={value} text={value} remove={onTagRemove} />
      ))}

      <Input
        autoComplete="off"
        type="search"
        data-testId={name}
        name={name}
        placeholder={placeHolder}
        onKeyDown={handleOnKeyUp}
      />
    </TagInput>
  );
};
