export const IS_AUTHORIZED_LS_KEY = 'isAuthorized';

export const IS_SSO_AND_SHOW_LOAN_VARIABLES_DIALOG = 'isSsoAndShowLoanVariablesDialog';

export const SUBDOMAIN_LS_KEY = 'subdomain';

export const KEEP_ME_LOGGED_IN = 'keepMeLoggedIn';

export const CSRF_TOKEN_NAME = 'csrfToken';

export const TWO_WEEKS_SECONDS = 2 * 7 * 24 * 60 * 60;
