import { useFormContext } from 'react-hook-form';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getFeesAndTaxesState } from 'store/selectors/controls.selector';
import VehicleApi from 'api/vehicleApi';
import { getCurrentVendorMappings } from 'store/selectors/ui.selector';
import { LENDERS_MOTHLY_INCOME_DEFAULT_VALUES } from 'modules/RACAffordabilityEngine/RacMontlyIncomeModal/consts/defaultValues';
import { getUser } from 'store/selectors/auth.selector';

import { IUser, IVehicleForm } from '../types';
import { PREFERENCES_FIELD_NAMES } from '../shared';

interface IUseVehicleFormReset {
  resetInputs: VoidFunction;
  resetFilters: VoidFunction;
  resetAll: VoidFunction;
}

export const useVehicleFormReset = (
  initialValues: IVehicleForm,
  resetStateCallback?: VoidFunction,
  resetVehicleFiltersDirtyFields?: VoidFunction,
  ltvTaxesFeesFilter?: string,
): IUseVehicleFormReset => {
  const { reset, getValues, setValue } = useFormContext();
  const { estimatedFeesControl, salesTaxRateControl, insuranceControl } = useSelector(getFeesAndTaxesState);
  const vendorOptions = useSelector(getCurrentVendorMappings);
  const user = useSelector(getUser) as IUser;

  const { rateSheetSettingsModal, feesAndTaxes } = getValues();

  const feesAndTaxesInitialValues = useMemo(
    () => ({
      ...initialValues.feesAndTaxes,
      salesTaxRate: salesTaxRateControl,
      estimatedFees: estimatedFeesControl,
      salesFees: estimatedFeesControl,
      vscAndGapInsurance: insuranceControl,
    }),
    [initialValues.feesAndTaxes, salesTaxRateControl, estimatedFeesControl, insuranceControl],
  );

  const withoutInventoryResetInitialValues = useCallback(
    () => ({
      feesAndTaxes: {
        ...feesAndTaxesInitialValues,
        salesTaxRate: feesAndTaxes.salesTaxRate,
      },
      rateSheetSettingsModal: rateSheetSettingsModal,
    }),
    [feesAndTaxes.salesTaxRate, feesAndTaxesInitialValues, rateSheetSettingsModal],
  );
  const withoutInventoryInitialValues = useCallback(
    () => (user?.isWithInventory ? {} : withoutInventoryResetInitialValues()),
    [user?.isWithInventory, withoutInventoryResetInitialValues],
  );

  const preferences = useMemo(
    () => ({
      ...initialValues.preferences,
      ltvTaxesFeesFilter: ltvTaxesFeesFilter || initialValues.preferences.ltvTaxesFeesFilter,
    }),
    [initialValues.preferences, ltvTaxesFeesFilter],
  );

  const resetState = useCallback(() => {
    resetStateCallback?.();
    setValue(PREFERENCES_FIELD_NAMES.VENDOR_FIELD, Object.keys(vendorOptions)[0]);
  }, [resetStateCallback, setValue, vendorOptions]);

  const resetInputs = useCallback(() => {
    const { vehicleFilters, ...otherInitialValues } = initialValues;
    const { tableView, stocks, ...otherFormValues } = getValues();

    VehicleApi.cancelAllRequests();

    reset({
      ...otherFormValues,
      ...otherInitialValues,
      tableView,
      stocks,
      feesAndTaxes: feesAndTaxesInitialValues,
      lendersMonthlyIncomeModal: LENDERS_MOTHLY_INCOME_DEFAULT_VALUES,
      preferences: preferences,
      netDownPayment: undefined,
      frontAndBackEndsAdds: insuranceControl,
      totalFrontAndBackEndsAddsDialog: {
        addOns: undefined,
        gap: undefined,
        vsc: insuranceControl,
      },
      ...withoutInventoryInitialValues(),
    });

    resetState();
  }, [
    initialValues,
    getValues,
    reset,
    feesAndTaxesInitialValues,
    preferences,
    insuranceControl,
    withoutInventoryInitialValues,
    resetState,
  ]);

  const resetFilters = useCallback(() => {
    reset(
      {
        ...getValues(),
        vehicleFilters: initialValues.vehicleFilters,
        accordionShown: initialValues.vehicleTableAccordion,
        tableView: initialValues.tableView,
        stocks: initialValues.stocks,
      },
      { isValid: true },
    );

    if (resetVehicleFiltersDirtyFields) resetVehicleFiltersDirtyFields();
  }, [
    reset,
    getValues,
    initialValues.vehicleFilters,
    initialValues.vehicleTableAccordion,
    initialValues.tableView,
    initialValues.stocks,
    resetVehicleFiltersDirtyFields,
  ]);

  const resetAll = useCallback(() => {
    const { tableView, ...otherFormValues } = getValues();

    VehicleApi.cancelAllRequests();

    reset({
      ...otherFormValues,
      ...initialValues,
      tableView: initialValues.tableView,
      feesAndTaxes: feesAndTaxesInitialValues,
      lendersMonthlyIncomeModal: LENDERS_MOTHLY_INCOME_DEFAULT_VALUES,
      preferences: preferences,
      netDownPayment: undefined,
      frontAndBackEndsAdds: insuranceControl,
      totalFrontAndBackEndsAddsDialog: {
        addOns: undefined,
        gap: undefined,
        vsc: insuranceControl,
      },
      ...withoutInventoryInitialValues(),
    });

    if (resetVehicleFiltersDirtyFields) resetVehicleFiltersDirtyFields();

    resetState();
  }, [
    getValues,
    reset,
    initialValues,
    feesAndTaxesInitialValues,
    preferences,
    insuranceControl,
    withoutInventoryInitialValues,
    resetVehicleFiltersDirtyFields,
    resetState,
  ]);

  return {
    resetInputs,
    resetFilters,
    resetAll,
  };
};
