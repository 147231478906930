import i18n from 'i18n';

import { Roles } from '../../../modules/core/enums';

export const FIELD_NAMES = {
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  EMAIL: 'email',
  PHONE_NUMBER: 'phoneNumber',
  DEALERSHIP: 'dealership',
  ROLE: 'role',
};

export const FIELDS = [
  FIELD_NAMES.FIRST_NAME,
  FIELD_NAMES.LAST_NAME,
  FIELD_NAMES.EMAIL,
  FIELD_NAMES.PHONE_NUMBER,
  FIELD_NAMES.DEALERSHIP,
  FIELD_NAMES.ROLE,
];

export const USER_INFO_FIELD_DEFAULT_VALUE = i18n.t('components.myAccount.noValuePresent');

export const SUCCESSFUL_CHANGE_MESSAGE = i18n.t('components.resetPassword.successfulChangePassword');

export const PASSWORD_CHANGE_FAILED_ERROR_NAME = 'passwordChangeFailed';

export const CHANGE_PASSWORD_NAMES = {
  OLD_PASSWORD: 'old_password',
  NEW_PASSWORD: 'new_password1',
  CONFIRMED_NEW_PASSWORD: 'new_password2',
};

export const CHANGE_PASSWORD_NAMES_MAPPER = {
  oldPassword: CHANGE_PASSWORD_NAMES.OLD_PASSWORD,
  newPassword: CHANGE_PASSWORD_NAMES.NEW_PASSWORD,
  confirmedNewPassword: CHANGE_PASSWORD_NAMES.CONFIRMED_NEW_PASSWORD,
};

export const PASSWORD_FIELDS = [
  CHANGE_PASSWORD_NAMES.OLD_PASSWORD,
  CHANGE_PASSWORD_NAMES.NEW_PASSWORD,
  CHANGE_PASSWORD_NAMES.CONFIRMED_NEW_PASSWORD,
];

export const TITLES: Record<string, string> = {
  MANAGER: i18n.t('components.myAccount.managerAccount'),
  SALESPERSON: i18n.t('components.myAccount.myAccount'),
};

export const TITLES_BY_ROLE_MAP = new Map<Roles, string>([
  [Roles.SALESPERSON, TITLES.SALESPERSON],
  [Roles.MANAGER, TITLES.MANAGER],
]);
