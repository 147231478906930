import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import { AuthReducerState } from 'store/reducers/auth.reducer';
import { IUser } from 'modules/core/types';

export const getAuthState = (state: RootState): AuthReducerState => state.auth;

export const getUser = createSelector(getAuthState, ({ user }): IUser | null => {
  if (user) {
    return {
      firstName: user.first_name,
      lastName: user.last_name,
      email: user.email,
      dealer: user.dealer,
      phoneNumber: user.phone_number,
      role: user.role,
      tenant: user.tenant,
      hasExecutiveAccess: user.has_executive_access,
      tenants: user.tenants,
      isWithInventory: user.with_inventory,
      is_sso: user.is_sso,
      rateSheetsData: user.rate_sheets_data,
    };
  }

  return null;
});
