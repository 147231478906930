import React from 'react';
import { useTranslation } from 'react-i18next';

import { LearningWrapper } from '../common/LearningWrapper/LearningWrapper';
import { ExtendedCardForm, TitleH1, AdditionalContainer } from '../styles';
import { LEARNING_PATHS } from '../Learning.const';

export const LearningStart: React.FC = () => {
  const { t } = useTranslation();

  return (
    <LearningWrapper nextPageLink={LEARNING_PATHS.keyFactors} isBackground>
      <AdditionalContainer>
        <ExtendedCardForm>
          <TitleH1>{t('components.learningLink.start.title')}</TitleH1>
          {t('components.learningLink.start.description')}
        </ExtendedCardForm>
      </AdditionalContainer>
    </LearningWrapper>
  );
};
