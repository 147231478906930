import React, { FC, useCallback, useMemo, useState } from 'react';
import { Image as ImageUI } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { IRACVehicle } from 'modules/core/types';
import { colonFormatter, costFormatter, thousandFormatter, vinFormatter } from 'modules/core/utils';
import { Badge, Stock } from 'modules/core/components';
import { useBackgroundPropsByLoanStatus, useModalState, useVehicleStatusBadgeProps } from 'modules/core/hooks';
import { useFormContext } from 'react-hook-form';
import { TableView } from 'modules/core/enums';
import { MODAL_DIALOG_ID, STATIC_IMAGE_URLS } from 'modules/core/constants';
import { setVehicleDetails } from 'store/reducers/vehicle.reducer';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'store/store';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { VINSpan } from 'common/styles';
import { showNotification } from 'store/reducers/ui.reducer';
import { getRouteOneData } from 'store/selectors/RACAffordabilityEngine.selector';

import { CARD_COLOR_MAP, CARD_HEADER_COLOR_MAP } from '../VehicleCard/common.const';
import { Card, CardHeader, CardRow, CardDescription, CardImageContainer, CardCheckbox } from './styles';

export interface IRACVehicleCard {
  vehicle: IRACVehicle;
}

export const LenderVehicleCard: FC<IRACVehicleCard> = ({ vehicle, children }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { handleOpenDialog } = useModalState([MODAL_DIALOG_ID.VEHICLE_DETAILS]);
  const routeOneData = useSelector(getRouteOneData);
  const { t } = useTranslation();
  const loanStatusBackgroundColor = useBackgroundPropsByLoanStatus(vehicle.loan_status, CARD_COLOR_MAP);
  const loanStatusHeaderBackgroundColor = useBackgroundPropsByLoanStatus(vehicle.loan_status, CARD_HEADER_COLOR_MAP);
  const badgeProps = useVehicleStatusBadgeProps(vehicle.age, vehicle.is_new, vehicle.is_sold);
  const { watch } = useFormContext();
  const { tableView } = watch();
  const { noPhoto, blackCar, whiteCar } = STATIC_IMAGE_URLS.icons;

  const isImageExist = !!vehicle.photo;

  const openVehicleDetailsDialog = useCallback(() => {
    dispatch(setVehicleDetails({ id: vehicle.id, title: vehicle.description }));
    handleOpenDialog();
  }, [handleOpenDialog, dispatch, vehicle]);

  const src = useMemo(() => {
    if (vehicle.manually_created) {
      if (Object.keys(routeOneData).length > 0 && routeOneData.sale_vehicle.vin === vehicle.vin) {
        return whiteCar;
      }

      return blackCar;
    }

    return isImageExist ? vehicle.photo : noPhoto;
  }, [vehicle.manually_created, vehicle.photo, vehicle.vin, isImageExist, noPhoto, routeOneData, blackCar, whiteCar]);

  const extraCash =
    typeof vehicle.ltv_value?.extra_down_payment === 'number'
      ? vehicle.ltv_value?.extra_down_payment // for RAC
      : vehicle.salesperson_bucket; // for Lenders

  const [imageSrc, setImageSrc] = useState<string>(src);

  const dialogTriggerProps = useMemo(
    () => ([noPhoto, whiteCar, blackCar].includes(imageSrc) ? {} : { onClick: openVehicleDetailsDialog }),
    [blackCar, imageSrc, noPhoto, openVehicleDetailsDialog, whiteCar],
  );

  const handleError = () => {
    setImageSrc(STATIC_IMAGE_URLS.icons.noPhoto);
  };

  return (
    <Card key={vehicle.id} color={loanStatusBackgroundColor as string} tableView={tableView}>
      <CardCheckbox>{children}</CardCheckbox>
      <CardHeader color={loanStatusHeaderBackgroundColor as string}>
        {vehicle.manually_created ? <div></div> : <Stock stock={vehicle.stock} />}
        <CopyToClipboard
          text={vehicle.vin}
          onCopy={() => dispatch(showNotification({ message: t('successfullyCopied'), type: 'successful' }))}
        >
          <VINSpan title={t('components.table.vinHelpMessage')}>{vinFormatter(vehicle.vin)}</VINSpan>
        </CopyToClipboard>
      </CardHeader>
      <CardImageContainer>
        <ImageUI src={imageSrc} onError={handleError} {...dialogTriggerProps} data-testId="card-rac-vehicle" />
      </CardImageContainer>
      <CardDescription>{vehicle.description}</CardDescription>
      <CardRow>{badgeProps && <Badge {...badgeProps} />}</CardRow>
      <CardRow>
        <span>{colonFormatter(t('components.table.columns.location'))}</span>
        <span>{vehicle.location}</span>
      </CardRow>
      <CardRow>
        <span>{colonFormatter(t('components.table.columns.miles'))}</span>
        <span>{thousandFormatter(vehicle.odometer)}</span>
      </CardRow>
      <CardRow>
        <span>{colonFormatter(t('components.table.columns.retailPrice'))}</span>
        <span>{costFormatter(vehicle.current_price)}</span>
      </CardRow>
      {tableView === TableView.SALESPERSON && typeof extraCash === 'number' && (
        <CardRow>
          <span>{colonFormatter(t('components.table.columns.extraCash'))}</span>
          <span>{costFormatter(extraCash)}</span>
        </CardRow>
      )}
    </Card>
  );
};
