import styled from 'styled-components';
import Image from 'react-bootstrap/Image';
import { Col, Form, Row } from 'react-bootstrap';
import { disabledSectionMixin, modalButtonMixin } from 'modules/core/theme/mixins';
import { Button } from 'modules/core/components/Button/styles';
import { STATIC_IMAGE_URLS } from 'modules/core/constants';
import { DEFAULT_FONT_SIZE } from 'modules/core/theme/typography';
import { Title } from 'components/ModalDialog/style';

import { COLORS } from '../../../modules/core/theme/colors';

export const Img = styled(Image)<{ isWithInventory: boolean }>`
  width: auto;
  max-height: 190px;
  max-width: 280px;

  ${({ isWithInventory }) => !isWithInventory && `min-width: 220px;`}
`;

export const VehiclePhotoBlock = styled.div`
  width: fit-content;
`;

export const ImgContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CardRow = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 10px 4px;
`;

export const DesctiptionRow = styled(Row)`
  margin-bottom: 10px;
`;

export const TierCol = styled(Col)`
  flex: none;
  width: 12%;
`;

export const MonthlyIncomeCol = styled(Col)`
  width: 27%;
  flex: none;
`;

export const ApprovedPaymentCol = styled(Col)`
  width: 30%;
  flex: none;
`;

export const CurrentPaymentCol = styled(Col)`
  width: 27%;
  flex: none;
`;

export const Card = styled.div`
  div:first-child {
    margin-top: 0;
  }
`;

export const FieldLabel = styled(Form.Label)`
  margin-bottom: 0.2rem;
  font-size: ${DEFAULT_FONT_SIZE};
  color: ${COLORS.GREY_24};
  font-weight: bold;
`;

export const VehiclesDescription = styled.div`
  div:first-child {
    font-size: 2rem;
    font-weight: 700;
    color: ${COLORS.BLACK_3};
  }
  div.vehicle-name {
    font-size: ${DEFAULT_FONT_SIZE};
    font-weight: bold;
    color: ${COLORS.GREY_24};
  }
  div.vehicle-stock-vin {
    font-size: ${DEFAULT_FONT_SIZE};
    font-weight: bold;
    color: ${COLORS.GREY_24};
  }
  div:last-child {
    font-size: ${DEFAULT_FONT_SIZE};
    font-weight: bold;
    color: ${COLORS.GREY_24};
    display: flex;
    flex-direction: column;
  }
`;

export const TierLabel = styled.div`
  margin-top: 2px;
`;

export const TierValue = styled.div<{ isAdjsutDealView: boolean }>`
  font-size: 15px;
  margin-top: ${({ isAdjsutDealView }) => (isAdjsutDealView ? `10px` : `3px`)};
  font-weight: bold;
  color: ${COLORS.BLACK_3};
`;

export const VehiclesDescriptionFields = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 1rem;
  color: ${COLORS.GREY_3};

  div:first-child {
    font-size: 15px;
    font-weight: bold;
  }
`;

export const Link = styled.a`
  color: ${({ theme: { colors } }) => colors['blue-4']};
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: ${({ theme: { colors } }) => colors['blue-4']};
    text-decoration: none;
  }
`;

export const HeaderActionsBlock = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const RecalculateBlock = styled.div`
  padding: 0 10px 15px 10px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const RecalculateButton = styled(Button)`
  width: 90%;
  font-size: 14px;
  min-width: auto;
  min-height: 30px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
`;

export const RecalculateIcon = styled.div<{ disabled: boolean }>`
  background-image: url(${STATIC_IMAGE_URLS.icons.whiteUndo});
  background-size: cover;
  background-repeat: no-repeat;
  height: 17px;
  width: 17px;
  border: none;
  cursor: pointer;
  transform: scaleX(-1);
  margin-right: 5px;
`;

export const SubmitMessageBlock = styled.div<{ width: string; isInvalid?: boolean }>`
  width: ${({ width }) => width && `${width}px`};
  ${({ isInvalid }) => isInvalid && `color: ${COLORS.RED_6}; font-style: italic;`}
`;

export const SubmitButtonsBlock = styled.div<{ isMakeItWork?: boolean }>`
  display: flex;
  flex-direction: column;
  ${({ isMakeItWork }) => isMakeItWork && `width: min-content; text-align: justify;`};
`;

export const ModalButton = styled(Button)<{ width?: string; padding?: string }>`
  ${modalButtonMixin};
  width: ${({ width }) => (width ? `${width}` : '120px')};
  padding: ${({ padding }) => (padding ? `${padding}` : '12px')};
  ${({ $marginRight }) => $marginRight && `margin-right: ${$marginRight};`};
`;

export const ColWithBorder = styled(Col)`
  border-right: 1px solid ${COLORS.GREY_25};
  margin-bottom: 0.5rem;
  width: 20%;
`;

export const FieldsRow = styled(Row)`
  div:nth-last-child(2) {
    border-right: 0;
  }
`;

export const FormControlFeedback = styled(Form.Control.Feedback)`
  font-size: 14px !important;
  font-weight: normal !important;
  margin-top: 3px;
  color: ${({ $isInvalid }) => ($isInvalid ? '#dc3545' : COLORS.BLACK_3)} !important;
`;

export const OptimizeDealTitle = styled(Title)`
  padding-left: 10px;
`;

export const AlternativeDealStructureTitle = styled(Title)`
  width: 100%;
  padding: 10px 0;
  border-top: 1px solid #e3e2e1;
`;

export const ActualPaymentInfoTitle = styled.div`
  margin-top: 10px;
  font-size: 12px;
  font-style: italic;
  text-align: justify;
`;

export const PaymentContainer = styled(Col)`
  padding: 0 20px;
  border-left: 1px solid ${COLORS.GREY_25};
`;

export const PaymentBlock = styled(Col)`
  display: flex;
  justify-content: start;
  flex-direction: column;
  background: ${COLORS.GREY_10};
  border-radius: 10px;
`;

export const TableBlock = styled.div`
  margin-top: 20px;
  padding: 0 10px;

  .table-responsive table {
    margin-bottom: 0;
  }
`;

export const ConfirmationToExistsBlock = styled.div`
  padding: 10px 5px;
  text-align: center;
  line-height: 35px;
  border-radius: 0.5rem;
  color: ${COLORS.GREY_24};
  font-weight: bold;
  background: white;
`;

export const ButtonUndo = styled.div`
  background-image: url(${STATIC_IMAGE_URLS.icons.undoIcon});
  background-size: cover;
  background-repeat: no-repeat;
  height: 25px;
  width: 25px;
  border: none;
  cursor: pointer;
  margin: 0 10px;
`;

export const ButtonUndoBlock = styled.div<{ disabled: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;

  ${({ disabled }) => disabled && disabledSectionMixin}
`;

export const ButtonUndoLabel = styled.div`
  font-size: ${DEFAULT_FONT_SIZE};
  color: #5c6c8a;
  width: 35px;
  margin-bottom: 0;
  font-weight: ${({ theme: { boldText } }) => boldText.bold};
`;

export const ModalBackdrop = styled.div<{ showBackdrop: boolean }>`
  ${({ showBackdrop }) =>
    showBackdrop &&
    `
    background: black;
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 4;
    opacity: 0.5;
  `}
`;

export const FailedBlock = styled.div`
  .modal-body {
    display: flex;
    height: 40vh;
    flex-direction: column;
    justify-content: center;

    span {
      width: 100%;
    }
  }
`;

export const TradeMark = styled.span`
  font-size: 20px;
`;
