import styled from 'styled-components';
import Toggle from 'react-toggle';
import 'react-toggle/style.css';
import { COLORS } from 'modules/core/theme/colors';

export const ToggleUI = styled(Toggle)`
  & .react-toggle-track {
    background-color: ${COLORS.GREY_1};
  }

  &.react-toggle--checked .react-toggle-track {
    background-color: ${COLORS.GREEN_9};
  }

  &.react-toggle:hover .react-toggle-track {
    background-color: ${COLORS.GREY_3};
  }

  &.react-toggle--checked:hover .react-toggle-track {
    background-color: ${COLORS.GREEN_3};
  }

  &.react-toggle--focus .react-toggle-thumb {
    box-shadow: 0 0 0 0;
  }

  &.react-toggle:active .react-toggle-thumb {
    box-shadow: 0px 0px 5px 5px ${COLORS.GREEN_3};
  }
`;
