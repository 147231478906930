export const FEES_AND_TAXES_INDEX_FIELD = 'feesAndTaxes';

export const FEES_AND_TAXES_FIELD_NAMES = {
  SALES_TAX_RATE: `${FEES_AND_TAXES_INDEX_FIELD}.salesTaxRate`,
  ESTIMATED_FEES: `${FEES_AND_TAXES_INDEX_FIELD}.estimatedFees`,
};

export const FRONT_AND_BACK_ENDS_ADDS_INDEX_FIELD = 'totalFrontAndBackEndsAddsDialog';

export const FRONT_AND_BACK_ENDS_ADDS_FIELD_NAMES = {
  ADD_ONS: `${FRONT_AND_BACK_ENDS_ADDS_INDEX_FIELD}.addOns`,
  VSC: `${FRONT_AND_BACK_ENDS_ADDS_INDEX_FIELD}.vsc`,
  GAP: `${FRONT_AND_BACK_ENDS_ADDS_INDEX_FIELD}.gap`,
  TOTAL_FRONT_AND_BACK_ENDS_ADDS: `${FRONT_AND_BACK_ENDS_ADDS_INDEX_FIELD}.totalFrontAndBackEndsAdds`,
};

export const APPROVED_LOAN_TERMS_FIELD_NAMES = {
  TIER: 'tier',
  STOCKS: 'stocks',
  FILTER_VIEW: 'filterView',
  FRONT_AND_BACK_ENDS_ADDS: 'frontAndBackEndsAdds',
  DESIRED_MONTHLY_PAYMENT: 'desiredMonthlyPayment',
  GROSS_MONTHLY_INCOME: 'grossMonthlyIncome',
  NET_DOWN_PAYMENT: 'netDownPayment',
};

export const WITHOUT_INVENTORY_FIELDS_PREFIX = 'rateSheetSettingsModal';

export const WITHOUT_INVENTORY_FIELDS_NAMES = {
  RATE_SHEET: `${WITHOUT_INVENTORY_FIELDS_PREFIX}.rateSheet`,
  REGION: `${WITHOUT_INVENTORY_FIELDS_PREFIX}.region`,
  TAX_RATE: `${WITHOUT_INVENTORY_FIELDS_PREFIX}.taxRate`,
};
