import { shallowEqual, useSelector } from 'react-redux';
import { ILenderVehicle, IRACVehicle, IUser, IVehicle, TFetchingVehicleFlagsSelector } from 'modules/core/types';
import { VehicleTablePlaceholderType } from 'modules/core/enums';
import { useDebounceVariable } from 'modules/core/hooks';
import { IS_FETCHING_VEHICLE_DELAY } from 'modules/core/constants';
import { useEffect, useState } from 'react';
import { getUser } from 'store/selectors/auth.selector';

export const useTablePlaceholderType = (
  isValid: boolean,
  vehicles: IVehicle[] | IRACVehicle[] | ILenderVehicle[],
  fetchingVehicleFlagsSelector: TFetchingVehicleFlagsSelector,
  previewVehiclesCount?: number,
  isValidStock?: boolean,
): VehicleTablePlaceholderType => {
  const { isFetchingVehicles, isFetchingVehiclesFailed, isReset } = useSelector(
    fetchingVehicleFlagsSelector,
    shallowEqual,
  );
  const user = useSelector(getUser) as IUser;

  const [isWasRequest, setIsWasRequest] = useState(false);

  useEffect(() => {
    if (isFetchingVehicles) setIsWasRequest(true);

    if (isReset) setIsWasRequest(false);
  }, [isFetchingVehicles, isReset]);

  // useDebounceVariable hook is used to prevent error placeholder from being displayed for short period of time when request is canceled.
  const debouncedIsFetchingVehiclesFailed = useDebounceVariable(isFetchingVehiclesFailed, IS_FETCHING_VEHICLE_DELAY);

  const isNoVehiclesFound = isValid && !vehicles.length;

  if (!isValidStock) return VehicleTablePlaceholderType.NEED_STOCK;

  if (debouncedIsFetchingVehiclesFailed && !isReset) return VehicleTablePlaceholderType.ERROR;

  if (user?.is_sso && !user?.isWithInventory && !isFetchingVehicles && isNoVehiclesFound)
    return VehicleTablePlaceholderType.DEFAULT;

  if (isFetchingVehicles || (!Number.isInteger(previewVehiclesCount) && isNoVehiclesFound))
    return VehicleTablePlaceholderType.LOADING;

  if (isFetchingVehiclesFailed && !debouncedIsFetchingVehiclesFailed) return VehicleTablePlaceholderType.LOADING;

  if (isReset && !isWasRequest) return VehicleTablePlaceholderType.DEFAULT;

  if (isNoVehiclesFound && !isFetchingVehicles && isWasRequest) return VehicleTablePlaceholderType.SEARCH;

  return VehicleTablePlaceholderType.DEFAULT;
};
