export const sendMetric = (action: string, category?: string, label?: string, value?: string): void => {
  const values = {} as any;

  if (typeof category !== 'undefined') {
    values.event_category = category;
  }

  if (typeof label !== 'undefined') {
    values.event_label = label;
  }

  if (typeof value !== 'undefined') {
    values.value = value;
  }

  if (typeof window !== 'undefined' && typeof (window as any).gtag !== 'undefined') {
    (window as any).gtag('event', action, values);
  }
};
