import { LOAN_STATUS_BACKGROUNDS } from 'modules/core/constants';
import { LoanStatusColors, TableView } from 'modules/core/enums';

import { TableColumn, TBackgroundProps } from './types';

export const getHiddenColumnsList = (columns: TableColumn[]): string[] =>
  columns.filter((column) => !column.isVisible).map((column) => `${column.id}`);

export const canRenderRow = (
  item: number,
  pageSize: number,
  currentPage: number,
  isServerPagination: boolean = false,
): boolean => {
  const startRow = pageSize * currentPage;
  const endRow = startRow + pageSize;

  if (isServerPagination) {
    return true;
  }

  return item >= startRow && item < endRow;
};

export const getBackgroundPropsByLoanStatus = (
  loanStatus: LoanStatusColors | undefined,
  backgroundColorPropsMap: Map<LoanStatusColors, TBackgroundProps>,
  tableView: TableView,
): TBackgroundProps => {
  const currentBackgroundColorProps =
    tableView === TableView.SALESPERSON
      ? backgroundColorPropsMap.get(LoanStatusColors.GREEN)
      : backgroundColorPropsMap.get(loanStatus || LoanStatusColors.GREEN);

  return currentBackgroundColorProps || LOAN_STATUS_BACKGROUNDS.RED;
};
