import styled from 'styled-components';
import { COLORS } from 'modules/core/theme/colors';

export const CheckBox = styled.div<{
  styles?: {
    padding: string;
    margin: string;
    maxWidth: string;
  };
  hasError: boolean;
}>`
  ${({ styles }) =>
    styles &&
    `
    padding: ${styles.padding};
    margin: ${styles.margin};
    max-width: ${styles.maxWidth};
  `}

  ${({ hasError }) =>
    hasError &&
    `
    .form-check-input {
      border-color: ${COLORS.RED_6};
    }
    .form-check-label {
      color: ${COLORS.RED_6};
    }
  `}

  .form-check-input:checked {
    background-color: ${COLORS.GREY_24};
    border-color: ${COLORS.GREY_24};
    color: black;
  }
  .form-check-input:active {
    outline: none;
  }
  .form-check-input:focus {
    border-color: ${COLORS.GREY_24};
    outline: none;
    box-shadow: none;
    border-color: 1px solid rgba(0, 0, 0, 0.25);
  }
`;

export const Input = styled.input`
  margin-right: 4px;
  margin-top: 2px;
  cursor: pointer;
`;

export const Label = styled.label`
  display: contents;
  cursor: pointer;
`;
