import styled from 'styled-components';
import { InputGroup } from 'react-bootstrap';
import { COLORS } from 'modules/core/theme/colors';

const Prepend = InputGroup.Text;

export type InputFontWeight = 'normal' | 'bold';

const ADORNMENT_WIDTH = '26px';

export const Input = styled(InputGroup)<{
  textstyle: InputFontWeight;
  isInvalid: boolean;
  endAdornment: boolean;
  startAdornment: boolean;
  isClickCallback?: boolean;
  readonly?: boolean;
}>`
  height: ${(props) => props.theme.variables.inputHeight};

  input {
    height: ${(props) => props.theme.variables.inputHeight};
    font-size: ${(props) => props.theme.fontSize.sm};
    text-align: ${(props) => props.textposition};
    color: ${(props) => props.theme.colors.black};
    font-weight: ${(props) => {
      const mapFontWeight = {
        normal: '',
        bold: props.theme.boldText.bold,
      };

      return mapFontWeight[props.textstyle];
    }};
    cursor: ${({ isClickCallback }) => isClickCallback && `pointer`};
    ${({ readonly }) =>
      readonly &&
      `background: ${COLORS.GREY_26}; &:focus {
      color: #212529;
      outline: 0;
      box-shadow: unset;
      background-color: ${COLORS.GREY_26};
      border: 1px solid ${COLORS.GREY_18};
    }`}
  }

  textarea {
    resize: none;
    height: 150px;
  }

  input,
  textarea {
    border: ${(props) => props.isInvalid && `1px solid ${COLORS.RED_3}`};
  }

  ${({ endAdornment, startAdornment }) => {
    const radiusStyle = '0.375rem !important;';
    let style = '';

    if (startAdornment) {
      style += `
        span:nth-child(1) {
          border-top-left-radius: ${radiusStyle}
          border-bottom-left-radius: ${radiusStyle}
        };
      `;
    }

    if (endAdornment) {
      style += `
        span:nth-child(${startAdornment ? '3' : '2'}) {
          border-top-right-radius: ${radiusStyle}
          border-bottom-right-radius: ${radiusStyle}
        };
      `;
    }

    style += `input { border-radius: ${!startAdornment && !endAdornment ? radiusStyle : 'unset !important;'} }`;

    if (startAdornment && !endAdornment) {
      style += `
        input {
          border-top-right-radius: ${radiusStyle}
          border-bottom-right-radius: ${radiusStyle}
        }
      `;
    }

    if (!startAdornment && endAdornment) {
      style += `
        input {
          border-top-left-radius: ${radiusStyle}
          border-bottom-left-radius: ${radiusStyle}
        }
      `;
    }

    return style;
  }}

  .is-invalid {
    background-image: none;
    padding-right: 12px;
  }

  .invalid-feedback {
    display: block !important;
    font-size: 12px;
    margin-top: 0;
    div {
      margin: 3px 0;
    }
  }
`;

export const Adornment = styled(Prepend)<{ isCursorPointer?: boolean }>`
  background: white;
  height: ${(props) => props.theme.variables.inputHeight};
  width: ${ADORNMENT_WIDTH};
  display: flex;
  justify-content: center;
  max-width: ${ADORNMENT_WIDTH};
  cursor: ${({ isCursorPointer }) => isCursorPointer && 'pointer'};
`;
