import styled, { keyframes } from 'styled-components';

const SPINNER_SIZE = '62px';

export const spinner = keyframes`
  0% {
    top: 6px;
    height: 51px;
  }

  50%,
  100% {
    top: 19px;
    height: 26px;
  }
`;

export const Container = styled.div<{ isWithMargins?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0px;
  ${({ isWithMargins }) =>
    isWithMargins &&
    `
    margin-top: 20vh;
    margin-bottom: 20vh;
  `}
`;

export const SpinnerWrapper = styled.div`
  display: inline-block;
  position: relative;
  width: ${SPINNER_SIZE};
  height: ${SPINNER_SIZE};
  vertical-align: middle;
`;

export const SpinnerItem = styled.div`
  display: inline-block;
  position: absolute;
  left: 6px;
  width: 13px;
  background: ${({ theme: { colors } }) => colors['green-4']};
  animation: ${spinner} 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;

  &:nth-child(1) {
    left: 6px;
    animation-delay: -0.24s;
  }

  &:nth-child(2) {
    left: 26px;
    animation-delay: -0.12s;
  }

  &:nth-child(3) {
    left: 45px;
    animation-delay: 0;
  }
`;
