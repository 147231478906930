export const NET_TRADE_IN_FORM_PREFIX = 'netTradeInForm';

export const NET_TRADE_IN_FIELDS_NAMES = {
  NET_TRADE_IN_TOTAL: `${NET_TRADE_IN_FORM_PREFIX}.netTradeInTotal`,
  NET_TRADE_IN_VALUE: `${NET_TRADE_IN_FORM_PREFIX}.tradeInValue`,
  AMOUNT_OWNED_ON_TRADE: `${NET_TRADE_IN_FORM_PREFIX}.amountOwnedOnTrade`,
  LIEN_HOLDER: `${NET_TRADE_IN_FORM_PREFIX}.lienHolder`,
  YEAR: `${NET_TRADE_IN_FORM_PREFIX}.year`,
  MAKE: `${NET_TRADE_IN_FORM_PREFIX}.make`,
  MODEL: `${NET_TRADE_IN_FORM_PREFIX}.model`,
  OTHER_YEAR: `${NET_TRADE_IN_FORM_PREFIX}.otherYear`,
  OTHER_MAKE: `${NET_TRADE_IN_FORM_PREFIX}.otherMake`,
  OTHER_MODEL: `${NET_TRADE_IN_FORM_PREFIX}.otherModel`,
};
