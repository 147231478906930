export const VENDORS = {
  JDP: 'JDP',
  KBB: 'KBB',
};

export const FEATURES_LIST_INITIAL_STATE = {
  rac: false,
  approved_loan_input: true,
  probability_estimator: true,
  credit_interview: false,
  is_cost_missing: false,
  is_email_sending_enabled: false,
  is_sms_sending_enabled: false,
  vehicle_details_from_jdp: false,
  vendor_mappings: {
    JDP: {
      vendor_trade: 'JDP Trade-in',
      vendor_retail: 'JDP Retail',
    },
    KBB: {
      kbb_lending_value: 'Blue Book® Lending Value',
      kbb_typical_listing: 'Blue Book® Typical Listing Price',
    },
  },
  vendor_name: VENDORS.JDP,
  can_fetch_new_vehicles: false,
};
