import { ILTVValues, IRACVehicle } from '../types';
import { STATIC_IMAGE_URLS } from './staticImagesUrls.const';

const stubbedLTVValue: ILTVValues = {
  rate: 10,
  category: '80-90%',
  payments: 500,
  profit: 100,
  extra_down_payment: 0,
  ltv: 90,
  max_price: 1000,
  discount_and_processing_fees: 0,
  discount: 0,
  f_and_i_flat: 0,
  vsc_sales: 0,
  additional_front_end_advance_at_list_price: 0,
  loan_amount: 10000,
};

export const stubbedRACVehicles: IRACVehicle[] = [
  {
    id: 1,
    make: 'Kia',
    model: 'Sorento',
    year: '2020',
    vin: 'JF2SKAKC6RH501605',
    photo: STATIC_IMAGE_URLS.cars.car1,
    current_price: 10000,
    unit_cost: 10000,
    guidebook_value: 10000,
    guidebook: 'Book Value',
    description: '2020 Kia Sorento',
    stock: 'SBX240438',
    drive_type: 'FWD',
    is_sold: false,
    age: 100,
    loan_term: 72,
    is_new: false,
    odometer: 10000,
    original_odometer: 10000,
    tier: 1,
    location: 'Boston',
    passenger_capacity: 5,
    loan_status: undefined,
    ltv_value: stubbedLTVValue,
    thumbnails: [],
    isStubbed: true,
    manually_created: false,
  },
  {
    id: 2,
    make: 'Kia',
    model: 'Sportage',
    year: '2020',
    vin: 'JF2SKAKC6RH501605',
    photo: STATIC_IMAGE_URLS.cars.car2,
    current_price: 10000,
    unit_cost: 10000,
    guidebook_value: 10000,
    guidebook: 'Book Value',
    description: '2020 Kia Sportage',
    stock: 'SBX240438',
    drive_type: 'FWD',
    is_sold: false,
    age: 100,
    loan_term: 72,
    is_new: false,
    odometer: 10000,
    original_odometer: 10000,
    tier: 1,
    location: 'Boston',
    passenger_capacity: 5,
    loan_status: undefined,
    ltv_value: stubbedLTVValue,
    thumbnails: [],
    isStubbed: true,
    manually_created: false,
  },
  {
    id: 3,
    make: 'Kia',
    model: 'Rio',
    year: '2020',
    vin: 'JF2SKAKC6RH501605',
    photo: STATIC_IMAGE_URLS.cars.car3,
    current_price: 10000,
    unit_cost: 10000,
    guidebook_value: 10000,
    guidebook: 'Book Value',
    description: '2020 Kia Rio RLX',
    stock: 'SBX240438',
    drive_type: 'FWD',
    is_sold: false,
    age: 100,
    loan_term: 72,
    is_new: false,
    odometer: 10000,
    original_odometer: 10000,
    tier: 1,
    location: 'Boston',
    passenger_capacity: 5,
    loan_status: undefined,
    ltv_value: stubbedLTVValue,
    thumbnails: [],
    isStubbed: true,
    manually_created: false,
  },
  {
    id: 4,
    make: 'Mini',
    model: 'Cooper',
    year: '2020',
    vin: 'JF2SKAKC6RH501605',
    photo: STATIC_IMAGE_URLS.cars.car4,
    current_price: 10000,
    unit_cost: 10000,
    guidebook_value: 10000,
    guidebook: 'Book Value',
    description: '2020 Mini Cooper',
    stock: 'SBX240438',
    drive_type: 'FWD',
    is_sold: false,
    age: 100,
    loan_term: 72,
    is_new: false,
    odometer: 10000,
    original_odometer: 10000,
    tier: 1,
    location: 'Boston',
    passenger_capacity: 5,
    loan_status: undefined,
    ltv_value: stubbedLTVValue,
    thumbnails: [],
    isStubbed: true,
    manually_created: false,
  },
  {
    id: 5,
    make: 'Hyundai',
    model: 'Tucson',
    year: '2020',
    vin: 'JF2SKAKC6RH501605',
    photo: STATIC_IMAGE_URLS.cars.car5,
    current_price: 10000,
    unit_cost: 10000,
    guidebook_value: 10000,
    guidebook: 'Book Value',
    description: '2020 Hyundai Tucson',
    stock: 'SBX240438',
    drive_type: 'FWD',
    is_sold: false,
    age: 100,
    loan_term: 72,
    is_new: false,
    odometer: 10000,
    original_odometer: 10000,
    tier: 1,
    location: 'Boston',
    passenger_capacity: 5,
    loan_status: undefined,
    ltv_value: stubbedLTVValue,
    thumbnails: [],
    isStubbed: true,
    manually_created: false,
  },
  {
    id: 6,
    make: 'Nissan',
    model: 'Qashqai',
    year: '2020',
    vin: 'JF2SKAKC6RH501605',
    photo: STATIC_IMAGE_URLS.cars.car6,
    current_price: 10000,
    unit_cost: 10000,
    guidebook_value: 10000,
    guidebook: 'Book Value',
    description: '2020 Nissan Qashqai',
    stock: 'SBX240438',
    drive_type: 'FWD',
    is_sold: false,
    age: 100,
    loan_term: 72,
    is_new: false,
    odometer: 10000,
    original_odometer: 10000,
    tier: 1,
    location: 'Boston',
    passenger_capacity: 5,
    loan_status: undefined,
    ltv_value: stubbedLTVValue,
    thumbnails: [],
    isStubbed: true,
    manually_created: false,
  },
];
