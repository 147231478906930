import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { STATIC_VIDEOS_URLS } from 'modules/core/constants';

import { LearningWrapper } from '../common/LearningWrapper/LearningWrapper';
import { CardForm, TitleH1, Video } from '../styles';
import { LEARNING_PATHS } from '../Learning.const';

export const LearningCreditHistory: React.FC = () => {
  const { t } = useTranslation();

  return (
    <LearningWrapper prevPageLink={LEARNING_PATHS.keyFactors} nextPageLink={LEARNING_PATHS.affordability}>
      <TitleH1>{t('components.learningLink.creditHistory.title')}</TitleH1>
      {t('components.learningLink.creditHistory.description')}
      <CardForm>
        <Video autoPlay muted playsInline>
          <source src={STATIC_VIDEOS_URLS.creditSlider} type="video/mp4" />
        </Video>
        <Trans defaults={t('components.learningLink.creditHistory.cardDescription1')} components={[<strong />]} />
        <p>{t('components.learningLink.creditHistory.cardDescription2')}</p>
        <p>{t('components.learningLink.creditHistory.cardDescription3')}</p>
      </CardForm>
    </LearningWrapper>
  );
};
