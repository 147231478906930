import { useState, useCallback } from 'react';
import { TableData } from 'modules/core/components/Table/types';
import { SortingRule } from 'react-table';

interface IUseTablePagination {
  pageCount: number;
  paginationCallback: (pageIndex: number, pageSize: number, sortBy: Array<SortingRule<object>>) => Promise<void>;
  pageSize: number;
}

export const useTablePagination = (
  data: TableData[],
  totalCount: number | null = null,
  fetchData: any,
  isServerPagination: boolean = false,
): IUseTablePagination => {
  const [pageCount, setPageCount] = useState(0);
  const [currentPageSize, setCurrentPageSize] = useState(0);

  const paginationCallback = useCallback(
    async (pageIndex: number, pageSize: number, sortBy: Array<SortingRule<object>>) => {
      const total: number = totalCount && isServerPagination ? totalCount : data.length;

      setPageCount(Math.ceil(total / pageSize));
      setCurrentPageSize(pageSize);

      if (fetchData && isServerPagination) {
        fetchData({ pageSize, pageIndex, sortBy });
      }
    },
    [data.length, setPageCount, totalCount, fetchData, isServerPagination],
  );

  return { pageCount, paginationCallback, pageSize: currentPageSize };
};
