import styled from 'styled-components';

export const OpenModalBlock = styled.div`
  height: 100%;
`;

export const TextBlock = styled.div<{ isPointer: boolean }>`
  height: 100%;
  cursor: ${({ isPointer }) => isPointer && 'pointer'};
`;
