import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { MODAL_DIALOG_WIDTH } from 'modules/core/constants';
import { ModalTheme } from 'modules/core/enums';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { IField, IOption } from 'modules/core/types';
import { FormRow } from 'modules/core/components';
import { getSubventionYearOptions } from 'store/selectors/controls.selector';
import { IVehicleModel } from 'modules/core/components/VehicleFilters/types';
import { getAddRouteOneVehicleFlags, getVehicleState } from 'store/selectors/vehicle.selector';
import { ModalDialog } from 'components/ModalDialog';
import { AppDispatch } from 'store/store';
import { createRouteOneVehicleThunk } from 'actions/vehicleActions';
import { resetAddRouteOneVehicleFlags, resetCreatedRouteOneVehicle } from 'store/reducers/vehicle.reducer';

import { ADD_VEHICLE_FORM_INITIAL_VALUES } from './consts/consts';
import { Panel } from './styles';
import { ADD_VEHICLE_FIELDS } from './consts/fieldConfig.const';
import { ADD_VEHICLE_FIELDS_NAMES } from './consts/fieldNames.const';

interface IAddVehicleDialog {
  show: boolean;
  tier: number;
  onHide: () => void;
  onHideAnotherModal?: () => void;
  backdrop?: boolean | 'static' | undefined;
  fromModal?: boolean;
}

export const AddVehicleDialog: FC<IAddVehicleDialog> = ({
  show,
  onHide,
  tier,
  backdrop = 'static',
  fromModal = false,
  onHideAnotherModal,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const formMethods = useForm({
    mode: 'onSubmit',
    shouldUnregister: false,
    defaultValues: { addVehicleForm: ADD_VEHICLE_FORM_INITIAL_VALUES },
  });
  const { getValues, reset, handleSubmit, setValue } = formMethods;

  const subventionYearOptions = useSelector(getSubventionYearOptions);
  const addRouteOneVehicleFlags = useSelector(getAddRouteOneVehicleFlags);
  const { vehicleMakes, vehicleModels } = useSelector(getVehicleState);
  const vehicleMakeOptions = useMemo(() => vehicleMakes.slice(3), [vehicleMakes]);

  const { vin, make, model, year, bookValue, mileage, retailPrice } = getValues().addVehicleForm;

  const getCurrentVehicleModelsFilterOptions = (
    make: string | undefined,
    vehicleModels: IVehicleModel[],
  ): IOption[] => {
    if (!make) {
      return vehicleModels.reduce((vehicleModel: IOption[], item) => {
        Object.values(item)[0].forEach((model: Record<string, any>) =>
          vehicleModel.push({ title: model.name, value: model.name }),
        );

        return vehicleModel;
      }, []);
    }

    return vehicleModels
      .filter((item) => make === Object.keys(item)[0])
      .reduce((vehicleModel: IOption[], item) => {
        Object.values(item)[0].forEach((model: Record<string, any>) =>
          vehicleModel.push({ title: model.name, value: model.name }),
        );

        return vehicleModel;
      }, []);
  };

  const currentModels = getCurrentVehicleModelsFilterOptions(make.title, vehicleModels);

  const mapOptionsByField = useMemo(
    () =>
      new Map([
        [ADD_VEHICLE_FIELDS_NAMES.MAKE, vehicleMakeOptions],
        [ADD_VEHICLE_FIELDS_NAMES.MODEL, currentModels],
        [ADD_VEHICLE_FIELDS_NAMES.YEAR, subventionYearOptions],
      ]),
    [vehicleMakeOptions, currentModels, subventionYearOptions],
  );

  const onSubmit = useCallback(() => {
    dispatch(resetCreatedRouteOneVehicle());

    const params = {
      vin: vin,
      make: make.value,
      model: model.value,
      year: year.value,
      book_value: bookValue,
      mileage: mileage,
      retail_price: retailPrice,
      tier: tier,
    };

    dispatch(createRouteOneVehicleThunk(params));
  }, [bookValue, dispatch, make.value, mileage, model.value, retailPrice, tier, vin, year.value]);

  const getField = useCallback(
    ({ name, component: Component, controlProps }: IField) => {
      if (
        name === ADD_VEHICLE_FIELDS_NAMES.MAKE ||
        name === ADD_VEHICLE_FIELDS_NAMES.MODEL ||
        name === ADD_VEHICLE_FIELDS_NAMES.YEAR
      ) {
        controlProps = { ...controlProps, options: mapOptionsByField.get(name) };
      }

      if (name === ADD_VEHICLE_FIELDS_NAMES.MODEL) {
        controlProps = { ...controlProps, disabled: !make.title };
      }

      return <Component name={name} {...controlProps} />;
    },
    [make.title, mapOptionsByField],
  );

  const renderFields = useCallback(
    (fields: IField[]) =>
      fields.map((field) => {
        const control = getField(field);

        return control ? (
          <>
            <FormRow
              key={field.name}
              label={field.label}
              disabled={Boolean(field.controlProps?.disabled)}
              required={Boolean(field.controlProps?.isRequired)}
              isVerticalAlign={field.isVerticalAlign}
              control={control}
            />
          </>
        ) : null;
      }),
    [getField],
  );

  const handleHide = useCallback(() => {
    onHide();
    reset({ addVehicleForm: ADD_VEHICLE_FORM_INITIAL_VALUES });
  }, [reset, onHide]);

  const onSubmitCallback = useCallback(() => {
    handleSubmit(onSubmit)();
  }, [handleSubmit, onSubmit]);

  useEffect(() => {
    if (make.value) {
      setValue(ADD_VEHICLE_FIELDS_NAMES.MODEL, {
        title: undefined,
        value: undefined,
      });
    }
  }, [make.value, setValue]);

  useEffect(() => {
    if (addRouteOneVehicleFlags.isLoaded) {
      handleHide();

      if (onHideAnotherModal) onHideAnotherModal();
      dispatch(resetAddRouteOneVehicleFlags());
      reset({ addVehicleForm: ADD_VEHICLE_FORM_INITIAL_VALUES });
    }
  }, [addRouteOneVehicleFlags.isLoaded, dispatch, fromModal, handleHide, onHideAnotherModal, reset]);

  return (
    <FormProvider {...formMethods}>
      <ModalDialog
        show={show}
        onHide={handleHide}
        onSubmitModal={onSubmitCallback}
        keyboard={false}
        backdrop={backdrop}
        title={t('addVehicleModal.title')}
        modalSize={MODAL_DIALOG_WIDTH.CUSTOM.PX_425}
        modalTheme={ModalTheme.DEFAULT}
        submitButtonTitle={t('common.buttons.save')}
        cancelButtonTitle={t('common.buttons.cancel')}
        isDisabled={addRouteOneVehicleFlags.isFetching}
        centered
      >
        <Panel>{renderFields(ADD_VEHICLE_FIELDS)}</Panel>
      </ModalDialog>
    </FormProvider>
  );
};
