/* eslint-disable @typescript-eslint/indent */
import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
// SWA-5628 Temporary hide: Lender Pricing/Market Pricing toggle from RAC page with Market Pricing state by default.
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Toggle } from 'modules/core/components';
import { Input, Multiselect } from 'modules/core/components';
import { useSelector } from 'react-redux';
import { getSubventionYearOptions } from 'store/selectors/controls.selector';
import { IField, IOption } from 'modules/core/types';
import { ColumnWidth, TableView } from 'modules/core/enums';
import { useFormContext } from 'react-hook-form';
import { getDealerStores } from 'store/selectors/dealer.selector';
import { getPassengerCapacities, getVehicleState } from 'store/selectors/vehicle.selector';
// SWA-5628 Temporary hide: Lender Pricing/Market Pricing toggle from RAC page with Market Pricing state by default.
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { PermissionWrapper } from 'modules/PermissionWrapper';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { PERMISSIONS } from 'modules/PermissionWrapper/permissions.const';
import { getFeaturesList } from 'store/selectors/ui.selector';

import { VEHICLE_FILTERS_FIELDS } from './fields.const';
// SWA-5628 Temporary hide: Lender Pricing/Market Pricing toggle from RAC page with Market Pricing state by default.
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ToggleContainer } from './styles';
import { AdditionalComponent, FilterLabel, FilterSection, FilterCol } from './styles';
import { FILTER_FIELDS_SUBSCRIPTION, FILTERS_FIELD_NAMES, TABLE_VIEW } from './vehicleFilters.const';
import { IVehicleFilterField } from './types';
import { getCurrentVehicleModelsFilterOptions } from '../../utils';
import { ALL_OPTION } from './options.const';
import { useDisableFilter } from './hooks/useDisableFilter';

interface IVehicleFilterSection {
  isRac?: boolean;
  isLender?: boolean;
}

export const VehicleFilterSection: FC<IVehicleFilterSection> = ({ isRac = false, isLender = false }) => {
  const { t } = useTranslation();
  const { watch, setValue } = useFormContext();
  const dealerStores = useSelector(getDealerStores);
  const { vehicleBodyTypes, vehicleMakes, vehicleModels } = useSelector(getVehicleState);
  const passengerCapacities = useSelector(getPassengerCapacities);
  const passengerCapacitiesOptions = [ALL_OPTION].concat(
    passengerCapacities.map((capacity) => ({
      title: String(capacity),
      value: String(capacity),
    })),
  );
  const featureList = useSelector(getFeaturesList);

  const subventionYearOptions = useSelector(getSubventionYearOptions);
  const { tableView } = watch();

  const {
    [FILTERS_FIELD_NAMES.MILES]: miles,
    [FILTERS_FIELD_NAMES.YEARS]: years,
    [FILTERS_FIELD_NAMES.MAKES]: makes,
  } = watch(FILTER_FIELDS_SUBSCRIPTION);

  const currentModels = getCurrentVehicleModelsFilterOptions(makes, vehicleModels);

  const mapOptionsByField = useMemo(
    () =>
      new Map([
        [FILTERS_FIELD_NAMES.STORES, dealerStores],
        [FILTERS_FIELD_NAMES.VEHICLES, vehicleBodyTypes],
        [FILTERS_FIELD_NAMES.MAKES, vehicleMakes],
        [FILTERS_FIELD_NAMES.MODELS, currentModels],
        [FILTERS_FIELD_NAMES.PASSENGER_CAPACITY, passengerCapacitiesOptions],
      ]),
    [dealerStores, vehicleBodyTypes, vehicleMakes, currentModels, passengerCapacitiesOptions],
  );

  const additionalComponentDependencyMap = useMemo(
    () =>
      new Map<string, boolean>([
        [FILTERS_FIELD_NAMES.YEARS_VALUE, true],
        [FILTERS_FIELD_NAMES.YEARS_VALUE, !!years],
        [FILTERS_FIELD_NAMES.ODOMETER_MILES, !!miles],
      ]),
    [years, miles],
  );

  const DISCOUNTING_LABELS_MAP = new Map<string, string>([
    [TableView.SALESPERSON, t('components.vehicleFilters.labels.marketPricing')],
    [TableView.MANAGER, t('components.vehicleFilters.labels.lenderPricing')],
  ]);

  // SWA-5628 Temporary hide: Lender Pricing/Market Pricing toggle from RAC page with Market Pricing state by default.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const discountingLabel = DISCOUNTING_LABELS_MAP.get(tableView) || t('components.vehicleFilters.labels.discounting');

  // SWA-5628 Temporary hide: Lender Pricing/Market Pricing toggle from RAC page with Market Pricing state by default.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onDiscountingChange = useCallback(
    (e) => {
      const tableViewValue = e.target.checked ? TABLE_VIEW.roles.manager : TABLE_VIEW.roles.salesperson;

      setValue(e.target.name, tableViewValue);
    },
    [setValue],
  );

  const renderAdditionalField = useCallback(
    ({ name, defaultValue, controlProps, component: Component }: IField) => {
      const shouldRender = additionalComponentDependencyMap.get(name) ?? true;

      return (
        <>
          {shouldRender && (
            <Component
              name={name}
              defaultValue={defaultValue as string}
              options={subventionYearOptions}
              {...controlProps}
            />
          )}
        </>
      );
    },
    [subventionYearOptions, additionalComponentDependencyMap],
  );

  const getFields = useCallback(
    (fieldData) =>
      fieldData.map(({ name, label, controlProps, component: Component, additionalComponent }: IVehicleFilterField) => (
        <FilterCol key={name} xs={ColumnWidth.FULL} sm={ColumnWidth.XS} lg={ColumnWidth.XXXS}>
          <FilterLabel>{label}</FilterLabel>
          {name === FILTERS_FIELD_NAMES.STORES ||
          name === FILTERS_FIELD_NAMES.VEHICLES ||
          name === FILTERS_FIELD_NAMES.MAKES ||
          name === FILTERS_FIELD_NAMES.MODELS ? (
            <Multiselect
              name={name}
              options={mapOptionsByField.get(name) || (controlProps?.options as IOption[]) || []}
              isDisabled={name === FILTERS_FIELD_NAMES.MODELS && !makes}
            />
          ) : (
            <Component
              name={name}
              options={mapOptionsByField.get(name) || (controlProps?.options as IOption[]) || []}
              disabled={(controlProps?.disabled as boolean) || false}
            />
          )}
          {additionalComponent && (
            <AdditionalComponent>{renderAdditionalField(additionalComponent)}</AdditionalComponent>
          )}
        </FilterCol>
      )),
    [makes, mapOptionsByField, renderAdditionalField],
  );

  const isVehicleTypeDisabled = !featureList.can_fetch_new_vehicles;

  const vehicleTypeField = VEHICLE_FILTERS_FIELDS.find((field) => field.name === FILTERS_FIELD_NAMES.VEHICLE_TYPE);

  if (vehicleTypeField) {
    vehicleTypeField.controlProps = {
      ...vehicleTypeField.controlProps,
      disabled: isVehicleTypeDisabled,
    };
  }

  const filtersWithDisabledExtraCash = useDisableFilter(
    VEHICLE_FILTERS_FIELDS,
    tableView === TABLE_VIEW.roles.manager,
    FILTERS_FIELD_NAMES.EXTRA_CASH,
  );

  const filtersFields = getFields(filtersWithDisabledExtraCash);

  return (
    <>
      <FilterSection>
        {filtersFields}
        <FilterCol xs={ColumnWidth.FULL} sm={ColumnWidth.XS} lg={ColumnWidth.XXXS}>
          <FilterLabel>{t('components.vehicleFilters.labels.minimumPayment')}</FilterLabel>
          <Input
            name={FILTERS_FIELD_NAMES.MINIMUM_MONTHLY_PAYMENT}
            placeholder={t('components.input.placeholders.integer')}
            decimalScale={0}
            hideInitZeroValue
          />
        </FilterCol>
        {/* SWA-5628 Temporary hide: Lender Pricing/Market Pricing toggle from RAC page with Market Pricing state by default. */}
        {/* SWA-6196 Temporary hide: Lender Pricing/Market Pricing toggle from Lender page with Market Pricing state by default. */}
        {!isRac && !isLender && (
          <PermissionWrapper allowedRoles={PERMISSIONS.DISCOUNTING_TOGGLE}>
            <FilterCol xs={ColumnWidth.FULL} sm={ColumnWidth.XS} lg={ColumnWidth.XXXS}>
              <FilterLabel>{discountingLabel}</FilterLabel>
              <ToggleContainer>
                <Toggle
                  icons={false}
                  name={TABLE_VIEW.name}
                  onToggleChange={onDiscountingChange}
                  checked={tableView === TABLE_VIEW.roles.manager}
                />
              </ToggleContainer>
            </FilterCol>
          </PermissionWrapper>
        )}
      </FilterSection>
    </>
  );
};
