import i18n from 'i18n';
import { ROUTER_PATH } from 'router/const/routerPath.const';
import { separatorFormatter } from 'modules/core/utils';

export const COMMON_POSTFIX = separatorFormatter(i18n.t('components.tabContent.streamline'));

export const LENDERS = 'lenders';

export const TAB_CONTENT_TITLES = {
  STREAMLINE: `${i18n.t('components.tabContent.streamline')}`,
  PROBABILITY_ESTIMATOR: `${i18n.t('components.tabContent.shopByBudget')} ${COMMON_POSTFIX}`,
  APPROVED_LOAN_INPUT: `${i18n.t('components.tabContent.lenderCallback')} ${COMMON_POSTFIX}`,
  CREDIT_INTERVIEW: `${i18n.t('components.tabContent.sendLink')} ${COMMON_POSTFIX}`,
  RAC_AFFORDABILITY_ENGINE: `${i18n.t('components.tabContent.RACAffordabilityEngine')} ${COMMON_POSTFIX}`,
};

export const TAB_CONTENT_MAP = new Map<string, string>([
  [ROUTER_PATH.PROBABILITY_ESTIMATOR, TAB_CONTENT_TITLES.PROBABILITY_ESTIMATOR],
  [ROUTER_PATH.APPROVED_LOAN_INPUT, TAB_CONTENT_TITLES.APPROVED_LOAN_INPUT],
  [ROUTER_PATH.RAC_AFFORDABILITY_ENGINE, TAB_CONTENT_TITLES.RAC_AFFORDABILITY_ENGINE],
  [ROUTER_PATH.CREDIT_INTERVIEW, TAB_CONTENT_TITLES.CREDIT_INTERVIEW],
]);
