import React, { useCallback, useEffect, useMemo } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Form, Row } from 'react-bootstrap';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Summary } from 'modules/ApprovedLoanInput/Summary';
import { IApprovedLoanInput } from 'modules/ApprovedLoanInput/types';
import {
  calcApprovedLoanValue,
  calcNetDownPayment,
  calcPriceAllowed,
  roundValue,
} from 'modules/ApprovedLoanInput/approvedLoadCalculation.service';
import { PageHeader, PanelWithFields } from 'modules/core/components';
import {
  useDebounceCallback,
  useDebounceVariable,
  useFeesAndTaxesInitialValues,
  useFormEnhanced,
  useSettingsInitialValues,
} from 'modules/core/hooks';
import {
  getApprovedLoanInputInitialValues,
  getApprovedLoanInputReportData,
  getVehiclesByParams,
  getVehiclesByStock,
  getVehicleFetchingFlags,
  getIsTableView,
  getNotAvailableVehicles,
  getNotFoundVehicles,
} from 'store/selectors/approvedLoanInput.selector';
import { FEES_AND_TAXES_FIELDS } from 'modules/core/shared/feesAndTaxes.config';
import { PREFERENCES_FIELDS } from 'modules/core/shared/preferencesConfig.const';
import {
  approvedLoanSlice,
  resetByStock,
  resetVehicles,
  resetVehiclesByParams,
  resetVehiclesByStock,
  setIsReset,
  setIsTableView,
} from 'store/reducers/approvedLoan.reducer';
import { VehicleSearchTable } from 'modules/VehicleSearchTable';
import { AppDispatch } from 'store/store';
import {
  getVehiclesByStockForApprovedLoanInputThunk,
  getVehiclesForApprovedLoanInputThunk,
} from 'actions/vehicleActions';
import { getSubmitVehicleObjectQuery, getTotalVehicles } from 'modules/core/utils';
import { IS_VALID_DELAY, REQUEST_DELAY } from 'modules/core/constants';
import {
  APPROVED_LOAN_INPUT_BY_STOCK_FORM_NAMES_MAPPER,
  APPROVED_LOAN_INPUT_FORM_NAMES_MAPPER,
} from 'modules/core/constants/mappers/submitVehicles.mapper';
import { ColumnWidth, TableName } from 'modules/core/enums';
import { useVendorFieldAndOptions } from 'hooks/useVendorFieldAndOptions';
import { vehicleApi } from 'api';
import { IVehicle } from 'modules/core/types';
import { useSwitchFilters } from 'modules/core/hooks/useSwitchFilters';

import {
  APPROVED_LOAN_NAMES_WATCH,
  FIELD_NAMES,
  VEHICLES_COUNT_FIELDS_SUBSCRIPTION,
  DEFAULT_PLACEHOLDER_TITLE,
} from './approvedLoanInput.const';
import { ApprovedLoanCol } from './styles';
import { ApprovedLoanInputService } from './approvedLoanInput.service';
import { FilterView } from '../core/enums/filterView.enum';
import { TitleContainer } from '../core/components/PageHeader/TitleContainer/TitleContainer';

export const ApprovedLoanInput: React.FC = () => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const { isReset, isFetchingVehiclesFailed } = useSelector(getVehicleFetchingFlags, shallowEqual);
  const isTableView = useSelector(getIsTableView);
  const notAvailableVehicles = useSelector(getNotAvailableVehicles);
  const notFoundVehicles = useSelector(getNotFoundVehicles);
  const vehiclesByParams = useSelector(getVehiclesByParams);
  const vehiclesByStock = useSelector(getVehiclesByStock);

  const { VENDOR_FILTER_OPTIONS } = useVendorFieldAndOptions();

  if (PREFERENCES_FIELDS[0].controlProps) {
    PREFERENCES_FIELDS[0].controlProps.options = VENDOR_FILTER_OPTIONS;
  }

  const { methods, syncFormAction } = useFormEnhanced<IApprovedLoanInput>(
    approvedLoanSlice.actions.syncInitialValues,
    getApprovedLoanInputInitialValues,
    {
      mode: 'onChange',
      shouldUnregister: false,
    },
  );

  const {
    handleSubmit,
    watch,
    setValue,
    formState: { isValid },
    getValues,
  } = methods;

  const { feesAndTaxes: feesAndTaxesValues, stocks, filterView, ...primitiveVehiclesCountWatchedValues } = watch(
    VEHICLES_COUNT_FIELDS_SUBSCRIPTION,
  );
  const { tableView, preferences, subvention, subventionYear } = watch();

  const {
    approvedInterestRate,
    approvedMonthlyPayment,
    approvedLoanTerm,
    approvedLTVRatio,
    cashDownPayment,
    tradeValue,
    amountOwed,
    feesAndTaxes,
  } = watch(APPROVED_LOAN_NAMES_WATCH) as IApprovedLoanInput;

  const commonDependencies = [
    approvedMonthlyPayment,
    approvedLoanTerm,
    approvedLTVRatio,
    approvedInterestRate,
    subvention,
    subventionYear,
    cashDownPayment,
    tradeValue,
    amountOwed,
    ...Object.values(preferences),
    ...Object.values(feesAndTaxes),
  ];

  const modalDependencies = [...Object.values(preferences), ...Object.values(feesAndTaxes)];

  const { currentFilterName, isValidStock, isRequestNeeded } = useSwitchFilters(
    commonDependencies,
    filterView,
    isFetchingVehiclesFailed,
    watch,
    setValue,
    modalDependencies,
  );

  useEffect(() => {
    if (isRequestNeeded) {
      if (filterView === FilterView.BY_PARAMS) dispatch(resetVehiclesByParams());
      else dispatch(resetVehiclesByStock());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, filterView]);

  const vehicles: IVehicle[] | undefined = filterView === FilterView.BY_PARAMS ? vehiclesByParams : vehiclesByStock;
  const totalVehicles = getTotalVehicles(filterView, isValidStock, vehicles);

  useEffect(() => {
    if (!isValidStock) dispatch(resetByStock());
  }, [dispatch, isValidStock]);

  useEffect(() => {
    if (!stocks.length && vehicles?.length) dispatch(resetByStock());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, vehicles?.length]);

  useSettingsInitialValues(setValue, getApprovedLoanInputInitialValues);
  useFeesAndTaxesInitialValues(setValue, getApprovedLoanInputInitialValues);

  const onSubmit = useCallback(() => {
    const values = getValues() as IApprovedLoanInput;
    const isFilterByParams = values.filterView === FilterView.BY_PARAMS;

    const CURRENT_APPROVED_LOAN_INPUT_FORM_NAMES_MAPPER = isFilterByParams
      ? APPROVED_LOAN_INPUT_FORM_NAMES_MAPPER
      : APPROVED_LOAN_INPUT_BY_STOCK_FORM_NAMES_MAPPER;

    if (typeof values.vehicleFilters.vehicleType === 'string') {
      values.condition = values.vehicleFilters.vehicleType;
    }

    // eslint-disable-next-line no-param-reassign
    if (!isFilterByParams) values.vehicleFilters.minMonthlyPayment = 0;

    const vehicleParameters = getSubmitVehicleObjectQuery<IApprovedLoanInput>(
      values,
      ApprovedLoanInputService.getSubmitQuery,
      CURRENT_APPROVED_LOAN_INPUT_FORM_NAMES_MAPPER,
    );

    syncFormAction();

    if (isFilterByParams) dispatch(getVehiclesForApprovedLoanInputThunk(vehicleParameters));
    else dispatch(getVehiclesByStockForApprovedLoanInputThunk(vehicleParameters));
  }, [dispatch, syncFormAction, getValues]);

  const onSubmitDebounced = useDebounceCallback((values: unknown) => {
    onSubmit();
  }, REQUEST_DELAY);

  const approvedLoanValue = useMemo(
    () =>
      calcApprovedLoanValue({
        approvedInterestRate,
        approvedMonthlyPayment,
        approvedLoanTerm,
      } as IApprovedLoanInput),
    [approvedInterestRate, approvedLoanTerm, approvedMonthlyPayment],
  );

  const priceAllowedValue = useMemo(
    () =>
      calcPriceAllowed(
        { cashDownPayment, amountOwed, tradeValue, feesAndTaxes } as IApprovedLoanInput,
        approvedLoanValue,
      ),
    [amountOwed, approvedLoanValue, cashDownPayment, feesAndTaxes, tradeValue],
  );

  const netDownPayment = useMemo(
    () => calcNetDownPayment({ cashDownPayment, tradeValue, amountOwed } as IApprovedLoanInput),
    [amountOwed, cashDownPayment, tradeValue],
  );

  // useDebounceVariable hook is used to fix isValid variable state behavior
  const debouncedIsValid = useDebounceVariable(isValid, IS_VALID_DELAY);

  const areRequiredFieldsFilledIn = Boolean(
    approvedMonthlyPayment &&
      approvedLoanTerm &&
      !Number.isNaN(approvedInterestRate) &&
      approvedLTVRatio &&
      !Number.isNaN(feesAndTaxes.salesTaxRate),
  );

  const previewPlaceholder = t('components.approvedLoanInput.previewPlaceholderPostfix', {
    totalVehicles,
    count: totalVehicles,
  });

  useEffect(() => {
    if (isReset && !areRequiredFieldsFilledIn) return;

    if (debouncedIsValid && areRequiredFieldsFilledIn && isRequestNeeded && isValidStock) {
      const values = getValues();

      vehicleApi.cancelGetVehiclesRequests();
      vehicleApi.cancelGetVehiclesByStockRequests();
      onSubmitDebounced(values);
      setValue(currentFilterName, false);
    }
  }, [
    currentFilterName,
    setValue,
    isRequestNeeded,
    isValidStock,
    isReset,
    debouncedIsValid,
    onSubmitDebounced,
    areRequiredFieldsFilledIn,
    getValues,
    tableView,
    stocks.length,
    filterView,
    // Inserting the commonVehiclesCountValues object initiates a comparison by reference and an additional hook launch.
    // This technique is used to avoid spreading the entire commonVehiclesCountValues object separately.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ...Object.values({ ...primitiveVehiclesCountWatchedValues, ...feesAndTaxesValues, ...preferences }),
  ]);

  useEffect(() => {
    setValue(FIELD_NAMES.APPROVED_LOAN_VALUE, roundValue(-approvedLoanValue));
  }, [approvedLoanValue, setValue]);

  useEffect(() => {
    setValue(FIELD_NAMES.PRICE_ALLOWED_VALUE, roundValue(priceAllowedValue));
  }, [priceAllowedValue, setValue]);

  useEffect(() => {
    setValue(FIELD_NAMES.NET_DOWN_PAYMENT, roundValue(netDownPayment));
  }, [netDownPayment, setValue]);

  useEffect(() => {
    if (areRequiredFieldsFilledIn) {
      dispatch(setIsReset(false));
    }
  }, [dispatch, areRequiredFieldsFilledIn]);

  // Reset vehicles if the user has deleted some of the required input values
  useEffect(() => {
    if (vehicles?.length && !debouncedIsValid) {
      dispatch(resetVehicles());
    }
  }, [dispatch, vehicles, debouncedIsValid]);

  const titleContainer = <TitleContainer title={t('components.approvedLoanInput.title')} />;

  return (
    <>
      <FormProvider {...methods}>
        <PageHeader title={titleContainer} />
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <ApprovedLoanCol lg={ColumnWidth.L} md={ColumnWidth.FULL}>
              <Summary />
            </ApprovedLoanCol>
            <ApprovedLoanCol lg={ColumnWidth.XXS} md={ColumnWidth.M} xs={ColumnWidth.FULL}>
              <PanelWithFields title={t('components.feesAndTaxes.title')} fieldsConfig={FEES_AND_TAXES_FIELDS} />
            </ApprovedLoanCol>
            <ApprovedLoanCol lg={ColumnWidth.XXXS} md={ColumnWidth.M} xs={ColumnWidth.FULL}>
              {PREFERENCES_FIELDS && (
                <PanelWithFields title={t('components.preferences.title')} fieldsConfig={PREFERENCES_FIELDS} />
              )}
            </ApprovedLoanCol>
          </Row>
          <Row>
            <ApprovedLoanCol xs={ColumnWidth.FULL}>
              <VehicleSearchTable
                tableName={TableName.ALI}
                data={vehicles || []}
                reportDataSelector={getApprovedLoanInputReportData}
                tableSubmitCallback={handleSubmit(onSubmit)}
                previewVehiclesCount={totalVehicles}
                defaultPlaceholderTitle={DEFAULT_PLACEHOLDER_TITLE}
                previewPlaceholder={previewPlaceholder}
                isTableActionsDisabled={!vehicles?.length}
                isTableView={isTableView}
                setIsTableView={setIsTableView}
                fetchingVehicleFlagsSelector={getVehicleFetchingFlags}
                areRequiredFieldsFilledIn={areRequiredFieldsFilledIn}
                notAvailableVehicles={notAvailableVehicles}
                notFoundVehicles={notFoundVehicles}
                isFilterByStockNumber
                isValidStock={isValidStock}
              />
            </ApprovedLoanCol>
          </Row>
        </Form>
      </FormProvider>
    </>
  );
};
