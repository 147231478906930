import styled from 'styled-components';
import { Card } from 'react-bootstrap';
import { COLORS } from 'modules/core/theme/colors';

export const CardForm = styled(Card)`
  background-color: ${COLORS.WHITE};
  margin: 0 0 20px 0;
  border: none;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  width: 345px;

  .is-invalid {
    z-index: 0 !important;
  }

  .card-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    background-color: ${COLORS.WHITE};
    font-weight: ${({ theme: { boldText } }) => boldText.dark};
    height: 54px;
    padding: 12px 15px;
  }

  .card-footer {
    display: flex;
    justify-content: flex-end;
    background-color: ${COLORS.WHITE};
    border: none;
    padding-bottom: 15px;
  }
`;

export const TotalIncomeTitleCard = styled(CardForm)`
  width: 100%;
`;
