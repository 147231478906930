import { useFormContext } from 'react-hook-form';

import { ControlField, RegisterField, SetFieldValue } from '../types/form';
import { getObjectProperty } from '../utils';

interface IUseUncontrolledInput {
  hasError: boolean;
  errorMessage: string | undefined;
  registerInput: RegisterField;
  setValue: SetFieldValue;
  controlField: ControlField;
  fieldValue: any | string | number | undefined;
}

export const useUncontrolledInput = (fieldName: string): IUseUncontrolledInput => {
  const {
    register,
    setValue,
    control,
    formState: { errors },
    watch,
  } = useFormContext();

  const objectProperty = getObjectProperty(errors, fieldName);

  return {
    controlField: control,
    setValue,
    hasError: Boolean(objectProperty),
    errorMessage: objectProperty?.message,
    registerInput: register,
    fieldValue: watch(fieldName),
  };
};
