import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IOption, VehicleBody } from 'modules/core/types';
import {
  getDefaultVehiclePhotoThunk,
  getVehicleAdjustRetailThunk,
  getVehicleBodiesThunk,
  getVehicleBodyTypesThunk,
  getVehicleMakesThunk,
  getVehicleAdjustmentLogThunk,
  getVehicleModelsThunk,
  createRouteOneVehicleThunk,
} from 'actions/vehicleActions';
import { IVehicleDetails } from 'components/Modals/VehicleDetailsDialog/types';
import { IVehicleAdjustRetailPayload, IVehicleLogPayload } from 'components/Modals/VehicleAdjustmentDialog/types';
import { IVehicleModel } from 'modules/core/components/VehicleFilters/types';
import { getPassengerCapacitiesThunk } from 'actions/passengerCapacitiesAction';
import { IAddRouteOneVehicleFlags } from 'modules/core/types/routeOne';
import { ICreateRouteOneVehicle } from 'components/Modals/AddVehicleDialog/types';
import { CREATE_ROUTE_ONE_VEHICLE_INITIAL_VALUES } from 'components/Modals/AddVehicleDialog/consts/consts';

export interface IVehicleReducerState {
  vehicleBodyTypes: IOption[];
  vehicleMakes: IOption[];
  vehicleModels: IVehicleModel[];
  bodies: VehicleBody[];
  passengerCapacities: number[];
  adjustmentId: number;
  vehicleAdjustRetail: IVehicleAdjustRetailPayload[];
  vehicleAdjustmentLogs: IVehicleLogPayload[];
  vehicleDetails?: IVehicleDetails;
  defaultVehiclePhoto: string;
  addRouteOneVehicleFlags: IAddRouteOneVehicleFlags;
  createdRouteOneVehicle: ICreateRouteOneVehicle;
}

const initialState: IVehicleReducerState = {
  vehicleBodyTypes: [],
  vehicleMakes: [],
  vehicleModels: [],
  bodies: [],
  passengerCapacities: [],
  adjustmentId: 0,
  vehicleAdjustRetail: [],
  vehicleAdjustmentLogs: [],
  defaultVehiclePhoto: '',
  addRouteOneVehicleFlags: {
    isInvalid: false,
    isFetching: false,
    isLoaded: false,
  },
  createdRouteOneVehicle: CREATE_ROUTE_ONE_VEHICLE_INITIAL_VALUES,
};

export const vehicleSlice = createSlice({
  name: 'vehicle',
  initialState,
  reducers: {
    setVehicleDetails: (state: IVehicleReducerState, { payload: vehicleDetails }: PayloadAction<IVehicleDetails>) => ({
      ...state,
      vehicleDetails,
    }),
    clearVehicleDetails: (state: IVehicleReducerState) => ({
      ...state,
      vehicleDetails: initialState.vehicleDetails,
    }),
    setAdjustmentId: (state: IVehicleReducerState, { payload: adjustmentId }: PayloadAction<number>) => ({
      ...state,
      adjustmentId,
    }),
    resetAddRouteOneVehicleFlags: (state: IVehicleReducerState) => ({
      ...state,
      addRouteOneVehicleFlags: {
        isInvalid: false,
        isFetching: false,
        isLoaded: false,
      },
    }),
    resetCreatedRouteOneVehicle: (state: IVehicleReducerState) => ({
      ...state,
      createdRouteOneVehicle: CREATE_ROUTE_ONE_VEHICLE_INITIAL_VALUES,
    }),
    clearVehicleAdjustmentData: (state: IVehicleReducerState) => ({
      ...state,
      adjustmentId: initialState.adjustmentId,
      vehicleAdjustRetail: initialState.vehicleAdjustRetail,
      vehicleAdjustmentLogs: initialState.vehicleAdjustmentLogs,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(
      getVehicleBodyTypesThunk.fulfilled,
      (state: IVehicleReducerState, { payload: vehicleBodyTypes }: PayloadAction<IOption[]>) => ({
        ...state,
        vehicleBodyTypes,
      }),
    );
    builder.addCase(
      getPassengerCapacitiesThunk.fulfilled,
      (state: IVehicleReducerState, { payload: passengerCapacities }: PayloadAction<number[]>) => ({
        ...state,
        passengerCapacities,
      }),
    );
    builder.addCase(
      getDefaultVehiclePhotoThunk.fulfilled,
      (state: IVehicleReducerState, { payload }: PayloadAction<string>) => ({
        ...state,
        defaultVehiclePhoto: payload,
      }),
    );
    builder.addCase(
      getVehicleMakesThunk.fulfilled,
      (state: IVehicleReducerState, { payload: vehicleMakes }: PayloadAction<IOption[]>) => ({
        ...state,
        vehicleMakes,
      }),
    );

    builder.addCase(
      getVehicleModelsThunk.fulfilled,
      (state: IVehicleReducerState, { payload: vehicleModels }: PayloadAction<IVehicleModel[]>) => ({
        ...state,
        vehicleModels,
      }),
    );

    builder.addCase(
      getVehicleBodiesThunk.fulfilled,
      (state: IVehicleReducerState, { payload: bodies }: PayloadAction<VehicleBody[]>) => ({
        ...state,
        bodies,
      }),
    );

    builder.addCase(
      getVehicleAdjustRetailThunk.fulfilled,
      (
        state: IVehicleReducerState,
        { payload: vehicleAdjustRetail }: PayloadAction<IVehicleAdjustRetailPayload[]>,
      ) => ({
        ...state,
        vehicleAdjustRetail,
      }),
    );

    builder.addCase(
      getVehicleAdjustmentLogThunk.fulfilled,
      (state: IVehicleReducerState, { payload: vehicleAdjustmentLogs }: PayloadAction<IVehicleLogPayload[]>) => ({
        ...state,
        vehicleAdjustmentLogs,
      }),
    );

    builder.addCase(
      createRouteOneVehicleThunk.fulfilled,
      (state: IVehicleReducerState, { payload }: PayloadAction<ICreateRouteOneVehicle>) => ({
        ...state,
        createdRouteOneVehicle: payload,
        addRouteOneVehicleFlags: {
          isInvalid: false,
          isFetching: false,
          isLoaded: true,
        },
      }),
    );
    builder.addCase(createRouteOneVehicleThunk.pending, (state: IVehicleReducerState) => ({
      ...state,
      addRouteOneVehicleFlags: {
        isInvalid: false,
        isFetching: true,
        isLoaded: false,
      },
    }));
    builder.addCase(createRouteOneVehicleThunk.rejected, (state: IVehicleReducerState) => ({
      ...state,
      addRouteOneVehicleFlags: {
        isInvalid: true,
        isFetching: false,
        isLoaded: true,
      },
    }));
  },
});

export const {
  setVehicleDetails,
  setAdjustmentId,
  clearVehicleAdjustmentData,
  clearVehicleDetails,
  resetAddRouteOneVehicleFlags,
  resetCreatedRouteOneVehicle,
} = vehicleSlice.actions;
