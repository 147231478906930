import { IControlNumbers } from 'modules/core/types/common';
import { sum, getDaysDiff, getEndOfPreviousYearDate, getFirstOfCurrentYearDate } from 'modules/core/utils';

import { IIncomeCardForm } from './types';

const MONTHS_PER_YEAR = 12;
const DAYS_PER_YEAR = 365;

export class IncomeCalculatorService {
  static calculateForGrossYTD(form: IIncomeCardForm): number {
    const getCorrectedValue = (value: any) => {
      if (typeof value === 'object') return value;

      return undefined;
    };

    const { grossYTDAmount, checkOrPayDate, dateOfHire, periodEndingDate } = form;

    const correctDateOfHire = getCorrectedValue(dateOfHire);
    const correctPeriodEndingDate = getCorrectedValue(periodEndingDate);
    const correctCheckOrPayDate = getCorrectedValue(checkOrPayDate);

    if (grossYTDAmount && (correctCheckOrPayDate || correctDateOfHire || correctPeriodEndingDate)) {
      const max1 = Math.max(correctPeriodEndingDate || 0, correctCheckOrPayDate || 0);
      const endOfPreviousYearData = getEndOfPreviousYearDate(new Date(max1));

      const max2 = Math.max(endOfPreviousYearData.getTime(), correctDateOfHire?.getTime() || 0);

      const daysDiff = getDaysDiff(new Date(max2), new Date(max1));

      const max3 = Math.max(daysDiff, 0);

      return max3 ? grossYTDAmount / ((max3 * MONTHS_PER_YEAR) / DAYS_PER_YEAR) : 0;
    }

    return 0;
  }

  static calculateForBasePay(form: IIncomeCardForm, weeklyToMonthly: number): number {
    const { hourlyRate, hoursPerWeek } = form;

    return hourlyRate * hoursPerWeek * weeklyToMonthly;
  }

  static calculateForEarlyYear(form: IIncomeCardForm): number {
    const { previousYearW2Amount, dateOfHire } = form;
    const firstOfCurrentYearDate = getFirstOfCurrentYearDate();
    const minTimePeriod = dateOfHire
      ? Math.min(getDaysDiff(dateOfHire, firstOfCurrentYearDate), DAYS_PER_YEAR)
      : DAYS_PER_YEAR;

    return minTimePeriod > 0 ? (previousYearW2Amount || 0) / (minTimePeriod * (MONTHS_PER_YEAR / DAYS_PER_YEAR)) : 0;
  }

  static calculateForNYSDisability(form: IIncomeCardForm, controlNumbers: IControlNumbers): number {
    const { grossYTDAmount, ytdNysDisabilityDeducted } = form;
    const { weeklyToMonthly, nysDisability } = controlNumbers;

    return ((grossYTDAmount * weeklyToMonthly) / ytdNysDisabilityDeducted) * nysDisability;
  }

  static calculateForAvgFromTaxReturns(form: IIncomeCardForm): number {
    const { line31ScheduleCYear1, line31ScheduleCYear2 } = form;

    return ((line31ScheduleCYear1 || 0) + (line31ScheduleCYear2 || 0)) / 2 / MONTHS_PER_YEAR;
  }

  static calculateForAvgFromDeposits(form: IIncomeCardForm): number {
    const values = [
      form.totalBankDeposits1,
      form.totalBankDeposits2,
      form.totalBankDeposits3,
      form.totalBankDeposits4,
      form.totalBankDeposits5,
      form.totalBankDeposits6,
    ];

    const totalBankDeposits = values.filter((value) => !!value);

    return sum(totalBankDeposits) / totalBankDeposits.length;
  }

  static calculateForSocialSecurity(form: IIncomeCardForm, socialSecurityGross: number): number {
    return form.amountReceived * socialSecurityGross;
  }
}
