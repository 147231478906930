import i18n from 'i18n';
import { IField } from 'modules/core/types/common';
import { requiredValidator, validateCreditScore, validateMinRequiredValue } from 'modules/core/validators';
import { PROBABILITY_ESTIMATOR_FIELDS } from 'modules/ProbabilityEstimator/probabilityEstimator.const';
import { CalculatorAdornment, Input } from 'modules/core/components';

import { LenderTierDropdown } from '../LenderTierDropdown/LenderTierDropdown';

const TIER_NAME = 'tier';

export const REHASH_CALCULATOR_FIELDS: IField[] = [
  {
    name: TIER_NAME,
    component: LenderTierDropdown,
    label: i18n.t('components.lender.tier'),
    isVerticalAlign: true,
    controlProps: {
      required: true,
      validate: validateCreditScore,
    },
  },
  {
    name: PROBABILITY_ESTIMATOR_FIELDS.GROSS_MONTHLY_INCOME,
    component: Input,
    label: i18n.t('components.consumerInformation.monthlyIncome'),
    isVerticalAlign: true,
    controlProps: {
      textStyle: 'bold',
      textPosition: 'start',
      endAdornment: <CalculatorAdornment />,
      decimalScale: 0,
      readonly: true,
      placeholder: i18n.t('components.input.placeholders.integer'),
      required: true,
      rules: requiredValidator,
      validate: validateMinRequiredValue,
    },
  },
  {
    name: PROBABILITY_ESTIMATOR_FIELDS.NET_DOWN_PAYMENT,
    component: Input,
    label: i18n.t('components.consumerInformation.totalCashAndTrade'),
    isVerticalAlign: true,
    controlProps: {
      textStyle: 'bold',
      textPosition: 'start',
      endAdornment: <CalculatorAdornment />,
      disabled: false,
      decimalScale: 0,
      allowNegative: true,
      readonly: true,
      hideInitZeroValue: true,
      placeholder: i18n.t('components.input.placeholders.integer'),
    },
  },
];
