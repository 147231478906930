import { useMemo } from 'react';

import { stubbedRACVehicles } from '../constants/stubbedVehicles';
import { IRACVehicle } from '../types';

export const useGenerateRandomVehicleList = (count: number) =>
  useMemo(() => {
    const randomList: IRACVehicle[] = [];

    Array.from({ length: count }).forEach(() => {
      const randomIndex = Math.floor(Math.random() * stubbedRACVehicles.length);

      randomList.push(stubbedRACVehicles[randomIndex]);
    });

    return randomList;
  }, [count]);
