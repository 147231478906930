import i18n from 'i18next';
import { IField } from 'modules/core/types';
import {
  requiredValidator,
  validateMinRequiredValueForDropdown,
  validateRequiredMinimumValue,
} from 'modules/core/validators';
import { Input } from 'modules/core/components';
import { MONEY_SYMBOL } from 'modules/core/constants';

import { ADD_VEHICLE_FIELDS_NAMES } from './fieldNames.const';
import { Dropdown } from '../../LoanVariablesDialog/components/common/Dropdown/Dropdown';

export const ADD_VEHICLE_FIELDS: IField[] = [
  {
    name: ADD_VEHICLE_FIELDS_NAMES.VIN,
    component: Input,
    label: i18n.t('addVehicleModal.labels.vin'),
    isVerticalAlign: true,
    controlProps: {
      textStyle: 'bold',
      textPosition: 'start',
      rules: requiredValidator,
      valueAsNumber: false,
      isRequired: true,
      maxLength: 24,
      isAllowTypeSpace: false,
    },
  },
  {
    name: ADD_VEHICLE_FIELDS_NAMES.MAKE,
    component: Dropdown,
    label: i18n.t('addVehicleModal.labels.make'),
    isVerticalAlign: true,
    controlProps: {
      options: [],
      isRequired: true,
      rules: requiredValidator,
      validate: validateMinRequiredValueForDropdown,
      placeholder: i18n.t('components.loanVariablesModal.modals.netTradeIn.selectPlaceholder'),
    },
  } as IField,
  {
    name: ADD_VEHICLE_FIELDS_NAMES.MODEL,
    component: Dropdown,
    label: i18n.t('addVehicleModal.labels.model'),
    isVerticalAlign: true,
    controlProps: {
      options: [],
      isRequired: true,
      rules: requiredValidator,
      validate: validateMinRequiredValueForDropdown,
      placeholder: i18n.t('components.loanVariablesModal.modals.netTradeIn.selectPlaceholder'),
    },
  } as IField,
  {
    name: ADD_VEHICLE_FIELDS_NAMES.YEAR,
    component: Dropdown,
    label: i18n.t('addVehicleModal.labels.year'),
    isVerticalAlign: true,
    controlProps: {
      options: [],
      isRequired: true,
      rules: requiredValidator,
      validate: validateMinRequiredValueForDropdown,
      placeholder: i18n.t('components.loanVariablesModal.modals.netTradeIn.selectPlaceholder'),
    },
  } as IField,
  {
    name: ADD_VEHICLE_FIELDS_NAMES.MILEAGE,
    component: Input,
    label: i18n.t('addVehicleModal.labels.mileage'),
    isVerticalAlign: true,
    controlProps: {
      textStyle: 'bold',
      textPosition: 'start',
      isRequired: true,
      decimalScale: 0,
      rules: requiredValidator,
      maxLength: 7,
      validate: validateRequiredMinimumValue,
      placeholder: i18n.t('components.input.placeholders.integer'),
      hideInitZeroValue: true,
    },
  },
  {
    name: ADD_VEHICLE_FIELDS_NAMES.RETAIL_PRICE,
    component: Input,
    label: i18n.t('addVehicleModal.labels.retailPrice'),
    isVerticalAlign: true,
    controlProps: {
      textStyle: 'bold',
      textPosition: 'start',
      isRequired: true,
      decimalScale: 0,
      rules: requiredValidator,
      maxLength: 11,
      validate: validateRequiredMinimumValue,
      startAdornment: <span>{MONEY_SYMBOL}</span>,
      placeholder: i18n.t('components.input.placeholders.integer'),
      hideInitZeroValue: true,
    },
  },
  {
    name: ADD_VEHICLE_FIELDS_NAMES.BOOK_VALUE,
    component: Input,
    label: i18n.t('addVehicleModal.labels.bookValue'),
    isVerticalAlign: true,
    controlProps: {
      textStyle: 'bold',
      textPosition: 'start',
      isRequired: true,
      decimalScale: 0,
      rules: requiredValidator,
      maxLength: 11,
      validate: validateRequiredMinimumValue,
      startAdornment: <span>{MONEY_SYMBOL}</span>,
      placeholder: i18n.t('components.input.placeholders.integer'),
      hideInitZeroValue: true,
    },
  },
];
