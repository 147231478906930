export const ICONS_HEIGHT = {
  DEFAULT: '27px',
  MIDDLE: '18px',
  SMALL: '15px',
  EXTRA_SMALL: '13px',
  XXS: '11px',
};

export const ICONS_WIDTH = {
  DEFAULT: '38px',
};
