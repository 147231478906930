import React from 'react';
import { Cell } from 'react-table';
import i18n from 'i18next';
import { ILenderVehicle, IRACVehicle, IVehiclePayload } from 'modules/core/types';
import {
  percentFormatter,
  prepareVehicleColumns,
  thousandFormatter,
  thousandFormatterOrHyphen,
  valueOrHyphen,
} from 'modules/core/utils';

import { ImageCell } from './ImageCell';
import { StockCell } from './StockCell';
import {
  DEFAULT_SALESPERSON_VISIBLE_VEHICLE_COLUMNS,
  DEFAULT_VISIBLE_VEHICLE_COLUMNS,
  EXTENDED_SALESPERSON_VISIBLE_VEHICLE_COLUMNS,
  LENDER_VEHICLE_TABLE_COLUMN_ID,
  RAC_VEHICLE_TABLE_COLUMN_ID,
  VEHICLE_TABLE_COLUMN_ID,
  WITHOUT_INVENTORY_TABLE_COLUMN_ID,
} from './defaultVehicleTableColumns';
import { ExtraCashCell } from './ExtraCashCell/ExtraCashCell';
import { VINCell } from './VINCell';
import { OpenLoanVariablesDialog } from './OpenLoanVariablesDialog/OpenLoanVariablesDialog';

const BASE_VEHICLE_TABLE_COLUMNS = [
  {
    Header: i18n.t('components.table.columns.photos'),
    accessor: VEHICLE_TABLE_COLUMN_ID.PHOTO,
    id: VEHICLE_TABLE_COLUMN_ID.PHOTO,
    disableSortBy: true,
    Cell: (cell: Cell<IVehiclePayload>): JSX.Element => <ImageCell data={cell.row.original} />,
  },
  {
    Header: i18n.t('components.table.columns.vehicle'),
    accessor: VEHICLE_TABLE_COLUMN_ID.VEHICLE,
    id: VEHICLE_TABLE_COLUMN_ID.VEHICLE,
  },
  {
    Header: i18n.t('components.table.columns.stock'),
    accessor: VEHICLE_TABLE_COLUMN_ID.STOCK,
    id: VEHICLE_TABLE_COLUMN_ID.STOCK,
    Cell: (cell: Cell<IVehiclePayload>): JSX.Element => (
      <StockCell
        stock={cell.row.original.stock}
        isSold={cell.row.original.is_sold}
        age={cell.row.original.age}
        isNew={cell.row.original.is_new}
      />
    ),
  },
  {
    Header: i18n.t('components.table.columns.vin'),
    accessor: VEHICLE_TABLE_COLUMN_ID.VIN,
    id: VEHICLE_TABLE_COLUMN_ID.VIN,
    Cell: (cell: Cell<IVehiclePayload>): JSX.Element => <VINCell vin={cell.row.original.vin} />,
  },
  {
    Header: i18n.t('components.table.columns.miles'),
    accessor: VEHICLE_TABLE_COLUMN_ID.MILES,
    id: VEHICLE_TABLE_COLUMN_ID.MILES,
    Cell: (cell: Cell<IVehiclePayload>): string => thousandFormatter(cell.row.original.odometer),
  },
  {
    Header: i18n.t('components.table.columns.location'),
    accessor: VEHICLE_TABLE_COLUMN_ID.LOCATION,
    id: VEHICLE_TABLE_COLUMN_ID.LOCATION,
  },
];

const COMMON_VEHICLE_TABLE_COLUMNS = [
  ...BASE_VEHICLE_TABLE_COLUMNS,
  {
    Header: i18n.t('components.table.columns.maxPrice'),
    accessor: VEHICLE_TABLE_COLUMN_ID.MAX_PRICE,
    id: VEHICLE_TABLE_COLUMN_ID.MAX_PRICE,
    Cell: (cell: Cell<IVehiclePayload>): string => thousandFormatter(cell.row.original.max_price),
  },
  {
    Header: i18n.t('components.table.columns.retailPrice'),
    accessor: VEHICLE_TABLE_COLUMN_ID.RETAIL_PRICE,
    id: VEHICLE_TABLE_COLUMN_ID.RETAIL_PRICE,
    Cell: (cell: Cell<IVehiclePayload>): string => thousandFormatter(cell.row.original.current_price),
  },
  {
    Header: i18n.t('components.table.columns.cost'),
    accessor: VEHICLE_TABLE_COLUMN_ID.UNIT_COST,
    id: VEHICLE_TABLE_COLUMN_ID.UNIT_COST,
    Cell: (cell: Cell<IVehiclePayload>): string => thousandFormatter(cell.row.original.unit_cost),
  },
  {
    Header: i18n.t('components.table.columns.estimatedProfit'),
    accessor: VEHICLE_TABLE_COLUMN_ID.DEALER_PROFIT,
    id: VEHICLE_TABLE_COLUMN_ID.DEALER_PROFIT,
    sortType: 'basic',
    Cell: (cell: Cell<IVehiclePayload>): string => thousandFormatter(cell.row.original.dealer_profit),
  },
  {
    Header: i18n.t('components.table.columns.discount'),
    accessor: VEHICLE_TABLE_COLUMN_ID.DISCOUNT,
    id: VEHICLE_TABLE_COLUMN_ID.DISCOUNT,
    sortType: 'basic',
    Cell: (cell: Cell<IVehiclePayload>): string => thousandFormatter(cell.row.original.discount),
  },
  {
    Header: i18n.t('components.table.columns.bookValue'),
    accessor: VEHICLE_TABLE_COLUMN_ID.BOOK_VALUE,
    id: VEHICLE_TABLE_COLUMN_ID.BOOK_VALUE,
    Cell: (cell: Cell<IVehiclePayload>): string => thousandFormatter(cell.row.original.guidebook_value),
  },
  {
    Header: i18n.t('components.table.columns.valuationGuide'),
    accessor: VEHICLE_TABLE_COLUMN_ID.VALUATION_GUIDE,
    id: VEHICLE_TABLE_COLUMN_ID.VALUATION_GUIDE,
  },
  {
    Header: i18n.t('components.table.columns.estimatedPayment'),
    accessor: VEHICLE_TABLE_COLUMN_ID.PAYMENT,
    id: VEHICLE_TABLE_COLUMN_ID.PAYMENT,
    Cell: (cell: Cell<IVehiclePayload>): string => thousandFormatter(cell.row.original.monthly_payment),
  },
  {
    Header: i18n.t('components.table.columns.recommendedTerm'),
    accessor: VEHICLE_TABLE_COLUMN_ID.LOAN_TERM,
    id: VEHICLE_TABLE_COLUMN_ID.LOAN_TERM,
  },
  {
    Header: i18n.t('components.table.columns.ltv'),
    accessor: VEHICLE_TABLE_COLUMN_ID.LTV_RATIO,
    id: VEHICLE_TABLE_COLUMN_ID.LTV_RATIO,
    Cell: (cell: Cell<IVehiclePayload>): string => percentFormatter(cell.row.original.ltv_ratio),
  },
  {
    Header: i18n.t('components.table.columns.extraCash'),
    accessor: VEHICLE_TABLE_COLUMN_ID.ADDITIONAL_DOWN_PAYMENT,
    id: VEHICLE_TABLE_COLUMN_ID.ADDITIONAL_DOWN_PAYMENT,
    Cell: (cell: Cell<IVehiclePayload>): JSX.Element => (
      <ExtraCashCell extraCashValue={cell.row.original.additional_down_payment} />
    ),
  },
  {
    Header: i18n.t('components.table.columns.estimatedAmtFinanced'),
    accessor: VEHICLE_TABLE_COLUMN_ID.LOAN_AMOUNT,
    id: VEHICLE_TABLE_COLUMN_ID.LOAN_AMOUNT,
    Cell: (cell: Cell<IVehiclePayload>): string => thousandFormatter(cell.row.original.loan_amount),
  },
  {
    Header: i18n.t('components.table.columns.inventoryAge'),
    accessor: VEHICLE_TABLE_COLUMN_ID.AGE,
    id: VEHICLE_TABLE_COLUMN_ID.AGE,
  },
  {
    Header: i18n.t('components.table.columns.driveType'),
    accessor: VEHICLE_TABLE_COLUMN_ID.DRIVE_TYPE,
    id: VEHICLE_TABLE_COLUMN_ID.DRIVE_TYPE,
  },
  {
    Header: i18n.t('components.table.columns.passengerCapacity'),
    accessor: VEHICLE_TABLE_COLUMN_ID.PASSENGER_CAPACITY,
    id: VEHICLE_TABLE_COLUMN_ID.PASSENGER_CAPACITY,
  },
];

const SALESPERSON_VEHICLE_TABLE_COLUMNS = [
  ...BASE_VEHICLE_TABLE_COLUMNS,
  {
    Header: i18n.t('components.table.columns.retailPrice'),
    accessor: VEHICLE_TABLE_COLUMN_ID.RETAIL_PRICE,
    id: VEHICLE_TABLE_COLUMN_ID.RETAIL_PRICE,
    Cell: (cell: Cell<IVehiclePayload>): string => thousandFormatter(cell.row.original.current_price),
  },
  {
    Header: i18n.t('components.table.columns.extraCash'),
    accessor: VEHICLE_TABLE_COLUMN_ID.ADDITIONAL_DOWN_PAYMENT,
    id: VEHICLE_TABLE_COLUMN_ID.ADDITIONAL_DOWN_PAYMENT,
    Cell: (cell: Cell<IVehiclePayload>): JSX.Element => (
      <ExtraCashCell extraCashValue={cell.row.original.additional_down_payment} />
    ),
  },
  {
    Header: i18n.t('components.table.columns.driveType'),
    accessor: VEHICLE_TABLE_COLUMN_ID.DRIVE_TYPE,
    id: VEHICLE_TABLE_COLUMN_ID.DRIVE_TYPE,
  },
  {
    Header: i18n.t('components.table.columns.passengerCapacity'),
    accessor: VEHICLE_TABLE_COLUMN_ID.PASSENGER_CAPACITY,
    id: VEHICLE_TABLE_COLUMN_ID.PASSENGER_CAPACITY,
  },
];

const VEHICLE_TABLE_COLUMNS = COMMON_VEHICLE_TABLE_COLUMNS;

const EXCLUDED_SALESPERSON_VIEW_VEHICLE_TABLE_COLUMNS_IDS = [VEHICLE_TABLE_COLUMN_ID.DISCOUNT];

const EXTENDED_SALESPERSON_VEHICLE_TABLE_COLUMNS = [
  ...COMMON_VEHICLE_TABLE_COLUMNS.filter(
    (col) => EXCLUDED_SALESPERSON_VIEW_VEHICLE_TABLE_COLUMNS_IDS.indexOf(col.id) === -1,
  ),
];

export const DEFAULT_MANAGER_VIEW_VEHICLE_COLUMNS = prepareVehicleColumns(
  VEHICLE_TABLE_COLUMNS,
  DEFAULT_VISIBLE_VEHICLE_COLUMNS,
);

export const DEFAULT_EXECUTIVE_VIEW_VEHICLE_COLUMNS = prepareVehicleColumns(
  VEHICLE_TABLE_COLUMNS,
  DEFAULT_VISIBLE_VEHICLE_COLUMNS,
);

export const DEFAULT_SALESPERSON_VIEW_VEHICLE_COLUMNS = prepareVehicleColumns(
  SALESPERSON_VEHICLE_TABLE_COLUMNS,
  DEFAULT_SALESPERSON_VISIBLE_VEHICLE_COLUMNS,
);

export const EXTENDED_SALESPERSON_VIEW_VEHICLE_COLUMNS = prepareVehicleColumns(
  EXTENDED_SALESPERSON_VEHICLE_TABLE_COLUMNS,
  EXTENDED_SALESPERSON_VISIBLE_VEHICLE_COLUMNS,
);

const BASE_RAC_VEHICLE_TABLE_COLUMNS = [
  {
    Header: i18n.t('components.table.columns.photos'),
    accessor: RAC_VEHICLE_TABLE_COLUMN_ID.PHOTO,
    id: RAC_VEHICLE_TABLE_COLUMN_ID.PHOTO,
    disableSortBy: true,
    isVisible: true,
    Cell: (cell: Cell<IRACVehicle>): JSX.Element => <ImageCell data={cell.row.original} noColoring />,
  },
  {
    Header: i18n.t('components.table.columns.vehicle'),
    accessor: RAC_VEHICLE_TABLE_COLUMN_ID.VEHICLE,
    id: RAC_VEHICLE_TABLE_COLUMN_ID.VEHICLE,
    isVisible: true,
    Cell: (cell: Cell<IRACVehicle>): JSX.Element => (
      <OpenLoanVariablesDialog value={cell.row.original.description} currentVehicleData={cell.row.original} />
    ),
  },
  {
    Header: i18n.t('components.table.columns.stock'),
    accessor: RAC_VEHICLE_TABLE_COLUMN_ID.STOCK,
    id: RAC_VEHICLE_TABLE_COLUMN_ID.STOCK,
    isVisible: true,
    Cell: (cell: Cell<IRACVehicle>): JSX.Element => (
      <OpenLoanVariablesDialog
        currentVehicleData={cell.row.original}
        cell={
          <StockCell stock={cell.row.original.stock} isSold={cell.row.original.is_sold} age={cell.row.original.age} />
        }
      />
    ),
  },
  {
    Header: i18n.t('components.table.columns.vin'),
    accessor: VEHICLE_TABLE_COLUMN_ID.VIN,
    id: VEHICLE_TABLE_COLUMN_ID.VIN,
    Cell: (cell: Cell<IVehiclePayload>): JSX.Element => <VINCell vin={cell.row.original.vin} />,
  },
];

const BASE_LENDER_VEHICLE_TABLE_COLUMNS = [
  {
    Header: i18n.t('components.table.columns.photos'),
    accessor: RAC_VEHICLE_TABLE_COLUMN_ID.PHOTO,
    id: RAC_VEHICLE_TABLE_COLUMN_ID.PHOTO,
    disableSortBy: true,
    isVisible: true,
    Cell: (cell: Cell<IVehiclePayload>): JSX.Element => <ImageCell data={cell.row.original} noColoring />,
  },
  {
    Header: i18n.t('components.table.columns.vehicle'),
    accessor: RAC_VEHICLE_TABLE_COLUMN_ID.VEHICLE,
    id: RAC_VEHICLE_TABLE_COLUMN_ID.VEHICLE,
    isVisible: true,
    Cell: (cell: Cell<ILenderVehicle>): JSX.Element => (
      <OpenLoanVariablesDialog value={cell.row.original.description} currentVehicleData={cell.row.original} />
    ),
  },
  {
    Header: i18n.t('components.table.columns.stock'),
    accessor: RAC_VEHICLE_TABLE_COLUMN_ID.STOCK,
    id: RAC_VEHICLE_TABLE_COLUMN_ID.STOCK,
    isVisible: true,
    Cell: (cell: Cell<ILenderVehicle>): JSX.Element => (
      <OpenLoanVariablesDialog
        currentVehicleData={cell.row.original}
        cell={
          <StockCell stock={cell.row.original.stock} isSold={cell.row.original.is_sold} age={cell.row.original.age} />
        }
      />
    ),
  },
  {
    Header: i18n.t('components.table.columns.vin'),
    accessor: VEHICLE_TABLE_COLUMN_ID.VIN,
    id: VEHICLE_TABLE_COLUMN_ID.VIN,
    Cell: (cell: Cell<IVehiclePayload>): JSX.Element => <VINCell vin={cell.row.original.vin} />,
  },
];

export const MANAGER_RAC_VEHICLE_TABLE_COLUMNS = [
  ...BASE_RAC_VEHICLE_TABLE_COLUMNS,
  {
    Header: i18n.t('components.table.columns.inventoryAge'),
    accessor: RAC_VEHICLE_TABLE_COLUMN_ID.AGE,
    id: RAC_VEHICLE_TABLE_COLUMN_ID.AGE,
    isVisible: true,
    Cell: (cell: Cell<IRACVehicle>): JSX.Element => (
      <OpenLoanVariablesDialog value={valueOrHyphen(cell.row.original.age)} currentVehicleData={cell.row.original} />
    ),
  },
  {
    Header: i18n.t('components.table.columns.miles'),
    accessor: RAC_VEHICLE_TABLE_COLUMN_ID.MILES,
    id: RAC_VEHICLE_TABLE_COLUMN_ID.MILES,
    isVisible: true,
    Cell: (cell: Cell<IRACVehicle>): JSX.Element => (
      <OpenLoanVariablesDialog
        value={thousandFormatter(cell.row.original.odometer)}
        currentVehicleData={cell.row.original}
      />
    ),
  },
  {
    Header: i18n.t('components.table.columns.location'),
    accessor: RAC_VEHICLE_TABLE_COLUMN_ID.LOCATION,
    id: RAC_VEHICLE_TABLE_COLUMN_ID.LOCATION,
    isVisible: true,
    Cell: (cell: Cell<IRACVehicle>): JSX.Element => (
      <OpenLoanVariablesDialog value={cell.row.original.location} currentVehicleData={cell.row.original} />
    ),
  },
  {
    Header: i18n.t('components.table.columns.maxPrice'),
    accessor: RAC_VEHICLE_TABLE_COLUMN_ID.MAX_PRICE,
    id: RAC_VEHICLE_TABLE_COLUMN_ID.MAX_PRICE,
    isVisible: true,
    Cell: (cell: Cell<IRACVehicle>): JSX.Element => (
      <OpenLoanVariablesDialog
        value={thousandFormatter(cell.row.original.ltv_value.max_price)}
        currentVehicleData={cell.row.original}
      />
    ),
  },
  {
    Header: i18n.t('components.table.columns.retailPrice'),
    accessor: RAC_VEHICLE_TABLE_COLUMN_ID.RETAIL_PRICE,
    id: RAC_VEHICLE_TABLE_COLUMN_ID.RETAIL_PRICE,
    isVisible: true,
    Cell: (cell: Cell<IRACVehicle>): JSX.Element => (
      <OpenLoanVariablesDialog
        value={thousandFormatter(cell.row.original.current_price)}
        currentVehicleData={cell.row.original}
      />
    ),
  },
  {
    Header: i18n.t('components.table.columns.bookValue'),
    accessor: RAC_VEHICLE_TABLE_COLUMN_ID.BOOK_VALUE,
    id: RAC_VEHICLE_TABLE_COLUMN_ID.BOOK_VALUE,
    isVisible: true,
    Cell: (cell: Cell<IRACVehicle>): JSX.Element => (
      <OpenLoanVariablesDialog
        value={thousandFormatter(cell.row.original.guidebook_value)}
        currentVehicleData={cell.row.original}
      />
    ),
  },
  {
    Header: i18n.t('components.table.columns.valuationGuide'),
    accessor: VEHICLE_TABLE_COLUMN_ID.VALUATION_GUIDE,
    id: VEHICLE_TABLE_COLUMN_ID.VALUATION_GUIDE,
    isVisible: true,
    Cell: (cell: Cell<IRACVehicle>): JSX.Element => (
      <OpenLoanVariablesDialog value={String(cell.row.original.guidebook)} currentVehicleData={cell.row.original} />
    ),
  },
  {
    Header: i18n.t('components.table.columns.cost'),
    accessor: RAC_VEHICLE_TABLE_COLUMN_ID.UNIT_COST,
    id: RAC_VEHICLE_TABLE_COLUMN_ID.UNIT_COST,
    isVisible: true,
    Cell: (cell: Cell<IRACVehicle>): JSX.Element => (
      <OpenLoanVariablesDialog
        value={thousandFormatterOrHyphen(cell.row.original.unit_cost)}
        currentVehicleData={cell.row.original}
      />
    ),
  },
  {
    Header: i18n.t('components.table.columns.discount'),
    accessor: RAC_VEHICLE_TABLE_COLUMN_ID.DISCOUNT,
    id: RAC_VEHICLE_TABLE_COLUMN_ID.DISCOUNT,
    sortType: 'basic',
    Cell: (cell: Cell<IRACVehicle>): JSX.Element => (
      <OpenLoanVariablesDialog
        value={thousandFormatter(cell.row.original.ltv_value.discount)}
        currentVehicleData={cell.row.original}
      />
    ),
  },
  {
    Header: i18n.t('components.table.columns.fAndIFlat'),
    accessor: RAC_VEHICLE_TABLE_COLUMN_ID.F_AND_I_FLAT,
    id: RAC_VEHICLE_TABLE_COLUMN_ID.F_AND_I_FLAT,
    isVisible: true,
    sortType: 'basic',
    Cell: (cell: Cell<IRACVehicle>): JSX.Element => (
      <OpenLoanVariablesDialog
        value={thousandFormatter(cell.row.original.ltv_value.f_and_i_flat)}
        currentVehicleData={cell.row.original}
      />
    ),
  },
  {
    Header: i18n.t('components.table.columns.payment'),
    accessor: RAC_VEHICLE_TABLE_COLUMN_ID.PAYMENT,
    id: RAC_VEHICLE_TABLE_COLUMN_ID.PAYMENT,
    isVisible: true,
    Cell: (cell: Cell<IRACVehicle>): JSX.Element => (
      <OpenLoanVariablesDialog
        value={thousandFormatter(cell.row.original.ltv_value.payments)}
        currentVehicleData={cell.row.original}
      />
    ),
  },
  {
    Header: i18n.t('components.table.columns.estimatedAmtFinanced'),
    accessor: RAC_VEHICLE_TABLE_COLUMN_ID.LOAN_AMOUNT,
    id: RAC_VEHICLE_TABLE_COLUMN_ID.LOAN_AMOUNT,
    isVisible: true,
    Cell: (cell: Cell<IRACVehicle>): JSX.Element => (
      <OpenLoanVariablesDialog
        value={thousandFormatter(cell.row.original.ltv_value.loan_amount)}
        currentVehicleData={cell.row.original}
      />
    ),
  },
  {
    Header: i18n.t('components.table.columns.term'),
    accessor: RAC_VEHICLE_TABLE_COLUMN_ID.TERM,
    id: RAC_VEHICLE_TABLE_COLUMN_ID.TERM,
    isVisible: true,
    Cell: (cell: Cell<IRACVehicle>): JSX.Element => (
      <OpenLoanVariablesDialog
        value={thousandFormatter(cell.row.original.loan_term)}
        currentVehicleData={cell.row.original}
      />
    ),
  },
  {
    Header: i18n.t('components.table.columns.profit'),
    accessor: RAC_VEHICLE_TABLE_COLUMN_ID.PROFIT,
    id: RAC_VEHICLE_TABLE_COLUMN_ID.PROFIT,
    sortType: 'basic',
    isVisible: true,
    Cell: (cell: Cell<IRACVehicle>): JSX.Element => (
      <OpenLoanVariablesDialog
        value={thousandFormatterOrHyphen(cell.row.original.ltv_value.profit)}
        currentVehicleData={cell.row.original}
      />
    ),
  },
  {
    Header: i18n.t('components.table.columns.ltv'),
    accessor: RAC_VEHICLE_TABLE_COLUMN_ID.LTV,
    id: RAC_VEHICLE_TABLE_COLUMN_ID.LTV,
    isVisible: true,
    Cell: (cell: Cell<IRACVehicle>): JSX.Element => (
      <OpenLoanVariablesDialog
        value={percentFormatter(cell.row.original.ltv_value.ltv)}
        currentVehicleData={cell.row.original}
      />
    ),
  },
  {
    Header: i18n.t('components.table.columns.driveType'),
    accessor: VEHICLE_TABLE_COLUMN_ID.DRIVE_TYPE,
    id: VEHICLE_TABLE_COLUMN_ID.DRIVE_TYPE,
    Cell: (cell: Cell<IRACVehicle>): JSX.Element => (
      <OpenLoanVariablesDialog value={cell.row.original.drive_type} currentVehicleData={cell.row.original} />
    ),
  },
  {
    Header: i18n.t('components.table.columns.passengerCapacity'),
    accessor: VEHICLE_TABLE_COLUMN_ID.PASSENGER_CAPACITY,
    id: VEHICLE_TABLE_COLUMN_ID.PASSENGER_CAPACITY,
    Cell: (cell: Cell<IRACVehicle>): JSX.Element => (
      <OpenLoanVariablesDialog
        value={valueOrHyphen(cell.row.original.passenger_capacity)}
        currentVehicleData={cell.row.original}
      />
    ),
  },
];

export const WITHOUT_INVENTORY_RAC_TABLE_COLUMNS = [
  {
    Header: i18n.t('components.table.columns.photos'),
    accessor: WITHOUT_INVENTORY_TABLE_COLUMN_ID.PHOTO,
    id: WITHOUT_INVENTORY_TABLE_COLUMN_ID.PHOTO,
    disableSortBy: true,
    isVisible: true,
    Cell: (cell: Cell<IRACVehicle>): JSX.Element => <ImageCell data={cell.row.original} noColoring />,
  },
  {
    Header: i18n.t('components.table.columns.vehicle'),
    accessor: WITHOUT_INVENTORY_TABLE_COLUMN_ID.VEHICLE,
    id: WITHOUT_INVENTORY_TABLE_COLUMN_ID.VEHICLE,
    isVisible: true,
    Cell: (cell: Cell<IRACVehicle>): JSX.Element => (
      <OpenLoanVariablesDialog value={cell.row.original.description} currentVehicleData={cell.row.original} />
    ),
  },
  {
    Header: i18n.t('components.table.columns.vin'),
    accessor: WITHOUT_INVENTORY_TABLE_COLUMN_ID.VIN,
    id: WITHOUT_INVENTORY_TABLE_COLUMN_ID.VIN,
    isVisible: true,
    Cell: (cell: Cell<IRACVehicle>): JSX.Element => (
      <VINCell vin={cell.row.original.vin} isStubbed={cell.row.original?.isStubbed} />
    ),
  },
  {
    Header: i18n.t('components.table.columns.miles'),
    accessor: WITHOUT_INVENTORY_TABLE_COLUMN_ID.MILES,
    id: WITHOUT_INVENTORY_TABLE_COLUMN_ID.MILES,
    isVisible: true,
    Cell: (cell: Cell<IRACVehicle>): JSX.Element => (
      <OpenLoanVariablesDialog
        value={thousandFormatter(cell.row.original.odometer)}
        currentVehicleData={cell.row.original}
      />
    ),
  },
  {
    Header: i18n.t('components.table.columns.retailPrice'),
    accessor: WITHOUT_INVENTORY_TABLE_COLUMN_ID.RETAIL_PRICE,
    id: WITHOUT_INVENTORY_TABLE_COLUMN_ID.RETAIL_PRICE,
    isVisible: true,
    Cell: (cell: Cell<IRACVehicle>): JSX.Element => (
      <OpenLoanVariablesDialog
        value={thousandFormatter(cell.row.original.current_price)}
        currentVehicleData={cell.row.original}
      />
    ),
  },
  {
    Header: i18n.t('components.table.columns.estimatedPayment'),
    accessor: WITHOUT_INVENTORY_TABLE_COLUMN_ID.ESTIMATED_PAYMENT,
    id: WITHOUT_INVENTORY_TABLE_COLUMN_ID.ESTIMATED_PAYMENT,
    isVisible: true,
    Cell: (cell: Cell<IRACVehicle>): JSX.Element => (
      <OpenLoanVariablesDialog
        value={thousandFormatter(cell.row.original.ltv_value.payments)}
        currentVehicleData={cell.row.original}
      />
    ),
  },
  {
    Header: i18n.t('components.table.columns.recommendedTerm'),
    accessor: WITHOUT_INVENTORY_TABLE_COLUMN_ID.RECOMMENDED_TERM,
    id: WITHOUT_INVENTORY_TABLE_COLUMN_ID.RECOMMENDED_TERM,
    isVisible: true,
    Cell: (cell: Cell<IRACVehicle>): JSX.Element => (
      <OpenLoanVariablesDialog
        value={thousandFormatter(cell.row.original.loan_term)}
        currentVehicleData={cell.row.original}
      />
    ),
  },
  {
    Header: i18n.t('components.table.columns.ltv'),
    accessor: WITHOUT_INVENTORY_TABLE_COLUMN_ID.LTV,
    id: WITHOUT_INVENTORY_TABLE_COLUMN_ID.LTV,
    isVisible: true,
    Cell: (cell: Cell<IRACVehicle>): JSX.Element => (
      <OpenLoanVariablesDialog
        value={percentFormatter(cell.row.original.ltv_value.ltv)}
        currentVehicleData={cell.row.original}
      />
    ),
  },
  {
    Header: i18n.t('components.table.columns.extraCash'),
    accessor: WITHOUT_INVENTORY_TABLE_COLUMN_ID.EXTRA_CASH,
    id: WITHOUT_INVENTORY_TABLE_COLUMN_ID.EXTRA_CASH,
    isVisible: true,
    Cell: (cell: Cell<IRACVehicle>): JSX.Element => (
      <OpenLoanVariablesDialog
        cell={
          <ExtraCashCell extraCashValue={(cell.row.original.ltv_value as Record<string, any>).extra_down_payment} />
        }
        currentVehicleData={cell.row.original}
      />
    ),
  },
  {
    Header: i18n.t('components.table.columns.bookValue'),
    accessor: WITHOUT_INVENTORY_TABLE_COLUMN_ID.BOOK_VALUE,
    id: WITHOUT_INVENTORY_TABLE_COLUMN_ID.BOOK_VALUE,
    isVisible: true,
    Cell: (cell: Cell<IRACVehicle>): JSX.Element => (
      <OpenLoanVariablesDialog
        value={thousandFormatter(cell.row.original.guidebook_value)}
        currentVehicleData={cell.row.original}
      />
    ),
  },
  {
    Header: i18n.t('components.table.columns.estimatedAmountFinanced'),
    accessor: WITHOUT_INVENTORY_TABLE_COLUMN_ID.ESTIMATED_AMOUNT_FINANCED,
    id: WITHOUT_INVENTORY_TABLE_COLUMN_ID.ESTIMATED_AMOUNT_FINANCED,
    isVisible: true,
    Cell: (cell: Cell<IRACVehicle>): JSX.Element => (
      <OpenLoanVariablesDialog
        value={thousandFormatter(cell.row.original.ltv_value.loan_amount)}
        currentVehicleData={cell.row.original}
      />
    ),
  },
];

export const SALESPERSON_RAC_VEHICLE_TABLE_COLUMNS = [
  ...BASE_RAC_VEHICLE_TABLE_COLUMNS,
  {
    Header: i18n.t('components.table.columns.inventoryAge'),
    accessor: RAC_VEHICLE_TABLE_COLUMN_ID.AGE,
    id: RAC_VEHICLE_TABLE_COLUMN_ID.AGE,
    isVisible: true,
    Cell: (cell: Cell<IRACVehicle>): JSX.Element => (
      <OpenLoanVariablesDialog value={valueOrHyphen(cell.row.original.age)} currentVehicleData={cell.row.original} />
    ),
  },
  {
    Header: i18n.t('components.table.columns.miles'),
    accessor: RAC_VEHICLE_TABLE_COLUMN_ID.MILES,
    id: RAC_VEHICLE_TABLE_COLUMN_ID.MILES,
    isVisible: true,
    Cell: (cell: Cell<IRACVehicle>): JSX.Element => (
      <OpenLoanVariablesDialog
        value={thousandFormatter(cell.row.original.odometer)}
        currentVehicleData={cell.row.original}
      />
    ),
  },
  {
    Header: i18n.t('components.table.columns.location'),
    accessor: RAC_VEHICLE_TABLE_COLUMN_ID.LOCATION,
    id: RAC_VEHICLE_TABLE_COLUMN_ID.LOCATION,
    isVisible: true,
    Cell: (cell: Cell<IRACVehicle>): JSX.Element => (
      <OpenLoanVariablesDialog value={cell.row.original.location} currentVehicleData={cell.row.original} />
    ),
  },
  {
    Header: i18n.t('components.table.columns.retailPrice'),
    accessor: RAC_VEHICLE_TABLE_COLUMN_ID.RETAIL_PRICE,
    id: RAC_VEHICLE_TABLE_COLUMN_ID.RETAIL_PRICE,
    isVisible: true,
    Cell: (cell: Cell<IRACVehicle>): JSX.Element => (
      <OpenLoanVariablesDialog
        value={thousandFormatter(cell.row.original.current_price)}
        currentVehicleData={cell.row.original}
      />
    ),
  },
  {
    Header: i18n.t('components.table.columns.bookValue'),
    accessor: RAC_VEHICLE_TABLE_COLUMN_ID.BOOK_VALUE,
    id: RAC_VEHICLE_TABLE_COLUMN_ID.BOOK_VALUE,
    isVisible: true,
    Cell: (cell: Cell<IRACVehicle>): JSX.Element => (
      <OpenLoanVariablesDialog
        value={thousandFormatter(cell.row.original.guidebook_value)}
        currentVehicleData={cell.row.original}
      />
    ),
  },
  {
    Header: i18n.t('components.table.columns.valuationGuide'),
    accessor: VEHICLE_TABLE_COLUMN_ID.VALUATION_GUIDE,
    id: VEHICLE_TABLE_COLUMN_ID.VALUATION_GUIDE,
    isVisible: true,
    Cell: (cell: Cell<IRACVehicle>): JSX.Element => (
      <OpenLoanVariablesDialog value={String(cell.row.original.guidebook)} currentVehicleData={cell.row.original} />
    ),
  },
  {
    Header: i18n.t('components.table.columns.cost'),
    accessor: RAC_VEHICLE_TABLE_COLUMN_ID.UNIT_COST,
    id: RAC_VEHICLE_TABLE_COLUMN_ID.UNIT_COST,
    isVisible: true,
    Cell: (cell: Cell<IRACVehicle>): JSX.Element => (
      <OpenLoanVariablesDialog
        value={thousandFormatterOrHyphen(cell.row.original.unit_cost)}
        currentVehicleData={cell.row.original}
      />
    ),
  },
  {
    Header: i18n.t('components.table.columns.fAndIFlat'),
    accessor: RAC_VEHICLE_TABLE_COLUMN_ID.F_AND_I_FLAT,
    id: RAC_VEHICLE_TABLE_COLUMN_ID.F_AND_I_FLAT,
    isVisible: true,
    sortType: 'basic',
    Cell: (cell: Cell<IRACVehicle>): JSX.Element => (
      <OpenLoanVariablesDialog
        value={thousandFormatter(cell.row.original.ltv_value.f_and_i_flat)}
        currentVehicleData={cell.row.original}
      />
    ),
  },
  {
    Header: i18n.t('components.table.columns.payment'),
    accessor: RAC_VEHICLE_TABLE_COLUMN_ID.PAYMENT,
    id: RAC_VEHICLE_TABLE_COLUMN_ID.PAYMENT,
    isVisible: true,
    Cell: (cell: Cell<IRACVehicle>): JSX.Element => (
      <OpenLoanVariablesDialog
        value={thousandFormatter(cell.row.original.ltv_value.payments)}
        currentVehicleData={cell.row.original}
      />
    ),
  },
  {
    Header: i18n.t('components.table.columns.estimatedAmtFinanced'),
    accessor: RAC_VEHICLE_TABLE_COLUMN_ID.LOAN_AMOUNT,
    id: RAC_VEHICLE_TABLE_COLUMN_ID.LOAN_AMOUNT,
    isVisible: true,
    Cell: (cell: Cell<IRACVehicle>): JSX.Element => (
      <OpenLoanVariablesDialog
        value={thousandFormatter(cell.row.original.ltv_value.loan_amount)}
        currentVehicleData={cell.row.original}
      />
    ),
  },
  {
    Header: i18n.t('components.table.columns.term'),
    accessor: RAC_VEHICLE_TABLE_COLUMN_ID.TERM,
    id: RAC_VEHICLE_TABLE_COLUMN_ID.TERM,
    isVisible: true,
    Cell: (cell: Cell<IRACVehicle>): JSX.Element => (
      <OpenLoanVariablesDialog
        value={thousandFormatter(cell.row.original.loan_term)}
        currentVehicleData={cell.row.original}
      />
    ),
  },
  {
    Header: i18n.t('components.table.columns.profit'),
    accessor: RAC_VEHICLE_TABLE_COLUMN_ID.PROFIT,
    id: RAC_VEHICLE_TABLE_COLUMN_ID.PROFIT,
    sortType: 'basic',
    isVisible: true,
    Cell: (cell: Cell<IRACVehicle>): JSX.Element => (
      <OpenLoanVariablesDialog
        value={thousandFormatterOrHyphen(cell.row.original.ltv_value.profit)}
        currentVehicleData={cell.row.original}
      />
    ),
  },
  {
    Header: i18n.t('components.table.columns.ltvExcTaxFees'),
    accessor: RAC_VEHICLE_TABLE_COLUMN_ID.LTV,
    id: RAC_VEHICLE_TABLE_COLUMN_ID.LTV,
    isVisible: true,
    Cell: (cell: Cell<IRACVehicle>): JSX.Element => (
      <OpenLoanVariablesDialog
        value={percentFormatter(cell.row.original.ltv_value.ltv)}
        currentVehicleData={cell.row.original}
      />
    ),
  },
  {
    Header: i18n.t('components.table.columns.extraCash'),
    accessor: RAC_VEHICLE_TABLE_COLUMN_ID.EXTRA_CASH,
    id: RAC_VEHICLE_TABLE_COLUMN_ID.EXTRA_CASH,
    isVisible: true,
    Cell: (cell: Cell<IRACVehicle>): JSX.Element => (
      <OpenLoanVariablesDialog
        cell={
          <ExtraCashCell extraCashValue={(cell.row.original.ltv_value as Record<string, any>).extra_down_payment} />
        }
        currentVehicleData={cell.row.original}
      />
    ),
  },
  {
    Header: i18n.t('components.table.columns.driveType'),
    accessor: VEHICLE_TABLE_COLUMN_ID.DRIVE_TYPE,
    id: VEHICLE_TABLE_COLUMN_ID.DRIVE_TYPE,
    Cell: (cell: Cell<IRACVehicle>): JSX.Element => (
      <OpenLoanVariablesDialog value={cell.row.original.drive_type} currentVehicleData={cell.row.original} />
    ),
  },
  {
    Header: i18n.t('components.table.columns.passengerCapacity'),
    accessor: VEHICLE_TABLE_COLUMN_ID.PASSENGER_CAPACITY,
    id: VEHICLE_TABLE_COLUMN_ID.PASSENGER_CAPACITY,
    Cell: (cell: Cell<IRACVehicle>): JSX.Element => (
      <OpenLoanVariablesDialog
        value={valueOrHyphen(cell.row.original.passenger_capacity)}
        currentVehicleData={cell.row.original}
      />
    ),
  },
];

export const SALESPERSON_LENDER_VEHICLE_TABLE_COLUMNS = [
  ...BASE_LENDER_VEHICLE_TABLE_COLUMNS,
  {
    Header: i18n.t('components.table.columns.inventoryAge'),
    accessor: VEHICLE_TABLE_COLUMN_ID.AGE,
    id: VEHICLE_TABLE_COLUMN_ID.AGE,
    isVisible: true,
    Cell: (cell: Cell<ILenderVehicle>): JSX.Element => (
      <OpenLoanVariablesDialog value={valueOrHyphen(cell.row.original.age)} currentVehicleData={cell.row.original} />
    ),
  },
  {
    Header: i18n.t('components.table.columns.miles'),
    accessor: RAC_VEHICLE_TABLE_COLUMN_ID.MILES,
    id: RAC_VEHICLE_TABLE_COLUMN_ID.MILES,
    isVisible: true,
    Cell: (cell: Cell<ILenderVehicle>): JSX.Element => (
      <OpenLoanVariablesDialog
        value={thousandFormatter(cell.row.original.odometer)}
        currentVehicleData={cell.row.original}
      />
    ),
  },
  {
    Header: i18n.t('components.table.columns.location'),
    accessor: LENDER_VEHICLE_TABLE_COLUMN_ID.LOCATION,
    id: LENDER_VEHICLE_TABLE_COLUMN_ID.LOCATION,
    isVisible: true,
    Cell: (cell: Cell<ILenderVehicle>): JSX.Element => (
      <OpenLoanVariablesDialog value={cell.row.original.location} currentVehicleData={cell.row.original} />
    ),
  },
  {
    Header: i18n.t('components.table.columns.retailPrice'),
    accessor: LENDER_VEHICLE_TABLE_COLUMN_ID.RETAIL_PRICE,
    id: LENDER_VEHICLE_TABLE_COLUMN_ID.RETAIL_PRICE,
    isVisible: true,
    Cell: (cell: Cell<ILenderVehicle>): JSX.Element => (
      <OpenLoanVariablesDialog
        value={thousandFormatter(cell.row.original.current_price)}
        currentVehicleData={cell.row.original}
      />
    ),
  },
  {
    Header: i18n.t('components.table.columns.bookValue'),
    accessor: LENDER_VEHICLE_TABLE_COLUMN_ID.BOOK_VALUE,
    id: LENDER_VEHICLE_TABLE_COLUMN_ID.BOOK_VALUE,
    isVisible: true,
    Cell: (cell: Cell<ILenderVehicle>): JSX.Element => (
      <OpenLoanVariablesDialog
        value={thousandFormatter(cell.row.original.guidebook_value)}
        currentVehicleData={cell.row.original}
      />
    ),
  },
  {
    Header: i18n.t('components.table.columns.valuationGuide'),
    accessor: LENDER_VEHICLE_TABLE_COLUMN_ID.VALUATION_GUIDE,
    id: LENDER_VEHICLE_TABLE_COLUMN_ID.VALUATION_GUIDE,
    isVisible: true,
    Cell: (cell: Cell<ILenderVehicle>): JSX.Element => (
      <OpenLoanVariablesDialog value={String(cell.row.original.guidebook)} currentVehicleData={cell.row.original} />
    ),
  },
  {
    Header: i18n.t('components.table.columns.cost'),
    accessor: RAC_VEHICLE_TABLE_COLUMN_ID.UNIT_COST,
    id: RAC_VEHICLE_TABLE_COLUMN_ID.UNIT_COST,
    isVisible: true,
    Cell: (cell: Cell<ILenderVehicle>): JSX.Element => (
      <OpenLoanVariablesDialog
        value={thousandFormatterOrHyphen(cell.row.original.unit_cost)}
        currentVehicleData={cell.row.original}
      />
    ),
  },
  {
    Header: i18n.t('components.table.columns.estimatedPayment'),
    accessor: LENDER_VEHICLE_TABLE_COLUMN_ID.PAYMENT,
    id: LENDER_VEHICLE_TABLE_COLUMN_ID.PAYMENT,
    isVisible: true,
    Cell: (cell: Cell<ILenderVehicle>): JSX.Element => (
      <OpenLoanVariablesDialog
        value={thousandFormatter(cell.row.original.monthly_payment as number)}
        currentVehicleData={cell.row.original}
      />
    ),
  },
  {
    Header: i18n.t('components.table.columns.estimatedAmtFinanced'),
    accessor: VEHICLE_TABLE_COLUMN_ID.LOAN_AMOUNT,
    id: VEHICLE_TABLE_COLUMN_ID.LOAN_AMOUNT,
    isVisible: true,
    Cell: (cell: Cell<ILenderVehicle>): JSX.Element => (
      <OpenLoanVariablesDialog
        value={thousandFormatter(cell.row.original.total_financed as number)}
        currentVehicleData={cell.row.original}
      />
    ),
  },
  {
    Header: i18n.t('components.table.columns.term'),
    accessor: LENDER_VEHICLE_TABLE_COLUMN_ID.RECOMMENDED_TERM,
    id: LENDER_VEHICLE_TABLE_COLUMN_ID.RECOMMENDED_TERM,
    isVisible: true,
    Cell: (cell: Cell<ILenderVehicle>): JSX.Element => (
      <OpenLoanVariablesDialog
        value={thousandFormatter(cell.row.original.term as number)}
        currentVehicleData={cell.row.original}
      />
    ),
  },
  {
    Header: i18n.t('components.table.columns.estimatedProfit'),
    accessor: LENDER_VEHICLE_TABLE_COLUMN_ID.ESTIMATED_PROFIT,
    id: LENDER_VEHICLE_TABLE_COLUMN_ID.ESTIMATED_PROFIT,
    sortType: 'basic',
    isVisible: true,
    Cell: (cell: Cell<ILenderVehicle>): JSX.Element => (
      <OpenLoanVariablesDialog
        value={thousandFormatter(cell.row.original.profit as number)}
        currentVehicleData={cell.row.original}
      />
    ),
  },
  {
    Header: i18n.t('components.table.columns.ltv'),
    accessor: VEHICLE_TABLE_COLUMN_ID.LTV_RATIO,
    id: VEHICLE_TABLE_COLUMN_ID.LTV_RATIO,
    isVisible: true,
    Cell: (cell: Cell<ILenderVehicle>): JSX.Element => (
      <OpenLoanVariablesDialog
        value={percentFormatter(cell.row.original.ltv_ratio as number)}
        currentVehicleData={cell.row.original}
      />
    ),
  },
  {
    Header: i18n.t('components.table.columns.extraCash'),
    accessor: LENDER_VEHICLE_TABLE_COLUMN_ID.SALESPERSON_BUCKET,
    id: LENDER_VEHICLE_TABLE_COLUMN_ID.SALESPERSON_BUCKET,
    isVisible: true,
    Cell: (cell: Cell<ILenderVehicle>): JSX.Element => (
      <OpenLoanVariablesDialog
        cell={<ExtraCashCell extraCashValue={cell.row.original.salesperson_bucket as number} />}
        currentVehicleData={cell.row.original}
      />
    ),
  },
  {
    Header: i18n.t('components.table.columns.driveType'),
    accessor: VEHICLE_TABLE_COLUMN_ID.DRIVE_TYPE,
    id: VEHICLE_TABLE_COLUMN_ID.DRIVE_TYPE,
    Cell: (cell: Cell<ILenderVehicle>): JSX.Element => (
      <OpenLoanVariablesDialog value={cell.row.original.drive_type} currentVehicleData={cell.row.original} />
    ),
  },
  {
    Header: i18n.t('components.table.columns.passengerCapacity'),
    accessor: VEHICLE_TABLE_COLUMN_ID.PASSENGER_CAPACITY,
    id: VEHICLE_TABLE_COLUMN_ID.PASSENGER_CAPACITY,
    Cell: (cell: Cell<ILenderVehicle>): JSX.Element => (
      <OpenLoanVariablesDialog
        value={valueOrHyphen(cell.row.original.passenger_capacity)}
        currentVehicleData={cell.row.original}
      />
    ),
  },
];

export const MANAGER_LENDER_VEHICLE_TABLE_COLUMNS = [
  ...BASE_LENDER_VEHICLE_TABLE_COLUMNS,
  {
    Header: i18n.t('components.table.columns.location'),
    accessor: LENDER_VEHICLE_TABLE_COLUMN_ID.LOCATION,
    id: LENDER_VEHICLE_TABLE_COLUMN_ID.LOCATION,
    isVisible: true,
    Cell: (cell: Cell<ILenderVehicle>): JSX.Element => (
      <OpenLoanVariablesDialog value={cell.row.original.location} currentVehicleData={cell.row.original} />
    ),
  },
  {
    Header: i18n.t('components.table.columns.inventoryAge'),
    accessor: VEHICLE_TABLE_COLUMN_ID.AGE,
    id: VEHICLE_TABLE_COLUMN_ID.AGE,
    isVisible: true,
    Cell: (cell: Cell<ILenderVehicle>): JSX.Element => (
      <OpenLoanVariablesDialog value={valueOrHyphen(cell.row.original.age)} currentVehicleData={cell.row.original} />
    ),
  },
  {
    Header: i18n.t('components.table.columns.miles'),
    accessor: VEHICLE_TABLE_COLUMN_ID.MILES,
    id: VEHICLE_TABLE_COLUMN_ID.MILES,
    isVisible: true,
    Cell: (cell: Cell<ILenderVehicle>): JSX.Element => (
      <OpenLoanVariablesDialog
        value={thousandFormatter(cell.row.original.odometer)}
        currentVehicleData={cell.row.original}
      />
    ),
  },
  {
    Header: i18n.t('components.table.columns.retailPrice'),
    accessor: LENDER_VEHICLE_TABLE_COLUMN_ID.RETAIL_PRICE,
    id: LENDER_VEHICLE_TABLE_COLUMN_ID.RETAIL_PRICE,
    isVisible: true,
    Cell: (cell: Cell<ILenderVehicle>): JSX.Element => (
      <OpenLoanVariablesDialog
        value={thousandFormatter(cell.row.original.current_price)}
        currentVehicleData={cell.row.original}
      />
    ),
  },
  {
    Header: i18n.t('components.table.columns.maxPrice'),
    accessor: VEHICLE_TABLE_COLUMN_ID.MAX_PRICE,
    id: VEHICLE_TABLE_COLUMN_ID.MAX_PRICE,
    isVisible: true,
    Cell: (cell: Cell<ILenderVehicle>): JSX.Element => (
      <OpenLoanVariablesDialog
        value={thousandFormatterOrHyphen(cell.row.original.manager_price as number)}
        currentVehicleData={cell.row.original}
      />
    ),
  },
  {
    Header: i18n.t('components.table.columns.discount'),
    accessor: VEHICLE_TABLE_COLUMN_ID.DISCOUNT,
    id: VEHICLE_TABLE_COLUMN_ID.DISCOUNT,
    sortType: 'basic',
    isVisible: true,
    Cell: (cell: Cell<ILenderVehicle>): JSX.Element => (
      <OpenLoanVariablesDialog
        value={thousandFormatterOrHyphen(cell.row.original.discount as number)}
        currentVehicleData={cell.row.original}
      />
    ),
  },
  {
    Header: i18n.t('components.table.columns.cost'),
    accessor: RAC_VEHICLE_TABLE_COLUMN_ID.UNIT_COST,
    id: RAC_VEHICLE_TABLE_COLUMN_ID.UNIT_COST,
    isVisible: true,
    Cell: (cell: Cell<ILenderVehicle>): JSX.Element => (
      <OpenLoanVariablesDialog
        value={thousandFormatterOrHyphen(cell.row.original.unit_cost)}
        currentVehicleData={cell.row.original}
      />
    ),
  },
  {
    Header: i18n.t('components.table.columns.estimatedProfit'),
    accessor: LENDER_VEHICLE_TABLE_COLUMN_ID.ESTIMATED_PROFIT,
    id: LENDER_VEHICLE_TABLE_COLUMN_ID.ESTIMATED_PROFIT,
    sortType: 'basic',
    isVisible: true,
    Cell: (cell: Cell<ILenderVehicle>): JSX.Element => (
      <OpenLoanVariablesDialog
        value={thousandFormatter(cell.row.original.profit as number)}
        currentVehicleData={cell.row.original}
      />
    ),
  },
  {
    Header: i18n.t('components.table.columns.term'),
    accessor: LENDER_VEHICLE_TABLE_COLUMN_ID.RECOMMENDED_TERM,
    id: LENDER_VEHICLE_TABLE_COLUMN_ID.RECOMMENDED_TERM,
    isVisible: true,
    Cell: (cell: Cell<ILenderVehicle>): JSX.Element => (
      <OpenLoanVariablesDialog
        value={thousandFormatter(cell.row.original.term as number)}
        currentVehicleData={cell.row.original}
      />
    ),
  },
  {
    Header: i18n.t('components.table.columns.estimatedPayment'),
    accessor: LENDER_VEHICLE_TABLE_COLUMN_ID.PAYMENT,
    id: LENDER_VEHICLE_TABLE_COLUMN_ID.PAYMENT,
    isVisible: true,
    Cell: (cell: Cell<ILenderVehicle>): JSX.Element => (
      <OpenLoanVariablesDialog
        value={thousandFormatter(cell.row.original.monthly_payment as number)}
        currentVehicleData={cell.row.original}
      />
    ),
  },
  {
    Header: i18n.t('components.table.columns.estimatedAmtFinanced'),
    accessor: VEHICLE_TABLE_COLUMN_ID.LOAN_AMOUNT,
    id: VEHICLE_TABLE_COLUMN_ID.LOAN_AMOUNT,
    isVisible: true,
    Cell: (cell: Cell<ILenderVehicle>): JSX.Element => (
      <OpenLoanVariablesDialog
        value={thousandFormatter(cell.row.original.total_financed as number)}
        currentVehicleData={cell.row.original}
      />
    ),
  },
  {
    Header: i18n.t('components.table.columns.bookValue'),
    accessor: LENDER_VEHICLE_TABLE_COLUMN_ID.BOOK_VALUE,
    id: LENDER_VEHICLE_TABLE_COLUMN_ID.BOOK_VALUE,
    isVisible: true,
    Cell: (cell: Cell<ILenderVehicle>): JSX.Element => (
      <OpenLoanVariablesDialog
        value={thousandFormatter(cell.row.original.guidebook_value)}
        currentVehicleData={cell.row.original}
      />
    ),
  },
  {
    Header: i18n.t('components.table.columns.valuationGuide'),
    accessor: LENDER_VEHICLE_TABLE_COLUMN_ID.VALUATION_GUIDE,
    id: LENDER_VEHICLE_TABLE_COLUMN_ID.VALUATION_GUIDE,
    isVisible: true,
    Cell: (cell: Cell<ILenderVehicle>): JSX.Element => (
      <OpenLoanVariablesDialog value={String(cell.row.original.guidebook)} currentVehicleData={cell.row.original} />
    ),
  },
  {
    Header: i18n.t('components.table.columns.ltv'),
    accessor: VEHICLE_TABLE_COLUMN_ID.LTV_RATIO,
    id: VEHICLE_TABLE_COLUMN_ID.LTV_RATIO,
    isVisible: true,
    Cell: (cell: Cell<ILenderVehicle>): JSX.Element => (
      <OpenLoanVariablesDialog
        value={percentFormatter(cell.row.original.ltv_ratio as number)}
        currentVehicleData={cell.row.original}
      />
    ),
  },
  {
    Header: i18n.t('components.table.columns.driveType'),
    accessor: VEHICLE_TABLE_COLUMN_ID.DRIVE_TYPE,
    id: VEHICLE_TABLE_COLUMN_ID.DRIVE_TYPE,
    Cell: (cell: Cell<ILenderVehicle>): JSX.Element => (
      <OpenLoanVariablesDialog value={cell.row.original.drive_type} currentVehicleData={cell.row.original} />
    ),
  },
  {
    Header: i18n.t('components.table.columns.passengerCapacity'),
    accessor: VEHICLE_TABLE_COLUMN_ID.PASSENGER_CAPACITY,
    id: VEHICLE_TABLE_COLUMN_ID.PASSENGER_CAPACITY,
    Cell: (cell: Cell<ILenderVehicle>): JSX.Element => (
      <OpenLoanVariablesDialog
        value={valueOrHyphen(cell.row.original.passenger_capacity)}
        currentVehicleData={cell.row.original}
      />
    ),
  },
];

export const LTV_TABLE_COLUMN = {
  Header: i18n.t('components.table.columns.ltv'),
  accessor: VEHICLE_TABLE_COLUMN_ID.LTV_RATIO,
  id: VEHICLE_TABLE_COLUMN_ID.LTV_RATIO,
  isVisible: true,
  Cell: (cell: Cell<IVehiclePayload>): string => percentFormatter(cell.row.original.ltv_ratio),
};

export const LTV_CLICKABLE_TABLE_COLUMN = {
  Header: i18n.t('components.table.columns.ltv'),
  accessor: VEHICLE_TABLE_COLUMN_ID.LTV_RATIO,
  id: VEHICLE_TABLE_COLUMN_ID.LTV_RATIO,
  isVisible: true,
  Cell: (cell: Cell<ILenderVehicle>): JSX.Element => (
    <OpenLoanVariablesDialog
      value={percentFormatter(cell.row.original.ltv_ratio as number)}
      currentVehicleData={cell.row.original}
    />
  ),
};

export const BANK_FEES_TABLE_COLUMN = {
  Header: i18n.t('components.table.columns.bankFees'),
  accessor: VEHICLE_TABLE_COLUMN_ID.BANK_FEES_NAME,
  id: VEHICLE_TABLE_COLUMN_ID.BANK_FEES_NAME,
  Cell: (cell: Cell<IVehiclePayload>): string => thousandFormatter(cell.row.original.bank_fees),
  isVisible: true,
};

export const BANK_FEES_CLICKABLE_TABLE_COLUMN = {
  Header: i18n.t('components.table.columns.bankFees'),
  accessor: VEHICLE_TABLE_COLUMN_ID.BANK_FEES_NAME,
  id: VEHICLE_TABLE_COLUMN_ID.BANK_FEES_NAME,
  isVisible: true,
  Cell: (cell: Cell<ILenderVehicle>): JSX.Element => (
    <OpenLoanVariablesDialog
      value={thousandFormatter(cell.row.original.bank_fees as number)}
      currentVehicleData={cell.row.original}
    />
  ),
};
