import styled from 'styled-components';

export const ErrorWrapper = styled.div`
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
`;

export const ErrorCard = styled.div`
  width: 100%;
  padding: 15px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
  font-size: 15px;
  box-sizing: border-box;
`;

export const ErrorHeader = styled.div`
  padding: 0 10px 10px 10px;
  color: #4e6280;
  border-bottom: 1px solid #e4e4e4;
  font-family: 'Rubik', sans-serif;

  h1 {
    margin: 0;
    font-weight: 600;
  }
`;

export const ErrorBody = styled.div`
  padding: 10px 10px 0 10px;

  p {
    color: #4f4f50;
    font-size: 18px;
    margin-bottom: 3px;
    margin-top: 0;
    font-family: 'Rubik', sans-serif;
  }
`;

export const ErrorLinks = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  padding-left: 10px;
  font-family: 'Rubik', sans-serif;

  a {
    position: relative;
    cursor: pointer;
    font-size: 18px;
    padding-left: 4px;
    color: ${({ theme: { colors } }) => colors['blue-4']};
    line-height: 1;

    &:hover {
      color: #23527c;
    }

    &:not(:last-child) {
      margin-right: 20px;
    }
  }
`;
