import { createSelector } from '@reduxjs/toolkit';
import { VehicleBody } from 'modules/core/types';
import { toMultiselectOptions, toVehicleAdjustmentLog, toVehicleAdjustRetail } from 'modules/core/utils';
import { IVehicleDetails } from 'components/Modals/VehicleDetailsDialog/types';
import { IAddRouteOneVehicleFlags } from 'modules/core/types/routeOne';
import { ICreateRouteOneVehicle } from 'components/Modals/AddVehicleDialog/types';

import { RootState } from '../store';
import { IVehicleReducerState } from '../reducers/vehicle.reducer';

export const getVehicleState = (state: RootState): IVehicleReducerState => state.vehicle;

export const getVehicleDetails = (state: RootState): IVehicleDetails | undefined =>
  getVehicleState(state).vehicleDetails;

export const getVehicleBodies = (state: RootState): VehicleBody[] => getVehicleState(state).bodies;

export const getDefaultVehiclePhoto = (state: RootState): string => getVehicleState(state).defaultVehiclePhoto;

export const getMultiselectVehicleBodiesOptions = createSelector(getVehicleBodies, toMultiselectOptions);

export const getAdjustmentId = (state: RootState): number => getVehicleState(state).adjustmentId;

export const getPassengerCapacities = (state: RootState): number[] => getVehicleState(state).passengerCapacities;

export const getVehicleAdjustRetail = createSelector(getVehicleState, toVehicleAdjustRetail);

export const getVehicleAdjustmentLog = createSelector(getVehicleState, toVehicleAdjustmentLog);

export const getAddRouteOneVehicleFlags = (state: RootState): IAddRouteOneVehicleFlags => {
  const { addRouteOneVehicleFlags } = getVehicleState(state);

  return { ...addRouteOneVehicleFlags };
};

export const getCreatedRouteOneVehicle = (state: RootState): ICreateRouteOneVehicle =>
  getVehicleState(state).createdRouteOneVehicle;
