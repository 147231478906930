import styled from 'styled-components';
import { theme } from 'modules/core/theme';

export enum DividerMode {
  LIGHT = 'light',
  BLUE = 'blue',
  GREEN = 'green',
}

const DIVIDER_MODE_COLOR_MAP: Record<string, string> = {
  light: theme.colors['white-2'],
  blue: theme.colors['blue-1'],
  green: theme.colors['green-5'],
};

const getDividerBorderColor = (mode = DividerMode.LIGHT) => DIVIDER_MODE_COLOR_MAP[mode];

export const Divider = styled.div<{ mode?: DividerMode }>`
  border-bottom: 1px solid;
  border-color: ${({ mode }) => getDividerBorderColor(mode)};
`;

export const VerticalDivider = styled(Divider)<{ mode?: DividerMode }>`
  border-bottom: none;
  border-right: 2px solid;
  border-color: ${({ mode }) => getDividerBorderColor(mode)}; ;
`;
