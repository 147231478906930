import i18n from 'i18next';
import { TableView } from 'modules/core/enums';
import { IUserAccessOptions, OptionValue } from 'modules/core/types';
import { PERMISSIONS } from 'modules/PermissionWrapper/permissions.const';

import { IVehicleTableViewOptions } from './types';

export const VEHICLE_TABLE_VIEW_TITLES = {
  MANAGER: i18n.t('vehicleTableView.managerView'),
  SALESPERSON: i18n.t('vehicleTableView.salespersonView'),
  EXECUTIVE: i18n.t('vehicleTableView.executiveView'),
};

export const VEHICLE_TABLE_VIEW: IVehicleTableViewOptions[] = [
  {
    title: VEHICLE_TABLE_VIEW_TITLES.MANAGER,
    value: TableView.MANAGER,
    allowedRoles: PERMISSIONS.TABLE_VIEW_BY_MANAGER,
  },
  {
    title: VEHICLE_TABLE_VIEW_TITLES.SALESPERSON,
    value: TableView.SALESPERSON,
    allowedRoles: PERMISSIONS.TABLE_VIEW_BY_SALESPERSON,
  },
];

export const VEHICLE_TABLE_VIEW_DEPENDENCIES = new Map<OptionValue, keyof IUserAccessOptions>([
  [TableView.EXECUTIVE, 'hasExecutiveAccess'],
]);
