import { STATIC_IMAGE_URLS } from 'modules/core/constants';
import React, { FC } from 'react';

import { CalculatorIcon } from './styles';

interface ICalculatorAdornment {
  dataTestId?: string;
  onClick?: VoidFunction;
}

export const CalculatorAdornment: FC<ICalculatorAdornment> = ({ dataTestId, onClick }) => (
  <CalculatorIcon src={STATIC_IMAGE_URLS.icons.calculator} data-testid={dataTestId} onClick={onClick} />
);
