import { AxiosResponse } from 'axios';
import { Api } from 'api/api';
import { Endpoints } from 'modules/core/constants';
import { TReport } from 'modules/core/types';
import { ReportType } from 'modules/core/enums';
import { ApiResponseType } from 'modules/core/enums/apiResponseType.enum';
import { TSendReport } from 'components/Modals/SendReportDialog/types';

class ReportApi extends Api {
  postSendSearch = (submitData: TSendReport): Promise<AxiosResponse> => this.api.post(Endpoints.sendSearch, submitData);
  postPrintReport = (submitData: TReport, reportType: ReportType): Promise<AxiosResponse<Blob>> =>
    this.api.post(`${Endpoints.postPrintReport}${reportType}/`, submitData, { responseType: ApiResponseType.BLOB });
}

export default new ReportApi();
