import { createAsyncThunk } from '@reduxjs/toolkit';
import { authApi } from 'api';
import { ROUTER_PATH } from 'router/const/routerPath.const';
import { THistory } from 'modules/core/types';
import {
  CSRF_TOKEN_NAME,
  IS_AUTHORIZED_LS_KEY,
  IS_SSO_AND_SHOW_LOAN_VARIABLES_DIALOG,
} from 'modules/auth/constants/auth.const';
import { getFromCookies } from 'modules/auth/utils/cookie';
import { AuthService } from 'modules/auth/services';
import { NavigationService } from 'router/navigation.service';

import { ACTION_PREFIX } from './actionPrefix.const';
import { DESTROY_STORE_ACTION } from './actions.const';

interface ICheckUserArgs {
  history: THistory;
  subdomain?: string;
}

export const checkUserThunk = createAsyncThunk(
  `${ACTION_PREFIX.USER}/check`,
  async (args: ICheckUserArgs, thunkAPI) => {
    try {
      if (!getFromCookies(CSRF_TOKEN_NAME)) {
        authApi.getScrfToken();
      }

      const { data } = await authApi.getUser(args?.subdomain);

      AuthService.setAuthorizedStatus();

      return data;
    } catch (e: any) {
      args.history.push(ROUTER_PATH.LOGIN);

      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const getUserInfo = async (args: ICheckUserArgs) => {
  try {
    if (!getFromCookies(CSRF_TOKEN_NAME)) {
      authApi.getScrfToken();
    }

    const { data } = await authApi.getUser(args.subdomain);

    AuthService.setAuthorizedStatus();

    return { payload: data };
  } catch (e: any) {
    args.history.push(ROUTER_PATH.LOGIN);
  }
};

// In order to avoid cycled dependencies
// we store this thunk here.
export const logoutUserThunk = createAsyncThunk(`${ACTION_PREFIX.AUTH}/logout`, async (_, thunkAPI) => {
  const data = await authApi.logout();

  if (data) {
    thunkAPI.dispatch({ type: DESTROY_STORE_ACTION });
    localStorage.removeItem(IS_AUTHORIZED_LS_KEY);
    sessionStorage.removeItem(IS_SSO_AND_SHOW_LOAN_VARIABLES_DIALOG);
    NavigationService.navigateToLogin();
  }
});
