import React, { FC } from 'react';
import { Badge, Stock } from 'modules/core/components';
import { useVehicleStatusBadgeProps } from 'modules/core/hooks';
import { IStock } from 'modules/core/components/Stock/Stock';
import { useSelector } from 'react-redux';
import { getUser } from 'store/selectors/auth.selector';

import { VEHICLE_STATUS_BADGE_PROPS } from '../../Badge/common.const';

interface IStockCell extends IStock {
  age: number | null;
  isNew?: boolean;
  isSold?: boolean;
}

export const StockCell: FC<IStockCell> = ({ stock, isSold, age, isNew }) => {
  const badgeProps = useVehicleStatusBadgeProps(age, isNew, isSold);
  const user = useSelector(getUser);

  return (
    <>
      <Stock stock={stock} />
      <br />
      {badgeProps && <Badge {...badgeProps} />}
      {user?.is_sso && <Badge className="make-it-work-badge" {...VEHICLE_STATUS_BADGE_PROPS.MAKE_IT_WORK} />}
    </>
  );
};
