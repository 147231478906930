import i18n from 'i18n';

export const DEFAULT_NET_TRADE_IN_VALUES = {
  netTradeInForm: {
    netTradeInTotal: undefined,
    tradeInValue: undefined,
    amountOwnedOnTrade: undefined,
    lienHolder: undefined,
    year: undefined,
    make: undefined,
    model: undefined,
    otherYear: undefined,
    otherMake: undefined,
    otherModel: undefined,
  },
};

export const OTHER_OPTION = {
  title: i18n.t('components.loanVariablesModal.modals.netTradeIn.options.other'),
  value: 'other',
};
