import { IOption } from 'modules/core/types/select';
import i18n from 'i18next';

export const SUBVENTION_OPTIONS: IOption[] = [
  {
    title: i18n.t('common.buttons.no'),
    value: 'false',
  },
  {
    title: i18n.t('common.buttons.yes'),
    value: 'true',
  },
];

export const DEFAULT_SUBVENTION_YEAR = 2016;
