import { useSelector } from 'react-redux';
import { getUser } from 'store/selectors/auth.selector';

import { DEFAULT_HELP_EMAIL } from '../constants';

export const useContactAdministratorLink = (): string => {
  const user = useSelector(getUser);

  const administratorEmail = user?.tenant?.admin?.email || DEFAULT_HELP_EMAIL;

  return `mailto:${administratorEmail}`;
};
