import React, { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconSuccess } from 'modules/core/components';
import { ButtonVariant } from 'modules/core/enums';
import { STATIC_IMAGE_URLS } from 'modules/core/constants';
import { ModalButton } from 'components/ModalDialog/style';
import { useSelector } from 'react-redux';
import { getDefaultVehiclePhoto } from 'store/selectors/vehicle.selector';

import { IVehicleAdjustRetail } from '../types';
import { GeneralVehicleInfo, Header, ImageUI as Image, VehicleModel } from './styles';

interface IDialogHeader {
  data: IVehicleAdjustRetail;
  resetForm: VoidFunction;
  isVendorResponseError?: boolean;
  areLogsExist: boolean;
}

export const DialogHeader: FC<IDialogHeader> = ({ data, resetForm, areLogsExist, isVendorResponseError }) => {
  const { t } = useTranslation();
  const defaultVehiclePhoto = useSelector(getDefaultVehiclePhoto);
  const [imageSrc, setImageSrc] = useState<string>(data?.thumbnails?.[0]);

  const handleError = () => {
    if (defaultVehiclePhoto) setImageSrc(defaultVehiclePhoto);
    else setImageSrc(STATIC_IMAGE_URLS.icons.noPhoto);
  };

  const resetButton = useMemo(
    () =>
      !isVendorResponseError && (
        <ModalButton buttontheme={ButtonVariant.OUTLINE} onClick={resetForm}>
          {t('common.buttons.reset')}
        </ModalButton>
      ),
    [isVendorResponseError, resetForm, t],
  );

  return (
    <Header>
      <GeneralVehicleInfo>
        <Image src={imageSrc} onError={handleError} />
        <VehicleModel>
          <span>{data?.description}</span>
          <span>{`${t('components.vehicleAdjustmentDialog.vin')} ${data?.vin}`}</span>
        </VehicleModel>
        {areLogsExist && <IconSuccess />}
      </GeneralVehicleInfo>
      {resetButton}
    </Header>
  );
};
