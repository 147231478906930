/* eslint-disable react/jsx-one-expression-per-line */
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Container, VehicleColor } from './styles';

export const MonthlyPaymentWarning: FC = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <span>{t('components.monthlyPaymentWarning.warning')}</span>
      <VehicleColor>{t('components.monthlyPaymentWarning.colorVehicles')}</VehicleColor>{' '}
      <span>{t('components.monthlyPaymentWarning.text')}</span>
    </Container>
  );
};
