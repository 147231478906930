import {
  FIELD_NAMES,
  INCOME_CALCULATOR_HEADERS_MAPPER,
  INCOME_CALCULATOR_ROWS_MAPPER,
  INCOME_CALCULATOR_TYPE_FORMATTERS,
  MAP_FIELDS_BY_TYPE,
} from 'components/IncomeCalculator/const';
import { IIncomeReportPayload, IIncomeReportHeaders } from 'modules/core/types';
import { IIncomeForm } from 'components/IncomeCalculator/types';

import { filterNullUndefinedZeroNotNumberValues } from '../object';

const prepareHeaderData = (data: Record<string, unknown>[]): IIncomeReportHeaders[] => {
  const incomeCalculatorFields = new Set(data.flatMap(Object.keys));

  return Object.entries(INCOME_CALCULATOR_HEADERS_MAPPER).reduce(
    (headers: IIncomeReportHeaders[], [field, header]) =>
      incomeCalculatorFields.has(field) ? [...headers, { ...header, prop: field }] : headers,
    [],
  );
};

const prepareRowData = (row: Record<string, unknown>): Record<string, unknown> =>
  Object.entries(row).reduce((preparedRowData, [field, value]) => {
    const { datatype } = INCOME_CALCULATOR_HEADERS_MAPPER[field] || {};

    const mappedValue = INCOME_CALCULATOR_ROWS_MAPPER[field]?.[value as string] || value;
    const formattedValue = INCOME_CALCULATOR_TYPE_FORMATTERS.get(datatype)?.(mappedValue) || mappedValue;

    return { ...preparedRowData, [field]: formattedValue };
  }, {});

export const toIncomeCalculatorReportData = (incomeForm: IIncomeForm): IIncomeReportPayload => {
  const filteredIncomeCalculatorData = incomeForm.savedIncomes.map(filterNullUndefinedZeroNotNumberValues);

  const fieldNames = MAP_FIELDS_BY_TYPE.get(filteredIncomeCalculatorData[0].type as string)?.map(({ name }) => name);

  fieldNames?.push(FIELD_NAMES.CATEGORY, FIELD_NAMES.TYPE, FIELD_NAMES.INCOME);

  const filteredIncomeDataByType = Object.fromEntries(
    Object.entries(filteredIncomeCalculatorData[0]).filter(([key, _]) => !!fieldNames?.includes(key)),
  );

  return {
    headers: prepareHeaderData([filteredIncomeDataByType]),
    rows: filteredIncomeCalculatorData.map(prepareRowData),
    total_income: Number(incomeForm.totalIncome.toFixed(2)),
  };
};
