import React, { memo, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Table, VehicleTableActions } from 'modules/core/components';
import { TablePlaceholderContent } from 'modules/core/components/Table/TablePlaceholderContent';
import { VehicleFilters } from 'modules/core/components/VehicleFilters';
import { VehicleDetailsDialog } from 'components/Modals/VehicleDetailsDialog';
import { VehicleAdjustmentDialog } from 'components/Modals/VehicleAdjustmentDialog';
import { SendReportDialog } from 'components/Modals/SendReportDialog';
import { useModalState, useTablePagination } from 'modules/core/hooks';
import { MODAL_DIALOG_ID } from 'modules/core/constants';
import {
  ILenderVehicle,
  IRACVehicle,
  IVehicle,
  TFetchingVehicleFlagsSelector,
  TReportSelector,
} from 'modules/core/types';
import { TableDropdowns } from 'modules/ProbabilityEstimator/styles';
import { useTableCallback } from 'modules/ProbabilityEstimator/hooks';
import { TableName, VehicleTablePlaceholderType } from 'modules/core/enums';
import { ITablePlaceholder } from 'modules/core/components/Table/TablePlaceholderContent/TablePlaceholderContent';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { TableData } from 'modules/core/components/Table/types';
import { getIsMissingCostInfo } from 'store/selectors/ui.selector';
import { useSelector } from 'react-redux';
import {
  LENDER_VEHICLE_TABLE_COLUMN_ID,
  RAC_VEHICLE_TABLE_COLUMN_ID,
  VEHICLE_TABLE_COLUMN_ID,
} from 'modules/core/components/VehicleTable/defaultVehicleTableColumns';

import { useTablePlaceholderType } from './hooks';
import { Container } from './styles';
import { PROBABILITY_ESTIMATOR_FIELDS } from '../ProbabilityEstimator/probabilityEstimator.const';
import { useColumns } from './hooks/useColumns';
import { useSortedData } from './hooks/useSortedData';
import { IRouteOneData } from '../core/types/routeOne';

interface IVehicleSearch {
  data: IVehicle[] | IRACVehicle[] | ILenderVehicle[];
  reportDataSelector?: TReportSelector;
  tableSubmitCallback: VoidFunction;
  previewVehiclesCount?: number;
  defaultPlaceholderTitle: string;
  previewPlaceholder: string;
  noColoring?: boolean;
  isTableFiltersDisabled?: boolean;
  isTableActionsDisabled?: boolean;
  isTableView: boolean;
  setIsTableView: ActionCreatorWithPayload<boolean, string>;
  areRequiredFieldsFilledIn?: boolean;
  fetchingVehicleFlagsSelector: TFetchingVehicleFlagsSelector;
  CustomVehicleCard?: any;
  tableName: TableName;
  isFilterByStockNumber?: boolean;
  notFoundVehicles?: string[];
  notAvailableVehicles?: string[];
  isValidStock: boolean;
  totalCount?: number;
  fetchData?: any;
  isServerPagination?: boolean;
  selectedRows?: TableData[];
  skipAutoStateReset?: boolean;
  handleOpenDialog?: () => void;
  isRac?: boolean;
  showNoVehicleErrorMessage?: boolean;
  showNoVehicleInInventoryErrorMessage?: boolean;
  isMakeItWork?: boolean;
  routeOneData?: IRouteOneData;
  tier?: number;
}

const VehicleSearchTable: React.FC<IVehicleSearch> = ({
  data,
  reportDataSelector,
  tableSubmitCallback,
  previewVehiclesCount,
  defaultPlaceholderTitle,
  previewPlaceholder,
  noColoring,
  CustomVehicleCard,
  isTableFiltersDisabled,
  isTableActionsDisabled,
  isTableView,
  setIsTableView,
  areRequiredFieldsFilledIn,
  fetchingVehicleFlagsSelector,
  tableName,
  isFilterByStockNumber,
  notAvailableVehicles,
  notFoundVehicles,
  isValidStock,
  totalCount,
  fetchData,
  isServerPagination = false,
  selectedRows,
  skipAutoStateReset = false,
  handleOpenDialog,
  isRac = false,
  showNoVehicleErrorMessage = false,
  showNoVehicleInInventoryErrorMessage = false,
  isMakeItWork = false,
  routeOneData = {},
  tier,
}) => {
  const { t } = useTranslation();
  const { pageCount, paginationCallback } = useTablePagination(data, totalCount, fetchData, isServerPagination);
  const isCostMissing = useSelector(getIsMissingCostInfo);
  const tableCallbacks = useTableCallback();
  const {
    watch,
    formState: { isValid },
  } = useFormContext();

  const selectedTableView = watch(PROBABILITY_ESTIMATOR_FIELDS.TABLE_VIEW);

  const { columns, defaultColumns, customerFriendlyColumns } = useColumns(selectedTableView, tableName, data);
  const { sortedData } = useSortedData(selectedTableView, tableName, data, isServerPagination);

  const currentColumns = useMemo(() => {
    const hiddenColumns = [
      RAC_VEHICLE_TABLE_COLUMN_ID.PROFIT,
      RAC_VEHICLE_TABLE_COLUMN_ID.UNIT_COST,
      LENDER_VEHICLE_TABLE_COLUMN_ID.ESTIMATED_PROFIT,
      VEHICLE_TABLE_COLUMN_ID.DEALER_PROFIT,
    ];

    return isCostMissing ? columns.filter((column) => !hiddenColumns.includes(column.id)) : columns;
  }, [columns, isCostMissing]);

  const renderAdditionalTableHeaderComponents = () => (
    <TableDropdowns>
      {reportDataSelector && (
        <VehicleTableActions
          reportDataSelector={reportDataSelector}
          closeDropDown={isTableActionsDisabled}
          isDisabled={isTableActionsDisabled}
        />
      )}
    </TableDropdowns>
  );

  const {
    handleCloseDialog: handleCloseVehicleDetailsDialog,
    isModalDialogActive: isVehicleDetailsDialogActive,
  } = useModalState([MODAL_DIALOG_ID.VEHICLE_DETAILS]);

  const {
    handleCloseDialog: handleCloseVehicleAdjustmentDialog,
    isModalDialogActive: isVehicleAdjustmentDialogActive,
  } = useModalState([MODAL_DIALOG_ID.VEHICLE_ADJUSTMENT]);

  const {
    handleCloseDialog: handleCloseSendReportDialog,
    isModalDialogActive: isSendReportDialogActive,
  } = useModalState([MODAL_DIALOG_ID.SEND_REPORT]);

  const isValidInputData = areRequiredFieldsFilledIn !== undefined ? areRequiredFieldsFilledIn : isValid;

  const tablePlaceholderType = useTablePlaceholderType(
    isValidInputData,
    data,
    fetchingVehicleFlagsSelector,
    previewVehiclesCount,
    isValidStock,
  );

  const tablePlaceholder = useMemo(() => {
    const tablePlaceholderMapProps = new Map<VehicleTablePlaceholderType, ITablePlaceholder>([
      [VehicleTablePlaceholderType.ERROR, { title: t('common.errorMessages.failedRequestMessage') }],
      [VehicleTablePlaceholderType.LOADING, { isFetching: true }],
      [VehicleTablePlaceholderType.DEFAULT, { title: defaultPlaceholderTitle }],
      [VehicleTablePlaceholderType.SEARCH, { title: previewPlaceholder }],
      [VehicleTablePlaceholderType.NEED_STOCK, { title: t('components.probabilityEstimator.needStock') }],
    ]);

    const tablePlaceholderProps = tablePlaceholderMapProps.get(tablePlaceholderType);

    return <TablePlaceholderContent {...tablePlaceholderProps} />;
  }, [t, defaultPlaceholderTitle, previewPlaceholder, tablePlaceholderType]);

  return (
    <Container>
      <Table
        handleOpenDialog={handleOpenDialog}
        tableCallbacks={tableCallbacks}
        data={sortedData}
        columns={currentColumns}
        defaultColumns={defaultColumns}
        customerFriendlyColumns={customerFriendlyColumns}
        pageCount={pageCount}
        fetchRequestCallback={paginationCallback}
        title={t('components.table.recommendedVehicles')}
        renderAdditionalTableHeaderComponents={renderAdditionalTableHeaderComponents}
        selectable
        withCardView
        {...(sortedData.length && isValidInputData ? {} : { tablePlaceholder })}
        tablePlaceholderType={tablePlaceholderType}
        isTableFiltersDisabled={isTableFiltersDisabled}
        isTableActionsDisabled={isTableActionsDisabled}
        isTableView={isTableView}
        setIsTableView={setIsTableView}
        noColoring={noColoring}
        CustomVehicleCard={CustomVehicleCard}
        totalCount={totalCount}
        isServerPagination={isServerPagination}
        selectedRows={selectedRows}
        skipAutoStateReset={skipAutoStateReset}
        isRac={isRac}
        isLender={tableName === TableName.LENDER}
        showNoVehicleErrorMessage={showNoVehicleErrorMessage}
        showNoVehicleInInventoryErrorMessage={showNoVehicleInInventoryErrorMessage}
        isMakeItWork={isMakeItWork}
        routeOneData={routeOneData as IRouteOneData}
        tier={tier}
      >
        <VehicleFilters
          isDisabled={isTableFiltersDisabled}
          isFilterByStockNumber={isFilterByStockNumber}
          notAvailableVehicles={notAvailableVehicles}
          notFoundVehicles={notFoundVehicles}
          isRac={isRac}
          isLender={tableName === TableName.LENDER}
        />
      </Table>
      <VehicleDetailsDialog show={isVehicleDetailsDialogActive} onHide={handleCloseVehicleDetailsDialog()} />
      <VehicleAdjustmentDialog
        show={isVehicleAdjustmentDialogActive}
        onHide={handleCloseVehicleAdjustmentDialog()}
        onApplyCallback={tableSubmitCallback}
      />
      {reportDataSelector && (
        <SendReportDialog
          show={isSendReportDialogActive}
          onHide={handleCloseSendReportDialog()}
          reportDataSelector={reportDataSelector}
        />
      )}
    </Container>
  );
};

export const VehicleSearchTableMemo = memo(VehicleSearchTable);
