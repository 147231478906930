import { Option } from 'react-multi-select-component/dist';

import { IBase, IOption, OptionValue } from '../types';

export const getFirstOptionValue = (options: IOption[]): OptionValue => options[0].value;

export const toOptions = (options: IBase[], valueKey: keyof IBase = 'name'): IOption[] =>
  options.map((option) => ({ title: option.name, value: option[valueKey] }));

export const toMultiselectOptions = (options: IBase[]): Option[] =>
  options.map(({ id, name }) => ({ label: name, value: id }));
