// In order to prevent cycled dependencies
// this code has been temporarily moved here.
import i18n from 'i18n';
import React from 'react';

import { FormField, IField } from '../types';
import { Dropdown, Input, PercentAdornment } from '../components';
import { EMPTY_STRING } from '../constants';
import {
  LTV_TAXES_FEES_FILTER_OPTIONS,
  JDP_VENDOR_FILTER_OPTIONS,
  PREFERENCES_FIELD_NAMES,
  PREFERENCES_PE_FIELD_NAMES,
} from './preferences.const';
import { requiredValidator } from '../validators';

export const COMMON_PREFERENCES_FIELDS: IField[] = [
  {
    name: PREFERENCES_FIELD_NAMES.LTV_TAXES_FEES_FILTER,
    component: Dropdown as FormField,
    controlProps: { options: LTV_TAXES_FEES_FILTER_OPTIONS },
    label: EMPTY_STRING,
  },
];

export const PREFERENCES_FIELDS: IField[] = [
  {
    name: PREFERENCES_FIELD_NAMES.VENDOR_FIELD,
    component: Dropdown as FormField,
    controlProps: { options: JDP_VENDOR_FILTER_OPTIONS },
    label: EMPTY_STRING,
  },
  ...COMMON_PREFERENCES_FIELDS,
];

export const PE_PREFERENCES_FIELDS: IField[] = [
  {
    label: i18n.t('components.preferences.labels.ltvHighLimit'),
    name: PREFERENCES_PE_FIELD_NAMES.LVT_HIGH_LIMIT,
    component: (props) => <Input {...props} />,
    controlProps: {
      endAdornment: <PercentAdornment />,
      decimalScale: 3,
      isRequired: true,
      rules: requiredValidator,
      placeholder: i18n.t('components.input.placeholders.percent'),
    },
  },
  ...PREFERENCES_FIELDS,
];
