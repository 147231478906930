import React, { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Column, ColumnInstance } from 'react-table';
import { PermissionWrapper } from 'modules/PermissionWrapper';
import { PERMISSIONS } from 'modules/PermissionWrapper/permissions.const';
import { useFormContext } from 'react-hook-form';
import { PROBABILITY_ESTIMATOR_FIELDS } from 'modules/ProbabilityEstimator/probabilityEstimator.const';

import { Divider, DividerMode } from '../../Divider';
import { AdjustMenuItemTitle, AdjustViewMenuItem } from './styles';

interface IAdditionalAdjustItems {
  tableColumns: ColumnInstance[];
  allColumns: ColumnInstance[];
  defaultColumns: Column<Record<string, unknown>>[];
  customerFriendlyColumns: string[];
  toggleHideAllColumns: (value: boolean) => void;
  setSelectedColumns: () => void;
}

export const AdditionalAdjustItems: FC<IAdditionalAdjustItems> = ({
  tableColumns,
  defaultColumns,
  toggleHideAllColumns,
  customerFriendlyColumns,
  setSelectedColumns,
  allColumns,
}) => {
  const { t } = useTranslation();
  const { setValue } = useFormContext();
  const areAllColumnsSelected = tableColumns.every(({ isVisible }) => isVisible);

  const areDefaultColumnsSelected = tableColumns.every(
    (col) => (defaultColumns.find(({ id }) => id === col.id)?.isVisible || false) === col.isVisible,
  );

  const areCustomerFriendlyColumnsSelected = tableColumns.every((col) =>
    customerFriendlyColumns.find((id) => id === col.id) ? col.isVisible : !col.isVisible,
  );

  const hideAllColumns = useCallback(() => {
    allColumns.forEach(({ toggleHidden }) => {
      toggleHidden(true);
    });
  }, [allColumns]);

  const handleSelectAllColumns = useCallback(() => {
    setSelectedColumns();
    toggleHideAllColumns(areAllColumnsSelected);
  }, [areAllColumnsSelected, toggleHideAllColumns, setSelectedColumns]);

  const handleSelectDefaultColumns = useCallback(() => {
    setSelectedColumns();

    if (areDefaultColumnsSelected) {
      hideAllColumns();

      return;
    }

    tableColumns.forEach(({ id, toggleHidden }) => {
      toggleHidden(!defaultColumns.find((col) => col.id === id)?.isVisible);
    });
  }, [tableColumns, defaultColumns, areDefaultColumnsSelected, hideAllColumns, setSelectedColumns]);

  const handleCustomerFriendlyColumns = useCallback(() => {
    setSelectedColumns();

    if (areCustomerFriendlyColumnsSelected) {
      hideAllColumns();

      return;
    }

    tableColumns.forEach(({ id, toggleHidden }) => {
      toggleHidden(!customerFriendlyColumns.find((colId) => colId === id));
    });
  }, [tableColumns, customerFriendlyColumns, areCustomerFriendlyColumnsSelected, hideAllColumns, setSelectedColumns]);

  useEffect(() => {
    setValue(PROBABILITY_ESTIMATOR_FIELDS.ARE_DEFAULT_COLUMNS_SELECTED, areDefaultColumnsSelected);
  }, [areDefaultColumnsSelected, setValue]);

  return (
    <div>
      <AdjustViewMenuItem>
        <input
          type="checkbox"
          onChange={handleSelectAllColumns}
          checked={areAllColumnsSelected}
          data-testId="select-all"
        />
        <AdjustMenuItemTitle>{t('components.table.selectAll')}</AdjustMenuItemTitle>
      </AdjustViewMenuItem>
      <PermissionWrapper allowedRoles={PERMISSIONS.ADJUST_VIEW_DEFAULT_SETTINGS}>
        <AdjustViewMenuItem>
          <input type="checkbox" onChange={handleSelectDefaultColumns} checked={areDefaultColumnsSelected} />
          <AdjustMenuItemTitle>{t('components.table.adjustView.defaultSettings')}</AdjustMenuItemTitle>
        </AdjustViewMenuItem>
      </PermissionWrapper>
      <PermissionWrapper allowedRoles={PERMISSIONS.ADJUST_VIEW_CUSTOMER_FRIENDLY_SETTINGS}>
        <AdjustViewMenuItem>
          <input
            type="checkbox"
            onChange={handleCustomerFriendlyColumns}
            checked={areCustomerFriendlyColumnsSelected}
          />
          <AdjustMenuItemTitle>{t('components.table.adjustView.customerFriendly')}</AdjustMenuItemTitle>
        </AdjustViewMenuItem>
      </PermissionWrapper>
      <Divider mode={DividerMode.BLUE} />
    </div>
  );
};
