import styled from 'styled-components';
import { COLORS } from 'modules/core/theme/colors';
import { Table } from 'react-bootstrap';

export const TableWrapper = styled(Table)`
  tbody,
  td,
  tfoot,
  th,
  thead,
  tr {
    border-style: hidden;
  }

  background-color: ${COLORS.GREY_10};
`;

export const TableHeader = styled.thead`
  position: sticky;
  top: -3px;
  color: ${COLORS.GREY_24};
  font-weight: bold;

  tr th:nth-child(2) {
    width: 12%;
  }
  tr th:nth-child(3),
  tr th:nth-child(4),
  tr th:nth-child(5),
  tr th:nth-child(6) {
    width: 13%;
  }
  tr th:nth-child(7) {
    width: 16%;
  }
  tr th:first-child {
    width: 1%;
  }
  tr th:last-child {
    width: 1%;
  }
`;

export const TableBody = styled.tbody<{ isAllColumns: boolean }>`
  td {
    text-align: center;
    vertical-align: middle;
  }
  tr:nth-child(2n - 1) {
    background-color: #e6e5e4;
  }
  tr:first-child {
    font-weight: bold;
    outline: 2px solid #cdac36;
    outline-offset: -2px;
  }
  tr:first-child td:first-child:before {
    content: '\u25CF';
    margin-right: ${({ isAllColumns }) => (!isAllColumns ? `-60px` : `-20px`)};
  }
`;

export const TableHeaderCell = styled.th`
  text-align: center;
  font-size: 15px;
  vertical-align: middle;
`;

export const TableCell = styled.td``;
