import { createGlobalStyle } from 'styled-components';
import { COLORS } from 'modules/core/theme/colors';

import { WindowBreakpoints } from '../enums/windowBreakpoints.enum';
import { RUBIK_FONT_FAMILY, DEFAULT_FONT_SIZE } from './typography';

export const GlobalStyles = createGlobalStyle`
  html, body {
    font-family: ${RUBIK_FONT_FAMILY};
    font-size: ${DEFAULT_FONT_SIZE};
  }

  input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none;
  }

  .modal.show {
    padding: 0 10px !important;

    @media (max-width: ${WindowBreakpoints.small}px) {
      padding-left: 10px;
    }
  }

  .card-body {
    @media (max-width: ${WindowBreakpoints.small}px) {
      padding: 1rem;
    }
  }

  .accordion {
    max-width: 100%;
  }

  .rmsc {
    --rmsc-h: 32px;
    --rmsc-radius: .375rem;
    --rmsc-border: ${COLORS.GREY_18};
  }

  .rmsc.multi-select {
    @media (min-width: ${WindowBreakpoints.xxxLarge + 1}px) {
      max-width: 145px;
    }
  }

  .rmsc .dropdown-container:focus-within {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
  }

  .rmsc .dropdown-container[aria-disabled="true"] {
    background-color: #e9ecef;
  }

  .rmsc .item-renderer {
    align-items: center;
  }

  .rmsc .gray {
    color: black;
  }

  .input-group-sm>.btn,
  .input-group-sm>.form-control,
  .input-group-sm>.form-select,
  .input-group-sm>.input-group-text {
    border-radius: .375rem;
  }
`;
