import { STATIC_IMAGE_URLS } from 'modules/core/constants';
import React from 'react';
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { LoginContent, PositionCard, CardImage, CardTitle, CardForm } from '../styles';
import { LoginForm } from './LoginForm';

const { logo } = STATIC_IMAGE_URLS;

export const Login: React.FC = () => {
  const { t } = useTranslation();

  return (
    <LoginContent>
      <PositionCard>
        <CardForm>
          <CardImage src={logo} />
          <Card.Body>
            <CardTitle>{t('components.login.title')}</CardTitle>
            <LoginForm />
          </Card.Body>
        </CardForm>
      </PositionCard>
    </LoginContent>
  );
};
