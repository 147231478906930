import { IApprovedLoanInput } from 'modules/ApprovedLoanInput/types';
import { IFetchingVehicleFlags, IVehicle } from 'modules/core/types';
import { toIncomeCalculatorReportData } from 'modules/core/utils/selectors/incomeReport';
import { toApprovedLoanInputReportData } from 'modules/core/utils';
import { createSelector } from '@reduxjs/toolkit';
import { IApprovedLoanState } from 'store/reducers/approvedLoan.reducer';
import { IIncomeForm } from 'components/IncomeCalculator/types';

import { RootState } from '../store';
import { getSelectedRows, getTableVisibleColumns } from './probabilityEstimator.selector';

const getApprovedLoanInputState = (state: RootState): IApprovedLoanState => state.approvedLoanInput;

export const getApprovedLoanInputInitialValues = (state: RootState): IApprovedLoanInput =>
  getApprovedLoanInputState(state).initialValues;

export const getIncome = (state: RootState): IIncomeForm => getApprovedLoanInputState(state).income;

export const getVehicleFetchingFlags = (state: RootState): IFetchingVehicleFlags => {
  const { isFetchingVehicles, isFetchingVehiclesFailed, isReset } = getApprovedLoanInputState(state);

  return { isFetchingVehicles, isFetchingVehiclesFailed, isReset };
};

export const getVehiclesByParams = (state: RootState): IVehicle[] | undefined =>
  getApprovedLoanInputState(state).vehicles.vehiclesByParams;

export const getVehiclesByStock = (state: RootState): IVehicle[] | undefined =>
  getApprovedLoanInputState(state).vehicles.vehiclesByStock;

export const getIsTableView = (state: RootState): boolean => getApprovedLoanInputState(state).isTableView;

export const getNotFoundVehicles = (state: RootState): string[] => getApprovedLoanInputState(state).notFoundVehicles;

export const getNotAvailableVehicles = (state: RootState): string[] =>
  getApprovedLoanInputState(state).notAvailableVehicles;

// TODO: Move getSelectedRows/getTableVisibleColumns selectors from probabilityEstimator scope
export const getApprovedLoanInputReportData = createSelector(
  getApprovedLoanInputInitialValues,
  getSelectedRows,
  getTableVisibleColumns,
  toApprovedLoanInputReportData,
);

export const getIncomeCalculatorReportData = createSelector(getIncome, toIncomeCalculatorReportData);
