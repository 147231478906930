import { COLORS } from 'modules/core/theme/colors';
import { LoanStatusColors } from 'modules/core/enums';
import { ILoanStatusGradientBackgroundProps } from 'modules/core/types';

export const LOAN_STATUS_BACKGROUNDS: Record<string, ILoanStatusGradientBackgroundProps> = {
  RED: {
    from: COLORS.WHITE_6,
    to: COLORS.RED_5,
  },
  YELLOW: {
    from: COLORS.WHITE_7,
    to: COLORS.YELLOW_6,
  },
  GREEN: {
    from: COLORS.WHITE_8,
    to: COLORS.GREEN_9,
  },
};

export const LOAN_STATUS_GRADIENT_BACKGROUND_COLORS_MAP = new Map<LoanStatusColors, ILoanStatusGradientBackgroundProps>(
  [
    [LoanStatusColors.RED, LOAN_STATUS_BACKGROUNDS.RED],
    [LoanStatusColors.YELLOW, LOAN_STATUS_BACKGROUNDS.YELLOW],
    [LoanStatusColors.GREEN, LOAN_STATUS_BACKGROUNDS.GREEN],
  ],
);
