import { useFormContext } from 'react-hook-form';
import { Column } from 'react-table';
import { FEES_AND_TAXES_INDEX_FIELD } from 'modules/core/shared';

import {
  BANK_FEES_TABLE_COLUMN,
  BANK_FEES_CLICKABLE_TABLE_COLUMN,
} from '../../core/components/VehicleTable/vehicleTableColumns';

export const useColumnsByBankFees = (
  columns: Column<Record<string, unknown>>[],
  isClickable: boolean = false,
): Column<Record<string, unknown>>[] => {
  const { watch } = useFormContext();
  const { bankFees, bankDiscount } = watch(FEES_AND_TAXES_INDEX_FIELD);

  if (bankFees || bankDiscount) {
    const currentColumns = [...columns];
    const bankFeesColumn = isClickable ? BANK_FEES_CLICKABLE_TABLE_COLUMN : BANK_FEES_TABLE_COLUMN;

    currentColumns.splice(currentColumns.length - 2, 0, bankFeesColumn);

    return currentColumns;
  }

  return columns;
};
