import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { STATIC_IMAGE_URLS, STATIC_VIDEOS_URLS } from 'modules/core/constants';

import { LearningWrapper } from '../common/LearningWrapper/LearningWrapper';
import { LoanValueTable } from '../common/LoanValueTable/LoanValueTable';
import { ExtendedCardForm, CardForm, TitleH1 } from '../styles';
import { LEARNING_PATHS } from '../Learning.const';
import { VehicleMiniCard } from '../common/VehicleMiniCard/VehicleMiniCard';

export const LearningLoanToValue: React.FC = () => {
  const { t } = useTranslation();

  return (
    <LearningWrapper prevPageLink={LEARNING_PATHS.affordability} nextPageLink={LEARNING_PATHS.lengthOfLoan}>
      <TitleH1>{t('components.learningLink.loanToValue.title')}</TitleH1>
      {t('components.learningLink.loanToValue.description')}
      <CardForm>
        <VehicleMiniCard
          imgSrc={STATIC_IMAGE_URLS.cars.chevrolet}
          title={t('components.learningLink.loanToValue.miniCard.title')}
          price={t('components.learningLink.loanToValue.miniCard.price')}
          description={t('components.learningLink.loanToValue.miniCard.description')}
        />
        <video autoPlay muted playsInline>
          <source src={STATIC_VIDEOS_URLS.loanValueSlider} type="video/mp4" />
        </video>
        <p>{t('components.learningLink.loanToValue.cardDescription1')}</p>
        <Trans defaults={t('components.learningLink.loanToValue.cardDescription2')} components={[<strong />]} />
      </CardForm>
      <ExtendedCardForm>
        <p>{t('components.learningLink.loanToValue.cardDescription3')}</p>
        <LoanValueTable />
      </ExtendedCardForm>
    </LearningWrapper>
  );
};
