import { EMPTY_STRING } from 'modules/core/constants';
import { IVehicleFiltersForm } from 'modules/core/types';
import { VehicleType } from 'modules/core/enums';
import { FilterView } from 'modules/core/enums/filterView.enum';

const VEHICLES_FILTER_INDEX_NAME = 'vehicleFilters';
const DEFAULT_DIFFERENCE_FROM_CURRENT_YEAR = 10;

export const FILTERS_FIELD_NAMES = {
  STORES: `${VEHICLES_FILTER_INDEX_NAME}.stores`,
  VEHICLE_TYPE: `${VEHICLES_FILTER_INDEX_NAME}.vehicleType`,
  VEHICLES: `${VEHICLES_FILTER_INDEX_NAME}.vehicles`,
  MAKES: `${VEHICLES_FILTER_INDEX_NAME}.makes`,
  MODELS: `${VEHICLES_FILTER_INDEX_NAME}.models`,
  MILES: `${VEHICLES_FILTER_INDEX_NAME}.miles`,
  ODOMETER_MILES: `${VEHICLES_FILTER_INDEX_NAME}.odometerMiles`,
  YEARS: `${VEHICLES_FILTER_INDEX_NAME}.years`,
  YEARS_VALUE: `${VEHICLES_FILTER_INDEX_NAME}.yearsValue`,
  AGES: `${VEHICLES_FILTER_INDEX_NAME}.ages`,
  MINIMUM_MONTHLY_PAYMENT: `${VEHICLES_FILTER_INDEX_NAME}.minMonthlyPayment`,
  DRIVE_TYPE: `${VEHICLES_FILTER_INDEX_NAME}.driveType`,
  PASSENGER_CAPACITY: `${VEHICLES_FILTER_INDEX_NAME}.passengerCapacity`,
  EXTRA_CASH: `${VEHICLES_FILTER_INDEX_NAME}.extraCash`,
};

export const FILTER_OPTION_VALUES = {
  VEHICLE_TYPE: {
    USED: VehicleType.USED,
    NEW: VehicleType.NEW,
    ALL: VehicleType.ALL,
  },
  MILES: {
    FILTER_MILES: EMPTY_STRING,
    UP_TO: 'odometer__lte',
    AT_LEAST: 'odometer__gte',
  },
  YEARS: {
    FILTER_YEARS: EMPTY_STRING,
    NEWER_THEN_FROM: 'year__gte',
    OLDER_THEN_FROM: 'year__lte',
    ONLY_FORM: 'year',
  },
  AGES: {
    LESS_10_DAYS: 'less_10',
    MORE_10_DAYS: 'more_10',
    MORE_20_DAYS: 'more_20',
    MORE_30_DAYS: 'more_30',
    MORE_60_DAYS: 'more_60',
    MORE_90_DAYS: 'more_90',
  },
  EXTRA_CASH: {
    NO_EXTRA_CASH: 'no_extra_cash',
    UP_TO_1000: 'up_to_1000',
    UP_TO_2000: 'up_to_2000',
    UP_TO_3000: 'up_to_3000',
    UP_TO_4000: 'up_to_4000',
    UP_TO_5000: 'up_to_5000',
  },
  DOMESTIC: 'Domestic',
  IMPORT: 'Import',
  DRIVE_TYPE: {
    AWD: 'AWD',
    FWD: 'FWD',
    RWD: 'RWD',
    FOUR_WD: '4WD',
    TWO_WD: '2WD',
  },
  PASSENGER_CAPACITY: {
    1: '1',
    2: '2',
    3: '3',
    4: '4',
    5: '5',
    6: '6',
    7: '7',
    8: '8',
    9: '9',
  },
};

export const VEHICLE_FILTERS_INITIAL_VALUES: IVehicleFiltersForm = {
  odometerMiles: 130000,
  yearsValue: (new Date().getFullYear() - DEFAULT_DIFFERENCE_FROM_CURRENT_YEAR).toString(),
  miles: FILTER_OPTION_VALUES.MILES.UP_TO,
  years: FILTER_OPTION_VALUES.YEARS.NEWER_THEN_FROM,
  minMonthlyPayment: 0,
  ages: EMPTY_STRING,
  makes: EMPTY_STRING,
  models: EMPTY_STRING,
  stores: EMPTY_STRING,
  vehicleType: FILTER_OPTION_VALUES.VEHICLE_TYPE.ALL,
  vehicles: EMPTY_STRING,
  driveType: EMPTY_STRING,
  passengerCapacity: EMPTY_STRING,
  extraCash: EMPTY_STRING,
};

/* eslint-disable */
export const FILTER_FIELDS_SUBSCRIPTION = [FILTERS_FIELD_NAMES.YEARS, FILTERS_FIELD_NAMES.MILES,FILTERS_FIELD_NAMES.MAKES, FILTERS_FIELD_NAMES.MODELS];

export const ACCORDION_NAME = 'vehicleTableAccordion';

export const FILTER_VIEW = 'filterView';

export const STOCKS ='stocks';

export const TABLE_VIEW = {
  name: 'tableView',
  roles: {
    manager: 'manager',
    salesperson: 'salesperson',
  },
};

export const GET_DEPENDENT_FILTER_VIEW = new Map<FilterView, FilterView>([
  [FilterView.BY_PARAMS, FilterView.BY_STOCK],
  [FilterView.BY_STOCK, FilterView.BY_PARAMS],
]);
