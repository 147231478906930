import React, { FC, useCallback } from 'react';
import ReactPaginate from 'react-paginate';

import { GoNextPage } from '../types';
import { PaginationWrapper } from './styles';

// for adding bootstrap styles
const paginationStylesProps = {
  containerClassName: 'pagination',
  nextLinkClassName: 'page-link',
  previousLinkClassName: 'page-link',
  pageClassName: 'page-item',
  pageLinkClassName: 'page-link',
  breakClassName: 'page-link',
};

interface IPagination {
  gotoPage: GoNextPage;
  pageCount: number;
  currentPage: number;
}

export const Pagination: FC<IPagination> = ({ gotoPage, pageCount, currentPage }) => {
  const handleChangePage = useCallback(
    ({ selected: selectedPage }: { selected: number }) => {
      gotoPage(selectedPage);
    },
    [gotoPage],
  );

  return (
    <PaginationWrapper>
      <ReactPaginate
        {...paginationStylesProps}
        forcePage={currentPage}
        pageCount={pageCount}
        pageRangeDisplayed={5}
        marginPagesDisplayed={0}
        onPageChange={handleChangePage}
        nextLabel="»"
        previousLabel="«"
      />
    </PaginationWrapper>
  );
};
