export const STANDARD_RATE_SHEET = 'Standard';

const defaultUndefinedValues = {
  title: undefined,
  value: undefined,
};

const defaultRateSheetStandard = {
  title: STANDARD_RATE_SHEET,
  value: STANDARD_RATE_SHEET,
};

export const DEFAULT_RATE_SHEET_FORM = {
  racSettingsFormEmpty: {
    rateSheet: { ...defaultUndefinedValues },
    region: { ...defaultUndefinedValues },
    taxRate: NaN,
  },

  racSettingsFormStandard: {
    rateSheet: { ...defaultRateSheetStandard },
    region: { ...defaultUndefinedValues },
    taxRate: NaN,
  },
};
