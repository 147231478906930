import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { Card, Col, ModalProps, Row } from 'react-bootstrap';
import { useFormContext, FormProvider, useForm, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Selector, useDispatch, useSelector } from 'react-redux';
import { Button, FormRow, MoneyLabel } from 'modules/core/components';
import { ButtonVariant, ColumnWidth } from 'modules/core/enums';
import { MODAL_DIALOG_WIDTH, PLUS_SIGN } from 'modules/core/constants';
import { AppDispatch, RootState } from 'store/store';
import { getControlNumbersThunk } from 'actions/controlActions';
import { sum } from 'modules/core/utils';
import { LOAN_VARIABLES_FIELDS_NAMES } from 'components/Modals/LoanVariablesDialog/consts/fieldNames.const';
import { getUser } from 'store/selectors/auth.selector';

import { IIncomeCardForm, IIncomeForm } from './types';
import { ModalDialog } from '../ModalDialog';
import { IncomeCard } from './IncomeCard';
import { TotalIncomeTitleCard } from './styles';
import { FORM_FIELD_NAMES, INCOME_INITIAL_VALUES, INCOME_PREFIX } from './const';

interface IIncomeCalculator extends ModalProps {
  onIncomeCalculatorSubmit: (incomes: IIncomeForm) => void;
  presetValue?: number;
  incomesSelector: Selector<RootState, IIncomeForm>;
  onHide: () => void;
  backdrop?: boolean | 'static' | undefined;
  centered?: boolean;
  isRac?: boolean;
  isRacLoanVariablesModal?: boolean;
}

export const IncomeCalculator: FC<IIncomeCalculator> = ({
  show,
  onHide,
  onIncomeCalculatorSubmit,
  incomesSelector,
  backdrop,
  verticallyCentered = false,
  isRac = false,
  isRacLoanVariablesModal = false,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const user = useSelector(getUser);
  const savedData = useSelector(incomesSelector);
  const { watch: commonWatch, setValue: setCommonValue } = useFormContext();

  const formMethods = useForm({
    mode: 'onSubmit',
    shouldUnregister: false,
    defaultValues: {
      income: { incomesForm: savedData.savedIncomes },
    },
  });

  const { handleSubmit, watch, control, clearErrors } = formMethods;

  const { remove, append, fields: cards } = useFieldArray({
    control,
    name: FORM_FIELD_NAMES.INCOME_FORM,
  });

  const { incomesForm } = watch(INCOME_PREFIX) as any;

  const totalIncome = useMemo(() => (Array.isArray(incomesForm) ? sum(incomesForm.map((item) => item.income)) : 0), [
    incomesForm,
  ]);

  const modalSize = useMemo(() => (cards.length > 1 ? MODAL_DIALOG_WIDTH.CUSTOM.PX_750 : MODAL_DIALOG_WIDTH.SM), [
    cards,
  ]);

  const updateIncomeCalculator = () => {
    remove();

    if (isRacLoanVariablesModal) {
      const commonMontlyIncomeForm = commonWatch(LOAN_VARIABLES_FIELDS_NAMES.MONTHLY_INCOME_FORM);

      commonMontlyIncomeForm.savedIncomes.forEach((card: IIncomeCardForm) => append(card));
    } else {
      const savedIncomesData: Partial<IIncomeCardForm>[] = commonWatch(FORM_FIELD_NAMES.SAVED_INCOMES);

      savedIncomesData.forEach((card) => append(card));
    }
  };

  const onSubmit = useCallback(() => {
    setCommonValue(FORM_FIELD_NAMES.TOTAL_INCOME, totalIncome);
    setCommonValue(FORM_FIELD_NAMES.SAVED_INCOMES, incomesForm);

    const incomesFormToSubmit = incomesForm.map((cardForm: Partial<IIncomeCardForm>) => {
      const arrayFormattedIncomesForm = Object.entries(cardForm).filter((fieldValue) => fieldValue[1] !== '');

      return Object.fromEntries(arrayFormattedIncomesForm);
    });

    onIncomeCalculatorSubmit({
      savedIncomes: incomesFormToSubmit,
      totalIncome,
    });
    onHide();
  }, [onHide, setCommonValue, incomesForm, totalIncome, onIncomeCalculatorSubmit]);

  const incomeCards = useMemo(() => {
    if (!incomesForm) {
      return null;
    }

    return cards.map((card, index) => (
      <Col sm={ColumnWidth.M} key={card.id}>
        <IncomeCard
          clearErrors={clearErrors}
          incomeNumber={index}
          onDelete={remove}
          showTrashIcon={cards.length > 1}
          key={card.id}
        />
      </Col>
    ));
  }, [cards, clearErrors, remove, incomesForm]);

  useEffect(() => {
    dispatch(getControlNumbersThunk());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (show === true) updateIncomeCalculator();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  return (
    <ModalDialog
      show={show}
      onHide={onHide}
      backdrop={backdrop}
      title={t('components.incomeCalculator.title')}
      submitButtonTitle={t('common.buttons.apply')}
      cancelButtonTitle={t('common.buttons.cancel')}
      onSubmitModal={handleSubmit(onSubmit)}
      modalSize={modalSize}
      verticallyCentered={verticallyCentered}
      upperCaseTitle
    >
      <TotalIncomeTitleCard>
        <Card.Body>
          <FormRow
            label={t('components.incomeCalculator.labels.totalIncome')}
            subLabel={t('components.incomeCalculator.labels.perMonth')}
            control={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <MoneyLabel dataTestId="total-income-per-month-field" isColored>
                {totalIncome || 0}
              </MoneyLabel>
            }
          />
        </Card.Body>
        <Card.Footer>
          <Button
            title={`${PLUS_SIGN} ${t('components.incomeCalculator.buttons.addIncome')}`}
            buttonTheme={ButtonVariant.OUTLINE}
            onClick={() => append(INCOME_INITIAL_VALUES)}
            disabled={user?.is_sso}
          />
        </Card.Footer>
      </TotalIncomeTitleCard>
      <FormProvider {...formMethods}>
        <Row>{incomeCards}</Row>
      </FormProvider>
    </ModalDialog>
  );
};
