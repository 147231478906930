import { WindowBreakpoints } from 'modules/core/enums/windowBreakpoints.enum';
import styled from 'styled-components';

export const HeaderButtonsGroup = styled.div<{ isRac: boolean }>`
  display: flex;
  justify-content: ${({ isRac }) => (isRac ? 'end' : 'space-between')};
  width: 210px;

  @media (max-width: ${WindowBreakpoints.small}px) {
    width: auto;
  }
`;

export const ConsumerInformationWrapper = styled.div`
  .card-title > span {
    margin-right: 40px;
  }
`;
