import React, { FC, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { Button, Icon, Loader } from 'modules/core/components';
import { getVehicleFiltersDirtyFields } from 'store/selectors/probabilityEstimator.selector';
import { ICONS_HEIGHT, STATIC_IMAGE_URLS } from 'modules/core/constants';
import { useVehicleFormReset } from 'modules/core/hooks';
import { INITIAL_VALUES } from 'modules/ProbabilityEstimator/probabilityEstimator.const';
import { OverlayTrigger } from 'react-bootstrap';
import {
  setVehicleFiltersDirtyFields,
  resetVehicleFiltersDirtyFields,
} from 'store/reducers/probabilityEstimator.reducer';

import { MonthlyPaymentWarning } from '../MonthlyPaymentWarning';
import { Container, Plug, Title, TotalVehiclesInfo, Popover } from './styles';

interface ISubmitSliderSection {
  isWarningSectionVisible: boolean;
  isFetchingVehicles: boolean;
  totalVehicles?: number;
  disabled?: boolean;
}

export const SubmitSliderSection: FC<ISubmitSliderSection> = ({
  isWarningSectionVisible,
  totalVehicles,
  disabled,
  isFetchingVehicles,
}) => {
  const { t } = useTranslation();
  const vehicleFilterDirtyFields = useSelector(getVehicleFiltersDirtyFields);
  const { resetFilters } = useVehicleFormReset(INITIAL_VALUES, undefined, resetVehicleFiltersDirtyFields);
  const {
    formState: { dirtyFields },
  } = useFormContext();
  const dispatch = useDispatch();

  const dirtyFieldsValues = dirtyFields.vehicleFilters ? dirtyFields.vehicleFilters : [];

  useEffect(() => {
    dispatch(setVehicleFiltersDirtyFields({ ...vehicleFilterDirtyFields, ...dirtyFields.vehicleFilters }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...Object.values(dirtyFieldsValues), dirtyFields, dispatch]);

  useEffect(() => {
    dirtyFields.vehicleFilters = { ...vehicleFilterDirtyFields };
  }, [vehicleFilterDirtyFields, dirtyFields]);

  const totalVehiclesInfo = useMemo(() => {
    const isTotalVehiclesValid = Number.isInteger(totalVehicles);

    if (isFetchingVehicles) {
      return <Loader />;
    }

    if (isTotalVehiclesValid) {
      return (
        <Title needMargin={!isWarningSectionVisible}>
          {t('components.submitSliderSection.title', {
            totalVehicles,
            count: totalVehicles,
          })}
          {Boolean(Object.keys(vehicleFilterDirtyFields).length) && (
            <OverlayTrigger
              key="top"
              placement="top"
              overlay={<Popover id="tooltip-top">{t('components.monthlyPaymentSlider.resetFilters')}</Popover>}
            >
              <Icon imageSrc={STATIC_IMAGE_URLS.icons.filter} height={ICONS_HEIGHT.DEFAULT} onClick={resetFilters} />
            </OverlayTrigger>
          )}
        </Title>
      );
    }

    return <Plug />;
  }, [isFetchingVehicles, isWarningSectionVisible, t, totalVehicles, resetFilters, vehicleFilterDirtyFields]);

  return (
    <Container>
      <TotalVehiclesInfo>
        {totalVehiclesInfo}
        {!isFetchingVehicles && isWarningSectionVisible && <MonthlyPaymentWarning />}
      </TotalVehiclesInfo>
      <Button title={t('common.buttons.search')} disabled={disabled} type="submit" />
    </Container>
  );
};
