import i18n from 'i18next';
import { costFormatter, thousandFormatter } from 'modules/core/utils';
import { EMPTY_STRING } from 'modules/core/constants';

import {
  IAdjustmentChosenValues,
  IVendorBody,
  ITotalCostInfo,
  IVehicleAdjustRetail,
  IVehicleInfo,
  TAccessoryOption,
  IVehicleAdjustmentForm,
} from './types';
import { costFormattedStringToNumber } from './utils';
import { RETAIL_NAME, TRADE_IN_NAME } from './common.const';

export class VehicleAdjustmentService {
  static getTotalAdjustmentCost(basePrice: number, addsDeducts: number, odometerAdj: number): number {
    return basePrice + addsDeducts + odometerAdj;
  }

  static getTotalAddsCost(
    name: TAccessoryOption,
    equipments: Record<string, boolean>,
    currentAdjustment: IVendorBody,
  ): number {
    return (
      currentAdjustment?.accessories.reduce((acc, accessory) => {
        const checked = equipments[accessory.slug];

        return checked ? acc + accessory[name] : acc;
      }, 0) || 0
    );
  }

  static getTotalCostInfoByName(
    name: TAccessoryOption,
    equipments: Record<string, boolean>,
    currentAdjustment: IVendorBody,
  ): ITotalCostInfo {
    const totalAddsCost = this.getTotalAddsCost(name, equipments, currentAdjustment);

    return (
      currentAdjustment && {
        basePrice: costFormatter(currentAdjustment[name]),
        addsDeducts: costFormatter(totalAddsCost),
        odometerAdj: costFormatter(currentAdjustment.odometrAdj),
        [name]: costFormatter(
          this.getTotalAdjustmentCost(currentAdjustment[name], totalAddsCost, currentAdjustment.odometrAdj),
        ),
      }
    );
  }

  static getVehicleInfo(vehicleAdjustRetail: IVehicleAdjustRetail): IVehicleInfo {
    return {
      condition: i18n.t('components.vehicleAdjustmentDialog.clean'),
      make: vehicleAdjustRetail?.adjustInfo?.make,
      year: vehicleAdjustRetail?.adjustInfo?.year,
      mileage: (thousandFormatter(vehicleAdjustRetail?.odometer) as unknown) as number,
    };
  }

  static getAdjustmentChosenValues(
    data: IVehicleAdjustmentForm,
    adjustmentId: number,
    currentAdjustment: IVendorBody,
  ): IAdjustmentChosenValues {
    const { series, equipments } = data;

    const vendorEquipment = Object.keys(equipments).filter((equipmentName) => equipments[equipmentName]);

    const vendorRetail = costFormattedStringToNumber(
      this.getTotalCostInfoByName(RETAIL_NAME, equipments, currentAdjustment)?.[RETAIL_NAME],
    );

    const vendorTrade = costFormattedStringToNumber(
      this.getTotalCostInfoByName(TRADE_IN_NAME, equipments, currentAdjustment)?.[TRADE_IN_NAME],
    );

    return {
      id: adjustmentId,
      vendor_equipment: vendorEquipment,
      vendor_retail: vendorRetail,
      vendor_series: series,
      vendor_trade: vendorTrade,
    };
  }

  static getDefaultFormState(vehicleAdjustRetail: IVehicleAdjustRetail): IVehicleAdjustmentForm {
    if (vehicleAdjustRetail?.vendorSetSeries && vehicleAdjustRetail?.vendorSetEquipment.length) {
      const defaultEquipmentFormState = vehicleAdjustRetail?.vendorSetEquipment.reduce(
        (accObj, item) => ({ ...accObj, [item]: true }),
        {},
      );

      return {
        series: vehicleAdjustRetail?.vendorSetSeries,
        equipments: defaultEquipmentFormState,
      };
    }

    const defaultRadioValue = vehicleAdjustRetail?.adjustInfo?.seriesInfo?.[0].id.toString() || EMPTY_STRING;

    return {
      series: defaultRadioValue,
      equipments: {},
    };
  }
}
