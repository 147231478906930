import { createAsyncThunk } from '@reduxjs/toolkit';
import { authApi } from 'api';
import { IChangePasswordFormValues, IForgotPasswordValue, ILoginFormValues } from 'modules/auth/auth';
import { AuthService } from 'modules/auth/services/auth.service';

import { ACTION_PREFIX } from './actionPrefix.const';

export const loginUserThunk = createAsyncThunk(
  `${ACTION_PREFIX.AUTH}/login`,
  async (loginValues: ILoginFormValues, thunkAPI) => {
    try {
      const { data } = await authApi.login(loginValues);

      AuthService.setLoggedStatus(loginValues.keepLogged);

      return data;
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  },
);

export const resetPasswordThunk = createAsyncThunk(
  `${ACTION_PREFIX.AUTH}/resetPassword`,
  async ({ email }: IForgotPasswordValue, thunkAPI) => {
    try {
      const { data } = await authApi.resetPassword(email);

      return data;
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  },
);

export const confirmPasswordResetThunk = createAsyncThunk(
  `${ACTION_PREFIX.AUTH}/resetPasswordKeyCheck`,
  async ({ password1, password2 }: IChangePasswordFormValues, thunkAPI) => {
    const arrayUrl = window.location.href.split('/');
    const keyFromUrl = arrayUrl[arrayUrl.length - 1];
    const newPasswordObject = {
      password1,
      password2,
      key: keyFromUrl,
    };

    try {
      await authApi.getResetPasswordKeyCheck(keyFromUrl);
      const { data } = await authApi.confirmPasswordReset(newPasswordObject);

      return data;
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  },
);

export const verificationThunk = createAsyncThunk(
  `${ACTION_PREFIX.AUTH}/verification`,
  async ({ password1, password2 }: IChangePasswordFormValues, thunkAPI) => {
    const arrayUrl = window.location.href.split('/');
    const keyFromUrl = arrayUrl[arrayUrl.length - 1];
    const newPasswordObject = {
      password1,
      password2,
      key: keyFromUrl,
    };

    try {
      const { data } = await authApi.verificationPassword(newPasswordObject);

      return data;
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  },
);
