import React, { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Tab } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'store/store';
import { getSubventionYearDataThunk, getVehicleFiltersThunk } from 'actions/controlActions';
import { Controller as AccordionController, useFormContext } from 'react-hook-form';
import { getUser } from 'store/selectors/auth.selector';
import { getVehicleMakesThunk, getVehicleModelsThunk } from 'actions/vehicleActions';

import {
  Accordion,
  AccordionToggle,
  ChevronDisplayFilters,
  FilterTabs,
  Label,
  ErrorMessageUI,
  StockSection,
  HelpMessage,
  CardBody,
} from './styles';
import { ACCORDION_NAME, FILTER_VIEW, STOCKS } from './vehicleFilters.const';
import { VehicleFilterSection } from './FilterSection';
import { TagsInput } from '../TagsInput';

interface IVehicleFilters {
  isDisabled?: boolean;
  isFilterByStockNumber?: boolean;
  notFoundVehicles?: string[];
  notAvailableVehicles?: string[];
  isRac?: boolean;
  isLender?: boolean;
}

export const VehicleFilters: FC<IVehicleFilters> = ({
  isDisabled,
  isFilterByStockNumber,
  notAvailableVehicles,
  notFoundVehicles,
  isRac = false,
  isLender = false,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const user = useSelector(getUser);
  const { t } = useTranslation();
  const { watch, control, setValue } = useFormContext();
  const isNotAvailableVehicles = !!notAvailableVehicles?.length;
  const isNotFoundVehicles = !!notFoundVehicles?.length;
  const { [ACCORDION_NAME]: accordionShown } = watch();

  const { stocks, filterView } = watch();

  const onFilterTabChange = useCallback(
    (currentFilterView) => {
      setValue(FILTER_VIEW, currentFilterView);
    },
    [setValue],
  );

  const onStockInputChange = useCallback(
    (currentStocks) => {
      setValue(STOCKS, currentStocks);
    },
    [setValue],
  );

  const handleToggle = useCallback(() => {
    setValue(ACCORDION_NAME, !accordionShown);
  }, [setValue, accordionShown]);

  useEffect(() => {
    if (!user?.is_sso || (user?.is_sso && user?.tenant)) {
      dispatch(getVehicleFiltersThunk());
    } else {
      dispatch(getVehicleMakesThunk());
      dispatch(getVehicleModelsThunk());
      dispatch(getSubventionYearDataThunk());
    }
  }, [dispatch, setValue, user?.is_sso, user?.tenant]);

  const renderAccordion = useCallback(
    () => (
      <Accordion {...(isDisabled ? {} : { defaultActiveKey: '0' })} activeKey={accordionShown ? '' : '0'}>
        <AccordionToggle eventKey="0" onClick={handleToggle}>
          <ChevronDisplayFilters active={accordionShown} />
          {t('components.vehicleFilters.title')}
        </AccordionToggle>
        <Accordion.Collapse eventKey="0">
          <CardBody>
            {isFilterByStockNumber ? (
              <FilterTabs
                onSelect={(currentFilterView) => onFilterTabChange(currentFilterView)}
                activeKey={filterView}
                className="mb-3"
              >
                <Tab eventKey="byParams" title={t('components.vehicleFilters.tabByParams')} data-testId="by-params">
                  <VehicleFilterSection isRac={isRac} isLender={isLender} />
                </Tab>
                <Tab title={t('components.vehicleFilters.tabByStock')} eventKey="byStock" data-testId="by-stock">
                  <StockSection>
                    <Col className="col-12" data-testId="stock-input">
                      <Label>{t('components.vehicleFilters.byStockNumber.label')}</Label>
                      <HelpMessage>{t('components.vehicleFilters.byStockNumber.helpMessage')}</HelpMessage>
                      <TagsInput
                        values={stocks}
                        onChange={(currentStocks: string[]) => onStockInputChange(currentStocks)}
                        name="stocks"
                        placeHolder={t('components.vehicleFilters.byStockNumber.placeholder')}
                      />
                      {isNotAvailableVehicles && (
                        <ErrorMessageUI data-testId="not-available-vehicles">
                          {t('components.vehicleFilters.byStockNumber.errors.notAvailable', {
                            stocks: notAvailableVehicles?.join(', '),
                          })}
                        </ErrorMessageUI>
                      )}
                      {isNotFoundVehicles && (
                        <ErrorMessageUI data-testId="not-found-vehicles">
                          {t('components.vehicleFilters.byStockNumber.errors.notFound', {
                            stocks: notFoundVehicles?.join(', '),
                          })}
                        </ErrorMessageUI>
                      )}
                    </Col>
                  </StockSection>
                </Tab>
              </FilterTabs>
            ) : (
              <>
                <VehicleFilterSection />
              </>
            )}
          </CardBody>
        </Accordion.Collapse>
      </Accordion>
    ),
    [
      isDisabled,
      accordionShown,
      handleToggle,
      t,
      isFilterByStockNumber,
      filterView,
      isRac,
      isLender,
      stocks,
      isNotAvailableVehicles,
      notAvailableVehicles,
      isNotFoundVehicles,
      notFoundVehicles,
      onFilterTabChange,
      onStockInputChange,
    ],
  );

  return <AccordionController render={renderAccordion} control={control} name={ACCORDION_NAME} />;
};
