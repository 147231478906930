export const MONEY_SYMBOL = '$';

export const PERCENT_SYMBOL = '%';

export const SLASH_SYMBOL = '/';

export const COLON_SYMBOL = ':';

export const COMMA_SYMBOL = ',';

export const COMMA_WITH_BLANK_SPACE = ', ';

export const SEPARATOR_SYMBOL = '|';

export const SEMICOLON_SYMBOL = ';';

export const EQUAL_SYMBOL = '=';

export const BLANK_SPACE = ' ';

export const PLUS_SIGN = '+';

export const DOT_SYMBOL = '.';

export const STAR_SYMBOL = '*';

export const ASTERISK = '*';

export const LINE_DELIMITER = ', ';

export const ELLIPSIS = '...';

export const HYPHEN = '-';
