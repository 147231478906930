import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getFooterDataThunk } from 'actions/controlActions';
import { IFooterData, INotificationData, IFeaturesList } from 'modules/core/types';
import { EMPTY_STRING, FEATURES_LIST_INITIAL_STATE } from 'modules/core/constants';
import { getFeaturesListThunk } from 'actions/featuresListActions';

export type UIReducerState = {
  version: string;
  activeModal: string[];
  notification: INotificationData | null;
  fullSideBar: boolean;
  featuresList: IFeaturesList;
  isFetchingFeatureList: boolean;
  isModalsDisabled: boolean;
};

const initialState: UIReducerState = {
  version: EMPTY_STRING,
  activeModal: [EMPTY_STRING],
  notification: null,
  fullSideBar: false,
  featuresList: FEATURES_LIST_INITIAL_STATE,
  isFetchingFeatureList: true,
  isModalsDisabled: true,
};

export const uiReducer = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    showModal: (state: UIReducerState, { payload }: PayloadAction<string[]>) => ({ ...state, activeModal: payload }),
    hideModal: (state: UIReducerState, { payload }: PayloadAction<string[]>) => ({
      ...state,
      activeModal: payload || [EMPTY_STRING],
    }),
    showNotification: (state: UIReducerState, { payload: notification }: PayloadAction<INotificationData>) => ({
      ...state,
      notification,
    }),
    clearNotification: (state: UIReducerState) => ({ ...state, notification: null }),
    setFullSideBar: (state: UIReducerState, { payload }: PayloadAction<boolean>) => ({
      ...state,
      fullSideBar: payload,
    }),
    setIsModalsDisabled: (state: UIReducerState, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isModalsDisabled: payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(
      getFooterDataThunk.fulfilled,
      (state: UIReducerState, { payload: { version } }: PayloadAction<IFooterData>) => ({
        ...state,
        version,
      }),
    );

    builder.addCase(
      getFeaturesListThunk.fulfilled,
      (state: UIReducerState, { payload: featuresList }: PayloadAction<IFeaturesList>) => ({
        ...state,
        featuresList,
        isFetchingFeatureList: false,
      }),
    );
    builder.addCase(getFeaturesListThunk.pending, (state: UIReducerState) => ({
      ...state,
      isFetchingFeatureList: true,
    }));
    builder.addCase(getFeaturesListThunk.rejected, (state: UIReducerState) => ({
      ...state,
      isFetchingFeatureList: false,
    }));
  },
});

const {
  hideModal,
  showModal,
  showNotification,
  clearNotification,
  setFullSideBar,
  setIsModalsDisabled,
} = uiReducer.actions;

export { hideModal, showModal, showNotification, clearNotification, setFullSideBar, setIsModalsDisabled };
