import { IField } from 'modules/core/types/common';
import { Input } from 'modules/core/components';
import i18n from 'i18n';

import { NET_DOWN_PAYMENT_FIELD_NAMES } from '../../probabilityEstimator.const';

export const NET_DOWN_PAYMENT_FIELDS: IField[] = [
  {
    name: NET_DOWN_PAYMENT_FIELD_NAMES.CASH_DOWN,
    component: Input,
    label: i18n.t('components.netDownPaymentModal.cashDownAndRebates'),
    controlProps: {
      hideInitZeroValue: true,
      decimalScale: 0,
      placeholder: i18n.t('components.input.placeholders.integer'),
    },
  },
  {
    name: NET_DOWN_PAYMENT_FIELD_NAMES.TRADE_VALUE,
    component: Input,
    label: i18n.t('components.netDownPaymentModal.tradeInValue'),
    controlProps: {
      hideInitZeroValue: true,
      decimalScale: 0,
      placeholder: i18n.t('components.input.placeholders.integer'),
    },
  },
  {
    name: NET_DOWN_PAYMENT_FIELD_NAMES.AMOUNT_OWED,
    component: Input,
    label: i18n.t('components.netDownPaymentModal.amountOwedOnTrade'),
    controlProps: {
      hideInitZeroValue: true,
      decimalScale: 0,
      placeholder: i18n.t('components.input.placeholders.integer'),
    },
  },
  {
    label: i18n.t('components.loanVariablesModal.modals.netTradeIn.fields.lienHolder'),
    name: NET_DOWN_PAYMENT_FIELD_NAMES.LIEN_HOLDER,
    component: Input,
    controlProps: {
      valueAsNumber: false,
      maxLength: 100,
    },
  },
];
