import React, { FC, useCallback, useMemo } from 'react';
import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { moneyFormatter } from 'modules/core/utils';
import { Checkbox } from 'modules/core/components';
import { ColumnWidth } from 'modules/core/enums';

import { Header, ColUI as Col } from './styles';
import { IVendorBody } from '../types';
import { VEHICLE_ADJUSTMENT_FORM_FIELDS } from '../common.const';

interface IAdjustmentTable {
  data: IVendorBody;
}

export const AdjustmentTable: FC<IAdjustmentTable> = ({ data }) => {
  const { t } = useTranslation();

  const renderRow = useCallback(
    ({ slug, accDesc, tradeIn, retail }) => (
      <Row key={slug}>
        <Col sm={ColumnWidth.XS}>
          <Checkbox name={`${VEHICLE_ADJUSTMENT_FORM_FIELDS.EQUIPMENTS}.${slug}`} label={accDesc} />
        </Col>
        <Col sm={ColumnWidth.XS}>{moneyFormatter(tradeIn)}</Col>
        <Col sm={ColumnWidth.XS}>{moneyFormatter(retail)}</Col>
      </Row>
    ),
    [],
  );

  const rows = useMemo(() => data?.accessories?.map(renderRow), [data, renderRow]);

  return (
    <>
      <Header>
        <Col sm={ColumnWidth.XS}>{t('components.vehicleAdjustmentDialog.equipment')}</Col>
        <Col sm={ColumnWidth.XS}>{t('components.vehicleAdjustmentDialog.tradeIn')}</Col>
        <Col sm={ColumnWidth.XS}>{t('components.vehicleAdjustmentDialog.retail')}</Col>
      </Header>
      {rows}
    </>
  );
};
