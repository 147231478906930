import { configureStore, EnhancedStore, getDefaultMiddleware } from '@reduxjs/toolkit';

import { appReducer, rootReducer } from './reducers';

export const store = configureStore({
  devTools: process.env.NODE_ENV !== 'production',
  reducer: rootReducer,
  middleware: () =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const createStoreWithMiddlewares = (initialState = {}): EnhancedStore => {
  const testStore = configureStore({
    reducer: rootReducer,
    middleware: () =>
      getDefaultMiddleware({
        serializableCheck: false,
      }),
    preloadedState: initialState,
  });

  return testStore;
};

export type RootState = ReturnType<typeof appReducer>;
export type AppDispatch = typeof store.dispatch;
