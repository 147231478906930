import { Endpoints } from 'modules/core/constants';
import { AxiosResponse, CancelTokenSource } from 'axios';
import { Api } from 'api/api';
import {
  ILenderVehicleByStock,
  IRACVehicle,
  IRACVehicleByStock,
  IVehicleByStock,
  VehicleBody,
} from 'modules/core/types';
import { ILenderVehicle, IPaginatedVehicles } from 'modules/core/types/vehicle';
import { IVehicleMake, IVehicleModel } from 'modules/core/components/VehicleFilters/types';
import {
  IVehicleAdjustRetailPayload,
  IVehicleAdjustRetailSubmitData,
  IVehicleLogPayload,
} from 'components/Modals/VehicleAdjustmentDialog/types';
import { ILoanVariables, ISendRouteOneData } from 'components/Modals/LoanVariablesDialog/types';
import { ICreateRouteOneVehicle } from 'components/Modals/AddVehicleDialog/types';

import { IRouteOneData } from '../modules/core/types/routeOne';

class VehicleApi extends Api {
  cancelRequestsTokenSources: Record<string, CancelTokenSource> = {};

  getVehicleMakes = (): Promise<AxiosResponse<IVehicleMake[]>> => this.api.get(Endpoints.getVehicleMakes);

  getVehicleModels = (): Promise<AxiosResponse<IVehicleModel[]>> => this.api.get(Endpoints.getVehicleModels);

  getVehiclePhotosById = async (id: number): Promise<AxiosResponse<string[][]>> => {
    try {
      this.cancelRequestsTokenSources.vehiclePhotos = await this.createCancelTokenSource();

      const response = await this.api.get(Endpoints.getVehiclePhotos, {
        cancelToken: this.cancelRequestsTokenSources.vehiclePhotos.token,
        params: { vehicle_id: id },
      });

      const validPhotos = await Promise.all(
        response.data[id].map(async (url: string) => {
          /**
           * check a default vehicle photo
           *
           * .../default.jpg - True
           * .../default_FR2Fdf.jpg - True
           * .../photo.jpg - False
           */
          if (/(default)(?:[_\s]\w+)?(\.jpg)/.test(url)) return url;

          return fetch(url, { method: 'HEAD' })
            .then((res) => {
              if (res.status === 200) return url;

              return null;
            })
            .catch(() => null);
        }),
      );

      response.data[id] = validPhotos.filter((url) => url);

      return response;
    } catch (e: any) {
      return e;
    }
  };

  getVehicleBodies = (): Promise<AxiosResponse<VehicleBody[]>> => this.api.get(Endpoints.getVehicleBodies);

  getVehicleBodyType = (): Promise<AxiosResponse<VehicleBody[]>> => this.api.get(Endpoints.getVehicleBodyTypes);

  getDefaultVehiclePhoto = (): Promise<AxiosResponse<string>> => this.api.get(Endpoints.getDefaultVehiclePhoto);

  getVehicleAdjustRetailById = (id: number): Promise<AxiosResponse<IVehicleAdjustRetailPayload[]>> =>
    this.api.get(Endpoints.vehicleAdjustRetail, { params: { id } });

  getPaginatedVehicles = (params: Record<string, unknown>): Promise<AxiosResponse<IPaginatedVehicles>> => {
    this.cancelRequestsTokenSources[`calculationsVehicles`] = this.createCancelTokenSource();

    return this.api.post(`${Endpoints.getVehicles}calculations/`, params, {
      cancelToken: this.cancelRequestsTokenSources[`calculationsVehicles`].token,
    });
  };

  getLoanTermsList = (params: Record<string, unknown>): Promise<AxiosResponse<any>> => {
    this.cancelRequestsTokenSources.loanTermsList = this.createCancelTokenSource();

    return this.api.get(`${Endpoints.getLoanTermsList}`, {
      cancelToken: this.cancelRequestsTokenSources.loanTermsList.token,
      params,
    });
  };

  getLenderLoanTermsList = (params: Record<string, unknown>): Promise<AxiosResponse<any>> => {
    this.cancelRequestsTokenSources.lenderLoanTermsList = this.createCancelTokenSource();

    return this.api.get(`${Endpoints.getLenderLoanTermsList}`, {
      cancelToken: this.cancelRequestsTokenSources.lenderLoanTermsList.token,
      params,
    });
  };

  getRateSheetsData = (): Promise<AxiosResponse<any>> => this.api.get(`${Endpoints.getRateSheetsData}`);

  saveRateSheetsData = (params: Record<string, unknown>): Promise<AxiosResponse<IVehicleByStock>> =>
    this.api.post(`${Endpoints.getRateSheetsData}`, params);

  getVehiclesByStock = (params: Record<string, unknown>): Promise<AxiosResponse<IVehicleByStock>> => {
    this.cancelRequestsTokenSources[`calculationsVehiclesByStock`] = this.createCancelTokenSource();

    return this.api.post(`${Endpoints.getVehicles}calculations/stock_mode/`, params, {
      cancelToken: this.cancelRequestsTokenSources[`calculationsVehiclesByStock`].token,
    });
  };

  getVehicleAdjustmentLogById = (id: number): Promise<AxiosResponse<IVehicleLogPayload[]>> =>
    this.api.get(`${Endpoints.getVehicleAdjustLog}${id}`);

  patchVehicleAdjustRetail = (data: IVehicleAdjustRetailSubmitData[]): void => {
    this.api.patch(Endpoints.vehicleAdjustRetail, data);
  };

  getRACVehicles = (params: Record<string, unknown>): Promise<AxiosResponse<IRACVehicle[]>> => {
    this.cancelRequestsTokenSources[`calculationsRACVehicles`] = this.createCancelTokenSource();

    return this.api.post(`${Endpoints.getRACVehicle}calculations/`, params, {
      cancelToken: this.cancelRequestsTokenSources[`calculationsRACVehicles`].token,
    });
  };

  getLenderVehicles = (params: Record<string, unknown>): Promise<AxiosResponse<ILenderVehicle[]>> => {
    this.cancelRequestsTokenSources.lenderVehicles = this.createCancelTokenSource();

    return this.api.post(`${Endpoints.getLenderVehicles}`, params, {
      cancelToken: this.cancelRequestsTokenSources.lenderVehicles.token,
    });
  };

  getRACVehiclesByStock = (params: Record<string, unknown>): Promise<AxiosResponse<IRACVehicleByStock>> => {
    this.cancelRequestsTokenSources.RACVehiclesByStock = this.createCancelTokenSource();

    return this.api.post(`${Endpoints.getRACVehicleByStock}`, params, {
      cancelToken: this.cancelRequestsTokenSources.RACVehiclesByStock.token,
    });
  };

  getLenderVehiclesByStock = (params: Record<string, unknown>): Promise<AxiosResponse<ILenderVehicleByStock>> => {
    this.cancelRequestsTokenSources.LenderVehiclesByStock = this.createCancelTokenSource();

    return this.api.post(`${Endpoints.getLenderVehiclesByStock}`, params, {
      cancelToken: this.cancelRequestsTokenSources.LenderVehiclesByStock.token,
    });
  };

  getLoanVariablesInfo = (params: Record<string, unknown>): Promise<AxiosResponse<ILoanVariables>> => {
    this.cancelRequestsTokenSources.loanVariablesInfo = this.createCancelTokenSource();

    return this.api.post(`${Endpoints.getLoanVariablesInfo}`, params, {
      cancelToken: this.cancelRequestsTokenSources.loanVariablesInfo.token,
    });
  };

  getLenderLoanVariablesInfo = (params: Record<string, unknown>): Promise<AxiosResponse<ILoanVariables>> => {
    this.cancelRequestsTokenSources.lenderLoanVariablesInfo = this.createCancelTokenSource();

    return this.api.post(`${Endpoints.getLenderLoanVariablesInfo}`, params, {
      cancelToken: this.cancelRequestsTokenSources.lenderLoanVariablesInfo.token,
    });
  };

  getRouteOneData = (): Promise<AxiosResponse<IRouteOneData>> => this.api.get(Endpoints.getRouteOneData);
  postRouteOneData = (params: ISendRouteOneData) => this.api.post(Endpoints.getRouteOneData, params);

  createRouteOneVehicle = (params: Record<string, unknown>): Promise<AxiosResponse<ICreateRouteOneVehicle>> =>
    this.api.post(Endpoints.createRouteOneVehicle, params);

  postJDPUsage = (vehicleID: number, vendorName: string) =>
    this.api.post(Endpoints.postJDPUsage, { vehicle_id: vehicleID, vendor_name: vendorName });

  cancelAllRequests = () => {
    Object.values(this.cancelRequestsTokenSources).forEach((item) => {
      item.cancel();
    });
  };

  cancelGetVehiclesRequests = () => {
    this.cancelRequestsTokenSources.calculationsVehicles?.cancel();
  };

  cancelGetRACVehiclesRequests = () => {
    this.cancelRequestsTokenSources.calculationsRACVehicles?.cancel();
  };

  cancelGetVehiclesByStockRequests = () => {
    this.cancelRequestsTokenSources.calculationsVehiclesByStock?.cancel();
  };

  cancelGetRACVehiclesByStockRequest = () => {
    this.cancelRequestsTokenSources.RACVehiclesByStock?.cancel();
  };

  cancelGetLoanVariablesInfo = () => {
    this.cancelRequestsTokenSources.loanVariablesInfo?.cancel();
  };

  cancelGetLenderLoanVariablesInfo = () => {
    this.cancelRequestsTokenSources.lenderLoanVariablesInfo?.cancel();
  };

  cancelGetLenderVehiclesByStockRequests = () => {
    this.cancelRequestsTokenSources.LenderVehiclesByStock?.cancel();
  };

  cancelGetLenderVehiclesRequests = () => {
    this.cancelRequestsTokenSources.lenderVehicles?.cancel();
  };
}

export default new VehicleApi();
