import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';
import { TableColumn } from 'modules/core/components/Table/types';
import { STATIC_IMAGE_URLS } from 'modules/core/constants';
import { ButtonVariant, ColumnWidth } from 'modules/core/enums';
import { DollarAdornment, FormRow, Input, Panel, PercentAdornment } from 'modules/core/components';
import { IField, IUser } from 'modules/core/types';
import { requiredValidator, validateNumberValue, validateRequiredMinimumValue } from 'modules/core/validators';
import { useSelector } from 'react-redux';
import { getIsMissingCostInfo } from 'store/selectors/ui.selector';
import { getUser } from 'store/selectors/auth.selector';
import { getRouteOneData } from 'store/selectors/RACAffordabilityEngine.selector';

import {
  Card,
  CardRow,
  Img,
  ImgContainer,
  VehiclesDescription,
  VehiclesDescriptionFields,
  ColWithBorder,
  FieldsRow,
  PaymentBlock,
  TableBlock,
  PaymentContainer,
  OptimizeDealTitle,
  AlternativeDealStructureTitle,
  ActualPaymentInfoTitle,
  FormControlFeedback,
  RecalculateButton,
  RecalculateBlock,
  RecalculateIcon,
  TierLabel,
  TierValue,
  DesctiptionRow,
  TierCol,
  VehiclePhotoBlock,
  MonthlyIncomeCol,
  ApprovedPaymentCol,
  CurrentPaymentCol,
} from '../styles';
import {
  MONTHLY_INCOME_FIELD,
  RAC_MW_TOTAL_AMOUNT_FINANCED_LABEL,
  MONTHLY_INCOME_LABEL,
  RAC_MW_TOTAL_AMOUNT_FINANCED_FIELD,
  PAYMENT_LABEL,
  CURRENT_PAYMENT_FIELD,
  CURRENT_PAYMENT_LABEL,
} from '../consts/fieldConfig.const';
import { LOAN_VARIABLES_FIELDS_NAMES } from '../consts/fieldNames.const';
import { Table } from '../Table/Table';
import { LOAN_VARIABLES_MODAL_FIELDS } from '../common.const';

const MAX_PAGE_SIZE = 4;
const CURRENT_PAGE = 0;

interface ILoanVariablesFields {
  columns: TableColumn[];
  loanVariables: any;
  showPrintPreview: boolean;
  showSubmitPreview: boolean;
  isFetching: boolean;
  isRecalculateButtonDisabled: boolean;
  generatedFields: {
    valuationLabels: JSX.Element[];
    vehicleValuationFields: JSX.Element[];
    financingTermsFields: JSX.Element[];
    financingTermsLabels: JSX.Element[];
    vehicleEnhancementsFields: JSX.Element[];
    vehicleEnhancementsLabels: JSX.Element[];
    transactionCostsFields: JSX.Element[];
    transactionCostsLabels: JSX.Element[];
  };
  paymentErrorMessage: string | undefined;
  participationErrorMessage: string | undefined;
  maxParticipation: number | undefined;
  tier: string;
  maxPaymentValue: number;
  defaultParticipation: number;
  calculateTotalAmountFinanced: () => void;
  setHistoryCallback: () => void;
  generateFields: (fields: IField[]) => JSX.Element[];
  isFieldChanged: (name: any) => boolean;
  manuallyCreated?: boolean;
  isLender?: boolean;
}

export const LoanVariablesFields: FC<ILoanVariablesFields> = ({
  columns,
  loanVariables,
  isRecalculateButtonDisabled,
  showPrintPreview,
  showSubmitPreview,
  generatedFields,
  paymentErrorMessage,
  participationErrorMessage = undefined,
  tier,
  maxPaymentValue,
  defaultParticipation = undefined,
  maxParticipation = undefined,
  isFetching,
  isFieldChanged,
  generateFields,
  setHistoryCallback,
  calculateTotalAmountFinanced,
  manuallyCreated = false,
  isLender = false,
}) => {
  const { t } = useTranslation();
  const maxPaymentMessage = t('components.loanVariablesModal.errorMessages.maxPaymentIs', {
    maxPaymentRange: maxPaymentValue,
  });
  const participationMessage =
    isLender &&
    (defaultParticipation || defaultParticipation === 0) &&
    t('components.loanVariablesModal.errorMessages.maxParticipationIs', {
      maxParticipation: maxParticipation || 0,
    });
  const isCostMissing = useSelector(getIsMissingCostInfo);
  const user = useSelector(getUser) as IUser;
  const routeOneData = useSelector(getRouteOneData);
  const vinLabel = t('components.loanVariablesModal.fields.vin', { vin: loanVariables?.vin });
  const stockLabel = t('components.loanVariablesModal.fields.stock', { stock: loanVariables?.stock });
  const currentColumns = isCostMissing
    ? columns.filter((column) => column.id !== LOAN_VARIABLES_MODAL_FIELDS.PROFIT)
    : columns;
  const { noPhoto, blackCar, whiteCar } = STATIC_IMAGE_URLS.icons;

  const getVehiclePhotoSrc = () => {
    if (!user?.isWithInventory || manuallyCreated) {
      if (Object.keys(routeOneData).length > 0 && routeOneData.sale_vehicle.vin === loanVariables.vin) {
        return whiteCar;
      }

      return blackCar;
    }

    if (loanVariables.photo) {
      return String(loanVariables.photo);
    }

    return noPhoto;
  };

  return (
    <Row>
      <DesctiptionRow>
        <VehiclePhotoBlock className="align-items-center pr-0">
          <ImgContainer>
            <Img data-testid="image" src={getVehiclePhotoSrc()} isWithInventory={user?.isWithInventory} />
          </ImgContainer>
        </VehiclePhotoBlock>
        <Col lg={ColumnWidth.XL} md={ColumnWidth.XL} xs={ColumnWidth.L}>
          <Row>
            <Col lg={ColumnWidth.FULL} md={ColumnWidth.FULL} xs={ColumnWidth.FULL}>
              <VehiclesDescription>
                <div>{loanVariables.description}</div>
                <div>
                  {user?.isWithInventory ? <div className="vehicle-name">{loanVariables?.dealer}</div> : <></>}
                  <div className="vehicle-stock-vin">
                    {user?.isWithInventory && !manuallyCreated ? `${stockLabel} | ${vinLabel}` : vinLabel}
                  </div>
                </div>
              </VehiclesDescription>
            </Col>
            <TierCol id="tierCol">
              <VehiclesDescriptionFields>
                <TierLabel>{t('components.loanVariablesModal.fields.tier')}</TierLabel>
                <TierValue isAdjsutDealView={!(showPrintPreview || showSubmitPreview)}>
                  {isLender ? tier : t('components.loanVariablesModal.fields.tier', { index: tier })}
                </TierValue>
              </VehiclesDescriptionFields>
            </TierCol>
            <MonthlyIncomeCol id="monthlyIncomeCol">
              <VehiclesDescriptionFields>
                {showPrintPreview || showSubmitPreview
                  ? generateFields([MONTHLY_INCOME_LABEL])
                  : generateFields([MONTHLY_INCOME_FIELD])}
              </VehiclesDescriptionFields>
            </MonthlyIncomeCol>
            <ApprovedPaymentCol id="approvedPaymentCol">
              <VehiclesDescriptionFields>
                {showPrintPreview || showSubmitPreview ? (
                  generateFields([PAYMENT_LABEL])
                ) : (
                  <FormRow
                    fieldWidth={ColumnWidth.FULL}
                    key={LOAN_VARIABLES_FIELDS_NAMES.PAYMENT}
                    required
                    label={t('components.loanVariablesModal.fields.approvedPayment')}
                    name={LOAN_VARIABLES_FIELDS_NAMES.PAYMENT}
                    control={
                      <>
                        <Input
                          name={LOAN_VARIABLES_FIELDS_NAMES.PAYMENT}
                          textStyle={'bold'}
                          required
                          startAdornment={<DollarAdornment />}
                          rules={requiredValidator}
                          validate={validateRequiredMinimumValue}
                          decimalScale={0}
                          placeholder={t('components.input.placeholders.integer')}
                          isInvalid={Boolean(paymentErrorMessage)}
                          {...(isFieldChanged('payment') && { blurCallback: setHistoryCallback })}
                        />
                        <FormControlFeedback
                          type="invalid"
                          className="d-block"
                          $isInvalid={Boolean(paymentErrorMessage)}
                        >
                          {paymentErrorMessage ? paymentErrorMessage : maxPaymentMessage}
                        </FormControlFeedback>
                      </>
                    }
                  />
                )}
              </VehiclesDescriptionFields>
            </ApprovedPaymentCol>
            {(loanVariables?.ltv_ratio || loanVariables?.ltv_categories?.length > 0) && (
              <CurrentPaymentCol id="currentPaymentCol">
                <VehiclesDescriptionFields>
                  {showPrintPreview || showSubmitPreview
                    ? generateFields([CURRENT_PAYMENT_LABEL])
                    : generateFields([CURRENT_PAYMENT_FIELD])}
                </VehiclesDescriptionFields>
              </CurrentPaymentCol>
            )}
          </Row>
        </Col>
      </DesctiptionRow>
      <div>
        {showPrintPreview || showSubmitPreview ? (
          <Panel title={t('components.RACAffordabilityEngine.approvedLoanTerms.title')}>
            <FieldsRow>
              <Col sm={ColumnWidth.FULL}>
                <Row>
                  <ColWithBorder>
                    <Card>
                      <CardRow>{generatedFields.valuationLabels}</CardRow>
                    </Card>
                  </ColWithBorder>
                  <ColWithBorder>
                    <Card>
                      <CardRow>{generatedFields.financingTermsLabels}</CardRow>
                    </Card>
                  </ColWithBorder>
                  <ColWithBorder>
                    <Card>
                      <CardRow>{generatedFields.vehicleEnhancementsLabels}</CardRow>
                    </Card>
                  </ColWithBorder>
                  <ColWithBorder>
                    <Card>
                      <CardRow>{generatedFields.transactionCostsLabels}</CardRow>
                    </Card>
                  </ColWithBorder>
                  <PaymentContainer sm={ColumnWidth.XXS}>
                    <PaymentBlock>
                      <CardRow>{generateFields([RAC_MW_TOTAL_AMOUNT_FINANCED_LABEL])}</CardRow>
                    </PaymentBlock>
                  </PaymentContainer>
                </Row>
              </Col>
            </FieldsRow>
          </Panel>
        ) : (
          <Panel title={t('components.RACAffordabilityEngine.approvedLoanTerms.title')}>
            <OptimizeDealTitle $uppercase $fontSize={'16px'}>
              {t('components.loanVariablesModal.labels.optimizeDeal')}
            </OptimizeDealTitle>
            <FieldsRow>
              <Col sm={ColumnWidth.FULL}>
                <Row>
                  <ColWithBorder>
                    <Card>
                      <CardRow>{generatedFields.vehicleValuationFields}</CardRow>
                    </Card>
                  </ColWithBorder>
                  <ColWithBorder>
                    <Card>
                      <CardRow>{generatedFields.financingTermsFields}</CardRow>
                    </Card>
                  </ColWithBorder>
                  <ColWithBorder>
                    <Card>
                      <CardRow>{generatedFields.vehicleEnhancementsFields}</CardRow>
                    </Card>
                  </ColWithBorder>
                  <ColWithBorder>
                    <Card>
                      <CardRow>{generatedFields.transactionCostsFields}</CardRow>
                    </Card>
                  </ColWithBorder>
                  <PaymentContainer sm={ColumnWidth.XXS}>
                    {isLender && (defaultParticipation || defaultParticipation === 0) && (
                      <Card>
                        <CardRow>
                          <FormRow
                            fieldWidth={ColumnWidth.FULL}
                            key={LOAN_VARIABLES_FIELDS_NAMES.PARTICIPATION}
                            label={t('components.loanTermTable.columns.participation')}
                            name={LOAN_VARIABLES_FIELDS_NAMES.PARTICIPATION}
                            control={
                              <>
                                <Input
                                  name={LOAN_VARIABLES_FIELDS_NAMES.PARTICIPATION}
                                  textStyle={'bold'}
                                  validate={validateNumberValue}
                                  endAdornment={<PercentAdornment />}
                                  decimalScale={2}
                                  placeholder={t('components.input.placeholders.decimal')}
                                  isInvalid={Boolean(participationErrorMessage)}
                                  hideInitZeroValue={true}
                                  disabled={!maxParticipation}
                                  {...(isFieldChanged('participation') && { blurCallback: setHistoryCallback })}
                                />
                                <FormControlFeedback
                                  type="invalid"
                                  className="d-block"
                                  $isInvalid={Boolean(participationErrorMessage)}
                                >
                                  {participationErrorMessage ? participationErrorMessage : participationMessage}
                                </FormControlFeedback>
                              </>
                            }
                          />
                        </CardRow>
                      </Card>
                    )}
                    <PaymentBlock>
                      <CardRow>{generateFields([RAC_MW_TOTAL_AMOUNT_FINANCED_FIELD])}</CardRow>
                      <RecalculateBlock>
                        <RecalculateButton
                          buttontheme={ButtonVariant.CONTAINED}
                          onClick={calculateTotalAmountFinanced}
                          disabled={isFetching || isRecalculateButtonDisabled}
                        >
                          <RecalculateIcon disabled={isFetching || isRecalculateButtonDisabled} />
                          <div>{t('components.loanVariablesModal.buttons.recalculate')}</div>
                        </RecalculateButton>
                      </RecalculateBlock>
                    </PaymentBlock>
                  </PaymentContainer>
                </Row>
              </Col>
            </FieldsRow>
            {!isLender && loanVariables?.ltv_categories?.length > 0 && (
              <TableBlock>
                <AlternativeDealStructureTitle $uppercase $fontSize={'16px'}>
                  {t('components.loanVariablesModal.labels.availableDealStructure')}
                </AlternativeDealStructureTitle>
                <Table
                  pageSize={MAX_PAGE_SIZE}
                  currentPage={CURRENT_PAGE}
                  columns={currentColumns}
                  ltvCategories={loanVariables.ltv_categories}
                  isAllColumns={user.isWithInventory && !manuallyCreated}
                />
                <ActualPaymentInfoTitle>
                  {isLender
                    ? t('components.loanVariablesModal.infoMessages.lenderActualPayment')
                    : t('components.loanVariablesModal.infoMessages.actualPayment')}
                </ActualPaymentInfoTitle>
              </TableBlock>
            )}
            {isLender && loanVariables?.ltv_ratio && (
              <TableBlock>
                <AlternativeDealStructureTitle $uppercase $fontSize={'16px'}>
                  {t('components.loanVariablesModal.labels.availableDealStructure')}
                </AlternativeDealStructureTitle>
                <Table
                  pageSize={MAX_PAGE_SIZE}
                  currentPage={CURRENT_PAGE}
                  columns={currentColumns}
                  ltvCategories={[loanVariables]}
                  isAllColumns={false}
                />
                <ActualPaymentInfoTitle>
                  {isLender
                    ? t('components.loanVariablesModal.infoMessages.lenderActualPayment')
                    : t('components.loanVariablesModal.infoMessages.actualPayment')}
                </ActualPaymentInfoTitle>
              </TableBlock>
            )}
          </Panel>
        )}
      </div>
    </Row>
  );
};
