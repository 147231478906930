import i18n from 'i18next';

import { IFieldControl } from '../types';
import { FIELD_NAMES } from './fieldNames.const';

export const FIELDS_INFO: Record<string, IFieldControl> = {
  AMOUNT_RECEIVED: {
    label: i18n.t('components.incomeCalculator.labels.amountReceived'),
    name: FIELD_NAMES.AMOUNT_RECEIVED,
  },
  GROSS_YTD: {
    label: i18n.t('components.incomeCalculator.labels.grossYTDAmount'),
    name: FIELD_NAMES.GROSS_YTD,
  },
  HOURLY_RATE: { label: i18n.t('components.incomeCalculator.labels.hourlyRate'), name: FIELD_NAMES.HOURLY_RATE },
  HOURS_PER_WEEK: {
    label: i18n.t('components.incomeCalculator.labels.hoursPerWeek'),
    name: FIELD_NAMES.HOURS_PER_WEEK,
  },
  NYS_DISABILITY_DEDUCTED: {
    label: i18n.t('components.incomeCalculator.labels.ytdNysDisabilityDeducted'),
    name: FIELD_NAMES.NYS_DISABILITY_DEDUCTED,
  },
  PREVIOUS_YEAR_W2_AMOUNT: {
    label: i18n.t('components.incomeCalculator.labels.previousYearW2Amount'),
    name: FIELD_NAMES.PREVIOUS_YEAR_W2_AMOUNT,
  },

  DATE_OF_HIRE: { label: i18n.t('components.incomeCalculator.labels.dateOfHire'), name: FIELD_NAMES.DATE_OF_HIRE },
  PERIOD_ENDING_DATE: {
    label: i18n.t('components.incomeCalculator.labels.periodEndingDate'),
    name: FIELD_NAMES.PERIOD_ENDING_DATE,
  },
  CHECK_OR_PAY_DATE: {
    label: i18n.t('components.incomeCalculator.labels.checkOrPayDate'),
    name: FIELD_NAMES.CHECK_OR_PAY_DATE,
  },

  LOANS_LISTED_ON_PAYCHECK: {
    label: i18n.t('components.incomeCalculator.labels.loansListedOnPaycheck'),
    name: FIELD_NAMES.LOANS_LISTED_ON_PAYCHECK,
  },
  GARNISHMENTS: { label: i18n.t('components.incomeCalculator.labels.garnishments'), name: FIELD_NAMES.GARNISHMENTS },
  TEMPORARY_SERVICE_EMPLOYEE: {
    label: i18n.t('components.incomeCalculator.labels.temporaryServiceEmployee'),
    name: FIELD_NAMES.TEMPORARY_SERVICE_EMPLOYEE,
  },
  SEASONAL_EMPLOYEE: {
    label: i18n.t('components.incomeCalculator.labels.seasonalEmployee'),
    name: FIELD_NAMES.SEASONAL_EMPLOYEE,
  },

  LINE_31_SCHEDULE_C_YEAR: {
    label: i18n.t('components.incomeCalculator.labels.line31ScheduleCYear'),
    name: FIELD_NAMES.LINE_31_SCHEDULE_C_YEAR,
  },
  TOTAL_BANK_DEPOSITS: {
    label: i18n.t('components.incomeCalculator.labels.totalBankDeposits'),
    name: FIELD_NAMES.TOTAL_BANK_DEPOSITS,
  },
};
