import React, { ChangeEvent, FC, useCallback, useEffect, useMemo } from 'react';
import { FormRow, Input } from 'modules/core/components';
import { requiredValidator } from 'modules/core/validators';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getVehicleState } from 'store/selectors/vehicle.selector';
import { getSubventionYearOptions } from 'store/selectors/controls.selector';
import { Dropdown } from 'components/Modals/LoanVariablesDialog/components/common/Dropdown/Dropdown';

import { OTHER_OPTION } from '../consts/consts';

interface IFields {
  year: string;
  make: string;
  model: string;
  otherYear: string;
  otherMake: string;
  otherModel: string;
  lienHolder: string;
}

interface IDropdownFields {
  disabled: boolean;
  disabledModelField: boolean;
  formPrefix: string;
  fieldWidth: number;
  fieldsNames: IFields;
}

export const DropdownFields: FC<IDropdownFields> = ({
  disabled,
  disabledModelField,
  formPrefix,
  fieldWidth,
  fieldsNames,
}) => {
  const { t } = useTranslation();
  const { watch } = useFormContext();
  const { setValue, clearErrors } = useFormContext();
  const { year, make, model, otherYear, otherMake, otherModel, lienHolder } = watch(formPrefix) as any;
  const { vehicleModels } = useSelector(getVehicleState);
  const subventionYearOptions = useSelector(getSubventionYearOptions);

  const initialValues = useMemo(
    () => ({
      year: year,
      make: make,
      model: model,
      otherYear: otherYear,
      otherMake: otherMake,
      otherModel: otherModel,
      lienHolder: lienHolder,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const mapOptionsToFields = useMemo(
    () =>
      new Map<string, object[] | void>([
        [fieldsNames.year, subventionYearOptions],
        [
          fieldsNames.make,
          vehicleModels.map((vehicle) => {
            const vehicleName = Object.keys(vehicle)[0];

            return { title: vehicleName, value: vehicleName };
          }),
        ],
      ]),
    [fieldsNames.make, fieldsNames.year, subventionYearOptions, vehicleModels],
  );

  const getOptions = useCallback(
    (name: string) => {
      const options = [...(mapOptionsToFields.get(name) || []), OTHER_OPTION];

      return options;
    },
    [mapOptionsToFields],
  );

  const changeCallback = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.includes(' ')) {
      const trimmedValue = event.target.value.trim();

      if (trimmedValue.length <= 0) {
        event.target.value = event.target.value.trim();
      }
    }
  };

  const getModels = useCallback(() => {
    const carObj = vehicleModels.find((obj) => Object.keys(obj)[0] === String(make?.title));

    if (carObj) {
      const newCarNames = Object.values(carObj)[0].map((model: { name: any }) => ({
        title: model.name,
        value: model.name,
      }));

      return [...newCarNames, OTHER_OPTION];
    } else {
      return [OTHER_OPTION];
    }
  }, [make?.title, vehicleModels]);

  useEffect(() => {
    if (year?.value) {
      setValue(fieldsNames.otherYear, undefined);
      clearErrors(fieldsNames.otherYear);
    }
  }, [clearErrors, fieldsNames.otherYear, setValue, year?.label, year?.value]);

  useEffect(() => {
    if (make?.value) {
      setValue(fieldsNames.otherMake, undefined);
      clearErrors(fieldsNames.otherMake);
    }
  }, [clearErrors, fieldsNames.otherMake, setValue, make?.value, make?.label]);

  useEffect(() => {
    if (model?.value) {
      setValue(fieldsNames.otherModel, undefined);
      clearErrors(fieldsNames.otherModel);
    }
  }, [clearErrors, fieldsNames.otherModel, setValue, model?.value, model?.label]);

  useEffect(() => {
    setValue(fieldsNames.model, undefined);
    setValue(fieldsNames.otherModel, undefined);
  }, [fieldsNames.model, fieldsNames.otherModel, make?.title, make?.value, setValue]);

  useEffect(() => {
    if (disabled) {
      setValue(fieldsNames.lienHolder, undefined);
      setValue(fieldsNames.year, undefined);
      setValue(fieldsNames.otherYear, undefined);
      setValue(fieldsNames.make, undefined);
      setValue(fieldsNames.otherMake, undefined);
      setValue(fieldsNames.model, undefined);
      setValue(fieldsNames.otherModel, undefined);
    }
  }, [
    disabled,
    fieldsNames.lienHolder,
    fieldsNames.make,
    fieldsNames.model,
    fieldsNames.otherMake,
    fieldsNames.otherModel,
    fieldsNames.otherYear,
    fieldsNames.year,
    setValue,
  ]);

  useEffect(() => {
    setValue(fieldsNames.lienHolder, initialValues.lienHolder);
    setValue(fieldsNames.year, initialValues.year);
    setValue(fieldsNames.otherYear, initialValues.otherYear);
    setValue(fieldsNames.make, initialValues.make);
    setValue(fieldsNames.otherMake, initialValues.otherMake);
    setValue(fieldsNames.model, initialValues.model);
    setValue(fieldsNames.otherModel, initialValues.otherModel);
  }, [
    fieldsNames.model,
    fieldsNames.otherModel,
    setValue,
    fieldsNames.year,
    fieldsNames.otherYear,
    fieldsNames.make,
    fieldsNames.otherMake,
    fieldsNames.lienHolder,
    initialValues.year,
    initialValues.otherYear,
    initialValues.make,
    initialValues.otherMake,
    initialValues.model,
    initialValues.otherModel,
    initialValues.lienHolder,
  ]);

  return (
    <>
      <FormRow
        fieldWidth={fieldWidth}
        key={fieldsNames.year}
        required
        label={t('components.loanVariablesModal.modals.netTradeIn.fields.year')}
        name={fieldsNames.year}
        control={
          <Dropdown
            name={fieldsNames.year}
            options={getOptions(fieldsNames.year)}
            placeholder={t('components.loanVariablesModal.modals.netTradeIn.selectPlaceholder')}
            disabled={disabled}
            showPlaceholder={disabled}
            rules={!disabled && requiredValidator}
          />
        }
      />
      {year?.value === OTHER_OPTION.value && (
        <FormRow
          fieldWidth={fieldWidth}
          key={`${fieldsNames.otherYear}-input`}
          required
          label={t('components.loanVariablesModal.modals.netTradeIn.options.other')}
          name={fieldsNames.otherYear}
          control={
            <Input
              name={fieldsNames.otherYear}
              textStyle="bold"
              rules={requiredValidator}
              decimalScale={0}
              defaultValue={initialValues?.otherYear?.value}
              thousandSeparator={false}
            />
          }
        />
      )}
      <FormRow
        fieldWidth={fieldWidth}
        key={fieldsNames.make}
        required
        label={t('components.loanVariablesModal.modals.netTradeIn.fields.make')}
        name={fieldsNames.make}
        control={
          <Dropdown
            name={fieldsNames.make}
            options={getOptions(fieldsNames.make)}
            placeholder={t('components.loanVariablesModal.modals.netTradeIn.selectPlaceholder')}
            disabled={disabled}
            showPlaceholder={disabled}
            rules={!disabled && requiredValidator}
          />
        }
      />
      {make?.value === OTHER_OPTION.value && (
        <FormRow
          fieldWidth={fieldWidth}
          key={`${fieldsNames.make}-input`}
          required
          label={t('components.loanVariablesModal.modals.netTradeIn.options.other')}
          name={fieldsNames.otherMake}
          control={
            <Input
              name={fieldsNames.otherMake}
              valueAsNumber={false}
              textStyle="bold"
              rules={requiredValidator}
              defaultValue={initialValues?.otherMake?.value}
              maxLength={25}
              changeCallback={changeCallback}
            />
          }
        />
      )}
      <FormRow
        fieldWidth={fieldWidth}
        key={fieldsNames.model}
        required
        label={t('components.loanVariablesModal.modals.netTradeIn.fields.model')}
        name={fieldsNames.model}
        control={
          <Dropdown
            name={fieldsNames.model}
            options={getModels()}
            placeholder={t('components.loanVariablesModal.modals.netTradeIn.selectPlaceholder')}
            disabled={disabledModelField}
            showPlaceholder={disabledModelField}
            rules={!disabled && requiredValidator}
          />
        }
      />
      {model?.value === OTHER_OPTION.value && (
        <FormRow
          fieldWidth={fieldWidth}
          key={`${fieldsNames.otherModel}-input`}
          required
          label={t('components.loanVariablesModal.modals.netTradeIn.options.other')}
          name={fieldsNames.otherModel}
          control={
            <Input
              name={fieldsNames.otherModel}
              valueAsNumber={false}
              textStyle="bold"
              rules={requiredValidator}
              defaultValue={initialValues?.otherModel?.value}
              maxLength={25}
              changeCallback={changeCallback}
            />
          }
        />
      )}
    </>
  );
};
