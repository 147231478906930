import React, { FC } from 'react';
import { HeaderGroup } from 'react-table';

import { ChevronDown, ChevronUp } from '../../Icons';
import { TABLE_RENDER_HEADER_KEY } from '../table.const';
import { TableHeaderRowElement, TableHeaderCell, TableHeaderCellDiv } from './styles';

export interface ITableHeader {
  headerGroups: HeaderGroup[];
}

export const TableHeader: FC<ITableHeader> = ({ headerGroups }) => (
  <thead data-testid="vehicle-table-header">
    {headerGroups.map(({ getHeaderGroupProps, headers }) => (
      <TableHeaderRowElement {...getHeaderGroupProps()}>
        {headers.map(({ getHeaderProps, render, getSortByToggleProps, isSorted, isSortedDesc, canSort }) => {
          const sortedDescIcon = isSortedDesc ? <ChevronDown active /> : <ChevronUp />;
          const sortedIcon = isSorted ? sortedDescIcon : <ChevronDown />;

          return (
            <TableHeaderCell data-testid={render(TABLE_RENDER_HEADER_KEY)} {...getHeaderProps(getSortByToggleProps())}>
              <TableHeaderCellDiv>
                {render(TABLE_RENDER_HEADER_KEY)}
                {canSort && sortedIcon}
              </TableHeaderCellDiv>
            </TableHeaderCell>
          );
        })}
      </TableHeaderRowElement>
    ))}
  </thead>
);
