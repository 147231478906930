import { PATTERNS } from 'modules/core/constants';
import React from 'react';
import { Form } from 'react-bootstrap';
import { FieldError } from 'react-hook-form';
import { RegisterField } from 'modules/core/types/form';
import { useTranslation } from 'react-i18next';

import { LoginFormLabel, FormControl, ErrorMessage } from '../styles';

interface IEmailInput {
  label?: string;
  register: RegisterField;
  errorEmail?: FieldError;
  placeholder?: string;
  resetPasswordError?: string;
  autoComplete: string;
}

export const EmailInput: React.FC<IEmailInput> = ({
  label,
  register,
  errorEmail,
  placeholder,
  resetPasswordError,
  autoComplete,
}) => {
  const { t } = useTranslation();
  const validationError = errorEmail?.message || resetPasswordError;

  return (
    <Form.Group>
      <LoginFormLabel htmlFor="email">{label}</LoginFormLabel>
      <FormControl
        name="email"
        placeholder={placeholder}
        ref={register({
          required: true,
          pattern: {
            value: PATTERNS.EMAIL_PATTERN,
            message: t('common.errorMessages.emailMessage'),
          },
        })}
        type="email"
        autoComplete={autoComplete}
      />
      {validationError && <ErrorMessage>{validationError}</ErrorMessage>}
    </Form.Group>
  );
};
