/* eslint-disable prettier/prettier */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getLenderControlsThunk, getLendersListThunk, getLenderThunk } from 'actions/lenderAction';
import {
  getLenderVehiclesByStockThunk,
  getVehiclesForLenderThunk,
} from 'actions/vehicleActions';
import { IIncomeForm } from 'components/IncomeCalculator/types';
import { ILenderVehicle, ILenderVehicleByStock, IVehicleFiltersDirtyFields } from 'modules/core/types';
import { INCOME_INITIAL_VALUES, INITIAL_VALUES_ONE_LENDER, LTV_INC_TAXES_FEES } from 'modules/Lender/Lender.const';
import { ILenderControlsResponse, ILenderForm, ILenderRequest, ILendersListItem } from 'modules/Lender/types/Lender.types';

export interface ILenderState extends ILenderRequest {
  name: string;
  path: string;
  initialValues: ILenderForm;
  vehicles: {
    vehiclesByParams?: ILenderVehicle[];
    vehiclesByStock?: ILenderVehicle[];
  };
  income: IIncomeForm;
  isTableView: boolean;
  vehicleFiltersDirtyFields: IVehicleFiltersDirtyFields;
  isReset: boolean;
  isFetchingVehicles: boolean;
  isFetchingVehiclesCount: boolean;
  isFetchingVehiclesFailed: boolean;
  notFoundVehicles: string[];
  notAvailableVehicles: string[];
}

export interface ITotalVehicles {
  totalVehiclesByParams?: number;
  totalVehiclesByStock?: number;
}

export interface ILendersState {
  lendersHashTable: Record<string, ILenderState>;
  isFetchingLendersList: boolean;
  isFetchingLender: boolean;
  currentLenderId: string;
  currentLenderControls: {
    maxVSC: number;
    maxGap: number;
    showRates: boolean;
  },
}

const initialState: ILendersState = {
  lendersHashTable: {},
  isFetchingLendersList: true,
  isFetchingLender: false,
  currentLenderId: '',
  currentLenderControls: {
    maxVSC: 0,
    maxGap: 0,
    showRates: false,
  },
};

export const initialStateOneLender: ILenderState = {
  name: '',
  path: '',
  initialValues: INITIAL_VALUES_ONE_LENDER,
  id: 0,
  vehicles: {
    vehiclesByParams: undefined,
    vehiclesByStock: undefined,
  },
  income: { totalIncome: 0, savedIncomes: INCOME_INITIAL_VALUES },
  isReset: false,
  isTableView: true,
  guidebooks: [],
  logo: '',
  ltv_calculation_method: '',
  status: '',
  tiers: [],
  vehicleFiltersDirtyFields: {},
  isFetchingVehicles: false,
  isFetchingVehiclesCount: false,
  isFetchingVehiclesFailed: false,
  notFoundVehicles: [],
  notAvailableVehicles: [],
};

export const lenderSlice = createSlice({
  name: 'lenders',
  initialState,
  reducers: {
    syncInitialValues: (state: ILendersState, { payload: initialValues }: PayloadAction<ILenderForm>) => ({
      ...state,
      lendersHashTable: {
        ...state.lendersHashTable,
        [state.currentLenderId]: { ...state.lendersHashTable[state.currentLenderId], initialValues },
      },
    }),
    setCurrentLenderId: (state: ILendersState, { payload }: PayloadAction<string>) => ({
      ...state,
      currentLenderId: payload,
    }),
    resetVehicles: (state: ILendersState) => ({
      ...state,
      lendersHashTable: {
        ...state.lendersHashTable,
        [state.currentLenderId]: {
          ...state.lendersHashTable[state.currentLenderId],
          vehicles: {
            vehiclesByParams: undefined,
            vehiclesByStock: undefined,
          },
          totalVehicles: {
            totalVehiclesByParams: undefined,
            totalVehiclesByStock: undefined,
          },
        },
      },
    }),
    resetVehiclesByStock: (state: ILendersState) => ({
      ...state,
      lendersHashTable: {
        ...state.lendersHashTable,
        [state.currentLenderId]: {
          ...state.lendersHashTable[state.currentLenderId],
          vehicles: {...state.lendersHashTable[state.currentLenderId].vehicles, vehiclesByStock: undefined},
        },
      },
    }),
    resetVehiclesByParams: (state: ILendersState) => ({
      ...state,
      lendersHashTable: {
        ...state.lendersHashTable,
        [state.currentLenderId]: {
          ...state.lendersHashTable[state.currentLenderId],
          vehicles: {...state.lendersHashTable[state.currentLenderId].vehicles, vehiclesByParams: undefined},
        },
      },
    }),
    resetByStockOptions: (state: ILendersState) => ({
      ...state,
      lendersHashTable: {
        ...state.lendersHashTable,
        [state.currentLenderId]: {
          ...state.lendersHashTable[state.currentLenderId],
          notFoundVehicles: [],
          notAvailableVehicles: [],
        },
      },
    }),
    resetByStock: (state: ILendersState) => ({
      ...state,
      lendersHashTable: {
        ...state.lendersHashTable,
        [state.currentLenderId]: {
          ...state.lendersHashTable[state.currentLenderId],
          vehicles: {...state.lendersHashTable[state.currentLenderId].vehicles, vehiclesByStock: undefined},
          notFoundVehicles: [],
          notAvailableVehicles: [],
        },
      },
    }),
    setIncome: (state: ILendersState, { payload: income }: PayloadAction<IIncomeForm>) => ({
      ...state,
      lendersHashTable: {
        ...state.lendersHashTable,
        [state.currentLenderId]: {
          ...state.lendersHashTable[state.currentLenderId],
          income,
        },
      },
    }),
    resetIncome: (state: ILendersState) => ({
      ...state,
      lendersHashTable: {
        ...state.lendersHashTable,
        [state.currentLenderId]: {
          ...state.lendersHashTable[state.currentLenderId],
          income: initialStateOneLender.income,
        },
      },
    }),
    setIsReset: (state: ILendersState, { payload }: PayloadAction<boolean>) => ({
      ...state,
      lendersHashTable: {
        ...state.lendersHashTable,
        [state.currentLenderId]: {
          ...state.lendersHashTable[state.currentLenderId],
          isReset: payload,
        },
      },
    }),
    resetVehicleFiltersDirtyFields: (state: ILendersState) => ({
      ...state,
      lendersHashTable: {
        ...state.lendersHashTable,
        [state.currentLenderId]: {
          ...state.lendersHashTable[state.currentLenderId],
          vehicleFiltersDirtyFields: {},
        },
      },
    }),
    setIsTableView: (state: ILendersState, { payload }: PayloadAction<boolean>) => ({
      ...state,
      lendersHashTable: {
        ...state.lendersHashTable,
        [state.currentLenderId]: {
          ...state.lendersHashTable[state.currentLenderId],
          isTableView: payload,
        },
      },
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(
      getLendersListThunk.fulfilled,
      (state: ILendersState, { payload }: PayloadAction<ILendersListItem[]>) => ({
        ...state,
        lendersHashTable: payload.reduce((lenderListObj, lender) => {
          const fullId: string = lender.path.split('/')[2];
          const id = Number(fullId.slice(0, fullId.indexOf('-')));

          return {
            ...lenderListObj,
            [fullId]: {
              ...initialStateOneLender,
              ...lender,
              id,
            },
          };
        }, {} as Record<string, ILenderState>),
        isFetchingLendersList: false,
      }),
    );
    builder.addCase(getLendersListThunk.pending, (state: ILendersState) => ({
      ...state,
      isFetchingLendersList: true,
    }));
    builder.addCase(getLendersListThunk.rejected, (state: ILendersState) => ({
      ...state,
      isFetchingLendersList: false,
    }));
    builder.addCase(getLenderThunk.fulfilled, (state: ILendersState, { payload }: PayloadAction<ILenderRequest>) => ({
      ...state,
      lendersHashTable: {
        ...state.lendersHashTable,
        [state.currentLenderId]: {
          ...state.lendersHashTable[state.currentLenderId],
          ...payload,
          initialValues: {
            ...state.lendersHashTable[state.currentLenderId].initialValues,
            preferences: {
              ...state.lendersHashTable[state.currentLenderId].initialValues.preferences,
              ltvTaxesFeesFilter:
                // TODO: need to change ltvTaxesFeesFilter type to boolean in all project
                state.lendersHashTable[state.currentLenderId].ltv_calculation_method === '' ?
                  (payload.ltv_calculation_method === LTV_INC_TAXES_FEES ? 'true' : 'false') :
                  state.lendersHashTable[state.currentLenderId].initialValues.preferences.ltvTaxesFeesFilter,
            },
          },
        },
      },
      isFetchingLender: false,
    }));
    builder.addCase(getLenderThunk.pending, (state: ILendersState) => ({
      ...state,
      isFetchingLender: true,
    }));
    builder.addCase(getLenderThunk.rejected, (state: ILendersState) => ({
      ...state,
      isFetchingLender: false,
    }));
    builder.addCase(
      getVehiclesForLenderThunk.fulfilled,
      (state: ILendersState, { payload: vehicles }: PayloadAction<ILenderVehicle[]>) => ({
        ...state,
        lendersHashTable: {
          ...state.lendersHashTable,
          [state.currentLenderId]: {
            ...state.lendersHashTable[state.currentLenderId],
            vehicles: {...state.lendersHashTable[state.currentLenderId].vehicles, vehiclesByParams: Array.isArray(vehicles) ? vehicles : []},
            isFetchingVehicles: false,
            isFetchingVehiclesCount: false,
            isFetchingVehiclesFailed: false,
          },
        },
      }),
    );
    builder.addCase(getVehiclesForLenderThunk.pending, (state: ILendersState) => ({
      ...state,
      lendersHashTable: {
        ...state.lendersHashTable,
        [state.currentLenderId]: {
          ...state.lendersHashTable[state.currentLenderId],
          vehicles: {...state.lendersHashTable[state.currentLenderId].vehicles, vehiclesByParams: []},
          isReset: false,
          isFetchingVehicles: true,
          isFetchingVehiclesCount: true,
          isFetchingVehiclesFailed: false,
        },
      },
    }));
    builder.addCase(getVehiclesForLenderThunk.rejected, (state: ILendersState) => ({
      ...state,
      lendersHashTable: {
        ...state.lendersHashTable,
        [state.currentLenderId]: {
          ...state.lendersHashTable[state.currentLenderId],
          vehicles: {...state.lendersHashTable[state.currentLenderId].vehicles, vehiclesByParams: []},
          isFetchingVehicles: false,
          isFetchingVehiclesCount: false,
          isFetchingVehiclesFailed: true,
        },
      },
    }));

    builder.addCase(
      getLenderVehiclesByStockThunk.fulfilled,
      (state: ILendersState, { payload }: PayloadAction<ILenderVehicleByStock>) => ({
        ...state,
        lendersHashTable: {
          ...state.lendersHashTable,
          [state.currentLenderId]: {
            ...state.lendersHashTable[state.currentLenderId],
            vehicles: {...state.lendersHashTable[state.currentLenderId].vehicles, vehiclesByStock: payload.vehicles},
            notFoundVehicles: payload.search_result.not_in_db,
            notAvailableVehicles: payload.search_result.not_available,
            isFetchingVehicles: false,
            isFetchingVehiclesCount: false,
            isFetchingVehiclesFailed: false,
          },
        },
      }),
    );

    builder.addCase(getLenderVehiclesByStockThunk.pending, (state: ILendersState) => ({
      ...state,
      lendersHashTable: {
        ...state.lendersHashTable,
        [state.currentLenderId]: {
          ...state.lendersHashTable[state.currentLenderId],
          vehicles: {...state.lendersHashTable[state.currentLenderId].vehicles, vehiclesByStock: []},
          isReset: false,
          isFetchingVehicles: true,
          isFetchingVehiclesCount: true,
          isFetchingVehiclesFailed: false,
        },
      },
    }));

    builder.addCase(getLenderVehiclesByStockThunk.rejected, (state: ILendersState) => ({
      ...state,
      lendersHashTable: {
        ...state.lendersHashTable,
        [state.currentLenderId]: {
          ...state.lendersHashTable[state.currentLenderId],
          vehicles: {...state.lendersHashTable[state.currentLenderId].vehicles, vehiclesByStock: []},
          isFetchingVehicles: false,
          isFetchingVehiclesCount: false,
          isFetchingVehiclesFailed: true,
        },
      },
    }));
    builder.addCase(getLenderControlsThunk.fulfilled, (state: ILendersState, { payload }: PayloadAction<ILenderControlsResponse>) => ({
      ...state,
      currentLenderControls: {
        maxVSC: payload.max_vsc_amount,
        maxGap: payload.max_gap_amount,
        showRates: payload.show_rates === 'yes',
      },
      isFetchingLender: false,
    }));
    builder.addCase(getLenderControlsThunk.pending, (state: ILendersState) => ({
      ...state,
      isFetchingLender: true,
    }));
    builder.addCase(getLenderControlsThunk.rejected, (state: ILendersState) => ({
      ...state,
      isFetchingLender: false,
    }));
  },
});

export const {
  syncInitialValues,
  setCurrentLenderId,
  resetVehicles,
  resetVehiclesByStock,
  resetVehiclesByParams,
  setIncome,
  resetIncome,
  resetByStockOptions,
  resetByStock,
  setIsReset,
  resetVehicleFiltersDirtyFields,
  setIsTableView,
} = lenderSlice.actions;
