import { VEHICLE_STATUS_BADGE_PROPS } from 'modules/core/components/Badge/common.const';
import { IBadge } from 'modules/core/components/Badge/Badge';

const VEHICLE_JUST_ARRIVED_DAYS = 20;

export const useVehicleStatusBadgeProps = (
  vehicleAge: number | null,
  isNew?: boolean,
  is_sold?: boolean,
): IBadge | null => {
  if (isNew) {
    return VEHICLE_STATUS_BADGE_PROPS.NEW;
  }

  if (is_sold) {
    return VEHICLE_STATUS_BADGE_PROPS.PENDING_SALE;
  }

  if (!vehicleAge) {
    return null;
  }

  return vehicleAge < VEHICLE_JUST_ARRIVED_DAYS ? VEHICLE_STATUS_BADGE_PROPS.JUST_ARRIVED : null;
};
