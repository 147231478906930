import styled from 'styled-components';
import { Container } from 'react-bootstrap';
import { COLORS } from 'modules/core/theme/colors';

export const ContainerFluid = styled(Container)`
  padding: 0;
`;

export const PageContainer = styled.div`
  display: flex;
  min-height: calc(100vh - 45px - 44px);
  position: relative;
`;

export const PageContent = styled(Container)<{ isSinglePage: boolean }>`
  width: ${({ isSinglePage }) => !isSinglePage && 'calc(100% - 50px)'};
  background-color: #f6f6f6;
  padding: 10px 15px;
  margin-left: ${({ isSinglePage }) => !isSinglePage && '48px'};
`;

export const DisabledContainer = styled.div`
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: ${COLORS.GREY_8};
  height: 100%;
  z-index: 5;
  opacity: 0.3;
  margin-left: 48px;
`;

export const PlaceholderTitle = styled.span`
  margin-bottom: 8px;
  font-size: 18px;
  font-style: italic;
  font-weight: ${({ theme: { boldText } }) => boldText.dark};
  color: ${COLORS.GREY_3};
  text-align: center;
`;

export const VINSpan = styled.span`
  cursor: pointer;
`;
