import styled from 'styled-components';
import { Carousel, Image } from 'react-bootstrap';
import { PlaceholderTitle } from 'common/styles';

export const Container = styled.div`
  position: relative;
  min-height: 480px;
  padding-bottom: 24px;

  ${PlaceholderTitle} {
    position: absolute;
    bottom: 50%;
    left: 16px;
    right: 16px;
    transform: translateY(-50%);
  }
`;

export const Title = styled.span`
  display: inline-block;
  margin-bottom: 16px;
  font-size: ${({ theme: { fontSize } }) => fontSize.normal};
  font-weight: ${({ theme: { boldText } }) => boldText.dark};
`;

export const CarouselUI = styled(Carousel)`
  .carousel-control-prev {
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.0001) 100%);
  }

  .carousel-control-next {
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.0001) 0%, rgba(0, 0, 0, 0.5) 100%);
  }
`;

export const CarouselItem = styled(Carousel.Item)`
  height: 450px;
`;

export const ImageUI = styled(Image)`
  height: 100%;
  min-width: 100%;
  width: 100%;
  object-fit: contain;
  object-position: center center;
`;
