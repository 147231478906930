import styled from 'styled-components';
import { Button as ButtonUI } from 'react-bootstrap';
import { ButtonVariant } from 'modules/core/enums';
import { ButtonTheme } from 'modules/core/types';
import { COLORS } from 'modules/core/theme/colors';
import { WindowBreakpoints } from 'modules/core/enums/windowBreakpoints.enum';

interface IButtonStyles {
  backGround: string;
  borderColor: string;
  hoverBackGround: string;
  disabledBackGround: string;
  disabledBorderColor: string;
  textColor: string;
}

const CONTAINED_BUTTON_STYLES: IButtonStyles = {
  backGround: COLORS.BLUE_1,
  borderColor: COLORS.BLUE_2,
  hoverBackGround: COLORS.BLUE_3,
  disabledBackGround: COLORS.GREY_9,
  disabledBorderColor: COLORS.GREY_9,
  textColor: COLORS.WHITE,
};

const OUTLINE_BUTTON_STYLES: IButtonStyles = {
  backGround: COLORS.WHITE,
  borderColor: COLORS.BLUE_1,
  hoverBackGround: COLORS.WHITE,
  disabledBackGround: COLORS.GREY_9,
  disabledBorderColor: COLORS.GREY_9,
  textColor: COLORS.BLUE_1,
};

const MAP_BUTTON_THEME = new Map<ButtonTheme, IButtonStyles>([
  [ButtonVariant.OUTLINE, OUTLINE_BUTTON_STYLES],
  [ButtonVariant.CONTAINED, CONTAINED_BUTTON_STYLES],
]);

export const Button = styled(ButtonUI)<{ buttontheme: ButtonTheme; width?: string }>`
  min-height: 35px;
  min-width: ${({ width }) => (width ? `${width}` : '155px')};
  color: ${({ buttontheme }) => MAP_BUTTON_THEME.get(buttontheme)?.textColor};
  font-size: ${({ theme: { fontSize } }) => fontSize.button};
  line-height: ${({ theme: { fontSize } }) => fontSize.button};
  white-space: nowrap;

  @media (max-width: ${WindowBreakpoints.medium}px) {
    min-width: ${({ width }) => (width ? `${width}` : '130px')};
    font-size: ${({ theme: { fontSize } }) => fontSize.sm};
    line-height: ${({ theme: { fontSize } }) => fontSize.sm};
  }

  &,
  &.btn-primary:not(:disabled):active,
  &:focus:not(:disabled) {
    background-color: ${({ buttontheme }) => MAP_BUTTON_THEME.get(buttontheme)?.backGround};
    border-color: ${({ buttontheme }) => MAP_BUTTON_THEME.get(buttontheme)?.borderColor};
    color: ${({ buttontheme }) => MAP_BUTTON_THEME.get(buttontheme)?.textColor};
  }

  &:disabled {
    background-color: ${({ buttontheme }) => MAP_BUTTON_THEME.get(buttontheme)?.disabledBackGround};
    border-color: ${({ buttontheme }) => MAP_BUTTON_THEME.get(buttontheme)?.disabledBorderColor};
    opacity: 1;
    box-shadow: none;
    pointer-events: none;
  }

  &:hover {
    background-color: ${({ buttontheme }) => MAP_BUTTON_THEME.get(buttontheme)?.hoverBackGround};
    color: ${({ buttontheme }) => MAP_BUTTON_THEME.get(buttontheme)?.textColor};
  }
`;

export const PrependIconWrapper = styled.span`
  margin-right: 8px;
`;
