import styled from 'styled-components';
import { Col } from 'react-bootstrap';
import { disabledSectionMixin } from 'modules/core/theme/mixins';
import { STATIC_IMAGE_URLS } from 'modules/core/constants';
import { WindowBreakpoints } from 'modules/core/enums/windowBreakpoints.enum';

export const SummaryButtonGroup = styled.div`
  display: flex;
  width: 400px;
  justify-content: space-between;

  @media (max-width: ${WindowBreakpoints.small}px) {
    width: auto;

    & > :nth-child(1),
    & > :nth-child(2) {
      display: none;
    }
  }
`;

export const ApprovedLoanCol = styled(Col)`
  margin-bottom: 10px;

  @media (max-width: ${WindowBreakpoints.xxxLarge + 1}px) {
    &:nth-child(1) {
      flex: 0 0 100%;
      max-width: 100%;
    }

    &:nth-child(2) {
      flex: 0 0 50%;
      max-width: 50%;
    }

    &:nth-child(3) {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }

  @media (max-width: ${WindowBreakpoints.small}px) {
    &:nth-child(2) {
      flex: 0 0 100%;
      max-width: 100%;
    }

    &:nth-child(3) {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
`;

export const ButtonPrint = styled.div<{ disabled?: boolean }>`
  background-image: url(${STATIC_IMAGE_URLS.icons.print});
  background-size: cover;
  background-repeat: no-repeat;
  height: 35px;
  width: 35px;
  border: none;
  cursor: pointer;
  margin: 0 10px;

  ${({ disabled }) => disabled && disabledSectionMixin}

  @media (max-width: ${WindowBreakpoints.small}px) {
    display: none;
  }
`;
