import { createSelector } from '@reduxjs/toolkit';
import { IIncomeForm } from 'components/IncomeCalculator/types';
import { IColumn, TableData } from 'modules/core/components/Table/types';
import { RETAIL_NAME } from 'modules/core/constants/report.const';
import { ILenderVehicle, ITableViewReport, ITier } from 'modules/core/types';
import { IFetchingVehicleFlags } from 'modules/core/types/fetchingVehicleFlags';
import { IRACVehicle } from 'modules/core/types/vehicle';
import { IRACAffordabilityEngineForm } from 'modules/RACAffordabilityEngine/types/RACAffordabilityEngineForm';
import { IRACAffordabilityEngineState } from 'store/reducers/RACAffordabilityEngine.reducer';
import { toIncomeCalculatorReportData } from 'modules/core/utils/selectors/incomeReport';
import { ILoanVariables } from 'components/Modals/LoanVariablesDialog/types';
import { CreditScoreColors } from 'modules/ProbabilityEstimator/enums/creditScoreColors.enum';
import { IRouteOneData, IRouteOneDataFlags } from 'modules/core/types/routeOne';
import { IRegionControls } from 'modules/core/types/region';
import { IRateSheetData } from 'modules/core/types/rateSheets';
import i18n from 'i18n';

import { getSelectedRows, getTableVisibleColumns } from './probabilityEstimator.selector';
import { RootState } from '../store';

export const getRACState = (state: RootState): IRACAffordabilityEngineState => state.RACAffordabilityEngine;

export const getRACInitialValues = (state: RootState): IRACAffordabilityEngineForm => getRACState(state).initialValues;

export const getIncome = (state: RootState): IIncomeForm => getRACState(state).income;

export const getVehiclesByParams = (state: RootState): IRACVehicle[] | undefined =>
  getRACState(state).vehicles.vehiclesByParams;

export const getVehiclesByStock = (state: RootState): IRACVehicle[] | undefined =>
  getRACState(state).vehicles.vehiclesByStock;

export const getNotFoundVehicles = (state: RootState): string[] => getRACState(state).notFoundVehicles;

export const getFirstRouteOneRender = (state: RootState): boolean => getRACState(state).firstRouteOneRender;

export const getNotAvailableVehicles = (state: RootState): string[] => getRACState(state).notAvailableVehicles;

export const getRouteOneData = (state: RootState): IRouteOneData => getRACState(state).routeOneData;

export const getSetupRateSheetsData = (state: RootState): IRateSheetData => getRACState(state).ratesheetsData;

export const getRateSheetsDataFlags = (state: RootState) => {
  const rateSheetsDataFlags = getRACState(state).rateSheetsDataFlags;

  return { ...rateSheetsDataFlags };
};

export const getVehicleFetchingFlags = (state: RootState): IFetchingVehicleFlags => {
  const { isFetchingVehicles, isFetchingVehiclesFailed, isReset } = getRACState(state);

  return { isFetchingVehicles, isFetchingVehiclesFailed, isReset };
};

export const getLoanVariablesFetchingFlag = (state: RootState) => {
  const { isFetchingLoanVariablesInfo } = getRACState(state);

  return { isFetchingLoanVariablesInfo };
};

export const getRoteOneDataFlags = (state: RootState): IRouteOneDataFlags => {
  const { routeOneDataFlags } = getRACState(state);

  return { ...routeOneDataFlags };
};

export const getTiers = (state: RootState): ITier[] =>
  getRACState(state).tiers.map((tier) => ({ ...tier, color: CreditScoreColors.GREEN })) || [];

export const getLoanVariablesInfo = (state: RootState): ILoanVariables => getRACState(state).loanVariablesInfo;

export const getRegionControls = (state: RootState): IRegionControls => getRACState(state).regionControls;

const toRACReportData = (
  racData: IRACAffordabilityEngineState,
  tableSelectedRows: TableData[],
  tableVisibleColumns: IColumn[],
): ITableViewReport => {
  const {
    totalNetDownPaymentDialog: { cashDown, tradeValue, amountOwed },
    desiredMonthlyPayment,
    feesAndTaxes,
    grossMonthlyIncome,
    tier,
  } = racData.initialValues;

  return {
    disclaimer: {
      amountOwed,
      cashDown,
      desiredMonthly: desiredMonthlyPayment,
      grossMonthlyIncome: grossMonthlyIncome,
      isRac: true,
      tier_name: i18n.t('components.loanVariablesModal.fields.tier', { index: tier.tier }),
      isProbability: false,
      tradeValue,
      salesTaxRate: feesAndTaxes.salesTaxRate || 0,
      salesFees: feesAndTaxes.estimatedFees || 0,
    },
    type: RETAIL_NAME,
    vehicles: tableSelectedRows,
    vehicle_parameters: tableVisibleColumns,
  };
};

export const getRACReportData = createSelector(getRACState, getSelectedRows, getTableVisibleColumns, toRACReportData);
export const getIsTableView = (state: RootState): boolean => getRACState(state).isTableView;

export const getIncomeCalculatorReportData = createSelector(getIncome, toIncomeCalculatorReportData);

export const getLoanVariablesCurrentVehicle = (state: RootState): IRACVehicle | ILenderVehicle | undefined =>
  getRACState(state).loanVariablesCurrentVehicle;
