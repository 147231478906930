import { createAsyncThunk } from '@reduxjs/toolkit';
import { dealerApi } from 'api';
import { ALL_STORES_OPTION } from 'modules/core/components/VehicleFilters/options.const';
import { COLON_SYMBOL } from 'modules/core/constants';

import { DEALER_PREFIX, STORE_PREFIX } from './constants';
import { ACTION_PREFIX } from './actionPrefix.const';

export const getAllowedDealersThunk = createAsyncThunk(`${ACTION_PREFIX.DEALER}/allowedDealers`, async () => {
  const { data } = await dealerApi.getAllowedDealers();

  return data;
});

// TODO: refactor this thunk and split its logic.
export const getDealerStoresThunk = createAsyncThunk(`${ACTION_PREFIX.DEALER}/stores`, async () => {
  const { data: dealerStores } = await dealerApi.getDealerStores();
  const { data: allowedDealers } = await dealerApi.getAllowedDealers();

  return [
    ALL_STORES_OPTION,
    ...dealerStores.map(({ name }) => ({
      title: name,
      value: `${STORE_PREFIX}${COLON_SYMBOL}${name}`,
    })),
    ...allowedDealers.map(({ shortname, name }) => ({
      title: shortname,
      value: `${DEALER_PREFIX}${COLON_SYMBOL}${name}`,
    })),
  ];
});

export const getPureDealerStoresThunk = createAsyncThunk(`${ACTION_PREFIX.DEALER}/pureStore`, async () => {
  const { data } = await dealerApi.getDealerStores();

  return data;
});

export const getDealersThunk = createAsyncThunk(`${ACTION_PREFIX.DEALER}/getDealers`, async () => {
  const { data } = await dealerApi.getDealers();

  return data;
});
