export const formatUrl = (currentUrl: string, pageLink: string): string => {
  // currentUrl example: '/learning/start/jHGffVv'
  // currentPage example: 'start'
  const currentPage = currentUrl.split('/')[2];

  // when the page will be changed the 'start' in the '/learning/start/jHGffVv' will be replaced with next/previous page name' ('key-factors' for instance)
  return currentUrl.replace(currentPage, pageLink);
};

export const getPathnameWithoutLastElement = (): string => {
  const currentUrl = window.location.pathname;

  return currentUrl.slice(0, currentUrl.lastIndexOf('/'));
};
