import { RefObject, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setFullSideBar } from 'store/reducers/ui.reducer';
import { AppDispatch } from 'store/store';

export const useClickOutsideSideBar = (ref: RefObject<HTMLElement>, fullSideBar: boolean) => {
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    function handleClickOutside(event: any) {
      const eventTargetElement = document.getElementsByClassName(event.srcElement.className)[0];

      if (
        fullSideBar &&
        typeof eventTargetElement !== 'undefined' &&
        ref.current &&
        !ref.current.contains(eventTargetElement)
      ) {
        dispatch(setFullSideBar(!fullSideBar));
      }
    }

    document.addEventListener('click', handleClickOutside);
    document.addEventListener('touch', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
      document.removeEventListener('touch', handleClickOutside);
    };
  }, [fullSideBar, dispatch, ref]);
};
