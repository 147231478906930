/* eslint-disable prettier/prettier */
import React, { useCallback, useState, useEffect, useMemo, useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Option } from 'react-multi-select-component/dist';
import { useUncontrolledInput } from 'modules/core/hooks';
import { IOption, ISelectable } from 'modules/core/types';
import {
  COMMA_WITH_BLANK_SPACE,
  EMPTY_STRING,
  ICONS_HEIGHT,
  STATIC_IMAGE_URLS,
} from 'modules/core/constants';
import { Input as InputUI } from 'modules/core/components/Input/styles';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getVehicleState } from 'store/selectors/vehicle.selector';

import {
  convertOptionsToMultiselectFormat,
  convertFormValueToMultiselectOptions,
  setModelOptionsOnMakesToggle,
  toggleOption,
} from './Multiselect.utils';
import { FILTERS_FIELD_NAMES, FILTER_FIELDS_SUBSCRIPTION } from '../VehicleFilters/vehicleFilters.const';
import { ALL_VALUES, CURRENT_OPTION_VALUE, MAP_MULTISELECT_GROUP_OPTIONS_BY_SELECT_NAME, MULTIPLY_VALUES_OPTIONS } from './Multiselect.const';
import { MultiSelectUI } from '../SimpleMultiSelect/styles';
import { MultiSelectWrapper } from './styles';
import { Icon } from '../Icon';

export interface IMultiselect extends Omit<ISelectable, 'options'> {
  name: string;
  options?: IOption[];
  isDisabled?: boolean;
  rules?: Record<string, any>;
}

const filterOptions = (options: Option[], filter: string) => {
  if (!filter) return options;

  return options.filter(({ label }) => label?.toLowerCase().includes(filter.toLowerCase()));
};

export const Multiselect: React.FC<IMultiselect> = ({ name, options = [], isDisabled = false, rules = {} }) => {
  const multiselectRef = useRef<HTMLDivElement>(null);
  const multiselectOptions: Option[] = useMemo(() => convertOptionsToMultiselectFormat(options), [options]);

  if (name === FILTERS_FIELD_NAMES.MODELS && options?.length){
    multiselectOptions.sort((a, b) => {
      if (a.value === ALL_VALUES || b.value === ALL_VALUES) return 0;

      if (a.value.toLowerCase() > b.value.toLowerCase()) return 1;

      if (a.value.toLowerCase() < b.value.toLowerCase()) return -1;

      return 0;
    });
  }

  const [selected, setSelected] = useState<Option[]>([]);
  const { controlField } = useUncontrolledInput(name);
  const { watch, setValue } = useFormContext();
  const { pathname } = useLocation();
  const formValue: string = watch(name);
  const { vehicleModels } = useSelector(getVehicleState);
  const { [FILTERS_FIELD_NAMES.MODELS]: models } = watch(FILTER_FIELDS_SUBSCRIPTION);

  useEffect(() => {
    if (formValue === EMPTY_STRING) setSelected([]);

    if (formValue) {
      const currentSelectedOptions: Option[] = convertFormValueToMultiselectOptions(formValue);

      setSelected(currentSelectedOptions);
    }
  }, [pathname, multiselectOptions, formValue, name]);

  const handleMultiselectChange = useCallback(
    (value: Option[]) => {
      const currentOptionValue = watch(CURRENT_OPTION_VALUE);

      const currentSelectValues = toggleOption(
        name,
        value,
        options,
        multiselectOptions,
        value.length ? currentOptionValue : { name: null, checked: null },
      );

      setModelOptionsOnMakesToggle(name, currentOptionValue, models, vehicleModels, currentSelectValues, setValue);

      const formattedValue = currentSelectValues.map((option) => option.value);

      setSelected(currentSelectValues);
      setValue(name, formattedValue.join(COMMA_WITH_BLANK_SPACE));
    },
    [watch, setValue, name, options, multiselectOptions, models, vehicleModels],
  );

  // set label (current selected checkboxes) in multiselect head.
  const customValueRenderer = useCallback(
    (selectedValues: Option[]) => {
      const currentMultiselectGroupOptions = MAP_MULTISELECT_GROUP_OPTIONS_BY_SELECT_NAME.get(name);

      if (!selectedValues.length) return [ALL_VALUES];

      const groupOption = selectedValues.filter(
        (option: Option) =>
          currentMultiselectGroupOptions?.some((groupOptionValue) => option.value === groupOptionValue) ||
          option.value === ALL_VALUES,
      );

      if (!groupOption.length) {
        return selectedValues.map((option: Option, index: number) =>
          index === selectedValues.length - 1 ? option.label : option.label + COMMA_WITH_BLANK_SPACE,
        );
      }

      return groupOption.map((option: Option) => option.label);
    },
    [name],
  );

  // set last modified checkbox in React-hook-form state
  const clickHandler = useCallback(
    (event: React.MouseEvent<HTMLDivElement, Event>) => {
      const { target }: any = event;
      let targetOptionValue = EMPTY_STRING;

      if (
        target.textContent &&
        target.textContent !== 'Select...' &&
        target.tagName !== 'UL' &&
        target.tagName !== 'INPUT'
      ) {
        targetOptionValue = target.textContent;
      } else if (target.tagName === 'INPUT' && !targetOptionValue && target.nextElementSibling?.textContent) {
        targetOptionValue = target.nextElementSibling.textContent;
      }

      const isSelected = targetOptionValue === ALL_VALUES
        ? selected.length === options.filter((option) => !MULTIPLY_VALUES_OPTIONS.includes(option.title)).length
        : selected.some(
          (option) =>
            option.label === targetOptionValue ||
            option.value === multiselectOptions.find((item) => item.label === targetOptionValue)?.value,
        );

      setValue(CURRENT_OPTION_VALUE, { name: targetOptionValue, selected: !isSelected });
    },
    [setValue, selected, options, multiselectOptions],
  );

  const MAX_BOTTOM_HEIGHT = 420;

  const maxHeight = window.top
    ? window.top.innerHeight - (multiselectRef?.current?.getBoundingClientRect()?.bottom || 0)
    : 0;

  const isRenderUpward = maxHeight < MAX_BOTTOM_HEIGHT;

  const renderMultiselect = useCallback(
    () => (
      /* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
      <MultiSelectWrapper onClick={clickHandler} ref={multiselectRef}>
        <MultiSelectUI
          options={multiselectOptions}
          value={selected}
          onChange={(values: Option[]) => handleMultiselectChange(values)}
          labelledBy={EMPTY_STRING}
          hasSelectAll={false}
          valueRenderer={customValueRenderer}
          filterOptions={filterOptions}
          disabled={isDisabled}
          isRenderUpward={isRenderUpward}
          ArrowRenderer={() => <Icon imageSrc={STATIC_IMAGE_URLS.icons.arrowIcon} height={ICONS_HEIGHT.XXS} />}
        />
      </MultiSelectWrapper>
    ),
    [multiselectOptions, selected, handleMultiselectChange, isDisabled, isRenderUpward, clickHandler, customValueRenderer],
  );

  return (
    <InputUI textstyle="normal" сlass="multiselect">
      <Controller render={renderMultiselect} defaultValue={[]} control={controlField} name={name} rules={rules} />
    </InputUI>
  );
};
