import styled from 'styled-components';
import { Button } from 'modules/core/components/Button/styles';
import { Popover as PopoverBootstrap } from 'react-bootstrap';
import { WindowBreakpoints } from 'modules/core/enums/windowBreakpoints.enum';

export const Container = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  margin-left: 20px;

  @media (max-width: ${WindowBreakpoints.medium}px) {
    margin-left: 0px;
  }

  ${Button} {
    width: 100%;
  }
`;

export const TotalVehiclesInfo = styled.div`
  position: relative;
  min-height: 48px;
  min-width: 100%;
  text-align: center;
  margin-bottom: 15px;

  @media (max-width: ${WindowBreakpoints.medium}px) {
    min-height: 40px;
    margin-bottom: 10px;
  }
`;

export const Title = styled.span<{ needMargin?: boolean }>`
  font-size: ${({ theme: { fontSize } }) => fontSize.lg};
  color: ${({ theme: { colors } }) => colors['grey-3']};
  font-weight: ${({ theme: { boldText } }) => boldText.dark};
  margin-bottom: ${({ needMargin }) => (needMargin ? '20px' : 0)};
  white-space: nowrap;

  @media (max-width: ${WindowBreakpoints.medium}px) {
    font-size: ${({ theme: { fontSize } }) => fontSize.huge};
  }

  span {
    margin-left: 12px;
    cursor: pointer;
  }
`;

export const Plug = styled.div`
  background-color: transparent;
  height: 56px;
`;

export const Popover = styled(PopoverBootstrap)`
  padding: 7px;
  color: ${({ theme: { colors } }) => colors['blue-1']};
  font-size: ${({ theme: { fontSize } }) => fontSize.sm};
`;
