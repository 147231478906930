import React, { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, useFormContext, useForm } from 'react-hook-form';
import { Form, Modal } from 'react-bootstrap';
import { IField } from 'modules/core/types';
import { TotalNetDownPaymentPanel } from 'components/Modals/FrontAndBackEndsAddsModal/styles';
import { ModalContainer, Title } from 'components/ModalDialog/style';
import { ButtonVariant, ColumnWidth, ModalTheme } from 'modules/core/enums';
import { FormRow, MoneyLabel } from 'modules/core/components';
import {
  LOAN_VARIABLES_FIELDS_NAMES,
  LOAN_VARIABLES_FORM_PREFIX,
} from 'components/Modals/LoanVariablesDialog/consts/fieldNames.const';
import { produce } from 'immer';

import { Panel } from '../styles';
import { ModalButton } from '../../../styles';
import { NET_TRADE_IN_FIELDS_NAMES, NET_TRADE_IN_FORM_PREFIX } from './consts/fieldNames.const';
import { DropdownFields } from './components/DropdownFields';
import { INetTradeInForm } from './types';
import { DEFAULT_NET_TRADE_IN_VALUES } from './consts/consts';

interface ISalesFees {
  show: boolean;
  onHide: () => void;
  title: string;
  modalSize: string;
  totalFieldLabel: string;
  calculationFields: IField[];
  fieldWidth: ColumnWidth;
  onNetTradeInCalculatorSubmit: (data: INetTradeInForm) => void;
  totalName: string;
  formValuesName: string;
  setFormHistory: React.Dispatch<React.SetStateAction<any[]>>;
  uppercaseTitle?: boolean;
  recalculateTaxes: (netTradeInFormValues?: any) => void;
}

export const NetTradeInCalculator: FC<ISalesFees> = ({
  show,
  onHide,
  title,
  modalSize,
  totalFieldLabel,
  calculationFields,
  fieldWidth,
  onNetTradeInCalculatorSubmit,
  totalName,
  formValuesName,
  setFormHistory,
  uppercaseTitle = true,
  recalculateTaxes,
}) => {
  const { t } = useTranslation();
  const { setValue: commonSetValue, watch: commonWatch, getValues: commonGetValues } = useFormContext();
  const loanTermFormValues = commonWatch(LOAN_VARIABLES_FORM_PREFIX);
  const commonNetTradeInForm = loanTermFormValues.netTradeInForm;
  const lastTradeInValue = commonGetValues().loanVariablesForm.netTradeInForm.tradeInValue;

  const formMethods = useForm({
    mode: 'onSubmit',
    shouldUnregister: false,
    defaultValues: {
      netTradeInForm: {
        ...DEFAULT_NET_TRADE_IN_VALUES.netTradeInForm,
        ...{
          ...commonNetTradeInForm,
          amountOwnedOnTrade: commonNetTradeInForm.amountOwnedOnTrade || NaN,
          tradeInValue: commonNetTradeInForm.tradeInValue || NaN,
        },
      },
    },
  });

  const {
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { isDirty, dirtyFields },
  } = formMethods;
  const { netTradeInForm } = getValues();

  const {
    netTradeInTotal,
    tradeInValue,
    amountOwnedOnTrade,
    lienHolder,
    year,
    make,
    model,
    otherYear,
    otherMake,
    otherModel,
  } = watch(NET_TRADE_IN_FORM_PREFIX) as any;

  const disabled =
    (Number.isNaN(tradeInValue) || tradeInValue === 0) &&
    (Number.isNaN(amountOwnedOnTrade) || amountOwnedOnTrade === 0);

  const disabledModelField = disabled || !Boolean(make);
  const generateFields = useCallback(
    (fields: IField[]) =>
      fields.map(({ name, component: Component, controlProps, label, isVerticalAlign }: IField) => {
        const _disabled = name === NET_TRADE_IN_FIELDS_NAMES.LIEN_HOLDER && disabled;

        return (
          <FormRow
            fieldWidth={fieldWidth}
            key={name}
            required={!!controlProps?.required}
            label={label}
            isVerticalAlign={isVerticalAlign}
            name={name}
            control={<Component {...controlProps} name={name} disabled={_disabled} />}
          />
        );
      }),
    [disabled, fieldWidth],
  );

  const onSubmit = useCallback(async () => {
    const data = {
      netTradeInTotal,
      tradeInValue,
      amountOwnedOnTrade,
      lienHolder,
      year,
      make,
      model,
      otherYear,
      otherMake,
      otherModel,
    };

    const values = {
      loanVariablesForm: {
        ...loanTermFormValues,
        netTradeIn: netTradeInTotal,
        netTradeInForm: netTradeInForm,
      },
    };
    const isDirtyTradeInValue: boolean = dirtyFields?.netTradeInForm?.tradeInValue;

    if (isDirtyTradeInValue) {
      commonSetValue(LOAN_VARIABLES_FIELDS_NAMES.CONFIRM_TAXES, false);
    }

    onNetTradeInCalculatorSubmit(data);

    commonSetValue(totalName, netTradeInTotal);
    commonSetValue(formValuesName, {
      ...netTradeInForm,
      otherMake: otherMake ? otherMake.trim() : '',
      otherModel: otherModel ? otherModel.trim() : '',
    });

    const loanVariablesInfo =
      ((lastTradeInValue === 0 || Number.isNaN(lastTradeInValue)) &&
        (tradeInValue === 0 || Number.isNaN(lastTradeInValue))) ||
      !isDirtyTradeInValue
        ? { taxes: values.loanVariablesForm.taxes }
        : await recalculateTaxes({ netTradeInForm: netTradeInForm });

    if (isDirty) {
      setFormHistory((prevHistory) =>
        produce(prevHistory, (draft) => {
          draft.push({
            loanVariablesForm: {
              ...values.loanVariablesForm,
              taxes: loanVariablesInfo?.taxes || 0,
            },
          });
        }),
      );
    }

    onHide();
  }, [
    netTradeInTotal,
    tradeInValue,
    amountOwnedOnTrade,
    lienHolder,
    year,
    make,
    model,
    otherYear,
    otherMake,
    otherModel,
    loanTermFormValues,
    netTradeInForm,
    dirtyFields?.netTradeInForm?.tradeInValue,
    onNetTradeInCalculatorSubmit,
    commonSetValue,
    totalName,
    formValuesName,
    lastTradeInValue,
    recalculateTaxes,
    isDirty,
    onHide,
    setFormHistory,
  ]);

  const calculateNetTradeIn = useCallback(() => {
    const _tradeInValue = tradeInValue || 0;
    const _amountOwnedOnTrade = amountOwnedOnTrade || 0;

    return _tradeInValue - _amountOwnedOnTrade;
  }, [amountOwnedOnTrade, tradeInValue]);

  useEffect(() => setValue(NET_TRADE_IN_FIELDS_NAMES.NET_TRADE_IN_TOTAL, calculateNetTradeIn()), [
    tradeInValue,
    amountOwnedOnTrade,
    setValue,
    calculateNetTradeIn,
  ]);

  return (
    <FormProvider {...formMethods}>
      <ModalContainer
        show={show}
        onHide={onHide}
        keyboard={false}
        backdrop={false}
        $modalSize={modalSize}
        $modalTheme={ModalTheme.DEFAULT}
        centered
      >
        <Modal.Header closeButton>
          <Title $uppercase={uppercaseTitle}>{title}</Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <TotalNetDownPaymentPanel>
              <FormRow
                fieldWidth={fieldWidth}
                label={totalFieldLabel}
                control={
                  <MoneyLabel isInteger isColored>
                    {netTradeInTotal || 0}
                  </MoneyLabel>
                }
              />
            </TotalNetDownPaymentPanel>
            <Panel>
              {generateFields(calculationFields)}
              <DropdownFields
                disabled={disabled}
                disabledModelField={disabledModelField}
                formPrefix={NET_TRADE_IN_FORM_PREFIX}
                fieldWidth={fieldWidth}
                fieldsNames={{
                  lienHolder: NET_TRADE_IN_FIELDS_NAMES.LIEN_HOLDER,
                  year: NET_TRADE_IN_FIELDS_NAMES.YEAR,
                  make: NET_TRADE_IN_FIELDS_NAMES.MAKE,
                  model: NET_TRADE_IN_FIELDS_NAMES.MODEL,
                  otherYear: NET_TRADE_IN_FIELDS_NAMES.OTHER_YEAR,
                  otherMake: NET_TRADE_IN_FIELDS_NAMES.OTHER_MAKE,
                  otherModel: NET_TRADE_IN_FIELDS_NAMES.OTHER_MODEL,
                }}
              />
            </Panel>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <ModalButton width={'20px'} padding={'6px'} buttontheme={ButtonVariant.OUTLINE} onClick={onHide}>
            {t('common.buttons.cancel')}
          </ModalButton>
          <ModalButton
            width={'20px'}
            padding={'6px'}
            buttontheme={ButtonVariant.CONTAINED}
            onClick={handleSubmit(onSubmit)}
          >
            {t('common.buttons.save')}
          </ModalButton>
        </Modal.Footer>
      </ModalContainer>
    </FormProvider>
  );
};
