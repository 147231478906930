import { ModalContainer } from 'components/ModalDialog/style';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonVariant, ModalTheme } from 'modules/core/enums';
import { Modal } from 'react-bootstrap';

import { ConfirmationToExistsBlock, ModalButton } from '../../styles';

export interface IExitConfirmationProps {
  isShow: boolean;
  hideOnYes: () => void;
  hideOnNo: () => void;
}

export const ExitConfirmationModal = ({ isShow, hideOnNo, hideOnYes }: IExitConfirmationProps) => {
  const { t } = useTranslation();

  return (
    <ModalContainer
      show={isShow}
      onHide={hideOnNo}
      backdrop={false}
      keyboard={false}
      $modalTheme={ModalTheme.DEFAULT}
      $modalSize="400px"
      centered
    >
      <Modal.Header closeButton />
      <Modal.Body>
        <ConfirmationToExistsBlock>
          {t('components.loanVariablesModal.warningMessages.exitConfirmation.first_part')}
          <br />
          {t('components.loanVariablesModal.warningMessages.exitConfirmation.second_part')}
        </ConfirmationToExistsBlock>
      </Modal.Body>
      <Modal.Footer className="f-dlex justify-content-end">
        <ModalButton width={'20px'} padding={'6px'} buttontheme={ButtonVariant.OUTLINE} onClick={hideOnYes}>
          {t('common.buttons.yes')}
        </ModalButton>
        <ModalButton width={'20px'} padding={'6px'} buttontheme={ButtonVariant.CONTAINED} onClick={hideOnNo}>
          {t('common.buttons.no')}
        </ModalButton>
      </Modal.Footer>
    </ModalContainer>
  );
};
