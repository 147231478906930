import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import { WindowBreakpoints } from 'modules/core/enums/windowBreakpoints.enum';

import { Label } from '../Label/styles';

export const FormRow = styled(Row)`
  align-items: center;

  &:not(:last-of-type) {
    margin-bottom: 6px;
  }

  #estimatedFees {
    @media (max-width: ${WindowBreakpoints.xxxxLarge}px) and (min-width: ${WindowBreakpoints.large + 1}px) {
      height: 45px;
    }
  }
  #salesFees {
    @media (max-width: ${WindowBreakpoints.xxxxLarge}px) and (min-width: ${WindowBreakpoints.large + 1}px) {
      height: 45px;
    }
  }
  #netDownPayment {
    @media (max-width: ${WindowBreakpoints.xxLarge}px) and (min-width: ${WindowBreakpoints.large + 1}px) {
      height: 45px;
    }
  }

  .mg-btm {
    margin-bottom: 24px;
  }

  textarea {
    border-radius: 0.375rem !important;
  }
`;

export const FormLabel = styled(Label)<{ disabled?: boolean; bold?: boolean }>`
  position: relative;
  font-weight: ${({ theme: { boldText }, required, disabled, bold }) =>
    (required || disabled || bold) && boldText.dark};
  font-size: ${({ theme: { fontSize } }) => fontSize.sm};
  color: ${(props) => (props.required ? props.theme.colors['grey-5'] : props.theme.colors['grey-3'])};
`;

export const SubLabel = styled.span`
  position: absolute;
  top: 1.5em;
  left: 0;
  font-size: ${({ theme: { fontSize } }) => fontSize.mini};
`;

export const ColUI = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: 2px auto;
  & > div {
    height: auto;
  }

  #enlarged-label {
    @media (max-width: ${WindowBreakpoints.xlarge}px) and (min-width: ${WindowBreakpoints.large + 1}px) {
      position: absolute;
    }
  }
`;
