import React, { FC, ReactElement } from 'react';
import { Loader } from 'modules/core/components';
import { PlaceholderTitle } from 'common/styles';

import { Container } from './styles';
import { DEFAULT_SEARCH_PLACEHOLDER } from '../table.const';

export interface ITablePlaceholder {
  title?: string;
  isFetching?: boolean;
  children?: ReactElement;
}

export const TablePlaceholderContent: FC<ITablePlaceholder> = ({
  title = DEFAULT_SEARCH_PLACEHOLDER,
  children,
  isFetching,
}) => (
  <Container>
    {isFetching ? (
      <Loader />
    ) : (
      <>
        <PlaceholderTitle>{title}</PlaceholderTitle>
        {children}
      </>
    )}
  </Container>
);
