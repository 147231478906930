import { WindowBreakpoints } from 'modules/core/enums/windowBreakpoints.enum';
import styled from 'styled-components';
import { STATIC_IMAGE_URLS } from 'modules/core/constants';

export const ItemsPerPage = styled.div`
  display: flex;
  align-items: center;
  font-size: ${({ theme: { fontSize } }) => fontSize.sm};

  @media (max-width: ${WindowBreakpoints.small}px) {
    align-items: baseline;
    margin-top: 10px;
    width: 100%;
  }

  .form-control {
    width: 75px;
    margin-left: 10px;
    background-image: url(${STATIC_IMAGE_URLS.icons.arrowIcon});
    background-repeat: no-repeat;
    background-position: right 0.4rem center;
    background-size: 11px 11px;
    padding-right: 22px;
  }
`;
