import { IVehicleReducerState } from 'store/reducers/vehicle.reducer';
import { toAdjustInfo, toVehicleLogUser } from 'components/Modals/VehicleAdjustmentDialog/selectors';
import { IVehicleAdjustRetail, IVehicleLog } from 'components/Modals/VehicleAdjustmentDialog/types';
import { IProbabilityEstimatorState } from 'store/reducers/probabilityEstimator.reducer';
import { ProbabilityEstimatorCalculationService } from 'modules/ProbabilityEstimator/services';
import { MINIMAL_PAYMENT_RANGE } from 'modules/ProbabilityEstimator/probabilityEstimator.const';
import { RETAIL_NAME } from 'modules/core/constants/report.const';
import { IApprovedLoanInput } from 'modules/ApprovedLoanInput/types';
import { UIReducerState } from 'store/reducers/ui.reducer';
import { EMPTY_STRING } from 'modules/core/constants';
import { SUPPORTED_FEATURES_MAPPER } from 'modules/core/constants/mappers/supportedFeatures.mapper';
import { IColumn, TableData, TReactTableColumn, TSelectedRow } from 'modules/core/components/Table/types';
import { DEFAULT_VISIBLE_VEHICLE_COLUMNS } from 'modules/core/components/VehicleTable/defaultVehicleTableColumns';
import { SELECT_ALL_COLUMN_ID } from 'modules/core/components/Table/table.const';
import { IFeesAndTaxes, ITableViewReport } from 'modules/core/types';

import { isSelectedTrueDropdown } from '../typings';

export const toVehicleAdjustRetail = ({ vehicleAdjustRetail }: IVehicleReducerState): IVehicleAdjustRetail => {
  const vehicleAdjustRetailValue = vehicleAdjustRetail[0];

  return (
    vehicleAdjustRetailValue && {
      description: vehicleAdjustRetailValue.description,
      odometer: vehicleAdjustRetailValue.odometer,
      vendorSetEquipment: vehicleAdjustRetailValue.vendor_equipment,
      adjustInfo: vehicleAdjustRetailValue.vendor_response && toAdjustInfo(vehicleAdjustRetailValue.vendor_response),
      regionJdpower: vehicleAdjustRetailValue.region_jdpower,
      vendorSetSeries: vehicleAdjustRetailValue.vendor_series || EMPTY_STRING,
      thumbnails: vehicleAdjustRetailValue.thumbnails,
      vin: vehicleAdjustRetailValue.vin,
    }
  );
};

export const toVehicleAdjustmentLog = ({ vehicleAdjustmentLogs }: IVehicleReducerState): IVehicleLog[] => {
  if (vehicleAdjustmentLogs.length) {
    return vehicleAdjustmentLogs.map((log) => ({
      changedAt: log.changed_at,
      changes: log.changes,
      user: toVehicleLogUser(log.user),
    }));
  }

  return [];
};

export const toTableVisibleColumns = (tableVisibleColumns: TReactTableColumn[]): IColumn[] => {
  if (tableVisibleColumns.length) {
    const columnsWithoutSelectionCol = tableVisibleColumns.filter((col) => col.id !== SELECT_ALL_COLUMN_ID);

    const columns = columnsWithoutSelectionCol.map(({ Header, id, isVisible }) => {
      const defaultChecked = !!DEFAULT_VISIBLE_VEHICLE_COLUMNS.find((item) => item === id);

      return {
        defaultChecked,
        modelProp: id,
        name: Header?.toString() || EMPTY_STRING,
        selected: !!isVisible,
      };
    });

    return columns;
  }

  return [];
};

export const toTableSelectedRows = (selectedTableRows: TSelectedRow[]): TableData[] =>
  selectedTableRows.map(({ original }) => original);

// TODO: Move function to separate file
export const toProbabilityEstimatorReportData = (
  probabilityEstimatorData: IProbabilityEstimatorState,
  controlFeesAndTaxes: IFeesAndTaxes,
): ITableViewReport => {
  const {
    totalNetDownPaymentDialog,
    feesAndTaxes,
    creditScore,
    grossMonthlyIncome = 0,
    desiredMonthlyPayment,
    preferences,
  } = probabilityEstimatorData.initialValues;

  const maximalMonthlyPayment = ProbabilityEstimatorCalculationService.getMaximalMonthlyPayment(
    creditScore.max_allowed_pti,
    creditScore.max_allowed_payment,
    grossMonthlyIncome,
  );

  return {
    disclaimer: {
      amountOwed: totalNetDownPaymentDialog.amountOwed,
      bankDiscount: feesAndTaxes.bankDiscount,
      bankFees: feesAndTaxes.bankFees,
      cashDown: totalNetDownPaymentDialog.cashDown,
      desiredMonthly: desiredMonthlyPayment,
      desiredMonthlyCeil: maximalMonthlyPayment,
      desiredMonthlyFloor: MINIMAL_PAYMENT_RANGE,
      grossMonthlyIncome,
      isProbability: true,
      ltvHighLimit: preferences.ltvHighLimit,
      salesTaxRate: feesAndTaxes.salesTaxRate || 0,
      salesFees: feesAndTaxes.estimatedFees || 0,
      selectedCreditScore: creditScore,
      tradeValue: totalNetDownPaymentDialog.tradeValue,
    },
    type: RETAIL_NAME,
    vehicles: probabilityEstimatorData.tableSelectedRows,
    vehicle_parameters: probabilityEstimatorData.tableVisibleColumns,
  };
};

// TODO: Move function to separate file
export const toApprovedLoanInputReportData = (
  approvedLoanInputData: IApprovedLoanInput,
  tableSelectedRows: TableData[],
  tableVisibleColumns: IColumn[],
): ITableViewReport => ({
  disclaimer: {
    amountOwed: approvedLoanInputData.amountOwed,
    approvedInterestRate: approvedLoanInputData.approvedInterestRate ? approvedLoanInputData.approvedInterestRate : 0,
    approvedLoanTerm: approvedLoanInputData.approvedLoanTerm,
    approvedLtvRatio: approvedLoanInputData.approvedLTVRatio,
    approvedMonthly: approvedLoanInputData.approvedMonthlyPayment,
    bankDiscount: approvedLoanInputData.feesAndTaxes.bankDiscount,
    bankFees: approvedLoanInputData.feesAndTaxes.bankFees,
    cashDown: approvedLoanInputData.cashDownPayment,
    estimatedFees: approvedLoanInputData.feesAndTaxes.estimatedFees || 0,
    grossMonthlyIncome: 0,
    isProbability: false,
    salesTaxRate: approvedLoanInputData.feesAndTaxes.salesTaxRate || 0,
    subvention: { value: isSelectedTrueDropdown(approvedLoanInputData.subvention) },
    subventionYear: { name: approvedLoanInputData.subventionYear },
    tradeValue: approvedLoanInputData.tradeValue,
  },
  type: RETAIL_NAME,
  vehicles: tableSelectedRows,
  vehicle_parameters: tableVisibleColumns,
});

export const toDisabledFeaturesList = ({ featuresList }: UIReducerState): string[] =>
  Object.entries(featuresList).reduce(
    (disabledFeatures: string[], [page, isDisabled]) =>
      !isDisabled && SUPPORTED_FEATURES_MAPPER[page]
        ? [...disabledFeatures, SUPPORTED_FEATURES_MAPPER[page]]
        : disabledFeatures,
    [],
  );
