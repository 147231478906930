import React from 'react';

import { CardContainer, Title, Price, PriceDescription, PriceContainer } from './styles';

interface IMiniCard {
  title: string;
  price: string;
  priceDescription: string;
}

export const MiniCard: React.FC<IMiniCard> = ({ title, price, priceDescription }) => (
  <CardContainer>
    <Title>{title}</Title>
    <PriceContainer>
      <Price>{price}</Price>
      <PriceDescription>{priceDescription}</PriceDescription>
    </PriceContainer>
  </CardContainer>
);
