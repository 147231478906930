import styled from 'styled-components';
import { COLORS } from 'modules/core/theme/colors';
import { Row, Col } from 'react-bootstrap';

export const Header = styled(Row)`
  font-weight: ${({ theme: { boldText } }) => boldText.dark};
`;

export const ColUI = styled(Col)`
  padding: 8px 6px;

  .form-group {
    margin: 0;
  }

  &:first-child {
    color: ${COLORS.GREY_3};
  }
`;
