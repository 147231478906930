import React, { useCallback, useEffect, useMemo } from 'react';
import { Card, Form } from 'react-bootstrap';
import { Dropdown, FormRow, MoneyLabel } from 'modules/core/components';
import { useTranslation } from 'react-i18next';
import { useFormContext, Validate } from 'react-hook-form';
import { IField } from 'modules/core/types';
import { ICONS_HEIGHT, STATIC_IMAGE_URLS } from 'modules/core/constants';
import { useSelector } from 'react-redux';
import { getControlNumbers } from 'store/selectors/controls.selector';
import { ColumnWidth } from 'modules/core/enums';

import { CardForm } from '../styles';
import { IIncomeCardForm } from '../types';
import {
  CATEGORY_OPTIONS,
  FIELD_NAMES,
  FORM_FIELD_NAMES,
  MAP_FIELDS_BY_TYPE,
  MAP_TYPE_BY_CATEGORY,
  TYPES,
} from '../const';
import { IncomeCalculatorService } from '../incomeCalculator.service';
import { getCalendarValidation } from '../../../modules/core/utils/validators';
import { TrashIcon } from './styles';

interface IIncomeCard {
  incomeNumber?: number;
  onDelete: (index?: number | number[]) => void;
  showTrashIcon: boolean;
  clearErrors: any;
}

export const IncomeCard: React.FC<IIncomeCard> = ({
  incomeNumber = 0,
  onDelete,
  showTrashIcon = false,
  clearErrors,
}) => {
  const { t } = useTranslation();
  const { setValue, watch } = useFormContext();
  const controlNumbers = useSelector(getControlNumbers);

  const cardValue = useMemo(() => watch().income?.incomesForm[incomeNumber] || {}, [watch, incomeNumber]);

  const typeOptions = useMemo(() => MAP_TYPE_BY_CATEGORY.get(cardValue.category) || [], [cardValue.category]);

  const clearIncomeFormErrors = useCallback(() => {
    clearErrors();
  }, [clearErrors]);

  const categoryDropdown = useMemo(
    () => (
      <Dropdown
        name={`${FORM_FIELD_NAMES.INCOME_FORM}.${incomeNumber}.${FIELD_NAMES.CATEGORY}`}
        defaultValue={cardValue.category || CATEGORY_OPTIONS[0].value}
        options={CATEGORY_OPTIONS}
        onChange={clearIncomeFormErrors}
      />
    ),
    [cardValue.category, clearIncomeFormErrors, incomeNumber],
  );

  const calculationsMap = useMemo(
    () =>
      new Map<string, (form: IIncomeCardForm) => number>([
        [TYPES.GROSS_YTD, () => IncomeCalculatorService.calculateForGrossYTD(cardValue)],
        [TYPES.BASE_PAY, () => IncomeCalculatorService.calculateForBasePay(cardValue, controlNumbers.weeklyToMonthly)],
        [TYPES.NYS_DISABILITY, () => IncomeCalculatorService.calculateForNYSDisability(cardValue, controlNumbers)],
        [TYPES.EARLY_YEAR, () => IncomeCalculatorService.calculateForEarlyYear(cardValue)],
        [TYPES.AVG_FROM_TAX_RETURNS, () => IncomeCalculatorService.calculateForAvgFromTaxReturns(cardValue)],
        [TYPES.AVG_FROM_DEPOSITS, () => IncomeCalculatorService.calculateForAvgFromDeposits(cardValue)],
        [TYPES.SOCIAL_SECURITY, () => cardValue.amountReceived * controlNumbers.socialSecurityGross],
        [TYPES.PENSION_AND_ETC, () => cardValue.amountReceived],
        [TYPES.DISABILITY_AND_ETC, () => cardValue.amountReceived],
        [TYPES.INSURANCE, () => cardValue.amountReceived],
        [TYPES.NON_CALCULATED, () => cardValue.amountReceived],
      ]),
    [cardValue, controlNumbers],
  );

  const deleteCard = useCallback(() => {
    onDelete(incomeNumber);
  }, [incomeNumber, onDelete]);

  const income = useMemo(() => (cardValue ? calculationsMap.get(cardValue.type)?.(cardValue) || 0 : 0), [
    calculationsMap,
    cardValue,
  ]);

  const typeDropdown = useMemo(() => {
    const defaultValue = typeOptions.some(({ value }) => value === cardValue.type)
      ? cardValue.type
      : typeOptions[0]?.value;

    return (
      <Dropdown
        name={`${FORM_FIELD_NAMES.INCOME_FORM}.${incomeNumber}.${FIELD_NAMES.TYPE}`}
        defaultValue={defaultValue}
        options={typeOptions}
        onChange={clearIncomeFormErrors}
      />
    );
  }, [cardValue.type, clearIncomeFormErrors, incomeNumber, typeOptions]);

  const formFields = useMemo(
    () =>
      MAP_FIELDS_BY_TYPE.get(cardValue.type)?.map(
        ({ component: Component, label, name, controlProps, defaultValue }: IField) => {
          const controlName = `${FORM_FIELD_NAMES.INCOME_FORM}.${incomeNumber}.${name}`;

          const finalValidate = controlProps?.validate
            ? controlProps.validate
            : getCalendarValidation(controlName, watch);

          const control = (
            <Component
              {...controlProps}
              key={controlName}
              name={controlName}
              defaultValue={(cardValue?.[name] || defaultValue) as string}
              testId={controlName}
              validate={finalValidate as Validate}
            />
          );

          return (
            <FormRow label={label} key={controlName} control={control} fieldWidth={ColumnWidth.FULL} isVerticalAlign />
          );
        },
      ),
    [cardValue, incomeNumber, watch],
  );

  useEffect(() => {
    setValue(`${FORM_FIELD_NAMES.INCOME_FORM}.${incomeNumber}.income`, income);
  }, [setValue, incomeNumber, income]);

  return (
    <CardForm>
      <Card.Header>
        <span data-testid="income-card-header">
          {`${t('components.incomeCalculator.labels.income')}${incomeNumber + 1}`}
        </span>
        {showTrashIcon && (
          <TrashIcon
            imageSrc={STATIC_IMAGE_URLS.icons.trash}
            height={ICONS_HEIGHT.SMALL}
            onClick={deleteCard}
            data-testid="trash-button"
          />
        )}
      </Card.Header>
      <Card.Body>
        <Form.Group>
          <FormRow
            label={t('components.incomeCalculator.labels.incomePerMonth')}
            control={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <MoneyLabel dataTestId="income-per-month-field" isColored>
                {cardValue.income || 0}
              </MoneyLabel>
            }
            isVerticalAlign
          />
          <FormRow
            label={t('components.incomeCalculator.labels.category')}
            control={categoryDropdown}
            isVerticalAlign
          />
          <FormRow label={t('components.incomeCalculator.labels.type')} control={typeDropdown} isVerticalAlign />
          {formFields}
        </Form.Group>
      </Card.Body>
    </CardForm>
  );
};

export const MemoizedIncomeCard = React.memo(IncomeCard);
