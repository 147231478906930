import styled from 'styled-components';

export const Panel = styled.div`
  padding: 18px 63px;
  background-color: ${({ theme: { colors } }) => colors.white};
  border-radius: 4px;
  box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
  display: flex;
  flex-direction: column;

  .row:last-child {
    margin-bottom: 0;
  }

  .row {
    margin-bottom: 16px;
  }
`;
