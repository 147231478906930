import React, { FC } from 'react';
import { EMPTY_STRING } from 'modules/core/constants';
import { OptionValue } from 'modules/core/types';
import { ColumnWidth } from 'modules/core/enums';

import { RowUI as Row, ColUI as Col } from './styles';

interface IInfoRow {
  label: string;
  value?: OptionValue;
}

export const InfoRow: FC<IInfoRow> = ({ label, value = EMPTY_STRING }) => (
  <Row>
    <Col sm={ColumnWidth.S}>{label}</Col>
    <Col sm={ColumnWidth.L}>{value}</Col>
  </Row>
);
