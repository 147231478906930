import React, { FC, useEffect, useMemo } from 'react';
import { useFormContext, Validate } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TUnderlyingHTMLTextElement } from 'modules/core/components/Input/types';
import { FormRow } from 'modules/core/components';
import { ASTERISK } from 'modules/core/constants';
import { useSelector } from 'react-redux';
import { getDisabledFeaturesList } from 'store/selectors/ui.selector';
import { SUPPORTED_FEATURES_MAPPER } from 'modules/core/constants/mappers/supportedFeatures.mapper';
import { getUser } from 'store/selectors/auth.selector';

import { FIELD_NAMES, FIELDS_CONFIG, FIELDS_VALIDATE_PROPS_MAP, REQUIRED_FIELDS_CONFIG } from './sendReportForm.const';
import { ErrorMessageUI, LabelTitle } from './styles';
import { getValidate } from './validators';
import { IValidateProps } from './types';

interface ISendSalespersonReportDialog {
  errors?: string;
  setIsContactInfoValid: (value: boolean) => void;
}

export const SendSalespersonReportDialog: FC<ISendSalespersonReportDialog> = ({ errors, setIsContactInfoValid }) => {
  const { t } = useTranslation();
  const user = useSelector(getUser);
  const { watch, trigger } = useFormContext();
  const disabledFeaturesList = useSelector(getDisabledFeaturesList);
  const isSMSSendingDisabled = disabledFeaturesList.includes(SUPPORTED_FEATURES_MAPPER.is_sms_sending_enabled);

  const isEmailSendingDisabled =
    user?.isWithInventory && disabledFeaturesList.includes(SUPPORTED_FEATURES_MAPPER.is_email_sending_enabled);

  const filteredFields = FIELDS_CONFIG.filter(({ name }) => {
    if (isSMSSendingDisabled && name === FIELD_NAMES.PHONE) return false;

    return !(isEmailSendingDisabled && name === FIELD_NAMES.EMAIL);
  });

  const contactInfoLabel = isEmailSendingDisabled || isSMSSendingDisabled ? 'disabled' : 'default';

  const values = watch();

  const { phone, email } = values;

  const requiredFields = useMemo(
    () =>
      REQUIRED_FIELDS_CONFIG.map(({ name, component: Component, controlProps }) => (
        <FormRow key={name} control={<Component name={name} {...controlProps} />} isVerticalAlign />
      )),
    [],
  );

  const fields = useMemo(
    () =>
      filteredFields.map(({ name, component: Component, controlProps }) => {
        const validateProps = FIELDS_VALIDATE_PROPS_MAP.get(name) as IValidateProps;
        const { validate, ...restControlProps } = controlProps as Record<string, unknown>;
        const finalValidate =
          validate ||
          getValidate(
            values[validateProps.dependentFieldName],
            validateProps.dependentFieldValuePattern,
            validateProps.valuePattern,
            validateProps.errorMessage,
          );

        const control = (
          <Component
            name={name}
            validate={finalValidate as Validate}
            {...restControlProps}
            as={controlProps?.as as TUnderlyingHTMLTextElement}
          />
        );

        return <FormRow key={name} control={control} isVerticalAlign />;
      }),
    [filteredFields, values],
  );

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (phone && email !== undefined) trigger(FIELD_NAMES.EMAIL);
  }, [trigger, phone]);

  useEffect(() => {
    if (email && phone !== undefined) trigger(FIELD_NAMES.PHONE);
  }, [trigger, email]);

  useEffect(() => {
    if (phone !== undefined && email === undefined && !Number.isNaN(phone)) setIsContactInfoValid(true);

    if (email !== undefined && phone === undefined && email.length > 0) setIsContactInfoValid(true);

    if (email !== undefined && phone !== undefined) setIsContactInfoValid(true);
  }, [email, phone, setIsContactInfoValid]);

  return (
    <>
      {requiredFields}
      <LabelTitle>{`${t(`components.sendReportDialog.contactInfo.titles.${contactInfoLabel}`)}${ASTERISK}`}</LabelTitle>
      {fields}
      {errors && <ErrorMessageUI data-testID="send-report-error">{errors}</ErrorMessageUI>}
    </>
  );
};
