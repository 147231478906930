import { CreatePassword, ForgotPassword, Login, SelectDealer } from 'modules/auth';
import { ProbabilityEstimator } from 'modules/ProbabilityEstimator';
import { ApprovedLoanInput } from 'modules/ApprovedLoanInput';
import { RACAffordabilityEngine } from 'modules/RACAffordabilityEngine';
import { LenderController } from 'modules/Lender/LenderController';
import { CreditInterview } from 'modules/CreditInterview';
import { VehicleAdjustmentDialogExport } from 'components/Modals/VehicleAdjustmentDialogExport';
import {
  LearningStart,
  LearningKeyFactors,
  LearningCreditHistory,
  LearningAffordability,
  LearningLoanToValue,
  LearningLengthOfLoan,
  LearningComparison,
  LearningFinal,
} from 'modules/Learning';
import { IRoute } from 'router/types';

import { ROUTER_PATH } from './routerPath.const';
import { ROUTER_NAME } from './common.const';

export const AUTH_ROUTES: IRoute[] = [
  {
    path: ROUTER_PATH.LOGIN,
    component: Login,
  },
  {
    path: ROUTER_PATH.RESET_PASSWORD,
    component: ForgotPassword,
  },
  {
    path: ROUTER_PATH.CREATE_NEW_PASSWORD,
    component: CreatePassword,
  },
  {
    path: ROUTER_PATH.VERIFICATION,
    component: CreatePassword,
  },
  {
    path: ROUTER_PATH.SELECT_DEALER,
    component: SelectDealer,
  },
];

export const PRIVATE_ROUTES: IRoute[] = [
  {
    path: ROUTER_PATH.PROBABILITY_ESTIMATOR,
    exact: true,
    component: ProbabilityEstimator,
    name: ROUTER_NAME.PROBABILITY_ESTIMATOR,
  },
  {
    path: ROUTER_PATH.APPROVED_LOAN_INPUT,
    component: ApprovedLoanInput,
    exact: true,
    name: ROUTER_NAME.APPROVED_LOAN_INPUT,
  },
  {
    path: ROUTER_PATH.RAC_AFFORDABILITY_ENGINE,
    component: RACAffordabilityEngine,
    exact: true,
    name: ROUTER_NAME.RAC_AFFORDABILITY_ENGINE,
  },
  {
    path: ROUTER_PATH.LENDER,
    component: LenderController,
    exact: true,
    name: ROUTER_NAME.LENDER,
  },
  {
    path: ROUTER_PATH.CREDIT_INTERVIEW,
    component: CreditInterview,
    exact: true,
    name: ROUTER_NAME.CREDIT_INTERVIEW,
  },
  {
    path: ROUTER_PATH.ADJUSTMENT_MODAL_VIEW,
    component: VehicleAdjustmentDialogExport,
    exact: true,
    name: ROUTER_NAME.ADJUSTMENT_MODAL_VIEW,
  },
];

export const MAKE_IT_WORKS_WITHOUT_INVENTORY_ROUTES: IRoute[] = [
  {
    path: ROUTER_PATH.RAC_AFFORDABILITY_ENGINE,
    component: RACAffordabilityEngine,
    exact: true,
    name: ROUTER_NAME.RAC_AFFORDABILITY_ENGINE,
  },
];

export const LEARNING_ROUTES: IRoute[] = [
  {
    path: ROUTER_PATH.LEARNING.START,
    component: LearningStart,
  },
  {
    path: ROUTER_PATH.LEARNING.KEY_FACTORS,
    component: LearningKeyFactors,
  },
  {
    path: ROUTER_PATH.LEARNING.CREDIT_HISTORY,
    component: LearningCreditHistory,
  },
  {
    path: ROUTER_PATH.LEARNING.AFFORDABILITY,
    component: LearningAffordability,
  },
  {
    path: ROUTER_PATH.LEARNING.LOAN_TO_VALUE,
    component: LearningLoanToValue,
  },
  {
    path: ROUTER_PATH.LEARNING.LENGTH_OF_LOAN,
    component: LearningLengthOfLoan,
  },
  {
    path: ROUTER_PATH.LEARNING.COMPARISON,
    component: LearningComparison,
  },
  {
    path: ROUTER_PATH.LEARNING.FINAL,
    component: LearningFinal,
  },
];
