import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import { WindowBreakpoints } from 'modules/core/enums/windowBreakpoints.enum';
import { COLORS } from 'modules/core/theme/colors';

export const PrintContainer = styled.div`
  width: 100%;
  margin-left: 2px;
`;

export const FormRow = styled(Row)`
  align-items: center;
  margin-bottom: 10px;
`;

export const ColUI = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: 2px auto;
  & > div {
    height: auto;
  }

  #enlarged-label {
    @media (max-width: ${WindowBreakpoints.xlarge}px) and (min-width: ${WindowBreakpoints.large + 1}px) {
      position: absolute;
    }
  }
`;

export const ExtraCashUnavailableWarn = styled.div`
  font-size: 12px;
  padding-left: 40%;
  width: 100%;
  margin-right: 0;
  padding-right: 10px;
  margin-bottom: 12px;
  text-align: justify;
  color: ${COLORS.RED_4};
`;
