import styled from 'styled-components';
import { Image, Card, Form, Button, InputGroup } from 'react-bootstrap';
import { COLORS } from 'modules/core/theme/colors';
import { STATIC_IMAGE_URLS } from 'modules/core/constants';

export const LoginContent = styled.div`
  height: 100%;
  background-image: url(${STATIC_IMAGE_URLS.loginBackground});
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  align-items: center;
  width: 100%;
  position: fixed;
  overflow-y: auto;
  top: 0;
  left: 0;

  @media (max-width: 575px) {
    padding: 10px;
  }
`;

export const PositionCard = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CardForm = styled(Card)`
  width: 400px;
  background-color: #fff;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2), -2px -2px 3px rgba(0, 0, 0, 0.2);
  font-family: Rubik, sans-serif;
`;

export const CardImage = styled(Card.Img)`
  width: 100%;
  padding: 35px 45px 0 45px;
`;

export const CardTitle = styled(Card.Title)`
  width: 100%;
  text-align: center;
  margin: 4px 0 30px 0;
  color: ${COLORS.BLUE_1};
  text-transform: uppercase;
`;

export const ImageSize = styled(Image)`
  height: 100%;
  width: auto;
`;

export const PositionMiniImage = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 40px;
  margin: 15px 0 0 0;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

export const Link = styled.a`
  border-radius: 20px;
  color: ${COLORS.BLUE_1};
  text-decoration: underline;
  font-size: 15px;

  &:hover {
    color: ${COLORS.BLUE_1};
  }
`;

export const AgreementModalLink = styled.button`
  display: contents;
  border-radius: 20px;
  color: ${COLORS.BLUE_1};
  text-decoration: underline;
  font-size: 15px;

  &:hover {
    color: ${COLORS.BLUE_1};
  }
`;

export const LinkCreatePassword = styled.a`
  color: ${({ theme: { colors } }) => colors['blue-4']};
  font-size: 14px;
  text-decoration: none;

  &:hover {
    color: ${COLORS.BLUE_1};
    text-decoration: underline;
  }
`;

export const BackToLoginPosition = styled.div`
  display: flex;
  justify-content: center;
`;

export const FormControl = styled(Form.Control)`
  border-radius: 0;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: none !important;
  padding-left: 0;
  font-size: 14px;

  &:focus {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    z-index: 0 !important;
  }

  &::placeholder {
    color: #a6a6a6;
    font-size: 14px;
  }
`;

export const EyeButton = styled(Button)`
  border-radius: 0;
  border: none;
  box-shadow: none !important;
  padding: 0;
`;

export const InputAppend = styled(InputGroup.Text)`
  position: absolute;
  top: 4px;
  right: 0;
  padding: 0;
  background: none;
  border: none;
`;

export const SubmitButton = styled(Button)`
  border-radius: 20px;
  height: 40px;
  width: 100%;
  font-size: 16px;
  border: 1px solid transparent;
  margin-top: -10px;

  &:disabled,
  &:focus,
  &:active {
    background-color: ${COLORS.BLUE_1} !important;
    box-shadow: none !important;
  }

  &:disabled {
    opacity: 0.6;
    border-color: ${COLORS.BLUE_1} !important;
  }

  &:enabled {
    background-color: ${COLORS.BLUE_1} !important;
  }

  &:hover:enabled {
    background-color: #6f7d97 !important;
  }
`;

export const FormPosition = styled(Form)`
  margin-top: 30px;
  padding: 0 30px 5px 30px;

  & > :nth-child(1n) {
    padding-bottom: 20px;
  }
`;

export const LoginFormLabel = styled(Form.Label)`
  color: ${COLORS.GREY_7};
  font-size: 14px;
`;

export const LoginFormCheck = styled(Form.Check)`
  color: ${COLORS.GREY_7};
  font-size: 14px;
  text-align: center;
  display: flex;
  justify-content: center;

  & > :nth-child(1) {
    margin-right: 10px;
  }
`;

export const LinkPosition = styled.div`
  width: 100%;
  padding: 0 0 20px 0;
  text-align: center;
`;

export const AndSpan = styled.span`
  font-size: 15px;
`;

export const ErrorMessage = styled.span`
  margin: 10px 0;
  font-size: 14px;
  color: #ff3b30;
`;

export const FormSelect = styled(Form.Select)`
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0;
  font-size: 15px;
  margin-bottom: 40px;
  padding-bottom: 0px !important;
  width: 100%;

  &:focus {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: none !important;
    outline: none !important;
  }
`;

export const TextSelectDealer = styled.div`
  background-color: transparent;
  border: none;
  color: ${COLORS.BLUE_1};
  font-family: 'Rubik', sans-serif;
  font-size: 15px;
  margin-bottom: 30px;
  padding: 0 !important;
  text-align: center;
  width: 100%;
`;

export const TextForgotPassword = styled.div`
  width: 100%;
  margin: 15px 0;
  padding: 0;
  font-family: 'Rubik', sans-serif;
  background-color: transparent;
  border: none;
  font-size: 15px;
  color: ${COLORS.BLUE_1};
  text-align: center;
`;

export const TextCheckEmail = styled.div`
  font-size: 15px;
  color: ${COLORS.BLUE_1};
  text-align: center;
  margin-bottom: 30px;
`;

export const CardBody = styled(Card.Body)`
  padding-top: 0;
`;

export const FormGroupAcceptTermsConditions = styled(Form.Group)`
  display: flex;
  justify-content: center;
  margin-top: 5px;
`;

export const AgreementCreatePassword = styled.div`
  text-align: justify;
  padding-left: 5px;
`;
