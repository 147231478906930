import { useSelector } from 'react-redux';
import { TableView, TableName } from 'modules/core/enums';
import { TableColumn } from 'modules/core/components/Table/types';
import { useEffect, useState } from 'react';
import { getCurrentVendorMappings, getVendorName } from 'store/selectors/ui.selector';
import { setDefaultColumnsBySettingsBookValue, setVendorColumns } from 'modules/core/utils';
import { IRACVehicle, IVehicle, ILenderVehicle } from 'modules/core/types';
import { useFormContext } from 'react-hook-form';
import { PREFERENCES_OPTION_VALUES } from 'modules/core/shared';
import { PROBABILITY_ESTIMATOR_FIELDS } from 'modules/ProbabilityEstimator/probabilityEstimator.const';
import { COMMA_SYMBOL } from 'modules/core/constants';

import { CUSTOMER_FRIENDLY_COLUMNS_MAP } from '../common.const';

import { useColumnsByBankFees, useColumnsByRole, useColumnsByLtvMethod } from '.';

interface IUseColumns {
  columns: TableColumn[];
  defaultColumns: TableColumn[];
  customerFriendlyColumns: string[];
}

export const useColumns = (
  selectedTableView: TableView,
  tableName: TableName,
  vehicles: IVehicle[] | IRACVehicle[] | ILenderVehicle[],
): IUseColumns => {
  const { watch, setValue } = useFormContext();
  const {
    preferences: { vendorField, ltvTaxesFeesFilter },
    selectedColumns,
    areDefaultColumnsSelected,
  } = watch();
  const [columns, setColumns] = useState<TableColumn[]>([]);
  const [defaultColumns, setDefaultColumns] = useState<TableColumn[]>([]);
  const vendorOptions = useSelector(getCurrentVendorMappings);
  const vendorName = useSelector(getVendorName);

  const columnsByRole = useColumnsByRole(selectedTableView, tableName);
  const columnsByLtvMethod = useColumnsByLtvMethod(columnsByRole, ltvTaxesFeesFilter, tableName === TableName.LENDER);
  const columnsByBankFees = useColumnsByBankFees(columnsByLtvMethod, tableName === TableName.LENDER);
  const columnsByVendor = setVendorColumns(columnsByBankFees, vendorName, tableName);
  const customerFriendlyColumns = CUSTOMER_FRIENDLY_COLUMNS_MAP.get(tableName)?.get(selectedTableView) || [];

  const setSelectedColumnsDependingOnVendor = () => {
    const selectedColumnsAsArray = selectedColumns.split(COMMA_SYMBOL);

    Object.values(PREFERENCES_OPTION_VALUES.VENDOR_FILTER).forEach((vendorItem) => {
      const vendorIndex = selectedColumnsAsArray.indexOf(vendorItem);

      if (vendorIndex > -1) {
        selectedColumnsAsArray[vendorIndex] = vendorField;
        setValue(PROBABILITY_ESTIMATOR_FIELDS.SELECTED_COLUMNS, selectedColumnsAsArray.join(COMMA_SYMBOL));
      }
    });
  };

  useEffect(() => {
    const columnsBySettingsBookValue = setDefaultColumnsBySettingsBookValue(vendorField, columnsByVendor);

    setDefaultColumns(columnsBySettingsBookValue);

    const vehiclesColumns = selectedColumns
      ? columnsBySettingsBookValue.map((item) => ({ ...item, isVisible: selectedColumns?.includes(item.id) }))
      : columnsBySettingsBookValue;

    setColumns(vehiclesColumns);

    // eslint-disable-next-line
  }, [vehicles.length, ...Object.values(vendorOptions), vendorName, vendorField, selectedTableView, ltvTaxesFeesFilter]);

  useEffect(() => {
    if (selectedColumns && areDefaultColumnsSelected) setSelectedColumnsDependingOnVendor();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendorField]);

  return { columns, defaultColumns, customerFriendlyColumns };
};
