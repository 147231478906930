import { Roles, TableView, TableName } from 'modules/core/enums';
import { TableColumn } from 'modules/core/components/Table/types';
import {
  DEFAULT_EXECUTIVE_VIEW_VEHICLE_COLUMNS,
  DEFAULT_MANAGER_VIEW_VEHICLE_COLUMNS,
  DEFAULT_SALESPERSON_VIEW_VEHICLE_COLUMNS,
  EXTENDED_SALESPERSON_VIEW_VEHICLE_COLUMNS,
  MANAGER_LENDER_VEHICLE_TABLE_COLUMNS,
  MANAGER_RAC_VEHICLE_TABLE_COLUMNS,
  SALESPERSON_LENDER_VEHICLE_TABLE_COLUMNS,
  SALESPERSON_RAC_VEHICLE_TABLE_COLUMNS,
} from 'modules/core/components/VehicleTable/vehicleTableColumns';
import {
  MANAGER_CUSTOMER_FRIENDLY_VISIBLE_VEHICLE_COLUMNS,
  MANAGER_LENDER_CUSTOMER_FRIENDLY_VEHICLE_COLUMNS,
  SALESPERSON_CUSTOMER_FRIENDLY_VISIBLE_VEHICLE_COLUMNS,
  SALESPERSON_LENDER_CUSTOMER_FRIENDLY_VEHICLE_COLUMNS,
} from 'modules/core/components/VehicleTable/defaultVehicleTableColumns';

const VIEW_VEHICLE_COLUMNS_MAP = new Map<TableView, TableColumn[]>([
  [TableView.MANAGER, DEFAULT_MANAGER_VIEW_VEHICLE_COLUMNS],
  [TableView.SALESPERSON, DEFAULT_SALESPERSON_VIEW_VEHICLE_COLUMNS],
  [TableView.EXECUTIVE, DEFAULT_EXECUTIVE_VIEW_VEHICLE_COLUMNS],
]);

const VIEW_VEHICLE_EXTENDED_COLUMNS_MAP = new Map<TableView, TableColumn[]>([
  [TableView.MANAGER, DEFAULT_MANAGER_VIEW_VEHICLE_COLUMNS],
  [TableView.SALESPERSON, EXTENDED_SALESPERSON_VIEW_VEHICLE_COLUMNS],
  [TableView.EXECUTIVE, DEFAULT_EXECUTIVE_VIEW_VEHICLE_COLUMNS],
]);

export const VIEW_VEHICLE_COLUMN_BY_ROLE_MAP = new Map<Roles, Map<TableView, TableColumn[]>>([
  [Roles.GLOBALADMIN, VIEW_VEHICLE_EXTENDED_COLUMNS_MAP],
  [Roles.ADMIN, VIEW_VEHICLE_EXTENDED_COLUMNS_MAP],
  [Roles.MANAGER, VIEW_VEHICLE_EXTENDED_COLUMNS_MAP],
  [Roles.SALESPERSON, VIEW_VEHICLE_COLUMNS_MAP],
]);

const VIEW_RAC_VEHICLE_COLUMNS_MAP = new Map<TableView, TableColumn[]>([
  [TableView.MANAGER, MANAGER_RAC_VEHICLE_TABLE_COLUMNS],
  [TableView.SALESPERSON, SALESPERSON_RAC_VEHICLE_TABLE_COLUMNS],
]);

export const VIEW_RAC_VEHICLE_COLUMN_BY_ROLE_MAP = new Map<Roles, Map<TableView, TableColumn[]>>([
  [Roles.GLOBALADMIN, VIEW_RAC_VEHICLE_COLUMNS_MAP],
  [Roles.ADMIN, VIEW_RAC_VEHICLE_COLUMNS_MAP],
  [Roles.MANAGER, VIEW_RAC_VEHICLE_COLUMNS_MAP],
  [Roles.SALESPERSON, VIEW_RAC_VEHICLE_COLUMNS_MAP],
]);

const VIEW_LENDER_VEHICLE_COLUMNS_MAP = new Map<TableView, TableColumn[]>([
  [TableView.MANAGER, MANAGER_LENDER_VEHICLE_TABLE_COLUMNS],
  [TableView.SALESPERSON, SALESPERSON_LENDER_VEHICLE_TABLE_COLUMNS],
]);

export const VIEW_LENDER_VEHICLE_COLUMN_BY_ROLE_MAP = new Map<Roles, Map<TableView, TableColumn[]>>([
  [Roles.GLOBALADMIN, VIEW_LENDER_VEHICLE_COLUMNS_MAP],
  [Roles.ADMIN, VIEW_LENDER_VEHICLE_COLUMNS_MAP],
  [Roles.MANAGER, VIEW_LENDER_VEHICLE_COLUMNS_MAP],
  [Roles.SALESPERSON, VIEW_LENDER_VEHICLE_COLUMNS_MAP],
]);

export const GET_DEFAULT_COLUMNS_MAP = new Map<TableName, TableColumn[]>([
  [TableName.RAC, SALESPERSON_RAC_VEHICLE_TABLE_COLUMNS],
  [TableName.PE, DEFAULT_SALESPERSON_VIEW_VEHICLE_COLUMNS],
  [TableName.ALI, DEFAULT_SALESPERSON_VIEW_VEHICLE_COLUMNS],
  [TableName.LENDER, SALESPERSON_LENDER_VEHICLE_TABLE_COLUMNS],
]);

export const COLUMNS_MAP_BY_TABLE = new Map<TableName, Map<Roles, Map<TableView, TableColumn[]>>>([
  [TableName.RAC, VIEW_RAC_VEHICLE_COLUMN_BY_ROLE_MAP],
  [TableName.PE, VIEW_VEHICLE_COLUMN_BY_ROLE_MAP],
  [TableName.ALI, VIEW_VEHICLE_COLUMN_BY_ROLE_MAP],
  [TableName.LENDER, VIEW_LENDER_VEHICLE_COLUMN_BY_ROLE_MAP],
]);

const VIEW_COMMON_CUSTOMER_FRIENDLY_COLUMNS_MAP = new Map<TableView, string[]>([
  [TableView.MANAGER, MANAGER_CUSTOMER_FRIENDLY_VISIBLE_VEHICLE_COLUMNS],
  [TableView.SALESPERSON, SALESPERSON_CUSTOMER_FRIENDLY_VISIBLE_VEHICLE_COLUMNS],
]);

const VIEW_LENDER_CUSTOMER_FRIENDLY_COLUMNS_MAP = new Map<TableView, string[]>([
  [TableView.MANAGER, MANAGER_LENDER_CUSTOMER_FRIENDLY_VEHICLE_COLUMNS],
  [TableView.SALESPERSON, SALESPERSON_LENDER_CUSTOMER_FRIENDLY_VEHICLE_COLUMNS],
]);

export const CUSTOMER_FRIENDLY_COLUMNS_MAP = new Map<TableName, Map<TableView, string[]>>([
  [TableName.RAC, VIEW_COMMON_CUSTOMER_FRIENDLY_COLUMNS_MAP],
  [TableName.PE, VIEW_COMMON_CUSTOMER_FRIENDLY_COLUMNS_MAP],
  [TableName.ALI, VIEW_COMMON_CUSTOMER_FRIENDLY_COLUMNS_MAP],
  [TableName.LENDER, VIEW_LENDER_CUSTOMER_FRIENDLY_COLUMNS_MAP],
]);
