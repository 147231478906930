import { FormField } from 'modules/core/types';
import i18n from 'i18n';
import { Dropdown, Input } from 'modules/core/components';
import { EMPTY_STRING } from 'modules/core/constants';

import {
  AGE_OPTIONS,
  DRIVE_TYPE_OPTIONS,
  EXTRA_CASH_OPTIONS,
  MILE_OPTIONS,
  VEHICLE_TYPE_OPTIONS,
  YEAR_OPTIONS,
} from './options.const';
import { FILTERS_FIELD_NAMES, VEHICLE_FILTERS_INITIAL_VALUES } from './vehicleFilters.const';
import { IVehicleFilterField } from './types';
import { Multiselect } from '../Multiselect/Multiselect';

export const VEHICLE_FILTERS_FIELDS: IVehicleFilterField[] = [
  {
    label: i18n.t('components.vehicleFilters.labels.location'),
    name: FILTERS_FIELD_NAMES.STORES,
    component: Multiselect as FormField,
  },
  {
    label: i18n.t('components.vehicleFilters.labels.condition'),
    name: FILTERS_FIELD_NAMES.VEHICLE_TYPE,
    component: Dropdown as FormField,
    controlProps: { options: VEHICLE_TYPE_OPTIONS },
  },
  {
    label: i18n.t('components.vehicleFilters.labels.bodyStyle'),
    name: FILTERS_FIELD_NAMES.VEHICLES,
    component: Multiselect as FormField,
  },
  {
    label: i18n.t('components.vehicleFilters.labels.year'),
    name: FILTERS_FIELD_NAMES.YEARS,
    component: Dropdown as FormField,
    controlProps: { options: YEAR_OPTIONS },
    additionalComponent: {
      name: FILTERS_FIELD_NAMES.YEARS_VALUE,
      defaultValue: VEHICLE_FILTERS_INITIAL_VALUES.yearsValue,
      label: EMPTY_STRING,
      component: Dropdown as FormField,
    },
  },
  {
    label: i18n.t('components.vehicleFilters.labels.mileage'),
    name: FILTERS_FIELD_NAMES.MILES,
    component: Dropdown as FormField,
    controlProps: { options: MILE_OPTIONS },
    additionalComponent: {
      name: FILTERS_FIELD_NAMES.ODOMETER_MILES,
      label: EMPTY_STRING,
      component: Input,
      controlProps: { placeholder: i18n.t('components.input.placeholders.integer') },
    },
  },
  {
    label: i18n.t('components.vehicleFilters.labels.inventoryAge'),
    name: FILTERS_FIELD_NAMES.AGES,
    component: Dropdown as FormField,
    controlProps: { options: AGE_OPTIONS },
  },
  {
    label: i18n.t('components.vehicleFilters.labels.make'),
    name: FILTERS_FIELD_NAMES.MAKES,
    component: Multiselect as FormField,
  },
  {
    label: i18n.t('components.vehicleFilters.labels.model'),
    name: FILTERS_FIELD_NAMES.MODELS,
    component: Multiselect as FormField,
  },
  {
    label: i18n.t('components.vehicleFilters.labels.driveType'),
    name: FILTERS_FIELD_NAMES.DRIVE_TYPE,
    component: Multiselect as FormField,
    controlProps: { options: DRIVE_TYPE_OPTIONS },
  },
  {
    label: i18n.t('components.vehicleFilters.labels.passengerCapacity'),
    name: FILTERS_FIELD_NAMES.PASSENGER_CAPACITY,
    component: Multiselect as FormField,
    controlProps: { options: [] },
  },
  {
    label: i18n.t('components.vehicleFilters.labels.extraCash'),
    name: FILTERS_FIELD_NAMES.EXTRA_CASH,
    component: Dropdown as FormField,
    controlProps: { options: EXTRA_CASH_OPTIONS, disabled: true },
  },
];
