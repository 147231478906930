import React, { FC, useCallback, useEffect, useState } from 'react';
import { Form, Row } from 'react-bootstrap';
import { ButtonVariant, ColumnWidth, TableName } from 'modules/core/enums';
import { DEFAULT_PLACEHOLDER_TITLE } from 'modules/ProbabilityEstimator/probabilityEstimator.const';
import { VehicleSearchTable } from 'modules/VehicleSearchTable';
import { Button, Loader, PageHeader } from 'modules/core/components';
import { FormProvider } from 'react-hook-form';
import {
  lenderSlice,
  resetByStock,
  resetVehicles,
  resetVehiclesByParams,
  resetVehiclesByStock,
  setIsTableView,
} from 'store/reducers/lenders.reducer';
import {
  useFormEnhanced,
  useFeesAndTaxesInitialValues,
  useModalState,
  useDebounceCallback,
  useSettingsInitialValues,
  useDebounceVariable,
} from 'modules/core/hooks';
import { useTranslation } from 'react-i18next';
import {
  getFetchingLenderFlag,
  getIsTableView,
  getLenderInitialValues,
  getLenderReportData,
  getLenderState,
  getLenderVehiclesByParams,
  getLenderVehiclesByStock,
  getVehicleFetchingFlags,
  getNotAvailableVehicles,
  getNotFoundVehicles,
} from 'store/selectors/lenders.selector';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { IS_VALID_DELAY, MODAL_DIALOG_ID, REQUEST_DELAY } from 'modules/core/constants';
import { SettingsModal } from 'modules/ProbabilityEstimator/SettingsModal';
import { ConsumerInformation } from 'modules/ProbabilityEstimator/ConsumerInformation';
import { AppDispatch } from 'store/store';
import { getSubmitVehicleObjectQuery, getTotalVehicles } from 'modules/core/utils';
import {
  LENDER_BY_STOCK_FORM_NAMES_MAPPER,
  LENDER_FORM_NAMES_MAPPER,
} from 'modules/core/constants/mappers/submitVehicles.mapper';
import { getLenderVehiclesByStockThunk, getVehiclesForLenderThunk } from 'actions/vehicleActions';
import { PageName } from 'modules/ProbabilityEstimator/ConsumerInformation/hooks/useConsumerInformationData';
import { getLenderControlsThunk, getLenderThunk } from 'actions/lenderAction';
import vehicleApi from 'api/vehicleApi';
import { LenderVehicleCard } from 'modules/core/components/Table/VehicleCardView/LenderVehicleCard/LenderVehicleCard';
import { AdaptedCol } from 'modules/ProbabilityEstimator/styles';
import { DisabledContainer } from 'common/styles';
import { useSwitchFilters } from 'modules/core/hooks/useSwitchFilters';
import { LENDERS_TAXES_FIELDS } from 'modules/core/shared/feesAndTaxes.config';
import { LoanVariablesDialog } from 'components/Modals/LoanVariablesDialog/LoanVariablesDialog';

import { ILenderForm } from './types/Lender.types';
import { TitleContainer } from '../core/components/PageHeader/TitleContainer/TitleContainer';
import { REHASH_CALCULATOR_FIELDS } from './RehashCalculator/RehashCalculator.const';
import { LenderService } from './services/Lender.service';
import { FilterView } from '../core/enums/filterView.enum';
import { ILenderVehicle } from '../core/types';
import { LTV_INC_TAXES_FEES } from './Lender.const';

export const Lender: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();

  const lenderState = useSelector(getLenderState);
  const vehiclesByParams = useSelector(getLenderVehiclesByParams);
  const vehiclesByStock = useSelector(getLenderVehiclesByStock);
  const notAvailableVehicles = useSelector(getNotAvailableVehicles);
  const notFoundVehicles = useSelector(getNotFoundVehicles);
  const isTableView = useSelector(getIsTableView);
  const { name: lenderName, id: lenderId } = lenderState;
  const isFetchingLender = useSelector(getFetchingLenderFlag);
  const { isFetchingVehicles, isFetchingVehiclesFailed } = useSelector(getVehicleFetchingFlags, shallowEqual);

  const [isActive, setIsActive] = useState(true);

  const titleContainer = <TitleContainer imgSrc={lenderState.logo} title={lenderName} />;

  const { methods, syncFormAction } = useFormEnhanced<ILenderForm>(
    lenderSlice.actions.syncInitialValues,
    getLenderInitialValues,
    {
      mode: 'onChange',
      shouldUnregister: false,
    },
  );

  const {
    handleSubmit,
    setValue,
    watch,
    getValues,
    formState: { isValid },
  } = methods;

  const {
    tier,
    vehicleFilters,
    cashDownPayment,
    desiredMonthlyPayment,
    grossMonthlyIncome,
    netDownPayment,
    tableView,
    stocks,
    filterView,
    preferences,
    feesAndTaxes,
  } = watch();

  const commonDependencies = [
    tier.tier_name,
    grossMonthlyIncome,
    desiredMonthlyPayment,
    ...Object.values(feesAndTaxes),
  ];

  const modalDependencies = [...Object.values(preferences), ...Object.values(feesAndTaxes), netDownPayment];

  const { currentFilterName, isValidStock, isRequestNeeded } = useSwitchFilters(
    commonDependencies,
    filterView,
    isFetchingVehiclesFailed,
    watch,
    setValue,
    modalDependencies,
  );

  useEffect(() => {
    if (isRequestNeeded) {
      if (filterView === FilterView.BY_PARAMS) dispatch(resetVehiclesByParams());
      else dispatch(resetVehiclesByStock());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, filterView]);

  const vehicles: ILenderVehicle[] | undefined =
    filterView === FilterView.BY_PARAMS ? vehiclesByParams : vehiclesByStock;
  const totalVehicles = getTotalVehicles(filterView, isValidStock, vehicles);

  useEffect(() => {
    if (!isValidStock) dispatch(resetByStock());
  }, [dispatch, isValidStock]);

  useEffect(() => {
    if (!stocks.length && vehicles?.length) dispatch(resetByStock());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, vehicles?.length]);

  useFeesAndTaxesInitialValues(setValue, getLenderInitialValues, undefined, undefined, undefined, true);
  useSettingsInitialValues(setValue, getLenderInitialValues);

  const { handleOpenDialog, isModalDialogActive } = useModalState([MODAL_DIALOG_ID.PREFERENCES]);

  const headerComponent = (
    <Button title={t('components.preferences.title')} buttonTheme={ButtonVariant.OUTLINE} onClick={handleOpenDialog} />
  );

  const previewPlaceholder = t('components.RACAffordabilityEngine.previewPlaceholderPostfix', {
    totalVehicles,
    count: totalVehicles,
  });

  const onSubmit = useCallback(async () => {
    syncFormAction();

    const values = getValues() as ILenderForm;
    const isFilterByParams = values.filterView === FilterView.BY_PARAMS;

    const CURRENT_LENDER_FORM_NAMES_MAPPER = isFilterByParams
      ? LENDER_FORM_NAMES_MAPPER
      : LENDER_BY_STOCK_FORM_NAMES_MAPPER;

    if (typeof values.vehicleFilters.vehicleType === 'string') {
      values.condition = values.vehicleFilters.vehicleType;
    }

    // eslint-disable-next-line no-param-reassign
    if (!isFilterByParams) values.vehicleFilters.minMonthlyPayment = 0;

    const vehicleParameters = getSubmitVehicleObjectQuery<ILenderForm>(
      values,
      LenderService.getSubmitQuery,
      CURRENT_LENDER_FORM_NAMES_MAPPER,
    );

    if (isFilterByParams) await dispatch(getVehiclesForLenderThunk(vehicleParameters));
    else await dispatch(getLenderVehiclesByStockThunk(vehicleParameters));
  }, [dispatch, syncFormAction, getValues]);

  const dependentValues = {
    cashDownPayment,
    desiredMonthlyPayment,
    grossMonthlyIncome,
    netDownPayment,
    tableView,
    tier: tier.tier_name,
    ...feesAndTaxes,
    ...vehicleFilters,
  };

  const onSubmitDebounced = useDebounceCallback((values: unknown) => {
    onSubmit();
  }, REQUEST_DELAY);

  const areRequiredFieldsFilled = Boolean(
    grossMonthlyIncome && tier.tier_name && !Number.isNaN(feesAndTaxes.salesTaxRate),
  );

  const debouncedIsValid = useDebounceVariable(isValid, IS_VALID_DELAY);

  useEffect(() => {
    if (!areRequiredFieldsFilled) {
      vehicleApi.cancelGetLenderVehiclesRequests();
      vehicleApi.cancelGetLenderVehiclesByStockRequests();
    }
  }, [areRequiredFieldsFilled]);

  useEffect(() => {
    if (typeof totalVehicles !== 'undefined' && !debouncedIsValid) {
      dispatch(resetVehicles());
    }

    if (areRequiredFieldsFilled && isRequestNeeded && isValidStock) {
      const values = getValues();

      vehicleApi.cancelGetLenderVehiclesRequests();
      vehicleApi.cancelGetLenderVehiclesByStockRequests();
      onSubmitDebounced(values);
      setValue(currentFilterName, false);
    }
  }, [
    stocks.length,
    filterView,
    debouncedIsValid,
    totalVehicles,
    areRequiredFieldsFilled,
    isRequestNeeded,
    isValidStock,
    currentFilterName,
    dispatch,
    getValues,
    onSubmitDebounced,
    setValue,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ...Object.values(dependentValues),
  ]);

  useEffect(() => {
    dispatch(getLenderThunk(lenderId));
    dispatch(getLenderControlsThunk(lenderId));
    setValue('lenderName', lenderName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsActive(!!lenderState.tiers.length);
  }, [lenderState]);

  const { handleCloseDialog: handleLoanCloseDialog, isModalDialogActive: isModalLoanDialogActive } = useModalState([
    MODAL_DIALOG_ID.LOAN_VARIABLES,
  ]);

  return isFetchingLender ? (
    <Loader />
  ) : (
    <FormProvider {...methods}>
      {!isActive && <DisabledContainer />}
      <PageHeader title={titleContainer} headerComponent={headerComponent} />
      <Form onSubmit={handleSubmit(onSubmit)}>
        {isModalDialogActive && (
          <SettingsModal tableName={TableName.LENDER} settingSubmitCallback={handleSubmit(onSubmit)} />
        )}
        <Row>
          <AdaptedCol xs={ColumnWidth.FULL}>
            <ConsumerInformation
              submitCallback={handleSubmit(onSubmit)}
              title={t('components.lender.consumerInformationTitle')}
              firstConsumerFields={REHASH_CALCULATOR_FIELDS}
              secondConsumerFields={LENDERS_TAXES_FIELDS}
              isFetchingVehicles={isFetchingVehicles}
              pageName={PageName.LENDER}
              totalVehicles={totalVehicles}
              areRequiredFieldsFilledIn={areRequiredFieldsFilled}
              ltvTaxesFeesFilter={lenderState.ltv_calculation_method === LTV_INC_TAXES_FEES ? 'true' : 'false'}
              isLender
            />
          </AdaptedCol>
        </Row>
        <Row>
          <AdaptedCol xs={ColumnWidth.FULL}>
            <VehicleSearchTable
              tableName={TableName.LENDER}
              data={vehicles || []}
              tableSubmitCallback={handleSubmit(onSubmit)}
              previewVehiclesCount={totalVehicles}
              defaultPlaceholderTitle={DEFAULT_PLACEHOLDER_TITLE}
              previewPlaceholder={previewPlaceholder}
              isTableFiltersDisabled={!debouncedIsValid}
              isTableActionsDisabled={!vehicles?.length || !areRequiredFieldsFilled}
              isTableView={isTableView}
              setIsTableView={setIsTableView}
              fetchingVehicleFlagsSelector={getVehicleFetchingFlags}
              reportDataSelector={getLenderReportData}
              CustomVehicleCard={LenderVehicleCard}
              notAvailableVehicles={notAvailableVehicles}
              notFoundVehicles={notFoundVehicles}
              isFilterByStockNumber
              isValidStock={isValidStock}
            />
          </AdaptedCol>
        </Row>
      </Form>
      {isModalLoanDialogActive && (
        <LoanVariablesDialog
          isLender={true}
          isLoanVariablesDialogActive={isModalLoanDialogActive}
          resetState={() => {}}
          onHide={handleLoanCloseDialog()}
          isRouteOneCar={false}
          isAddedRouteOneVehicle={false}
        />
      )}
    </FormProvider>
  );
};
