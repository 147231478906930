import {
  FULL_DATE_TO_STRING_OPTION,
  MONEY_SYMBOL,
  PERCENT_SYMBOL,
  SLASH_SYMBOL,
  COLON_SYMBOL,
  SEPARATOR_SYMBOL,
  ELLIPSIS,
  HYPHEN,
} from '../constants';
import { TTranslationPayload } from '../types';

type TFormatterValue = number | TTranslationPayload;

export const capitalize = (value: string): string => {
  if (typeof value !== 'string') return '';

  return value.charAt(0).toUpperCase() + value.slice(1);
};

const appendSymbol = (value: TFormatterValue, symbol: string): string => `${value} ${symbol}`;

const prependedSymbol = (symbol: string, value: TFormatterValue): string => `${symbol} ${value}`;

export const percentFormatter = (value: TFormatterValue): string => appendSymbol(value, PERCENT_SYMBOL);

export const moneyFormatter = (value: TFormatterValue): string => prependedSymbol(MONEY_SYMBOL, value);

export const slashFormatter = (value: TFormatterValue): string => appendSymbol(value, SLASH_SYMBOL);

export const colonFormatter = (value: TFormatterValue): string => appendSymbol(value, COLON_SYMBOL);

export const vinFormatter = (value: string): string => value.slice(0, 4) + ELLIPSIS;

export const separatorFormatter = (value: TFormatterValue): string => prependedSymbol(`${SEPARATOR_SYMBOL} `, value);

export const thousandFormatter = (value: number, options?: Intl.NumberFormatOptions): string =>
  Intl.NumberFormat('en-US', options).format(value);

export const thousandFormatterOrHyphen = (value: number | null): string =>
  value !== null ? thousandFormatter(value) : HYPHEN;

export const valueOrHyphen = (value: number | null): string => (value !== null ? value.toString() : HYPHEN);

export const costFormatter = (value: number | null): string =>
  value !== null ? moneyFormatter(thousandFormatter(value)) : HYPHEN;

export const reportCostFormatter = (value: number): string =>
  moneyFormatter(thousandFormatter(Number(value.toFixed(2))));

const toDate = (value: string, option?: Record<string, string>): string =>
  Intl.DateTimeFormat('en-US', option).format(new Date(value));

export const fullDateFormatter = (value: string): string => toDate(value, FULL_DATE_TO_STRING_OPTION);

export const numericDateFormatter = (value: string): string => toDate(value);

export const getExtraCashConversionValue = (value: number): string => {
  switch (true) {
    case value >= 0 && value <= 500:
      return '$0';
    case value >= 500 && value <= 1000:
      return 'up to $1,000';
    case value >= 1001 && value <= 2000:
      return 'up to $2,000';
    case value >= 2001 && value <= 3000:
      return 'up to $3,000';
    case value >= 3001 && value <= 4000:
      return 'up to $4,000';
    case value >= 4001 && value <= 5000:
      return 'up to $5,000';
    case value > 5000:
      return 'more than $5,000';
    default:
      return thousandFormatter(value);
  }
};

export const getObjectProperty = (object: Record<string, any>, path: string): Record<string, any> | any => {
  if (path === undefined || path === null) {
    return object;
  }

  const parts = path.split('.');

  return parts.reduce((acc, key) => acc?.[key], object);
};
