export const filterNullUndefinedZeroNotNumberValues = (data: Record<string, unknown>): Record<string, unknown> => {
  const prepareData = Object.entries(data).filter((item) => {
    const value = item[1]; // item: [key, value],

    if (Number(value) === 0 || value === null || value === undefined || Number.isNaN(value)) return false;

    return true;
  });

  return Object.fromEntries(prepareData);
};
