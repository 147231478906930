import React, { FC } from 'react';

import { Badge as BadgeUI } from './styles';

export interface IBadge {
  title: string;
  color: string;
  className?: string;
}

export const Badge: FC<IBadge> = ({ title, color, className = 'badge-ui' }) => (
  <BadgeUI className={className} color={color}>
    {title}
  </BadgeUI>
);
