// In order to prevent cycled dependencies
// this code has been temporarily moved here.
import React from 'react';
import i18n from 'i18n';
import { Input } from 'modules/core/components/Input/Input';
import { APPROVED_LOAN_TERMS_FIELD_NAMES } from 'modules/RACAffordabilityEngine/const/fieldNames.const';

import { IField } from '../types';
import { CalculatorAdornment, DollarAdornment, PercentAdornment } from '../components/Adornments';
import { FEES_AND_TAXES_FIELD_NAMES } from './feesAndTaxes.const';
import { TableName } from '../enums';
import { requiredValidator } from '../validators';

export const FEES_AND_TAXES_FIELDS: IField[] = [
  {
    label: i18n.t('components.feesAndTaxes.labels.salesTaxRate'),
    name: FEES_AND_TAXES_FIELD_NAMES.SALES_TAX_RATE,
    isVerticalAlign: true,
    component: (props) => <Input {...props} />,
    controlProps: {
      isRequired: true,
      rules: requiredValidator,
      endAdornment: <PercentAdornment />,
      decimalScale: 3,
      placeholder: i18n.t('components.input.placeholders.percent'),
    },
  },
  {
    label: i18n.t('components.feesAndTaxes.labels.estimatedFees'),
    name: FEES_AND_TAXES_FIELD_NAMES.ESTIMATED_FEES,
    isVerticalAlign: true,
    component: (props) => <Input {...props} />,
    controlProps: {
      startAdornment: <DollarAdornment />,
      decimalScale: 0,
      placeholder: i18n.t('components.input.placeholders.integer'),
    },
  },
  {
    label: i18n.t('components.feesAndTaxes.labels.bankFees'),
    name: FEES_AND_TAXES_FIELD_NAMES.BANK_FEES,
    isVerticalAlign: true,
    component: (props) => <Input {...props} />,
    controlProps: {
      startAdornment: <DollarAdornment />,
      hideInitZeroValue: true,
      decimalScale: 0,
      placeholder: i18n.t('components.input.placeholders.integer'),
    },
  },
  {
    label: i18n.t('components.feesAndTaxes.labels.bankDiscount'),
    name: FEES_AND_TAXES_FIELD_NAMES.BANK_DISCOUNT,
    isVerticalAlign: true,
    component: (props) => <Input {...props} />,
    controlProps: {
      endAdornment: <PercentAdornment />,
      hideInitZeroValue: true,
      decimalScale: 3,
      placeholder: i18n.t('components.input.placeholders.percent'),
    },
  },
];

export const LENDERS_FEES_AND_TAXES_FIELDS: IField[] = [
  {
    label: i18n.t('components.feesAndTaxes.labels.bankFees'),
    name: FEES_AND_TAXES_FIELD_NAMES.BANK_FEES,
    isVerticalAlign: true,
    component: (props) => <Input {...props} />,
    controlProps: {
      startAdornment: <DollarAdornment />,
      hideInitZeroValue: true,
      decimalScale: 0,
      placeholder: i18n.t('components.input.placeholders.integer'),
    },
  },
  {
    label: i18n.t('components.feesAndTaxes.labels.bankDiscount'),
    name: FEES_AND_TAXES_FIELD_NAMES.BANK_DISCOUNT,
    isVerticalAlign: true,
    component: (props) => <Input {...props} />,
    controlProps: {
      endAdornment: <PercentAdornment />,
      hideInitZeroValue: true,
      decimalScale: 3,
      placeholder: i18n.t('components.input.placeholders.percent'),
    },
  },
];

export const LENDERS_TAXES_FIELDS: IField[] = [
  {
    label: i18n.t('components.feesAndTaxes.labels.salesTaxRate'),
    name: FEES_AND_TAXES_FIELD_NAMES.SALES_TAX_RATE,
    isVerticalAlign: true,
    component: (props) => <Input {...props} />,
    controlProps: {
      endAdornment: <PercentAdornment />,
      required: true,
      rules: requiredValidator,
      decimalScale: 3,
      placeholder: i18n.t('components.input.placeholders.percent'),
    },
  },
  {
    label: i18n.t('components.feesAndTaxes.labels.salesFees'),
    name: FEES_AND_TAXES_FIELD_NAMES.SALES_FEES,
    isVerticalAlign: true,
    component: (props) => <Input {...props} />,
    controlProps: {
      startAdornment: <DollarAdornment />,
      decimalScale: 0,
      placeholder: i18n.t('components.input.placeholders.integer'),
    },
  },
  {
    name: APPROVED_LOAN_TERMS_FIELD_NAMES.FRONT_AND_BACK_ENDS_ADDS,
    component: Input,
    label: i18n.t('components.consumerInformation.frontAndBackEndsAdds'),
    isVerticalAlign: true,
    controlProps: {
      textStyle: 'bold',
      textPosition: 'start',
      endAdornment: <CalculatorAdornment />,
      disabled: false,
      decimalScale: 0,
      allowNegative: true,
      readonly: true,
      hideInitZeroValue: true,
      placeholder: i18n.t('components.input.placeholders.integer'),
    },
  },
];

export const FEES_AND_TAXES_FIELDS_MAP_BY_TABLE = new Map<TableName, IField[]>([
  [TableName.PE, FEES_AND_TAXES_FIELDS],
  [TableName.LENDER, LENDERS_FEES_AND_TAXES_FIELDS],
]);
