import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'react-bootstrap';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { PageHeader } from 'modules/core/components';
import { ColumnWidth, TableName } from 'modules/core/enums';
import {
  useDebounceCallback,
  useDebounceVariable,
  useFeesAndTaxesInitialValues,
  useFormEnhanced,
  useModalState,
  useSettingsInitialValues,
} from 'modules/core/hooks';
import { FormProvider } from 'react-hook-form';
import {
  getRACInitialValues,
  getVehiclesByParams,
  getVehiclesByStock,
  getVehicleFetchingFlags,
  getRACReportData,
  getIsTableView,
  getNotAvailableVehicles,
  getNotFoundVehicles,
  getRouteOneData,
  getRateSheetsDataFlags,
  getLoanVariablesCurrentVehicle,
  getRoteOneDataFlags,
} from 'store/selectors/RACAffordabilityEngine.selector';
import {
  RACAffordabilityEngineSlice,
  closeRateSheetsModal,
  resetByStock,
  resetVehicles,
  resetVehiclesByParams,
  resetVehiclesByStock,
  setIsReset,
  setIsTableView,
  clearLoanVariablesCurrentVehicle,
} from 'store/reducers/RACAffordabilityEngine.reducer';
import { VehicleSearchTable } from 'modules/VehicleSearchTable';
import { getRACVehiclesThunk, getRACVehiclesByStockThunk, getRateSheetsDataThunk } from 'actions/vehicleActions';
import { AppDispatch } from 'store/store';
import { IRACVehicle } from 'modules/core/types';
import { useSwitchFilters } from 'modules/core/hooks/useSwitchFilters';
import { TitleContainer } from 'modules/core/components/PageHeader/TitleContainer/TitleContainer';
import { LenderVehicleCard } from 'modules/core/components/Table/VehicleCardView/LenderVehicleCard/LenderVehicleCard';
import { vehicleApi } from 'api';
import { FilterView } from 'modules/core/enums/filterView.enum';
import { getSubmitVehicleObjectQuery, getTotalVehicles } from 'modules/core/utils';
import {
  RAC_BY_STOCK_FORM_NAMES_MAPPER,
  RAC_FORM_NAMES_MAPPER,
} from 'modules/core/constants/mappers/submitVehicles.mapper';
import { IS_VALID_DELAY, MODAL_DIALOG_ID, REQUEST_DELAY, STATIC_IMAGE_URLS } from 'modules/core/constants';
import { getFrontAndBackEndsAdds } from 'components/Modals/FrontAndBackEndsAddsModal/service';
import { getFeesAndTaxesState } from 'store/selectors/controls.selector';
import { getUser } from 'store/selectors/auth.selector';
import { getRegionControlsThunk } from 'actions/controlActions';
import { SetupRateSheetAndTaxRateModal } from 'components/Modals/SetupRateSheetAndTaxRateModal/SetupRateSheetAndTaxRateModal';
import { FEES_AND_TAXES_FIELD_NAMES } from 'modules/core/shared';
import { LoanVariablesDialog } from 'components/Modals/LoanVariablesDialog/LoanVariablesDialog';
import { IS_SSO_AND_SHOW_LOAN_VARIABLES_DIALOG } from 'modules/auth/constants/auth.const';
import { useConfirmTabClose } from 'hooks/useConfirmTabClose';
import { getAddRouteOneVehicleFlags, getCreatedRouteOneVehicle } from 'store/selectors/vehicle.selector';
import { setIsModalsDisabled } from 'store/reducers/ui.reducer';

import {
  RAC_CONSUMER_INFORMATION_FIRST_FIELDS,
  RAC_CONSUMER_INFORMATION_SECOND_FIELDS,
} from './const/fieldConfig.const';
import { IRACAffordabilityEngineForm } from './types/RACAffordabilityEngineForm';
import { APPROVED_LOAN_TERMS_NAMES_WATCH, DEFAULT_PLACEHOLDER_TITLE } from './RACAffordabilityEngine.const';
import { RACLogo } from './styles';
import { RACAffordabilityEngineService } from './RACAffordabilityEngine.service';
import { AdaptedCol } from '../ProbabilityEstimator/styles';
import { ConsumerInformation } from '../ProbabilityEstimator/ConsumerInformation';
import { PageName } from '../ProbabilityEstimator/ConsumerInformation/hooks/useConsumerInformationData';
import { APPROVED_LOAN_TERMS_FIELD_NAMES, WITHOUT_INVENTORY_FIELDS_NAMES } from './const/fieldNames.const';
import { useRouteOneValues } from './hooks/useRouteOneValues';
import { WithoutInventoryPageHeaderRow } from './WithoutInventoryPageHeaderRow/WithoutInventoryPageHeaderRow';
import { useHideVehicleField } from '../core/hooks/useHideVehicleField';

export const RACAffordabilityEngine: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();
  const routeOneData = useSelector(getRouteOneData);
  const user = useSelector(getUser);
  const vehiclesByParams = useSelector(getVehiclesByParams);
  const vehiclesByStock = useSelector(getVehiclesByStock);
  const notAvailableVehicles = useSelector(getNotAvailableVehicles);
  const notFoundVehicles = useSelector(getNotFoundVehicles);
  const isTableView = useSelector(getIsTableView);
  const addRouteOneVehicleFlags = useSelector(getAddRouteOneVehicleFlags);
  const createdRouteOneVehicle = useSelector(getCreatedRouteOneVehicle);
  const { isShow } = useSelector(getRateSheetsDataFlags);
  const currentVehicleData = useSelector(getLoanVariablesCurrentVehicle);
  const routeOneDataFlags = useSelector(getRoteOneDataFlags);
  const { isReset, isFetchingVehiclesFailed, isFetchingVehicles } = useSelector(getVehicleFetchingFlags, shallowEqual);
  const [isRouteOneVehicle, setIsRouteOneVehicle] = useState<boolean>(false);
  const [isAddedRouteOneVehicle, setAddedIsRouteOneVehicle] = useState<boolean>(false);
  const isSsoAndLoanVariablesDialogOpen = sessionStorage.getItem(IS_SSO_AND_SHOW_LOAN_VARIABLES_DIALOG);
  const { handleOpenDialog, handleCloseDialog, isModalDialogActive } = useModalState([MODAL_DIALOG_ID.LOAN_VARIABLES]);

  const { methods, syncFormAction } = useFormEnhanced<IRACAffordabilityEngineForm>(
    RACAffordabilityEngineSlice.actions.syncInitialValues,
    getRACInitialValues,
    {
      mode: 'onChange',
      shouldUnregister: false,
    },
  );

  useEffect(() => {
    dispatch(getRegionControlsThunk());
  }, [dispatch]);

  const {
    handleSubmit,
    setValue,
    watch,
    getValues,
    formState: { isValid, errors },
  } = methods;

  const isFormValid = Object.keys(errors).length === 0 && !isValid ? true : isValid;

  const {
    tier,
    desiredMonthlyPayment,
    grossMonthlyIncome,
    netDownPayment,
    feesAndTaxes,
    stocks,
    frontAndBackEndsAdds,
    rateSheetSettingsModal,
    ...otherValues
  } = watch(APPROVED_LOAN_TERMS_NAMES_WATCH) as IRACAffordabilityEngineForm;
  const { tableView, filterView, preferences } = watch();

  const formValues = watch() as IRACAffordabilityEngineForm;
  const { insuranceControl } = useSelector(getFeesAndTaxesState);
  const { addOns, vsc, gap } = formValues.totalFrontAndBackEndsAddsDialog;

  useEffect(() => {
    if (insuranceControl !== 0 && !frontAndBackEndsAdds) {
      const frontAndBackEndsAdds = getFrontAndBackEndsAdds(addOns || 0, vsc || 0, gap || 0);

      setValue(APPROVED_LOAN_TERMS_FIELD_NAMES.FRONT_AND_BACK_ENDS_ADDS, frontAndBackEndsAdds || 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insuranceControl, setValue, frontAndBackEndsAdds, vsc]);

  const commonDependencies = [tier.id, desiredMonthlyPayment, grossMonthlyIncome, ...Object.values(feesAndTaxes)];

  const modalDependencies = Object.values(preferences);

  const { currentFilterName, isValidStock, isRequestNeeded } = useSwitchFilters(
    commonDependencies,
    filterView,
    isFetchingVehiclesFailed,
    watch,
    setValue,
    modalDependencies,
  );

  useEffect(() => {
    if (isRequestNeeded) {
      if (filterView === FilterView.BY_PARAMS) dispatch(resetVehiclesByParams());
      else dispatch(resetVehiclesByStock());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, filterView]);

  const vehiclesData: IRACVehicle[] | undefined =
    filterView === FilterView.BY_PARAMS ? vehiclesByParams : vehiclesByStock;

  const vehicles = useHideVehicleField(vehiclesData);
  const totalVehicles = getTotalVehicles(filterView, isValidStock, vehicles);

  useEffect(() => {
    if (!isValidStock) dispatch(resetByStock());
  }, [dispatch, isValidStock]);

  useEffect(() => {
    if (!stocks.length && vehicles?.length) dispatch(resetByStock());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, vehicles?.length]);

  const isRouteOneDataEmpty = Object.keys(routeOneData).length === 0;
  const isMakeItWork = user?.is_sso;

  const onSubmit = useCallback(() => {
    const values = getValues() as IRACAffordabilityEngineForm;
    const isFilterByParams = values.filterView === FilterView.BY_PARAMS;

    const CURRENT_RAC_FORM_NAMES_MAPPER = isFilterByParams ? RAC_FORM_NAMES_MAPPER : RAC_BY_STOCK_FORM_NAMES_MAPPER;

    if (typeof values.vehicleFilters.vehicleType === 'string') {
      values.condition = values.vehicleFilters.vehicleType;
    }

    // eslint-disable-next-line no-param-reassign
    if (!isFilterByParams) values.vehicleFilters.minMonthlyPayment = 0;

    let vehicleParameters = getSubmitVehicleObjectQuery<IRACAffordabilityEngineForm>(
      values,
      RACAffordabilityEngineService.getSubmitQuery,
      CURRENT_RAC_FORM_NAMES_MAPPER,
    );

    syncFormAction();

    if (isFilterByParams) dispatch(getRACVehiclesThunk(vehicleParameters));
    else dispatch(getRACVehiclesByStockThunk(vehicleParameters));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, syncFormAction, getValues]);

  useSettingsInitialValues(setValue, getRACInitialValues);
  useFeesAndTaxesInitialValues(setValue, getRACInitialValues, vsc, !!isMakeItWork, user?.isWithInventory);
  useRouteOneValues(
    setValue,
    isRouteOneDataEmpty,
    routeOneData,
    !!isReset,
    tier,
    desiredMonthlyPayment,
    grossMonthlyIncome,
    isMakeItWork,
    user?.isWithInventory,
  );

  const logo = <RACLogo src={STATIC_IMAGE_URLS.racLogo} alt={t('components.RACAffordabilityEngine.logoAlt')} />;

  const previewPlaceholder = t('components.RACAffordabilityEngine.previewPlaceholderPostfix', {
    totalVehicles,
    count: totalVehicles,
  });

  const onSubmitDebounced = useDebounceCallback((values: unknown) => {
    onSubmit();
  }, REQUEST_DELAY);

  // useDebounceVariable hook is used to fix isValid variable state behavior
  const debouncedIsValid = useDebounceVariable(isFormValid, IS_VALID_DELAY);

  const areRequiredFieldsFilledIn = Boolean(
    grossMonthlyIncome && tier.tier !== 0 && !Number.isNaN(feesAndTaxes.salesTaxRate),
  );

  useEffect(() => {
    if (isReset && !areRequiredFieldsFilledIn) return;

    if (
      debouncedIsValid &&
      areRequiredFieldsFilledIn &&
      (isRequestNeeded || addRouteOneVehicleFlags.isLoaded) &&
      isValidStock
    ) {
      const values = getValues();

      vehicleApi.cancelGetRACVehiclesRequests();
      vehicleApi.cancelGetRACVehiclesByStockRequest();
      onSubmitDebounced(values);
      setValue(currentFilterName, false);
    }
  }, [
    addRouteOneVehicleFlags.isLoaded,
    currentFilterName,
    setValue,
    isRequestNeeded,
    isValidStock,
    getValues,
    tableView,
    onSubmitDebounced,
    debouncedIsValid,
    areRequiredFieldsFilledIn,
    isReset,
    tier.tier,
    desiredMonthlyPayment,
    grossMonthlyIncome,
    netDownPayment,
    feesAndTaxes.vscAndGapInsurance,
    feesAndTaxes.estimatedFees,
    feesAndTaxes.documentFee,
    feesAndTaxes.salesTaxRate,
    stocks.length,
    // Inserting the otherValues object initiates a comparison by reference and an additional hook launch.
    // This technique is used to avoid spreading the entire otherValues object separately.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ...Object.values(otherValues),
  ]);

  useEffect(() => {
    if (areRequiredFieldsFilledIn) {
      dispatch(setIsReset(false));
    }
  }, [dispatch, areRequiredFieldsFilledIn]);

  useEffect(() => {
    if (!areRequiredFieldsFilledIn && typeof totalVehicles !== 'undefined') {
      dispatch(resetVehicles());
    }
  }, [dispatch, areRequiredFieldsFilledIn, totalVehicles]);

  useEffect(() => {
    if (!areRequiredFieldsFilledIn) {
      vehicleApi.cancelGetRACVehiclesRequests();
      vehicleApi.cancelGetRACVehiclesByStockRequest();
    }
  }, [areRequiredFieldsFilledIn]);

  useConfirmTabClose({ canShow: user?.is_sso });

  useEffect(() => {
    const isRouteOneDataExists = Object.keys(routeOneData || {}).length > 0;

    if (
      user?.is_sso &&
      !isSsoAndLoanVariablesDialogOpen &&
      isRouteOneDataExists &&
      routeOneData.vehicle_exists &&
      tier?.tier &&
      user?.isWithInventory
    ) {
      setIsRouteOneVehicle(true);
      handleOpenDialog();

      sessionStorage.setItem(IS_SSO_AND_SHOW_LOAN_VARIABLES_DIALOG, 'true');
    }
  }, [
    routeOneData,
    user?.is_sso,
    handleOpenDialog,
    tier?.tier,
    user?.isWithInventory,
    isSsoAndLoanVariablesDialogOpen,
  ]);

  useEffect(() => {
    if (!addRouteOneVehicleFlags.isLoaded && createdRouteOneVehicle.id && !currentVehicleData?.id) {
      setAddedIsRouteOneVehicle(true);
      handleOpenDialog();
    }

    if (addRouteOneVehicleFlags.isLoaded && createdRouteOneVehicle.id) {
      dispatch(clearLoanVariablesCurrentVehicle());
      handleCloseDialog()();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addRouteOneVehicleFlags.isLoaded, createdRouteOneVehicle.id, dispatch, currentVehicleData?.id]);

  useEffect(() => {
    const isRouteOneDataExists = Object.keys(routeOneData).length > 0;

    if (user?.is_sso && !user?.isWithInventory && isRouteOneDataExists) {
      dispatch(getRateSheetsDataThunk());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, Object.keys(routeOneData).length]);

  useEffect(() => {
    if (user?.is_sso && !user?.isWithInventory && routeOneDataFlags.isReceived && isRouteOneDataEmpty) {
      dispatch(setIsModalsDisabled(false));
    }
  }, [user, isRouteOneDataEmpty, routeOneDataFlags.isReceived, dispatch]);

  useEffect(() => {
    if (user?.isWithInventory) {
      dispatch(setIsModalsDisabled(false));
    }
  }, [user, dispatch]);

  const titleContainer = <TitleContainer title={t('components.RACAffordabilityEngine.title')} />;

  useEffect(() => {
    if (!user?.isWithInventory) {
      setValue(FEES_AND_TAXES_FIELD_NAMES.SALES_TAX_RATE, user?.rateSheetsData?.tax_rate);
    }
  }, [setValue, user?.isWithInventory, user?.rateSheetsData?.tax_rate]);

  const getPageLogoAndTitle = () => (
    <>
      <PageHeader title={logo} />
      <PageHeader title={titleContainer} />
    </>
  );

  useEffect(() => {
    if (!user?.isWithInventory) {
      setValue(WITHOUT_INVENTORY_FIELDS_NAMES.RATE_SHEET, user?.rateSheetsData?.rate_sheet || null);
      setValue(WITHOUT_INVENTORY_FIELDS_NAMES.REGION, user?.rateSheetsData?.region || null);
      setValue(FEES_AND_TAXES_FIELD_NAMES.SALES_TAX_RATE, user?.rateSheetsData?.tax_rate);
    }
  }, [
    setValue,
    user?.isWithInventory,
    user?.rateSheetsData?.rate_sheet,
    user?.rateSheetsData?.region,
    user?.rateSheetsData?.tax_rate,
  ]);

  return (
    <FormProvider {...methods}>
      {!user?.isWithInventory ? (
        <WithoutInventoryPageHeaderRow
          getPageLogoAndTitle={getPageLogoAndTitle}
          submitCallback={handleSubmit(onSubmit)}
        />
      ) : (
        getPageLogoAndTitle()
      )}
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <AdaptedCol xs={ColumnWidth.FULL}>
            <ConsumerInformation
              title={t('components.consumerInformation.racTitle')}
              totalVehicles={totalVehicles}
              firstConsumerFields={RAC_CONSUMER_INFORMATION_FIRST_FIELDS}
              secondConsumerFields={RAC_CONSUMER_INFORMATION_SECOND_FIELDS}
              isFetchingVehicles={isFetchingVehicles}
              pageName={PageName.RAC}
              submitCallback={handleSubmit(onSubmit)}
              areRequiredFieldsFilledIn={areRequiredFieldsFilledIn}
              isRac
            />
          </AdaptedCol>
        </Row>
        <Row>
          <Col xs={ColumnWidth.FULL}>
            <VehicleSearchTable
              tableName={TableName.RAC}
              data={vehicles || []}
              tableSubmitCallback={handleSubmit(onSubmit)}
              previewVehiclesCount={totalVehicles}
              defaultPlaceholderTitle={DEFAULT_PLACEHOLDER_TITLE}
              previewPlaceholder={previewPlaceholder}
              isTableFiltersDisabled={!areRequiredFieldsFilledIn || !user?.isWithInventory}
              isTableActionsDisabled={!vehicles?.length || !areRequiredFieldsFilledIn}
              isTableView={isTableView}
              setIsTableView={setIsTableView}
              fetchingVehicleFlagsSelector={getVehicleFetchingFlags}
              areRequiredFieldsFilledIn={areRequiredFieldsFilledIn}
              CustomVehicleCard={LenderVehicleCard}
              reportDataSelector={getRACReportData}
              notAvailableVehicles={notAvailableVehicles}
              notFoundVehicles={notFoundVehicles}
              isFilterByStockNumber
              isValidStock={isValidStock}
              isRac
              isMakeItWork={user?.is_sso}
              routeOneData={routeOneData}
              tier={tier.tier}
              showNoVehicleErrorMessage={
                isMakeItWork &&
                formValues.filterView === FilterView.BY_PARAMS &&
                !isRouteOneDataEmpty &&
                !!vehicles?.length &&
                !routeOneData.vehicle_exists
              }
              showNoVehicleInInventoryErrorMessage={
                isMakeItWork &&
                formValues.filterView === FilterView.BY_PARAMS &&
                !isRouteOneDataEmpty &&
                !!vehicles?.length &&
                routeOneData.vehicle_exists &&
                !vehicles.map((vehicle) => vehicle.vin).includes(routeOneData.sale_vehicle.vin)
              }
            />
          </Col>
        </Row>
      </Form>
      {isModalDialogActive && (
        <LoanVariablesDialog
          isLoanVariablesDialogActive={isModalDialogActive}
          resetState={() => {
            setIsRouteOneVehicle(false);
            setAddedIsRouteOneVehicle(false);
          }}
          onHide={handleCloseDialog()}
          isRouteOneCar={isRouteOneVehicle}
          isAddedRouteOneVehicle={isAddedRouteOneVehicle}
        />
      )}
      {isShow && (
        <SetupRateSheetAndTaxRateModal
          show={isShow}
          onHide={() => {
            dispatch(closeRateSheetsModal());
            dispatch(setIsModalsDisabled(false));

            if (
              !isSsoAndLoanVariablesDialogOpen &&
              Object.keys(routeOneData || {}).length > 0 &&
              routeOneData.vehicle_exists
            ) {
              setIsRouteOneVehicle(true);
              handleOpenDialog();
              sessionStorage.setItem(IS_SSO_AND_SHOW_LOAN_VARIABLES_DIALOG, 'true');
            }
          }}
        />
      )}
    </FormProvider>
  );
};
