import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { ROUTER_PATH } from 'router/const/routerPath.const';
import { getExtraCashConversionValue } from 'modules/core/utils';

interface IExtraCashCell {
  extraCashValue: number;
}
export const ExtraCashCell: FC<IExtraCashCell> = ({ extraCashValue }) => {
  const { pathname } = useLocation();

  const isExtraCashFormatted = pathname === ROUTER_PATH.PROBABILITY_ESTIMATOR;

  return <>{isExtraCashFormatted ? getExtraCashConversionValue(extraCashValue) : extraCashValue}</>;
};
