import { FieldsMapper } from 'modules/core/types';

// Convert camel case type properties to snake case.
// Finds mapped field from provided mapper.
export const convertToSnakeCase = <D>(object: D, mapper: FieldsMapper): Record<string, unknown> =>
  Object.entries(object as { [key: string]: unknown } | ArrayLike<unknown>).reduce((queryObject, [key, value]) => {
    const snakeCaseKey = mapper[key];

    return snakeCaseKey ? { ...queryObject, [snakeCaseKey]: value } : queryObject;
  }, {});

// TODO_AK: Need to convert to UpperCase all variables from back-end after Lenders tasks
// Also converts all attachments
export const convertToUpperCase = (object: Record<string, unknown>, mapper: FieldsMapper): Record<string, unknown> =>
  Object.entries(object).reduce((queryObject, [key, value]) => {
    const upperCaseKey = mapper[key];

    if (typeof value === 'object' && value) {
      return { ...queryObject, [upperCaseKey || key]: convertToUpperCase(value as Record<string, unknown>, mapper) };
    }

    return upperCaseKey ? { ...queryObject, [upperCaseKey]: value } : queryObject;
  }, {});

export const convertToNonFalsyValues = (data: Record<string, unknown>): Record<string, unknown> =>
  Object.entries(data).reduce(
    (nonFalsyValues: Record<string, unknown>, [key, value]) =>
      value === 0 || !!value ? { ...nonFalsyValues, [key]: value } : nonFalsyValues,
    {},
  );

export const convertFalsyValuesToZero = (data: Record<string, unknown>): Record<string, unknown> =>
  Object.entries(data).reduce(
    (nonFalsyValues: Record<string, unknown>, [key, value]) => ({ ...nonFalsyValues, [key]: value || 0 }),
    {},
  );
