import styled from 'styled-components';
import { COLORS } from 'modules/core/theme/colors';
import { Dropdown, InputGroup } from 'react-bootstrap';

const OVERRIDER_DROPDOWN_SELECTORS = `
  &,
  &:hover,
  &:focus,
  &:checked,
  &.btn-primary:not(:disabled):not(.disabled):active,
  .show > &.btn-primary.dropdown-toggle
`;

export const InputGroupUI = styled(InputGroup)`
  .btn.disabled,
  .btn:disabled,
  fieldset:disabled .btn {
    color: ${COLORS.GREY_24};
    background-color: ${COLORS.GREY_26};
    opacity: 1;
    border: 1px solid ${COLORS.GREY_18};
  }
  .btn { ${OVERRIDER_DROPDOWN_SELECTORS} {
    background-color: ${({ theme: { colors } }) => colors.white};
    border: 1px solid ${({ $isInvalid }) => ($isInvalid ? COLORS.RED_3 : COLORS.GREY_18)};
    color: ${({ theme: { colors }, $isPlaceholder }) => ($isPlaceholder ? COLORS.GREY_24 : colors.black)};
    box-shadow: none;
    ${({ $isPlaceholder }) => !$isPlaceholder && `font-weight: bold;`};
  }
`;

export const RacDropdown = styled(Dropdown)`
  btn.disabled,
  .btn:disabled,
  fieldset:disabled .btn {
    color: ${COLORS.GREY_24};
    background-color: ${COLORS.GREY_26};
    opacity: 1;
    border: 1px solid ${COLORS.GREY_18};
  }
`;

export const Menu = styled(Dropdown.Menu)`
  width: 100%;
  padding: 0;
  max-height: 20vh;
  overflow: auto;

  a:hover {
    background-color: #f0f0f0;
    color: black;
  }
`;

export const MenuItem = styled(Dropdown.Item)`
  font-size: ${({ theme: { fontSize } }) => fontSize.sm};
  text-align: center;
  padding: 5px;
  margin: 2px 0;
  position: relative;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const DropdownToggle = styled(Dropdown.Toggle)`
  width: 100%;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: ${({ theme: { fontSize } }) => fontSize.sm};

  &::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: '';
    position: absolute;
    right: 0.5em;
    top: 50% !important;
    transform: translateY(-50%);
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
  }
`;

export const DefaultToggle = styled(DropdownToggle)`
  display: block;
  width: 100%;
  padding-right: 2em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
`;
