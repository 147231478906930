/* eslint-disable @typescript-eslint/indent */
import { TableColumn } from 'modules/core/components/Table/types';

import { ALL_MODELS_OPTION } from '../components/VehicleFilters/options.const';
import { IVehicleModel } from '../components/VehicleFilters/types';
import { VEHICLE_TABLE_COLUMN_ID } from '../components/VehicleTable/defaultVehicleTableColumns';
import { TableName, TableView } from '../enums';
import { PREFERENCES_OPTION_VALUES } from '../shared';
import { ILenderVehicle, IRACVehicle, IVehicle, IOption } from '../types';
import { COMMA_SYMBOL, VENDORS } from '../constants';
import { convertToNonFalsyValues, convertToSnakeCase } from './converters';
import { FilterView } from '../enums/filterView.enum';

type TransformCallback<V> = (values: V) => Record<string, unknown>;

type TSortVehicles = (vehicles: IVehicle[]) => IVehicle[];

type TSortRACVehicles = (vehicles: IRACVehicle[]) => IRACVehicle[];

type TSortLenderVehicles = (vehicles: ILenderVehicle[]) => ILenderVehicle[];

export const getSubmitVehicleObjectQuery = <V>(
  formValues: V,
  transformValuesCallback: TransformCallback<V>,
  transformMapper: Record<string, string>,
): Record<string, unknown> =>
  convertToNonFalsyValues(convertToSnakeCase(transformValuesCallback(formValues), transformMapper));

export const getCurrentVehicleModelsFilterOptions = (makes: string, vehicleModels: IVehicleModel[]): IOption[] => {
  const makesArr = makes?.split(COMMA_SYMBOL).map((param: string) => param.trim());

  const currentModels = vehicleModels
    .filter((item) => makesArr.includes(Object.keys(item)[0]))
    .reduce((acc: IOption[], item) => {
      Object.values(item)[0].forEach((model: Record<string, any>) =>
        acc.push({ title: model.name, value: model.name }),
      );

      return acc;
    }, []);

  return [ALL_MODELS_OPTION, ...currentModels];
};

export const prepareVehicleColumns = (tableColumns: TableColumn[], visibleColumns: string[]): TableColumn[] =>
  tableColumns.map((column) => {
    const visibilityColumn = visibleColumns.indexOf(column.id) !== -1;

    return { ...column, isVisible: visibilityColumn };
  });

export const setVendorColumns = (columns: TableColumn[], vendorName: string, tableName: TableName): TableColumn[] => {
  if (tableName === TableName.PE || tableName === TableName.ALI) {
    return columns.filter(
      (column) =>
        (column.id !== VEHICLE_TABLE_COLUMN_ID.VENDOR_TRADE_IN &&
          column.id !== VEHICLE_TABLE_COLUMN_ID.VENDOR_RETAIL) ||
        (column.id !== VEHICLE_TABLE_COLUMN_ID.KBB_LENDING_VALUE &&
          column.id !== VEHICLE_TABLE_COLUMN_ID.KBB_TYPICAL_LISTING),
    );
  }

  if (vendorName === VENDORS.KBB) {
    return columns.filter(
      (column) =>
        column.id !== VEHICLE_TABLE_COLUMN_ID.VENDOR_TRADE_IN && column.id !== VEHICLE_TABLE_COLUMN_ID.VENDOR_RETAIL,
    );
  }

  if (vendorName === VENDORS.JDP) {
    return columns.filter(
      (column) =>
        column.id !== VEHICLE_TABLE_COLUMN_ID.KBB_LENDING_VALUE &&
        column.id !== VEHICLE_TABLE_COLUMN_ID.KBB_TYPICAL_LISTING,
    );
  }

  return columns;
};

/* eslint-disable no-param-reassign */
export const setDefaultColumnsBySettingsBookValue = (vendorField: string, columns: TableColumn[]): TableColumn[] =>
  columns.map((col) => {
    Object.values(PREFERENCES_OPTION_VALUES.VENDOR_FILTER).forEach((id) => {
      const formattedColId = col.id;

      if (id === formattedColId && formattedColId === vendorField) col.isVisible = true;

      if (id === formattedColId && formattedColId !== vendorField) col.isVisible = false;
    });

    return col;
  });

export const sortVehiclesForSalespersonView: TSortVehicles = (vehicles) => {
  if (vehicles.length === 0 || !vehicles.length) return [];

  return vehicles.slice().sort((a: IVehicle, b: IVehicle): number => {
    if (a.additional_down_payment === b.additional_down_payment && (a as IVehicle).dealer_profit) {
      return (b as IVehicle).dealer_profit - (a as IVehicle).dealer_profit;
    }

    return (a.additional_down_payment || 0) - (b.additional_down_payment || 0);
  });
};

export const sortVehiclesForManagerView: TSortVehicles = (vehicles) => {
  if (vehicles.length === 0 || !vehicles.length) return [];

  return (vehicles as IVehicle[]).slice().sort((a, b): number => {
    if (a.loan_status_value === b.loan_status_value && a.dealer_profit) {
      return b.dealer_profit - a.dealer_profit;
    }

    return a.loan_status_value - b.loan_status_value;
  });
};

export const sortRACVehiclesForSalespersonView: TSortRACVehicles = (vehicles) => {
  if (vehicles.length === 0 || !vehicles.length) return [];

  return (vehicles as IRACVehicle[]).slice().sort((a, b): number => {
    if (a.ltv_value.extra_down_payment === b.ltv_value.extra_down_payment) {
      if (a.ltv_value.ltv === b.ltv_value.ltv) {
        return a.ltv_value.payments - b.ltv_value.payments;
      }

      return a.ltv_value.ltv - b.ltv_value.ltv;
    }

    return a.ltv_value.extra_down_payment - b.ltv_value.extra_down_payment;
  });
};

export const sortRACVehiclesForManagerView: TSortRACVehicles = (vehicles) => {
  if (vehicles.length === 0 || !vehicles.length) return [];

  return (vehicles as IRACVehicle[]).slice().sort((a, b): number => {
    if (a.ltv_value.ltv === b.ltv_value.ltv) {
      if (a.ltv_value.profit === b.ltv_value.profit) {
        return a.ltv_value.payments - b.ltv_value.payments;
      }

      return (b.ltv_value.profit || 0) - (a.ltv_value.profit || 0);
    }

    return a.ltv_value.ltv - b.ltv_value.ltv;
  });
};

export const sortLenderVehiclesForSalesPerson: TSortLenderVehicles = (vehicles) => {
  if (vehicles.length === 0 || !vehicles.length) return [];

  return (vehicles as ILenderVehicle[]).slice().sort((a, b): number => {
    if (a.salesperson_bucket === b.salesperson_bucket) {
      if (a.ltv_ratio === b.ltv_ratio) {
        return b.profit - a.profit;
      }

      return a.ltv_ratio - b.ltv_ratio;
    }

    return a.salesperson_bucket - b.salesperson_bucket;
  });
};

export const sortLenderVehiclesForManager: TSortLenderVehicles = (vehicles) => {
  if (vehicles.length === 0 || !vehicles.length) return [];

  return (vehicles as ILenderVehicle[]).slice().sort((a, b): number => {
    if (a.monthly_payment === b.monthly_payment) {
      if (a.ltv_ratio === b.ltv_ratio) {
        return b.profit - a.profit;
      }

      return a.ltv_ratio - b.ltv_ratio;
    }

    return a.monthly_payment - b.monthly_payment;
  });
};

export const getTotalVehicles = (
  filterView: FilterView,
  isValidStock: boolean,
  vehicles: IVehicle[] | IRACVehicle[] | ILenderVehicle[] | undefined,
  totalCount: number | undefined = undefined,
): number | undefined =>
  Array.isArray(vehicles) && (filterView === FilterView.BY_PARAMS ? true : isValidStock)
    ? filterView === FilterView.BY_PARAMS && totalCount
      ? totalCount
      : vehicles?.length
    : undefined;

const SORT_VEHICLE_MAP_BY_VIEW = new Map<TableView, TSortVehicles>([
  [TableView.MANAGER, sortVehiclesForManagerView],
  [TableView.SALESPERSON, sortVehiclesForSalespersonView],
]);

const RAC_SORT_VEHICLE_MAP_BY_VIEW = new Map<TableView, TSortRACVehicles>([
  [TableView.MANAGER, sortRACVehiclesForManagerView],
  [TableView.SALESPERSON, sortRACVehiclesForSalespersonView],
]);

const LENDER_SORT_VEHICLE_MAP_BY_VIEW = new Map<TableView, TSortLenderVehicles>([
  [TableView.MANAGER, sortLenderVehiclesForManager],
  [TableView.SALESPERSON, sortLenderVehiclesForSalesPerson],
]);

export const SORT_VEHICLE_MAP_BY_TABLE = new Map<
  TableName,
  Map<TableView, TSortVehicles | TSortRACVehicles | TSortLenderVehicles>
>([
  [TableName.RAC, RAC_SORT_VEHICLE_MAP_BY_VIEW],
  [TableName.PE, SORT_VEHICLE_MAP_BY_VIEW],
  [TableName.ALI, SORT_VEHICLE_MAP_BY_VIEW],
  [TableName.LENDER, LENDER_SORT_VEHICLE_MAP_BY_VIEW],
]);
