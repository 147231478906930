import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'store/store';
import { getAuthState } from 'store/selectors/auth.selector';
import { getFeaturesListThunk } from 'actions/featuresListActions';
import { getLendersListThunk } from 'actions/lenderAction';

import { useCheckAuthUser } from './hooks';

interface IRequestComponentWrapper {
  children: React.ReactNode;
}

export const RequestComponentWrapper: FC<IRequestComponentWrapper> = ({ children }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { isAuth } = useSelector(getAuthState);

  useEffect(() => {
    if (isAuth) {
      dispatch(getFeaturesListThunk());
      dispatch(getLendersListThunk());
    }
  }, [isAuth, dispatch]);

  useCheckAuthUser();

  return <>{children}</>;
};
