export const LEARNING_PATHS = {
  start: 'start',
  keyFactors: 'key-factors',
  creditHistory: 'credit-history',
  affordability: 'affordability',
  loanToValue: 'loan-to-value',
  lengthOfLoan: 'length-of-loan',
  comparison: 'comparison',
  final: 'final',
};

export const loanValueFields = [
  {
    name: 'components.learningLink.loanToValue.table.vehicleCost',
    sign: '',
    value: '15,000$',
  },
  {
    name: 'components.learningLink.loanToValue.table.taxesFees',
    sign: '+',
    value: '1,000$',
  },
  {
    name: 'components.learningLink.loanToValue.table.downPayment',
    sign: '=',
    value: '1,000$',
    withLine: true,
  },
  {
    name: 'components.learningLink.loanToValue.table.loanAmount',
    sign: '=',
    value: '15,000$',
  },
  {
    name: 'components.learningLink.loanToValue.table.assignedBankValue',
    sign: '/',
    value: '13,500$',
    withLine: true,
  },
  {
    name: 'components.learningLink.loanToValue.table.loanValueRatio',
    sign: '=',
    value: '111%',
  },
];
