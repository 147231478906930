import { MODAL_DIALOG_ID } from 'modules/core/constants/modalDialog.const';
import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { ModalDialog } from 'components/ModalDialog';
import { PanelWithFields } from 'modules/core/components';
import { useModalState } from 'modules/core/hooks/useModalState';
import { Col, Row } from 'react-bootstrap';
import { EMPTY_STRING } from 'modules/core/constants';
import { ColumnWidth, TableName } from 'modules/core/enums';
import { FEES_AND_TAXES_FIELDS_MAP_BY_TABLE } from 'modules/core/shared/feesAndTaxes.config';
import { PE_PREFERENCES_FIELDS } from 'modules/core/shared/preferencesConfig.const';

import { useVendorFieldAndOptions } from '../../../hooks/useVendorFieldAndOptions';
import { IProbabilityEstimatorForm } from '../types';

interface ISettingsModal {
  tableName: TableName;
  settingSubmitCallback: VoidFunction;
}

export const SettingsModal: FC<ISettingsModal> = ({ tableName, settingSubmitCallback }) => {
  const { t } = useTranslation();
  const { getValues, reset } = useFormContext<IProbabilityEstimatorForm>();

  const { VENDOR_FIELD } = useVendorFieldAndOptions();
  const preferencesWithVendor = [...PE_PREFERENCES_FIELDS.slice(0, 1), VENDOR_FIELD, ...PE_PREFERENCES_FIELDS.slice(2)];
  const preferencesWithoutVendor = [...PE_PREFERENCES_FIELDS.slice(0, 1), ...PE_PREFERENCES_FIELDS.slice(2)];
  const preferencesFields = tableName === TableName.PE ? preferencesWithVendor : preferencesWithoutVendor;
  const taxesFeesFields = FEES_AND_TAXES_FIELDS_MAP_BY_TABLE.get(tableName) || [];

  const { isModalDialogActive, handleCloseDialog } = useModalState<IProbabilityEstimatorForm>(
    [MODAL_DIALOG_ID.PREFERENCES],
    getValues(),
    reset,
  );

  const inputData = getValues();
  const areRequiredFieldsFilledIn = Boolean(
    !Number.isNaN(inputData.feesAndTaxes.salesTaxRate) && !Number.isNaN(inputData.preferences.ltvHighLimit),
  );

  const onSubmit = useCallback(() => {
    if (areRequiredFieldsFilledIn) {
      handleCloseDialog()();

      settingSubmitCallback();
    }
  }, [areRequiredFieldsFilledIn, settingSubmitCallback, handleCloseDialog]);

  return (
    <ModalDialog
      title={EMPTY_STRING}
      submitButtonTitle={t('common.buttons.save')}
      cancelButtonTitle={t('common.buttons.cancel')}
      show={isModalDialogActive}
      onHide={handleCloseDialog(true)}
      onSubmitModal={onSubmit}
      isDisabled={!areRequiredFieldsFilledIn}
    >
      <Row>
        <Col xs={ColumnWidth.FULL} sm={ColumnWidth.M} className="mb-3">
          {preferencesFields && (
            <PanelWithFields title={t('components.preferences.title')} fieldsConfig={preferencesFields} />
          )}
        </Col>
        <Col xs={ColumnWidth.FULL} sm={ColumnWidth.M}>
          <PanelWithFields title={t('components.feesAndTaxes.title')} fieldsConfig={taxesFeesFields} />
        </Col>
      </Row>
    </ModalDialog>
  );
};
