import React from 'react';
import { Trans } from 'react-i18next';

import { CardContainer, Img, ImgContainer, Title, Price, PriceDescription, PriceContainer } from './styles';

interface ICarouselCard {
  imgSrc: string;
  title: string;
  price: string;
  mileage: string;
  description: string;
  priceDescription: string;
}

export const CarouselCard: React.FC<ICarouselCard> = ({
  imgSrc,
  title,
  price,
  mileage,
  description,
  priceDescription,
}) => (
  <CardContainer>
    <ImgContainer>
      <Img src={imgSrc} />
    </ImgContainer>
    <Title>{title}</Title>
    <PriceContainer>
      <Price>{price}</Price>
      <PriceDescription>{priceDescription}</PriceDescription>
    </PriceContainer>
    <p>{mileage}</p>
    <Trans defaults={description} components={[<strong />]} />
  </CardContainer>
);
