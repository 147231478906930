export enum ColumnWidth {
  XXXXS = 1,
  XXXS = 2,
  XXS = 3,
  XS = 4,
  S = 5,
  M = 6,
  L = 7,
  XL = 8,
  XXL = 9,
  XXXL = 10,
  FULL = 12,
}
