import styled from 'styled-components';
import { Col } from 'react-bootstrap';
import { AdjustMenuItemTitle, AdjustMenuItem } from 'modules/core/components/Table/AdjustDropdown/styles';
import { COLORS } from 'modules/core/theme/colors';
import { WindowBreakpoints } from 'modules/core/enums/windowBreakpoints.enum';

export const AdaptedCol = styled(Col)`
  margin-bottom: 4px;
  padding-right: 4px;

  @media (max-width: ${WindowBreakpoints.medium}px) {
    .pr-0 {
      padding-left: 30px;
      margin-top: 15px;
    }
  }

  @media (max-width: ${WindowBreakpoints.large}px) {
    .rac-padding {
      padding-left: 30px;
      margin-top: 15px;
    }
  }
`;

export const TableDropdowns = styled.div`
  display: flex;

  .dropdown {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }

  ${AdjustMenuItem} {
    justify-content: center;
    padding: 10px 5px;

    &:hover {
      background-color: ${COLORS.BLUE_1};
    }
  }

  ${AdjustMenuItemTitle} {
    margin: 0;
  }
`;
