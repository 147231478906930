export const getValidate = (
  dependentFieldValue: string,
  dependentFieldValuePattern: RegExp,
  valuePattern: RegExp,
  errorMessage: string,
) => (value: string): string | undefined => {
  let isValid = valuePattern.test(value);

  if (!value && dependentFieldValue) isValid = true;

  return isValid ? undefined : errorMessage;
};
