import { publicUrl } from 'modules/core/utils/routing';

export const STATIC_IMAGE_URLS = {
  logo: publicUrl('/static/images/logo-image.png'),
  logoWhite: publicUrl('/static/images/streamline_logo_white.png'),
  favicon: publicUrl('/static/images/favicon.ico'),
  loginBackground: publicUrl('/static/images/login-background.jpg'),
  learningBackground: publicUrl('/static/images/learning-background.jpg'),
  racLogo: publicUrl('/static/images/rac-logo.png'),
  icons: {
    eye: publicUrl('/static/images/eye.png'),
    closedEye: publicUrl('/static/images/closed-eye.png'),
    mail: publicUrl('/static/images/mail-image.png'),
    questionMark: publicUrl('/static/images/question-mark.png'),
    congrats: publicUrl('/static/images/congrats-image.png'),
    calculator: publicUrl('/static/images/calculator-icon.png'),
    car: publicUrl('/static/images/car.png'),
    noPhoto: publicUrl('/static/images/noPhoto.png'),
    dashboard: publicUrl('/static/images/dashboard-icon.png'),
    house: publicUrl('/static/images/house.png'),
    message: publicUrl('/static/images/message.png'),
    play: publicUrl('/static/images/play-icon.svg'),
    print: publicUrl('/static/images/print.svg'),
    user: publicUrl('/static/images/user-icon.svg'),
    info: publicUrl('/static/images/info.svg'),
    infoOrange: publicUrl('/static/images/info-orange.svg'),
    success: publicUrl('/static/images/success.svg'),
    table: publicUrl('/static/images/table.svg'),
    cards: publicUrl('/static/images/cards.svg'),
    trash: publicUrl('/static/images/trash.svg'),
    calendar: publicUrl('/static/images/calendar.svg'),
    filter: publicUrl('/static/images/filter.svg'),
    highlightedFilter: publicUrl('/static/images/highlightedFilter.svg'),
    backArrow: publicUrl('/static/images/back-arrow.png'),
    downArrow: publicUrl('/static/images/down-arrow.png'),
    arrowIcon: publicUrl('/static/images/arrowIcon.svg'),
    undoIcon: publicUrl('/static/images/undo.svg'),
    whiteUndo: publicUrl('/static/images/whiteUndo.png'),
    fillCircle: publicUrl('/static/images/fillCircle.svg'),
    blackCar: publicUrl('/static/images/blackCar.png'),
    whiteCar: publicUrl('/static/images/whiteCar.png'),
    lockWhiteBackground: publicUrl('/static/images/lockWhiteBackground.svg'),
    unlockWhiteBackground: publicUrl('/static/images/unlockWhiteBackground.svg'),
  },
  sideBar: {
    probabilityEstimator: publicUrl('/static/images/probability-estimator.svg'),
    approvedLoanInput: publicUrl('/static/images/approved-loan-input.svg'),
    creditInterview: publicUrl('/static/images/interview.svg'),
    adminPanel: publicUrl('/static/images/admin-panel.svg'),
    rac: publicUrl('/static/images/rac.svg'),
  },
  cars: {
    chevrolet: publicUrl('/static/images/chevy-traverse.png'),
    bmw: publicUrl('/static/images/bmw-z4.png'),
    car1: publicUrl('/static/images/car1.jpg'),
    car2: publicUrl('/static/images/car2.jpg'),
    car3: publicUrl('/static/images/car3.jpg'),
    car4: publicUrl('/static/images/car4.jpg'),
    car5: publicUrl('/static/images/car5.jpg'),
    car6: publicUrl('/static/images/car6.jpg'),
  },
};
