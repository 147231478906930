import { Card } from 'react-bootstrap';
import styled from 'styled-components';
import { titlePanelMixin } from 'modules/core/theme/mixins';
import { WindowBreakpoints } from 'modules/core/enums/windowBreakpoints.enum';

const { Title: TitleUI } = Card;

export const Title = styled(TitleUI)`
  ${titlePanelMixin};
  padding: 12px 15px;
  height: 54px;
  font-size: ${({ theme: { fontSize } }) => fontSize.sm};
  margin-bottom: 0;
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;

  & span {
    margin-right: 10px;
    margin-bottom: 5px;
  }

  @media (max-width: ${WindowBreakpoints.small}px) {
    font-size: ${({ theme: { fontSize } }) => fontSize.normal};
  }
`;
