import React, { FC, ReactNode, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IField } from 'modules/core/types/common';
import { MODAL_DIALOG_ID } from 'modules/core/constants';
import { useIncomeReportButton, useModalState } from 'modules/core/hooks';
import { Button, CalculatorAdornment, FormRow, Panel } from 'modules/core/components';
import { ButtonVariant, ColumnWidth } from 'modules/core/enums';
import { ButtonPrint } from 'modules/ApprovedLoanInput/styles';
import { ResetModal } from 'modules/ApprovedLoanInput/Summary/ResetModal';
import { IncomeCalculator } from 'components/IncomeCalculator';
import { IIncomeForm } from 'components/IncomeCalculator/types';
import { CATEGORIES, FORM_FIELD_NAMES, TYPES } from 'components/IncomeCalculator/const';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import { LendersMonthlyIncomeModal } from 'modules/RACAffordabilityEngine/RacMontlyIncomeModal/LendersMonthlyIncomeModal';
import { FEES_AND_TAXES_FIELD_NAMES } from 'modules/core/shared';
import { saveRateSheetsDataThunk } from 'actions/vehicleActions';
import { getRouteOneData } from 'store/selectors/RACAffordabilityEngine.selector';
import { getUser } from 'store/selectors/auth.selector';
import { getIsModalsDisabled } from 'store/selectors/ui.selector';
import { IUser } from 'modules/core/types';
import { ESTIMATED_FEES_OVERRIDDEN } from 'modules/RACAffordabilityEngine/types/RACAffordabilityEngineForm';

import { PROBABILITY_ESTIMATOR_FIELDS } from '../probabilityEstimator.const';
import { MonthlyPaymentSlider } from '../MonthlyPaymentSlider';
import { NetDownPaymentModal } from './NetDownPaymentModal';
import { ConsumerInformationWrapper, HeaderButtonsGroup } from './styles';
import { PageName, useConsumerInformationData } from './hooks/useConsumerInformationData';
import { FrontAndBackEndsAddsModal } from '../../../components/Modals/FrontAndBackEndsAddsModal';
import { APPROVED_LOAN_TERMS_FIELD_NAMES } from '../../RACAffordabilityEngine/const/fieldNames.const';

interface IConsumerInformation {
  title: string;
  firstConsumerFields: IField[];
  secondConsumerFields?: IField[];
  isFetchingVehicles: boolean;
  pageName: PageName;
  totalVehicles?: number;
  submitCallback: VoidFunction;
  ltvTaxesFeesFilter?: string;
  areRequiredFieldsFilledIn: boolean;
  isRac?: boolean;
  isLender?: boolean;
}

export const ConsumerInformation: FC<IConsumerInformation> = ({
  title,
  isFetchingVehicles,
  pageName,
  totalVehicles,
  submitCallback,
  ltvTaxesFeesFilter,
  firstConsumerFields,
  secondConsumerFields = [],
  areRequiredFieldsFilledIn,
  isRac = false,
  isLender = false,
}) => {
  const {
    getIncome,
    incomeCalculatorData,
    setIncome,
    resetVehicleFiltersDirtyFields,
    onResetState,
    initialValues,
  } = useConsumerInformationData(pageName);

  const { t } = useTranslation();
  const { onButtonPrintClick } = useIncomeReportButton(incomeCalculatorData);
  const { isSmallWidth } = useWindowDimensions();
  const dispatch = useDispatch();
  const routeOneData = useSelector(getRouteOneData);
  const isResetModalDisabled = useSelector(getIsModalsDisabled);
  const user = useSelector(getUser) as IUser;

  const {
    watch,
    formState: { isValid, errors },
    setValue,
    getValues,
  } = useFormContext();

  const isFormValid = Object.keys(errors).length === 0 && !isValid ? true : isValid;

  const values = getValues();
  const {
    isModalDialogActive: isModalNetDownPaymentDialogActive,
    handleOpenDialog: handleNetDownPaymentDialogOpen,
  } = useModalState([MODAL_DIALOG_ID.NET_DOWN_PAYMENT]);

  const {
    isModalDialogActive: isFrontAndBackEndsAddsDialogActive,
    handleOpenDialog: handleFrontAndBackEndsAddsDialogOpen,
  } = useModalState([MODAL_DIALOG_ID.FRONT_AND_BACK_ENDS_ADDS]);

  const {
    isModalDialogActive: isResetFormDialogActive,
    handleOpenDialog: handleResetFormOpenDialog,
    handleCloseDialog: handleResetFormCloseDialog,
  } = useModalState([MODAL_DIALOG_ID.RESET_FORM]);

  const {
    isModalDialogActive: isIncomeDialogActive,
    handleOpenDialog: handleIncomeDialogOpen,
    handleCloseDialog: handleIncomeDialogClose,
  } = useModalState([MODAL_DIALOG_ID.INCOME_CALCULATOR]);

  const onIncomeCalculatorSubmit = useCallback(
    (incomeForm: IIncomeForm) => {
      setValue(PROBABILITY_ESTIMATOR_FIELDS.GROSS_MONTHLY_INCOME, incomeForm.totalIncome);

      dispatch(setIncome(incomeForm));
    },
    [dispatch, setValue, setIncome],
  );

  const mapModalByFieldName = useMemo(
    () =>
      new Map<string, ReactNode>([
        [PROBABILITY_ESTIMATOR_FIELDS.NET_DOWN_PAYMENT, <CalculatorAdornment dataTestId="net-down-payment-button" />],
        [
          PROBABILITY_ESTIMATOR_FIELDS.GROSS_MONTHLY_INCOME,
          <CalculatorAdornment dataTestId="income-calculator-button" />,
        ],
        [
          APPROVED_LOAN_TERMS_FIELD_NAMES.FRONT_AND_BACK_ENDS_ADDS,
          <CalculatorAdornment dataTestId="fron-and-back-ends-adds-button" />,
        ],
      ]),
    [],
  );

  const mapAdornmentOnClickByFieldName = useMemo(
    () =>
      new Map<string, VoidFunction>([
        [PROBABILITY_ESTIMATOR_FIELDS.NET_DOWN_PAYMENT, handleNetDownPaymentDialogOpen],
        [PROBABILITY_ESTIMATOR_FIELDS.GROSS_MONTHLY_INCOME, handleIncomeDialogOpen],
        [APPROVED_LOAN_TERMS_FIELD_NAMES.FRONT_AND_BACK_ENDS_ADDS, handleFrontAndBackEndsAddsDialogOpen],
      ]),
    [handleNetDownPaymentDialogOpen, handleIncomeDialogOpen, handleFrontAndBackEndsAddsDialogOpen],
  );

  const mapOnClickFunctionByFieldName = useMemo(
    () =>
      new Map<string, VoidFunction>([
        [PROBABILITY_ESTIMATOR_FIELDS.NET_DOWN_PAYMENT, handleNetDownPaymentDialogOpen],
        [APPROVED_LOAN_TERMS_FIELD_NAMES.FRONT_AND_BACK_ENDS_ADDS, handleFrontAndBackEndsAddsDialogOpen],
      ]),
    [handleNetDownPaymentDialogOpen, handleFrontAndBackEndsAddsDialogOpen],
  );

  const mapOnClickFunctionByFieldNameForRacPage = useMemo(
    () =>
      new Map<string, VoidFunction>([
        [PROBABILITY_ESTIMATOR_FIELDS.NET_DOWN_PAYMENT, handleNetDownPaymentDialogOpen],
        [APPROVED_LOAN_TERMS_FIELD_NAMES.FRONT_AND_BACK_ENDS_ADDS, handleFrontAndBackEndsAddsDialogOpen],
        [PROBABILITY_ESTIMATOR_FIELDS.GROSS_MONTHLY_INCOME, handleIncomeDialogOpen],
      ]),
    [handleNetDownPaymentDialogOpen, handleFrontAndBackEndsAddsDialogOpen, handleIncomeDialogOpen],
  );

  const { grossMonthlyIncome, creditScore, tier } = watch();

  const getTierOrCreditScoreByPageName = useMemo(
    () =>
      new Map<string, any>([
        [PageName.PROBABILITY, creditScore],
        [PageName.LENDER, tier],
        [PageName.RAC, tier],
      ]),
    [creditScore, tier],
  );

  const monthlyPaymentSliderData = getTierOrCreditScoreByPageName.get(pageName);

  const handleChange = useCallback(() => {
    const { grossMonthlyIncome: currentGrossMonthlyIncome } = watch();

    const otherCardData = {
      category: CATEGORIES.OTHER,
      type: TYPES.NON_CALCULATED,
      amountReceived: currentGrossMonthlyIncome,
    };

    setValue(FORM_FIELD_NAMES.TOTAL_INCOME, currentGrossMonthlyIncome);
    setValue(FORM_FIELD_NAMES.SAVED_INCOMES, [otherCardData]);

    dispatch(setIncome({ savedIncomes: [otherCardData], totalIncome: Number(currentGrossMonthlyIncome) }));
  }, [dispatch, setValue, watch, setIncome]);

  const salesTaxRateHandleChange = useCallback(
    (event) => {
      const value = String(event.target.value);

      if (user?.is_sso && !user?.isWithInventory && isRac && value.charAt(value.length - 1) !== '.') {
        const values = getValues();

        const params = {
          ratesheet: values.rateSheetSettingsModal.rateSheet,
          region: values.rateSheetSettingsModal.region,
          tax_rate: values.feesAndTaxes.salesTaxRate,
        };

        dispatch(saveRateSheetsDataThunk(params));
      }
    },
    [user?.is_sso, user?.isWithInventory, isRac, getValues, dispatch],
  );

  const estimatedFeesHandleChange = useCallback(() => {
    if (isRac && user?.is_sso) {
      const values = getValues();

      if (Object.keys(routeOneData || {}).length > 0) {
        const initialEstimatedFees =
          (routeOneData.taxes_fees.sales_fees || 0) + (routeOneData.taxes_fees.title_reg_license || 0);

        if (initialEstimatedFees !== values.feesAndTaxes.estimatedFees) {
          setValue(ESTIMATED_FEES_OVERRIDDEN, true);
        }
      }
    }
  }, [getValues, isRac, routeOneData, setValue, user?.is_sso]);

  const mapOnChangeCallbackByFieldName = useMemo(
    () =>
      new Map<string, VoidFunction>([
        [PROBABILITY_ESTIMATOR_FIELDS.GROSS_MONTHLY_INCOME, handleChange],
        [FEES_AND_TAXES_FIELD_NAMES.SALES_TAX_RATE, salesTaxRateHandleChange as VoidFunction],
        [FEES_AND_TAXES_FIELD_NAMES.ESTIMATED_FEES, estimatedFeesHandleChange],
      ]),
    [estimatedFeesHandleChange, handleChange, salesTaxRateHandleChange],
  );

  const generateFields = useCallback(
    (fields: IField[]) =>
      fields.map(({ name, component: Component, controlProps, label, isVerticalAlign }: IField) => {
        const getRACAndLenderClickCallback = (name: string) => {
          if (isRac) {
            if (user.is_sso && isResetModalDisabled) {
              return undefined;
            }

            return mapOnClickFunctionByFieldNameForRacPage.get(name);
          }

          return mapOnClickFunctionByFieldNameForRacPage.get(name);
        };

        const changeCallback = mapOnChangeCallbackByFieldName.get(name);
        const adornment = mapModalByFieldName.get(name);
        const adornmentOnClick =
          isRac && user.is_sso && isResetModalDisabled ? undefined : mapAdornmentOnClickByFieldName.get(name);

        const setAdornment = (fieldName: string) => {
          if (!isSmallWidth || (isSmallWidth && fieldName !== PROBABILITY_ESTIMATOR_FIELDS.GROSS_MONTHLY_INCOME))
            return adornment;

          return undefined;
        };

        const fieldWidth = controlProps?.fieldWidth ? { fieldWidth: controlProps.fieldWidth as ColumnWidth } : {};
        const clickCallback =
          isRac || isLender ? getRACAndLenderClickCallback(name) : mapOnClickFunctionByFieldName.get(name);

        return (
          <FormRow
            {...fieldWidth}
            key={name}
            required={!!controlProps?.required}
            label={label}
            isVerticalAlign={isVerticalAlign}
            name={isRac || isLender ? name : undefined}
            control={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <Component
                {...controlProps}
                name={name}
                endAdornment={setAdornment(name)}
                endAdornmentOnClick={adornmentOnClick}
                changeCallback={changeCallback}
                testId={name}
                clickCallback={clickCallback}
              />
            }
          />
        );
      }),
    [
      user,
      isResetModalDisabled,
      mapOnChangeCallbackByFieldName,
      mapModalByFieldName,
      mapAdornmentOnClickByFieldName,
      isRac,
      isLender,
      mapOnClickFunctionByFieldName,
      mapOnClickFunctionByFieldNameForRacPage,
      isSmallWidth,
    ],
  );

  const firstFields = useMemo(() => generateFields(firstConsumerFields), [generateFields, firstConsumerFields]);

  const secondFields = useMemo(() => {
    if (secondConsumerFields.length > 0) return generateFields(secondConsumerFields);
    else return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [secondConsumerFields.length, generateFields]);

  const headerComponent = useMemo(
    () => (
      <HeaderButtonsGroup isRac={isRac || isLender}>
        {!isRac && !isLender && (
          <ButtonPrint data-testid="print-income" onClick={onButtonPrintClick} disabled={!grossMonthlyIncome} />
        )}
        <Button
          title={t('components.approvedLoanInput.approvedLoanTerms.buttons.reset')}
          onClick={handleResetFormOpenDialog}
          buttonTheme={ButtonVariant.OUTLINE}
          disabled={isRac && user.is_sso && isResetModalDisabled}
        />
        <ResetModal
          show={isResetFormDialogActive}
          onHide={handleResetFormCloseDialog()}
          initialValues={initialValues}
          resetStateCallback={onResetState}
          resetVehicleFiltersDirtyFields={resetVehicleFiltersDirtyFields}
          ltvTaxesFeesFilter={ltvTaxesFeesFilter}
        />
      </HeaderButtonsGroup>
    ),
    [
      user,
      isResetModalDisabled,
      isRac,
      isLender,
      onButtonPrintClick,
      grossMonthlyIncome,
      t,
      handleResetFormOpenDialog,
      isResetFormDialogActive,
      handleResetFormCloseDialog,
      initialValues,
      onResetState,
      resetVehicleFiltersDirtyFields,
      ltvTaxesFeesFilter,
    ],
  );

  return (
    <ConsumerInformationWrapper>
      <Panel title={title} headerComponent={headerComponent}>
        <Row>
          <Col
            xs={ColumnWidth.FULL}
            md={secondFields.length > 0 ? ColumnWidth.M : ColumnWidth.XS}
            lg={secondFields.length > 0 ? ColumnWidth.XXXS : ColumnWidth.XS}
            className="d-flex flex-column justify-content-between"
          >
            {firstFields}
          </Col>
          {secondFields.length > 0 && (
            <Col
              xs={ColumnWidth.FULL}
              md={ColumnWidth.M}
              lg={secondFields.length > 0 ? ColumnWidth.XXXS : ColumnWidth.XXS}
              className="d-flex flex-column justify-content-between"
            >
              {secondFields}
            </Col>
          )}
          <Col
            xs={ColumnWidth.FULL}
            md={secondFields.length > 0 ? ColumnWidth.FULL : ColumnWidth.XL}
            lg={ColumnWidth.XL}
            className={`${secondFields.length > 0 ? 'rac-padding' : ''} pr-0 d-flex flex-column justify-content-center`}
          >
            <MonthlyPaymentSlider
              name={PROBABILITY_ESTIMATOR_FIELDS.DESIRED_MONTHLY_PAYMENT}
              creditScore={monthlyPaymentSliderData}
              income={grossMonthlyIncome as number}
              totalVehicles={totalVehicles}
              disabled={!isFormValid || !areRequiredFieldsFilledIn || isFetchingVehicles}
              isFetchingVehicles={isFetchingVehicles}
            />
          </Col>
          {isModalNetDownPaymentDialogActive && (
            <NetDownPaymentModal
              submitCallback={submitCallback}
              isLender={isRac || isLender}
              fieldWidth={ColumnWidth.M}
            />
          )}
          {isFrontAndBackEndsAddsDialogActive && (
            <FrontAndBackEndsAddsModal isLender={isLender} submitCallback={submitCallback} />
          )}
          {isRac || isLender ? (
            <LendersMonthlyIncomeModal
              show={isIncomeDialogActive}
              onHide={handleIncomeDialogClose()}
              initialValues={values.lendersMonthlyIncomeModal}
              modalName={'lendersMonthlyIncomeModal'}
              totalName={PROBABILITY_ESTIMATOR_FIELDS.GROSS_MONTHLY_INCOME}
            />
          ) : (
            <IncomeCalculator
              show={isIncomeDialogActive}
              onHide={handleIncomeDialogClose()}
              onIncomeCalculatorSubmit={onIncomeCalculatorSubmit}
              incomesSelector={getIncome}
              isRac={isRac}
            />
          )}
        </Row>
      </Panel>
    </ConsumerInformationWrapper>
  );
};
