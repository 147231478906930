import { Endpoints } from 'modules/core/constants';
import { AxiosResponse } from 'axios';
import { Api } from 'api/api';
import { IControlNumbersPayload, IFooterData } from 'modules/core/types/common';
import { CreditScore } from 'modules/ProbabilityEstimator/types/creditScore.type';
import { IFeesAndTaxesPayload, ITier } from 'modules/core/types';
import { ISubventionYear } from 'modules/ApprovedLoanInput/types';
import { IAdjustEventMessagesPayload } from 'components/Modals/VehicleAdjustmentDialog/types';
import { IRegionControlsPayload } from 'modules/core/types/region';
import { ILenderControlsPayload } from 'modules/core/types/lender';

class ControlApi extends Api {
  getFooterData = (): Promise<AxiosResponse<IFooterData>> => this.api.get(Endpoints.getFooterData);

  getFeesAndTaxesData = (): Promise<AxiosResponse<IFeesAndTaxesPayload>> => this.api.get(Endpoints.getFeesAndTaxesData);

  getSubventionYearData = (): Promise<AxiosResponse<ISubventionYear[]>> =>
    this.api.get(Endpoints.getSubventionYearData);

  getInterestRateList = (): Promise<AxiosResponse<CreditScore[]>> => this.api.get(Endpoints.getInterestRateList);

  getAdjustEventMessages = (): Promise<AxiosResponse<IAdjustEventMessagesPayload[]>> =>
    this.api.get(Endpoints.getAdjustEventMessages);

  getControlNumbers = (): Promise<AxiosResponse<IControlNumbersPayload>> => this.api.get(Endpoints.getControlNumbers);

  getTierOptions = (): Promise<AxiosResponse<ITier[]>> => this.api.get(Endpoints.getTierOptions);

  getRegionControls = (): Promise<AxiosResponse<IRegionControlsPayload>> => this.api.get(Endpoints.getRegionControls);

  getLenderControls = (): Promise<AxiosResponse<ILenderControlsPayload>> => this.api.get(Endpoints.getLenderControls);
}

export default new ControlApi();
