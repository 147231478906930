import i18n from 'i18n';
import { FEES_AND_TAXES_INITIAL_VALUES, PREFERENCES_RAC_INITIAL_VALUES } from 'modules/core/shared';
import { TableView } from 'modules/core/enums';
import { INCOME_INITIAL_VALUES } from 'components/IncomeCalculator/const/initial.const';
import {
  FILTERS_FIELD_NAMES,
  VEHICLE_FILTERS_INITIAL_VALUES,
} from 'modules/core/components/VehicleFilters/vehicleFilters.const';
import { ITier } from 'modules/core/types';
import { LOAN_VARIABLES_FIELDS_NAMES } from 'components/Modals/LoanVariablesDialog/consts/fieldNames.const';

import { IRACAffordabilityEngineForm } from './types/RACAffordabilityEngineForm';
import { APPROVED_LOAN_TERMS_FIELD_NAMES, FEES_AND_TAXES_INDEX_FIELD } from './const/fieldNames.const';
import { FilterView } from '../core/enums/filterView.enum';
import {
  NET_DOWN_PAYMENT_VALUES,
  PROBABILITY_ESTIMATOR_FIELDS,
} from '../ProbabilityEstimator/probabilityEstimator.const';
import { CreditScoreColors } from '../ProbabilityEstimator/enums/creditScoreColors.enum';
import { LENDERS_MOTHLY_INCOME_DEFAULT_VALUES } from './RacMontlyIncomeModal/consts/defaultValues';

export const FRONT_AND_BACK_ENDS_ADDS_VALUES = {
  addOns: 0,
  vsc: 0,
  gap: 0,
};

export const INITIAL_VALUES: IRACAffordabilityEngineForm = {
  feesAndTaxes: FEES_AND_TAXES_INITIAL_VALUES,
  preferences: PREFERENCES_RAC_INITIAL_VALUES,
  vehicleFilters: VEHICLE_FILTERS_INITIAL_VALUES,
  tableView: TableView.SALESPERSON,
  filterView: FilterView.BY_PARAMS,
  totalNetDownPaymentDialog: NET_DOWN_PAYMENT_VALUES,
  totalFrontAndBackEndsAddsDialog: FRONT_AND_BACK_ENDS_ADDS_VALUES,
  stocks: [],
  grossMonthlyIncome: undefined,
  desiredMonthlyPayment: 0,
  vehicleTableAccordion: true,
  income: { totalIncome: 0, savedIncomes: INCOME_INITIAL_VALUES },
  tier: { id: 0, tier: 0, color: CreditScoreColors.DEFAULT } as ITier,
  isRequestNeeded: {
    byParams: true,
    byStock: true,
  },
  price: LOAN_VARIABLES_FIELDS_NAMES.ADJUSTED_PRICE,
  maximalPaymentRange: 0,
  currentVehicleData: undefined,
  monthlyIncome: 0,
  payment: 0,
  vendorName: '',
  condition: 'all',
  lendersMonthlyIncomeModal: LENDERS_MOTHLY_INCOME_DEFAULT_VALUES,
  rateSheetSettingsModal: {
    rateSheet: { title: '', value: '' },
    region: { title: '', value: '' },
    taxRate: NaN,
  },
};

export const APPROVED_LOAN_TERMS_NAMES_WATCH = [
  PROBABILITY_ESTIMATOR_FIELDS.GROSS_MONTHLY_INCOME,
  PROBABILITY_ESTIMATOR_FIELDS.DESIRED_MONTHLY_PAYMENT,
  APPROVED_LOAN_TERMS_FIELD_NAMES.TIER,
  APPROVED_LOAN_TERMS_FIELD_NAMES.NET_DOWN_PAYMENT,
  APPROVED_LOAN_TERMS_FIELD_NAMES.FRONT_AND_BACK_ENDS_ADDS,
  APPROVED_LOAN_TERMS_FIELD_NAMES.STOCKS,
  APPROVED_LOAN_TERMS_FIELD_NAMES.FILTER_VIEW,
  FEES_AND_TAXES_INDEX_FIELD,
  ...Object.values(FILTERS_FIELD_NAMES),
];

export const DEFAULT_PLACEHOLDER_TITLE: string = i18n.t('components.RACAffordabilityEngine.defaultPlaceholderTitle');
