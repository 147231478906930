import { createAsyncThunk } from '@reduxjs/toolkit';
import { controlApi } from 'api';
import { getVehicleBodyTypesThunk, getVehicleMakesThunk, getVehicleModelsThunk } from 'actions/vehicleActions';
import { getAllowedDealersThunk, getDealerStoresThunk } from 'actions/dealerActions';

import { ACTION_PREFIX } from './actionPrefix.const';

export const getFooterDataThunk = createAsyncThunk(`${ACTION_PREFIX.CONTROL}/footerData`, async () => {
  const { data } = await controlApi.getFooterData();

  return data;
});

export const getFeesAndTaxesDataThunk = createAsyncThunk(`${ACTION_PREFIX.CONTROL}/feesAndTaxesData`, async () => {
  const { data } = await controlApi.getFeesAndTaxesData();

  return data;
});

export const getSubventionYearDataThunk = createAsyncThunk(`${ACTION_PREFIX.CONTROL}/subventionYearData`, async () => {
  const { data } = await controlApi.getSubventionYearData();

  return data;
});

export const getControlNumbersThunk = createAsyncThunk(`${ACTION_PREFIX.CONTROL}/getControlNumbers`, async () => {
  const { data } = await controlApi.getControlNumbers();

  return data;
});

export const getInterestRateListThunk = createAsyncThunk(`${ACTION_PREFIX.CONTROL}/getInterestRateList`, async () => {
  const { data } = await controlApi.getInterestRateList();

  return data;
});

export const getVehicleFiltersThunk = createAsyncThunk(
  `${ACTION_PREFIX.CONTROL}/vehicleFilters`,
  async (_, thunkAPI) => {
    thunkAPI.dispatch(getVehicleMakesThunk());
    thunkAPI.dispatch(getVehicleModelsThunk());
    thunkAPI.dispatch(getVehicleBodyTypesThunk());
    thunkAPI.dispatch(getDealerStoresThunk());
    thunkAPI.dispatch(getAllowedDealersThunk());
    thunkAPI.dispatch(getSubventionYearDataThunk());
  },
);

export const getAdjustEventMessagesThunk = createAsyncThunk(
  `${ACTION_PREFIX.CONTROL}/adjustEventMessages`,
  async () => {
    const { data } = await controlApi.getAdjustEventMessages();

    return data;
  },
);

export const getTierOptionsThunk = createAsyncThunk(`${ACTION_PREFIX.FEATURES_LIST}/getTierOptions`, async () => {
  const { data } = await controlApi.getTierOptions();

  return data;
});

export const getRegionControlsThunk = createAsyncThunk(`${ACTION_PREFIX.FEATURES_LIST}/getRegionControls`, async () => {
  const { data } = await controlApi.getRegionControls();

  return data;
});
