import { ITier } from 'modules/Lender/types/Lender.types';

import { CreditScoreColors } from '../enums/creditScoreColors.enum';
import { DEFAULT_INCOME_RATIO } from '../probabilityEstimator.const';
import { CreditScore, CreditScoreColor } from '../types/creditScore.type';

export class ProbabilityEstimatorCalculationService {
  private static isValuesValidForPaymentColor = (ptiRatio: number, desiredMonthlyPayment: number) => (
    maxPti: number,
    maxPayment: number,
  ) => ptiRatio <= maxPti && desiredMonthlyPayment <= maxPayment;

  static getPercentPaymentToIncomeRatio(income: number, desiredMonthly: number, salesTaxRate: number): number {
    if (isNaN(salesTaxRate) || income === 0 || isNaN(income)) {
      return 0;
    }

    let paymentRatio = DEFAULT_INCOME_RATIO;

    if (income > DEFAULT_INCOME_RATIO) {
      paymentRatio = (desiredMonthly * 100) / income;
    }

    paymentRatio = paymentRatio || DEFAULT_INCOME_RATIO;

    return Math.round(paymentRatio);
  }

  static getDefaultMonthlyPayment(
    maxGreenPTI: number,
    income: number,
    maximalMonthlyPayment: number,
    maxGreenPayment: number,
    salesTaxRate: number,
  ): number {
    if (isNaN(salesTaxRate) || income === 0 || isNaN(income)) {
      return 0;
    }

    const desiredMonthly = (income * maxGreenPTI) / 100;

    return Math.round(Math.min(maxGreenPayment, desiredMonthly, maximalMonthlyPayment));
  }

  static getMaximalMonthlyPayment(maxAllowedPTI: number, maxAllowedPayment: number, income: number): number {
    const possibleMaxMonthlyPayment = (income * maxAllowedPTI) / 100;

    return Math.round(Math.min(possibleMaxMonthlyPayment, maxAllowedPayment));
  }

  static getMonthlyPaymentColor(
    ptiRatio: number,
    desiredMonthlyPayment: number,
    { max_green_payment, max_yellow_payment, max_green_pti, max_yellow_pti }: CreditScore | ITier,
    disabled?: boolean,
  ): CreditScoreColor {
    if (disabled) {
      return CreditScoreColors.GREY;
    }

    const isPaymentColorValid = this.isValuesValidForPaymentColor(ptiRatio, desiredMonthlyPayment);

    if (isPaymentColorValid(max_green_pti, max_green_payment)) {
      return CreditScoreColors.GREEN;
    }

    if (isPaymentColorValid(max_yellow_pti, max_yellow_payment)) {
      return CreditScoreColors.YELLOW;
    }

    return CreditScoreColors.RED;
  }

  static getTotalNetDownPayment(cashDown: number, tradeValue: number, amountOwed: number): number {
    return cashDown + tradeValue - amountOwed;
  }
}
