import styled from 'styled-components';

export interface IIconProps {
  imageSrc: string;
  height: string;
  width?: string;
}

export const Icon = styled.span<IIconProps>`
  display: inline-block;
  background-repeat: no-repeat;
  background-size: cover;
  height: ${({ imageSrc, height }) => (imageSrc ? height : '0')};
  width: ${({ height, width }) => width || height};
  background-image: ${({ imageSrc }) => `url(${imageSrc})`};
  vertical-align: middle;
`;
