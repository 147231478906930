import { PROBABILITY_ESTIMATOR_FIELDS } from 'modules/ProbabilityEstimator/probabilityEstimator.const';
import { FieldsMapper } from 'modules/core/types';

const FEES_AND_TAXES_MAPPER = {
  bankDiscount: 'bank_discount',
  bankFees: 'bank_fees',
  estimatedFees: 'sales_fees',
  salesTaxRate: 'sales_tax_rate',
};

const PREFERENCES_MAPPER = {
  ltvTaxesFeesFilter: 'is_ltv',
  vendorField: 'vendor_field',
};

export const FILTERS_MAPPER = {
  minMonthlyPayment: 'min_monthly_payment',
  odometerMiles: 'odometer__lte',

  // years field
  year__gte: 'year__gte',
  year__lte: 'year__lte',
  year: 'year',

  // miles field
  odometer__lte: 'odometer__lte',
  odometer__gte: 'odometer__gte',

  // ages field
  age__lt: 'age__lt',
  age__gt: 'age__gt',
  age__gte: 'age__gte',

  store: 'store',
  dealer: 'dealer',
  condition: 'condition',
  body_types: 'body_types',
  make: 'make',
  model: 'model',
  make_type: 'make_type',

  drive_type: 'drive_type',
  passenger_capacity: 'passenger_capacity',

  extraCash: 'extra_cash',
};

const TABLE_MAPPER = {
  tableView: 'table_view',
};

const PROBABILITY_ESTIMATOR_PREFERENCES = {
  ...PREFERENCES_MAPPER,
  ltvHighLimit: 'ltv_high_limit',
};

const TOTAL_NET_DOWN_PAYMENT_MAPPER = {
  tradeValue: 'trade_value',
  amountOwned: 'amount_owned',
  cashDown: 'cash_down',
  cashDownPayment: 'cash_down',
};

const APPROVED_FIELDS_MAPPER = {
  approvedMonthlyPayment: 'approved_monthly',
  approvedLoanTerm: 'approved_loan_term',
  approvedLTVRatio: 'approved_ltv_limit',
  approvedLoanValue: 'number',
  approvedInterestRate: 'approved_interest_rate',
};

const COMMON_NAMES_MAPPER: FieldsMapper = {
  ...FEES_AND_TAXES_MAPPER,
  ...FILTERS_MAPPER,
  ...PREFERENCES_MAPPER,
  ...TOTAL_NET_DOWN_PAYMENT_MAPPER,
  ...TABLE_MAPPER,
  estimatorType: 'estimator_type',
};

export const PROBABILITY_ESTIMATOR_FORM_NAMES_MAPPER: FieldsMapper = {
  ...COMMON_NAMES_MAPPER,
  ...PROBABILITY_ESTIMATOR_PREFERENCES,
  [PROBABILITY_ESTIMATOR_FIELDS.DESIRED_MONTHLY_PAYMENT]: 'desired_monthly',
  interestRate: 'interest_rate',
};

export const PROBABILITY_ESTIMATOR_BY_STOCK_FORM_NAMES_MAPPER: FieldsMapper = {
  ...FEES_AND_TAXES_MAPPER,
  ...TOTAL_NET_DOWN_PAYMENT_MAPPER,
  ...TABLE_MAPPER,
  ...PROBABILITY_ESTIMATOR_PREFERENCES,
  [PROBABILITY_ESTIMATOR_FIELDS.DESIRED_MONTHLY_PAYMENT]: 'desired_monthly',
  estimatorType: 'estimator_type',
  interestRate: 'interest_rate',
  minMonthlyPayment: 'min_monthly_payment',
  stocks: 'stock',
};

export const APPROVED_LOAN_INPUT_FORM_NAMES_MAPPER: FieldsMapper = {
  ...COMMON_NAMES_MAPPER,
  ...APPROVED_FIELDS_MAPPER,
  amountOwed: 'amount_owned',
  estimatedFees: 'estimated_fees',
};

export const APPROVED_LOAN_INPUT_BY_STOCK_FORM_NAMES_MAPPER: FieldsMapper = {
  ...FEES_AND_TAXES_MAPPER,
  ...TOTAL_NET_DOWN_PAYMENT_MAPPER,
  ...TABLE_MAPPER,
  ...APPROVED_FIELDS_MAPPER,
  ...PREFERENCES_MAPPER,
  estimatorType: 'estimator_type',
  amountOwed: 'amount_owned',
  estimatedFees: 'estimated_fees',
  minMonthlyPayment: 'min_monthly_payment',
  stocks: 'stock',
};

export const RAC_FORM_NAMES_MAPPER: FieldsMapper = {
  ...FILTERS_MAPPER,
  ...TABLE_MAPPER,
  desiredMonthlyPayment: 'approved_monthly_payment',
  cashDown: 'cash_down_payment',
  tradeValue: 'trade_value',
  amountOwed: 'amount_owed',
  netDownPayment: 'net_down_payment',
  addOns: 'add_ons',
  estimatedFees: 'estimated_fees',
  salesTaxRate: 'sales_tax_rate',
  vsc: 'vsc',
  gap: 'gap',
  estimatorType: 'estimator_type',
  tier: 'tier',
};

export const RAC_BY_STOCK_FORM_NAMES_MAPPER: FieldsMapper = {
  ...TABLE_MAPPER,
  desiredMonthlyPayment: 'approved_monthly_payment',
  cashDown: 'cash_down_payment',
  netDownPayment: 'net_down_payment',
  tradeValue: 'trade_value',
  amountOwed: 'amount_owed',
  addOns: 'add_ons',
  estimatedFees: 'estimated_fees',
  salesTaxRate: 'sales_tax_rate',
  vsc: 'vsc',
  gap: 'gap',
  estimatorType: 'estimator_type',
  tier: 'tier',
  minMonthlyPayment: 'min_monthly_payment',
  stocks: 'stock',
};

export const RAC_FORM_FOR_LOAN_VARIABLES_NAMES_MAPPER: FieldsMapper = {
  ...TABLE_MAPPER,
  desiredMonthlyPayment: 'approved_monthly_payment',
  cashDown: 'cash_down_payment',
  netDownPayment: 'net_down_payment',
  tradeValue: 'trade_value',
  amountOwed: 'amount_owed',
  estimatedFees: 'estimated_fees',
  salesTaxRate: 'sales_tax_rate',
  vscAndGapInsurance: 'vsc_and_gap_insurance',
  estimatorType: 'estimator_type',
  tier: 'tier',
  addOns: 'add_ons',
  unitCost: 'unit_cost',
  vsc: 'vsc',
  gap: 'gap',
  loanTerm: 'loan_term',
  odometer: 'odometer',
  currentPrice: 'current_price',
  guidebookValue: 'guidebook_value',
};

export const COMMON_LENDER_FORM_NAMES_MAPPER: FieldsMapper = {
  ...TABLE_MAPPER,
  lenderName: 'lender_name',

  tier_id: 'tier',
  grossMonthlyIncome: 'monthly_income',
  desiredMonthlyPayment: 'targeted_payment',

  cashDown: 'cash_down_payment',
  tradeValue: 'trade_value',
  amountOwed: 'amount_owed',
  netDownPayment: 'extra_cash_down',

  salesTaxRate: 'sales_tax_rate',
  salesFees: 'sales_fees',
  bankFees: 'bank_fees',
  bankDiscount: 'bank_discount',

  ltvHighLimit: 'ltv_high_limit',
  ltvTaxesFeesFilter: 'is_ltv',
  overridePrice: 'override_price',

  vsc: 'vsc',
  gap: 'gap',
  addOns: 'add_ons',
};

export const LENDER_FORM_NAMES_MAPPER: FieldsMapper = {
  ...FILTERS_MAPPER,
  ...COMMON_LENDER_FORM_NAMES_MAPPER,
};

export const LENDER_BY_STOCK_FORM_NAMES_MAPPER: FieldsMapper = {
  ...COMMON_LENDER_FORM_NAMES_MAPPER,

  minMonthlyPayment: 'min_monthly_payment',
  stocks: 'stock',
};
