import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';
import { Provider } from 'react-redux';
import 'i18n';
import { theme } from 'modules/core/theme';
import { store } from 'store/store';
import { RequestComponentWrapper } from 'RequestComponentWrapper';

import { SideBar } from './SideBar';

const load = (element: Element | DocumentFragment | null) =>
  element &&
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <RequestComponentWrapper>
            <SideBar isExternal />
          </RequestComponentWrapper>
        </ThemeProvider>
      </Provider>
    </React.StrictMode>,
    element,
  );

window.componentLoader = window.componentLoader || {};
window.componentLoader = { ...window.componentLoader, loadSideBar: load };
window.dispatchEvent(new Event('swa:side-bar-loaded'));
