import { Selector } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from 'store/store';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getFeesAndTaxesState } from 'store/selectors/controls.selector';
import { SetFieldValue } from 'modules/core/types/form';
import { FEES_AND_TAXES_FIELD_NAMES, FEES_AND_TAXES_INITIAL_VALUES } from 'modules/core/shared';
import { getFeesAndTaxesDataThunk } from 'actions/controlActions';

import { FRONT_AND_BACK_ENDS_ADDS_FIELD_NAMES } from '../../RACAffordabilityEngine/const/fieldNames.const';
import { FRONT_AND_BACK_ENDS_ADDS_VALUES } from '../../RACAffordabilityEngine/RACAffordabilityEngine.const';
import { IVehicleForm } from '../types';

export const useFeesAndTaxesInitialValues = <V extends IVehicleForm>(
  setValue: SetFieldValue,
  selector: Selector<RootState, V>,
  vsc?: number,
  isMakeItWork?: boolean,
  isWithInventory = true,
  isLender = false,
): void => {
  const dispatch: AppDispatch = useDispatch();
  const {
    feesAndTaxes: { estimatedFees, salesTaxRate },
  } = useSelector(selector);
  const { estimatedFeesControl, salesTaxRateControl, insuranceControl } = useSelector(getFeesAndTaxesState);

  useEffect(() => {
    if (isWithInventory) {
      const valuesUpdated = [estimatedFeesControl !== estimatedFees, salesTaxRateControl !== salesTaxRate].some(
        (valueUpdated: boolean) => valueUpdated,
      );

      const allDefault = [
        estimatedFees === FEES_AND_TAXES_INITIAL_VALUES.estimatedFees,
        salesTaxRate === FEES_AND_TAXES_INITIAL_VALUES.salesTaxRate,
        !!vsc ? vsc === FRONT_AND_BACK_ENDS_ADDS_VALUES.vsc : true,
      ].every((defaultValue: boolean) => defaultValue);

      if (valuesUpdated && allDefault) {
        if (!isMakeItWork) {
          setValue(FEES_AND_TAXES_FIELD_NAMES.ESTIMATED_FEES, estimatedFeesControl);
          setValue(FEES_AND_TAXES_FIELD_NAMES.SALES_FEES, estimatedFeesControl);

          if (!isLender) {
            setValue(FRONT_AND_BACK_ENDS_ADDS_FIELD_NAMES.VSC, insuranceControl);
          }
        }

        setValue(FEES_AND_TAXES_FIELD_NAMES.SALES_TAX_RATE, salesTaxRateControl);
        setValue(FRONT_AND_BACK_ENDS_ADDS_FIELD_NAMES.ADD_ONS, 0);
      } else {
        dispatch(getFeesAndTaxesDataThunk());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isMakeItWork,
    dispatch,
    salesTaxRateControl,
    estimatedFeesControl,
    insuranceControl,
    setValue,
    estimatedFees,
    salesTaxRate,
    isWithInventory,
    isLender,
  ]);
};
