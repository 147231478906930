import styled from 'styled-components';

export const PaginationWrapper = styled.div`
  .pagination {
    justify-content: center;
    margin-top: 1rem;
    margin-bottom: 0 !important;
  }

  .page-link,
  .page-item a {
    color: ${({ theme: { colors } }) => colors['grey-5']};
  }

  .selected a {
    background-color: ${({ theme: { colors } }) => colors['white-2']};
    border-color: ${({ theme: { colors } }) => colors['white-3']};
  }
`;
