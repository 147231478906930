import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { clearState } from 'store/reducers/auth.reducer';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { AppDispatch } from 'store/store';
import { EndUserAgreement, PrivacyPolicy } from 'components/Modals';
import { loginUserThunk } from 'actions/authActions';
import { getAuthState } from 'store/selectors/auth.selector';
import { checkUserThunk } from 'actions/userActions';

import { Button, EmailInput, PasswordInput } from '../common';
import { AgreementModalLink, AndSpan, ErrorMessage, FormPosition, Link, LinkPosition, LoginFormCheck } from '../styles';

interface ILoginFormValues {
  email: string;
  password: string;
  keepLogged: boolean;
}

export const LoginForm: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<ILoginFormValues>({
    mode: 'onChange',
  });
  const { isError, errorMessage } = useSelector(getAuthState);
  const [showEndUserAgreement, setShowEndUserAgreement] = useState(false);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);

  const onSubmit = useCallback(
    async (data: ILoginFormValues) => {
      dispatch(clearState());
      await dispatch(loginUserThunk(data));
      await dispatch(checkUserThunk({ history: history }));
    },
    [history, dispatch],
  );

  return (
    <FormPosition onSubmit={handleSubmit(onSubmit)}>
      <EmailInput label={t('components.login.email')} register={register} errorEmail={errors.email} autoComplete="on" />
      <PasswordInput
        label={t('components.login.password')}
        name="password"
        register={register}
        errorPassword={errors.password}
        rules={{ required: true }}
      />
      {isError ? <ErrorMessage>{errorMessage}</ErrorMessage> : null}
      <LinkPosition>
        <Link href="/password/reset">{t('components.login.forgotPasswordQuestion')}</Link>
      </LinkPosition>
      <LinkPosition>
        <AgreementModalLink onClick={() => setShowEndUserAgreement(true)} type="button">
          {t('components.endUserAgreement.label')}
        </AgreementModalLink>
        <EndUserAgreement show={showEndUserAgreement} onHide={() => setShowEndUserAgreement(false)} />
        <AndSpan>
          &nbsp;
          {t('components.login.and')}
          &nbsp;
        </AndSpan>
        <AgreementModalLink onClick={() => setShowPrivacyPolicy(true)} type="button">
          {t('components.privacyPolicy.label')}
        </AgreementModalLink>
        <PrivacyPolicy show={showPrivacyPolicy} onHide={() => setShowPrivacyPolicy(false)} />
      </LinkPosition>
      <Form.Group>
        <LoginFormCheck label={t('components.login.keepLoggedIn')} name="keepLogged" type="checkbox" ref={register} />
      </Form.Group>
      <Button label={t('components.login.buttons.login')} disabled={!isValid} />
    </FormPosition>
  );
};
