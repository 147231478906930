import { IS_AUTHORIZED_LS_KEY, KEEP_ME_LOGGED_IN, TWO_WEEKS_SECONDS } from 'modules/auth/constants/auth.const';
import { setCookies } from 'modules/auth/utils/cookie';

export class AuthService {
  static setLoggedStatus(keepLoggedIn: boolean): void {
    setCookies(KEEP_ME_LOGGED_IN, keepLoggedIn ? 1 : 0, { path: '/', maxAge: TWO_WEEKS_SECONDS });
    this.setAuthorizedStatus();
  }

  static setAuthorizedStatus(): void {
    localStorage.setItem(IS_AUTHORIZED_LS_KEY, 'true');
  }
}
