import React, { Dispatch, SFC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Modal } from 'react-bootstrap';
import { Content, Title } from 'components/ModalDialog/style';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { MODAL_DIALOG_ID, MODAL_DIALOG_WIDTH, RECALCULATE_TAXES_DELAY } from 'modules/core/constants';
import { IRACAffordabilityEngineForm } from 'modules/RACAffordabilityEngine/types/RACAffordabilityEngineForm';
import { ButtonVariant, ColumnWidth } from 'modules/core/enums';
import { FormRow } from 'modules/core/components';
import { ButtonPrint } from 'modules/ApprovedLoanInput/styles';
import { resetRouteOneDataFlags, setNetTradeIn, setSalesFees } from 'store/reducers/RACAffordabilityEngine.reducer';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'store/store';
import { useDebounceCallback, useModalState } from 'modules/core/hooks';
import ReactToPrint from 'react-to-print';
import {
  IField,
  IGetVscAndGapLimitResult,
  ILenderVehicle,
  IRACGetVscAndGapLimit,
  IRACVehicle,
} from 'modules/core/types';
import { IProbabilityEstimatorForm } from 'modules/ProbabilityEstimator/types';
import { produce } from 'immer';
import { getSubmitVehicleObjectQuery } from 'modules/core/utils';
import { RACAffordabilityEngineService } from 'modules/RACAffordabilityEngine/RACAffordabilityEngine.service';
import { RAC_FORM_FOR_LOAN_VARIABLES_NAMES_MAPPER } from 'modules/core/constants/mappers/submitVehicles.mapper';
import { getVendorName } from 'store/selectors/ui.selector';
import {
  getLenderLoanTermsListThunk,
  getLenderLoanVariablesInfoThunk,
  getLoanTermsListThunk,
  getLoanVariablesInfoThunk,
  postRouteOneDataThunk,
} from 'actions/vehicleActions';
import { ProbabilityEstimatorCalculationService } from 'modules/ProbabilityEstimator/services';
import { DEFAULT_MAXIMAL_PAYMENT_RANGE } from 'modules/ProbabilityEstimator/probabilityEstimator.const';
import { TableColumn } from 'modules/core/components/Table/types';
import { COLORS } from 'modules/core/theme/colors';
import { LendersMonthlyIncomeModal } from 'modules/RACAffordabilityEngine/RacMontlyIncomeModal/LendersMonthlyIncomeModal';
import { getUser } from 'store/selectors/auth.selector';
import { vehicleApi } from 'api';
import { getCurrentLenderControls, getLenderState } from 'store/selectors/lenders.selector';
import {
  getRegionControls,
  getRoteOneDataFlags,
  getRouteOneData,
  getLoanVariablesFetchingFlag,
  getVehiclesByParams,
} from 'store/selectors/RACAffordabilityEngine.selector';
import { IRouteOneDataFlags } from 'modules/core/types/routeOne';
import { ILenderTierTerm } from 'modules/core/types/lender';

import { Loader } from '../Loader';
import {
  ButtonUndo,
  ButtonUndoBlock,
  ButtonUndoLabel,
  HeaderActionsBlock,
  ModalBackdrop,
  ModalButton,
  SubmitButtonsBlock,
  FormControlFeedback,
  SubmitMessageBlock,
  TradeMark,
} from '../styles';
import { ISendRouteOneData } from '../types';
import {
  RAC_MW_CONFIRM_SUBMIT_FIELD,
  RAC_MW_FINANCING_TERMS_FIELDS,
  RAC_MW_FINANCING_TERMS_LABELS,
  RAC_MW_TRANSACTION_COSTS_FIELDS,
  RAC_MW_TRANSACTION_COSTS_LABELS,
  RAC_MW_VEHICLE_ENHANCEMENTS_FEILDS,
  RAC_MW_VEHICLE_ENHANCEMENTS_LABELS,
  RAC_MW_VEHICLE_VALUATION_FIELDS,
  RAC_MW_VEHICLE_VALUATION_LABELS,
} from '../consts/fieldConfig.const';
import { LOAN_VARIABLES_FIELDS_NAMES, LOAN_VARIABLES_FORM_PREFIX } from '../consts/fieldNames.const';
import { SaleFeesCalculator } from '../modals/Calculators/SaleFeesCalculator/SaleFeesCalculator';
import { NetTradeInCalculator } from '../modals/Calculators/NetTradeInCalculator/NetTradeInCalculator';
import { LOAN_VARIABLES_MODAL_DIALOG_ID } from '../consts/consts';
import { CALCULATION_FIELDS } from '../modals/Calculators/SaleFeesCalculator/consts/fieldConfig.const';
import { ISalesFeesForm } from '../modals/Calculators/SaleFeesCalculator/types';
import { INetTradeInForm } from '../modals/Calculators/NetTradeInCalculator/types';
import { LoanVariablesFields } from './LoanVariablesFields';
import { NET_TRADE_IN_CALCULATION_FIELDS } from '../modals/Calculators/NetTradeInCalculator/consts/fieldConfig.const';
// SWA-5628 Temporary hide: Lender Pricing/Market Pricing toggle from RAC page with Market Pricing state by default.
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ExtraCashUnavailableWarn, PrintContainer } from './common/styles';
import {
  LENDER_LOAN_VARIABELS_COLUMNS,
  LENDER_LOAN_VARIABLES_CUSTOMER_RATE,
  LOAN_VARIABLES_COLUMNS_MAP,
  LOAN_VARIABLES_MODAL_FIELDS,
} from '../common.const';
import { OTHER_OPTION } from '../modals/Calculators/NetTradeInCalculator/consts/consts';
import { AddVehicleDialog } from '../../AddVehicleDialog/AddVehicleDialog';

interface ILoanVariablesFormModal {
  isLender?: boolean;
  currentVehicleData: IRACVehicle | ILenderVehicle | undefined;
  loanVariables: any;
  formHistory: any[];
  defaultValues: any;
  loanTermsList: number[];
  uppercaseTitle?: boolean;
  isShowExitConfirmModal: boolean;
  isLoanVariablesDialogActive: boolean;
  isRouteOneVehicle: boolean;
  handleHide: () => void;
  handleHideWithResetVehicle: () => void;
  setFormHistory: Dispatch<React.SetStateAction<any[]>>;
  setLoanVariables: Dispatch<any>;
  setIsShowExitConfirmModal: Dispatch<React.SetStateAction<boolean>>;
  setLoanTermsList: Dispatch<React.SetStateAction<number[]>>;
  isAddedRouteOneVehicle: boolean;
}

const PAGE_STYLE = `
@page { size: auto; }
@media print {
  body { -webkit-print-color-adjust: exact; }
  .row { --bs-gutter-x: 1.5rem; --bs-gutter-y: 0; display: flex; flex-wrap: wrap; }
  #tierCol { flex: 0 0 14%; flex-direction: column; }
  #monthlyIncomeCol { flex: 0 0 27%; flex-direction: column; }
  #approvedPaymentCol { flex: 0 0 27%; flex-direction: column; }
  #currentPaymentCol { flex: 0 0 27%; flex-direction: column; }
  .col-lg-8 { flex: 0 0 60%; flex-direction: column; }
  .col-md-8 { flex: 0 0 60%; flex-direction: column; }
  .col-lg-9 { flex: 0 0 75%; }
  .button-print { visibility: hidden }
}`;

const RECALCULATE_TAXES_FIELDS = [
  LOAN_VARIABLES_FIELDS_NAMES.RETAIL_PRICE,
  LOAN_VARIABLES_FIELDS_NAMES.ADD_ONS,
  LOAN_VARIABLES_FIELDS_NAMES.VSC,
  LOAN_VARIABLES_FIELDS_NAMES.GAP,
];
const REQUIRED_FIELDS_KEYS = [
  ...RECALCULATE_TAXES_FIELDS.map((fullName) => fullName.split('.')[1]),
  'netTradeIn',
  'guidebookValue',
];

const ON_CHANGE_CALLBACK_FIELDS = [LOAN_VARIABLES_FIELDS_NAMES.CONFIRM_TAXES, LOAN_VARIABLES_FIELDS_NAMES.CONFIRM_BOOK];

export const LoanVariablesFormContent: SFC<ILoanVariablesFormModal> = ({
  isLender = false,
  currentVehicleData,
  loanVariables,
  defaultValues,
  formHistory,
  loanTermsList,
  uppercaseTitle = true,
  isShowExitConfirmModal = false,
  isRouteOneVehicle = false,
  handleHide,
  handleHideWithResetVehicle,
  setFormHistory,
  setLoanVariables,
  setIsShowExitConfirmModal,
  setLoanTermsList,
  isAddedRouteOneVehicle,
}) => {
  const lenderName = useSelector(getLenderState)?.name;
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const regionControls = useSelector(getRegionControls);
  const lenderControls = useSelector(getCurrentLenderControls);
  const vendorName = useSelector(getVendorName);
  const routeOneData = useSelector(getRouteOneData);
  const routeOneDataFlags = useSelector(getRoteOneDataFlags);
  const vehiclesByParams = useSelector(getVehiclesByParams);
  const [paymentErrorMessage, setPaymentErrorMessage] = useState<string | undefined>(undefined);
  const [participationErrorMessage, setParticipationErrorMessage] = useState<string | undefined>(undefined);
  const [maxParticipation, setMaxParticipation] = useState<number | undefined>(undefined);
  const [mileageErrorMessage, setMileageErrorMessage] = useState<string | undefined>(undefined);
  const [showPrintPreview, setShowPrintPreview] = useState(false);
  const [isVscInvalid, setIsVscInvalid] = useState(false);
  const [isGapInvalid, setIsGapInvalid] = useState(false);
  const [showSubmitPreview, setShowSubmitPreview] = useState(false);
  const [isToggled, setToggled] = useState<boolean>(false);
  const [isUndoClicked, setIsUndoClicked] = useState<boolean>(false);
  const [showAddVehicleDialog, setShowAddVehicleDialog] = useState<boolean>(false);
  const user = useSelector(getUser);
  const { getValues: commonGetValues } = useFormContext<IRACAffordabilityEngineForm>();
  const { tier } = commonGetValues() as any;
  const componentRef = useRef(null);
  const { max_allowed_pti, max_allowed_payment } = tier;
  const tierName = isLender ? tier.tier_name : tier.tier;
  const isMakeItWork = user?.is_sso;
  const { isFetchingLoanVariablesInfo } = useSelector(getLoanVariablesFetchingFlag);

  const formMethods = useForm({
    mode: 'onSubmit',
    shouldUnregister: false,
    defaultValues: defaultValues,
  });
  const {
    getValues,
    reset,
    setValue,
    setError,
    watch,
    handleSubmit,
    clearErrors,
    errors,
    formState: { dirtyFields },
  } = formMethods;
  const {
    price,
    retailPrice,
    mileage,
    guidebookValue,
    cashDown,
    rebates,
    addOns,
    vsc,
    gap,
    totalSalesFees,
    taxes,
    payment,
    participation,
    loanTerm,
    apr,
    monthlyIncome,
    confirmBook,
    confirmTaxes,
    confirmSubmit,
    vin,
    netTradeInForm,
    salesFeesForm,
    ltvCategories,
    monthlyIncomeForm,
    isBookVerified,
    vehicle_id,
  } = watch(LOAN_VARIABLES_FORM_PREFIX) as any;

  const isParticipationValid = useCallback(() => {
    if (!isLender) return true;

    return !participation || participation <= (commonGetValues().tier as any).max_participation;
  }, [commonGetValues, isLender, participation])();

  const maxPaymentValue = useCallback(
    () =>
      ProbabilityEstimatorCalculationService.getMaximalMonthlyPayment(
        max_allowed_pti,
        max_allowed_payment,
        monthlyIncome,
      ) || DEFAULT_MAXIMAL_PAYMENT_RANGE,
    [max_allowed_payment, max_allowed_pti, monthlyIncome],
  );
  const necessaryDirtyFields = Object.keys(dirtyFields?.loanVariablesForm || {}).filter(
    (key) => !['totalAmountFinanced', 'monthlyIncome'].includes(key),
  );

  const { watch: watchFormContext } = useFormContext();
  const ltvTaxesFeesFilter = watchFormContext('preferences.ltvTaxesFeesFilter');

  const isExtraCashAvailable = useCallback(() => {
    if (isLender) {
      return loanVariables.extra_cash_or_discount === 0;
    } else {
      return (
        ltvCategories.filter((ltvCategory: { extra_down_payment: number }) => ltvCategory.extra_down_payment === 0)
          .length === 1
      );
    }
  }, [isLender, loanVariables.extra_cash_or_discount, ltvCategories])();

  const requiredFieldsInvalid =
    Object.keys(errors?.loanVariablesForm || {}).filter((key) => REQUIRED_FIELDS_KEYS.includes(key) && key).length > 0;

  const headerTitle = useCallback(() => {
    const verify = t('components.loanVariablesModal.headers.verifyDealStructure');
    const print = t('components.loanVariablesModal.headers.printDealStructure');
    const adjust = isLender
      ? t('components.loanVariablesModal.headers.lenderAdjustDealStructure', { lenderName: lenderName })
      : t('components.loanVariablesModal.headers.adjustDealStructure');

    return showSubmitPreview ? verify : showPrintPreview ? print : adjust;
  }, [t, isLender, lenderName, showSubmitPreview, showPrintPreview]);

  const setHistoryCallback = useCallback(() => {
    const values = getValues();

    setFormHistory((prevHistory) =>
      produce(prevHistory, (draft) => {
        draft.push(values);
      }),
    );
  }, [getValues, setFormHistory]);

  // SWA-5628 Temporary hide: Lender Pricing/Market Pricing toggle from RAC page with Market Pricing state by default.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handlePriceClick = () => {
    setToggled(!isToggled);
    isToggled
      ? setValue(LOAN_VARIABLES_FIELDS_NAMES.PRICE, LOAN_VARIABLES_FIELDS_NAMES.RETAIL_PRICE)
      : setValue(LOAN_VARIABLES_FIELDS_NAMES.PRICE, LOAN_VARIABLES_FIELDS_NAMES.ADJUSTED_PRICE);
    setHistoryCallback();
  };

  useEffect(() => {
    if (
      formHistory.length === 1 &&
      (currentVehicleData?.odometer !== currentVehicleData?.original_odometer ||
        (user?.is_sso && isRouteOneVehicle && routeOneData?.sale_vehicle?.mileage !== loanVariables.odometer))
    ) {
      setValue(LOAN_VARIABLES_FIELDS_NAMES.CONFIRM_BOOK, false);
      setValue(LOAN_VARIABLES_FIELDS_NAMES.IS_BOOK_VERIFIED, false);
    }
  }, [
    formHistory.length,
    currentVehicleData?.odometer,
    currentVehicleData?.original_odometer,
    setValue,
    loanVariables.odometer,
    routeOneData?.sale_vehicle?.mileage,
    user?.is_sso,
    isRouteOneVehicle,
  ]);

  const {
    isModalDialogActive: isModalSalesFeesDialogActive,
    handleOpenDialog: handleSalesFeesDialogOpen,
  } = useModalState([MODAL_DIALOG_ID.LOAN_VARIABLES, LOAN_VARIABLES_MODAL_DIALOG_ID.SALES_FEES_CALCULATOR]);

  const {
    isModalDialogActive: isIncomeDialogActive,
    handleOpenDialog: handleIncomeDialogOpen,
  } = useModalState<IProbabilityEstimatorForm>([
    MODAL_DIALOG_ID.LOAN_VARIABLES,
    LOAN_VARIABLES_MODAL_DIALOG_ID.MOUNTLY_INCOME_CALCULATOR,
  ]);

  const { handleCloseDialog: handleIncomeDialogClose } = useModalState<IProbabilityEstimatorForm>([
    MODAL_DIALOG_ID.LOAN_VARIABLES,
    LOAN_VARIABLES_MODAL_DIALOG_ID.MOUNTLY_INCOME_CALCULATOR,
  ]);

  const {
    isModalDialogActive: isNetTradeInDialogOpen,
    handleOpenDialog: handleNetTradeInDialogOpen,
    handleCloseDialog: handleCloseNetTradeInDialog,
  } = useModalState<IProbabilityEstimatorForm>([
    MODAL_DIALOG_ID.LOAN_VARIABLES,
    LOAN_VARIABLES_MODAL_DIALOG_ID.NET_TRADE_IN_CALCULATOR,
  ]);

  const mapOnClickFunctionByFieldName = useMemo(
    () =>
      new Map<string, VoidFunction>([
        [LOAN_VARIABLES_FIELDS_NAMES.TOTAL_SALES_FEES, handleSalesFeesDialogOpen],
        [LOAN_VARIABLES_FIELDS_NAMES.MONTHLY_INCOME, handleIncomeDialogOpen],
        [LOAN_VARIABLES_FIELDS_NAMES.NET_TRADE_IN, handleNetTradeInDialogOpen],
      ]),
    [handleSalesFeesDialogOpen, handleIncomeDialogOpen, handleNetTradeInDialogOpen],
  );

  const requestParams = useMemo(
    () => ({
      vehicle_id: vehicle_id,
      add_ons: addOns || 0,
      current_price: retailPrice || 0,
      vsc: vsc || 0,
      gap: gap || 0,
      loan_term: loanTerm.value || 0,
      guidebook_value: guidebookValue || 0,
      trade_value: netTradeInForm.tradeInValue || 0,
      sales_fees: isLender ? totalSalesFees || 0 : undefined,
      estimated_fees: !isLender ? totalSalesFees || 0 : undefined,
      approved_monthly_payment: payment || 0,
      odometer: mileage || 0,
      cash_down_payment: (cashDown || 0) + (rebates || 0),
      vsc_and_gap_insurance: (vsc || 0) + (gap || 0),
      amount_owed: netTradeInForm.amountOwnedOnTrade || 0,
      participation: participation || 0,
    }),
    [
      addOns,
      cashDown,
      rebates,
      vehicle_id,
      gap,
      guidebookValue,
      loanTerm.value,
      mileage,
      netTradeInForm.amountOwnedOnTrade,
      netTradeInForm.tradeInValue,
      payment,
      retailPrice,
      totalSalesFees,
      vsc,
      participation,
      isLender,
    ],
  );

  const getLoanVariablesInfo = useCallback(
    async (params?: any) => {
      const vehicleParameters = getSubmitVehicleObjectQuery<IRACAffordabilityEngineForm>(
        { ...currentVehicleData, ...commonGetValues(), vendorName: vendorName, isLender },
        RACAffordabilityEngineService.getSubmitQueryForLoanVariables,
        RAC_FORM_FOR_LOAN_VARIABLES_NAMES_MAPPER,
      );

      let loanVariablesInfoResponse: { payload: any };

      if (isLender) {
        vehicleApi.cancelGetLenderLoanVariablesInfo();
        loanVariablesInfoResponse = await dispatch(
          getLenderLoanVariablesInfoThunk({
            ...vehicleParameters,
            ...requestParams,
            ...params,
            bank_discount: commonGetValues().feesAndTaxes.bankDiscount,
            bank_fees: commonGetValues().feesAndTaxes.bankFees,
            lender_name: commonGetValues().lenderName,
            is_ltv: ltvTaxesFeesFilter,
            ltv_high_limit: commonGetValues().preferences.ltvHighLimit,
            monthly_income: commonGetValues().grossMonthlyIncome,
            targeted_payment: requestParams.approved_monthly_payment,
          }),
        );
      } else {
        vehicleApi.cancelGetLoanVariablesInfo();
        loanVariablesInfoResponse = await dispatch(
          getLoanVariablesInfoThunk({
            ...vehicleParameters,
            ...requestParams,
            ...params,
          }),
        );
      }

      return loanVariablesInfoResponse.payload;
    },
    [commonGetValues, currentVehicleData, dispatch, isLender, ltvTaxesFeesFilter, requestParams, vendorName],
  );

  const calculateTotalAmountFinanced = useCallback(async () => {
    if (!paymentErrorMessage) {
      const values = getValues();

      const loanVariablesInfoResponse: any = await getLoanVariablesInfo({
        unit_cost: values.loanVariablesForm.guidebookValue,
        taxes: taxes,
        vehicle_id: values.loanVariablesForm.vehicle_id,
        sales_fees: isLender ? values.loanVariablesForm.totalSalesFees || 0 : undefined,
      });

      const isLtvCategoriesExists =
        (isLender && loanVariablesInfoResponse?.ltv_ratio) || loanVariablesInfoResponse?.ltv_categories?.length > 0;
      const totalAmountFinanced = isLtvCategoriesExists
        ? isLender
          ? loanVariablesInfoResponse?.total_financed
          : loanVariablesInfoResponse.ltv_categories[0].loan_amount
        : 0;

      setLoanVariables(loanVariablesInfoResponse);
      setIsUndoClicked(false);
      setHistoryCallback();
      reset({
        loanVariablesForm: {
          ...values.loanVariablesForm,
          ltvCategories: isLtvCategoriesExists
            ? isLender
              ? loanVariablesInfoResponse
              : loanVariablesInfoResponse.ltv_categories
            : [],
          currentPayment: isLtvCategoriesExists
            ? isLender
              ? loanVariablesInfoResponse?.monthly_payment
              : loanVariablesInfoResponse.ltv_categories[0].payments
            : NaN,
          totalAmountFinanced: totalAmountFinanced,
        },
      });
    }
  }, [
    paymentErrorMessage,
    getValues,
    isLender,
    getLoanVariablesInfo,
    taxes,
    setLoanVariables,
    setHistoryCallback,
    reset,
  ]);

  const handleCalculateTotalAmountFinancedClick = useCallback(() => {
    clearErrors(LOAN_VARIABLES_FIELDS_NAMES.CONFIRM_TAXES);
    clearErrors(LOAN_VARIABLES_FIELDS_NAMES.CONFIRM_BOOK);
    clearErrors(LOAN_VARIABLES_FIELDS_NAMES.CONFIRM_SUBMIT);

    handleSubmit(calculateTotalAmountFinanced)();
  }, [calculateTotalAmountFinanced, clearErrors, handleSubmit]);

  const isFieldChanged = useCallback(
    (name: any) => {
      const loanVariablesForm = getValues().loanVariablesForm as any;

      const prevValues =
        formHistory.length <= 1
          ? defaultValues.loanVariablesForm
          : formHistory[formHistory.length - 1].loanVariablesForm;

      if (name === 'loanTerm') {
        return Number(loanVariablesForm.loanTerm.value) !== Number(prevValues.loanTerm.value);
      }

      if (Number.isNaN(prevValues[name]) && Number.isNaN(loanVariablesForm[name])) {
        return false;
      }

      return prevValues[name] !== loanVariablesForm[name];
    },
    [defaultValues.loanVariablesForm, formHistory, getValues],
  );

  const recalculateTaxes = useCallback(async () => {
    if (dirtyFields?.loanVariablesForm) {
      const {
        addOns,
        retailPrice,
        vsc,
        gap,
        loanTerm,
        guidebookValue,
        netTradeInForm,
        totalSalesFees,
        payment,
        mileage,
        cashDown,
        rebates,
        vehicle_id,
      } = getValues().loanVariablesForm;

      const loanVariablesInfoResponse: any = await getLoanVariablesInfo({
        vehicle_id: vehicle_id,
        add_ons: addOns || 0,
        current_price: retailPrice || 0,
        vsc: vsc || 0,
        gap: gap || 0,
        loan_term: loanTerm.value || 0,
        guidebook_value: guidebookValue || 0,
        trade_value: netTradeInForm.tradeInValue || 0,
        sales_fees: isLender ? totalSalesFees || 0 : undefined,
        estimated_fees: !isLender ? totalSalesFees || 0 : undefined,
        approved_monthly_payment: payment || 0,
        odometer: mileage || 0,
        cash_down_payment: (cashDown || 0) + (rebates || 0),
        vsc_and_gap_insurance: (vsc || 0) + (gap || 0),
        amount_owed: netTradeInForm.amountOwnedOnTrade || 0,
      });

      setValue(LOAN_VARIABLES_FIELDS_NAMES.TAXES, loanVariablesInfoResponse?.taxes || 0);
      setValue(LOAN_VARIABLES_FIELDS_NAMES.CONFIRM_TAXES, false);

      return loanVariablesInfoResponse;
    }
  }, [dirtyFields?.loanVariablesForm, getLoanVariablesInfo, getValues, setValue, isLender]);

  const recalculateTaxesWithDelay = useDebounceCallback(() => {
    handleRecalculateTaxesClick();
  }, RECALCULATE_TAXES_DELAY);

  const handleRecalculateTaxesClick = useCallback(() => {
    clearErrors(LOAN_VARIABLES_FIELDS_NAMES.CONFIRM_TAXES);
    clearErrors(LOAN_VARIABLES_FIELDS_NAMES.CONFIRM_BOOK);
    clearErrors(LOAN_VARIABLES_FIELDS_NAMES.CONFIRM_SUBMIT);

    return recalculateTaxes();
  }, [clearErrors, recalculateTaxes]);

  const setConfirmTaxesToDefault = useCallback(() => {
    setValue(LOAN_VARIABLES_FIELDS_NAMES.CONFIRM_TAXES, false);
  }, [setValue]);

  const setConfirmBookToDefault = useCallback(() => {
    setValue(LOAN_VARIABLES_FIELDS_NAMES.CONFIRM_BOOK, false);
  }, [setValue]);

  const setIsBookVerifiedToDefault = useCallback(() => {
    if (guidebookValue !== 0) setValue(LOAN_VARIABLES_FIELDS_NAMES.IS_BOOK_VERIFIED, true);
    setHistoryCallback();
  }, [guidebookValue, setValue, setHistoryCallback]);

  const getLoanTermsList = useCallback(async () => {
    setConfirmBookToDefault();
    setValue(LOAN_VARIABLES_FIELDS_NAMES.IS_BOOK_VERIFIED, false);
    const { mileage } = getValues().loanVariablesForm;
    let loanTermsListResponse;

    if (!Number.isNaN(mileage)) {
      if (isLender) {
        loanTermsListResponse = await dispatch(
          getLenderLoanTermsListThunk({
            mileage: mileage,
            tier: (commonGetValues().tier as any).tier_name,
            year: currentVehicleData?.description.split(' ')[0],
            lender_name: commonGetValues().lenderName,
          }),
        );
      } else {
        loanTermsListResponse = await dispatch(
          getLoanTermsListThunk({
            mileage: mileage,
            tier: tierName,
          }),
        );
      }

      setLoanTermsList(loanTermsListResponse.payload);
      setValue(LOAN_VARIABLES_FIELDS_NAMES.LOAN_TERM, {
        title: loanTermsListResponse.payload[0],
        value: loanTermsListResponse.payload[0],
      });
      setValue(LOAN_VARIABLES_FIELDS_NAMES.LOAN_TERMS_LIST, loanTermsListResponse.payload);
    }
  }, [
    setConfirmBookToDefault,
    setValue,
    getValues,
    isLender,
    setLoanTermsList,
    dispatch,
    commonGetValues,
    currentVehicleData?.description,
    tierName,
  ]);

  const callbackLookup = useMemo(
    () => ({
      ...RECALCULATE_TAXES_FIELDS.reduce((fields, field) => ({ ...fields, [field]: recalculateTaxesWithDelay }), {}),
      ...ON_CHANGE_CALLBACK_FIELDS.reduce((fields, field) => ({ ...fields, [field]: setHistoryCallback }), {}),
    }),
    [recalculateTaxesWithDelay, setHistoryCallback],
  ) as { [key: string]: () => void };

  const handleChangeCallback = useCallback(
    (name: string) => {
      if (name === LOAN_VARIABLES_FIELDS_NAMES.MONTHLY_INCOME) {
        return undefined;
      }

      if (name === LOAN_VARIABLES_FIELDS_NAMES.CONFIRM_BOOK) {
        return setIsBookVerifiedToDefault;
      }

      if (name === LOAN_VARIABLES_FIELDS_NAMES.TAXES) {
        return setConfirmTaxesToDefault;
      }

      if (name === LOAN_VARIABLES_FIELDS_NAMES.GUIDEBOOK_VALUE) {
        return setConfirmBookToDefault;
      }

      if (name === LOAN_VARIABLES_FIELDS_NAMES.MILEAGE) {
        return getLoanTermsList;
      }

      if (name === LOAN_VARIABLES_FIELDS_NAMES.LOAN_TERM && isFieldChanged('loanTerm')) {
        return setHistoryCallback;
      }

      return callbackLookup[name];
    },
    [
      isFieldChanged,
      callbackLookup,
      setIsBookVerifiedToDefault,
      setConfirmTaxesToDefault,
      setConfirmBookToDefault,
      getLoanTermsList,
      setHistoryCallback,
    ],
  );

  const getVscOrGapLimit = useCallback(
    ({
      fieldName,
      vsc,
      gap,
      t,
      guidebookValue,
      isVscInvalid,
      isGapInvalid,
      regionControls,
      setIsVscInvalid,
      setIsGapInvalid,
    }: IRACGetVscAndGapLimit): IGetVscAndGapLimitResult => {
      let message = '';
      let maxValue = 0;
      let isInvalid = false;

      if (isLender) {
        const vscLimit = lenderControls.maxVSC;
        const gapLimit = lenderControls.maxGap;

        if (fieldName === LOAN_VARIABLES_FIELDS_NAMES.VSC && !!vscLimit) {
          isInvalid = vsc > vscLimit;

          if (isInvalid !== isVscInvalid) setIsVscInvalid(isInvalid);
          message = t('components.RACAffordabilityEngine.vscAndGapLimits.vscLimitMessage', { maxVSC: vscLimit });
        }

        if (fieldName === LOAN_VARIABLES_FIELDS_NAMES.GAP && !!gapLimit) {
          isInvalid = gap > gapLimit;

          if (isInvalid !== isGapInvalid) setIsGapInvalid(isInvalid);
          message = t('components.RACAffordabilityEngine.vscAndGapLimits.gapLimitMessage', { maxGap: gapLimit });
        }

        return { message, isInvalid };
      }

      if (
        fieldName === LOAN_VARIABLES_FIELDS_NAMES.VSC &&
        !!regionControls.vscLimitVehicleValue &&
        !!regionControls.vscLimitBelow &&
        !!regionControls.vscLimitAbove
      ) {
        if (guidebookValue && guidebookValue < regionControls.vscLimitVehicleValue) {
          maxValue = regionControls.vscLimitBelow;
        } else {
          maxValue = regionControls.vscLimitAbove;
        }

        isInvalid = vsc > maxValue;

        if (isInvalid !== isVscInvalid) setIsVscInvalid(isInvalid);
        message = t('components.RACAffordabilityEngine.vscAndGapLimits.vscLimitMessage', { maxVSC: maxValue });
      }

      if (fieldName === LOAN_VARIABLES_FIELDS_NAMES.GAP && !!regionControls.gapLimit) {
        maxValue = regionControls.gapLimit;
        isInvalid = gap > maxValue;

        if (isInvalid !== isGapInvalid) setIsGapInvalid(isInvalid);
        message = t('components.RACAffordabilityEngine.vscAndGapLimits.gapLimitMessage', { maxGap: maxValue });
      }

      return { message, isInvalid };
    },
    [isLender, lenderControls.maxGap, lenderControls.maxVSC],
  );

  const manuallyCreated =
    currentVehicleData?.manually_created ||
    (isRouteOneVehicle && routeOneData?.manually_created) ||
    isAddedRouteOneVehicle;

  const generateFields = useCallback(
    (fields: IField[]) => {
      const loanTermOptions =
        loanTermsList.length >= 1 ? loanTermsList.map((value) => ({ title: value, value: value })) : [];

      return fields.map(({ name, component: Component, controlProps, label, isVerticalAlign, textLabel }: IField) => {
        const adornmentOnClick = mapOnClickFunctionByFieldName.get(name);
        const options = name === LOAN_VARIABLES_FIELDS_NAMES.LOAN_TERM ? loanTermOptions : [];
        let inputLabel =
          name === LOAN_VARIABLES_FIELDS_NAMES.GUIDEBOOK_VALUE
            ? currentVehicleData?.guidebook || loanVariables?.guidebook
            : label;
        const fieldWidth = controlProps?.fieldWidth ? { fieldWidth: controlProps.fieldWidth as ColumnWidth } : {};
        const nameKey = name.split('.')[1];

        const isVscOrGap = [LOAN_VARIABLES_FIELDS_NAMES.VSC, LOAN_VARIABLES_FIELDS_NAMES.GAP].includes(name);
        const areVscLimitsValid =
          !!regionControls.vscLimitVehicleValue && !!regionControls.vscLimitBelow && !!regionControls.vscLimitAbove;

        const isMileage = [LOAN_VARIABLES_FIELDS_NAMES.MILEAGE].includes(name);

        let message = '';
        let isInvalid = false;

        if (isVscOrGap && !showPrintPreview && !showSubmitPreview) {
          const { message: vscOrGapMessage, isInvalid: isVscOrGapInvalid } = getVscOrGapLimit({
            fieldName: name,
            vsc,
            gap,
            t,
            guidebookValue,
            isVscInvalid,
            isGapInvalid,
            regionControls,
            setIsVscInvalid,
            setIsGapInvalid,
          });

          message = vscOrGapMessage;
          isInvalid = isVscOrGapInvalid;
        }

        if (name === LOAN_VARIABLES_FIELDS_NAMES.CURRENT_PAYMENT) {
          isInvalid = !isExtraCashAvailable;
        }

        if (name === LOAN_VARIABLES_FIELDS_NAMES.GUIDEBOOK_VALUE) {
          if (!user?.isWithInventory || manuallyCreated) {
            inputLabel = t('components.loanVariablesModal.fields.bookValue');
          }
        }

        if (isMileage && mileageErrorMessage) {
          message = mileageErrorMessage;
          isInvalid = true;
        }

        const changeCallback = handleChangeCallback(name);
        const blurCallback = isFieldChanged(nameKey) ? setHistoryCallback : undefined;
        const isDisabled = () => {
          if (isLender) {
            return (
              (name === LOAN_VARIABLES_FIELDS_NAMES.GAP && !lenderControls.maxGap) ||
              (name === LOAN_VARIABLES_FIELDS_NAMES.VSC && !lenderControls.maxVSC) ||
              Boolean(controlProps?.disabled)
            );
          } else {
            return (
              (name === LOAN_VARIABLES_FIELDS_NAMES.GAP && !regionControls.gapLimit) || Boolean(controlProps?.disabled)
            );
          }
        };

        return (
          <FormRow
            {...fieldWidth}
            key={name}
            required={!!controlProps?.required}
            label={inputLabel}
            isVerticalAlign={isVerticalAlign}
            name={name}
            control={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <>
                <Component
                  {...controlProps}
                  options={options}
                  name={name}
                  textLabel={textLabel}
                  endAdornmentOnClick={adornmentOnClick}
                  blurCallback={blurCallback}
                  changeCallback={changeCallback}
                  isInvalid={name === LOAN_VARIABLES_FIELDS_NAMES.GUIDEBOOK_VALUE ? !isBookVerified : isInvalid}
                  clickCallback={mapOnClickFunctionByFieldName.get(name)}
                  disabled={isDisabled()}
                  className={
                    areVscLimitsValid &&
                    [
                      LOAN_VARIABLES_FIELDS_NAMES.MILEAGE,
                      LOAN_VARIABLES_FIELDS_NAMES.REBATES,
                      LOAN_VARIABLES_FIELDS_NAMES.TOTAL_SALES_FEES,
                    ].includes(name)
                      ? 'mg-btm'
                      : undefined
                  }
                />
                {(isVscOrGap || isMileage) && (
                  <FormControlFeedback type="invalid" className="d-block" $isInvalid={isInvalid}>
                    {message}
                  </FormControlFeedback>
                )}
              </>
            }
          />
        );
      });
    },
    [
      manuallyCreated,
      lenderControls,
      isLender,
      loanTermsList,
      mapOnClickFunctionByFieldName,
      currentVehicleData?.guidebook,
      loanVariables?.guidebook,
      regionControls,
      showPrintPreview,
      showSubmitPreview,
      user?.isWithInventory,
      handleChangeCallback,
      isFieldChanged,
      setHistoryCallback,
      isBookVerified,
      getVscOrGapLimit,
      vsc,
      gap,
      t,
      guidebookValue,
      isVscInvalid,
      isGapInvalid,
      isExtraCashAvailable,
      mileageErrorMessage,
    ],
  );

  const generatedFields = useMemo(
    () => ({
      valuationLabels: generateFields(RAC_MW_VEHICLE_VALUATION_LABELS),
      vehicleValuationFields: generateFields(RAC_MW_VEHICLE_VALUATION_FIELDS),
      financingTermsFields: generateFields(RAC_MW_FINANCING_TERMS_FIELDS),
      financingTermsLabels: generateFields(RAC_MW_FINANCING_TERMS_LABELS),
      vehicleEnhancementsFields: generateFields(RAC_MW_VEHICLE_ENHANCEMENTS_FEILDS),
      vehicleEnhancementsLabels: generateFields(RAC_MW_VEHICLE_ENHANCEMENTS_LABELS),
      transactionCostsFields: generateFields(RAC_MW_TRANSACTION_COSTS_FIELDS),
      transactionCostsLabels: generateFields(RAC_MW_TRANSACTION_COSTS_LABELS),
    }),
    [generateFields],
  );

  const onSalesFeesCalculatorSubmit = useCallback(
    (data: ISalesFeesForm) => {
      dispatch(setSalesFees(data));
    },
    [dispatch],
  );

  const onNetTradeInCalculatorSubmit = useCallback(
    (data: INetTradeInForm) => {
      dispatch(setNetTradeIn(data));
    },
    [dispatch],
  );

  const onCheckValues = (isPrint: boolean = false) => {
    if (!confirmBook) {
      setError(LOAN_VARIABLES_FIELDS_NAMES.CONFIRM_BOOK, { message: 'error' });
    }

    if (!confirmTaxes) {
      setError(LOAN_VARIABLES_FIELDS_NAMES.CONFIRM_TAXES, { message: 'error' });
    }

    if (!paymentErrorMessage) {
      confirmBook && confirmTaxes && (isPrint ? setShowPrintPreview(true) : setShowSubmitPreview(true));
    }

    dispatch(resetRouteOneDataFlags());
  };

  const handleVerifyDealClick = (isPrint: boolean = false) => {
    clearErrors(LOAN_VARIABLES_FIELDS_NAMES.CONFIRM_TAXES);
    clearErrors(LOAN_VARIABLES_FIELDS_NAMES.CONFIRM_BOOK);
    clearErrors(LOAN_VARIABLES_FIELDS_NAMES.CONFIRM_SUBMIT);

    handleSubmit(() => onCheckValues(isPrint))();
  };

  const handleUndo = useCallback(() => {
    if (formHistory.length > 1) {
      const previousState = formHistory[formHistory.length - 2] as any;
      const price = previousState.loanVariablesForm.price;

      setToggled(price !== LOAN_VARIABLES_FIELDS_NAMES.RETAIL_PRICE);

      setFormHistory(formHistory.slice(0, formHistory.length - 1));
      setLoanVariables({ ...loanVariables, ltv_categories: previousState.loanVariablesForm.ltvCategories });
      setLoanTermsList(previousState.loanVariablesForm.loanTermsList);
      setIsUndoClicked(true);
      reset(previousState);
    }
  }, [formHistory, loanVariables, reset, setFormHistory, setLoanTermsList, setLoanVariables]);

  const getColumns = (): TableColumn[] => {
    if (isLender) {
      let columns = LENDER_LOAN_VARIABELS_COLUMNS as TableColumn[];

      if (!lenderControls.showRates)
        columns = columns.filter((column) => LENDER_LOAN_VARIABLES_CUSTOMER_RATE.id !== column.id);

      columns = columns.filter((column) =>
        ltvTaxesFeesFilter === 'true'
          ? column.id !== LOAN_VARIABLES_MODAL_FIELDS.LTV_EXC
          : column.id !== LOAN_VARIABLES_MODAL_FIELDS.LTV_INC,
      );

      return columns;
    }

    let columns = [
      ...(LOAN_VARIABLES_COLUMNS_MAP.get(price || LOAN_VARIABLES_FIELDS_NAMES.RETAIL_PRICE) as TableColumn[]),
    ];

    if (!user?.isWithInventory) {
      columns = columns.filter(
        (column) =>
          ![LOAN_VARIABLES_MODAL_FIELDS.PROFIT, LOAN_VARIABLES_MODAL_FIELDS.PAYMENT_TERMS].includes(column.id),
      );
    }

    if (manuallyCreated) {
      columns = columns.filter((column) => LOAN_VARIABLES_MODAL_FIELDS.PROFIT !== column.id);
    }

    if (columns[1].id === LOAN_VARIABLES_MODAL_FIELDS.EXTRA_CASH) {
      columns[1].Header = isExtraCashAvailable ? (
        t('components.loanTermTable.columns.extraCashOrDiscount')
      ) : (
        <span style={{ color: COLORS.RED_4 }}>*{t('components.loanTermTable.columns.extraCashOrDiscount')}</span>
      );
    }

    return columns;
  };

  const sendRouteOneData = useCallback(async () => {
    const data: ISendRouteOneData = {
      approved_monthly_payment: payment,
      cash_down_payment: cashDown,
      rebates: rebates,
      sales_fees: salesFeesForm.salesDocumentFees,
      add_ons: addOns,
      current_price: retailPrice,
      odometer: mileage,
      guidebook_value: guidebookValue,
      loan_term: loanTerm.value,
      vsc: vsc,
      gap: gap,
      taxes: taxes,
      title_reg_license: salesFeesForm.titleRegistrationLicense,
      vin: vin,
      apr: apr,
      year: netTradeInForm.year?.value === OTHER_OPTION.value ? netTradeInForm.otherYear : netTradeInForm.year?.value,
      make: netTradeInForm.make?.value === OTHER_OPTION.value ? netTradeInForm.otherMake : netTradeInForm.make?.value,
      model:
        netTradeInForm.model?.value === OTHER_OPTION.value ? netTradeInForm.otherModel : netTradeInForm.model?.value,
      primary_monthly_income: monthlyIncomeForm.income.primaryMonthlyIncome,
      primary_other_monthly_income: monthlyIncomeForm.income.primaryOtherMonthlyIncome,
      coApplicant_monthly_income: monthlyIncomeForm.income.coApplicantMonthlyIncome,
      coApplicant_other_monthly_income: monthlyIncomeForm.income.coApplicantOtherMonthlyIncome,
      vehicle_id: vehicle_id,
    };

    if (!confirmSubmit) {
      setError(LOAN_VARIABLES_FIELDS_NAMES.CONFIRM_SUBMIT, { message: 'Error' });
    }

    if (netTradeInForm.lienHolder) {
      data.lien_holder = netTradeInForm.lienHolder;
    }

    if (netTradeInForm.tradeInValue) {
      data.value = netTradeInForm.tradeInValue;
    }

    if (netTradeInForm.amountOwnedOnTrade) {
      data.amount_owed = netTradeInForm.amountOwnedOnTrade;
    }

    if (confirmSubmit) {
      dispatch(postRouteOneDataThunk(data));
    }
  }, [
    payment,
    cashDown,
    rebates,
    salesFeesForm.salesDocumentFees,
    salesFeesForm.titleRegistrationLicense,
    addOns,
    retailPrice,
    mileage,
    guidebookValue,
    loanTerm.value,
    vsc,
    gap,
    taxes,
    vin,
    apr,
    netTradeInForm.year?.value,
    netTradeInForm.otherYear,
    netTradeInForm.make?.value,
    netTradeInForm.otherMake,
    netTradeInForm.model?.value,
    netTradeInForm.otherModel,
    netTradeInForm.lienHolder,
    netTradeInForm.tradeInValue,
    netTradeInForm.amountOwnedOnTrade,
    monthlyIncomeForm.income.primaryMonthlyIncome,
    monthlyIncomeForm.income.primaryOtherMonthlyIncome,
    monthlyIncomeForm.income.coApplicantMonthlyIncome,
    monthlyIncomeForm.income.coApplicantOtherMonthlyIncome,
    vehicle_id,
    confirmSubmit,
    setError,
    dispatch,
  ]);

  const handleSubmitClick = () => {
    clearErrors(LOAN_VARIABLES_FIELDS_NAMES.CONFIRM_SUBMIT);

    handleSubmit(sendRouteOneData)();
  };

  useEffect(() => {
    const maxValue = maxPaymentValue();
    let errorMessage;

    if (payment > maxValue) {
      if (isFieldChanged('monthlyIncome')) {
        setValue(LOAN_VARIABLES_FIELDS_NAMES.PAYMENT, maxValue);
      } else {
        errorMessage = t('components.loanVariablesModal.errorMessages.maxPaymentIs', { maxPaymentRange: maxValue });
      }
    }

    setPaymentErrorMessage(errorMessage);
  }, [setValue, isFieldChanged, maxPaymentValue, payment, t]);

  const defaultParticipation = (commonGetValues().tier as any).max_participation as number;

  useEffect(() => {
    if (isLender) {
      const tierTerms: ILenderTierTerm[] = (commonGetValues().tier as any).tier_terms;

      const overrideParticipationArray = tierTerms
        .filter(
          (term) =>
            term.term_in_months === loanTerm.value &&
            term.model_year === Number(currentVehicleData?.description.split(' ')[0]),
        )
        .map((term) => term.override_participation);

      const maxValue = overrideParticipationArray.length > 0 ? Math.max(...overrideParticipationArray) : 0;

      if (!maxValue) return;

      let errorMessage;

      if (participation > maxValue) {
        errorMessage = t('components.loanVariablesModal.errorMessages.maxParticipationIs', {
          maxParticipation: maxValue,
        });
      }

      setMaxParticipation(maxValue);
      setParticipationErrorMessage(errorMessage);
    }
  }, [commonGetValues, currentVehicleData?.description, isLender, loanTerm.value, participation, t]);

  useEffect(() => {
    if (isLender) {
      const loanTerm = requestParams.loan_term;

      let errorMessage = '';

      if (!loanTerm) {
        errorMessage = t('components.loanVariablesModal.errorMessages.exceedsMaxMileage');
      }

      setMileageErrorMessage(errorMessage);
    }
  }, [isLender, requestParams, t]);

  useEffect(() => {
    if (isUndoClicked && formHistory.length === 1) {
      setIsUndoClicked(false);
    }
  }, [formHistory.length, isUndoClicked]);

  const getModalFooterClassName = (
    routeOneDataFlags: IRouteOneDataFlags,
    showPrintPreview: boolean,
    showSubmitPreview: boolean,
  ) => {
    if (routeOneDataFlags.isLoading) {
      return 'd-flex justify-content-center';
    }

    if (showPrintPreview || showSubmitPreview) {
      return 'd-flex justify-content-end';
    }

    return 'd-block';
  };

  return (
    <FormProvider {...formMethods}>
      <ModalBackdrop
        showBackdrop={
          isShowExitConfirmModal ||
          isModalSalesFeesDialogActive ||
          isIncomeDialogActive ||
          isNetTradeInDialogOpen ||
          showAddVehicleDialog
        }
      />
      <Modal.Header closeButton>
        <Title $uppercase={uppercaseTitle}>{headerTitle()}</Title>
        {!showPrintPreview && !showSubmitPreview && <TradeMark>{t('common.symbols.tradeMark')}</TradeMark>}
      </Modal.Header>
      {!(showPrintPreview || showSubmitPreview) && (
        <Modal.Header>
          <HeaderActionsBlock>
            <Col>
              <div className="d-flex align-items-center">
                <ButtonUndoBlock onClick={handleUndo} disabled={formHistory.length === 1}>
                  <ButtonUndo />
                  <ButtonUndoLabel>{t('components.loanVariablesModal.buttons.undo')}</ButtonUndoLabel>
                </ButtonUndoBlock>
                <PrintContainer>
                  <ButtonPrint
                    onClick={() => handleVerifyDealClick(true)}
                    className="d-flex float-right button-print"
                    disabled={(isUndoClicked ? true : necessaryDirtyFields.length > 0) || requiredFieldsInvalid}
                  />
                </PrintContainer>
              </div>
            </Col>
            {/* SWA-5628 Temporary hide: Lender Pricing/Market Pricing toggle from RAC page with Market Pricing state by default. */}
            {/* <CustomToggleSwitch
                  handleClick={handlePriceClick}
                  isToggled={isToggled}
                  trueTitle={t('components.loanVariablesModal.prices.adjustedPrice')}
                  falseTitle={t('components.loanVariablesModal.prices.retailPrice')}
                /> */}
          </HeaderActionsBlock>
        </Modal.Header>
      )}
      <FormProvider {...formMethods}>
        <Content ref={componentRef}>
          <LoanVariablesFields
            columns={getColumns()}
            loanVariables={loanVariables}
            showPrintPreview={showPrintPreview}
            showSubmitPreview={showSubmitPreview}
            setHistoryCallback={setHistoryCallback}
            generateFields={generateFields}
            generatedFields={generatedFields}
            paymentErrorMessage={paymentErrorMessage}
            participationErrorMessage={participationErrorMessage}
            defaultParticipation={defaultParticipation}
            maxParticipation={maxParticipation}
            tier={tierName}
            maxPaymentValue={maxPaymentValue()}
            calculateTotalAmountFinanced={handleCalculateTotalAmountFinancedClick}
            isRecalculateButtonDisabled={
              (isUndoClicked ? false : necessaryDirtyFields.length < 1) ||
              isFetchingLoanVariablesInfo ||
              isVscInvalid ||
              isGapInvalid
            }
            isFetching={isFetchingLoanVariablesInfo}
            isFieldChanged={isFieldChanged}
            manuallyCreated={manuallyCreated}
            isLender={isLender}
          />
          {isIncomeDialogActive && (
            <LendersMonthlyIncomeModal
              show={isIncomeDialogActive}
              onHide={handleIncomeDialogClose()}
              setFormHistory={setFormHistory}
              initialValues={monthlyIncomeForm}
              modalName={LOAN_VARIABLES_FIELDS_NAMES.MONTHLY_INCOME_FORM}
              totalName={LOAN_VARIABLES_FIELDS_NAMES.MONTHLY_INCOME}
              verticallyCentered
            />
          )}
          {isModalSalesFeesDialogActive && (
            <SaleFeesCalculator
              title={t('components.loanVariablesModal.fields.salesFees')}
              onHide={handleIncomeDialogClose()}
              show={isModalSalesFeesDialogActive}
              modalSize={MODAL_DIALOG_WIDTH.CUSTOM.PX_500}
              totalFieldLabel={t('components.loanVariablesModal.modals.salesFees.fields.totalFees')}
              totalWidthField={ColumnWidth.S}
              calculationFields={CALCULATION_FIELDS}
              onSalesFeesCalculatorSubmit={onSalesFeesCalculatorSubmit}
              totalName={LOAN_VARIABLES_FIELDS_NAMES.TOTAL_SALES_FEES}
              formValuesName={LOAN_VARIABLES_FIELDS_NAMES.SALES_FEES_FORM}
              setFormHistory={setFormHistory}
            />
          )}
          {isNetTradeInDialogOpen && (
            <NetTradeInCalculator
              title={t('components.loanVariablesModal.fields.netTradeIn')}
              onHide={handleCloseNetTradeInDialog()}
              show={isNetTradeInDialogOpen}
              modalSize={MODAL_DIALOG_WIDTH.CUSTOM.PX_425}
              totalFieldLabel={t('components.loanVariablesModal.modals.netTradeIn.fields.totalNetTradeIn')}
              fieldWidth={ColumnWidth.M}
              calculationFields={NET_TRADE_IN_CALCULATION_FIELDS}
              onNetTradeInCalculatorSubmit={onNetTradeInCalculatorSubmit}
              totalName={LOAN_VARIABLES_FIELDS_NAMES.NET_TRADE_IN}
              formValuesName={LOAN_VARIABLES_FIELDS_NAMES.NET_TRADE_IN_FORM}
              setFormHistory={setFormHistory}
              recalculateTaxes={handleRecalculateTaxesClick}
            />
          )}
          {user?.is_sso && (
            <AddVehicleDialog
              tier={tierName}
              show={showAddVehicleDialog}
              onHide={() => {
                setShowAddVehicleDialog(false);
              }}
              onHideAnotherModal={handleHide}
              backdrop={false}
              fromModal={true}
            />
          )}
        </Content>
        <Modal.Footer className={getModalFooterClassName(routeOneDataFlags, showPrintPreview, showSubmitPreview)}>
          {!isExtraCashAvailable && (
            <ExtraCashUnavailableWarn>
              {t('components.loanVariablesModal.warningMessages.extraCashUnavailable')}
            </ExtraCashUnavailableWarn>
          )}
          {showPrintPreview && (
            <>
              <ModalButton buttontheme={ButtonVariant.OUTLINE} onClick={() => setShowPrintPreview(false)}>
                {t('components.loanVariablesModal.buttons.back')}
              </ModalButton>
              <ReactToPrint
                trigger={() => (
                  <ModalButton buttontheme={ButtonVariant.CONTAINED} onClick={() => setShowPrintPreview(false)}>
                    {t('components.loanVariablesModal.buttons.print')}
                  </ModalButton>
                )}
                content={() => componentRef.current}
                pageStyle={PAGE_STYLE}
              />
            </>
          )}
          {showSubmitPreview && (
            <SubmitButtonsBlock isMakeItWork={isMakeItWork}>
              {routeOneDataFlags.isLoading && <Loader isWithMargins={false} />}
              <div>
                {routeOneDataFlags?.message.length > 0 && (
                  <SubmitMessageBlock
                    width={routeOneDataFlags.isInvalid ? '650' : '350'}
                    isInvalid={routeOneDataFlags.isInvalid}
                  >
                    {routeOneDataFlags.message}
                  </SubmitMessageBlock>
                )}
                {isMakeItWork && routeOneDataFlags?.isInvalid === undefined && (
                  <div>{generateFields([RAC_MW_CONFIRM_SUBMIT_FIELD])}</div>
                )}
                {(routeOneDataFlags?.isInvalid === true ||
                  routeOneDataFlags?.isInvalid === undefined ||
                  !isMakeItWork) && (
                  <div className="d-flex justify-content-end">
                    <ModalButton
                      buttontheme={ButtonVariant.OUTLINE}
                      onClick={() => setShowSubmitPreview(false)}
                      width={'120px'}
                      $marginRight={'14px'}
                      disabled={routeOneDataFlags.isLoading}
                    >
                      {t('components.loanVariablesModal.buttons.back')}
                    </ModalButton>
                    {isMakeItWork && (
                      <ModalButton
                        buttontheme={ButtonVariant.CONTAINED}
                        onClick={handleSubmitClick}
                        width={'15rem'}
                        disabled={routeOneDataFlags.isLoading}
                      >
                        {t('components.loanVariablesModal.buttons.submitDeal')}
                      </ModalButton>
                    )}
                    {!isMakeItWork && (
                      <ReactToPrint
                        trigger={() => (
                          <ModalButton buttontheme={ButtonVariant.CONTAINED} onClick={() => setShowPrintPreview(false)}>
                            {t('components.loanVariablesModal.buttons.print')}
                          </ModalButton>
                        )}
                        content={() => componentRef.current}
                        pageStyle={PAGE_STYLE}
                      />
                    )}
                  </div>
                )}
              </div>
            </SubmitButtonsBlock>
          )}
          {!showPrintPreview && !showSubmitPreview && (
            <div className="d-flex justify-content-between">
              {user?.is_sso ? (
                <ModalButton
                  buttontheme={ButtonVariant.OUTLINE}
                  width={'132px'}
                  onClick={() => setShowAddVehicleDialog(true)}
                >
                  {t('addVehicleModal.button')}
                </ModalButton>
              ) : (
                <div></div>
              )}
              <div>
                {((!user?.isWithInventory && (vehiclesByParams?.length || 0) > 1) || user?.isWithInventory) && (
                  <ModalButton
                    buttontheme={ButtonVariant.OUTLINE}
                    width={'160px'}
                    $marginRight={'7px'}
                    onClick={() =>
                      formHistory.length > 1 ? setIsShowExitConfirmModal(true) : handleHideWithResetVehicle()
                    }
                  >
                    {t('components.loanVariablesModal.buttons.changeVehicle')}
                  </ModalButton>
                )}
                <ModalButton
                  buttontheme={ButtonVariant.CONTAINED}
                  onClick={() => handleVerifyDealClick()}
                  disabled={
                    (isUndoClicked ? true : necessaryDirtyFields.length > 0) ||
                    !isExtraCashAvailable ||
                    isVscInvalid ||
                    isGapInvalid ||
                    !isParticipationValid
                  }
                >
                  {t('components.loanVariablesModal.buttons.verifyDeal')}
                </ModalButton>
              </div>
            </div>
          )}
        </Modal.Footer>
      </FormProvider>
    </FormProvider>
  );
};
