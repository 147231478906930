export const UNLOCK_INVENTORY_FORM_PREFIX = 'unlockInventoryForm';

export const UNLOCK_INVENTORY_FIELDS_NAMES = {
  PHONE_NUMBER: `${UNLOCK_INVENTORY_FORM_PREFIX}.phoneNumber`,
  BILLING_CONTACT: `${UNLOCK_INVENTORY_FORM_PREFIX}.billingContact`,
  FULL_NAME: `${UNLOCK_INVENTORY_FORM_PREFIX}.fullName`,
  EMAIL: `${UNLOCK_INVENTORY_FORM_PREFIX}.email`,
  CONFIRM_PRICE: `${UNLOCK_INVENTORY_FORM_PREFIX}.confirmPrice`,
  CONFIRM_TERMS: `${UNLOCK_INVENTORY_FORM_PREFIX}.confirmTerms`,
};
