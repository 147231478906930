import React, { FC, useCallback, useMemo } from 'react';
import { Controller, ControllerRenderProps, Validate } from 'react-hook-form';
import { IFormField } from 'modules/core/types';
import { useUncontrolledInput } from 'modules/core/hooks';
import { requiredValidator } from 'modules/core/validators';

import { DefaultToggle, InputGroupUI, Menu, MenuItem, RacDropdown } from './styles';

interface IDropdown extends IFormField {
  placeholder?: string;
  options?: any[];
  name: string;
  required?: boolean;
  disabled?: boolean;
  isValid?: boolean;
  initialValue?: any;
  showPlaceholder?: boolean;
  rules?: any;
  changeCallback?: (() => void) | undefined;
  validate?: Validate | Record<string, Validate> | undefined;
}

export const Dropdown: FC<IDropdown> = ({
  name,
  options,
  placeholder,
  disabled = false,
  showPlaceholder,
  rules,
  changeCallback,
  validate,
}) => {
  const { controlField, registerInput, errorMessage, hasError } = useUncontrolledInput(name);

  const renderOptions = useCallback(
    ({ onChange }: ControllerRenderProps) =>
      options?.map((option: { value: string; title: string }) => (
        <MenuItem
          onClick={() => {
            onChange(option);
          }}
          onBlur={() => {
            changeCallback && changeCallback();
          }}
          key={option.value}
          title={option.title}
        >
          {option.title}
        </MenuItem>
      )),
    [changeCallback, options],
  );

  const commonInputProps = useMemo(
    () => ({
      name,
      $isInvalid: Boolean(errorMessage) || hasError,
      disabled: disabled,
    }),
    [disabled, errorMessage, hasError, name],
  );

  const renderDropdown = useCallback(
    (renderProps) => (
      <InputGroupUI $isPlaceholder={!renderProps?.value?.title || showPlaceholder} {...commonInputProps}>
        <RacDropdown disabled={disabled} ref={registerInput(requiredValidator)} name={name}>
          <DefaultToggle {...commonInputProps} title={renderProps?.value?.title || placeholder}>
            {renderProps?.value?.title || placeholder}
          </DefaultToggle>
          <Menu>{renderOptions(renderProps)}</Menu>
        </RacDropdown>
      </InputGroupUI>
    ),
    [disabled, registerInput, name, showPlaceholder, commonInputProps, placeholder, renderOptions],
  );

  return <Controller name={name} render={renderDropdown} control={controlField} rules={{ ...rules, validate }} />;
};
