import i18n from 'i18next';
import { FEES_AND_TAXES_INITIAL_VALUES, PREFERENCES_PE_INITIAL_VALUES } from 'modules/core/shared';
import {
  FILTERS_FIELD_NAMES,
  VEHICLE_FILTERS_INITIAL_VALUES,
} from 'modules/core/components/VehicleFilters/vehicleFilters.const';
import { TableView } from 'modules/core/enums';
import { INCOME_INITIAL_VALUES } from 'components/IncomeCalculator/const/initial.const';
import { LENDERS_MOTHLY_INCOME_DEFAULT_VALUES } from 'modules/RACAffordabilityEngine/RacMontlyIncomeModal/consts/defaultValues';
import { INITIAL_PAGE_SIZE } from 'modules/core/components/Table/table.const';

import { CreditScoreColors } from './enums/creditScoreColors.enum';
import { CreditScore, IProbabilityEstimatorForm } from './types';
import { FilterView } from '../core/enums/filterView.enum';

export const DEFAULT_INCOME_RATIO = 0;
export const MINIMAL_PAYMENT_RANGE = 0;
export const DEFAULT_MAXIMAL_PAYMENT_RANGE = 5000;

export const NET_DOWN_PAYMENT_INDEX_FIELD = 'totalNetDownPaymentDialog';

export const NET_DOWN_PAYMENT_FIELD_NAMES = {
  CASH_DOWN: `${NET_DOWN_PAYMENT_INDEX_FIELD}.cashDown`,
  TRADE_VALUE: `${NET_DOWN_PAYMENT_INDEX_FIELD}.tradeValue`,
  AMOUNT_OWED: `${NET_DOWN_PAYMENT_INDEX_FIELD}.amountOwed`,
  TOTAL_NET_DOWN: `${NET_DOWN_PAYMENT_INDEX_FIELD}.totalNetDownPayment`,
  LIEN_HOLDER: `${NET_DOWN_PAYMENT_INDEX_FIELD}.lienHolder`,
  YEAR: `${NET_DOWN_PAYMENT_INDEX_FIELD}.year`,
  MAKE: `${NET_DOWN_PAYMENT_INDEX_FIELD}.make`,
  MODEL: `${NET_DOWN_PAYMENT_INDEX_FIELD}.model`,
  OTHER_YEAR: `${NET_DOWN_PAYMENT_INDEX_FIELD}.otherYear`,
  OTHER_MAKE: `${NET_DOWN_PAYMENT_INDEX_FIELD}.otherMake`,
  OTHER_MODEL: `${NET_DOWN_PAYMENT_INDEX_FIELD}.otherModel`,
};

export const NET_DOWN_PAYMENT_VALUES = {
  cashDown: 0,
  tradeValue: 0,
  amountOwed: 0,
  lienHolder: '',
  year: undefined,
  make: undefined,
  model: undefined,
  otherYear: undefined,
  otherMake: '',
  otherModel: '',
};

export const PROBABILITY_ESTIMATOR_FIELDS = {
  DESIRED_MONTHLY_PAYMENT: 'desiredMonthlyPayment',
  MAXIMAL_PAYMENT_RANGE: 'maximalPaymentRange',
  GROSS_MONTHLY_INCOME: 'grossMonthlyIncome',
  NET_DOWN_PAYMENT: 'netDownPayment',
  CREDIT_SCORE: 'creditScore',
  TABLE_VIEW: 'tableView',
  SELECTED_COLUMNS: 'selectedColumns',
  STOCKS: 'stocks',
  FILTER_VIEW: 'filterView',
  ARE_DEFAULT_COLUMNS_SELECTED: 'areDefaultColumnsSelected',
  LIMIT: 'limit',
};

export const INITIAL_VALUES: IProbabilityEstimatorForm = {
  feesAndTaxes: FEES_AND_TAXES_INITIAL_VALUES,
  preferences: PREFERENCES_PE_INITIAL_VALUES,
  vehicleFilters: VEHICLE_FILTERS_INITIAL_VALUES,
  totalNetDownPaymentDialog: NET_DOWN_PAYMENT_VALUES,
  tableView: TableView.SALESPERSON,
  filterView: FilterView.BY_PARAMS,
  stocks: [],
  grossMonthlyIncome: undefined,
  desiredMonthlyPayment: 0,
  netDownPayment: undefined,
  vehicleTableAccordion: true,
  creditScore: {
    id: 1,
    color: CreditScoreColors.DEFAULT,
    interest_rate: 0,
    loan_score: 'prime',
    ltv_limit: 0,
    max_allowed_payment: 0,
    max_allowed_pti: 0,
    max_green_payment: 0,
    max_green_pti: 0,
    max_yellow_payment: 0,
    max_yellow_pti: 0,
  } as CreditScore,
  lendersMonthlyIncomeModal: LENDERS_MOTHLY_INCOME_DEFAULT_VALUES,
  income: { totalIncome: 0, savedIncomes: INCOME_INITIAL_VALUES },
  isRequestNeeded: {
    byParams: true,
    byStock: true,
  },
  condition: 'all',
  limit: INITIAL_PAGE_SIZE,
};

export const VEHICLES_COUNT_FIELDS_SUBSCRIPTION = [
  PROBABILITY_ESTIMATOR_FIELDS.GROSS_MONTHLY_INCOME,
  PROBABILITY_ESTIMATOR_FIELDS.CREDIT_SCORE,
  PROBABILITY_ESTIMATOR_FIELDS.DESIRED_MONTHLY_PAYMENT,
  PROBABILITY_ESTIMATOR_FIELDS.TABLE_VIEW,
  PROBABILITY_ESTIMATOR_FIELDS.STOCKS,
  PROBABILITY_ESTIMATOR_FIELDS.FILTER_VIEW,
  ...Object.values(FILTERS_FIELD_NAMES),
];

export const DEFAULT_PLACEHOLDER_TITLE: string = i18n.t('components.probabilityEstimator.defaultPlaceholderTitle');
