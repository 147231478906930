import { IOption } from 'modules/core/types';
import { getFirstOptionValue } from 'modules/core/utils';
import {
  IPreferencesForm,
  IProbabilityEstimatorPreferencesForm,
  IRACPreferencesForm,
} from 'modules/core/types/preferences';
import i18n from 'i18n';

export const PREFERENCES_OPTION_VALUES = {
  VENDOR_FILTER: {
    VENDOR_TRADE: 'vendor_trade',
    VENDOR_RETAIL: 'vendor_retail',
    KBB_LENDING_VALUE: 'kbb_lending_value',
    KBB_TYPICAL_LISTING: 'kbb_typical_listing',
    BLACK_BOOK_FINANCE_ADVANCE: 'black_book_finance_advance',
    BLACK_BOOK_WHOLESALE: 'black_book_wholesale',
    CPO: '_cpo',
  },
  LTV_TAXES: {
    LTV_INC: 'true',
    LTV_EXC: 'false',
  },
  PRICE: {
    PRICE_BASIS: 'true',
    OVERRIDER_PRICE: 'false',
  },
};

export const RAC_PREFERENCES_OPTION_VALUES = {
  ...PREFERENCES_OPTION_VALUES,
  PRICE: {
    PRICE_BASIS: 'false',
    OVERRIDER_PRICE: 'true',
  },
};

export const COMMON_VENDOR_FILTER_OPTIONS: IOption[] = [
  {
    title: i18n.t('components.preferences.options.vendor.blackBookWholesale'),
    value: PREFERENCES_OPTION_VALUES.VENDOR_FILTER.BLACK_BOOK_WHOLESALE,
  },
  {
    title: i18n.t('components.preferences.options.vendor.cpo'),
    value: PREFERENCES_OPTION_VALUES.VENDOR_FILTER.CPO,
  },
];

export const JDP_VENDOR_FILTER_OPTIONS: IOption[] = [
  {
    title: i18n.t('components.preferences.options.vendor.vendorTradeJDP'),
    value: PREFERENCES_OPTION_VALUES.VENDOR_FILTER.VENDOR_TRADE,
  },
  {
    title: i18n.t('components.preferences.options.vendor.vendorRetailJDP'),
    value: PREFERENCES_OPTION_VALUES.VENDOR_FILTER.VENDOR_RETAIL,
  },
  ...COMMON_VENDOR_FILTER_OPTIONS,
];

export const KBB_VENDOR_FILTER_OPTIONS: IOption[] = [
  {
    title: i18n.t('components.preferences.options.vendor.kbbLendingValue'),
    value: PREFERENCES_OPTION_VALUES.VENDOR_FILTER.KBB_LENDING_VALUE,
  },
  {
    title: i18n.t('components.preferences.options.vendor.KbbTypicalListing'),
    value: PREFERENCES_OPTION_VALUES.VENDOR_FILTER.KBB_TYPICAL_LISTING,
  },
  ...COMMON_VENDOR_FILTER_OPTIONS,
];

export const LTV_TAXES_FEES_FILTER_OPTIONS: IOption[] = [
  {
    title: i18n.t('components.preferences.options.ltvTaxes.ltvInc'),
    value: PREFERENCES_OPTION_VALUES.LTV_TAXES.LTV_INC,
  },
  {
    title: i18n.t('components.preferences.options.ltvTaxes.ltvExc'),
    value: PREFERENCES_OPTION_VALUES.LTV_TAXES.LTV_EXC,
  },
];

const PREFERENCES_INDEX_FIELD = 'preferences';

export const PREFERENCES_FIELD_NAMES = {
  VENDOR_FIELD: `${PREFERENCES_INDEX_FIELD}.vendorField`,
  LTV_TAXES_FEES_FILTER: `${PREFERENCES_INDEX_FIELD}.ltvTaxesFeesFilter`,
};

export const PREFERENCES_PE_FIELD_NAMES = {
  ...PREFERENCES_FIELD_NAMES,
  LVT_HIGH_LIMIT: `${PREFERENCES_INDEX_FIELD}.ltvHighLimit`,
};

export const PREFERENCES_INITIAL_VALUES: IPreferencesForm = {
  vendorField: undefined,
  ltvTaxesFeesFilter: getFirstOptionValue(LTV_TAXES_FEES_FILTER_OPTIONS) as string,
};

export const PREFERENCES_PE_INITIAL_VALUES: IProbabilityEstimatorPreferencesForm = {
  ...PREFERENCES_INITIAL_VALUES,
  ltvHighLimit: 0,
};

export const PREFERENCES_LENDER_INITIAL_VALUES = PREFERENCES_PE_INITIAL_VALUES;

export const PREFERENCES_RAC_INITIAL_VALUES: IRACPreferencesForm = {
  ...PREFERENCES_INITIAL_VALUES,
  ltvHighLimit: 0,
};
