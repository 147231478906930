import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EndUserAgreement, PrivacyPolicy } from 'components/Modals';
import { getFooterDataThunk } from 'actions/controlActions';
import { AppDispatch } from 'store/store';
import { getUiState } from 'store/selectors/ui.selector';
import { useTranslation } from 'react-i18next';

import { FooterContainer, FooterLink } from './styles';

export const Footer: FC = () => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const [showEndUserAgreement, setShowEndUserAgreement] = useState(false);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const { version } = useSelector(getUiState);

  useEffect(() => {
    dispatch(getFooterDataThunk());
  }, [dispatch]);

  return (
    <FooterContainer>
      <div>
        <FooterLink onClick={() => setShowPrivacyPolicy(true)}>{t('components.privacyPolicy.label')}</FooterLink>
        <FooterLink onClick={() => setShowEndUserAgreement(true)}>{t('components.endUserAgreement.label')}</FooterLink>

        <EndUserAgreement show={showEndUserAgreement} onHide={() => setShowEndUserAgreement(false)} />
        <PrivacyPolicy show={showPrivacyPolicy} onHide={() => setShowPrivacyPolicy(false)} />
      </div>
      <div>{version && `v ${version}`}</div>
    </FooterContainer>
  );
};
