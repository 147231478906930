import i18n from 'i18next';
import { Input, Radio } from 'modules/core/components';
import { CalendarInput } from 'modules/core/components/CalendarInput';
import { IField, IOption } from 'modules/core/types';

import { IFieldControl } from '../types';
import { FIELDS_INFO } from './fieldsInfo.const';
import { FIELD_NAMES } from './fieldNames.const';
import { RADIO_VALUES } from './options.const';

const getAmountField = ({ label, name, required = true }: IFieldControl): IField => ({
  component: Input,
  label,
  name,
  controlProps: {
    rules: { required: required ? i18n.t('components.incomeCalculator.errorMessages.required') : undefined },
    decimalScale: 2,
    placeholder: i18n.t('components.input.placeholders.decimal'),
  },
});

export const AMOUNT_RECEIVED = {
  ...getAmountField(FIELDS_INFO.AMOUNT_RECEIVED),
};

export const GROSS_YTD = {
  ...getAmountField(FIELDS_INFO.GROSS_YTD),
};

export const HOURLY_RATE: IField = {
  ...FIELDS_INFO.HOURLY_RATE,
  component: Input,
  controlProps: {
    rules: { required: i18n.t('components.incomeCalculator.errorMessages.required') },
    decimalScale: 2,
    placeholder: i18n.t('components.input.placeholders.decimal'),
  },
};

export const HOURS_PER_WEEK: IField = {
  ...FIELDS_INFO.HOURS_PER_WEEK,
  component: Input,
  controlProps: {
    rules: { required: i18n.t('components.incomeCalculator.errorMessages.required') },
    decimalScale: 2,
    placeholder: i18n.t('components.input.placeholders.decimal'),
  },
};

export const NYS_DISABILITY_DEDUCTED = {
  ...getAmountField(FIELDS_INFO.NYS_DISABILITY_DEDUCTED),
};

export const PREVIOUS_YEAR_W2_AMOUNT = {
  ...getAmountField(FIELDS_INFO.PREVIOUS_YEAR_W2_AMOUNT),
};

const YES_NO_OPTIONS: IOption[] = [
  {
    title: i18n.t('common.buttons.yes'),
    value: RADIO_VALUES.YES,
  },
  {
    title: i18n.t('common.buttons.no'),
    value: RADIO_VALUES.NO,
  },
];

export const RADIO_SECTION: IField[] = [
  {
    ...FIELDS_INFO.LOANS_LISTED_ON_PAYCHECK,
    component: Radio,
    defaultValue: RADIO_VALUES.NO,
    controlProps: { options: YES_NO_OPTIONS, inline: true },
  } as IField,
  {
    ...FIELDS_INFO.GARNISHMENTS,
    component: Radio,
    defaultValue: RADIO_VALUES.NO,
    controlProps: { options: YES_NO_OPTIONS, inline: true },
  } as IField,
  {
    ...FIELDS_INFO.TEMPORARY_SERVICE_EMPLOYEE,
    component: Radio,
    defaultValue: RADIO_VALUES.NO,
    controlProps: { options: YES_NO_OPTIONS, inline: true },
  } as IField,
  {
    ...FIELDS_INFO.SEASONAL_EMPLOYEE,
    component: Radio,
    defaultValue: RADIO_VALUES.NO,
    controlProps: { options: YES_NO_OPTIONS, inline: true },
  } as IField,
];

export const DATE_OF_HIRE_IF_LESS_THAN_2_YEARS: IField = {
  component: CalendarInput,
  ...FIELDS_INFO.DATE_OF_HIRE,
  controlProps: { maxDate: new Date() },
};

export const PERIOD_ENDING_DATE: IField = {
  component: CalendarInput,
  ...FIELDS_INFO.PERIOD_ENDING_DATE,
  controlProps: {
    rules: { required: i18n.t('components.incomeCalculator.errorMessages.required') },
    maxDate: new Date(),
  },
};

export const CHECK_OR_PAY_DATE: IField = {
  component: CalendarInput,
  ...FIELDS_INFO.CHECK_OR_PAY_DATE,
  controlProps: { maxDate: new Date() },
};

export const AVG_FROM_TAX_RETURNS_COUNT_NUMBERS = [1, 2];

export const AVG_FROM_TAX_RETURNS_FIELDS = AVG_FROM_TAX_RETURNS_COUNT_NUMBERS.map((index) => ({
  ...getAmountField({
    label: i18n.t('components.incomeCalculator.labels.line31ScheduleCYear', { index }),
    name: `${FIELD_NAMES.LINE_31_SCHEDULE_C_YEAR}${index}`,
    required: Boolean(index === 1),
  }),
}));

export const AVG_FROM_DEPOSITS_FIELDS_COUNT_NUMBERS = [1, 2, 3, 4, 5, 6];

export const AVG_FROM_DEPOSITS_FIELDS = AVG_FROM_DEPOSITS_FIELDS_COUNT_NUMBERS.map((index) => ({
  ...getAmountField({
    label: i18n.t('components.incomeCalculator.labels.totalBankDeposits', { index }),
    name: `${FIELD_NAMES.TOTAL_BANK_DEPOSITS}${index}`,
    required: Boolean(index === 1),
  }),
}));
