import { useState, useEffect } from 'react';

export const useDebounceForLoading = (value: boolean): boolean => {
  const [debouncedValue, setDebouncedValue] = useState(true);
  const displayLoadingComponentsDelay = 100;

  useEffect(() => {
    let handler: NodeJS.Timeout;

    if (value === false) {
      handler = setTimeout(() => {
        setDebouncedValue(value);
      }, displayLoadingComponentsDelay);
    } else {
      setDebouncedValue(value);
    }

    return () => {
      clearTimeout(handler);
    };
  }, [value]);

  return debouncedValue;
};
