import React, { FC } from 'react';
import { MONEY_SYMBOL } from 'modules/core/constants';
import { thousandFormatter } from 'modules/core/utils';

import { Label, LabelSign, LabelContent } from './styles';
import { NUMBER_FORMAT_OPTIONS } from './moneyLabel.const';

interface IMoneyLabel {
  isColored?: boolean;
  dataTestId?: string;
  isInteger?: boolean;
}

export const MoneyLabel: FC<IMoneyLabel> = ({ isColored, dataTestId, children, isInteger }) => {
  const formattedValue = thousandFormatter(children as number, isInteger ? undefined : NUMBER_FORMAT_OPTIONS);

  return (
    <Label colored={isColored}>
      <LabelSign>{MONEY_SYMBOL}</LabelSign>
      <LabelContent data-testid={dataTestId}>{formattedValue}</LabelContent>
    </Label>
  );
};
