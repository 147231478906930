export const MODAL_DIALOG_WIDTH = {
  SM: '390px',
  MD: '720px',
  FULL_SCREEN: '100%',
  AUTO: 'auto',

  CUSTOM: {
    PX_425: '425px',
    PX_500: '500px',
    PX_613: '613px',
    PX_650: '650px',
    PX_750: '750px',
  },
};

export const MODAL_DIALOG_ID = {
  NET_DOWN_PAYMENT: 'netDownPayment',
  FRONT_AND_BACK_ENDS_ADDS: 'frontAndBackEndsAdds',
  RESET_FORM: 'resetForm',
  PREFERENCES: 'preferences',
  MY_ACCOUNT: 'myAccount',
  VEHICLE_DETAILS: 'vehicleDetails',
  VEHICLE_HISTORY: 'vehicleHistory',
  VEHICLE_ADJUSTMENT: 'vehicleAdjustment',
  SEND_REPORT: 'sendReport',
  INCOME_CALCULATOR: 'incomeCalculator',
  RAC_INCOME_CALCULATOR: 'racIncomeCalculator',
  LOAN_VARIABLES: 'loanVariables',
  SALES_FEES: 'salesFees',
  UNLOCK_INVENTORY: 'unlockInventory',
};

export const MODAL_DIALOG_WITH_NO_BTTNS = true;

export const MODAL_STYLES = {
  FULL_SCREEN: `
  .modal-dialog {
    margin: 0 auto;
    max-height: 100%;
  }

  .modal-content {
    margin: 0 auto;
    max-height: 100%;
  }
  `,
};
