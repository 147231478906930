import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getUser } from 'store/selectors/auth.selector';
import { IUser } from 'modules/core/types';

import { checkUserThunk } from '../actions/userActions';

export const useCheckAuthUser = (): IUser | null => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(getUser);

  const checkAuthUser = useCallback(async () => {
    if (!user) {
      await dispatch(checkUserThunk({ history: history }));
    }
  }, [dispatch, history, user]);

  useEffect(() => {
    checkAuthUser();
  }, [checkAuthUser]);

  return user;
};
