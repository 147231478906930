import { createAsyncThunk } from '@reduxjs/toolkit';
import { vehicleApi } from 'api';
import { RequestStatus } from 'modules/core/enums';
import {
  ALL_MAKES_OPTION,
  ALL_VEHICLES_OPTION,
  DOMESTIC_OPTION,
  IMPORT_OPTION,
} from 'modules/core/components/VehicleFilters/options.const';
import { toOptions } from 'modules/core/utils';
import { IPaginatedVehicles, IRACVehicle, IVehicle, IVehicleByStock } from 'modules/core/types';
import { ISendRouteOneData } from 'components/Modals/LoanVariablesDialog/types';

import { ACTION_PREFIX } from './actionPrefix.const';

export const getVehicleMakesThunk = createAsyncThunk(`${ACTION_PREFIX.DEALER}/makes`, async () => {
  const { data } = await vehicleApi.getVehicleMakes();

  return [
    ALL_MAKES_OPTION,
    DOMESTIC_OPTION,
    IMPORT_OPTION,
    ...data.map(({ name, make_type }) => ({
      title: name,
      value: name,
      make_type,
    })),
  ];
});

export const getVehicleModelsThunk = createAsyncThunk(`${ACTION_PREFIX.DEALER}/models`, async () => {
  const { data } = await vehicleApi.getVehicleModels();

  return [...data];
});

export const getVehicleBodiesThunk = createAsyncThunk(`${ACTION_PREFIX.VEHICLE}/getVehicleBodies`, async () => {
  const { data } = await vehicleApi.getVehicleBodies();

  return data;
});

export const getVehiclesPaginatedThunk = createAsyncThunk(
  `${ACTION_PREFIX.VEHICLE}/getVehicles`,
  async (vehicleParameters: Record<string, unknown>, thunkAPI) => {
    try {
      const { data } = await vehicleApi.getPaginatedVehicles(vehicleParameters);

      return data;
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const getVehiclesForProbabilityEstimatorThunk = createAsyncThunk(
  `${ACTION_PREFIX.VEHICLE}/getVehiclesForProbabilityEstimator`,
  async ({ ...vehicleParameters }: Record<string, unknown>, thunkAPI) => {
    try {
      const { payload } = await thunkAPI.dispatch(getVehiclesPaginatedThunk(vehicleParameters));

      if (payload instanceof Error) {
        throw payload;
      } else {
        return payload as IPaginatedVehicles;
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const getLoanTermsListThunk = createAsyncThunk(
  `${ACTION_PREFIX.VEHICLE}/getLoanTermsList`,
  async (params: Record<string, unknown>, thunkAPI) => {
    try {
      const { data } = await vehicleApi.getLoanTermsList(params);

      return data;
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const getLenderLoanTermsListThunk = createAsyncThunk(
  `${ACTION_PREFIX.VEHICLE}/getLenderLoanTermsList`,
  async (params: Record<string, unknown>, thunkAPI) => {
    try {
      const { data } = await vehicleApi.getLenderLoanTermsList(params);

      return data;
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const getRateSheetsDataThunk = createAsyncThunk(`${ACTION_PREFIX.VEHICLE}/getRateSheetsData`, async () => {
  const { data } = await vehicleApi.getRateSheetsData();

  return data;
});

export const saveRateSheetsDataThunk = createAsyncThunk(
  `${ACTION_PREFIX.VEHICLE}/saveRateSheetsData`,
  async (params: Record<string, unknown>, thunkAPI) => {
    try {
      vehicleApi.saveRateSheetsData(params);
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const getLoanVariablesInfoThunk = createAsyncThunk(
  `${ACTION_PREFIX.VEHICLE}/getLoanVariablesInfo`,
  async (vehicleParameters: Record<string, unknown>, thunkAPI) => {
    try {
      const { data } = await vehicleApi.getLoanVariablesInfo(vehicleParameters);

      return data;
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const getUsedVehiclesByStockThunk = createAsyncThunk(
  `${ACTION_PREFIX.VEHICLE}/getUsedVehiclesByStock`,
  async (vehicleParameters: Record<string, unknown>, thunkAPI) => {
    try {
      const { data } = await vehicleApi.getVehiclesByStock({ ...vehicleParameters, is_user_event: 'true' });

      return data;
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const getVehiclesByStockForProbabilityEstimatorThunk = createAsyncThunk(
  `${ACTION_PREFIX.VEHICLE}/getVehiclesByStockForProbabilityEstimator`,
  async (vehicleParameters: Record<string, unknown>, thunkAPI) => {
    try {
      const bothPayload = await Promise.all([thunkAPI.dispatch(getUsedVehiclesByStockThunk(vehicleParameters))]);

      if (bothPayload[0].meta.requestStatus === RequestStatus.REJECTED) {
        return thunkAPI.rejectWithValue(RequestStatus.REJECTED);
      }

      return [bothPayload[0].payload as IVehicleByStock];
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const getVehiclesByStockForApprovedLoanInputThunk = createAsyncThunk(
  `${ACTION_PREFIX.VEHICLE}/getVehiclesByStockForApprovedLoanInput`,
  async (vehicleParameters: Record<string, unknown>, thunkAPI) => {
    try {
      const bothPayload = await Promise.all([thunkAPI.dispatch(getUsedVehiclesByStockThunk(vehicleParameters))]);

      if (bothPayload[0].meta.requestStatus === RequestStatus.REJECTED) {
        return thunkAPI.rejectWithValue(RequestStatus.REJECTED);
      }

      return [bothPayload[0].payload as IVehicleByStock];
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const getRACVehiclesByStockThunk = createAsyncThunk(
  `${ACTION_PREFIX.VEHICLE}/getRACVehiclesByStock`,
  async (vehicleParameters: Record<string, unknown>, thunkAPI) => {
    try {
      const { data } = await vehicleApi.getRACVehiclesByStock(vehicleParameters);

      return data;
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const getRAC_VehiclesThunk = createAsyncThunk(
  `${ACTION_PREFIX.VEHICLE}/getNewRACVehicles`,
  async (vehicleParameters: Record<string, unknown>, thunkAPI) => {
    try {
      const { data } = await vehicleApi.getRACVehicles(vehicleParameters);

      return data;
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const getRACVehiclesThunk = createAsyncThunk(
  `${ACTION_PREFIX.VEHICLE}/getVehiclesForRAC`,
  async ({ ...vehicleParameters }: Record<string, unknown>, thunkAPI) => {
    try {
      const { payload } = await thunkAPI.dispatch(getRAC_VehiclesThunk(vehicleParameters));

      if (payload instanceof Error) {
        throw payload;
      } else {
        return payload as IRACVehicle[];
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const getVehiclesForLenderThunk = createAsyncThunk(
  `${ACTION_PREFIX.VEHICLE}/getLenderVehicles`,
  async ({ ...vehicleParameters }: Record<string, unknown>, thunkAPI) => {
    try {
      const { data } = await vehicleApi.getLenderVehicles({ ...vehicleParameters });

      return data;
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const getLenderVehiclesByStockThunk = createAsyncThunk(
  `${ACTION_PREFIX.VEHICLE}/getLenderVehiclesByStockThunk`,
  async (vehicleParameters: Record<string, unknown>, thunkAPI) => {
    try {
      const { data } = await vehicleApi.getLenderVehiclesByStock(vehicleParameters);

      return data;
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const getLenderLoanVariablesInfoThunk = createAsyncThunk(
  `${ACTION_PREFIX.VEHICLE}/getLenderLoanVariablesInfo`,
  async (vehicleParameters: Record<string, unknown>, thunkAPI) => {
    try {
      const { data } = await vehicleApi.getLenderLoanVariablesInfo(vehicleParameters);

      return data;
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const getRouteOneDataThunk = createAsyncThunk(`${ACTION_PREFIX.VEHICLE}/getRouteOneDataThunk`, async () => {
  const { data } = await vehicleApi.getRouteOneData();

  return data;
});

export const postRouteOneDataThunk = createAsyncThunk(
  `${ACTION_PREFIX.VEHICLE}/postRouteOneDataThunk`,
  async (params: ISendRouteOneData, thunkAPI) => {
    try {
      const { data } = await vehicleApi.postRouteOneData(params);

      return data;
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const createRouteOneVehicleThunk = createAsyncThunk(
  `${ACTION_PREFIX.VEHICLE}/createRouteOneVehicleThunk`,
  async (params: Record<string, unknown>, thunkAPI) => {
    try {
      const { data } = await vehicleApi.createRouteOneVehicle(params);

      return data;
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const getVehiclesForApprovedLoanInputThunk = createAsyncThunk(
  `${ACTION_PREFIX.VEHICLE}/getVehiclesForApprovedLoanInput`,
  async ({ ...vehicleParameters }: Record<string, unknown>, thunkAPI) => {
    try {
      const { payload } = await thunkAPI.dispatch(getVehiclesPaginatedThunk(vehicleParameters));

      if (payload instanceof Error) {
        throw payload;
      } else {
        return payload as IVehicle[];
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const getVehicleBodyTypesThunk = createAsyncThunk(`${ACTION_PREFIX.VEHICLE}/bodyTypes`, async () => {
  const { data } = await vehicleApi.getVehicleBodyType();

  return [ALL_VEHICLES_OPTION, ...toOptions(data)];
});

export const getDefaultVehiclePhotoThunk = createAsyncThunk(`${ACTION_PREFIX.VEHICLE}/defaultPhoto`, async () => {
  const { data } = await vehicleApi.getDefaultVehiclePhoto();

  return data;
});

export const getVehicleAdjustRetailThunk = createAsyncThunk(
  `${ACTION_PREFIX.VEHICLE}/vehiclesAdjustRetail`,
  async (id: number) => {
    const { data } = await vehicleApi.getVehicleAdjustRetailById(id);

    return data;
  },
);

export const getVehicleAdjustmentLogThunk = createAsyncThunk(
  `${ACTION_PREFIX.VEHICLE}/vehicleAdjustmentLog`,
  async (id: number) => {
    const { data } = await vehicleApi.getVehicleAdjustmentLogById(id);

    return data;
  },
);
