export const ADD_VEHICLE_FORM_INITIAL_VALUES = {
  vin: '',
  make: {
    title: undefined,
    value: undefined,
  },
  model: {
    title: undefined,
    value: undefined,
  },
  year: {
    title: undefined,
    value: undefined,
  },
  mileage: NaN,
  retailPrice: NaN,
  bookValue: NaN,
};

export const CREATE_ROUTE_ONE_VEHICLE_INITIAL_VALUES = {
  id: NaN,
  odometer: NaN,
  book_value: NaN,
  current_price: NaN,
  loan_term: NaN,
};
