import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import Slider from 'rc-slider';
import { Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { moneyFormatter, percentFormatter } from 'modules/core/utils';
import { ColumnWidth } from 'modules/core/enums';
import { shallowEqual, useSelector } from 'react-redux';
import { getUser } from 'store/selectors/auth.selector';
import { getVehicleFetchingFlags } from 'store/selectors/RACAffordabilityEngine.selector';

import { SubmitSliderSection } from './SubmitSliderSection';
import { ProbabilityEstimatorCalculationService } from '../services';
import { DEFAULT_MAXIMAL_PAYMENT_RANGE, MINIMAL_PAYMENT_RANGE } from '../probabilityEstimator.const';
import { CreditScoreColors } from '../enums/creditScoreColors.enum';
import {
  getMarksStyles,
  getTrackStyles,
  IncomeRation,
  MonthlyPaymentSliderWrapper,
  MonthlyPaymentTooltipText,
  PaymentRation,
  railStyles,
  RatioPercent,
  SliderLabel,
  sliderStyles,
  tipProps,
} from './styles';
import { CreditScore } from '../types';
import { ITier } from '../../Lender/types/Lender.types';

const { createSliderWithTooltip } = Slider;

const SliderUI = createSliderWithTooltip(Slider);

export interface IMonthlyPaymentSlider {
  name: string;
  creditScore: CreditScore | ITier;
  income: number;
  isFetchingVehicles: boolean;
  totalVehicles?: number;
  disabled?: boolean;
}

const MonthlyPaymentSlider: FC<IMonthlyPaymentSlider> = ({
  name,
  creditScore,
  income,
  totalVehicles,
  disabled,
  isFetchingVehicles,
}) => {
  const { t } = useTranslation();
  const { setValue, watch } = useFormContext();
  const user = useSelector(getUser);
  const { isReset } = useSelector(getVehicleFetchingFlags, shallowEqual);
  const { desiredMonthlyPayment, feesAndTaxes } = watch();
  const { salesTaxRate } = feesAndTaxes;

  const {
    max_allowed_pti,
    max_allowed_payment,
    max_green_pti,
    max_yellow_payment,
    max_green_payment,
    max_yellow_pti,
  } = creditScore;

  const [isFirstRender, setIsFirstRender] = useState(true);
  const [isFirstSet, setIsFirstSet] = useState(true);
  const [maximalPaymentRange, setMaximalPaymentRange] = useState(0);
  const [sliderColor, setSliderColor] = useState(CreditScoreColors.GREY);
  const sliderLabel = t('components.monthlyPaymentSlider.title');

  const incomeRatio = useMemo(
    () =>
      ProbabilityEstimatorCalculationService.getPercentPaymentToIncomeRatio(
        income,
        desiredMonthlyPayment,
        salesTaxRate,
      ),
    [income, desiredMonthlyPayment, salesTaxRate],
  );

  const trimFormatSliderLabel = useCallback(
    (value: number) => (
      <MonthlyPaymentTooltipText color={sliderColor}>{moneyFormatter(value)}</MonthlyPaymentTooltipText>
    ),
    [sliderColor],
  );

  const handleChange = useCallback(
    (value: number) => {
      setValue(name, value);
    },
    [setValue, name],
  );

  const marks = {
    [MINIMAL_PAYMENT_RANGE]: getMarksStyles(t('components.monthlyPaymentSlider.moreLikely')),
    [maximalPaymentRange]: getMarksStyles(t('components.monthlyPaymentSlider.lessLikely')),
  };

  const isWarningSectionVisible = useMemo(() => sliderColor === CreditScoreColors.RED, [sliderColor]);

  const scoreId = 'id' in creditScore ? creditScore.id : creditScore.tier_id;

  useEffect(() => {
    setSliderColor(
      ProbabilityEstimatorCalculationService.getMonthlyPaymentColor(
        incomeRatio,
        desiredMonthlyPayment,
        { max_green_payment, max_yellow_payment, max_green_pti, max_yellow_pti } as CreditScore,
        disabled,
      ),
    );
  }, [
    desiredMonthlyPayment,
    disabled,
    incomeRatio,
    max_green_payment,
    max_green_pti,
    max_yellow_payment,
    max_yellow_pti,
  ]);

  useEffect(() => {
    let maximalPayment = 0;

    if (
      typeof income !== 'undefined' &&
      typeof max_allowed_payment !== 'undefined' &&
      typeof max_allowed_pti !== 'undefined'
    ) {
      maximalPayment = ProbabilityEstimatorCalculationService.getMaximalMonthlyPayment(
        max_allowed_pti,
        max_allowed_payment,
        income,
      );

      setMaximalPaymentRange(maximalPayment || DEFAULT_MAXIMAL_PAYMENT_RANGE);
    }

    if (!isFirstRender) {
      if (desiredMonthlyPayment && (isFirstSet || isReset) && user?.is_sso) {
        setValue(name, desiredMonthlyPayment < maximalPayment ? desiredMonthlyPayment : maximalPayment);
        setIsFirstSet(false);
      } else {
        setValue(
          name,
          ProbabilityEstimatorCalculationService.getDefaultMonthlyPayment(
            max_green_pti,
            income,
            maximalPayment,
            max_green_payment,
            salesTaxRate,
          ),
        );
      }
    }

    setIsFirstRender(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scoreId, income, max_green_payment, max_green_pti, name, max_allowed_payment, max_allowed_pti, salesTaxRate]);

  return (
    <MonthlyPaymentSliderWrapper disabled={disabled}>
      <Col xs={ColumnWidth.FULL} lg={ColumnWidth.M}>
        <SliderLabel className="payment-range">{sliderLabel}</SliderLabel>
        <SliderUI
          min={MINIMAL_PAYMENT_RANGE}
          max={maximalPaymentRange}
          value={desiredMonthlyPayment}
          onChange={handleChange}
          marks={marks}
          tipProps={tipProps}
          tipFormatter={trimFormatSliderLabel}
          railStyle={railStyles}
          trackStyle={getTrackStyles(sliderColor)}
          handleStyle={sliderStyles}
        />
        <PaymentRation>
          <IncomeRation>{t('components.monthlyPaymentSlider.incomeRatio')}</IncomeRation>
          <RatioPercent color={sliderColor}>{percentFormatter(incomeRatio)}</RatioPercent>
        </PaymentRation>
      </Col>
      <Col xs={ColumnWidth.FULL} lg={ColumnWidth.M}>
        <SubmitSliderSection
          isWarningSectionVisible={isWarningSectionVisible}
          totalVehicles={totalVehicles}
          disabled={disabled}
          isFetchingVehicles={isFetchingVehicles}
        />
      </Col>
    </MonthlyPaymentSliderWrapper>
  );
};

export const MemoizedMonthlyPaymentSlider = React.memo(MonthlyPaymentSlider);
