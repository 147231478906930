import styled from 'styled-components';
import { COLORS } from 'modules/core/theme/colors';
import { PlaceholderTitle } from 'common/styles';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 200px;
  background-color: ${COLORS.GREY_2};

  ${PlaceholderTitle} {
    argin-bottom: 8px;
  }
`;
