// In order to prevent cycled dependencies
// this code has been temporarily moved here.
export const VEHICLE_TABLE_COLUMN_ID = {
  PHOTO: 'car_photos',
  VEHICLE: 'description',
  STOCK: 'stock',
  AGE: 'age',
  MILES: 'odometer',
  VIN: 'vin',
  LOCATION: 'dealer.shortname',
  MAX_PRICE: 'max_price',
  RETAIL_PRICE: 'current_price',
  VENDOR_TRADE_IN: 'vendor_trade',
  UNIT_COST: 'unit_cost',
  VENDOR_RETAIL: 'vendor_retail',
  KBB_LENDING_VALUE: 'kbb_lending_value',
  KBB_TYPICAL_LISTING: 'kbb_typical_listing',
  BOOK_VALUE: 'guidebook_value',
  VALUATION_GUIDE: 'guidebook',
  BLACK_BOOK_FINANCE_ADVANCE: 'black_book_finance_advance',
  BLACK_BOOK_WHOLESALE: 'black_book_wholesale',
  PAYMENT: 'monthly_payment',
  LOAN_TERM: 'loan_term',
  LOAN_AMOUNT: 'loan_amount',
  DEALER_PROFIT: 'dealer_profit',
  LTV_RATIO: 'ltv_ratio',
  ADDITIONAL_DOWN_PAYMENT: 'additional_down_payment',
  DISCOUNT: 'discount',
  DRIVE_TYPE: 'drive_type',
  PASSENGER_CAPACITY: 'passenger_capacity',
  BANK_FEES_NAME: 'bank_fees',
};

export const RAC_VEHICLE_TABLE_COLUMN_ID = {
  PHOTO: 'photo',
  VIN: 'vin',
  VEHICLE: 'description',
  STOCK: 'stock',
  AGE: 'age',
  MILES: 'odometer',
  LOCATION: 'location',
  MAX_PRICE: 'ltv_value.max_price',
  RETAIL_PRICE: 'current_price',
  VENDOR_TRADE_IN: 'vendor_trade',
  KBB_LENDING_VALUE: 'kbb_lending_value',
  BOOK_VALUE: 'guidebook_value',
  VALUATION_GUIDE: 'guidebook',
  UNIT_COST: 'unit_cost',
  PAYMENT: 'ltv_value.payments',
  TERM: 'loan_term',
  PROFIT: 'ltv_value.profit',
  LTV: 'ltv_value.ltv',
  EXTRA_CASH: 'ltv_value.extra_down_payment',
  LOAN_VARIABLES: 'loanVariables',
  F_AND_I_FLAT: 'ltv_value.f_and_i_flat',
  DISCOUNT: 'ltv_value.discount',
  LOAN_AMOUNT: 'ltv_value.loan_amount',
  ESTIMATED_PAYMENT: 'estimated_payment',
  RECOMMENDED_TERM: 'recommended_term',
};

export const WITHOUT_INVENTORY_TABLE_COLUMN_ID = {
  PHOTO: 'photo',
  VEHICLE: 'description',
  VIN: 'vin',
  MILES: 'odometer',
  RETAIL_PRICE: 'current_price',
  ESTIMATED_PAYMENT: 'ltv_value.payments',
  RECOMMENDED_TERM: 'loan_term',
  LTV: 'ltv_value.ltv',
  EXTRA_CASH: 'ltv_value.extra_down_payment',
  BOOK_VALUE: 'guidebook_value',
  ESTIMATED_AMOUNT_FINANCED: 'ltv_value.loan_amount',
};

export const LENDER_VEHICLE_TABLE_COLUMN_ID = {
  LOCATION: 'location',
  RETAIL_PRICE: 'current_price',
  RECOMMENDED_TERM: 'term',
  ESTIMATED_PROFIT: 'profit',
  PAYMENT: 'monthly_payment',
  BOOK_VALUE: 'guidebook_value',
  VALUATION_GUIDE: 'guidebook',
  SALESPERSON_BUCKET: 'salesperson_bucket',
};

const BASIC_VISIBLE_VEHICLE_TABLE_COLUMNS = [
  VEHICLE_TABLE_COLUMN_ID.PHOTO,
  VEHICLE_TABLE_COLUMN_ID.VEHICLE,
  VEHICLE_TABLE_COLUMN_ID.STOCK,
  VEHICLE_TABLE_COLUMN_ID.VIN,
  VEHICLE_TABLE_COLUMN_ID.MILES,
  VEHICLE_TABLE_COLUMN_ID.LOCATION,
  VEHICLE_TABLE_COLUMN_ID.RETAIL_PRICE,
];

export const DEFAULT_VISIBLE_VEHICLE_COLUMNS = [
  ...BASIC_VISIBLE_VEHICLE_TABLE_COLUMNS,
  VEHICLE_TABLE_COLUMN_ID.AGE,
  VEHICLE_TABLE_COLUMN_ID.MAX_PRICE,
  VEHICLE_TABLE_COLUMN_ID.VENDOR_TRADE_IN,
  VEHICLE_TABLE_COLUMN_ID.KBB_LENDING_VALUE,
  VEHICLE_TABLE_COLUMN_ID.BOOK_VALUE,
  VEHICLE_TABLE_COLUMN_ID.VALUATION_GUIDE,
  VEHICLE_TABLE_COLUMN_ID.UNIT_COST,
  VEHICLE_TABLE_COLUMN_ID.PAYMENT,
  VEHICLE_TABLE_COLUMN_ID.LOAN_TERM,
  VEHICLE_TABLE_COLUMN_ID.DEALER_PROFIT,
  VEHICLE_TABLE_COLUMN_ID.LTV_RATIO,
  VEHICLE_TABLE_COLUMN_ID.LOAN_AMOUNT,
  VEHICLE_TABLE_COLUMN_ID.DISCOUNT,
];

export const DEFAULT_SALESPERSON_VISIBLE_VEHICLE_COLUMNS = [
  ...BASIC_VISIBLE_VEHICLE_TABLE_COLUMNS,
  VEHICLE_TABLE_COLUMN_ID.UNIT_COST,
  VEHICLE_TABLE_COLUMN_ID.DEALER_PROFIT,
  VEHICLE_TABLE_COLUMN_ID.VENDOR_TRADE_IN,
  VEHICLE_TABLE_COLUMN_ID.KBB_LENDING_VALUE,
  VEHICLE_TABLE_COLUMN_ID.BOOK_VALUE,
  VEHICLE_TABLE_COLUMN_ID.VALUATION_GUIDE,
  VEHICLE_TABLE_COLUMN_ID.PAYMENT,
  VEHICLE_TABLE_COLUMN_ID.LOAN_TERM,
  VEHICLE_TABLE_COLUMN_ID.LTV_RATIO,
  VEHICLE_TABLE_COLUMN_ID.LOAN_AMOUNT,
  VEHICLE_TABLE_COLUMN_ID.ADDITIONAL_DOWN_PAYMENT,
  VEHICLE_TABLE_COLUMN_ID.AGE,
];

export const EXTENDED_SALESPERSON_VISIBLE_VEHICLE_COLUMNS = DEFAULT_SALESPERSON_VISIBLE_VEHICLE_COLUMNS;

export const SALESPERSON_CUSTOMER_FRIENDLY_VISIBLE_VEHICLE_COLUMNS = [
  ...BASIC_VISIBLE_VEHICLE_TABLE_COLUMNS,
  VEHICLE_TABLE_COLUMN_ID.ADDITIONAL_DOWN_PAYMENT,
  RAC_VEHICLE_TABLE_COLUMN_ID.EXTRA_CASH,
  RAC_VEHICLE_TABLE_COLUMN_ID.PHOTO,
];

export const MANAGER_CUSTOMER_FRIENDLY_VISIBLE_VEHICLE_COLUMNS = [
  ...BASIC_VISIBLE_VEHICLE_TABLE_COLUMNS,
  RAC_VEHICLE_TABLE_COLUMN_ID.PHOTO,
];

export const SALESPERSON_LENDER_CUSTOMER_FRIENDLY_VEHICLE_COLUMNS = [
  RAC_VEHICLE_TABLE_COLUMN_ID.PHOTO,
  VEHICLE_TABLE_COLUMN_ID.VEHICLE,
  VEHICLE_TABLE_COLUMN_ID.STOCK,
  VEHICLE_TABLE_COLUMN_ID.VIN,
  VEHICLE_TABLE_COLUMN_ID.MILES,
  VEHICLE_TABLE_COLUMN_ID.RETAIL_PRICE,
  VEHICLE_TABLE_COLUMN_ID.ADDITIONAL_DOWN_PAYMENT,
  LENDER_VEHICLE_TABLE_COLUMN_ID.SALESPERSON_BUCKET,
];

export const MANAGER_LENDER_CUSTOMER_FRIENDLY_VEHICLE_COLUMNS = [
  RAC_VEHICLE_TABLE_COLUMN_ID.PHOTO,
  VEHICLE_TABLE_COLUMN_ID.VEHICLE,
  LENDER_VEHICLE_TABLE_COLUMN_ID.LOCATION,
  VEHICLE_TABLE_COLUMN_ID.STOCK,
  VEHICLE_TABLE_COLUMN_ID.MILES,
  VEHICLE_TABLE_COLUMN_ID.RETAIL_PRICE,
];
