import { createSelector } from '@reduxjs/toolkit';
import { IIncomeForm } from 'components/IncomeCalculator/types';
import { IColumn, TableData } from 'modules/core/components/Table/types';
import { RETAIL_NAME } from 'modules/core/constants/report.const';
import { IFeesAndTaxes, IFetchingVehicleFlags, ILenderVehicle, ITableViewReport } from 'modules/core/types';
import { toIncomeCalculatorReportData } from 'modules/core/utils/selectors/incomeReport';
import { ILenderForm, ITier } from 'modules/Lender/types/Lender.types';
import { CreditScoreColors } from 'modules/ProbabilityEstimator/enums/creditScoreColors.enum';
import { MINIMAL_PAYMENT_RANGE } from 'modules/ProbabilityEstimator/probabilityEstimator.const';
import { ProbabilityEstimatorCalculationService } from 'modules/ProbabilityEstimator/services';
import { ILenderState, initialStateOneLender } from 'store/reducers/lenders.reducer';
import { RootState } from 'store/store';
import { PERMISSIONS } from 'modules/PermissionWrapper/permissions.const';
import { IMenuLink } from 'modules/core/components/SideBar/sideBarMenuLinks';

import { getFeesAndTaxesState } from './controls.selector';
import { getSelectedRows, getTableVisibleColumns } from './probabilityEstimator.selector';

export const getLendersState = (state: RootState): Record<string, ILenderState> => state.lenders.lendersHashTable;

export const getLenderState = (state: RootState): ILenderState => getLendersState(state)[state.lenders.currentLenderId];

export const getCurrentLenderControls = (state: RootState): { maxVSC: number; maxGap: number; showRates: boolean } =>
  state.lenders.currentLenderControls;

export const getLendersList = (state: RootState): IMenuLink[] =>
  Object.values(getLendersState(state)).map((lender) => ({
    name: lender.name,
    iconUrl: '',
    link: lender.path,
    allowedRoles: PERMISSIONS.LENDER,
    smallFont: true,
  }));

export const getCurrentLenderId = (state: RootState): string => state.lenders.currentLenderId;

export const getLendersFullIds = (state: RootState): string[] => Object.keys(getLendersState(state));

export const getLenderInitialValues = (state: RootState): ILenderForm => getLenderState(state).initialValues;

export const getFetchingLendersFlag = (state: RootState): boolean => state.lenders.isFetchingLendersList;

export const getFetchingLenderFlag = (state: RootState): boolean => state.lenders.isFetchingLender;

export const getLenderVehiclesByParams = (state: RootState): ILenderVehicle[] | undefined =>
  getLenderState(state).vehicles.vehiclesByParams;

export const getLenderVehiclesByStock = (state: RootState): ILenderVehicle[] | undefined =>
  getLenderState(state).vehicles.vehiclesByStock;

export const getNotFoundVehicles = (state: RootState): string[] => getLenderState(state).notFoundVehicles;

export const getNotAvailableVehicles = (state: RootState): string[] => getLenderState(state).notAvailableVehicles;

export const getIsTableView = (state: RootState): boolean => getLenderState(state).isTableView;

export const getIncome = (state: RootState): IIncomeForm =>
  getLenderState(state)?.income || initialStateOneLender.income;

export const getIncomeCalculatorReportData = createSelector(getIncome, toIncomeCalculatorReportData);

export const getTiers = (state: RootState): ITier[] =>
  getLenderState(state).tiers.map((tier) => ({ ...tier, color: CreditScoreColors.GREEN })) || []; // TODO_AK remove after backend add color in SWA-4350 task

export const getVehicleFetchingFlags = (state: RootState): IFetchingVehicleFlags => {
  const { isFetchingVehicles, isFetchingVehiclesFailed, isReset } = getLenderState(state);

  return { isFetchingVehicles, isFetchingVehiclesFailed, isReset };
};

export const toLenderReportData = (
  lenderData: ILenderState,
  controlFeesAndTaxes: IFeesAndTaxes,
  tableSelectedRows: TableData[],
  tableVisibleColumns: IColumn[],
): ITableViewReport => {
  const {
    totalNetDownPaymentDialog,
    feesAndTaxes,
    tier: creditScore,
    grossMonthlyIncome,
    desiredMonthlyPayment,
    preferences,
  } = lenderData.initialValues;

  const maximalMonthlyPayment = ProbabilityEstimatorCalculationService.getMaximalMonthlyPayment(
    creditScore.max_allowed_pti,
    creditScore.max_allowed_payment,
    grossMonthlyIncome || 0,
  );

  return {
    disclaimer: {
      amountOwed: totalNetDownPaymentDialog.amountOwed,
      bankDiscount: feesAndTaxes.bankDiscount,
      bankFees: feesAndTaxes.bankFees,
      cashDown: totalNetDownPaymentDialog.cashDown,
      desiredMonthly: desiredMonthlyPayment || 0,
      desiredMonthlyCeil: maximalMonthlyPayment,
      desiredMonthlyFloor: MINIMAL_PAYMENT_RANGE,
      grossMonthlyIncome: grossMonthlyIncome || 0,
      isLender: true,
      ltvHighLimit: preferences.ltvHighLimit,
      salesTaxRate: feesAndTaxes.salesTaxRate || 0,
      salesFees: feesAndTaxes.salesFees || 0,
      selectedCreditScore: { ...creditScore, interest_rate: creditScore.rate },
      tradeValue: totalNetDownPaymentDialog.tradeValue,
    },
    type: RETAIL_NAME,
    vehicles: tableSelectedRows,
    vehicle_parameters: tableVisibleColumns,
  };
};

export const getLenderReportData = createSelector(
  getLenderState,
  getFeesAndTaxesState,
  getSelectedRows,
  getTableVisibleColumns,
  toLenderReportData,
);
