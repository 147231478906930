import { LoanStatusColors } from 'modules/core/enums';
import { COLORS } from 'modules/core/theme/colors';

export const CARD_COLOR_MAP = new Map<LoanStatusColors, string>([
  [LoanStatusColors.GREEN, COLORS.GREEN_1],
  [LoanStatusColors.YELLOW, COLORS.YELLOW_3],
  [LoanStatusColors.RED, COLORS.RED_1],
]);

export const CARD_HEADER_COLOR_MAP = new Map<LoanStatusColors, string>([
  [LoanStatusColors.GREEN, COLORS.GREEN_7],
  [LoanStatusColors.YELLOW, COLORS.YELLOW_5],
  [LoanStatusColors.RED, COLORS.RED_3],
]);
