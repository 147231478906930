import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider } from 'modules/core/components/Divider';
import { Button } from 'modules/core/components';
import { ButtonVariant } from 'modules/core/enums';
import { useModalState } from 'modules/core/hooks';
import { MODAL_DIALOG_ID } from 'modules/core/constants';

import { ButtonGroup, Label } from './styles';

interface IRowContent {
  label: string;
  yesButtonAction: VoidFunction;
  yesButtonContained?: boolean;
  noButtonContained?: boolean;
}

export const RowContent: FC<IRowContent> = ({
  label,
  yesButtonAction,
  yesButtonContained = false,
  noButtonContained = true,
}) => {
  const { t } = useTranslation();
  const { handleCloseDialog } = useModalState([MODAL_DIALOG_ID.RESET_FORM]);

  const handleButtonVariant = useCallback(
    (isContained) => (isContained ? ButtonVariant.CONTAINED : ButtonVariant.OUTLINE),
    [],
  );

  const handleSubmitModal = useCallback(() => {
    yesButtonAction();
    handleCloseDialog()();
  }, [handleCloseDialog, yesButtonAction]);

  return (
    <>
      <Label>{label}</Label>
      <Divider />
      <ButtonGroup>
        <Button
          title={t('common.buttons.yes')}
          onClick={handleSubmitModal}
          buttonTheme={handleButtonVariant(yesButtonContained)}
        />
        <Button
          title={t('common.buttons.no')}
          onClick={handleCloseDialog()}
          buttonTheme={handleButtonVariant(noButtonContained)}
        />
      </ButtonGroup>
    </>
  );
};
