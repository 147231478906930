import styled from 'styled-components';
import { InputGroup } from 'react-bootstrap';
import { STATIC_IMAGE_URLS } from 'modules/core/constants';

export const Dropdown = styled(InputGroup)`
  height: ${({ theme: { variables } }) => variables.inputHeight};

  select.form-control {
    height: ${({ theme: { variables } }) => variables.inputHeight};
    font-size: ${({ theme: { fontSize } }) => fontSize.sm};
    background-image: url(${STATIC_IMAGE_URLS.icons.arrowIcon});
    background-repeat: no-repeat;
    background-position: right 0.4rem center;
    background-size: 11px 11px;
    padding-right: 22px;
  }

  .is-invalid {
    background-image: none;
  }
`;
