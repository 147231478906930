import React from 'react';
import i18n from 'i18n';
import { Input, TelephoneCodeAdornment } from 'modules/core/components';
import { IField } from 'modules/core/types';
import { TelephoneInput } from 'modules/core/components/TelephoneInput';
import { PATTERNS } from 'modules/core/constants';
import { validateMaxRequiredAreaValue, validateMaxRequiredInputValue } from 'modules/core/validators';

import { IValidateProps } from './types';

const FORM_PlACEHOLDERS = {
  NAME: i18n.t('components.sendReportDialog.sendSalespersonForm.placeholders.name'),
  LAST_NAME: i18n.t('components.sendReportDialog.sendSalespersonForm.placeholders.lastName'),
  PHONE: i18n.t('components.sendReportDialog.sendSalespersonForm.placeholders.phoneNumber'),
  EMAIL: i18n.t('components.sendReportDialog.sendSalespersonForm.placeholders.email'),
  NOTE: i18n.t('components.sendReportDialog.sendSalespersonForm.placeholders.note'),
};

export const FIELD_NAMES = {
  NAME: 'name',
  LAST_NAME: 'lastName',
  PHONE: 'phone',
  EMAIL: 'email',
  NOTE: 'note',
};

const FORMAT_NUMBER_MASK = '(###) ### #### ###';

export const REQUIRED_FIELDS_CONFIG: IField[] = [
  {
    name: FIELD_NAMES.NAME,
    controlProps: {
      required: true,
      rules: { required: true },
      valueAsNumber: false,
      validate: validateMaxRequiredInputValue,
      placeholder: FORM_PlACEHOLDERS.NAME,
      testId: 'send-report-name',
    },
    component: Input,
  },
  {
    name: FIELD_NAMES.LAST_NAME,
    controlProps: {
      required: true,
      rules: { required: true },
      valueAsNumber: false,
      validate: validateMaxRequiredInputValue,
      placeholder: FORM_PlACEHOLDERS.LAST_NAME,
      testId: 'send-report-lastname',
    },
    component: Input,
  },
];

export const FIELDS_CONFIG: IField[] = [
  {
    name: FIELD_NAMES.PHONE,
    controlProps: {
      format: FORMAT_NUMBER_MASK,
      startAdornment: <TelephoneCodeAdornment />,
      placeholder: FORM_PlACEHOLDERS.PHONE,
      testId: 'send-report-phone',
    },
    component: TelephoneInput,
  },
  {
    name: FIELD_NAMES.EMAIL,
    controlProps: {
      valueAsNumber: false,
      placeholder: FORM_PlACEHOLDERS.EMAIL,
      testId: 'send-report-email',
    },
    component: Input,
  },
  {
    name: FIELD_NAMES.NOTE,
    controlProps: {
      as: 'textarea',
      valueAsNumber: false,
      validate: validateMaxRequiredAreaValue,
      placeholder: FORM_PlACEHOLDERS.NOTE,
    },
    component: Input,
  },
];

export const VALIDATE_PROPS = {
  PHONE: {
    dependentFieldName: FIELD_NAMES.EMAIL,
    dependentFieldValuePattern: PATTERNS.EMAIL_PATTERN,
    valuePattern: PATTERNS.PHONE_PATTERN,
    errorMessage: i18n.t('common.errorMessages.phoneMessage'),
  },
  EMAIL: {
    dependentFieldName: FIELD_NAMES.PHONE,
    dependentFieldValuePattern: PATTERNS.PHONE_PATTERN,
    valuePattern: PATTERNS.EMAIL_PATTERN,
    errorMessage: i18n.t('common.errorMessages.emailMessage'),
  },
};

export const FIELDS_VALIDATE_PROPS_MAP = new Map<string, IValidateProps>([
  [FIELD_NAMES.PHONE, VALIDATE_PROPS.PHONE],
  [FIELD_NAMES.EMAIL, VALIDATE_PROPS.EMAIL],
]);
