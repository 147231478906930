import i18n from 'i18n';

export const ERROR_401_UNAUTHORIZED = 401;
export const ERROR_403_FORBIDDEN = 403;
export const ERROR_404_NOT_FOUND = 404;

export const ERROR_PAGE_BODY_MAP = new Map<number, string>([
  [ERROR_403_FORBIDDEN, i18n.t('components.errorPage.message.error403')],
  [ERROR_404_NOT_FOUND, i18n.t('components.errorPage.message.error404')],
]);
