import { ROUTER_PATH } from 'router/const/routerPath.const';

import { IMenuLink, lenderTitleItem } from './sideBarMenuLinks';

export const sortLenderLinks = (links: IMenuLink[]): IMenuLink[] => {
  const lenderLinks = links.sort((a, b) => {
    if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;

    if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;

    return 0;
  });

  // ['1','2','q','y','z'] => ['q','y','z','1','2']
  const fullLendersLink = lenderLinks
    .filter((lenderLink) => !Number(lenderLink.name[0]))
    .concat(lenderLinks.filter((lenderLink) => Number(lenderLink.name[0])));

  return fullLendersLink;
};

const prepareFullLendersLinks = (isRAC: boolean, lenderLinks: IMenuLink[], RACLink: IMenuLink): IMenuLink[] => {
  const lendersLinks: IMenuLink[] = isRAC ? lenderLinks.concat([RACLink]) : lenderLinks;

  const fullLendersLink = sortLenderLinks(lendersLinks);

  if (fullLendersLink.length > 0) fullLendersLink.unshift(lenderTitleItem);

  return fullLendersLink;
};

export const concatMenuLinks = (defaultMenuLinks: IMenuLink[], lenderLinks: IMenuLink[]): IMenuLink[] => {
  const RACIndex = defaultMenuLinks.findIndex((item) => item.link === ROUTER_PATH.RAC_AFFORDABILITY_ENGINE);
  const isRAC: boolean = RACIndex >= 0;
  const defaultSeparatorIndex = 2;
  const separator: number = isRAC ? RACIndex : defaultSeparatorIndex;

  const firstPartMenuLinks = defaultMenuLinks.slice(0, separator);
  const lastPartMenuLinks = defaultMenuLinks.slice(isRAC ? separator + 1 : separator, defaultMenuLinks.length + 1);
  const RACLink = { ...defaultMenuLinks[RACIndex], smallFont: true };

  const fullLendersLink = prepareFullLendersLinks(isRAC, lenderLinks, RACLink);

  return firstPartMenuLinks.concat(fullLendersLink).concat(lastPartMenuLinks);
};

export const filterByDisabledFeatures = (menuLinks: IMenuLink[], disabledFeaturesList: string[]): IMenuLink[] =>
  menuLinks.filter((menuLink) => {
    const isFeatureDisabled = disabledFeaturesList.find((disabledFeature) => menuLink.featureName === disabledFeature);

    return !isFeatureDisabled;
  });
