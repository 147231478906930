import styled from 'styled-components';
import { Image } from 'react-bootstrap';

const ICON_SIZE = '17px';

export const CalculatorIcon = styled(Image)`
  width: ${ICON_SIZE};
  height: ${ICON_SIZE};
  cursor: pointer;
`;
