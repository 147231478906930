import styled from 'styled-components';

export const TotalIncomePanel = styled.div`
  padding: 15px;
  background-color: ${({ theme: { colors } }) => colors.white};
  border-radius: 4px;
  box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  .row:last-child {
    margin-bottom: 0;
  }
`;
