import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ModalProps } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { authApi } from 'api';
import { ModalDialog } from 'components/ModalDialog';
import { MODAL_DIALOG_WIDTH, EMPTY_STRING } from 'modules/core/constants';
import { Alignment, ButtonVariant, Roles } from 'modules/core/enums';
import { AppDispatch } from 'store/store';
import { FormProvider, useForm } from 'react-hook-form';
import { showNotification } from 'store/reducers/ui.reducer';
import { ModalButton } from 'components/ModalDialog/style';
import { InfoRow, LinkButton } from 'modules/core/components';
import { getUser } from 'store/selectors/auth.selector';
import { useContactAdministratorLink } from 'modules/core/hooks';
import { TUserOption } from 'modules/core/types';
import { convertToSnakeCase } from 'modules/core/utils';

import { Container, Link, CustomerInfo } from './styles';
import {
  SUCCESSFUL_CHANGE_MESSAGE,
  USER_INFO_FIELD_DEFAULT_VALUE,
  FIELDS,
  FIELD_NAMES,
  PASSWORD_CHANGE_FAILED_ERROR_NAME,
  CHANGE_PASSWORD_NAMES_MAPPER,
  TITLES_BY_ROLE_MAP,
  TITLES,
} from './common.const';
import { ChangePasswordForm } from './ChangePasswordForm';
import { MyAccountDialogService } from './myAccountDialog.service';

export const MyAccountDialog: FC<ModalProps> = ({ show, onHide }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const user = useSelector(getUser);
  const contactAdministratorLink = useContactAdministratorLink();
  const formMethods = useForm({ mode: 'onChange' });

  const {
    handleSubmit,
    setError,
    clearErrors,
    watch,
    formState: { isValid, errors },
  } = formMethods;

  const [isFormShow, setIsFormShow] = useState(false);
  const { oldPassword, newPassword, confirmedNewPassword } = watch();
  const role = useMemo(() => {
    const currentRoleValue = user?.role || Roles.SALESPERSON;

    return currentRoleValue.toLowerCase();
  }, [user]);

  const dealer = useMemo(() => (user?.dealer && `${user.dealer.name} (${user.dealer.shortname})`) || EMPTY_STRING, [
    user,
  ]);

  const title = useMemo(() => {
    if (user?.role) {
      return TITLES_BY_ROLE_MAP.get(user.role) || TITLES.SALESPERSON;
    }

    return TITLES.SALESPERSON;
  }, [user]);

  const onSubmit = useCallback(
    async (submitData) => {
      try {
        await authApi.changePassword(convertToSnakeCase(submitData, CHANGE_PASSWORD_NAMES_MAPPER));

        dispatch(showNotification({ message: SUCCESSFUL_CHANGE_MESSAGE, type: 'successful' }));
        setIsFormShow(false);
      } catch (e: any) {
        MyAccountDialogService.setErrorsMessages(setError, e.response.data);
      }
    },
    [dispatch, setError],
  );

  const renderRow = useCallback(
    (label, value) => (
      <InfoRow
        key={label}
        label={t(`components.myAccount.labels.${label}`)}
        value={value || USER_INFO_FIELD_DEFAULT_VALUE}
      />
    ),
    [t],
  );

  const additionalButton = useMemo(
    () => (
      <ModalButton
        onClick={handleSubmit(onSubmit)}
        buttontheme={ButtonVariant.CONTAINED}
        type="submit"
        disabled={!isValid}
      >
        {t('common.buttons.save')}
      </ModalButton>
    ),
    [handleSubmit, isValid, onSubmit, t],
  );

  const toggleFormVisibility = useCallback(() => {
    setIsFormShow((state) => !state);
  }, []);

  const getFieldValue = useCallback(
    (name) => {
      if (name === FIELD_NAMES.DEALERSHIP) {
        return dealer;
      }

      if (name === FIELD_NAMES.ROLE) {
        return role;
      }

      return user?.[name as TUserOption];
    },
    [dealer, role, user],
  );

  const fields = useMemo(() => FIELDS.map((name) => renderRow(name, getFieldValue(name))), [getFieldValue, renderRow]);

  useEffect(() => {
    clearErrors(PASSWORD_CHANGE_FAILED_ERROR_NAME);
  }, [clearErrors, oldPassword, newPassword, confirmedNewPassword]);

  return (
    <ModalDialog
      show={show}
      onHide={onHide}
      title={title}
      modalSize={MODAL_DIALOG_WIDTH.SM}
      cancelButtonTitle={t('common.buttons.cancel')}
      upperCaseTitle
      alignFooterContent={Alignment.LEFT}
      {...(isFormShow ? { additionalButton } : {})}
    >
      <Container>
        <CustomerInfo>{fields}</CustomerInfo>
        <LinkButton onClick={toggleFormVisibility} type="submit" active={isFormShow}>
          {t('components.resetPassword.changePassword')}
        </LinkButton>
        {isFormShow && (
          <FormProvider {...formMethods}>
            <ChangePasswordForm errors={errors} />
          </FormProvider>
        )}
        <Link href={contactAdministratorLink}>{t('components.errorPage.links.contactAdministrator')}</Link>
      </Container>
    </ModalDialog>
  );
};
