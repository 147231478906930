import i18n from 'i18next';
import { IOption } from 'modules/core/types';

export const TABLE_ITEMS_PER_PAGE_OPTIONS: IOption[] = [
  {
    title: '100',
    value: 100,
  },
  {
    title: '60',
    value: 60,
  },
  {
    title: '30',
    value: 30,
  },
  {
    title: '15',
    value: 15,
  },
];

export const SELECT_ALL_COLUMN_ID = 'selection';

export const TABLE_RENDER_CELL_KEY = 'Cell';
export const TABLE_RENDER_HEADER_KEY = 'Header';

// 30 items per page
export const INITIAL_PAGE_SIZE = +TABLE_ITEMS_PER_PAGE_OPTIONS[TABLE_ITEMS_PER_PAGE_OPTIONS.length - 2].value;

export const DEFAULT_SEARCH_PLACEHOLDER = i18n.t('components.table.searchPlaceholder.nothingToShow');
