import React, { FC, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useFormContext, Controller, ControllerRenderProps } from 'react-hook-form';
import { Dropdown } from 'react-bootstrap';
import { useUncontrolledInput } from 'modules/core/hooks';
import { IFormField } from 'modules/core/types/form';
import { useTranslation } from 'react-i18next';
import { PREFERENCES_PE_FIELD_NAMES } from 'modules/core/shared';
import { CreditScoreColors } from 'modules/ProbabilityEstimator/enums/creditScoreColors.enum';
import { getTiers } from 'store/selectors/lenders.selector';

import {
  DefaultToggle,
  OptionItem,
  ScoreMenu,
  SelectedScoreToggle,
} from '../../ProbabilityEstimator/CreditScoreDropdown/styles';
import { ITier } from '../types/Lender.types';

export const LenderTierDropdown: FC<IFormField> = ({ name, validate }) => {
  const { t } = useTranslation();
  const { watch } = useFormContext();
  const { controlField, setValue } = useUncontrolledInput(name);
  const interestRateList = useSelector(getTiers);

  const selectedScore = watch(name) as ITier;

  const isSelectedDefault = selectedScore.color === CreditScoreColors.DEFAULT;
  const toggleTitle = isSelectedDefault ? t('components.creditScore.title') : selectedScore.tier_name;
  const ToggleComponent = isSelectedDefault ? DefaultToggle : SelectedScoreToggle;

  const renderOptions = useCallback(
    ({ onChange, onBlur }: ControllerRenderProps) =>
      interestRateList.map((tier) => {
        const isSelectedOption = selectedScore.tier_name === tier.tier_name;

        return (
          <OptionItem
            onClick={() => {
              onChange(tier);
              setValue(PREFERENCES_PE_FIELD_NAMES.LVT_HIGH_LIMIT, tier.ltv_limit);
            }}
            onBlur={onBlur}
            color={tier.color}
            selected={isSelectedOption}
            key={tier.tier_name}
            data-testid="tier-option"
          >
            {tier.tier_name}
          </OptionItem>
        );
      }),
    [interestRateList, selectedScore, setValue],
  );

  const renderDropdown = useCallback(
    (renderProps) => (
      <Dropdown data-testid="tierDropdown">
        <ToggleComponent color={selectedScore.color} data-testid="default-toggle">
          {toggleTitle}
        </ToggleComponent>
        <ScoreMenu>{renderOptions(renderProps)}</ScoreMenu>
      </Dropdown>
    ),
    [ToggleComponent, renderOptions, selectedScore.color, toggleTitle],
  );

  return (
    <Controller
      name={name}
      render={renderDropdown}
      control={controlField}
      rules={{
        validate,
      }}
    />
  );
};
