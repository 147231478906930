import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setVehicleDetails } from 'store/reducers/vehicle.reducer';
import { AppDispatch } from 'store/store';
import { ILoanStatusGradientBackgroundProps, IRACVehicle, IUser, IVehiclePayload } from 'modules/core/types';
import {
  EMPTY_STRING,
  LOAN_STATUS_GRADIENT_BACKGROUND_COLORS_MAP,
  MODAL_DIALOG_ID,
  STATIC_IMAGE_URLS,
} from 'modules/core/constants';
import { useModalState, useBackgroundPropsByLoanStatus } from 'modules/core/hooks';
import { getDefaultVehiclePhoto } from 'store/selectors/vehicle.selector';
import { getUser } from 'store/selectors/auth.selector';
import { getRouteOneData } from 'store/selectors/RACAffordabilityEngine.selector';

import { Container, ImageUI } from './styles';

interface IVehicleDetailDialogTriggerImage {
  data: IRACVehicle | IVehiclePayload;
  noColoring?: boolean;
}

export const ImageCell: FC<IVehicleDetailDialogTriggerImage> = ({ data, noColoring }) => {
  const dispatch = useDispatch<AppDispatch>();
  const defaultVehiclePhoto = useSelector(getDefaultVehiclePhoto);
  const routeOneData = useSelector(getRouteOneData);
  const { handleOpenDialog } = useModalState([MODAL_DIALOG_ID.VEHICLE_DETAILS]);
  const loanStatusGradientBackgroundColors = useBackgroundPropsByLoanStatus(
    data.loan_status,
    LOAN_STATUS_GRADIENT_BACKGROUND_COLORS_MAP,
  );
  const user = useSelector(getUser) as IUser;
  const { blackCar, whiteCar, noPhoto } = STATIC_IMAGE_URLS.icons;

  const isImageExist = !!data.thumbnails?.length;

  const openVehicleDetailsDialog = useCallback(() => {
    dispatch(setVehicleDetails({ id: data.id, title: data.description }));
    handleOpenDialog();
  }, [handleOpenDialog, dispatch, data]);

  const getVehiclePhotoSrc = useCallback(() => {
    if (!user?.isWithInventory || data?.manually_created) {
      if (Object.keys(routeOneData).length > 0 && routeOneData?.sale_vehicle.vin === data.vin) {
        return whiteCar;
      }

      if (!!data.isStubbed && !!data.photo) {
        return data.photo as string;
      }

      return blackCar;
    }

    if (isImageExist) {
      return data.thumbnails[0];
    }

    return noPhoto;
  }, [
    blackCar,
    data?.manually_created,
    data.thumbnails,
    data.vin,
    data.isStubbed,
    data.photo,
    isImageExist,
    noPhoto,
    routeOneData,
    user?.isWithInventory,
    whiteCar,
  ]);

  const src = useMemo(() => getVehiclePhotoSrc(), [getVehiclePhotoSrc]);

  const [imageSrc, setImageSrc] = useState<string>(EMPTY_STRING);

  const handleError = useCallback(() => {
    if (defaultVehiclePhoto) setImageSrc(defaultVehiclePhoto);
    else setImageSrc(STATIC_IMAGE_URLS.icons.noPhoto);
  }, [defaultVehiclePhoto]);

  useEffect(() => {
    setImageSrc(EMPTY_STRING);
    const img = new Image();

    img.src = src;
    img.onload = () => {
      setImageSrc(src);
    };

    img.onerror = handleError;
  }, [handleError, src]);

  const dialogTriggerProps = useMemo(
    () =>
      [noPhoto, blackCar, whiteCar].includes(imageSrc) || !!data.isStubbed ? {} : { onClick: openVehicleDetailsDialog },
    [blackCar, imageSrc, noPhoto, openVehicleDetailsDialog, whiteCar, data.isStubbed],
  );

  return (
    <Container
      backgroundColors={loanStatusGradientBackgroundColors as ILoanStatusGradientBackgroundProps}
      noColoring={noColoring}
    >
      {imageSrc.length ? (
        <ImageUI
          src={imageSrc}
          {...dialogTriggerProps}
          onError={handleError}
          isImage={![noPhoto, blackCar, whiteCar].includes(imageSrc) && !data.isStubbed}
        />
      ) : (
        <></>
      )}
    </Container>
  );
};
