import styled from 'styled-components';

export const FooterContainer = styled.div`
  position: relative;
  z-index: 30;
  padding: 0 20px;
  width: 100%;
  height: 45px;
  line-height: 45px;
  background-color: #b2dec7;
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  border-top: 1px solid ${({ theme: { colors } }) => colors['white-2']};

  div {
    display: flex;
    flex-direction: row;
    color: ${({ theme: { colors } }) => colors['grey-5']};
  }
`;

export const FooterLink = styled.span`
  margin-right: 15px;
  cursor: pointer;
`;
