import { logoutUserThunk } from 'actions/userActions';
import { IUser } from 'modules/core/types';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from 'store/selectors/auth.selector';

interface IUseConfirmTabCloseProps {
  canShow?: boolean;
}

const handleBeforeUnload = (event: BeforeUnloadEvent) => {
  event.preventDefault();
  event.returnValue = '';
};

export const removeHandleBeforeUnloadEvent = () => window.removeEventListener('beforeunload', handleBeforeUnload);

export const useConfirmTabClose = ({ canShow = false }: IUseConfirmTabCloseProps) => {
  const user = useSelector(getUser) as IUser;
  const dispatch = useDispatch();

  const handleWithoutInventoryBeforeUnload = useCallback(
    (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue = '';

      window.addEventListener('unload', () => {
        dispatch(logoutUserThunk());
      });
    },
    [dispatch],
  );

  useEffect(() => {
    if (!user?.isWithInventory && canShow) {
      window.addEventListener('beforeunload', handleWithoutInventoryBeforeUnload);
    } else if (canShow) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    }
  }, [canShow, handleWithoutInventoryBeforeUnload, user?.isWithInventory]);
};

export default useConfirmTabClose;
