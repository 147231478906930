import styled from 'styled-components';
import { COLORS } from 'modules/core/theme/colors';

export const LinkButton = styled.button<{ active?: boolean }>`
  display: block;
  margin-bottom: 8px;
  color: ${({ active }) => (active ? COLORS.BLACK : COLORS.BLUE_4)};
  border: none;
  padding: 0;
  background-color: transparent;
`;
