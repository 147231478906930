import styled from 'styled-components';
import { WindowBreakpoints } from 'modules/core/enums/windowBreakpoints.enum';

import { titlePanelMixin } from '../../../theme/mixins';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const Logo = styled.img`
  height: 50px;
  margin: 10px 0 0 0;
`;

export const Title = styled.div`
  display: flex;
  flex-direction: row;
`;

export const FirstPartTitle = styled.h4`
  ${titlePanelMixin};
  font-size: ${({ theme: { fontSize } }) => fontSize.md};
  margin: 10px 5px 10px 0;

  @media (max-width: ${WindowBreakpoints.small}px) {
    font-size: ${({ theme: { fontSize } }) => fontSize.sm};
  }
`;

export const SecondPartTitle = styled.span`
  color: ${({ theme: { colors } }) => colors['grey-5']};
  font-size: ${({ theme: { fontSize } }) => fontSize.md};
  margin: 10px 5px 10px 0;
  line-height: 1.2;

  @media (max-width: ${WindowBreakpoints.small}px) {
    font-size: ${({ theme: { fontSize } }) => fontSize.sm};
  }
`;
