import React from 'react';
import i18n from 'i18n';
import { requiredValidator, validateMinRequiredValue, validateNumberValue } from 'modules/core/validators';
import { Input, Dropdown, DollarAdornment, PercentAdornment } from 'modules/core/components';
import { IField } from 'modules/core/types/common';

import { FIELD_NAMES } from '../approvedLoanInput.const';
import { SUBVENTION_OPTIONS } from './subvention.const';

export const FIRST_COLUMN: IField[] = [
  {
    label: i18n.t('components.approvedLoanInput.approvedLoanTerms.labels.monthlyPayment'),
    name: FIELD_NAMES.APPROVED_MONTHLY_PAYMENT,
    isVerticalAlign: true,
    controlProps: {
      startAdornment: <DollarAdornment />,
      isRequired: true,
      hideInitZeroValue: true,
      rules: requiredValidator,
      validate: validateMinRequiredValue,
      decimalScale: 0,
      placeholder: i18n.t('components.input.placeholders.integer'),
    },
    component: Input,
  },
  {
    label: i18n.t('components.approvedLoanInput.approvedLoanTerms.labels.term'),
    name: FIELD_NAMES.APPROVED_LOAN_TERM,
    isVerticalAlign: true,
    controlProps: {
      isRequired: true,
      hideInitZeroValue: true,
      rules: requiredValidator,
      validate: validateMinRequiredValue,
      decimalScale: 0,
      placeholder: i18n.t('components.input.placeholders.integer'),
    },
    component: Input,
  },
  {
    label: i18n.t('components.approvedLoanInput.approvedLoanTerms.labels.ltv'),
    name: FIELD_NAMES.APPROVED_LTV_RATIO,
    isVerticalAlign: true,
    controlProps: {
      endAdornment: <PercentAdornment />,
      isRequired: true,
      hideInitZeroValue: true,
      rules: requiredValidator,
      validate: validateMinRequiredValue,
      decimalScale: 3,
      placeholder: i18n.t('components.input.placeholders.percent'),
    },
    component: Input,
  },
  {
    label: i18n.t('components.approvedLoanInput.approvedLoanTerms.labels.estimatedAmountFinanced'),
    name: FIELD_NAMES.APPROVED_LOAN_VALUE,
    isVerticalAlign: true,
    controlProps: { startAdornment: <DollarAdornment />, disabled: true, textPosition: 'end' },
    component: Input,
  },
];

export const FIRST_COLUMN_MOBILE: IField[] = [...FIRST_COLUMN.slice(0, -1)];

export const SECOND_COLUMN: IField[] = [
  {
    label: i18n.t('components.approvedLoanInput.approvedLoanTerms.labels.apr'),
    name: FIELD_NAMES.APPROVED_INTEREST_RATE,
    isVerticalAlign: true,
    controlProps: {
      endAdornment: <PercentAdornment />,
      isRequired: true,
      rules: requiredValidator,
      decimalScale: 3,
      validate: validateNumberValue,
      placeholder: i18n.t('components.input.placeholders.percent'),
    },
    component: Input,
  },
  {
    label: i18n.t('components.approvedLoanInput.approvedLoanTerms.labels.subvention'),
    name: FIELD_NAMES.SUBVENTION,
    isVerticalAlign: true,
    controlProps: { options: SUBVENTION_OPTIONS },
    component: Dropdown,
  } as IField,
  {
    label: i18n.t('components.approvedLoanInput.approvedLoanTerms.labels.subventionYear'),
    name: FIELD_NAMES.SUBVENTION_YEAR,
    isVerticalAlign: true,
    controlProps: { options: [] },
    component: Dropdown,
  } as IField,
  {
    label: i18n.t('components.approvedLoanInput.approvedLoanTerms.labels.priceAllowed'),
    name: FIELD_NAMES.PRICE_ALLOWED_VALUE,
    isVerticalAlign: true,
    controlProps: { startAdornment: <DollarAdornment />, disabled: true, textPosition: 'end', allowNegative: true },
    component: Input,
  },
];

export const SECOND_COLUMN_MOBILE: IField[] = [...SECOND_COLUMN.slice(0, -1)];

export const THIRD_COLUMN: IField[] = [
  {
    label: i18n.t('components.approvedLoanInput.approvedLoanTerms.labels.cashDownPayment'),
    name: FIELD_NAMES.CASH_DOWN_PAYMENT,
    isVerticalAlign: true,
    controlProps: {
      startAdornment: <DollarAdornment />,
      hideInitZeroValue: true,
      decimalScale: 0,
      placeholder: i18n.t('components.input.placeholders.integer'),
    },
    component: Input,
  },
  {
    label: i18n.t('components.approvedLoanInput.approvedLoanTerms.labels.tradeValue'),
    controlProps: {
      startAdornment: <DollarAdornment />,
      hideInitZeroValue: true,
      decimalScale: 0,
      placeholder: i18n.t('components.input.placeholders.integer'),
    },
    name: FIELD_NAMES.TRADE_VALUE,
    isVerticalAlign: true,
    component: Input,
  },
  {
    label: i18n.t('components.approvedLoanInput.approvedLoanTerms.labels.amountOwed'),
    name: FIELD_NAMES.AMOUNT_OWED,
    isVerticalAlign: true,
    controlProps: {
      startAdornment: <DollarAdornment />,
      hideInitZeroValue: true,
      decimalScale: 0,
      placeholder: i18n.t('components.input.placeholders.integer'),
    },
    component: Input,
  },
  {
    label: i18n.t('components.approvedLoanInput.approvedLoanTerms.labels.totalCashAndTrade'),
    name: FIELD_NAMES.NET_DOWN_PAYMENT,
    isVerticalAlign: true,
    controlProps: { startAdornment: <DollarAdornment />, disabled: true, textPosition: 'end', allowNegative: true },
    component: Input,
  },
];

export const THIRD_COLUMN_MOBILE: IField[] = [
  ...THIRD_COLUMN.slice(0, 4),
  {
    label: i18n.t('components.approvedLoanInput.approvedLoanTerms.labels.estimatedAmountFinanced'),
    name: FIELD_NAMES.APPROVED_LOAN_VALUE,
    isVerticalAlign: true,
    controlProps: { startAdornment: <DollarAdornment />, disabled: true, textPosition: 'end' },
    component: Input,
  },
  {
    label: i18n.t('components.approvedLoanInput.approvedLoanTerms.labels.priceAllowed'),
    name: FIELD_NAMES.PRICE_ALLOWED_VALUE,
    isVerticalAlign: true,
    controlProps: { startAdornment: <DollarAdornment />, disabled: true, textPosition: 'end', allowNegative: true },
    component: Input,
  },
];
