import React, { FC, useEffect, useState } from 'react';
import { Route, RouteProps, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getAuthState, getUser } from 'store/selectors/auth.selector';
import { useIsRoleAllowed } from 'modules/core/hooks';
import { Loader } from 'modules/core/components';
import { ErrorPage } from 'pages/ErrorPage/ErrorPage';

import { NavigationService } from './navigation.service';
import { ROUTES_PERMISSIONS_MAP } from './const/common.const';
import { getLendersList } from '../store/selectors/lenders.selector';
import { getDisabledFeaturesList } from '../store/selectors/ui.selector';
import { LOGIN_URL_PREFIX } from '../modules/core/constants';
import { ROUTER_PATH } from './const/routerPath.const';

export interface IRouterItem extends RouteProps {
  isPrivate?: boolean;
  isAccessibleToAll?: boolean;
}

export const RouterItem: FC<IRouterItem> = ({
  component,
  render,
  path,
  exact,
  isPrivate = false,
  isAccessibleToAll,
}: IRouterItem) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const { isAuth, isFetching } = useSelector(getAuthState);
  const lenders = useSelector(getLendersList);
  const disabledFeaturesList = useSelector(getDisabledFeaturesList);
  const isRoleAllowed = useIsRoleAllowed(ROUTES_PERMISSIONS_MAP.get(path as string));
  const user = useSelector(getUser);

  useEffect(() => {
    setIsLoading(isFetching);

    if (!isAuth && isPrivate) {
      NavigationService.navigateToLogin();
    }

    if (
      isAuth &&
      user &&
      user.tenants &&
      window.location.pathname.includes('login') &&
      window.location.hostname.includes(LOGIN_URL_PREFIX)
    ) {
      history.push(ROUTER_PATH.SELECT_DEALER);
    }

    if (
      isAuth &&
      user &&
      !isPrivate &&
      !isAccessibleToAll &&
      window.location.pathname.includes('login') &&
      (!user.tenants || !window.location.hostname.includes(LOGIN_URL_PREFIX))
    ) {
      setIsLoading(true);
      NavigationService.navigateToDashboard(user, disabledFeaturesList, lenders);
    }
  }, [user, isAuth, isPrivate, isAccessibleToAll, isFetching, setIsLoading, disabledFeaturesList, lenders, history]);

  return isLoading ? (
    <Loader />
  ) : (
    <Route
      render={render}
      component={isRoleAllowed || !user?.isWithInventory ? component : ErrorPage}
      path={path}
      exact={exact}
    />
  );
};
