import { createAsyncThunk } from '@reduxjs/toolkit';

import { ACTION_PREFIX } from './actionPrefix.const';
import { passengerCapacitiesApi } from '../api';

export const getPassengerCapacitiesThunk = createAsyncThunk(
  `${ACTION_PREFIX.VEHICLE}/getPassengerCapacities`,
  async () => {
    const { data } = await passengerCapacitiesApi.getPassengerCapacities();

    return data;
  },
);
