import styled from 'styled-components';
import { ErrorMessage } from 'modules/auth/styles';
import { COLORS } from 'modules/core/theme/colors';

export const LabelTitle = styled.div`
  position: relative;
  padding: 8px 0;
  font-size: ${({ theme: { fontSize } }) => fontSize.sm};
`;

export const LabelSubTitle = styled.div`
  position: absolute;
  top: 0.15em;
  left: 5.2em;
  font-size: ${({ theme: { fontSize } }) => fontSize.md};
`;

export const ErrorMessageUI = styled(ErrorMessage)`
  font-size: 12px;
  margin-bottom: 16px;
  text-align: center;
  color: ${COLORS.RED_6};
`;
