import React from 'react';
import i18n from 'i18n';
import { Input } from 'modules/core/components/Input/Input';
import { IField } from 'modules/core/types';
import { CalculatorAdornment, DollarAdornment } from 'modules/core/components';
import { ColumnWidth } from 'modules/core/enums';
import { requiredValidator, validateMinRequiredValue, validateRequiredMinimumValue } from 'modules/core/validators';
import { MONEY_SYMBOL } from 'modules/core/constants';

import { LOAN_VARIABLES_FIELDS_NAMES } from './fieldNames.const';
import { ConfirmCheckBox } from '../components/common/ConfirmCheckBox/ConfirmCheckBox';
import { LabelField } from '../components/common/Label/LabelField';
import { Dropdown } from '../components/common/Dropdown/Dropdown';

export const RAC_MW_VEHICLE_VALUATION_FIELDS: IField[] = [
  {
    label: i18n.t('components.loanVariablesModal.fields.retailPrice'),
    name: LOAN_VARIABLES_FIELDS_NAMES.RETAIL_PRICE,
    component: Input,
    controlProps: {
      fieldWidth: ColumnWidth.FULL,
      textStyle: 'bold',
      textPosition: 'start',
      decimalScale: 0,
      required: true,
      startAdornment: <DollarAdornment />,
      rules: requiredValidator,
      validate: validateRequiredMinimumValue,
      placeholder: i18n.t('components.input.placeholders.integer'),
      hideInitZeroValue: true,
    },
  },
  {
    name: LOAN_VARIABLES_FIELDS_NAMES.MILEAGE,
    component: Input,
    label: i18n.t('components.loanVariablesModal.fields.mileage'),
    isVerticalAlign: true,
    controlProps: {
      textStyle: 'bold',
      textPosition: 'start',
      required: true,
      decimalScale: 0,
      rules: requiredValidator,
      maxLength: 7,
      validate: validateRequiredMinimumValue,
      placeholder: i18n.t('components.input.placeholders.integer'),
    },
  },
  {
    name: LOAN_VARIABLES_FIELDS_NAMES.GUIDEBOOK_VALUE,
    component: Input,
    label: i18n.t('components.loanVariablesModal.fields.jdpTradeIn'),
    isVerticalAlign: true,
    controlProps: {
      textStyle: 'bold',
      textPosition: 'start',
      required: true,
      decimalScale: 0,
      rules: requiredValidator,
      validate: validateRequiredMinimumValue,
      startAdornment: <DollarAdornment />,
      placeholder: i18n.t('components.input.placeholders.integer'),
      hideInitZeroValue: true,
    },
  },
  {
    name: LOAN_VARIABLES_FIELDS_NAMES.CONFIRM_BOOK,
    component: ConfirmCheckBox,
    textLabel: i18n.t('components.loanVariablesModal.confirmTexts.book'),
  },
];

export const RAC_MW_VEHICLE_VALUATION_LABELS: IField[] = [
  {
    label: i18n.t('components.loanVariablesModal.fields.retailPrice'),
    name: LOAN_VARIABLES_FIELDS_NAMES.RETAIL_PRICE,
    component: LabelField,
    controlProps: {
      textStyle: 'bold',
      fieldWidth: ColumnWidth.FULL,
      symbol: MONEY_SYMBOL,
      decimalScale: 3,
      placeholder: i18n.t('components.input.placeholders.integer'),
    },
  },
  {
    name: LOAN_VARIABLES_FIELDS_NAMES.MILEAGE,
    component: LabelField,
    label: i18n.t('components.loanVariablesModal.fields.mileage'),
    isVerticalAlign: true,
    controlProps: {
      textStyle: 'bold',
      fieldWidth: ColumnWidth.FULL,
      placeholder: i18n.t('components.input.placeholders.integer'),
      isNeedFormat: false,
    },
  },
  {
    name: LOAN_VARIABLES_FIELDS_NAMES.GUIDEBOOK_VALUE,
    component: LabelField,
    label: i18n.t('components.loanVariablesModal.fields.jdpTradeIn'),
    isVerticalAlign: true,
    controlProps: {
      fieldWidth: ColumnWidth.FULL,
      textStyle: 'bold',
      decimalScale: 3,
      placeholder: i18n.t('components.input.placeholders.integer'),
    },
  },
];

export const RAC_MW_FINANCING_TERMS_LABELS: IField[] = [
  {
    label: i18n.t('components.loanVariablesModal.fields.cashDown'),
    name: LOAN_VARIABLES_FIELDS_NAMES.CASH_DOWN,
    component: LabelField,
    controlProps: {
      textStyle: 'bold',
      fieldWidth: ColumnWidth.FULL,
      decimalScale: 0,
      placeholder: i18n.t('components.input.placeholders.integer'),
    },
  },
  {
    label: i18n.t('components.loanVariablesModal.fields.rebates'),
    name: LOAN_VARIABLES_FIELDS_NAMES.REBATES,
    component: LabelField,
    controlProps: {
      textStyle: 'bold',
      fieldWidth: ColumnWidth.FULL,
      decimalScale: 0,
      placeholder: i18n.t('components.input.placeholders.integer'),
    },
  },
  {
    name: LOAN_VARIABLES_FIELDS_NAMES.NET_TRADE_IN,
    component: LabelField,
    label: i18n.t('components.loanVariablesModal.fields.netTradeIn'),
    isVerticalAlign: true,
    controlProps: {
      textStyle: 'bold',
      fieldWidth: ColumnWidth.FULL,
      decimalScale: 3,
    },
  },
];

export const RAC_MW_VEHICLE_ENHANCEMENTS_LABELS: IField[] = [
  {
    label: i18n.t('components.loanVariablesModal.fields.addOns'),
    name: LOAN_VARIABLES_FIELDS_NAMES.ADD_ONS,
    component: LabelField,
    controlProps: {
      textStyle: 'bold',
      fieldWidth: ColumnWidth.FULL,
      decimalScale: 0,
      placeholder: i18n.t('components.input.placeholders.integer'),
    },
  },
  {
    label: i18n.t('components.loanVariablesModal.fields.vsc'),
    name: LOAN_VARIABLES_FIELDS_NAMES.VSC,
    component: LabelField,
    controlProps: {
      textStyle: 'bold',
      fieldWidth: ColumnWidth.FULL,
      decimalScale: 0,
      placeholder: i18n.t('components.input.placeholders.integer'),
    },
  },
  {
    label: i18n.t('components.loanVariablesModal.fields.gap'),
    name: LOAN_VARIABLES_FIELDS_NAMES.GAP,
    component: LabelField,
    controlProps: {
      textStyle: 'bold',
      fieldWidth: ColumnWidth.FULL,
      decimalScale: 0,
      placeholder: i18n.t('components.input.placeholders.integer'),
    },
  },
];

export const RAC_MW_TRANSACTION_COSTS_LABELS: IField[] = [
  {
    label: i18n.t('components.loanVariablesModal.fields.loanTerm'),
    name: LOAN_VARIABLES_FIELDS_NAMES.LOAN_TERM,
    component: LabelField,
    controlProps: {
      fieldWidth: ColumnWidth.FULL,
      textStyle: 'bold',
      decimalScale: 3,
      isNeedFormat: false,
    },
  },
  {
    name: LOAN_VARIABLES_FIELDS_NAMES.TOTAL_SALES_FEES,
    component: LabelField,
    label: i18n.t('components.loanVariablesModal.fields.salesFees'),
    isVerticalAlign: true,
    controlProps: {
      fieldWidth: ColumnWidth.FULL,
      decimalScale: 0,
    },
  },
  {
    label: i18n.t('components.loanVariablesModal.fields.taxes'),
    name: LOAN_VARIABLES_FIELDS_NAMES.TAXES,
    component: LabelField,
    controlProps: {
      fieldWidth: ColumnWidth.FULL,
      decimalScale: 0,
    },
  },
];

export const RAC_MW_TOTAL_AMOUNT_FINANCED_LABEL: IField = {
  label: i18n.t('components.loanVariablesModal.fields.totalAmountFinanced'),
  name: LOAN_VARIABLES_FIELDS_NAMES.TOTAL_AMOUNT_FINANCED,
  component: LabelField,
  controlProps: {
    textStyle: 'bold',
    fieldWidth: ColumnWidth.FULL,
    decimalScale: 3,
    placeholder: i18n.t('components.input.placeholders.integer'),
  },
};

export const MONTHLY_INCOME_LABEL: IField = {
  label: i18n.t('components.loanVariablesModal.fields.monthlyIncome'),
  name: LOAN_VARIABLES_FIELDS_NAMES.MONTHLY_INCOME,
  component: LabelField,
  controlProps: {
    textStyle: 'bold',
    fieldWidth: ColumnWidth.FULL,
    decimalScale: 3,
  },
};

export const PAYMENT_LABEL: IField = {
  label: i18n.t('components.loanVariablesModal.fields.approvedPayment'),
  name: LOAN_VARIABLES_FIELDS_NAMES.PAYMENT,
  component: LabelField,
  controlProps: {
    fieldWidth: ColumnWidth.FULL,
    decimalScale: 3,
  },
};

export const RAC_MW_FINANCING_TERMS_FIELDS: IField[] = [
  {
    label: i18n.t('components.loanVariablesModal.fields.cashDown'),
    name: LOAN_VARIABLES_FIELDS_NAMES.CASH_DOWN,
    component: Input,
    controlProps: {
      textStyle: 'bold',
      startAdornment: <DollarAdornment />,
      fieldWidth: ColumnWidth.FULL,
      decimalScale: 0,
      placeholder: i18n.t('components.input.placeholders.integer'),
      hideInitZeroValue: true,
    },
  },
  {
    label: i18n.t('components.loanVariablesModal.fields.rebates'),
    name: LOAN_VARIABLES_FIELDS_NAMES.REBATES,
    component: Input,
    controlProps: {
      textStyle: 'bold',
      startAdornment: <DollarAdornment />,
      fieldWidth: ColumnWidth.FULL,
      decimalScale: 0,
      placeholder: i18n.t('components.input.placeholders.integer'),
      hideInitZeroValue: true,
    },
  },
  {
    name: LOAN_VARIABLES_FIELDS_NAMES.NET_TRADE_IN,
    component: Input,
    label: i18n.t('components.loanVariablesModal.fields.netTradeIn'),
    isVerticalAlign: true,
    controlProps: {
      allowNegative: true,
      readonly: true,
      startAdornment: <DollarAdornment />,
      textStyle: 'bold',
      textPosition: 'start',
      endAdornment: <CalculatorAdornment />,
      decimalScale: 3,
      hideInitZeroValue: true,
      placeholder: i18n.t('components.input.placeholders.integer'),
    },
  },
];

export const RAC_MW_VEHICLE_ENHANCEMENTS_FEILDS: IField[] = [
  {
    label: i18n.t('components.loanVariablesModal.fields.addOns'),
    name: LOAN_VARIABLES_FIELDS_NAMES.ADD_ONS,
    component: Input,
    controlProps: {
      textStyle: 'bold',
      fieldWidth: ColumnWidth.FULL,
      decimalScale: 0,
      startAdornment: <DollarAdornment />,
      placeholder: i18n.t('components.input.placeholders.integer'),
      hideInitZeroValue: true,
    },
  },
  {
    label: i18n.t('components.loanVariablesModal.fields.vscWithNumber'),
    name: LOAN_VARIABLES_FIELDS_NAMES.VSC,
    component: Input,
    controlProps: {
      textStyle: 'bold',
      fieldWidth: ColumnWidth.FULL,
      decimalScale: 0,
      startAdornment: <DollarAdornment />,
      placeholder: i18n.t('components.input.placeholders.integer'),
      hideInitZeroValue: true,
    },
  },
  {
    label: i18n.t('components.loanVariablesModal.fields.gapWithNumber'),
    name: LOAN_VARIABLES_FIELDS_NAMES.GAP,
    component: Input,
    controlProps: {
      textStyle: 'bold',
      fieldWidth: ColumnWidth.FULL,
      decimalScale: 0,
      startAdornment: <DollarAdornment />,
      placeholder: i18n.t('components.input.placeholders.integer'),
      hideInitZeroValue: true,
    },
  },
];

export const RAC_MW_TRANSACTION_COSTS_FIELDS: IField[] = [
  {
    label: i18n.t('components.loanVariablesModal.fields.loanTerm'),
    name: LOAN_VARIABLES_FIELDS_NAMES.LOAN_TERM,
    component: Dropdown,
    controlProps: {
      textStyle: 'bold',
      fieldWidth: ColumnWidth.FULL,
      required: true,
      rules: requiredValidator,
      validate: validateMinRequiredValue,
    },
  },
  {
    name: LOAN_VARIABLES_FIELDS_NAMES.TOTAL_SALES_FEES,
    component: Input,
    label: i18n.t('components.loanVariablesModal.fields.salesFees'),
    isVerticalAlign: true,
    controlProps: {
      readonly: true,
      textStyle: 'bold',
      textPosition: 'start',
      endAdornment: <CalculatorAdornment />,
      required: true,
      decimalScale: 0,
      startAdornment: <DollarAdornment />,
      rules: requiredValidator,
      hideInitZeroValue: true,
      placeholder: i18n.t('components.input.placeholders.integer'),
    },
  },
  {
    label: i18n.t('components.loanVariablesModal.fields.taxes'),
    name: LOAN_VARIABLES_FIELDS_NAMES.TAXES,
    component: Input,
    controlProps: {
      textStyle: 'bold',
      fieldWidth: ColumnWidth.FULL,
      required: true,
      rules: requiredValidator,
      decimalScale: 0,
      startAdornment: <DollarAdornment />,
      placeholder: i18n.t('components.input.placeholders.integer'),
    },
  },
  {
    name: LOAN_VARIABLES_FIELDS_NAMES.CONFIRM_TAXES,
    component: ConfirmCheckBox,
    textLabel: i18n.t('components.loanVariablesModal.confirmTexts.taxes'),
  },
];

export const RAC_MW_CONFIRM_SUBMIT_FIELD: IField = {
  name: LOAN_VARIABLES_FIELDS_NAMES.CONFIRM_SUBMIT,
  component: ConfirmCheckBox,
  textLabel: i18n.t('components.loanVariablesModal.confirmTexts.dealRefresh'),
  controlProps: {
    styles: {
      padding: '0',
      margin: '0',
      maxWidth: '30rem',
    },
  },
};

export const RAC_MW_TOTAL_AMOUNT_FINANCED_FIELD: IField = {
  label: i18n.t('components.loanVariablesModal.fields.totalAmountFinanced'),
  name: LOAN_VARIABLES_FIELDS_NAMES.TOTAL_AMOUNT_FINANCED,
  component: Input,
  controlProps: {
    disabled: true,
    textStyle: 'bold',
    fieldWidth: ColumnWidth.FULL,
    startAdornment: <DollarAdornment />,
    decimalScale: 3,
    placeholder: i18n.t('components.input.placeholders.integer'),
  },
};

export const MONTHLY_INCOME_FIELD: IField = {
  label: i18n.t('components.loanVariablesModal.fields.monthlyIncome'),
  name: LOAN_VARIABLES_FIELDS_NAMES.MONTHLY_INCOME,
  component: Input,
  controlProps: {
    textStyle: 'bold',
    fieldWidth: ColumnWidth.FULL,
    startAdornment: <DollarAdornment />,
    textPosition: 'start',
    endAdornment: <CalculatorAdornment />,
    decimalScale: 0,
    readonly: true,
    placeholder: i18n.t('components.input.placeholders.integer'),
    required: true,
    rules: requiredValidator,
    validate: validateRequiredMinimumValue,
  },
};

export const CURRENT_PAYMENT_FIELD: IField = {
  label: i18n.t('components.loanVariablesModal.fields.currentPayment'),
  name: LOAN_VARIABLES_FIELDS_NAMES.CURRENT_PAYMENT,
  component: Input,
  controlProps: {
    textStyle: 'bold',
    fieldWidth: ColumnWidth.FULL,
    startAdornment: <DollarAdornment />,
    textPosition: 'start',
    disabled: true,
    placeholder: i18n.t('components.input.placeholders.integer'),
  },
};

export const CURRENT_PAYMENT_LABEL: IField = {
  label: i18n.t('components.loanVariablesModal.fields.currentPayment'),
  name: LOAN_VARIABLES_FIELDS_NAMES.CURRENT_PAYMENT,
  component: LabelField,
  controlProps: {
    textStyle: 'bold',
    fieldWidth: ColumnWidth.FULL,
  },
};
