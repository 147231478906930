import i18n from 'i18n';
import { COLON_SYMBOL, COMMA_SYMBOL, COMMA_WITH_BLANK_SPACE, EMPTY_STRING } from 'modules/core/constants';
import { IOption } from 'modules/core/types';
import { SetFieldValue } from 'react-hook-form';
import { Option } from 'react-multi-select-component/dist';

import { IVehicleModel } from '../VehicleFilters/types';
import { FILTERS_FIELD_NAMES } from '../VehicleFilters/vehicleFilters.const';
import { ALL_VALUES, MAP_MULTISELECT_GROUP_OPTIONS_BY_SELECT_NAME } from './Multiselect.const';

export interface IMultiselectMakeOption extends IOption {
  make_type?: string;
}

export const convertOptionsToMultiselectFormat = (options: IOption[]): Option[] =>
  options.map((option) => ({ label: option.title, value: option.value ? option.value : option.title }));

export const convertFormValueToMultiselectOptions = (formValue: string): Option[] =>
  formValue.split(COMMA_WITH_BLANK_SPACE).map((option: string) => ({
    label: option.includes(COLON_SYMBOL) ? option.split(COLON_SYMBOL)[1] : option,
    value: option,
  }));

const filterCurrentMultiselectGroupOptions = (
  currentMultiselectGroupOptions: string[] | undefined,
  multiselectOptions: Option[],
) => {
  if (currentMultiselectGroupOptions) {
    return multiselectOptions.filter(
      (multiselectOption: Option) =>
        !currentMultiselectGroupOptions.some((groupOption) => groupOption === multiselectOption.value),
    );
  }

  return multiselectOptions;
};

// Handler 'All' checkbox
/* eslint-disable no-param-reassign */
const toggleSelectAllOption = (
  multiselectName: string,
  currentMultiselectValues: Option[],
  multiselectOptions: Option[],
  currentOptionValue: Record<string, any>,
) => {
  if (typeof currentOptionValue.name !== 'string') return currentMultiselectValues;

  const currentMultiselectGroupOptions = MAP_MULTISELECT_GROUP_OPTIONS_BY_SELECT_NAME.get(multiselectName);

  if (currentOptionValue.name === ALL_VALUES) {
    // If select 'All' checkbox  => select all checkboxes
    if (currentOptionValue.selected) {
      return filterCurrentMultiselectGroupOptions(currentMultiselectGroupOptions, multiselectOptions);
    }

    // If unselect 'All' checkbox  => unselect all checkboxes
    return [];
  }

  // if 'All' is checked and some other option was unchecked - uncheck 'All' + select and show all checked values in the multiselect value field instead of 'All'
  if (
    currentMultiselectValues.some((option) => option.value === ALL_VALUES) &&
    !(currentOptionValue.name === ALL_VALUES) &&
    !currentOptionValue.selected
  ) {
    return currentMultiselectValues.filter((multiselectOption: Option) => !(multiselectOption.value === ALL_VALUES));
  }

  const filteredCurrentMultiselectValues = filterCurrentMultiselectGroupOptions(
    currentMultiselectGroupOptions,
    multiselectOptions,
  );

  if (
    filteredCurrentMultiselectValues.length - 1 === currentMultiselectValues.length &&
    !(currentOptionValue.name === ALL_VALUES)
  ) {
    return filteredCurrentMultiselectValues;
  }

  return currentMultiselectValues;
};

const filterMakeTypeGroupOptionValues = (
  multiselectOptions: Option[],
  initialOptions: IMultiselectMakeOption[],
  makeTypeOptionValueToExclude: string,
) =>
  multiselectOptions.filter((multiselectOption: Option) => {
    let excludedOption = false;

    ((initialOptions as unknown) as IMultiselectMakeOption[]).forEach((option) => {
      if (
        (multiselectOption.value === option.value && option.make_type === makeTypeOptionValueToExclude.toUpperCase()) ||
        multiselectOption.value === makeTypeOptionValueToExclude
      ) {
        excludedOption = true;
      }
    });

    return !(excludedOption || multiselectOption.label === i18n.t('components.vehicleFilters.options.all'));
  });

// Handler Domestic, Import etc. groups
const toggleMultiselectGroup = (
  multiselectName: string,
  currentMultiselectValues: Option[],
  initialOptions: IMultiselectMakeOption[],
  multiselectOptions: Option[],
  currentOptionValue: Record<any, any>,
) => {
  const currentMultiselectGroupOptions = MAP_MULTISELECT_GROUP_OPTIONS_BY_SELECT_NAME.get(multiselectName);

  // If unselect Domestic => unselect Domestic and Domestic group checkboxes
  if (currentMultiselectGroupOptions?.includes(currentOptionValue.name) && !currentOptionValue.selected) return [];

  // if change (unselect => select) Domestic => select Domestic and Domestic group checkboxes
  if (
    currentMultiselectGroupOptions?.some((groupOption) => groupOption === currentOptionValue.name) &&
    currentOptionValue.selected
  ) {
    const groupOptionNameToExclude = currentMultiselectGroupOptions
      ? currentMultiselectGroupOptions.filter((groupOption: string) => groupOption !== currentOptionValue.name)[0]
      : EMPTY_STRING;

    return filterMakeTypeGroupOptionValues(multiselectOptions, initialOptions, groupOptionNameToExclude);
  }

  // if Domestic checkbox selected and change some checkbox => uncheck Domestic checkbox
  if (
    currentMultiselectValues.some((option) =>
      currentMultiselectGroupOptions?.some((groupOption) => groupOption === option.value),
    )
  ) {
    /* eslint-disable prettier/prettier */
    const currentSelectedOptionGroup = currentMultiselectValues.filter(
      (multiselectOption: Option) =>
        currentMultiselectGroupOptions?.some((groupOption: string) => groupOption === multiselectOption.value),
    )[0].value;

    return currentMultiselectValues.filter((option) => option.value !== currentSelectedOptionGroup)
  }

  // If All, Domestic, Import etc. unselected and change same other checkbox =>
  //   if checkboxes match Domestic => select Domestic checkbox
  if (
    !currentMultiselectValues.some((option) =>
      currentMultiselectGroupOptions?.some((groupOption) => groupOption === option.value)
      || option.value === ALL_VALUES,
    )
  ) {
    currentMultiselectGroupOptions?.forEach(groupOption => {
      const currentGroupOptions = initialOptions.filter(initialOption => initialOption?.make_type?.toLowerCase() === groupOption.toLowerCase());
      const matchingOptions = currentGroupOptions.filter(currentOption => currentMultiselectValues.find(currentValue => currentValue.value === currentOption.value))

      if(currentGroupOptions.length === matchingOptions.length && currentGroupOptions.length === currentMultiselectValues.length) {
        const currentCroupOption: Option = {label: groupOption, value: groupOption};

        currentMultiselectValues.unshift(currentCroupOption);
      }

    });
  }

  return currentMultiselectValues;
};

export const setModelOptionsOnMakesToggle = (
  multiselectName: string,
  currentOptionValue: Record<string, any>,
  currentModelsFormValue: string,
  vehicleModels: IVehicleModel[],
  currentMultiselectValues: Option[],
  setValue: SetFieldValue<any>,
): void => {
  if (multiselectName === FILTERS_FIELD_NAMES.MAKES && !currentOptionValue.selected) {
    const selectedModelsArr = currentModelsFormValue?.split(COMMA_SYMBOL).map((param: string) => param.trim());
    const currentMakeModelsObj = vehicleModels.filter(
      (item: IVehicleModel) => Object.keys(item)[0] === currentOptionValue.name,
    )[0];

    const currentMakeModelsArr = (currentMakeModelsObj as Record<string, any>)?.[currentOptionValue.name].map(
      (item: IVehicleModel) => item.name,
    );
    /* eslint-disable @typescript-eslint/indent */
    const filteredModels = selectedModelsArr
      ? selectedModelsArr
          .filter((currentModel: string) => currentMakeModelsArr?.indexOf(currentModel) === -1)
          .join(COMMA_WITH_BLANK_SPACE)
      : EMPTY_STRING;

    setValue(FILTERS_FIELD_NAMES.MODELS, filteredModels);
  }

  if (multiselectName === FILTERS_FIELD_NAMES.MAKES && !currentMultiselectValues.length) {
    setValue(FILTERS_FIELD_NAMES.MODELS, EMPTY_STRING);
  }
};

export const toggleOption = (
  multiselectName: string,
  currentMultiselectValues: Option[],
  initialOptions: IMultiselectMakeOption[],
  multiselectOptions: Option[],
  currentOptionValue: Record<any, any>,
): Option[] => {
  const currentSelectValues = toggleSelectAllOption(
    multiselectName,
    currentMultiselectValues,
    multiselectOptions,
    currentOptionValue,
  );

  return toggleMultiselectGroup(
    multiselectName,
    currentSelectValues,
    initialOptions,
    multiselectOptions,
    currentOptionValue,
  );
};
