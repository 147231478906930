import { AxiosError, AxiosResponse } from 'axios';

import { ERROR_401_UNAUTHORIZED } from '../pages/ErrorPage/error.const';
import { logoutUserThunk } from '../actions/userActions';
import { store } from '../store/store';

export const responseOnFulfilled = <D = unknown>(response: AxiosResponse<D>): AxiosResponse<D> => response;

export const responseOnReject = async (error: AxiosError): Promise<AxiosError> => {
  if (error.response?.status === ERROR_401_UNAUTHORIZED) {
    store.dispatch(logoutUserThunk());
  }

  return Promise.reject(error);
};
