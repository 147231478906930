import React, { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IVehicle } from 'modules/core/types';
import {
  colonFormatter,
  costFormatter,
  getExtraCashConversionValue,
  thousandFormatter,
  vinFormatter,
} from 'modules/core/utils';
import { Badge, Stock } from 'modules/core/components';
import { useBackgroundPropsByLoanStatus, useModalState, useVehicleStatusBadgeProps } from 'modules/core/hooks';
import { useFormContext } from 'react-hook-form';
import { TableView } from 'modules/core/enums';
import { useDispatch } from 'react-redux';
import { Image as ImageUI } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { ROUTER_PATH } from 'router/const/routerPath.const';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { setVehicleDetails } from 'store/reducers/vehicle.reducer';
import { MODAL_DIALOG_ID, STATIC_IMAGE_URLS } from 'modules/core/constants';
import { AppDispatch } from 'store/store';
import { VINSpan } from 'common/styles';

import { CARD_COLOR_MAP, CARD_HEADER_COLOR_MAP } from './common.const';
import { Card, CardHeader, CardRow, CardDescription, CardImageContainer, CardCheckbox } from './styles';
import { showNotification } from '../../../../../../store/reducers/ui.reducer';

export interface IVehicleCard {
  vehicle: IVehicle;
}

export const VehicleCard: FC<IVehicleCard> = ({ vehicle, children }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { handleOpenDialog } = useModalState([MODAL_DIALOG_ID.VEHICLE_DETAILS]);
  const { t } = useTranslation();
  const badgeProps = useVehicleStatusBadgeProps(vehicle.age, vehicle.is_new, vehicle.is_sold);
  const loanStatusBackgroundColor = useBackgroundPropsByLoanStatus(vehicle.loan_status, CARD_COLOR_MAP);
  const loanStatusHeaderBackgroundColor = useBackgroundPropsByLoanStatus(vehicle.loan_status, CARD_HEADER_COLOR_MAP);
  const { watch } = useFormContext();
  const { tableView } = watch();
  const { pathname } = useLocation();

  const isImageExist = !!vehicle.full_size_photo[0];

  const isExtraCashFormatted = pathname === ROUTER_PATH.PROBABILITY_ESTIMATOR;

  const openVehicleDetailsDialog = useCallback(() => {
    dispatch(setVehicleDetails({ id: vehicle.id, title: vehicle.description }));
    handleOpenDialog();
  }, [handleOpenDialog, dispatch, vehicle]);

  const src = useMemo(() => (isImageExist ? vehicle.full_size_photo[0] : STATIC_IMAGE_URLS.icons.noPhoto), [
    vehicle,
    isImageExist,
  ]);

  const [imageSrc, setImageSrc] = useState<string>(src);

  const dialogTriggerProps = useMemo(
    () => (imageSrc === STATIC_IMAGE_URLS.icons.noPhoto ? {} : { onClick: openVehicleDetailsDialog }),
    [imageSrc, openVehicleDetailsDialog],
  );

  const handleError = () => {
    setImageSrc(STATIC_IMAGE_URLS.icons.noPhoto);
  };

  return (
    <Card key={vehicle.id} color={loanStatusBackgroundColor as string}>
      <CardCheckbox>{children}</CardCheckbox>
      <CardHeader color={loanStatusHeaderBackgroundColor as string}>
        <Stock stock={vehicle.stock} />
        <CopyToClipboard
          text={vehicle.vin}
          onCopy={() => dispatch(showNotification({ message: t('successfullyCopied'), type: 'successful' }))}
        >
          <VINSpan title={t('components.table.vinHelpMessage')}>{vinFormatter(vehicle.vin)}</VINSpan>
        </CopyToClipboard>
      </CardHeader>
      <CardImageContainer>
        <ImageUI src={imageSrc} onError={handleError} {...dialogTriggerProps} />
      </CardImageContainer>
      <CardDescription>{vehicle.description}</CardDescription>
      <CardRow>{badgeProps && <Badge {...badgeProps} />}</CardRow>
      <CardRow>
        <span>{colonFormatter(t('components.table.columns.location'))}</span>
        <span>{vehicle.dealer.shortname}</span>
      </CardRow>
      <CardRow>
        <span>{colonFormatter(t('components.table.columns.miles'))}</span>
        <span>{thousandFormatter(vehicle.odometer)}</span>
      </CardRow>
      <CardRow>
        <span>{colonFormatter(t('components.table.columns.retailPrice'))}</span>
        <span>{costFormatter(vehicle.current_price)}</span>
      </CardRow>
      {tableView === TableView.SALESPERSON && typeof vehicle.additional_down_payment !== 'undefined' && (
        <CardRow>
          <span>{colonFormatter(t('components.table.columns.extraCash'))}</span>
          <span>
            {isExtraCashFormatted
              ? getExtraCashConversionValue(vehicle.additional_down_payment)
              : costFormatter(vehicle.additional_down_payment)}
          </span>
        </CardRow>
      )}
    </Card>
  );
};
