import styled from 'styled-components';
import { Col, Row } from 'react-bootstrap';

export const RowUI = styled(Row)`
  margin: 0;
`;

export const ColUI = styled(Col)`
  padding: 0 4px;
  box-sizing: border-box;

  &:first-child {
    font-weight: ${({ theme: { boldText } }) => boldText.dark};
  }
`;
