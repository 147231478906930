import React, { FC, ReactNode } from 'react';
import { Card } from 'react-bootstrap';

import { Divider } from '../Divider';
import { Title } from '../Title';

const { Body } = Card;

interface IPanel {
  title?: string;
  headerComponent?: ReactNode;
}

export const Panel: FC<IPanel> = ({ children, headerComponent, title }) => (
  <Card>
    {title && (
      <>
        <Title>
          <span>{title}</span>
          {headerComponent}
        </Title>
        <Divider />
      </>
    )}
    <Body>{children}</Body>
  </Card>
);
