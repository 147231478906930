import React from 'react';
import { Header, SideBar, Footer, Notification } from 'modules/core/components';
import { ContainerFluid, PageContainer, PageContent } from 'common/styles';

interface IMainLayout {
  children: React.ReactNode;
  isSinglePage: boolean;
}

export const MainLayout: React.FC<IMainLayout> = ({ children, isSinglePage }) => (
  <ContainerFluid fluid>
    <Notification />
    <Header />
    <PageContainer>
      <SideBar />
      <PageContent isSinglePage={isSinglePage} fluid>
        {children}
      </PageContent>
    </PageContainer>
    <Footer />
  </ContainerFluid>
);
