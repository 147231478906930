import React, { FC } from 'react';
import { useRenderFields } from 'modules/core/hooks/useRenderFields';
import { IField } from 'modules/core/types';

import { Panel } from '../Panel/Panel';

interface IPanelWithFields {
  fieldsConfig: IField[];
  title: string;
}

export const PanelWithFields: FC<IPanelWithFields> = ({ fieldsConfig, title }) => {
  const fields = useRenderFields(fieldsConfig);

  return <Panel title={title}>{fields}</Panel>;
};
