import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import Preloader from 'components/common/Preloader/Preloader';
import { EndUserAgreement } from 'components/Modals';
import { AppDispatch } from 'store/store';
import { confirmPasswordResetThunk, verificationThunk } from 'actions/authActions';
import { minLengthPasswordValidation } from 'modules/core/rules';
import { ROUTER_PATH } from 'router/const/routerPath.const';

import {
  AgreementCreatePassword,
  ErrorMessage,
  FormGroupAcceptTermsConditions,
  FormPosition,
  Link,
  LinkCreatePassword,
  LoginFormCheck,
  TextForgotPassword,
} from '../styles';
import { IChangePasswordFormValues } from '../auth';
import { BackToLoginLink, Button, PasswordInput } from '../common';

interface ICreatePasswordForm {
  onShowCongratulationsContent: VoidFunction;
}

export const CreatePasswordForm: React.FC<ICreatePasswordForm> = ({ onShowCongratulationsContent }) => {
  const location = useLocation();
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const [expiredKey, setExpiredKey] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [showEndUserAgreement, setShowEndUserAgreement] = useState(false);
  const {
    register,
    handleSubmit,
    setError,
    trigger,
    getValues,
    formState: { errors, isValid, isSubmitted },
  } = useForm<IChangePasswordFormValues>({ mode: 'onChange' });

  const onSubmit = useCallback(
    async (data: IChangePasswordFormValues) => {
      setShowSpinner(true);

      const { payload } = await dispatch(
        location.pathname.includes(ROUTER_PATH.VERIFICATION)
          ? verificationThunk(data)
          : confirmPasswordResetThunk(data),
      );

      if (payload.detail) {
        setExpiredKey(true);
      } else if (payload.password1) {
        if (payload.password1[0] === t('common.errorMessages.passwordsDontMatch')) {
          setError('password2', { message: payload.password1[0] });
        } else {
          setError('password1', { message: payload.password1[0] });
        }
      } else if (payload.password2) {
        setError('password2', { message: payload.password2[0] });
      } else if (payload.key) {
        setError('password2', {
          message: t('common.errorMessages.linkError', {
            message: payload.key.key || payload.key,
          }),
        });
      } else {
        onShowCongratulationsContent();
      }

      setShowSpinner(false);
    },
    [dispatch, setShowSpinner, setError, setExpiredKey, onShowCongratulationsContent, t, location.pathname],
  );

  const checkPasswordsMatches = () => {
    const { password1, password2 } = getValues();
    const errorMessage = password1 === password2 ? undefined : t('components.createPassword.passwordsDoNotMatch');

    return errorMessage;
  };

  return (
    <FormPosition onSubmit={handleSubmit(onSubmit)}>
      <PasswordInput
        label={t('components.createPassword.yourPassword')}
        name="password1"
        register={register}
        rules={minLengthPasswordValidation}
        errorPassword={isSubmitted ? errors.password1 : undefined}
        onChange={() => {
          trigger('password2');
        }}
      />
      <PasswordInput
        label={t('components.createPassword.confirmPassword')}
        name="password2"
        register={register}
        rules={{ validate: checkPasswordsMatches }}
        errorPassword={isSubmitted ? errors.password2 : undefined}
      />
      <FormGroupAcceptTermsConditions>
        <LoginFormCheck
          type="checkbox"
          name="accept"
          label={t('components.createPassword.accept')}
          ref={register}
          required
        />
        <LinkCreatePassword>
          <AgreementCreatePassword onClick={() => setShowEndUserAgreement(true)}>
            {t('components.endUserAgreement.label')}
          </AgreementCreatePassword>
          <EndUserAgreement show={showEndUserAgreement} onHide={() => setShowEndUserAgreement(false)} />
        </LinkCreatePassword>
      </FormGroupAcceptTermsConditions>
      {expiredKey ? (
        <TextForgotPassword>
          <ErrorMessage role="alert">
            {t('common.errorMessages.expiredLinkKey')}
            <br />
            <Link href="/password/reset">{t('components.createPassword.forgotPasswordForm')}</Link>
          </ErrorMessage>
        </TextForgotPassword>
      ) : (
        <>
          {showSpinner ? (
            <Preloader />
          ) : (
            <Form.Group>
              <Button label={t('common.buttons.save')} disabled={!isValid && isSubmitted} />
            </Form.Group>
          )}
          {!showSpinner && <BackToLoginLink />}
        </>
      )}
    </FormPosition>
  );
};
