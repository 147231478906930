import { PATTERNS } from 'modules/core/constants';
import i18n from 'i18n';

export const minLengthPasswordValidation = {
  required: true,
  pattern: {
    value: PATTERNS.PASSWORD_PATTERN,
    message: i18n.t('common.errorMessages.passwordMessage'),
  },
};
