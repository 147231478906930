import React, { FC, useCallback } from 'react';
import { ModalButton, ModalContainer, Title } from 'components/ModalDialog/style';
import { FormRow, Input, PercentAdornment } from 'modules/core/components';
import { EMPTY_STRING, MODAL_DIALOG_WIDTH } from 'modules/core/constants';
import { ButtonVariant, ColumnWidth, ModalTheme } from 'modules/core/enums';
import { useTranslation } from 'react-i18next';
import { FormLabel } from 'modules/core/components/FormRow/styles';
import { useDispatch, useSelector } from 'react-redux';
import { getRateSheetsDataFlags, getSetupRateSheetsData } from 'store/selectors/RACAffordabilityEngine.selector';
import { Form, Modal, Col } from 'react-bootstrap';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { requiredValidator, validateMinRequiredValueForDropdown, validateNumberValue } from 'modules/core/validators';
import { IUser } from 'modules/core/types';
import { getUser } from 'store/selectors/auth.selector';
import { saveRateSheetsDataThunk } from 'actions/vehicleActions';
import { IRACAffordabilityEngineForm } from 'modules/RACAffordabilityEngine/types/RACAffordabilityEngineForm';
import { WITHOUT_INVENTORY_FIELDS_NAMES } from 'modules/RACAffordabilityEngine/const/fieldNames.const';
import { FEES_AND_TAXES_FIELD_NAMES } from 'modules/core/shared';
import { getRegionControlsThunk, getTierOptionsThunk } from 'actions/controlActions';
import { resetVehicles, setIsReset } from 'store/reducers/RACAffordabilityEngine.reducer';
import { IRateSheetData } from 'modules/core/types/rateSheets';

import { RateSheetRegionRow } from './styles';
import { RAC_SETTINGS_FIELDS_NAMES } from './consts/fieldNames.const';
import { DEFAULT_RATE_SHEET_FORM, STANDARD_RATE_SHEET } from './consts/consts';
import { Dropdown } from '../LoanVariablesDialog/components/common/Dropdown/Dropdown';
import { Panel } from '../commonStyles';

interface ISetupRateSheetAndTaxRateModal {
  show: boolean;
  onHide: () => void;
}

const setDefaultRateSheetForm = (rateSheets: IRateSheetData) =>
  rateSheets.hasOwnProperty(STANDARD_RATE_SHEET)
    ? DEFAULT_RATE_SHEET_FORM.racSettingsFormStandard
    : DEFAULT_RATE_SHEET_FORM.racSettingsFormEmpty;

export const SetupRateSheetAndTaxRateModal: FC<ISetupRateSheetAndTaxRateModal> = ({ show, onHide }) => {
  const { t } = useTranslation();
  const rateSheets = useSelector(getSetupRateSheetsData);
  const dispatch = useDispatch();
  const user = useSelector(getUser) as IUser;
  const { isSavingRateSheets } = useSelector(getRateSheetsDataFlags);
  const { setValue: commonSetValue, getValues: commonGetValues } = useFormContext<IRACAffordabilityEngineForm>();
  const defaultUserRateSheetData = {
    rateSheet: {
      title: user.rateSheetsData?.rate_sheet,
      value: user.rateSheetsData?.rate_sheet,
    },
    region: {
      title: user.rateSheetsData?.region,
      value: user.rateSheetsData?.region,
    },
    taxRate: user.rateSheetsData?.tax_rate,
  };
  const initialValues =
    Object.keys(user?.rateSheetsData || {}).length > 0 ? defaultUserRateSheetData : setDefaultRateSheetForm(rateSheets);
  const formMethods = useForm({
    mode: 'onSubmit',
    shouldUnregister: false,
    defaultValues: { racSettingsForm: initialValues },
  });
  const {
    handleSubmit,
    getValues,
    setValue,
    clearErrors,
    setError,
    formState: { dirtyFields },
  } = formMethods;
  const commonValues = commonGetValues();
  const { rateSheet, region, taxRate } = getValues().racSettingsForm;
  const getRegionsByRateSheet = useCallback((rateSheetValue: string) => rateSheets[rateSheetValue], [rateSheets]);
  const rateSheetsOptions = () =>
    Object.keys(rateSheets || {}).map((rateSheet) => ({ title: rateSheet, value: rateSheet }));

  const regionsOptions = useCallback(() => {
    if (rateSheet?.value) {
      const regionsDataByRateSheet = getRegionsByRateSheet(rateSheet.value);

      return regionsDataByRateSheet?.map((region) => ({ title: region, value: region }));
    }

    return [];
  }, [rateSheet?.value, getRegionsByRateSheet]);

  const rateSheetChangeCallback = useCallback(() => {
    if (rateSheet?.value) {
      const regionsDataByRateSheet = getRegionsByRateSheet(rateSheet.value);
      const isRegionExists = regionsDataByRateSheet.includes(String(region.value));

      if (!isRegionExists) {
        setValue(RAC_SETTINGS_FIELDS_NAMES.REGION, DEFAULT_RATE_SHEET_FORM.racSettingsFormEmpty.region);
      }
    }
  }, [rateSheet?.value, region?.value, setValue, getRegionsByRateSheet]);

  const regionChangeCallback = useCallback(() => clearErrors(RAC_SETTINGS_FIELDS_NAMES.REGION), [clearErrors]);

  const taxRateChangeCallback = useCallback(
    (data) => {
      if (!data?.target?.value) {
        setError(RAC_SETTINGS_FIELDS_NAMES.TAX_RATE, { message: EMPTY_STRING });
      } else clearErrors(RAC_SETTINGS_FIELDS_NAMES.TAX_RATE);
    },
    [setError, clearErrors],
  );

  const setCommonValues = useCallback(() => {
    commonSetValue(WITHOUT_INVENTORY_FIELDS_NAMES.RATE_SHEET, rateSheet.value);
    commonSetValue(WITHOUT_INVENTORY_FIELDS_NAMES.REGION, region.value);
    commonSetValue(WITHOUT_INVENTORY_FIELDS_NAMES.TAX_RATE, taxRate);
    commonSetValue(FEES_AND_TAXES_FIELD_NAMES.SALES_TAX_RATE, taxRate);
  }, [commonSetValue, rateSheet, region, taxRate]);

  const onSubmit = useCallback(async () => {
    const params = { ratesheet: rateSheet.value, region: region.value, tax_rate: taxRate };

    if (Object.keys(dirtyFields?.racSettingsForm || {}).length > 0) {
      await dispatch(saveRateSheetsDataThunk(params));

      const tiersResponse: any = await dispatch(getTierOptionsThunk());
      const tier = tiersResponse?.payload.find(({ tier }: any) => tier === commonValues.tier.tier);

      await dispatch(getRegionControlsThunk());

      if (!tier) {
        dispatch(resetVehicles());
      }

      dispatch(setIsReset(true));
    }

    setCommonValues();
    onHide();
  }, [
    rateSheet.value,
    region.value,
    taxRate,
    dirtyFields.racSettingsForm,
    dispatch,
    setCommonValues,
    commonValues.tier.tier,
    onHide,
  ]);

  const onSubmitCallback = useCallback(() => {
    handleSubmit(onSubmit)();
  }, [handleSubmit, onSubmit]);

  return (
    <FormProvider {...formMethods}>
      <ModalContainer
        show={show}
        onHide={() => undefined}
        keyboard={false}
        onKeyDown={(event: any) => {
          if (event.key === 'Enter') {
            event.preventDefault();

            return false;
          }
        }}
        $modalSize={MODAL_DIALOG_WIDTH.CUSTOM.PX_650}
        $modalTheme={ModalTheme.DEFAULT}
        centered
      >
        <Modal.Header>
          <Title $uppercase>{t('components.racSetupRateSheetAndTaxRateModal.title')}</Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Panel>
              <RateSheetRegionRow>
                <Col>
                  <FormLabel required>
                    {t('components.racSetupRateSheetAndTaxRateModal.fields.rateSheetRegion.label')}
                  </FormLabel>
                </Col>
                <Col>
                  <Dropdown
                    options={rateSheetsOptions()}
                    name={RAC_SETTINGS_FIELDS_NAMES.RATE_SHEET}
                    placeholder={t('components.racSetupRateSheetAndTaxRateModal.fields.rateSheetRegion.placeholder')}
                    changeCallback={rateSheetChangeCallback}
                    rules={requiredValidator}
                    validate={validateMinRequiredValueForDropdown}
                  />
                </Col>
                <Col>
                  <Dropdown
                    options={regionsOptions()}
                    name={RAC_SETTINGS_FIELDS_NAMES.REGION}
                    placeholder={t('components.racSetupRateSheetAndTaxRateModal.fields.taxRate.placeholder')}
                    disabled={rateSheet.value === undefined}
                    rules={requiredValidator}
                    validate={validateMinRequiredValueForDropdown}
                    changeCallback={regionChangeCallback}
                  />
                </Col>
              </RateSheetRegionRow>
              <FormRow
                required
                fieldWidth={ColumnWidth.XL}
                label={t('components.racSetupRateSheetAndTaxRateModal.fields.taxRate.label')}
                control={
                  <Input
                    name={RAC_SETTINGS_FIELDS_NAMES.TAX_RATE}
                    endAdornment={<PercentAdornment />}
                    decimalScale={3}
                    placeholder={t('components.input.placeholders.percent')}
                    changeCallback={taxRateChangeCallback}
                    rules={requiredValidator}
                    validate={validateNumberValue}
                  />
                }
              />
            </Panel>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <ModalButton buttontheme={ButtonVariant.CONTAINED} onClick={onSubmitCallback} disabled={isSavingRateSheets}>
            {t('common.buttons.save')}
          </ModalButton>
        </Modal.Footer>
      </ModalContainer>
    </FormProvider>
  );
};
