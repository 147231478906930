import styled from 'styled-components';
import { COLORS } from 'modules/core/theme/colors';
import { WindowBreakpoints } from 'modules/core/enums/windowBreakpoints.enum';

export const RowTitle = styled.div`
  width: 65%;
  font-size: 20px;
  color: black;

  @media screen and (max-width: ${WindowBreakpoints.small}px) {
    width: 60%;
    font-size: 17px;
  }
`;

export const RowSign = styled.div`
  width: 10%;
  color: ${COLORS.GREEN_7};
  font-weight: 800;
  font-size: 24px;

  @media screen and (max-width: ${WindowBreakpoints.small}px) {
    width: 5%;
    font-size: 20px;
  }
`;

export const RowValue = styled.div`
  width: 25%;
  font-size: 24px;
  font-weight: 800;
  line-height: 1.5rem;
  text-align: end;

  @media screen and (max-width: ${WindowBreakpoints.small}px) {
    width: 35%;
    font-size: 20px;
  }
`;

export const HorizontalLine = styled.div`
  width: 100%;
  height: 3px;
  border-bottom: 2px solid ${COLORS.GREY_13};
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin: 7px 0;
`;
