import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import i18n from 'i18n';
import { INITIAL_VALUES } from 'modules/RACAffordabilityEngine/RACAffordabilityEngine.const';
import { ILenderVehicle, IRACVehicle, IRACVehicleByStock, ITier, IVehicleFiltersDirtyFields } from 'modules/core/types';
import { IIncomeForm } from 'components/IncomeCalculator/types';
import { IRACAffordabilityEngineForm } from 'modules/RACAffordabilityEngine/types/RACAffordabilityEngineForm';
import {
  getLoanVariablesInfoThunk,
  getRACVehiclesThunk,
  getRACVehiclesByStockThunk,
  getLoanTermsListThunk,
  getRouteOneDataThunk,
  getRateSheetsDataThunk,
  saveRateSheetsDataThunk,
  postRouteOneDataThunk,
  getLenderLoanVariablesInfoThunk,
  getLenderLoanTermsListThunk,
} from 'actions/vehicleActions';
import { ILoanVariables, ILoanVariablesForm } from 'components/Modals/LoanVariablesDialog/types';
import { getRegionControlsThunk, getTierOptionsThunk } from 'actions/controlActions';
import { ISalesFeesForm } from 'components/Modals/LoanVariablesDialog/modals/Calculators/SaleFeesCalculator/types';
import { INetTradeInForm } from 'components/Modals/LoanVariablesDialog/modals/Calculators/NetTradeInCalculator/types';
import { IRouteOneData, IRouteOneDataFlags } from 'modules/core/types/routeOne';
import { IRegionControls, IRegionControlsPayload } from 'modules/core/types/region';
import { IRateSheetData } from 'modules/core/types/rateSheets';

export interface IRACAffordabilityEngineState {
  initialValues: IRACAffordabilityEngineForm;
  vehicles: {
    vehiclesByParams?: IRACVehicle[];
    vehiclesByStock?: IRACVehicle[];
  };
  income: IIncomeForm;
  isFetchingVehicles: boolean;
  isFetchingVehiclesFailed: boolean;
  isReset: boolean;
  loanVariablesInfo: ILoanVariables;
  loanVariablesCurrentVehicle?: IRACVehicle | ILenderVehicle;
  routeOneData: IRouteOneData;
  ratesheetsData: IRateSheetData;
  tiers: ITier[];
  regionControls: IRegionControls;
  isTableView: boolean;
  notFoundVehicles: string[];
  notAvailableVehicles: string[];
  vehicleFiltersDirtyFields: IVehicleFiltersDirtyFields;
  firstRouteOneRender: boolean;
  isFetchingLoanVariablesInfo: boolean;
  rateSheetsDataFlags: IRateSheetDataFlags;
  routeOneDataFlags: IRouteOneDataFlags;
}

interface IRateSheetDataFlags {
  isSavingRateSheets: boolean;
  isFetchingRateSheets: boolean;
  isShow?: boolean;
  isCalculatedPayment?: boolean;
}

const rateSheetsDataFlagsInitialValues = {
  isSavingRateSheets: false,
  isFetchingRateSheets: true,
  isShow: false,
  isCalculatedPayment: false,
};

const initialState: IRACAffordabilityEngineState = {
  initialValues: INITIAL_VALUES,
  vehicles: {
    vehiclesByParams: undefined,
    vehiclesByStock: undefined,
  },
  income: INITIAL_VALUES.income,
  isFetchingVehicles: false,
  isFetchingVehiclesFailed: false,
  isReset: false,
  loanVariablesInfo: {} as ILoanVariables,
  routeOneData: {} as IRouteOneData,
  ratesheetsData: {},
  tiers: [],
  regionControls: {} as IRegionControls,
  isTableView: true,
  notFoundVehicles: [],
  notAvailableVehicles: [],
  vehicleFiltersDirtyFields: {},
  firstRouteOneRender: true,
  rateSheetsDataFlags: rateSheetsDataFlagsInitialValues,
  isFetchingLoanVariablesInfo: false,
  routeOneDataFlags: {
    isLoading: false,
    message: '',
    isInvalid: undefined,
    isReceived: false,
  },
};

export const RACAffordabilityEngineSlice = createSlice({
  name: 'RACAffordabilityEngine',
  initialState,
  reducers: {
    syncInitialValues: (
      state: IRACAffordabilityEngineState,
      { payload: initialValues }: PayloadAction<IRACAffordabilityEngineForm>,
    ) => ({
      ...state,
      initialValues,
    }),
    closeRateSheetsModal: (state: IRACAffordabilityEngineState) => ({
      ...state,
      rateSheetsDataFlags: { isSavingRateSheets: false, isShow: false, isFetchingRateSheets: false },
    }),
    clearVehicleDetails: (state: IRACAffordabilityEngineState) => ({
      ...state,
      loanVariablesInfo: initialState.loanVariablesInfo,
    }),
    setIncome: (state: IRACAffordabilityEngineState, { payload: income }: PayloadAction<IIncomeForm>) => ({
      ...state,
      income,
    }),
    setLoanVariablesCurrentVehicle: (
      state: IRACAffordabilityEngineState,
      { payload: vehicle }: PayloadAction<IRACVehicle | ILenderVehicle>,
    ) => ({
      ...state,
      loanVariablesCurrentVehicle: vehicle,
    }),
    clearLoanVariablesCurrentVehicle: (state: IRACAffordabilityEngineState) => ({
      ...state,
      loanVariablesCurrentVehicle: initialState.loanVariablesCurrentVehicle,
    }),
    resetRouteOneDataFlags: (state: IRACAffordabilityEngineState) => ({
      ...state,
      routeOneDataFlags: initialState.routeOneDataFlags,
    }),
    resetIncome: (state: IRACAffordabilityEngineState) => ({
      ...state,
      income: initialState.income,
    }),
    setFirstRouteOneRender: (state: IRACAffordabilityEngineState, { payload }: PayloadAction<boolean>) => ({
      ...state,
      firstRouteOneRender: payload,
    }),
    setSalesFees: (state: IRACAffordabilityEngineState, { payload: salesFeesData }: PayloadAction<ISalesFeesForm>) => ({
      ...state,
      salesFeesData,
    }),
    setNetTradeIn: (
      state: IRACAffordabilityEngineState,
      { payload: netTradeInData }: PayloadAction<INetTradeInForm>,
    ) => ({
      ...state,
      netTradeInData,
    }),
    setLoanVariables: (
      state: IRACAffordabilityEngineState,
      { payload: LoanVariablesData }: PayloadAction<ILoanVariablesForm>,
    ) => ({
      ...state,
      LoanVariablesData,
    }),
    resetVehicles: (state: IRACAffordabilityEngineState) => ({
      ...state,
      vehicles: {
        vehiclesByParams: undefined,
        vehiclesByStock: undefined,
      },
    }),
    resetVehiclesByStock: (state: IRACAffordabilityEngineState) => ({
      ...state,
      vehicles: { ...state.vehicles, vehiclesByStock: undefined },
    }),
    resetVehiclesByParams: (state: IRACAffordabilityEngineState) => ({
      ...state,
      vehicles: { ...state.vehicles, vehiclesByParams: undefined },
    }),
    resetByStockOptions: (state: IRACAffordabilityEngineState) => ({
      ...state,
      notFoundVehicles: [],
      notAvailableVehicles: [],
    }),
    resetByStock: (state: IRACAffordabilityEngineState) => ({
      ...state,
      notFoundVehicles: [],
      notAvailableVehicles: [],
      vehicles: { ...state.vehicles, vehiclesByStock: undefined },
    }),
    setIsReset: (state: IRACAffordabilityEngineState, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isReset: payload,
    }),
    setIsTableView: (state: IRACAffordabilityEngineState, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isTableView: payload,
    }),
    resetVehicleFiltersDirtyFields: (state: IRACAffordabilityEngineState) => ({
      ...state,
      vehicleFiltersDirtyFields: {},
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(
      getRACVehiclesThunk.fulfilled,
      (state: IRACAffordabilityEngineState, { payload: vehicles }: PayloadAction<IRACVehicle[]>) => ({
        ...state,
        vehicles: {
          ...state.vehicles,
          vehiclesByParams: Array.isArray(vehicles) ? vehicles.map((vehicle) => ({ ...vehicle, is_new: false })) : [],
        },
        isFetchingVehicles: false,
        isFetchingVehiclesFailed: false,
      }),
    );
    builder.addCase(getRACVehiclesThunk.pending, (state: IRACAffordabilityEngineState) => ({
      ...state,
      vehicles: { ...state.vehicles, vehiclesByParams: undefined },
      isReset: false,
      isFetchingVehicles: true,
      isFetchingVehiclesFailed: false,
    }));
    builder.addCase(getRACVehiclesThunk.rejected, (state: IRACAffordabilityEngineState) => ({
      ...state,
      vehicles: { ...state.vehicles, vehiclesByParams: undefined },
      isFetchingVehicles: false,
      isFetchingVehiclesFailed: true,
    }));
    builder.addCase(
      getRateSheetsDataThunk.fulfilled,
      (state: IRACAffordabilityEngineState, { payload: ratesheetsData }: any) => ({
        ...state,
        ratesheetsData,
        rateSheetsDataFlags: {
          isSavingRateSheets: false,
          isFetchingRateSheets: false,
          isShow: true,
        },
      }),
    );
    builder.addCase(getRateSheetsDataThunk.pending, (state: IRACAffordabilityEngineState) => ({
      ...state,
      rateSheetsDataFlags: {
        isSavingRateSheets: false,
        isFetchingRateSheets: true,
        isShow: false,
      },
    }));
    builder.addCase(getRateSheetsDataThunk.rejected, (state: IRACAffordabilityEngineState) => ({
      ...state,
      rateSheetsDataFlags: {
        isSavingRateSheets: false,
        isFetchingRateSheets: true,
        isShow: false,
      },
    }));
    builder.addCase(saveRateSheetsDataThunk.pending, (state: IRACAffordabilityEngineState) => ({
      ...state,
      rateSheetsDataFlags: {
        isSavingRateSheets: true,
        isFetchingRateSheets: false,
        isShow: true,
      },
    }));
    builder.addCase(saveRateSheetsDataThunk.fulfilled, (state: IRACAffordabilityEngineState) => ({
      ...state,
      rateSheetsDataFlags: {
        isSavingRateSheets: false,
        isFetchingRateSheets: false,
        isCalculatedPayment: true,
      },
    }));
    builder.addCase(saveRateSheetsDataThunk.rejected, (state: IRACAffordabilityEngineState) => ({
      ...state,
      rateSheetsDataFlags: {
        isSavingRateSheets: false,
        isFetchingRateSheets: false,
        isShow: true,
      },
    }));
    builder.addCase(
      getLoanTermsListThunk.fulfilled,
      (state: IRACAffordabilityEngineState, { payload: loanTermsList }: any) => ({
        ...state,
        loanTermsList,
      }),
    );
    builder.addCase(getLoanTermsListThunk.pending, (state: IRACAffordabilityEngineState) => ({
      ...state,
    }));
    builder.addCase(getLoanTermsListThunk.rejected, (state: IRACAffordabilityEngineState) => ({
      ...state,
    }));
    builder.addCase(
      getLenderLoanTermsListThunk.fulfilled,
      (state: IRACAffordabilityEngineState, { payload: loanTermsList }: any) => ({
        ...state,
        loanTermsList,
      }),
    );
    builder.addCase(getLenderLoanTermsListThunk.pending, (state: IRACAffordabilityEngineState) => ({
      ...state,
    }));
    builder.addCase(getLenderLoanTermsListThunk.rejected, (state: IRACAffordabilityEngineState) => ({
      ...state,
    }));
    builder.addCase(
      getLoanVariablesInfoThunk.fulfilled,
      (state: IRACAffordabilityEngineState, { payload: loanVariablesInfo }: PayloadAction<ILoanVariables>) => ({
        ...state,
        loanVariablesInfo,
        isFetchingLoanVariablesInfo: false,
      }),
    );
    builder.addCase(getLoanVariablesInfoThunk.pending, (state: IRACAffordabilityEngineState) => ({
      ...state,
      isFetchingLoanVariablesInfo: true,
    }));
    builder.addCase(getLoanVariablesInfoThunk.rejected, (state: IRACAffordabilityEngineState) => ({
      ...state,
      isFetchingLoanVariablesInfo: true,
    }));
    builder.addCase(
      getLenderLoanVariablesInfoThunk.fulfilled,
      (state: IRACAffordabilityEngineState, { payload: loanVariablesInfo }: PayloadAction<ILoanVariables>) => ({
        ...state,
        loanVariablesInfo,
        isFetchingLoanVariablesInfo: false,
      }),
    );
    builder.addCase(getLenderLoanVariablesInfoThunk.pending, (state: IRACAffordabilityEngineState) => ({
      ...state,
      isFetchingLoanVariablesInfo: true,
    }));
    builder.addCase(getLenderLoanVariablesInfoThunk.rejected, (state: IRACAffordabilityEngineState) => ({
      ...state,
      isFetchingLoanVariablesInfo: true,
    }));
    builder.addCase(
      getTierOptionsThunk.fulfilled,
      (state: IRACAffordabilityEngineState, { payload: tiers }: PayloadAction<ITier[]>) => ({
        ...state,
        tiers,
      }),
    );
    builder.addCase(
      getRegionControlsThunk.fulfilled,
      (state: IRACAffordabilityEngineState, { payload }: PayloadAction<IRegionControlsPayload>) => ({
        ...state,
        regionControls: {
          vscLimitAbove: payload.vsc_limit_above,
          vscLimitBelow: payload.vsc_limit_below,
          vscLimitVehicleValue: payload.vsc_limit_vehicle_value,
          gapLimit: payload.gap_limit,
          gapMinLtv: payload.gap_min_ltv,
        },
      }),
    );

    builder.addCase(
      getRouteOneDataThunk.fulfilled,
      (state: IRACAffordabilityEngineState, { payload: routeOneData }: PayloadAction<IRouteOneData>) => ({
        ...state,
        routeOneData,
        routeOneDataFlags: {
          ...state.routeOneDataFlags,
          isReceived: true,
        },
      }),
    );
    builder.addCase(getRouteOneDataThunk.pending, (state: IRACAffordabilityEngineState) => ({
      ...state,
      routeOneDataFlags: {
        ...state.routeOneDataFlags,
        isReceived: false,
      },
    }));
    builder.addCase(getRouteOneDataThunk.rejected, (state: IRACAffordabilityEngineState) => ({
      ...state,
      routeOneDataFlags: {
        ...state.routeOneDataFlags,
        isReceived: true,
      },
    }));

    builder.addCase(postRouteOneDataThunk.fulfilled, (state: IRACAffordabilityEngineState) => ({
      ...state,
      routeOneDataFlags: {
        isLoading: false,
        isInvalid: false,
        message: i18n.t('components.loanVariablesModal.successMessages.submitDeal'),
        isReceived: state.routeOneDataFlags.isReceived,
      },
    }));
    builder.addCase(postRouteOneDataThunk.pending, (state: IRACAffordabilityEngineState) => ({
      ...state,
      routeOneDataFlags: {
        isLoading: true,
        isInvalid: false,
        message: '',
        isReceived: state.routeOneDataFlags.isReceived,
      },
    }));
    builder.addCase(postRouteOneDataThunk.rejected, (state: IRACAffordabilityEngineState) => ({
      ...state,
      routeOneDataFlags: {
        isLoading: false,
        isInvalid: true,
        message: i18n.t('components.loanVariablesModal.errorMessages.submitDeal'),
        isReceived: state.routeOneDataFlags.isReceived,
      },
    }));
    builder.addCase(
      getRACVehiclesByStockThunk.fulfilled,
      (state: IRACAffordabilityEngineState, { payload }: PayloadAction<IRACVehicleByStock>) => ({
        ...state,
        vehicles: { ...state.vehicles, vehiclesByStock: payload.vehicles },
        notFoundVehicles: payload.search_result.not_in_db,
        notAvailableVehicles: payload.search_result.not_available,
        isFetchingVehicles: false,
        isFetchingVehiclesFailed: false,
      }),
    );

    builder.addCase(getRACVehiclesByStockThunk.pending, (state: IRACAffordabilityEngineState) => ({
      ...state,
      vehicles: { ...state.vehicles, vehiclesByStock: undefined },
      notFoundVehicles: [],
      notAvailableVehicles: [],
      isReset: false,
      isFetchingVehicles: true,
      isFetchingVehiclesFailed: false,
    }));

    builder.addCase(getRACVehiclesByStockThunk.rejected, (state: IRACAffordabilityEngineState) => ({
      ...state,
      vehicles: { ...state.vehicles, vehiclesByStock: undefined },
      notFoundVehicles: [],
      notAvailableVehicles: [],
      isFetchingVehicles: false,
      isFetchingVehiclesFailed: true,
    }));
  },
});

export const {
  clearVehicleDetails,
  setIncome,
  setSalesFees,
  setNetTradeIn,
  setLoanVariables,
  setLoanVariablesCurrentVehicle,
  clearLoanVariablesCurrentVehicle,
  resetVehicles,
  resetVehiclesByStock,
  resetVehiclesByParams,
  resetIncome,
  resetByStockOptions,
  resetByStock,
  setIsReset,
  setIsTableView,
  setFirstRouteOneRender,
  resetVehicleFiltersDirtyFields,
  closeRateSheetsModal,
  resetRouteOneDataFlags,
} = RACAffordabilityEngineSlice.actions;
