import React, { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { PROBABILITY_ESTIMATOR_FIELDS } from 'modules/ProbabilityEstimator/probabilityEstimator.const';
import { TableView } from 'modules/core/enums';
import { TReportSelector } from 'modules/core/types';
import {
  AdjustMenu,
  AdjustMenuItem,
  AdjustMenuItemTitle,
  AdjustToggle,
} from 'modules/core/components/Table/AdjustDropdown/styles';

import { useDropdownOptions } from './useDropdownOptions';
import { IActionOption } from './types';

interface IVehicleTableActions {
  reportDataSelector: TReportSelector;
  closeDropDown?: boolean;
  isDisabled?: boolean;
}

export const VehicleTableActions: FC<IVehicleTableActions> = ({ reportDataSelector, closeDropDown, isDisabled }) => {
  const { t } = useTranslation();
  const { watch } = useFormContext();
  const selectedTableView = watch(PROBABILITY_ESTIMATOR_FIELDS.TABLE_VIEW);

  const { managerViewDropdownOptions, salespersonViewDropdownOptions } = useDropdownOptions(reportDataSelector);

  const TABLE_VIEW_ACTIONS_MAP = useMemo(
    () =>
      new Map<TableView, IActionOption[]>([
        [TableView.MANAGER, managerViewDropdownOptions],
        [TableView.SALESPERSON, salespersonViewDropdownOptions],
      ]),
    [managerViewDropdownOptions, salespersonViewDropdownOptions],
  );

  useEffect(() => {
    closeDropDown && document.getElementById('share-vehicles-dropdown')?.click();
  }, [closeDropDown]);

  const dropdownMenuItems = useMemo(
    () =>
      TABLE_VIEW_ACTIONS_MAP.get(selectedTableView)?.map(({ title, actionProp, isDisabled }) => (
        <AdjustMenuItem {...actionProp} disabled={isDisabled} key={title}>
          <AdjustMenuItemTitle>{title}</AdjustMenuItemTitle>
        </AdjustMenuItem>
      )),
    [selectedTableView, TABLE_VIEW_ACTIONS_MAP],
  );

  return (
    <Dropdown id="share-vehicles-dropdown">
      <AdjustToggle disabled={isDisabled}>{t('vehicleTableActions.title')}</AdjustToggle>
      <AdjustMenu>{dropdownMenuItems}</AdjustMenu>
    </Dropdown>
  );
};
