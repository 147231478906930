import styled from 'styled-components';

const CHEVRON_BORDER_STYLE = '0.3em solid transparent';

export const ChevronDown = styled.span<{ active?: boolean }>`
  content: '';
  opacity: ${(props) => (props.active ? 1 : 0.5)};
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.155em;
  border-top: 8px solid;
  border-right: ${CHEVRON_BORDER_STYLE};
  border-bottom: 0;
  border-left: ${CHEVRON_BORDER_STYLE};
`;

export const ChevronUp = styled(ChevronDown)`
  opacity: 1;
  border-bottom: 8px solid;
  border-top: none;
`;
