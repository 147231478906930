import styled, { css } from 'styled-components';
import { Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { theme } from 'modules/core/theme';
import { RUBIK_FONT_FAMILY } from 'modules/core/theme/typography';
import { STATIC_IMAGE_URLS } from 'modules/core/constants';

const sideBarLinkMixin = css`
  position: relative;
  border-radius: 0;
  font-size: 15px;
  padding: 10px 10px;
  width: 100%;
  color: ${theme.colors['green-6']};
  display: flex;

  &:hover {
    text-decoration: none;
    background-color: ${theme.colors['white-4']};
    color: ${theme.colors['green-6']};
  }
`;

export const SideBarMenu = styled.div<{
  open: boolean;
}>`
  width: ${({ open }) => (open ? 'auto' : '50px')};
  background-color: ${theme.colors['green-4']};
  transition: width 0.5s ease;
  height: 100%;
  position: fixed;
  top: 0;
  max-width: 85%;
  z-index: ${({ open }) => (open ? '1001' : '9')};
  overflow-y: ${({ open }) => (open ? 'auto' : 'visible')};
`;

export const Overlay = styled.div<{
  open: boolean;
}>`
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: ${({ open }) => (open ? '31' : '8')};
  background-color: rgba(0, 0, 0, 0.5);
  -o-transition: opacity .2s ease 0s,visibility .2s ease 0s;
  transition: opacity .2s ease 0s,visibility .2s ease 0s;

  opacity: ${({ open }) => (open ? '1' : '0')};
  visibility: ${({ open }) => (open ? 'visible' : 'hidden')};
}
`;

export const SideBarList = styled(Nav)`
  display: flex;
  flex-direction: column;
`;

export const SideBarListLink = styled(NavLink)<{
  selected: boolean;
  smallFont?: boolean;
}>`
  background-color: ${({ selected }) => selected && theme.colors['white-5']};
  padding: ${({ smallFont }) => smallFont && '6px 10px !important'};
  text-decoration: none;

  ${sideBarLinkMixin};
`;

export const SideBarListExternalLink = styled.a<{
  selected: boolean;
  smallFont?: boolean;
}>`
  background-color: ${({ selected }) => selected && theme.colors['white-5']};
  padding: ${({ smallFont }) => smallFont && '10px !important'};
  ${sideBarLinkMixin};
  cursor: pointer;
  text-decoration: none;
`;

export const MenuItemTitle = styled.span<{
  open: boolean;
  smallFont?: boolean;
}>`
  white-space: nowrap;
  margin-left: 10px;
  font-family: ${RUBIK_FONT_FAMILY};
  text-transform: ${({ smallFont }) => (smallFont ? 'none' : 'uppercase')};
  transition: all 0.5s ease-in-out;
  font-size: ${({ smallFont }) => smallFont && '0.92em'};
  display: ${({ open }) => (open ? 'inline-flex' : 'none')};
  align-items: center;
`;

export const ToggleMenuIcon = styled.div<{
  open: boolean;
  isExternal?: boolean;
}>`
  display: flex;
  padding: 10px 10px;
  position: relative;
  z-index: 36;
  height: ${({ isExternal }) => (isExternal ? '24px' : '44px')};
  align-items: center;
  cursor: pointer;

  span {
    display: inline-block;
    position: relative;
    transition: all 0.5s ease;
    transform: ${({ open }) => (open ? 'rotate(-180deg)' : 'rotate(0)')};
    top: 1px;
    left: ${({ open }) => (open ? '95%' : '8px')};
    width: 15px;
    height: 15px;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(${STATIC_IMAGE_URLS.icons.play});
  }
`;
