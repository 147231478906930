import { STATIC_IMAGE_URLS } from 'modules/core/constants';
import React, { useState } from 'react';
import { Form, InputGroup, Image } from 'react-bootstrap';
import { FieldError, RegisterOptions } from 'react-hook-form';
import { RegisterField } from 'modules/core/types/form';
import { ErrorMessage, EyeButton, FormControl, InputAppend, LoginFormLabel } from 'modules/auth/styles';

type FormControlElement = HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;

const { icons } = STATIC_IMAGE_URLS;

interface IPasswordInput {
  label: string;
  name: string;
  register: RegisterField;
  rules?: RegisterOptions;
  errorPassword?: FieldError;
  onChange?: React.ChangeEventHandler<FormControlElement>;
}

export const PasswordInput: React.FC<IPasswordInput> = ({ label, name, register, rules, errorPassword, onChange }) => {
  const [showPassword, setShowPassword] = useState('password');
  const [srcIconEye, setSrcIconEye] = useState(icons.closedEye);

  const onShowPassword = () => {
    if (showPassword === 'password') {
      setShowPassword('text');
      setSrcIconEye(icons.eye);
    } else {
      setShowPassword('password');
      setSrcIconEye(icons.closedEye);
    }
  };

  return (
    <Form.Group>
      <LoginFormLabel htmlFor={name}>{label}</LoginFormLabel>
      <InputGroup>
        <FormControl name={name} type={showPassword} ref={register(rules)} required onChange={onChange} />
        <InputAppend>
          <EyeButton variant="outlined" onClick={onShowPassword}>
            <Image src={srcIconEye} />
          </EyeButton>
        </InputAppend>
      </InputGroup>
      {errorPassword && <ErrorMessage>{errorPassword.message}</ErrorMessage>}
    </Form.Group>
  );
};
