import i18n from 'i18next';
import { ROUTER_PATH } from 'router/const/routerPath.const';
import { EXTERNAL_PATH } from 'router/const/externalPath.const';
import { STATIC_IMAGE_URLS } from 'modules/core/constants';
import { PERMISSIONS } from 'modules/PermissionWrapper/permissions.const';
import { Roles } from 'modules/core/enums';
import { SUPPORTED_FEATURES_MAPPER } from 'modules/core/constants/mappers/supportedFeatures.mapper';

export interface IMenuLink {
  name: string;
  link?: string;
  href?: string;
  iconUrl: string;
  allowedRoles?: Roles[];
  text?: string;
  smallFont?: boolean;
  featureName?: string;
  path?: string;
}

export const defaultMenuLinks: IMenuLink[] = [
  {
    name: i18n.t('components.probabilityEstimator.title'),
    iconUrl: STATIC_IMAGE_URLS.sideBar.probabilityEstimator,
    link: ROUTER_PATH.PROBABILITY_ESTIMATOR,
    featureName: SUPPORTED_FEATURES_MAPPER.probability_estimator,
  },
  {
    name: i18n.t('components.approvedLoanInput.title'),
    iconUrl: STATIC_IMAGE_URLS.sideBar.approvedLoanInput,
    link: ROUTER_PATH.APPROVED_LOAN_INPUT,
    allowedRoles: PERMISSIONS.APPROVED_LOAN_INPUT,
    featureName: SUPPORTED_FEATURES_MAPPER.approved_loan_input,
  },
  {
    name: i18n.t('common.sideBarMenuLink.rac'),
    iconUrl: '',
    link: ROUTER_PATH.RAC_AFFORDABILITY_ENGINE,
    allowedRoles: PERMISSIONS.RAC_AFFORDABILITY_ENGINE,
    featureName: SUPPORTED_FEATURES_MAPPER.rac,
  },
  {
    name: i18n.t('components.creditInterview.title'),
    iconUrl: STATIC_IMAGE_URLS.sideBar.creditInterview,
    link: ROUTER_PATH.CREDIT_INTERVIEW,
    featureName: SUPPORTED_FEATURES_MAPPER.credit_interview,
  },
  {
    name: i18n.t('common.sideBarMenuLink.admin'),
    iconUrl: STATIC_IMAGE_URLS.sideBar.adminPanel,
    href: EXTERNAL_PATH.ADMIN_PANEL,
    allowedRoles: PERMISSIONS.ADMIN_PANEL,
  },
  {
    name: i18n.t('common.sideBarMenuLink.admin'),
    iconUrl: STATIC_IMAGE_URLS.sideBar.adminPanel,
    href: EXTERNAL_PATH.MANAGER_PANEL,
    allowedRoles: PERMISSIONS.MANAGER_PANEL,
  },
];

export const lenderTitleItem: IMenuLink = {
  name: i18n.t('common.sideBarMenuLink.lenders'),
  iconUrl: STATIC_IMAGE_URLS.sideBar.rac,
  allowedRoles: PERMISSIONS.LENDER.concat(PERMISSIONS.RAC_AFFORDABILITY_ENGINE),
};
