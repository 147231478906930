import React, { FC, useCallback } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { TABLE_ITEMS_PER_PAGE_OPTIONS } from '../table.const';
import { GoNextPage } from '../types';
import { ItemsPerPage as ItemsPerPageUI } from './styles';

interface IItemsPerPage {
  setPageSize: (pageSize: number) => void;
  currentPageSize: number;
  gotoPage: GoNextPage;
}

export const ItemsPerPage: FC<IItemsPerPage> = ({ setPageSize, currentPageSize, gotoPage }) => {
  const { t } = useTranslation();

  const handleSelect = useCallback(
    ({ target: { value } }: any) => {
      setPageSize(Number(value));
      gotoPage(0);
    },
    [setPageSize, gotoPage],
  );

  return (
    <ItemsPerPageUI>
      <span>{t('components.table.vehiclesPerPage.title')}</span>
      <Form.Control as="select" size="sm" onChange={handleSelect} value={currentPageSize}>
        {TABLE_ITEMS_PER_PAGE_OPTIONS.map(({ value, title }) => (
          <option value={value} key={value}>
            {title}
          </option>
        ))}
      </Form.Control>
    </ItemsPerPageUI>
  );
};
