import styled from 'styled-components';
import { Dropdown } from 'react-bootstrap';
import { COLORS } from 'modules/core/theme/colors';

import { CreditScoreColors } from '../enums/creditScoreColors.enum';
import { CreditScoreColor } from '../types';

const { Toggle, Menu, Item } = Dropdown;

const CIRCLE_SIZE = '7px';

const OVERRIDER_DROPDOWN_SELECTORS = `
  &,
  &:hover,
  &:focus,
  &.btn-primary:not(:disabled):not(.disabled):active,
  .show > &.btn-primary.dropdown-toggle
`;

const MAP_OPTION_BACKGROUND_BY_SCORE = new Map([
  [CreditScoreColors.GREEN, COLORS.GREEN_1],
  [CreditScoreColors.ORANGE, COLORS.YELLOW_1],
  [CreditScoreColors.YELLOW, COLORS.YELLOW_3],
  [CreditScoreColors.RED, COLORS.RED_1],
]);

const MAP_OPTION_COLOR_TEXT_BY_SCORE = new Map([
  [CreditScoreColors.GREEN, COLORS.GREEN_2],
  [CreditScoreColors.ORANGE, COLORS.YELLOW_2],
  [CreditScoreColors.YELLOW, COLORS.YELLOW_4],
  [CreditScoreColors.RED, COLORS.RED_2],
]);

export const ScoreToggle = styled(Toggle)`
  width: 100%;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: ${({ theme: { fontSize } }) => fontSize.sm};
`;

export const DefaultToggle = styled(ScoreToggle)`
  ${OVERRIDER_DROPDOWN_SELECTORS} {
    background-color: ${({ theme: { colors } }) => colors['grey-1']};
    border: 1px solid transparent;
    color: ${({ theme: { colors } }) => colors.black};
    box-shadow: none;
  }

  &:disabled {
    color: ${COLORS.GREY_24};
    background-color: ${COLORS.GREY_26};
    opacity: 1;
    border: 1px solid ${COLORS.GREY_18};
  }
`;

export const SelectedScoreToggle = styled(ScoreToggle)<{ color: CreditScoreColor }>`
  ${OVERRIDER_DROPDOWN_SELECTORS} {
    background-color: white;
    border-color: ${({ color }) => MAP_OPTION_BACKGROUND_BY_SCORE.get(color)};
    color: ${({ color }) => MAP_OPTION_COLOR_TEXT_BY_SCORE.get(color)};
    box-shadow: none;
  }

  &:disabled {
    color: ${COLORS.GREY_24};
    background-color: ${COLORS.GREY_26};
    opacity: 1;
    border: 1px solid ${COLORS.GREY_18};
  }
`;

export const ScoreMenu = styled(Menu)`
  width: 100%;
  padding: 0;
`;

export const OptionItem = styled(Item)<{ color: CreditScoreColor; selected?: boolean }>`
  background-color: ${({ color, selected }) => !selected && MAP_OPTION_BACKGROUND_BY_SCORE.get(color)};
  color: ${({ color }) => MAP_OPTION_COLOR_TEXT_BY_SCORE.get(color)};
  font-size: ${({ theme: { fontSize } }) => fontSize.sm};
  text-align: center;
  padding: 5px;
  margin: 2px 0;
  position: relative;
  cursor: pointer;

  &:hover {
    background-color: ${({ color, selected }) => !selected && MAP_OPTION_BACKGROUND_BY_SCORE.get(color)};
    color: ${COLORS.WHITE};
    font-weight: bold;
  }

  &::before {
    content: '';
    display: ${({ selected }) => (selected ? 'inline-block' : 'none')};
    position: absolute;
    top: 36%;
    left: 22%;
    width: ${CIRCLE_SIZE};
    height: ${CIRCLE_SIZE};
    background-color: ${({ color }) => MAP_OPTION_COLOR_TEXT_BY_SCORE.get(color)};
    border-radius: 50%;
  }
`;
