import i18n from 'i18n';

export const CREDIT_INTERVIEW_FIELDS = {
  CUSTOMER_CAR: 'customerCar',
  CUSTOMER_NAME: 'customerName',
  CUSTOMER_PHONE: 'customerPhone',
};

export const CREDIT_INTERVIEW_LABELS = {
  CUSTOMER_NAME: i18n.t('components.creditInterview.labels.fullName'),
  CUSTOMER_PHONE: i18n.t('components.creditInterview.labels.phoneNumber'),
};

export const FORMAT_NUMBER_MASK = '+1 (###) ### #### ###';

export const POST_CREDIT_INTERVIEW_INFO_ERROR_NAME = 'postCreditInterviewInfoError';
