import React, { FC } from 'react';
import { Form } from 'react-bootstrap';
import { useUncontrolledInput } from 'modules/core/hooks';
import { IFormField } from 'modules/core/types/form';

export interface ICheckbox extends IFormField {
  label?: string | JSX.Element;
  disabled?: boolean;
}

export const Checkbox: FC<ICheckbox> = ({ name, label, disabled, rules }) => {
  const { registerInput } = useUncontrolledInput(name);

  return (
    <Form.Group controlId={name}>
      <Form.Check type="checkbox" label={label} ref={registerInput(rules)} name={name} disabled={disabled} />
    </Form.Group>
  );
};
