export const ROUTER_PATH = {
  BASE: '/',
  LOGIN: '/login',
  RESET_PASSWORD: '/password/reset',
  CREATE_NEW_PASSWORD: '/password/set/',
  VERIFICATION: '/verification/',
  ERROR: '/error',
  SELECT_DEALER: '/select-dealer',

  PROBABILITY_ESTIMATOR: '/estimator/probability/estimator/',
  APPROVED_LOAN_INPUT: '/estimator/loan/input/',
  RAC_AFFORDABILITY_ENGINE: '/rac',
  CREDIT_INTERVIEW: '/sales/send/',
  LENDER: '/lenders/:lenderFullId',
  LEARNING: {
    START: '/learning/start',
    KEY_FACTORS: '/learning/key-factors',
    CREDIT_HISTORY: '/learning/credit-history',
    AFFORDABILITY: '/learning/affordability',
    LOAN_TO_VALUE: '/learning/loan-to-value',
    LENGTH_OF_LOAN: '/learning/length-of-loan',
    COMPARISON: '/learning/comparison',
    FINAL: '/learning/final',
  },
  ADJUSTMENT_MODAL_VIEW: '/adjustment/modal/:id',
};
