import { IFeesAndTaxesForm } from '../types';

export const FEES_AND_TAXES_INDEX_FIELD = 'feesAndTaxes';

export const FEES_AND_TAXES_FIELD_NAMES = {
  SALES_TAX_RATE: `${FEES_AND_TAXES_INDEX_FIELD}.salesTaxRate`,
  ESTIMATED_FEES: `${FEES_AND_TAXES_INDEX_FIELD}.estimatedFees`,
  BANK_FEES: `${FEES_AND_TAXES_INDEX_FIELD}.bankFees`,
  BANK_DISCOUNT: `${FEES_AND_TAXES_INDEX_FIELD}.bankDiscount`,
  INSURANCE: `${FEES_AND_TAXES_INDEX_FIELD}.vscAndGapInsurance`,
  DOCUMENT_FEE: `${FEES_AND_TAXES_INDEX_FIELD}.documentFee`,
  SALES_FEES: `${FEES_AND_TAXES_INDEX_FIELD}.salesFees`,
};

export const FEES_AND_TAXES_INITIAL_VALUES: IFeesAndTaxesForm = {
  salesTaxRate: undefined,
  bankFees: 0,
  estimatedFees: undefined,
  bankDiscount: 0,
  documentFee: 0,
  vsc: 0,
  vscAndGapInsurance: 0,
  salesFees: undefined,
};
