import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Logo } from 'modules/core/components/Logo/Logo';
import { logoutUserThunk } from 'actions/userActions';
import { MyAccountDialog } from 'components/Modals';
import { useModalState } from 'modules/core/hooks';
import { BLANK_SPACE, MODAL_DIALOG_ID } from 'modules/core/constants';
import { PermissionWrapper } from 'modules/PermissionWrapper';
import { PERMISSIONS } from 'modules/PermissionWrapper/permissions.const';
import { EXTERNAL_PATH } from 'router/const/externalPath.const';
import { getUser } from 'store/selectors/auth.selector';
import { ROUTER_PATH } from 'router/const/routerPath.const';
import { removeHandleBeforeUnloadEvent } from 'hooks/useConfirmTabClose';

import { DropdownItem, DropdownToggleWithIcon, HeaderWrapper, NavigationWrapper, UserMenu, UserName } from './styles';

export const Header: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const history = useHistory();

  const { isModalDialogActive, handleCloseDialog, handleOpenDialog } = useModalState([MODAL_DIALOG_ID.MY_ACCOUNT]);

  const onLogOut = useCallback(() => {
    dispatch(logoutUserThunk());
  }, [dispatch]);

  const goToSelectDealerPage = () => history.push(ROUTER_PATH.SELECT_DEALER, user);

  const redirectToAdminAccount = () => {
    window.location.replace(EXTERNAL_PATH.ADMIN_ACCOUNT);
  };

  return (
    <HeaderWrapper needExtraSpace={user?.isWithInventory}>
      <Logo />
      {user?.isWithInventory && (
        <NavigationWrapper>
          <UserName>
            {user?.firstName}
            {BLANK_SPACE}
            {user?.lastName}
          </UserName>
          <Dropdown align={'end'}>
            <DropdownToggleWithIcon>
              <span />
            </DropdownToggleWithIcon>
            <UserMenu align="right">
              <PermissionWrapper allowedRoles={PERMISSIONS.ACCOUNT_DIALOG}>
                <DropdownItem
                  onClick={() => {
                    removeHandleBeforeUnloadEvent();
                    handleOpenDialog();
                  }}
                >
                  {t('common.header.myAccount')}
                </DropdownItem>
              </PermissionWrapper>
              <PermissionWrapper allowedRoles={PERMISSIONS.REDIRECT_TO_ADMIN_ACCOUNT}>
                <DropdownItem
                  onClick={() => {
                    removeHandleBeforeUnloadEvent();
                    redirectToAdminAccount();
                  }}
                >
                  {t('common.header.myAccount')}
                </DropdownItem>
              </PermissionWrapper>
              {user?.tenants && (
                <DropdownItem onClick={goToSelectDealerPage}>{t('common.header.switchDealer')}</DropdownItem>
              )}
              <DropdownItem
                onClick={() => {
                  removeHandleBeforeUnloadEvent();
                  onLogOut();
                }}
              >
                {t('common.header.logOut')}
              </DropdownItem>
            </UserMenu>
          </Dropdown>
        </NavigationWrapper>
      )}
      <MyAccountDialog show={isModalDialogActive} onHide={handleCloseDialog()} />
    </HeaderWrapper>
  );
};
