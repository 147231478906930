import React, { FC } from 'react';
import { Row } from 'react-table';
import { LOAN_STATUS_GRADIENT_BACKGROUND_COLORS_MAP, MODAL_DIALOG_ID } from 'modules/core/constants';
import { ILoanStatusGradientBackgroundProps, IUser } from 'modules/core/types';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { getUser } from 'store/selectors/auth.selector';
import { useTranslation } from 'react-i18next';
import { UnlockInventoryDialog } from 'components/Modals/UnlockInventoryDialog/UnlockInventoryDialog';
import { useModalState } from 'modules/core/hooks';
import { getIsModalsDisabled } from 'store/selectors/ui.selector';

import { getBackgroundPropsByLoanStatus, canRenderRow } from '../helper';
import { TABLE_RENDER_CELL_KEY } from '../table.const';
import { TableCell, TableRow, UnlockButton, UnlockIcon } from '../styles';
import { RAC_VEHICLE_TABLE_COLUMN_ID } from '../../VehicleTable/defaultVehicleTableColumns';

export interface ITableRow {
  rows: Row[];
  additionalRows: Row[];
  prepareRow: (row: Row) => void;
  pageSize: number;
  currentPage: number;
  noColoring?: boolean;
  isServerPagination?: boolean;
  handleOpenDialog?: () => void;
  isMakeItWork: boolean;
  routeOneVehicleRowIndex: number;
  isRac?: boolean;
  isLender?: boolean;
}

export const TableBody: FC<ITableRow> = ({
  rows,
  additionalRows,
  prepareRow,
  pageSize,
  currentPage,
  noColoring,
  isServerPagination = false,
  handleOpenDialog,
  isMakeItWork,
  routeOneVehicleRowIndex,
  isRac,
  isLender = false,
}) => {
  const { watch } = useFormContext();
  const { tableView } = watch();
  const { t } = useTranslation();
  const user = useSelector(getUser) as IUser;
  const isUnlockInventoryDisabled = useSelector(getIsModalsDisabled);
  const specialColumns = [RAC_VEHICLE_TABLE_COLUMN_ID.PHOTO, RAC_VEHICLE_TABLE_COLUMN_ID.VIN];
  const { handleOpenDialog: handleOpenUnlockInventory, handleCloseDialog, isModalDialogActive } = useModalState([
    MODAL_DIALOG_ID.UNLOCK_INVENTORY,
  ]);

  return (
    <tbody>
      {rows.map((row, item) => {
        prepareRow(row);
        const loanStatusGradientBackgroundColors = getBackgroundPropsByLoanStatus(
          (row.original as Record<string, any>).loan_status,
          LOAN_STATUS_GRADIENT_BACKGROUND_COLORS_MAP,
          tableView,
        );

        return canRenderRow(item, pageSize, currentPage, isServerPagination) ? (
          <TableRow
            isMakeItWork={isMakeItWork}
            isMakeItWorkVehicle={row.index === routeOneVehicleRowIndex}
            isRac={isRac}
            isLender={isLender}
            {...row.getRowProps()}
            onClick={handleOpenDialog}
          >
            {row.cells.map(({ getCellProps, render, column }) => (
              <TableCell
                {...getCellProps()}
                backgroundColors={loanStatusGradientBackgroundColors as ILoanStatusGradientBackgroundProps}
                noColoring={noColoring}
                isRac={isRac}
                isSpecialColumn={specialColumns.includes(column.id)}
              >
                {render(TABLE_RENDER_CELL_KEY)}
              </TableCell>
            ))}
          </TableRow>
        ) : null;
      })}
      {additionalRows.map((row, item) => {
        prepareRow(row);
        const loanStatusGradientBackgroundColors = getBackgroundPropsByLoanStatus(
          (row.original as Record<string, any>).loan_status,
          LOAN_STATUS_GRADIENT_BACKGROUND_COLORS_MAP,
          tableView,
        );

        return !user.isWithInventory && isRac ? (
          <TableRow isBlur isRac={isRac} isRelative={additionalRows.length > 4 && item === 2} {...row.getRowProps()}>
            {row.cells.map(({ getCellProps, render, column }) => (
              <TableCell
                {...getCellProps()}
                backgroundColors={loanStatusGradientBackgroundColors as ILoanStatusGradientBackgroundProps}
                noColoring={noColoring}
                isRac={isRac}
                isSpecialColumn={specialColumns.includes(column.id)}
                isBlur
              >
                {render(TABLE_RENDER_CELL_KEY)}
              </TableCell>
            ))}
            {additionalRows.length > 4 && item === 2 && (
              <>
                <UnlockButton disabled={isUnlockInventoryDisabled} onClick={handleOpenUnlockInventory}>
                  <UnlockIcon />
                  {t('components.RACAffordabilityEngine.withoutInventory.unlockInventory.unlock')}
                </UnlockButton>
                <UnlockInventoryDialog show={isModalDialogActive} onHide={handleCloseDialog()} />
              </>
            )}
          </TableRow>
        ) : null;
      })}
    </tbody>
  );
};
