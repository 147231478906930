import { ITier, IVehicleForm } from 'modules/core/types';
import { TableView } from 'modules/core/enums';
import { IIncomeForm } from 'components/IncomeCalculator/types';
import { IRateSheet } from 'modules/core/types/rateSheets';

import { FilterView } from '../../core/enums/filterView.enum';
import { INetDownPaymentForm } from '../../ProbabilityEstimator/types';
import { IFrontAndBackEndsAddsForm } from '../../../components/Modals/FrontAndBackEndsAddsModal/type';
import { ILendersMonthlyIncome } from '../RacMontlyIncomeModal/consts/types';

export interface IRACAffordabilityEngineForm extends IVehicleForm {
  lenderName?: string;
  grossMonthlyIncome: number | undefined;
  desiredMonthlyPayment: number;
  maximalPaymentRange: number;
  netDownPayment?: number;
  frontAndBackEndsAdds?: number;
  tableView: TableView;
  income: IIncomeForm;
  totalNetDownPaymentDialog: INetDownPaymentForm;
  totalFrontAndBackEndsAddsDialog: IFrontAndBackEndsAddsForm;
  tier: ITier;
  filterView: FilterView;
  stocks: string[];
  isRequestNeeded: {
    byParams: boolean;
    byStock: boolean;
  };
  currentVehicleData: any;
  monthlyIncome: number;
  lendersMonthlyIncomeModal: ILendersMonthlyIncome;
  price: string;
  payment: number;
  vendorName: string;
  condition: string;
  rateSheetSettingsModal: IRateSheet;
  participation?: number;
}

export const ESTIMATED_FEES_OVERRIDDEN = 'estimatedFeesOverridden';
