import { Endpoints } from 'modules/core/constants';
import { AxiosResponse } from 'axios';
import { Api } from 'api/api';
import { AllowedDealer, IDealer, IDealerStore } from 'modules/core/types/dealer';

class DealerApi extends Api {
  getDealers = (): Promise<AxiosResponse<IDealer[]>> => this.api.get(Endpoints.getDealers);

  getAllowedDealers = (): Promise<AxiosResponse<AllowedDealer[]>> => this.api.get(Endpoints.getAllowedDealers);

  getDealerStores = (): Promise<AxiosResponse<IDealerStore[]>> => this.api.get(Endpoints.getDealersStore);
}

export default new DealerApi();
