import { COLORS } from 'modules/core/theme/colors';
import { MultiSelect } from 'react-multi-select-component';
import styled from 'styled-components';

export const MultiSelectUI = styled(MultiSelect)<{ isRenderUpward?: boolean }>`
  color: ${COLORS.GREY_21};

  .dropdown-heading {
    height: 32px;
    font-size: 15px;
    padding-right: 6px;
  }

  .dropdown-heading-value {
    text-overflow: unset !important;
  }

  .gray {
    color: ${COLORS.GREY_21} !important;
  }

  .select-item {
    padding: 0 5px !important;
    margin: 0 !important;
    max-height: 25px;
  }

  .item-renderer {
    font-size: 15px;
    white-space: nowrap;
    max-width: 400px;
  }

  .dropdown-content {
    width: auto;
    min-width: 100%;
    z-index: 2;
  }

  .dropdown-content .panel-content {
    overflow-y: scroll;
    max-height: 300px !important;
    box-sizing: border-box;
    min-height: 175px;
    position: absolute;
    bottom: ${(props) => !!props.isRenderUpward && '100%'};
    margin-bottom: ${(props) => !!props.isRenderUpward && '40px'};
  }

  .dropdown-content .panel-content .options {
    overflow-y: visible !important;
    padding-left: auto;
  }
`;
