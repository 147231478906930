import { INCOME_INITIAL_VALUES } from 'components/IncomeCalculator/const';

import { LOAN_VARIABLES_FIELDS_NAMES } from './fieldNames.const';

export const LOAN_VARIABLES_MODAL_DIALOG_ID = {
  SALES_FEES_CALCULATOR: 'salesFeesCalculator',
  MOUNTLY_INCOME_CALCULATOR: 'mountlyIncomeCalculator',
  NET_TRADE_IN_CALCULATOR: 'netTradeInCalculator',
};

export const DEFAULT_LOAN_VARIABLES_VALUES = {
  loanVariablesForm: {
    price: LOAN_VARIABLES_FIELDS_NAMES.RETAIL_PRICE,
    retailPrice: LOAN_VARIABLES_FIELDS_NAMES.RETAIL_PRICE,
    adjustedPrice: LOAN_VARIABLES_FIELDS_NAMES.ADJUSTED_PRICE,
    mileage: NaN,
    guidebookValue: NaN,
    cashDown: NaN,
    rebates: 0,
    netTradeIn: NaN,
    addOns: NaN,
    vsc: NaN,
    gap: NaN,
    totalSalesFees: NaN,
    taxes: NaN,
    payment: NaN,
    loanTerm: undefined,
    loanTermsList: [],
    apr: NaN,
    totalAmountFinanced: NaN,
    monthlyIncome: NaN,
    confirmBook: false,
    confirmTaxes: false,
    confirmSubmit: false,
    vin: undefined,
    stock: undefined,
    vehicleTier: undefined,
    netTradeInForm: {
      netTradeInTotal: NaN,
      tradeInValue: NaN,
      amountOwnedOnTrade: NaN,
      lienHolder: undefined,
      year: undefined,
      make: undefined,
      model: undefined,
      otherYear: undefined,
      otherMake: undefined,
      otherModel: undefined,
    },
    salesFeesForm: {
      salesFees: NaN,
      salesDocumentFees: NaN,
      titleRegistrationLicense: undefined,
    },
    monthlyIncomeForm: {
      totalIncome: NaN,
      savedIncomes: INCOME_INITIAL_VALUES,
    },
    ltvCategories: [],
    isRecalculateButtonDisabled: true,
    isVerifyDealButtonDisabled: false,
    isBookVerified: true,
  },
};
