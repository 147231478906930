import React, { FC, useMemo } from 'react';
import { Form } from 'react-bootstrap';
import { FieldErrors } from 'react-hook-form';
import { ErrorMessage } from 'modules/auth/styles';

import { FormGroup, Label } from './styles';
import { FIELDS_CONFIG } from './changePassword.const';

export const ChangePasswordForm: FC<FieldErrors> = ({ errors }) => {
  const fields = useMemo(
    () =>
      FIELDS_CONFIG.map(({ name, label, component: Component, controlProps }) => (
        <FormGroup key={name}>
          <Label>{label}</Label>
          <Component name={name} {...controlProps} />
        </FormGroup>
      )),
    [],
  );

  return (
    <Form>
      {fields}
      {errors.passwordChangeFailed && <ErrorMessage>{errors.passwordChangeFailed?.message}</ErrorMessage>}
    </Form>
  );
};
