import React from 'react';
import { useTranslation } from 'react-i18next';

import { LearningWrapper } from '../common/LearningWrapper/LearningWrapper';
import { CardForm, TitleH1, AdditionalContainer } from '../styles';
import { LEARNING_PATHS } from '../Learning.const';

export const LearningFinal: React.FC = () => {
  const { t } = useTranslation();

  return (
    <LearningWrapper prevPageLink={LEARNING_PATHS.comparison} isBackground>
      <AdditionalContainer>
        <CardForm>
          <TitleH1>{t('components.learningLink.final.title')}</TitleH1>
          {t('components.learningLink.final.description')}
        </CardForm>
      </AdditionalContainer>
    </LearningWrapper>
  );
};
