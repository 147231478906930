import React, { useMemo } from 'react';
import { ColumnWidth } from 'modules/core/enums';

import { IField } from '../types';
import { FormRow } from '../components';

export const useRenderFields = (fieldsConfig: IField[]): JSX.Element[] =>
  useMemo(
    () =>
      fieldsConfig.map(({ name, label, isVerticalAlign, component: Component, controlProps }) => {
        const fieldWidth = controlProps?.fieldWidth ? { fieldWidth: controlProps.fieldWidth as ColumnWidth } : {};

        return (
          <FormRow
            key={name}
            label={label}
            isVerticalAlign={isVerticalAlign}
            {...fieldWidth}
            control={<Component name={name} testId={label} {...controlProps} />}
            required={Boolean(controlProps?.isRequired)}
          />
        );
      }),
    [fieldsConfig],
  );
