import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useModalState } from 'modules/core/hooks';
import { createGlobalStyle } from 'styled-components';
import { MODAL_DIALOG_ID, MODAL_DIALOG_WITH_NO_BTTNS } from 'modules/core/constants';
import { AppDispatch } from 'store/store';
import { setAdjustmentId } from 'store/reducers/vehicle.reducer';
import { VehicleAdjustmentDialog } from 'components/Modals/VehicleAdjustmentDialog';

export const VehicleAdjustmentDialogExport: FC = () => {
  const { isModalDialogActive } = useModalState([MODAL_DIALOG_ID.VEHICLE_DETAILS]);

  const { id } = useParams<{ id: string }>();

  function onHide() {
    window.close();
  }

  const BackdropStyles = createGlobalStyle`
    .backdrop-full-screen {
      background-color: #f8f9fa;
      opacity: 1 !important;
    }
  `;

  const dispatch = useDispatch<AppDispatch>();

  if (id !== null) dispatch(setAdjustmentId(Number(id)));

  return (
    <>
      <BackdropStyles />
      <VehicleAdjustmentDialog
        show={isModalDialogActive}
        onHide={onHide}
        onApplyCallback={onHide}
        isDisplayWithoutButtons={MODAL_DIALOG_WITH_NO_BTTNS}
      />
    </>
  );
};
