import { STATIC_IMAGE_URLS } from 'modules/core/constants';
import { useTranslation } from 'react-i18next';
import React, { useCallback, useState } from 'react';

import { ForgotPasswordForm } from './ForgotPasswordForm';
import {
  PositionCard,
  CardForm,
  CardImage,
  CardTitle,
  PositionMiniImage,
  FormPosition,
  TextCheckEmail,
  CardBody,
  LoginContent,
  ImageSize,
} from '../styles';
import { Button } from '../common';

const { logo, icons } = STATIC_IMAGE_URLS;

export const ForgotPassword: React.FC = () => {
  const { t } = useTranslation();
  const [isEmailExist, setIsEmailExist] = useState(false);

  const showLinkIsSentContent = useCallback(() => {
    setIsEmailExist(true);
  }, [setIsEmailExist]);

  return (
    <LoginContent>
      <PositionCard>
        <CardForm>
          <CardImage variant="top" src={logo} />
          {isEmailExist ? (
            <>
              <PositionMiniImage>
                <ImageSize src={icons.mail} />
              </PositionMiniImage>
              <CardBody>
                <CardTitle>{t('components.resetPassword.linkIsSent')}</CardTitle>
                <FormPosition>
                  <TextCheckEmail>
                    {t('components.resetPassword.checkEmailLineOne')}
                    <br />
                    {t('components.resetPassword.checkEmailLineTwo')}
                  </TextCheckEmail>
                  <Button label={t('components.login.buttons.backToLogin')} isOnClick />
                </FormPosition>
              </CardBody>
            </>
          ) : (
            <>
              <PositionMiniImage>
                <ImageSize src={icons.questionMark} />
              </PositionMiniImage>
              <CardBody>
                <CardTitle>{t('components.login.forgotPassword')}</CardTitle>
                <ForgotPasswordForm onShowLinkIsSentContent={showLinkIsSentContent} />
              </CardBody>
            </>
          )}
        </CardForm>
      </PositionCard>
    </LoginContent>
  );
};
