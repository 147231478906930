import styled from 'styled-components';
import { Dropdown } from 'react-bootstrap';

const { Toggle, Menu, Item } = Dropdown;

const applyDefaultAdjustToggleStyles = () => `
  color: rgb(75, 93, 125);
  border-color: rgb(75, 93, 125);
  background-color: inherit;
 `;

export const AdjustToggle = styled(Toggle)`
  min-width: 160px;
  font-size: ${({ theme: { fontSize } }) => fontSize.sm};
  opacity: ${({ disabled }) => disabled && '0.5'};
  pointer-events: ${({ disabled }) => disabled && 'none'};

  &,
  &:hover,
  &:focus {
    ${applyDefaultAdjustToggleStyles()}
    box-shadow: none;
  }

  &[aria-expanded='true'].dropdown-toggle,
  &.dropdown-toggle:disabled {
    color: ${({ theme: { colors } }) => colors['black-2']};
    background-color: ${({ theme: { colors } }) => colors.white};
    border-color: ${({ theme: { colors } }) => colors['grey-1']};
  }
`;

export const AdjustMenu = styled(Menu)<{ maxHeight: string }>`
  width: 100%;
  font-size: ${({ theme: { fontSize } }) => fontSize.m};
  background-color: ${({ theme: { colors } }) => colors['grey-4']};
  overflow-y: auto;
  max-height: ${({ maxHeight }) => maxHeight};
`;

export const AdjustMenuItem = styled(Item)<{ disabled?: boolean }>`
  &,
  &:focus,
  &:active {
    display: flex;
    align-items: center;
    text-align: center;
    padding: 11px 5px;
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    white-space: normal;
  }
`;

export const AdjustViewMenuItem = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 0 5px 5px;
`;

export const AdjustMenuItemTitle = styled.span`
  font-weight: ${({ theme: { boldText } }) => boldText.normal};
  text-transform: none;
  margin-left: 4px;
`;
