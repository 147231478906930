import { Hooks, Row } from 'react-table';
import React from 'react';
import { ILoanStatusGradientBackgroundProps, IVehiclePayload } from 'modules/core/types';
import { useBackgroundPropsByLoanStatus } from 'modules/core/hooks';
import { LOAN_STATUS_GRADIENT_BACKGROUND_COLORS_MAP } from 'modules/core/constants';

import { TableCheckbox } from '../TableCheckbox';
import { SelectCell } from './styles';
import { SELECT_ALL_COLUMN_ID } from '../table.const';

export const useSelectableColumns = (selectable: boolean, noColoring?: boolean) => (hooks: Hooks): void => {
  if (selectable) {
    hooks.visibleColumns.push((cols) => [
      {
        id: SELECT_ALL_COLUMN_ID,
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <div>
            <TableCheckbox {...getToggleAllRowsSelectedProps()} />
          </div>
        ),
        Cell: ({ row }: { row: Row<IVehiclePayload> }) => {
          const loanStatusGradientBackgroundColors = useBackgroundPropsByLoanStatus(
            row.original.loan_status,
            LOAN_STATUS_GRADIENT_BACKGROUND_COLORS_MAP,
          );

          return (
            <SelectCell
              backgroundColor={loanStatusGradientBackgroundColors as ILoanStatusGradientBackgroundProps}
              noColoring={noColoring}
            >
              <TableCheckbox {...row.getToggleRowSelectedProps()} />
            </SelectCell>
          );
        },
      },
      ...cols,
    ]);
  }
};
