import { PATTERNS } from 'modules/core/constants';
import i18n from 'i18n';
import { RegisterOptions } from 'react-hook-form';

export const PHONE_INPUT_RULES: RegisterOptions = {
  required: `${i18n.t('components.creditInterview.errors.phoneRequired')}`,
  pattern: {
    value: PATTERNS.PHONE_PATTERN,
    message: i18n.t('common.errorMessages.phoneMessage'),
  },
};

export const TEXT_INPUT_RULES: RegisterOptions = {
  required: `${i18n.t('components.creditInterview.errors.name')}`,
  pattern: {
    value: PATTERNS.NAME_PATTERN,
    message: i18n.t('common.errorMessages.nameMessage'),
  },
};
