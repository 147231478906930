import { useTranslation } from 'react-i18next';
import { useContactAdministratorLink } from 'modules/core/hooks';
import { EMPTY_STRING, STATIC_IMAGE_URLS } from 'modules/core/constants';
import { EXTERNAL_PATH } from 'router/const/externalPath.const';
import { PERMISSIONS } from 'modules/PermissionWrapper/permissions.const';
import { ILinkProps } from 'modules/core/types';
import { Roles } from 'modules/core/enums';

interface ILinkConfig extends ILinkProps {
  iconSrc: string;
  allowedRoles?: Roles[];
  title: string;
}

export const useLinkConfig = (): ILinkConfig[] => {
  const { t } = useTranslation();
  const contactAdministratorLink = useContactAdministratorLink();

  return [
    {
      isExternal: true,
      path: contactAdministratorLink,
      iconSrc: STATIC_IMAGE_URLS.icons.message,
      title: t('components.errorPage.links.contactAdministrator'),
    },
    {
      isExternal: true,
      path: EXTERNAL_PATH.ADMIN_PANEL,
      iconSrc: STATIC_IMAGE_URLS.icons.house,
      allowedRoles: PERMISSIONS.REDIRECT_TO_ADMIN_PANEL_BY_ERROR_PAGE,
      title: t('components.errorPage.links.goToHomePage'),
    },
    {
      isExternal: true,
      path: EMPTY_STRING,
      iconSrc: STATIC_IMAGE_URLS.icons.house,
      allowedRoles: PERMISSIONS.REDIRECT_TO_BASE_PAGE_BY_ERROR_PAGE,
      title: t('components.errorPage.links.goToHomePage'),
    },
  ];
};
