export const UNLOCK_INVENTORY_FORM_INITIAL_VALUES = {
  phoneNumber: '',
  billingContact: '',
  confirmPrice: false,
  confirmTerms: false,
};

export const MODAL_STEPS = {
  FIRST: 1,
  SECOND: 2,
  THIRD: 3,
};
