export const ADD_VEHICLE_FORM_PREFIX = 'addVehicleForm';

export const ADD_VEHICLE_FIELDS_NAMES = {
  VIN: `${ADD_VEHICLE_FORM_PREFIX}.vin`,
  MAKE: `${ADD_VEHICLE_FORM_PREFIX}.make`,
  MODEL: `${ADD_VEHICLE_FORM_PREFIX}.model`,
  YEAR: `${ADD_VEHICLE_FORM_PREFIX}.year`,
  MILEAGE: `${ADD_VEHICLE_FORM_PREFIX}.mileage`,
  RETAIL_PRICE: `${ADD_VEHICLE_FORM_PREFIX}.retailPrice`,
  BOOK_VALUE: `${ADD_VEHICLE_FORM_PREFIX}.bookValue`,
};
