import styled from 'styled-components';

export const TableHeaderCell = styled.th`
  background-color: ${({ theme: { colors } }) => colors['white-2']};
  color: ${({ theme: { colors } }) => colors['grey-3']};
  font-weight: normal;
  padding: 0 !important;
  vertical-align: top;
  border: 0;
  text-align: center;
  outline: 2px solid #fff;
  outline-offset: -1px;
`;

export const TableHeaderCellDiv = styled.div`
  padding: 8px;
  width: 100%;
  height: 100%;
`;

export const TableHeaderRowElement = styled.tr`
  th {
    position: sticky;
    top: -3px;
  }
  z-index: 1;
`;
