import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonPrint, SummaryButtonGroup } from 'modules/ApprovedLoanInput/styles';
import { INITIAL_VALUES } from 'modules/ApprovedLoanInput/approvedLoanInput.const';
import { Button } from 'modules/core/components';
import { getIncome, getIncomeCalculatorReportData } from 'store/selectors/approvedLoanInput.selector';
import { getSubventionYearDataThunk } from 'actions/controlActions';
import { AppDispatch } from 'store/store';
import { ResetModal } from 'modules/ApprovedLoanInput/Summary/ResetModal';
import { ButtonVariant } from 'modules/core/enums';
import { useIncomeReportButton, useModalState } from 'modules/core/hooks';
import { MODAL_DIALOG_ID } from 'modules/core/constants';
import {
  setIncome,
  resetIncome,
  resetVehicles,
  resetByStockOptions,
  setIsReset,
} from 'store/reducers/approvedLoan.reducer';
import { IncomeCalculator } from 'components/IncomeCalculator';
import { IIncomeForm } from 'components/IncomeCalculator/types';

export const HeaderComponent: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();
  const incomeCalculatorData = useSelector(getIncomeCalculatorReportData);
  const { isButtonPrintDisabled, onButtonPrintClick } = useIncomeReportButton(incomeCalculatorData);

  const {
    isModalDialogActive: isResetDialogActive,
    handleOpenDialog: handleResetDialogOpen,
    handleCloseDialog: handleResetDialogClose,
  } = useModalState([MODAL_DIALOG_ID.RESET_FORM]);

  const {
    isModalDialogActive: isIncomeDialogActive,
    handleOpenDialog: handleIncomeDialogOpen,
    handleCloseDialog: handleIncomeDialogClose,
  } = useModalState([MODAL_DIALOG_ID.INCOME_CALCULATOR]);

  useEffect(() => {
    dispatch(getSubventionYearDataThunk());
  }, [dispatch]);

  const onIncomeCalculatorSubmit = useCallback(
    (incomeForm: IIncomeForm) => {
      dispatch(setIncome(incomeForm));
    },
    [dispatch],
  );

  const resetStateCallback = useCallback(() => {
    dispatch(resetVehicles());
    dispatch(resetIncome());
    dispatch(setIsReset(true));
  }, [dispatch]);

  return (
    <SummaryButtonGroup>
      <Button
        title={t('components.approvedLoanInput.approvedLoanTerms.buttons.incomeCalculator')}
        onClick={handleIncomeDialogOpen}
      />
      <IncomeCalculator
        show={isIncomeDialogActive}
        onHide={handleIncomeDialogClose()}
        onIncomeCalculatorSubmit={onIncomeCalculatorSubmit}
        incomesSelector={getIncome}
      />
      <ButtonPrint onClick={onButtonPrintClick} disabled={isButtonPrintDisabled} />
      <Button
        title={t('components.approvedLoanInput.approvedLoanTerms.buttons.reset')}
        onClick={handleResetDialogOpen}
        buttonTheme={ButtonVariant.OUTLINE}
      />
      <ResetModal
        show={isResetDialogActive}
        onHide={handleResetDialogClose()}
        initialValues={INITIAL_VALUES}
        resetStateCallback={resetStateCallback}
        resetVehicleFiltersDirtyFields={() => dispatch(resetByStockOptions())}
      />
    </SummaryButtonGroup>
  );
};
