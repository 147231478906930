import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import { COLORS } from 'modules/core/theme/colors';

export const CalendarUI = styled(DatePicker)<{ isInvalid: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: ${COLORS.GREY_21};
  background-color: ${COLORS.WHITE};
  background-clip: padding-box;
  border: 1px solid ${COLORS.GREY_18};
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &.calendar-input {
    border: ${(props) => props.isInvalid && `1px solid ${COLORS.RED_3}`};
  }

  &.react-datepicker-ignore-onclickoutside:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
  }
`;
