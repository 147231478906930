export const LOAN_VARIABLES_FORM_PREFIX = 'loanVariablesForm';

export const LOAN_VARIABLES_FIELDS_NAMES = {
  ADD_ONS: `${LOAN_VARIABLES_FORM_PREFIX}.addOns`,
  ADJUSTED_PRICE: `${LOAN_VARIABLES_FORM_PREFIX}.adjustedPrice`,
  APR: `${LOAN_VARIABLES_FORM_PREFIX}.apr`,
  CASH_DOWN: `${LOAN_VARIABLES_FORM_PREFIX}.cashDown`,
  CONFIRM_BOOK: `${LOAN_VARIABLES_FORM_PREFIX}.confirmBook`,
  CONFIRM_SUBMIT: `${LOAN_VARIABLES_FORM_PREFIX}.confirmSubmit`,
  CONFIRM_TAXES: `${LOAN_VARIABLES_FORM_PREFIX}.confirmTaxes`,
  GAP: `${LOAN_VARIABLES_FORM_PREFIX}.gap`,
  GUIDEBOOK_VALUE: `${LOAN_VARIABLES_FORM_PREFIX}.guidebookValue`,
  LOAN_TERM: `${LOAN_VARIABLES_FORM_PREFIX}.loanTerm`,
  LOAN_TERMS_LIST: `${LOAN_VARIABLES_FORM_PREFIX}.loanTermsList`,
  MILEAGE: `${LOAN_VARIABLES_FORM_PREFIX}.mileage`,
  MONTHLY_INCOME: `${LOAN_VARIABLES_FORM_PREFIX}.monthlyIncome`,
  MONTHLY_INCOME_FORM: `${LOAN_VARIABLES_FORM_PREFIX}.monthlyIncomeForm`,
  NET_TRADE_IN: `${LOAN_VARIABLES_FORM_PREFIX}.netTradeIn`,
  NET_TRADE_IN_FORM: `${LOAN_VARIABLES_FORM_PREFIX}.netTradeInForm`,
  PAYMENT: `${LOAN_VARIABLES_FORM_PREFIX}.payment`,
  PRICE: `${LOAN_VARIABLES_FORM_PREFIX}.price`,
  REBATES: `${LOAN_VARIABLES_FORM_PREFIX}.rebates`,
  RETAIL_PRICE: `${LOAN_VARIABLES_FORM_PREFIX}.retailPrice`,
  SALES_FEES_FORM: `${LOAN_VARIABLES_FORM_PREFIX}.salesFeesForm`,
  SAVED_INCOMES: `${LOAN_VARIABLES_FORM_PREFIX}.savedIncomes`,
  STOCK: `${LOAN_VARIABLES_FORM_PREFIX}.stock`,
  TAXES: `${LOAN_VARIABLES_FORM_PREFIX}.taxes`,
  TIER: `${LOAN_VARIABLES_FORM_PREFIX}.vehicleTier`,
  TOTAL_AMOUNT_FINANCED: `${LOAN_VARIABLES_FORM_PREFIX}.totalAmountFinanced`,
  TOTAL_SALES_FEES: `${LOAN_VARIABLES_FORM_PREFIX}.totalSalesFees`,
  VIN: `${LOAN_VARIABLES_FORM_PREFIX}.vin`,
  VSC: `${LOAN_VARIABLES_FORM_PREFIX}.vsc`,
  LTV_CATEGORIES: `${LOAN_VARIABLES_FORM_PREFIX}.ltvCategories`,
  IS_RECALCULATE_BUTTON_DISABLED: `${LOAN_VARIABLES_FORM_PREFIX}.isRecalculateButtonDisabled`,
  IS_VERIFY_DEAL_BUTTON_DISABLED: `${LOAN_VARIABLES_FORM_PREFIX}.isVerifyDealButtonDisabled`,
  IS_BOOK_VERIFIED: `${LOAN_VARIABLES_FORM_PREFIX}.isBookVerified`,
  CURRENT_PAYMENT: `${LOAN_VARIABLES_FORM_PREFIX}.currentPayment`,
  PARTICIPATION: `${LOAN_VARIABLES_FORM_PREFIX}.participation`,
};
