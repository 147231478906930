import React, { FC, useCallback, useEffect, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Form, Row } from 'react-bootstrap';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Button, PageHeader } from 'modules/core/components';
import { ButtonVariant, ColumnWidth, TableName } from 'modules/core/enums';
import { useModalState } from 'modules/core/hooks/useModalState';
import {
  useDebounceCallback,
  useFeesAndTaxesInitialValues,
  useFormEnhanced,
  useDebounceVariable,
  useSettingsInitialValues,
} from 'modules/core/hooks';
import {
  probabilityEstimatorSlice,
  resetByStock,
  resetVehicles,
  resetVehiclesByParams,
  resetVehiclesByStock,
  setIsTableView,
} from 'store/reducers/probabilityEstimator.reducer';
import {
  getIsTableView,
  getNotAvailableVehicles,
  getNotFoundVehicles,
  getProbabilityEstimatorInitialValues,
  getProbabilityEstimatorReportData,
  getSelectedRows,
  getVehicleFetchingFlags,
  getVehiclesByParams,
  getVehiclesByParamsCount,
  getVehiclesByStock,
} from 'store/selectors/probabilityEstimator.selector';
import { AppDispatch } from 'store/store';
import {
  PROBABILITY_ESTIMATOR_BY_STOCK_FORM_NAMES_MAPPER,
  PROBABILITY_ESTIMATOR_FORM_NAMES_MAPPER,
} from 'modules/core/constants/mappers/submitVehicles.mapper';
import { getSubmitVehicleObjectQuery, getTotalVehicles } from 'modules/core/utils';
import {
  getVehiclesByStockForProbabilityEstimatorThunk,
  getVehiclesForProbabilityEstimatorThunk,
} from 'actions/vehicleActions';
import { MODAL_DIALOG_ID } from 'modules/core/constants/modalDialog.const';
import { SettingsModal } from 'modules/ProbabilityEstimator/SettingsModal';
import { vehicleApi } from 'api';
import { IS_VALID_DELAY, REQUEST_DELAY } from 'modules/core/constants';
import { IVehicle } from 'modules/core/types';
import { useSwitchFilters } from 'modules/core/hooks/useSwitchFilters';
import { TableData } from 'modules/core/components/Table/types';

import { ConsumerInformation } from './ConsumerInformation';
import {
  DEFAULT_PLACEHOLDER_TITLE,
  PROBABILITY_ESTIMATOR_FIELDS,
  VEHICLES_COUNT_FIELDS_SUBSCRIPTION,
} from './probabilityEstimator.const';
import { IProbabilityEstimatorForm } from './types';
import { ProbabilityEstimatorService } from './services/probabilityEstimator.service';
import { AdaptedCol } from './styles';
import { VehicleSearchTable } from '../VehicleSearchTable';
import { CreditScoreColors } from './enums/creditScoreColors.enum';
import { FilterView } from '../core/enums/filterView.enum';
import { CONSUMER_INFORMATION_FIELDS } from './ConsumerInformation/consumerInformationFields.const';
import { PageName } from './ConsumerInformation/hooks/useConsumerInformationData';
import { TitleContainer } from '../core/components/PageHeader/TitleContainer/TitleContainer';

export const ProbabilityEstimator: FC = () => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const vehiclesByParams = useSelector(getVehiclesByParams);
  const vehiclesByParamsCount = useSelector(getVehiclesByParamsCount);
  const vehiclesByStock = useSelector(getVehiclesByStock);
  const { isFetchingVehicles, isFetchingVehiclesFailed } = useSelector(getVehicleFetchingFlags, shallowEqual);
  const notAvailableVehicles = useSelector(getNotAvailableVehicles);
  const notFoundVehicles = useSelector(getNotFoundVehicles);
  const isTableView = useSelector(getIsTableView);
  const selectedRows: TableData[] = useSelector(getSelectedRows);

  const [currentOffset, setOffset] = useState(0);
  const [currentOrdering, setOrdering] = useState(null);
  const [isServerPagination, setServerPagination] = useState(true);
  const [skipAutoStateReset, setSkipAutoStateReset] = useState(false);

  const { methods, syncFormAction } = useFormEnhanced<IProbabilityEstimatorForm>(
    probabilityEstimatorSlice.actions.syncInitialValues,
    getProbabilityEstimatorInitialValues,
    {
      mode: 'onChange',
      shouldUnregister: false,
    },
  );

  const {
    handleSubmit,
    getValues,
    formState: { isValid },
    watch,
    setValue,
  } = methods;

  const { creditScore, stocks, filterView, ...commonVehiclesCountValues } = watch(VEHICLES_COUNT_FIELDS_SUBSCRIPTION);
  const { tableView, netDownPayment, desiredMonthlyPayment, grossMonthlyIncome, preferences, feesAndTaxes } = watch();

  const commonDependencies = [creditScore.id, grossMonthlyIncome, desiredMonthlyPayment];

  const modalDependencies = [...Object.values(preferences), ...Object.values(feesAndTaxes), netDownPayment];

  const { currentFilterName, isValidStock, isRequestNeeded } = useSwitchFilters(
    commonDependencies,
    filterView,
    isFetchingVehiclesFailed,
    watch,
    setValue,
    modalDependencies,
  );

  useEffect(() => {
    if (isRequestNeeded) {
      if (filterView === FilterView.BY_PARAMS) dispatch(resetVehiclesByParams());
      else dispatch(resetVehiclesByStock());
    }

    setServerPagination(filterView === FilterView.BY_PARAMS ? true : false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, filterView]);

  const vehicles: IVehicle[] | undefined = filterView === FilterView.BY_PARAMS ? vehiclesByParams : vehiclesByStock;
  const totalVehicles = getTotalVehicles(filterView, isValidStock, vehicles, vehiclesByParamsCount);

  useEffect(() => {
    if (!isValidStock) dispatch(resetByStock());
  }, [dispatch, isValidStock]);

  useEffect(() => {
    if (!stocks.length && vehicles?.length) dispatch(resetByStock());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, vehicles?.length]);

  useSettingsInitialValues(setValue, getProbabilityEstimatorInitialValues);
  useFeesAndTaxesInitialValues(setValue, getProbabilityEstimatorInitialValues);

  const getVehicleParameters = (values: IProbabilityEstimatorForm, isFilterByParams: Boolean) => {
    const CURRENT_PROBABILITY_ESTIMATOR_FORM_NAMES_MAPPER = isFilterByParams
      ? PROBABILITY_ESTIMATOR_FORM_NAMES_MAPPER
      : PROBABILITY_ESTIMATOR_BY_STOCK_FORM_NAMES_MAPPER;

    if (typeof values.vehicleFilters.vehicleType === 'string') {
      values.condition = values.vehicleFilters.vehicleType;
    }

    // eslint-disable-next-line no-param-reassign
    if (!isFilterByParams) values.vehicleFilters.minMonthlyPayment = 0;

    return getSubmitVehicleObjectQuery<IProbabilityEstimatorForm>(
      values,
      ProbabilityEstimatorService.getSubmitQuery,
      CURRENT_PROBABILITY_ESTIMATOR_FORM_NAMES_MAPPER,
    );
  };

  const onSubmit = useCallback(
    () => {
      const values = getValues() as IProbabilityEstimatorForm;
      const isFilterByParams = values.filterView === FilterView.BY_PARAMS;

      syncFormAction();
      const vehicleParameters = getVehicleParameters(values, isFilterByParams);

      if (isFilterByParams) {
        setSkipAutoStateReset(false);
        dispatch(getVehiclesForProbabilityEstimatorThunk({ ...vehicleParameters, limit: values.limit }));
        setSkipAutoStateReset(true);
      } else dispatch(getVehiclesByStockForProbabilityEstimatorThunk(vehicleParameters));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, syncFormAction, getValues],
  );

  const onPageChanged = useCallback(
    (values: IProbabilityEstimatorForm, limit, offset, ordering) => {
      const isFilterByParams = values.filterView === FilterView.BY_PARAMS;

      syncFormAction();
      const vehicleParameters = getVehicleParameters(values, isFilterByParams);

      if (isFilterByParams) {
        dispatch(
          getVehiclesForProbabilityEstimatorThunk({
            ...vehicleParameters,
            limit: limit,
            offset: offset,
            ordering: ordering,
          }),
        );
      } else dispatch(getVehiclesByStockForProbabilityEstimatorThunk(vehicleParameters));
    },
    [syncFormAction, dispatch],
  );

  const onSubmitDebounced = useDebounceCallback((values: unknown) => {
    onSubmit();
  }, REQUEST_DELAY);

  const { handleOpenDialog, isModalDialogActive } = useModalState([MODAL_DIALOG_ID.PREFERENCES]);

  const headerComponent = (
    <Button title={t('components.preferences.title')} buttonTheme={ButtonVariant.OUTLINE} onClick={handleOpenDialog} />
  );

  // useDebounceVariable hook is used to fix isValid variable state behavior
  const debouncedIsValid = useDebounceVariable(isValid, IS_VALID_DELAY);

  const areRequiredFieldsFilledIn = Boolean(creditScore.color !== CreditScoreColors.DEFAULT && grossMonthlyIncome);

  const previewPlaceholder = t('components.probabilityEstimator.previewPlaceholderPostfix', {
    totalVehicles,
    count: totalVehicles,
  });

  useEffect(() => {
    if (!areRequiredFieldsFilledIn) return;

    if (debouncedIsValid && areRequiredFieldsFilledIn && isValidStock && isRequestNeeded) {
      const values = getValues();

      vehicleApi.cancelGetVehiclesRequests();
      vehicleApi.cancelGetVehiclesByStockRequests();
      onSubmitDebounced(values);
      setValue(currentFilterName, false);
    }
  }, [
    currentFilterName,
    setValue,
    isRequestNeeded,
    isValidStock,
    getValues,
    tableView,
    onSubmitDebounced,
    debouncedIsValid,
    creditScore.id,
    grossMonthlyIncome,
    areRequiredFieldsFilledIn,
    stocks.length,
    filterView,
    // Inserting the commonVehiclesCountValues object initiates a comparison by reference and an additional hook launch.
    // This technique is used to avoid spreading the entire commonVehiclesCountValues object separately.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ...Object.values(commonVehiclesCountValues),
  ]);
  /* eslint-enable react-hooks/exhaustive-deps */

  // Reset vehicles if the user has deleted some of the required input values
  useEffect(() => {
    if (typeof totalVehicles !== 'undefined' && !areRequiredFieldsFilledIn) {
      dispatch(resetVehicles());
    }
  }, [dispatch, totalVehicles, areRequiredFieldsFilledIn]);

  useEffect(() => {
    if (!areRequiredFieldsFilledIn) {
      vehicleApi.cancelGetVehiclesRequests();
      vehicleApi.cancelGetVehiclesByStockRequests();
    }
  }, [areRequiredFieldsFilledIn]);

  const titleContainer = <TitleContainer title={t('components.probabilityEstimator.title')} />;

  const fetchData = useCallback(
    ({ pageSize, pageIndex, sortBy }: { pageSize: number; pageIndex: number; sortBy: any }) => {
      if (!areRequiredFieldsFilledIn) return;
      const offset = pageSize * pageIndex;
      const values = getValues();

      if (
        (values.limit !== pageSize || currentOffset !== offset || (currentOrdering && currentOrdering !== sortBy)) &&
        skipAutoStateReset
      ) {
        vehicleApi.cancelGetVehiclesRequests();
        vehicleApi.cancelGetVehiclesByStockRequests();

        onPageChanged(values as IProbabilityEstimatorForm, pageSize, offset, sortBy);
      }

      setValue(PROBABILITY_ESTIMATOR_FIELDS.LIMIT, pageSize);
      setOffset(offset);
      setOrdering(sortBy);
    },
    [areRequiredFieldsFilledIn, getValues, currentOffset, currentOrdering, skipAutoStateReset, setValue, onPageChanged],
  );

  return (
    <FormProvider {...methods}>
      <PageHeader title={titleContainer} headerComponent={headerComponent} />
      <Form onSubmit={handleSubmit(onSubmit)}>
        {isModalDialogActive && (
          <SettingsModal tableName={TableName.PE} settingSubmitCallback={handleSubmit(onSubmit)} />
        )}
        <Row>
          <AdaptedCol xs={ColumnWidth.FULL}>
            <ConsumerInformation
              title={t('components.consumerInformation.title')}
              totalVehicles={totalVehicles}
              firstConsumerFields={CONSUMER_INFORMATION_FIELDS}
              isFetchingVehicles={isFetchingVehicles}
              pageName={PageName.PROBABILITY}
              submitCallback={handleSubmit(onSubmit)}
              areRequiredFieldsFilledIn={areRequiredFieldsFilledIn}
            />
          </AdaptedCol>
        </Row>
        <Row>
          <AdaptedCol xs={ColumnWidth.FULL}>
            <VehicleSearchTable
              tableName={TableName.PE}
              data={vehicles || []}
              reportDataSelector={getProbabilityEstimatorReportData}
              tableSubmitCallback={handleSubmit(onSubmit)}
              previewVehiclesCount={totalVehicles}
              defaultPlaceholderTitle={DEFAULT_PLACEHOLDER_TITLE}
              previewPlaceholder={previewPlaceholder}
              isTableFiltersDisabled={!debouncedIsValid}
              isTableActionsDisabled={!vehicles?.length || !debouncedIsValid}
              isTableView={isTableView}
              setIsTableView={setIsTableView}
              fetchingVehicleFlagsSelector={getVehicleFetchingFlags}
              areRequiredFieldsFilledIn={areRequiredFieldsFilledIn}
              notAvailableVehicles={notAvailableVehicles}
              notFoundVehicles={notFoundVehicles}
              isFilterByStockNumber
              isValidStock={isValidStock}
              totalCount={totalVehicles}
              fetchData={fetchData}
              isServerPagination={isServerPagination}
              selectedRows={selectedRows}
              skipAutoStateReset={skipAutoStateReset}
            />
          </AdaptedCol>
        </Row>
      </Form>
    </FormProvider>
  );
};
