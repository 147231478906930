import { RootState } from 'store/store';
import { createSelector } from '@reduxjs/toolkit';
import { ISubventionYear } from 'modules/ApprovedLoanInput/types';
import { IControlReducerState } from 'store/reducers/controls.reducer';
import { CreditScore } from 'modules/ProbabilityEstimator/types';
import { IControlNumbers, IFeesAndTaxes } from 'modules/core/types';
import { EMPTY_STRING } from 'modules/core/constants';

export const getControlsState = (state: RootState): IControlReducerState => state.controls;

export const getSubventionYearState = (state: RootState): ISubventionYear[] => getControlsState(state).subventionYear;

export const getFeesAndTaxesState = (state: RootState): IFeesAndTaxes => getControlsState(state).feesAndTaxes;

export const getSubventionYearOptions = createSelector(getSubventionYearState, (subventionYears) =>
  subventionYears
    .map((item: ISubventionYear) => ({
      title: item.vehicle_year,
      value: parseInt(item.vehicle_year, 10),
    }))
    .sort((a, b) => (b.value > a.value ? 1 : -1)),
);

export const getInterestRate = (state: RootState): CreditScore[] => {
  const { interestRateList } = getControlsState(state);

  return interestRateList.map(
    (interestRate: any): CreditScore => {
      const { colour, ...rest } = interestRate;

      return { ...rest, color: colour };
    },
  );
};

export const getAdjustEventMessage = createSelector(
  getControlsState,
  ({ adjustEventMessages }): string => adjustEventMessages?.[0]?.value || EMPTY_STRING,
);

export const getControlNumbers = createSelector(
  getControlsState,
  ({ controlNumbers }): IControlNumbers => ({
    weeklyToMonthly: controlNumbers?.WeeklyToMonthlyConversion ?? 0,
    nysDisability: controlNumbers?.NYSWeeklyDisabilityInsurance ?? 0,
    socialSecurityGross: controlNumbers?.SocialSecurityGrossUpMultiple ?? 0,
  }),
);
