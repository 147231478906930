import { EstimatorType, TableView } from 'modules/core/enums';
import {
  IFeesAndTaxesFormOptions,
  IProbabilityEstimatorPreferencesFormOptions,
  IVehicleFiltersForm,
} from 'modules/core/types';
import { COLON_SYMBOL, COMMA_SYMBOL, EMPTY_STRING } from 'modules/core/constants';
import { FILTER_OPTION_VALUES } from 'modules/core/components/VehicleFilters/vehicleFilters.const';
import { FILTERS_MAPPER } from 'modules/core/constants/mappers/submitVehicles.mapper';
import { convertFalsyValuesToZero } from 'modules/core/utils';
import { ALL_VALUES } from 'modules/core/components/Multiselect/Multiselect.const';

import { IProbabilityEstimatorForm } from '../types';

export const MAP_AGES_KEY_BY_VALUE = new Map<string, Record<string, number>>([
  [FILTER_OPTION_VALUES.AGES.LESS_10_DAYS, { age__lt: 10 }],
  [FILTER_OPTION_VALUES.AGES.MORE_10_DAYS, { age__gte: 10 }],
  [FILTER_OPTION_VALUES.AGES.MORE_20_DAYS, { age__gte: 20 }],
  [FILTER_OPTION_VALUES.AGES.MORE_30_DAYS, { age__gte: 30 }],
  [FILTER_OPTION_VALUES.AGES.MORE_60_DAYS, { age__gte: 60 }],
  [FILTER_OPTION_VALUES.AGES.MORE_90_DAYS, { age__gte: 90 }],
]);

const MAKES_TYPE_MAP = new Map<string, string>([
  [FILTER_OPTION_VALUES.DOMESTIC, 'DOMESTIC'],
  [FILTER_OPTION_VALUES.IMPORT, 'IMPORT'],
]);

export class ProbabilityEstimatorService {
  static getFilterObject(filters: IVehicleFiltersForm, tableView: TableView): Record<string, unknown> {
    const {
      miles,
      odometerMiles,
      years,
      yearsValue,
      ages,
      stores,
      vehicles,
      makes,
      models,
      driveType,
      passengerCapacity,
      extraCash,
      ...rest
    } = filters;

    return {
      ...rest,
      [miles]: odometerMiles,
      [years]: yearsValue,
      ...ProbabilityEstimatorService.getMultipleParamsObject(FILTERS_MAPPER.body_types, vehicles),
      ...ProbabilityEstimatorService.getMakesByType(makes),
      ...ProbabilityEstimatorService.getMultipleParamsObject(FILTERS_MAPPER.model, models),
      ...ProbabilityEstimatorService.getCorrectAges(ages),
      ...ProbabilityEstimatorService.getMultipleParamsObject(FILTERS_MAPPER.dealer, stores),
      ...ProbabilityEstimatorService.getMultipleParamsObject(FILTERS_MAPPER.drive_type, driveType),
      ...ProbabilityEstimatorService.getMultipleParamsObject(FILTERS_MAPPER.passenger_capacity, passengerCapacity),
      ...ProbabilityEstimatorService.getExtraCash(tableView, extraCash),
    };
  }

  static getSubmitQuery(formValues: IProbabilityEstimatorForm): Record<string, unknown> {
    const interestRate = formValues?.creditScore?.interest_rate;
    const {
      feesAndTaxes,
      preferences,
      totalNetDownPaymentDialog,
      vehicleFilters,
      tableView,
      ...restValues
    } = formValues;
    const { salesTaxRate, ...restFeesAndTaxes } = feesAndTaxes;

    return {
      ...restValues,
      ...ProbabilityEstimatorService.getFilterObject(vehicleFilters, tableView),
      ...convertFalsyValuesToZero(restFeesAndTaxes as Record<IFeesAndTaxesFormOptions, number>),
      ...convertFalsyValuesToZero(preferences as Record<IProbabilityEstimatorPreferencesFormOptions, string | number>),
      interestRate,
      tableView,
      salesTaxRate,
      netDownPayment: totalNetDownPaymentDialog.totalNetDownPayment || 0,
      estimatorType: EstimatorType.PROBABILITY_ESTIMATOR,
      amountOwned: totalNetDownPaymentDialog.amountOwed || 0,
      tradeValue: totalNetDownPaymentDialog.tradeValue || 0,
      cashDown: totalNetDownPaymentDialog.cashDown || 0,
    };
  }

  private static getMakesByType(makes?: string): Record<string, string[] | string> {
    const makesArr = makes?.split(COMMA_SYMBOL).map((param: string) => param.trim());
    const makeType = makesArr?.filter(
      (make) => make === FILTER_OPTION_VALUES.DOMESTIC || make === FILTER_OPTION_VALUES.IMPORT,
    )[0];

    if (makeType && MAKES_TYPE_MAP.get(makeType)) {
      return { make_type: MAKES_TYPE_MAP.get(makeType) || EMPTY_STRING };
    }

    return makesArr?.[0] && !makesArr?.[0].includes(ALL_VALUES) ? { make: makesArr } : {};
  }

  private static getCorrectAges(ages?: string): Record<string, number> {
    if (ages) {
      return MAP_AGES_KEY_BY_VALUE.get(ages) || {};
    }

    return {};
  }

  private static getExtraCash(tableView: TableView, extraCash?: string): Record<string, string> {
    if (extraCash && tableView === TableView.SALESPERSON) {
      return { extraCash: extraCash };
    }

    return {};
  }

  private static getMultipleParamsObject(filterName: string, params: string | undefined): Record<string, any> {
    const responseObj: Record<string, any> = {};

    if (params) {
      const paramsArr = params
        .split(COMMA_SYMBOL)
        .map((param: string) => (param.includes(COLON_SYMBOL) ? param.split(COLON_SYMBOL)[1] : param.trim()));

      responseObj[filterName] = paramsArr[0].includes(ALL_VALUES) ? EMPTY_STRING : paramsArr;

      return responseObj;
    }

    return {};
  }
}
