import styled from 'styled-components';
import { Dropdown } from 'react-bootstrap';
import { STATIC_IMAGE_URLS } from 'modules/core/constants';
import { COLORS } from 'modules/core/theme/colors';
import { WindowBreakpoints } from 'modules/core/enums/windowBreakpoints.enum';

export const HeaderWrapper = styled.div<{ needExtraSpace?: boolean }>`
  position: relative;
  display: flex;
  padding-left: ${({ needExtraSpace }) => (needExtraSpace ? '50px' : '15px')};
  justify-content: space-between;
  background-color: #b2dec7;
  height: 44px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 49px;
    width: 1px;
    height: 100%;
    background-color: #b2dec7;
    z-index: 35;
  }
`;

export const NavigationWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const UserName = styled.div`
  font-size: 17px;
  color: ${COLORS.GREEN_10};
  text-transform: capitalize;

  @media (max-width: ${WindowBreakpoints.small}px) {
    display: flex;
    font-size: ${({ theme: { fontSize } }) => fontSize.normal};
    width: 105px;
    overflow: hidden;
  }
`;

export const UserMenu = styled(Dropdown.Menu)`
  padding: 0;
  background-color: #b2dec7;
  border-radius: 0;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  right: 0;
  left: auto;
`;

export const DropdownToggle = styled(Dropdown.Toggle)`
  &,
  &:hover,
  &:active,
  &:focus {
    margin-right: 28px;
    height: 44px;
    display: inline-block;
    padding: 12px 12px 6px 12px;
    cursor: pointer;
    font-size: 15px;
    color: ${COLORS.GREEN_10} !important;
    background-color: #b2dec7 !important;
    border: none;
    box-shadow: none !important;

    @media (max-width: ${WindowBreakpoints.small}px) {
      margin-right: 2px;
      padding-left: 0;
    }
  }
`;

export const DropdownToggleWithIcon = styled(DropdownToggle)`
  background: url(${STATIC_IMAGE_URLS.icons.user}) no-repeat center;
  background-size: contain;
  height: 30px !important;

  &::after {
    padding-bottom: 5px;
    margin-left: 65px;

    @media (max-width: ${WindowBreakpoints.small}px) {
      font-size: 14px;
      margin-left: 55px;
    }
  }
`;

export const DropdownItem = styled(Dropdown.Item)`
  padding: 7px 53px 7px 10px;
  cursor: pointer;

  &,
  &:hover {
    color: #333333;
  }

  &:hover {
    background-color: ${COLORS.WHITE_2};
  }
`;
