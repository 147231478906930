import { createAsyncThunk } from '@reduxjs/toolkit';

import { ACTION_PREFIX } from './actionPrefix.const';
import { featuresListApi } from '../api';

export const getFeaturesListThunk = createAsyncThunk(
  `${ACTION_PREFIX.FEATURES_LIST}/getFeaturesList`,
  async (subdomain?: string) => {
    const { data } = await featuresListApi.getFeaturesList(subdomain);

    if (!data) throw new Error('Features list is empty');

    return data;
  },
);
