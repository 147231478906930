export const COLORS = {
  BLUE_1: '#4b5d7d',
  BLUE_2: '#2e6da4',
  BLUE_3: '#6f7d97',
  BLUE_4: '#337ab7',
  BLUE_5: '#ccecff',
  BLUE_6: '#035792',
  BLUE_7: '#6289D9',
  BLUE_8: '#6280D9',
  BLUE_9: '#6289d97d',
  BLUE_10: '#80bdff',
  WHITE: '#fff',
  WHITE_2: '#e7e7e7',
  WHITE_3: '#ddd',
  WHITE_4: '#f0f0f0',
  WHITE_5: '#f6f6f6',
  WHITE_6: '#fef4f4',
  WHITE_7: '#f8f5ea',
  WHITE_8: '#eff6f0',
  WHITE_9: '#dee2e6',
  WHITE_10: '#dfdfdf',
  BLACK: '#000',
  BLACK_2: '#333',
  BLACK_3: '#414141',
  GREEN_1: '#c2efca',
  GREEN_2: '#79ac85',
  GREEN_3: '#70ba7d',
  GREEN_4: '#b2dec7',
  GREEN_5: '#92b4a4',
  GREEN_6: '#668072',
  GREEN_7: '#36cb4f',
  GREEN_8: '#70bf2b',
  GREEN_9: '#bceac4',
  GREEN_10: '#728072',
  YELLOW_1: '#ffdfb2',
  YELLOW_2: '#cb9f69',
  YELLOW_3: '#fff0b2',
  YELLOW_4: '#ceb96e',
  YELLOW_5: '#fc0',
  YELLOW_6: '#faebac',
  YELLOW_7: '#cdac36',
  ORANGE_1: '#ff9500',
  ORANGE_2: '#ff992c',
  ORANGE_3: '#f58a7d',
  ORANGE_4: '#ffcc20',
  ORANGE_5: '#e69138',
  RED_1: '#ffc4c0',
  RED_2: '#b47571',
  RED_3: '#ff3b30',
  RED_4: '#ff0000',
  RED_5: '#fbbebb',
  RED_6: '#dc3545',
  GREY_1: '#c0c0c0',
  GREY_2: '#f7f7f7',
  GREY_3: '#747474',
  GREY_4: '#dbdfe5',
  GREY_5: '#414141',
  GREY_6: '#adabab',
  GREY_7: '#a6a6a6',
  GREY_8: '#7c7c7c',
  GREY_9: '#dedede',
  GREY_10: '#f6f6f6',
  GREY_11: '#dbdbdb',
  GREY_12: '#f1f1f1',
  GREY_13: '#c8c5c5',
  GREY_14: '#c8c5c5',
  GREY_15: '#878787',
  GREY_16: '#343a40',
  GREY_17: '#403B4D',
  GREY_18: '#ced4da',
  GREY_19: '#cbcbcbad',
  GREY_20: '#cbcbcb',
  GREY_21: '#495057',
  GREY_22: '#ccc',
  GREY_23: '#949494',
  GREY_24: '#828282',
  GREY_25: '#E3E2E1',
  GREY_26: '#e9ecef',
};
