import { STATIC_IMAGE_URLS } from 'modules/core/constants';
import React, { useCallback, useState } from 'react';
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { Button } from '../common';
import {
  LoginContent,
  PositionCard,
  CardForm,
  CardImage,
  CardTitle,
  TextForgotPassword,
  PositionMiniImage,
  FormPosition,
  ImageSize,
} from '../styles';
import { CreatePasswordForm } from './CreatePasswordForm';

const { logo, icons } = STATIC_IMAGE_URLS;

export const CreatePassword: React.FC = () => {
  const { t } = useTranslation();
  const [successChangedPassword, setSuccessChangedPassword] = useState(false);

  const showCongratulationsContent = useCallback(() => {
    setSuccessChangedPassword(true);
  }, []);

  return (
    <LoginContent>
      <PositionCard>
        <CardForm>
          <CardImage src={logo} />
          {successChangedPassword ? (
            <>
              <PositionMiniImage>
                <ImageSize src={icons.congrats} />
              </PositionMiniImage>
              <Card.Body>
                <CardTitle>{t('components.createPassword.congrats')}</CardTitle>
                <TextForgotPassword>
                  {t('components.createPassword.passwordChanged')}
                  <div>&nbsp;</div>
                  {t('components.createPassword.emailConfirmInfo')}
                </TextForgotPassword>
                <FormPosition>
                  <Button label={t('components.login.buttons.backToLogin')} isOnClick />
                </FormPosition>
              </Card.Body>
            </>
          ) : (
            <Card.Body>
              <CardTitle>{t('components.createPassword.createYourPassword')}</CardTitle>
              <CreatePasswordForm onShowCongratulationsContent={showCongratulationsContent} />
            </Card.Body>
          )}
        </CardForm>
      </PositionCard>
    </LoginContent>
  );
};
