import { Roles } from 'modules/core/enums';
import { IUser } from 'modules/core/types';
import { getAdminLink, getFullPath, getGlobalAdminLink, getManagerAdminLink } from 'router/utils/getRedirectLink';
import { SUPPORTED_FEATURES_MAPPER } from 'modules/core/constants/mappers/supportedFeatures.mapper';
import { IMenuLink } from 'modules/core/components/SideBar/sideBarMenuLinks';
import { EMPTY_STRING, LOGIN_URL_PREFIX, ROUTE_ONE_DOMAIN } from 'modules/core/constants';
import { sortLenderLinks } from 'modules/core/components/SideBar/utils';

import { EXTERNAL_PATH } from './externalPath.const';
import { ROUTER_PATH } from './routerPath.const';

interface INavigationObject {
  method: (link: string) => void;
  path: string;
}

export const getManagerRedirectPath = (disabledFeatures: string[], lenders: IMenuLink[]): string => {
  if (!disabledFeatures.includes(SUPPORTED_FEATURES_MAPPER.probability_estimator)) {
    return ROUTER_PATH.PROBABILITY_ESTIMATOR;
  }

  if (!disabledFeatures.includes(SUPPORTED_FEATURES_MAPPER.approved_loan_input)) {
    return ROUTER_PATH.APPROVED_LOAN_INPUT;
  }

  if (!disabledFeatures.includes(SUPPORTED_FEATURES_MAPPER.rac)) {
    return ROUTER_PATH.RAC_AFFORDABILITY_ENGINE;
  }

  if (lenders.length > 0) {
    const sortedLenders = sortLenderLinks(lenders);

    return (sortedLenders[0]?.link || sortedLenders[0]?.path) as string;
  }

  if (!disabledFeatures.includes(SUPPORTED_FEATURES_MAPPER.credit_interview)) {
    return ROUTER_PATH.CREDIT_INTERVIEW;
  }

  return EMPTY_STRING;
};

export const getSalespersonRedirectPath = (disabledFeatures: string[]): string => {
  if (!disabledFeatures.includes(SUPPORTED_FEATURES_MAPPER.probability_estimator)) {
    return ROUTER_PATH.PROBABILITY_ESTIMATOR;
  }

  if (!disabledFeatures.includes(SUPPORTED_FEATURES_MAPPER.credit_interview)) {
    return ROUTER_PATH.CREDIT_INTERVIEW;
  }

  return ROUTER_PATH.ERROR;
};

export const getDashboardNavigationMethod = (path: string): INavigationObject => ({
  method: (link: string) => window.location.replace(link),
  path: path,
});

export const getRolesDashboardNavigation = (
  user: IUser,
  disabledFeatures: string[],
  lenders: IMenuLink[],
  subdomain = null,
): INavigationObject => {
  if (user?.is_sso) {
    const subdomain = user.isWithInventory ? user.tenant.subdomain : ROUTE_ONE_DOMAIN;

    return getDashboardNavigationMethod(getFullPath(subdomain, ROUTER_PATH.RAC_AFFORDABILITY_ENGINE));
  }

  if (Roles.GLOBALADMIN === user.role) {
    let path: string;

    if (window.location.hostname.includes(LOGIN_URL_PREFIX)) path = getGlobalAdminLink();
    else path = EXTERNAL_PATH.ADMIN_PANEL;

    return getDashboardNavigationMethod(path);
  }

  if (Roles.ADMIN === user.role) {
    const path = getAdminLink(subdomain || user.tenant.subdomain);

    return getDashboardNavigationMethod(path);
  }

  if (Roles.SALESPERSON === user.role) {
    const path = getSalespersonRedirectPath(disabledFeatures);

    return getDashboardNavigationMethod(getFullPath(subdomain || user.tenant.subdomain, path));
  }

  if (Roles.MANAGER === user.role) {
    const path = getManagerRedirectPath(disabledFeatures, lenders);

    if (path === EMPTY_STRING)
      return getDashboardNavigationMethod(getManagerAdminLink(subdomain || user.tenant.subdomain));

    return getDashboardNavigationMethod(getFullPath(subdomain || user.tenant.subdomain, path));
  }

  return getDashboardNavigationMethod(ROUTER_PATH.PROBABILITY_ESTIMATOR);
};
