import { RefObject, useEffect, useState } from 'react';

export const useIsScroll = (ref: RefObject<HTMLElement>): boolean => {
  const [isScroll, setIsScroll] = useState<boolean>(false);

  useEffect(() => {
    const deviation = 0.95;
    const containerHeight = ref.current?.clientHeight || 0;
    const differentHeight = document.documentElement.clientHeight - containerHeight * deviation;

    setIsScroll(differentHeight <= 0);
  }, [ref, ref.current?.clientHeight]);

  return isScroll;
};
