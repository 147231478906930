import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { loanValueFields } from '../../Learning.const';
import { RowTitle, RowSign, RowValue, HorizontalLine, Row } from './styles';

interface ILoanValueField {
  name: string;
  sign: string;
  value: string;
  withLine?: boolean;
}

export const LoanValueTable: React.FC = () => {
  const { t } = useTranslation();

  const renderFields = useCallback(
    (fields: ILoanValueField[]) =>
      fields.map((field) => (
        <div key={field.name}>
          <Row>
            <RowTitle>{t(field.name)}</RowTitle>
            <RowSign>{field.sign}</RowSign>
            <RowValue>{field.value}</RowValue>
          </Row>
          {field.withLine && <HorizontalLine />}
        </div>
      )),
    [t],
  );

  return <>{renderFields(loanValueFields)}</>;
};
