import styled from 'styled-components';
import { ILoanStatusGradientBackgroundProps } from 'modules/core/types';

export const ImageUI = styled.img<{ isImage?: boolean }>`
  cursor: ${({ isImage }) => (isImage ? 'pointer' : 'default')};
  width: 80px;
  max-height: 60px;
  object-fit: contain;
`;

export const Container = styled.div<{ backgroundColors: ILoanStatusGradientBackgroundProps; noColoring?: boolean }>`
  margin: -10px;
  min-height: 80px;
  min-width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ backgroundColors, noColoring }) =>
    !noColoring && `linear-gradient(to left, ${backgroundColors.from}, ${backgroundColors.to})`};
`;
