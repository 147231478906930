import styled, { css } from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import { COLORS } from 'modules/core/theme/colors';

export const rowMixin = css`
  padding: 8px 0;
  margin-bottom: 16px;
`;

export const Container = styled.div`
  span {
    display: inline-block;

    &:first-child {
      margin-bottom: 8px;
    }
  }
`;

export const Title = styled.div`
  font-weight: ${({ theme: { boldText } }) => boldText.dark};
  margin-bottom: 16px;
`;

export const RowUI = styled(Row)`
  ${rowMixin};
`;

export const Header = styled(Row)`
  ${rowMixin};

  background-color: ${COLORS.GREY_12};
`;

export const ColUI = styled(Col)`
  display: flex;
  align-items: center;

  &:last-child {
    display: block;
  }
`;

export const NoDataMessage = styled.span`
  color: ${COLORS.GREY_3};
`;
