import { IOption } from 'modules/core/types';

const ALL_STORES_NAME = 'All';
const STORE_NAME = 'store';
const DEALER_NAME = 'dealer';

const sortedByTitle = (array: IOption[]): IOption[] =>
  array.sort((a, b) => {
    if (a.title.toLowerCase() > b.title.toLowerCase()) return 1;

    if (a.title.toLowerCase() < b.title.toLowerCase()) return -1;

    return 0;
  });

export const sortDealers = (dealerStores: IOption[]): IOption[] => {
  const allStoresItem = [dealerStores.find((item) => item.title === ALL_STORES_NAME)];
  const dealers = dealerStores.filter((item) => typeof item.value === 'string' && item.value.indexOf(STORE_NAME) === 0);
  const stores = dealerStores.filter((item) => typeof item.value === 'string' && item.value.indexOf(DEALER_NAME) === 0);

  const sortedDealers = sortedByTitle(dealers);
  const sortedStores = sortedByTitle(stores);

  return allStoresItem.concat(sortedDealers).concat(sortedStores) as IOption[];
};
