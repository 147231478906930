import styled from 'styled-components';

export const Container = styled.div`
  font-size: ${({ theme: { fontSize } }) => fontSize.mini};
  margin-bottom: 5px;
`;

export const VehicleColor = styled.span`
  color: ${({ theme: { colors } }) => colors['red-3']};
`;
