import styled from 'styled-components';
import { Row } from 'react-bootstrap';
import { COLORS } from 'modules/core/theme/colors';
import { Label } from 'modules/core/components';
import { FONT_SIZE } from 'modules/core/theme/fontSize';
import { disabledSectionMixin } from 'modules/core/theme/mixins';
import { WindowBreakpoints } from 'modules/core/enums/windowBreakpoints.enum';

import { CreditScoreColors } from '../enums/creditScoreColors.enum';
import { CreditScoreColor } from '../types';

type ColoredDiv = { color: CreditScoreColor };

const SLIDER_HEIGHT = 20;
const SLIDER_LINE_SIZE = 28;
const BOX_SHADOW = '0 2px 6px -8px #000, 0 0 39px -2px rgba(204, 204, 204, 0.23)';

const mapTrackStyleByColor = {
  [CreditScoreColors.DEFAULT]: COLORS.GREEN_1,
  [CreditScoreColors.GREY]: COLORS.GREY_19,
  [CreditScoreColors.GREEN]: COLORS.GREEN_1,
  [CreditScoreColors.ORANGE]: COLORS.YELLOW_3,
  [CreditScoreColors.YELLOW]: COLORS.YELLOW_3,
  [CreditScoreColors.RED]: COLORS.RED_1,
};

const mapTitleColorByCreditScore = {
  [CreditScoreColors.DEFAULT]: COLORS.GREEN_3,
  [CreditScoreColors.GREY]: COLORS.GREY_8,
  [CreditScoreColors.GREEN]: COLORS.GREEN_3,
  [CreditScoreColors.ORANGE]: COLORS.YELLOW_4,
  [CreditScoreColors.YELLOW]: COLORS.YELLOW_4,
  [CreditScoreColors.RED]: COLORS.RED_3,
};

export const mapTooltipColorByCreditScore = {
  ...mapTitleColorByCreditScore,
  [CreditScoreColors.GREY]: COLORS.GREY_20,
};

export const mapRadioIncomeColorByCreditScore = {
  ...mapTitleColorByCreditScore,
  [CreditScoreColors.RED]: COLORS.RED_2,
};

export const railStyles = {
  backgroundColor: COLORS.WHITE,
  height: SLIDER_HEIGHT,
  boxShadow: BOX_SHADOW,
};

export const getTrackStyles = (color: CreditScoreColor): { backgroundColor: string; height: number } => {
  const backgroundColor = mapTrackStyleByColor[color];

  return { ...railStyles, backgroundColor };
};

export const getMarksStyles = (label: string): Record<string, unknown> => ({
  style: {
    marginTop: 15,
    fontSize: FONT_SIZE.MINI,
    minWidth: 60,
    color: COLORS.GREY_8,
  },
  label,
});

export const sliderStyles = {
  borderColor: COLORS.GREY_20,
  backgroundColor: COLORS.GREY_20,
  height: SLIDER_LINE_SIZE,
  width: SLIDER_LINE_SIZE,
  boxShadow: BOX_SHADOW,
};

export const tipProps = {
  placement: 'top',
  prefixCls: 'rc-slider-tooltip',
  visible: true,
};

export const MonthlyPaymentSliderWrapper = styled(Row)<{ disabled?: boolean }>`
  display: flex;
  width: 100%;
  height: 100%;
  margin: 2px 0;
  background-color: ${({ theme: { colors } }) => colors['white-4']};
  justify-content: space-between;
  align-items: center;
  padding: 15px 30px;

  ${({ disabled }) => disabled && disabledSectionMixin}

  @media (max-width: ${WindowBreakpoints.large}px) {
    flex-direction: column;
    height: auto;
  }

  @media (max-width: ${WindowBreakpoints.small}px) {
    padding: 15px 20px;
  }

  .rc-slider-tooltip-arrow,
  .rc-slider-dot {
    display: none;
  }

  .rc-slider-tooltip-inner {
    background: none;
    box-shadow: none;
  }
`;

export const SliderLabel = styled(Label)`
  margin-left: 40px;
  margin-right: 40px;
  display: flex;
  justify-content: center;
  margin-bottom: 35px;
  color: ${({ theme: { colors } }) => colors['grey-8']};
  font-weight: ${({ theme: { boldText } }) => boldText.bold};
`;

export const PaymentRation = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0 10px;
`;

export const IncomeRation = styled.span`
  font-weight: ${({ theme: { boldText } }) => boldText.bold};
  font-size: ${({ theme: { fontSize } }) => fontSize.m};
  color: ${({ theme: { colors } }) => colors['grey-8']};
`;

export const RatioPercent = styled.div<ColoredDiv>`
  margin-left: 10px;
  font-size: ${({ theme: { fontSize } }) => fontSize.normal};
  background-color: ${({ color }) => mapTrackStyleByColor[color]};
  color: ${({ color }) => mapRadioIncomeColorByCreditScore[color]};
  border-radius: 4px;
  padding: 0 15px;
`;

export const MonthlyPaymentTooltipText = styled.div<ColoredDiv>`
  font-size: ${({ theme: { fontSize } }) => fontSize.huge};
  min-width: 200px;
  font-weight: ${({ theme: { boldText } }) => boldText.bold};
  background: none;
  color: ${({ color }) => mapTooltipColorByCreditScore[color]};
`;
