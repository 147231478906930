import React, { SFC, useCallback, useEffect, useState } from 'react';
import { ModalTheme, RequestStatus } from 'modules/core/enums';
import {
  clearLoanVariablesCurrentVehicle,
  clearVehicleDetails,
  resetRouteOneDataFlags,
} from 'store/reducers/RACAffordabilityEngine.reducer';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'store/store';
import { ModalContainer, Title } from 'components/ModalDialog/style';
import { useFormContext } from 'react-hook-form';
import { IRACAffordabilityEngineForm } from 'modules/RACAffordabilityEngine/types/RACAffordabilityEngineForm';
import { getSubmitVehicleObjectQuery } from 'modules/core/utils';
import { RACAffordabilityEngineService } from 'modules/RACAffordabilityEngine/RACAffordabilityEngine.service';
import { RAC_FORM_FOR_LOAN_VARIABLES_NAMES_MAPPER } from 'modules/core/constants/mappers/submitVehicles.mapper';
import {
  getLenderLoanTermsListThunk,
  getLenderLoanVariablesInfoThunk,
  getLoanTermsListThunk,
  getLoanVariablesInfoThunk,
} from 'actions/vehicleActions';
import { Modal } from 'react-bootstrap';
import { getVendorName } from 'store/selectors/ui.selector';
import { getLoanVariablesCurrentVehicle, getRouteOneData } from 'store/selectors/RACAffordabilityEngine.selector';
import { getUser } from 'store/selectors/auth.selector';
import { PlaceholderTitle } from 'common/styles';
import { useTranslation } from 'react-i18next';
import { vehicleApi } from 'api';
import { getAddRouteOneVehicleFlags, getCreatedRouteOneVehicle } from 'store/selectors/vehicle.selector';
import { resetCreatedRouteOneVehicle } from 'store/reducers/vehicle.reducer';

import { LoanVariablesFormContent } from './components/LoanVariablesFormContent';
import { Loader } from './Loader';
import { ExitConfirmationModal } from './modals/ExitConfirmationModal/ExitConfirmationModal';
import { DEFAULT_NET_TRADE_IN_VALUES } from './modals/Calculators/NetTradeInCalculator/consts/consts';
import { DEFAULT_LOAN_VARIABLES_VALUES } from './consts/consts';
import { DEFAULT_SALES_FEES_VALUES } from './modals/Calculators/SaleFeesCalculator/consts/consts';
import { FailedBlock, TradeMark } from './styles';

interface ILoanVariablesDialog {
  isLender?: boolean;
  isLoanVariablesDialogActive: boolean;
  isRouteOneCar?: boolean;
  isAddedRouteOneVehicle?: boolean;
  resetState: () => void;
  onHide: () => void;
}

export const LoanVariablesDialog: SFC<ILoanVariablesDialog> = ({
  isLender = false,
  isLoanVariablesDialogActive,
  isRouteOneCar = false,
  isAddedRouteOneVehicle = false,
  resetState,
  onHide,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { getValues: commonGetValues } = useFormContext<IRACAffordabilityEngineForm>();
  const [isShowExitConfirmModal, setIsShowExitConfirmModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isFetchingFailed, setIsFetchingFailed] = useState<boolean>(false);
  const [loanVariables, setLoanVariables] = useState<any>();
  const [loanTermsList, setLoanTermsList] = useState<Array<number>>([]);
  const [formHistory, setFormHistory] = useState<any[]>([]);
  const routeOneData = useSelector(getRouteOneData);
  const createdRouteOneVehicle = useSelector(getCreatedRouteOneVehicle);
  const vendorName = useSelector(getVendorName);
  const addRouteOneVehicleFlags = useSelector(getAddRouteOneVehicleFlags);
  const currentVehicleData = useSelector(getLoanVariablesCurrentVehicle);
  const { t } = useTranslation();
  const {
    totalFrontAndBackEndsAddsDialog,
    totalNetDownPaymentDialog,
    grossMonthlyIncome,
    desiredMonthlyPayment,
    lendersMonthlyIncomeModal,
    feesAndTaxes,
    tier,
    cashDownOverridden,
    estimatedFeesOverridden,
  } = commonGetValues() as any;
  const user = useSelector(getUser);
  const { tier: tierName } = tier;
  const totalNetTradeIn = totalNetDownPaymentDialog.tradeValue - totalNetDownPaymentDialog.amountOwed;
  const netTradeInFormDefaultValue = {
    ...DEFAULT_NET_TRADE_IN_VALUES.netTradeInForm,
    netTradeInTotal: totalNetTradeIn,
    tradeInValue: totalNetDownPaymentDialog.tradeValue,
    amountOwnedOnTrade: totalNetDownPaymentDialog.amountOwed,
    lienHolder: totalNetDownPaymentDialog.lienHolder,
    year: totalNetDownPaymentDialog.year,
    make: totalNetDownPaymentDialog.make,
    model: totalNetDownPaymentDialog.model,
    otherYear: totalNetDownPaymentDialog.otherYear,
    otherMake: totalNetDownPaymentDialog.otherMake,
    otherModel: totalNetDownPaymentDialog.otherModel,
  };
  const isRouteOneVehicle =
    ((loanVariables?.vin || currentVehicleData?.vin) &&
      (loanVariables?.vin || currentVehicleData?.vin) === routeOneData?.sale_vehicle?.vin) ||
    isRouteOneCar;
  const isLtvCategoriesExists =
    (isLender && loanVariables?.ltv_ratio) ||
    (loanVariables?.ltv_categories && loanVariables.ltv_categories.length > 0);
  const isCashDownOverridden = Boolean(cashDownOverridden);
  const isEstimatedFeesOverridden = Boolean(estimatedFeesOverridden);

  const handleHide = useCallback(() => {
    dispatch(clearLoanVariablesCurrentVehicle());
    resetState();
    onHide();
  }, [dispatch, resetState, onHide]);

  const handleHideWithResetVehicle = useCallback(() => {
    handleHide();
    dispatch(resetCreatedRouteOneVehicle());
  }, [dispatch, handleHide]);

  const getCurrentPayment = useCallback(() => {
    if (isLender) {
      return loanVariables?.monthly_payment || NaN;
    } else {
      return isLtvCategoriesExists ? loanVariables.ltv_categories[0].payments : NaN;
    }
  }, [isLender, isLtvCategoriesExists, loanVariables?.ltv_categories, loanVariables?.monthly_payment]);

  const getTotalAmountFinanced = useCallback(() => {
    if (isLender) {
      return loanVariables?.total_financed || NaN;
    } else {
      return isLtvCategoriesExists ? loanVariables.ltv_categories[0].loan_amount : NaN;
    }
  }, [isLender, isLtvCategoriesExists, loanVariables?.ltv_categories, loanVariables?.total_financed]);

  const defaultSalesFeesValue =
    user?.is_sso && isEstimatedFeesOverridden
      ? feesAndTaxes.estimatedFees || 0
      : (routeOneData.taxes_fees?.sales_fees || 0) + (routeOneData.taxes_fees?.title_reg_license || 0);
  const defaultValues = {
    loanVariablesForm: {
      ...DEFAULT_LOAN_VARIABLES_VALUES.loanVariablesForm,
      vin: loanVariables?.vin,
      stock: currentVehicleData?.stock,
      tier: tierName,
      retailPrice: currentVehicleData?.current_price || 0,
      netTradeInForm: netTradeInFormDefaultValue,
      mileage: currentVehicleData?.odometer,
      guidebookValue: currentVehicleData?.guidebook_value || 0,
      monthlyIncome: grossMonthlyIncome,
      addOns: totalFrontAndBackEndsAddsDialog.addOns || 0,
      vsc: totalFrontAndBackEndsAddsDialog.vsc || 0,
      gap: totalFrontAndBackEndsAddsDialog.gap || 0,
      cashDown: totalNetDownPaymentDialog.cashDown || 0,
      participation: 0,
      monthlyIncomeForm: lendersMonthlyIncomeModal,
      netTradeIn: totalNetTradeIn,
      payment: desiredMonthlyPayment,
      totalSalesFees: isLender ? feesAndTaxes.salesFees : feesAndTaxes.estimatedFees,
      salesFeesForm: {
        ...DEFAULT_SALES_FEES_VALUES.salesFeesForm,
        salesDocumentFees: isLender ? feesAndTaxes.salesFees : feesAndTaxes.estimatedFees,
        salesFees: isLender ? feesAndTaxes.salesFees : feesAndTaxes.estimatedFees,
      },
      currentPayment: getCurrentPayment(),
      apr: isLender ? loanVariables?.customer_rate : isLtvCategoriesExists ? loanVariables.ltv_categories[0].rate : NaN,
      loanTerm: { title: loanTermsList[0], value: loanTermsList[0] },
      totalAmountFinanced: getTotalAmountFinanced(),
      taxes: loanVariables?.taxes || 0,
      ltvCategories: isLtvCategoriesExists ? loanVariables.ltv_categories : [],
      isRecalculateButtonDisabled: true,
      isVerifyDealButtonDisabled: false,
      isBookVerified: true,
      vehicle_id: currentVehicleData?.id,
      ...(isRouteOneVehicle && {
        taxes: routeOneData.taxes_fees?.taxes,
        vehicle_id: routeOneData.vehicle_id,
        retailPrice: routeOneData.sale_vehicle.retail_price,
        mileage: routeOneData.sale_vehicle.mileage,
        guidebookValue: routeOneData.sale_vehicle.book_value,
      }),
      ...(isAddedRouteOneVehicle && {
        vehicle_id: createdRouteOneVehicle.id,
        retailPrice: createdRouteOneVehicle.current_price,
        mileage: createdRouteOneVehicle.odometer,
        guidebookValue: createdRouteOneVehicle.book_value,
      }),
      ...(user?.is_sso && {
        cashDown: isCashDownOverridden
          ? totalNetDownPaymentDialog.cashDown || 0
          : routeOneData.finance_details?.cash_down || 0,
        rebates: isCashDownOverridden ? 0 : routeOneData.finance_details?.rebates || 0,
        salesFeesForm: {
          salesDocumentFees: isEstimatedFeesOverridden
            ? feesAndTaxes.estimatedFees || 0
            : routeOneData.taxes_fees?.sales_fees || 0,
          salesFees: defaultSalesFeesValue,
          titleRegistrationLicense: isEstimatedFeesOverridden ? 0 : routeOneData.taxes_fees?.title_reg_license || 0,
        },
        totalSalesFees: defaultSalesFeesValue,
        loanTerm: {
          title: loanVariables?.estimated_term,
          value: loanVariables?.estimated_term,
        },
      }),
    },
  };

  const getLoanVariablesData = useCallback(
    async () => {
      const values = commonGetValues();

      const vehicleParameters = getSubmitVehicleObjectQuery<IRACAffordabilityEngineForm>(
        { ...currentVehicleData, ...values, vendorName: vendorName, isLender },
        RACAffordabilityEngineService.getSubmitQueryForLoanVariables,
        RAC_FORM_FOR_LOAN_VARIABLES_NAMES_MAPPER,
      );

      const params = {
        ...vehicleParameters,
        vehicle_id: currentVehicleData?.id,
        guidebook_value: currentVehicleData?.guidebook_value,
        sales_fees: isLender ? feesAndTaxes.salesFees || 0 : undefined,
        estimated_fees: !isLender ? feesAndTaxes.estimatedFees || 0 : undefined,
        ...(isRouteOneVehicle && {
          ...(routeOneData.taxes_fees?.taxes && { taxes: routeOneData.taxes_fees.taxes || 0 }),
          vehicle_id: routeOneData.vehicle_id,
          guidebook_value: routeOneData.sale_vehicle.book_value,
          taxes: routeOneData.taxes_fees?.taxes || 0,
          add_ons: 0,
          amount_owed: routeOneData.trade_in_vehicle.amount_owed,
          cash_down_payment:
            (routeOneData.finance_details.cash_down || 0) + (routeOneData.finance_details.rebates || 0),
          current_price: routeOneData.sale_vehicle.retail_price,
          loan_term: routeOneData.finance_details.term,
          net_down_payment:
            (routeOneData.trade_in_vehicle.value || 0) - (routeOneData.trade_in_vehicle.amount_owed || 0),
          odometer: routeOneData.sale_vehicle.mileage,
          trade_value: routeOneData.trade_in_vehicle.value,
          sales_tax_rate: feesAndTaxes.salesTaxRate,
        }),
        ...(isAddedRouteOneVehicle && {
          vehicle_id: createdRouteOneVehicle.id,
          guidebook_value: createdRouteOneVehicle.book_value,
          current_price: createdRouteOneVehicle.current_price,
          odometer: createdRouteOneVehicle.odometer,
          loan_term: createdRouteOneVehicle.loan_term,
          taxes: undefined,
        }),
      };

      let loanVariablesInfoResponse;
      let loanTermsListResponse;

      if (isLender) {
        loanVariablesInfoResponse = await dispatch(
          getLenderLoanVariablesInfoThunk({
            ...params,
            bank_discount: feesAndTaxes.bankDiscount,
            bank_fees: feesAndTaxes.bankFees,
            lender_name: commonGetValues().lenderName,
            is_ltv: commonGetValues().preferences.ltvTaxesFeesFilter,
            ltv_high_limit: commonGetValues().preferences.ltvHighLimit,
            monthly_income: grossMonthlyIncome,
            targeted_payment: desiredMonthlyPayment,
          }),
        );
        loanTermsListResponse = await dispatch(
          getLenderLoanTermsListThunk({
            mileage: currentVehicleData?.odometer,
            tier: (commonGetValues().tier as any).tier_name,
            year: currentVehicleData?.description.split(' ')[0],
            lender_name: commonGetValues().lenderName,
          }),
        );
      } else {
        loanVariablesInfoResponse = await dispatch(getLoanVariablesInfoThunk(params));
        loanTermsListResponse = await dispatch(
          getLoanTermsListThunk({
            mileage: isAddedRouteOneVehicle
              ? createdRouteOneVehicle?.odometer
              : currentVehicleData?.odometer || routeOneData?.sale_vehicle?.mileage,
            tier: tierName,
          }),
        );
      }

      [loanVariablesInfoResponse?.meta?.requestStatus, loanTermsListResponse?.meta?.requestStatus].includes(
        RequestStatus.REJECTED,
      ) && setIsFetchingFailed(true);

      setLoanTermsList(loanTermsListResponse.payload);
      setLoanVariables(loanVariablesInfoResponse.payload);

      const loanVariablesInfo: any = loanVariablesInfoResponse.payload;
      const isLtvCategoriesExists = isLender
        ? loanVariablesInfo?.ltv
        : loanVariablesInfo?.ltv_categories && loanVariablesInfo.ltv_categories.length > 0;
      let totalAmountFinanced;
      let apr;
      let currentPayment;

      if (isLender) {
        totalAmountFinanced = loanVariablesInfo?.total_financed || NaN;
        apr = loanVariablesInfo?.customer_rate || NaN;
        currentPayment = loanVariablesInfo?.monthly_payment || [];
      } else {
        apr = isLtvCategoriesExists ? loanVariablesInfo.ltv_categories[0].rate : NaN;
        totalAmountFinanced = isLtvCategoriesExists ? loanVariablesInfo.ltv_categories[0].loan_amount : NaN;
        currentPayment = isLtvCategoriesExists ? loanVariablesInfo.ltv_categories[0].payments : [];
      }

      setFormHistory([
        {
          loanVariablesForm: {
            ...defaultValues.loanVariablesForm,
            loanTerm: { title: loanTermsListResponse.payload[0], value: loanTermsListResponse.payload[0] },
            loanTermsList: loanTermsListResponse.payload,
            apr: apr,
            totalAmountFinanced: totalAmountFinanced,
            taxes: loanVariablesInfo.taxes || 0,
            ltvCategories: isLender ? loanVariablesInfo : isLtvCategoriesExists ? loanVariablesInfo.ltv_categories : [],
            currentPayment: currentPayment,
          },
        },
      ]);

      setIsLoading(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      commonGetValues,
      currentVehicleData,
      dispatch,
      vendorName,
      isRouteOneVehicle,
      routeOneData,
      createdRouteOneVehicle,
    ],
  );

  const handleHideWithConfirmation = useCallback(() => {
    setIsShowExitConfirmModal(true);

    if (isShowExitConfirmModal) {
      dispatch(clearVehicleDetails());
      handleHideWithResetVehicle();
      setIsShowExitConfirmModal(false);
    }
  }, [dispatch, handleHideWithResetVehicle, isShowExitConfirmModal]);

  const trackJDPUsage = useCallback(async () => {
    if (user?.is_sso && !user?.isWithInventory) return;

    await vehicleApi.postJDPUsage(currentVehicleData?.id || routeOneData?.vehicle_id, vendorName);
  }, [currentVehicleData?.id, routeOneData?.vehicle_id, user?.isWithInventory, user?.is_sso, vendorName]);

  useEffect(() => {
    if (
      !addRouteOneVehicleFlags.isLoaded &&
      (!isAddedRouteOneVehicle ? isRouteOneCar || currentVehicleData?.id : createdRouteOneVehicle.id)
    ) {
      getLoanVariablesData();
      dispatch(resetRouteOneDataFlags());
      trackJDPUsage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, getLoanVariablesData, isLoanVariablesDialogActive, trackJDPUsage]);

  return (
    <ModalContainer
      show={isLoanVariablesDialogActive}
      onHide={formHistory.length > 1 ? handleHideWithConfirmation : handleHideWithResetVehicle}
      backdrop={'static'}
      $modalTheme={ModalTheme.DEFAULT}
      centered
      $minModalSize={'950px'}
      $modalSize={'950px'}
    >
      {isLoading ? (
        <Modal.Body>
          <Loader />
        </Modal.Body>
      ) : isFetchingFailed ? (
        <FailedBlock>
          <Modal.Header closeButton>
            <Title $uppercase>{t('components.loanVariablesModal.headers.adjustDealStructure')}</Title>
            <TradeMark>{t('common.symbols.tradeMark')}</TradeMark>
          </Modal.Header>
          <Modal.Body>
            <PlaceholderTitle>{t('common.errorMessages.failedRequestMessage')}</PlaceholderTitle>
          </Modal.Body>
        </FailedBlock>
      ) : (
        <>
          <LoanVariablesFormContent
            isLender={isLender}
            isLoanVariablesDialogActive={isLoanVariablesDialogActive}
            currentVehicleData={currentVehicleData}
            defaultValues={defaultValues}
            loanVariables={loanVariables}
            loanTermsList={loanTermsList}
            isShowExitConfirmModal={isShowExitConfirmModal}
            isRouteOneVehicle={isRouteOneVehicle}
            formHistory={formHistory}
            handleHide={handleHide}
            handleHideWithResetVehicle={handleHideWithResetVehicle}
            setFormHistory={setFormHistory}
            setLoanVariables={setLoanVariables}
            setIsShowExitConfirmModal={setIsShowExitConfirmModal}
            setLoanTermsList={setLoanTermsList}
            isAddedRouteOneVehicle={isAddedRouteOneVehicle}
          />
          {isShowExitConfirmModal && (
            <ExitConfirmationModal
              isShow={isShowExitConfirmModal}
              hideOnNo={() => setIsShowExitConfirmModal(false)}
              hideOnYes={handleHideWithConfirmation}
            />
          )}
        </>
      )}
    </ModalContainer>
  );
};
