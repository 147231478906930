import styled from 'styled-components';
import { COLORS } from 'modules/core/theme/colors';
import Image from 'react-bootstrap/Image';
import { WindowBreakpoints } from 'modules/core/enums/windowBreakpoints.enum';

export const CardContainer = styled.div`
  width: 100%;
  border: 1px solid ${COLORS.GREY_9};
  border-radius: 5px;
  padding: 10px 10px;
  margin-bottom: 20px;
  display: flex;
`;

export const Title = styled.p`
  color: ${COLORS.GREY_3};
  font-size: 14px;
  font-style: italic;
  margin-bottom: 0;
`;

export const Price = styled.p`
  color: ${COLORS.BLUE_7};
  font-size: 24px;
  font-weight: 800;
  margin-bottom: 0;
`;

export const Description = styled.p`
  color: ${COLORS.GREY_3};
  font-size: 14px;
  margin-bottom: 0;
`;
export const Img = styled(Image)`
  width: 70%;
`;

export const ImgContainer = styled.div`
  width: 150px;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: ${WindowBreakpoints.small}px) {
    margin-right: 5px;
  }
`;
