import { IFormData, ITransformedData, TFormDataOption } from './types';
import { FIELD_NAMES } from './sendReportForm.const';

export const transformFormData = (formData: IFormData): ITransformedData => {
  const emailField = formData[FIELD_NAMES.EMAIL as TFormDataOption]
    ? { email: formData[FIELD_NAMES.EMAIL as TFormDataOption] }
    : {};

  const phoneField = formData[FIELD_NAMES.PHONE as TFormDataOption]
    ? { phone_number: formData[FIELD_NAMES.PHONE as TFormDataOption].toString() }
    : {};

  const noteField = formData[FIELD_NAMES.NOTE as TFormDataOption]
    ? { note: formData[FIELD_NAMES.NOTE as TFormDataOption] }
    : {};

  return {
    first_name: formData[FIELD_NAMES.NAME as TFormDataOption],
    last_name: formData[FIELD_NAMES.LAST_NAME as TFormDataOption],
    ...emailField,
    ...phoneField,
    ...noteField,
  };
};
