import React, { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavItem } from 'react-bootstrap';
import { Icon } from 'modules/core/components';
import { ICONS_HEIGHT, STATIC_IMAGE_URLS } from 'modules/core/constants';
import { AppDispatch } from 'store/store';
import { getFullSideBar, getDisabledFeaturesList, getFetchingUIFlag } from 'store/selectors/ui.selector';
import { getFetchingLendersFlag, getLendersList } from 'store/selectors/lenders.selector';
import { setFullSideBar } from 'store/reducers/ui.reducer';
import { PermissionWrapper } from 'modules/PermissionWrapper';
import { ALL } from 'modules/PermissionWrapper/roleGroups.const';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import { getAuthState } from 'store/selectors/auth.selector';
import { useDebounceForLoading } from 'router/utils/useDebounceForLoading';
import { removeHandleBeforeUnloadEvent } from 'hooks/useConfirmTabClose';
import i18n from 'i18n';

import {
  MenuItemTitle,
  SideBarList,
  SideBarListLink,
  SideBarListExternalLink,
  SideBarMenu,
  ToggleMenuIcon,
  Overlay,
} from './styles';
import { IMenuLink, defaultMenuLinks } from './sideBarMenuLinks';
import { filterByDisabledFeatures, concatMenuLinks } from './utils';
import { useClickOutsideSideBar } from './hooks';

type NavigationPropsType = { to?: string; href?: string; selected?: boolean };
interface INavigationLink {
  smallFont?: boolean;
}

interface ISideBar {
  isExternal?: boolean;
}

export const SideBar: React.FC<ISideBar> = ({ isExternal }) => {
  const dispatch = useDispatch<AppDispatch>();
  const fullSideBar = useSelector(getFullSideBar);
  const disabledFeaturesList = useSelector(getDisabledFeaturesList);
  const lenderLinks = useSelector(getLendersList);
  const { pathname } = window.location;
  const currentPathName = useMemo(() => pathname, [pathname]);

  const enabledDefaultMenuLinks = filterByDisabledFeatures(defaultMenuLinks, disabledFeaturesList);
  const enabledLendersMenuLinks = filterByDisabledFeatures(lenderLinks, disabledFeaturesList);
  const enabledMenuLinks = concatMenuLinks(enabledDefaultMenuLinks, enabledLendersMenuLinks);
  const { isLargeWidth } = useWindowDimensions();

  const { isFetching: isAuthFetching } = useSelector(getAuthState);
  const isFetchingFeatureList = useSelector(getFetchingUIFlag);
  const isFetchingLendersList = useSelector(getFetchingLendersFlag);
  const isFetching = isAuthFetching || isFetchingFeatureList || isFetchingLendersList;
  const isLoading = useDebounceForLoading(isFetching);

  const sidebarRef = useRef(null);

  useClickOutsideSideBar(sidebarRef, fullSideBar);

  const toggleSideBar = useCallback(() => {
    dispatch(setFullSideBar(!fullSideBar));

    document.body.style.overflow = isLargeWidth && !fullSideBar ? 'hidden' : 'auto';
  }, [dispatch, fullSideBar, isLargeWidth]);

  useEffect(() => {
    dispatch(setFullSideBar(false));

    document.body.style.overflow = 'auto';
  }, [pathname, dispatch]);

  return isLoading ? (
    <></>
  ) : (
    <>
      <Overlay open={fullSideBar && isLargeWidth} onClick={toggleSideBar} />
      <SideBarMenu ref={sidebarRef} open={fullSideBar} className="sidebarMenu">
        <ToggleMenuIcon
          isExternal={isExternal}
          open={fullSideBar}
          onClick={toggleSideBar}
          id="sidebar-arrow"
          data-testid="sideBarArrow"
        >
          <span />
        </ToggleMenuIcon>
        <div>
          <SideBarList open={fullSideBar}>
            {enabledMenuLinks.map((item: IMenuLink) => {
              const NavigationLink: FC<INavigationLink> =
                item.link && !isExternal ? SideBarListLink : SideBarListExternalLink;

              const navigationProps: NavigationPropsType =
                item.link && !isExternal
                  ? { to: item.link, selected: item.link === currentPathName }
                  : { href: item.href || item.link, selected: currentPathName.includes(item.href as string) };

              return (
                <PermissionWrapper allowedRoles={item.allowedRoles || ALL} key={`${item.name}-${Math.random()}`}>
                  {!(!fullSideBar && !item.iconUrl) && (
                    <NavItem
                      key={item.name}
                      onMouseDown={item?.iconUrl === STATIC_IMAGE_URLS.sideBar.rac ? toggleSideBar : undefined}
                      onClick={
                        item.name === i18n.t('common.sideBarMenuLink.rac') ? undefined : removeHandleBeforeUnloadEvent
                      }
                    >
                      <NavigationLink smallFont={item.smallFont} {...navigationProps}>
                        <Icon imageSrc={item?.iconUrl} height={ICONS_HEIGHT.DEFAULT} />
                        <MenuItemTitle open={fullSideBar} smallFont={item.smallFont}>
                          {item.name}
                        </MenuItemTitle>
                      </NavigationLink>
                    </NavItem>
                  )}
                </PermissionWrapper>
              );
            })}
          </SideBarList>
        </div>
      </SideBarMenu>
    </>
  );
};
