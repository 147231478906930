import styled from 'styled-components';
import { COLORS } from 'modules/core/theme/colors';

export const Label = styled.div<{
  colored?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: ${({ theme: { variables } }) => variables.inputHeight};
  padding: 6px;
  font-size: ${({ theme: { fontSize } }) => fontSize.sm};
  background-color: ${({ colored }) => (colored ? COLORS.GREEN_1 : COLORS.GREY_2)};
  border-radius: 4px;
`;

export const LabelSign = styled.div`
  margin-right: 5px;
`;

export const LabelContent = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
