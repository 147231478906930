import styled from 'styled-components';
import { STATIC_IMAGE_URLS } from 'modules/core/constants';
import { WindowBreakpoints } from 'modules/core/enums/windowBreakpoints.enum';

export const LogoWrapper = styled.div<{
  isInMobileMenu?: boolean;
  isClickable: boolean;
}>`
  display: inline-block;
  height: 40px;
  width: 170px;
  vertical-align: top;
  background: no-repeat left center url(${STATIC_IMAGE_URLS.logo});
  background-size: contain;
  cursor: ${({ isClickable }) => isClickable && 'pointer'};

  margin-left: ${({ isInMobileMenu }) => isInMobileMenu && '50px'};

  @media (max-width: ${WindowBreakpoints.small}px) {
    margin-right: 5px;
    width: 123px;
  }
`;
