import React from 'react';
import i18n from 'i18n';
import { Input } from 'modules/core/components/Input/Input';
import { IField } from 'modules/core/types';
import { CalculatorAdornment, DollarAdornment, PercentAdornment } from 'modules/core/components';

import { APPROVED_LOAN_TERMS_FIELD_NAMES, FEES_AND_TAXES_FIELD_NAMES } from './fieldNames.const';
import { ColumnWidth } from '../../core/enums';
import { requiredValidator, validateMinRequiredValue, validateTierValue } from '../../core/validators';
import { TierDropdown } from '../TierDropdown';
import { PROBABILITY_ESTIMATOR_FIELDS } from '../../ProbabilityEstimator/probabilityEstimator.const';

export const RAC_CONSUMER_INFORMATION_FIRST_FIELDS: IField[] = [
  {
    label: i18n.t('components.approvedLoanInput.approvedLoanTerms.labels.tier'),
    name: APPROVED_LOAN_TERMS_FIELD_NAMES.TIER,
    controlProps: {
      fieldWidth: ColumnWidth.FULL,
      rules: requiredValidator,
      required: true,
      validate: validateTierValue,
    },
    component: TierDropdown,
  } as IField,
  {
    name: PROBABILITY_ESTIMATOR_FIELDS.GROSS_MONTHLY_INCOME,
    component: Input,
    label: i18n.t('components.consumerInformation.monthlyIncome'),
    isVerticalAlign: true,
    controlProps: {
      textStyle: 'bold',
      textPosition: 'start',
      endAdornment: <CalculatorAdornment />,
      required: true,
      readonly: true,
      decimalScale: 0,
      validate: validateMinRequiredValue,
      hideInitZeroValue: true,
      placeholder: i18n.t('components.input.placeholders.integer'),
    },
  },
  {
    name: PROBABILITY_ESTIMATOR_FIELDS.NET_DOWN_PAYMENT,
    component: Input,
    label: i18n.t('components.consumerInformation.totalCashAndTrade'),
    isVerticalAlign: true,
    controlProps: {
      textStyle: 'bold',
      textPosition: 'start',
      endAdornment: <CalculatorAdornment />,
      disabled: false,
      decimalScale: 0,
      allowNegative: true,
      readonly: true,
      hideInitZeroValue: true,
      placeholder: i18n.t('components.input.placeholders.integer'),
    },
  },
];

export const RAC_CONSUMER_INFORMATION_SECOND_FIELDS: IField[] = [
  {
    label: i18n.t('components.feesAndTaxes.labels.salesTaxRate'),
    name: FEES_AND_TAXES_FIELD_NAMES.SALES_TAX_RATE,
    component: Input,
    controlProps: {
      fieldWidth: ColumnWidth.FULL,
      endAdornment: <PercentAdornment />,
      required: true,
      rules: requiredValidator,
      decimalScale: 3,
      placeholder: i18n.t('components.input.placeholders.percent'),
    },
  },
  {
    label: i18n.t('components.feesAndTaxes.labels.estimatedFees'),
    name: FEES_AND_TAXES_FIELD_NAMES.ESTIMATED_FEES,
    component: Input,
    controlProps: {
      fieldWidth: ColumnWidth.FULL,
      startAdornment: <DollarAdornment />,
      decimalScale: 0,
      placeholder: i18n.t('components.input.placeholders.integer'),
    },
  },
  {
    name: APPROVED_LOAN_TERMS_FIELD_NAMES.FRONT_AND_BACK_ENDS_ADDS,
    component: Input,
    label: i18n.t('components.consumerInformation.frontAndBackEndsAdds'),
    isVerticalAlign: true,
    controlProps: {
      textStyle: 'bold',
      textPosition: 'start',
      endAdornment: <CalculatorAdornment />,
      disabled: false,
      decimalScale: 0,
      allowNegative: true,
      readonly: true,
      hideInitZeroValue: true,
      placeholder: i18n.t('components.input.placeholders.integer'),
    },
  },
];
