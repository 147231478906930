import i18n from 'i18n';
import { COLORS } from 'modules/core/theme/colors';

import { IBadge } from './Badge';

export const VEHICLE_STATUS_BADGE_PROPS: Record<string, IBadge> = {
  NEW: {
    title: i18n.t('components.table.cellsTitles.new'),
    color: COLORS.ORANGE_3,
  },
  JUST_ARRIVED: {
    title: i18n.t('components.table.cellsTitles.justArrived'),
    color: COLORS.ORANGE_3,
  },
  PENDING_SALE: {
    title: i18n.t('components.table.cellsTitles.pendingSale'),
    color: COLORS.ORANGE_1,
  },
  MAKE_IT_WORK: {
    title: i18n.t('components.table.cellsTitles.makeItWork'),
    color: COLORS.YELLOW_7,
  },
};
