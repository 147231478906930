import styled from 'styled-components';
import { COLORS } from 'modules/core/theme/colors';
import { TableView } from 'modules/core/enums';

type ColoredDiv = { color: string };

export const CardHeader = styled.div<ColoredDiv>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ color }) => color};
  width: 100%;
  color: ${COLORS.WHITE};
  text-transform: capitalize;
  padding: 0 5px;
  height: 25px;
  min-height: 25px;
`;

export const CardDescription = styled.div`
  font-weight: 700;
`;

export const CardRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  border-bottom: 1px solid ${COLORS.WHITE};
  height: 25px;
  align-items: center;

  span:first-child {
    color: ${COLORS.GREY_15};
  }
`;

export const CardCheckbox = styled.div`
  position: absolute;
  left: 13px;
  top: 16px;
`;

export const Card = styled.div<{ color: string; tableView: TableView }>`
  width: 305px;
  height: ${({ tableView }) => (tableView === TableView.SALESPERSON ? '430px' : '403px')};
  padding: 20px 25px;
  background-color: ${({ color }) => color};
  margin: 0 15px 15px 15px;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 15px;
  position: relative;

  ${CardCheckbox} {
    div {
      background-color: ${({ color }) => color};
    }
  }
`;

export const CardImageContainer = styled.div`
  img {
    height: 191px;
    width: 255px;
    object-fit: cover;
  }
`;
