import React, { FC, useCallback, useMemo } from 'react';
import { ILenderVehicle, IRACVehicle } from 'modules/core/types';
import { useDispatch } from 'react-redux';
import { useModalState } from 'modules/core/hooks';
import { MODAL_DIALOG_ID } from 'modules/core/constants';
import { AppDispatch } from 'store/store';
import { setLoanVariablesCurrentVehicle } from 'store/reducers/RACAffordabilityEngine.reducer';

import { OpenModalBlock, TextBlock } from './styles';

interface IVehicleDetails {
  value?: string | number;
  cell?: JSX.Element;
  currentVehicleData: IRACVehicle | ILenderVehicle;
}

export const OpenLoanVariablesDialog: FC<IVehicleDetails> = ({ value, cell, currentVehicleData }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { handleOpenDialog } = useModalState([MODAL_DIALOG_ID.LOAN_VARIABLES]);

  const handleOpenLoanVariablesDialog = useCallback(() => {
    dispatch(setLoanVariablesCurrentVehicle(currentVehicleData));
    handleOpenDialog();
  }, [currentVehicleData, dispatch, handleOpenDialog]);

  const dialogTriggerProps = useMemo(
    () => (!!currentVehicleData.isStubbed ? {} : { onClick: handleOpenLoanVariablesDialog }),
    [currentVehicleData.isStubbed, handleOpenLoanVariablesDialog],
  );

  return (
    <OpenModalBlock>
      <TextBlock {...dialogTriggerProps} isPointer={!currentVehicleData.isStubbed}>
        {value ? value : cell}
      </TextBlock>
    </OpenModalBlock>
  );
};
